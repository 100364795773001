import classNames from 'classnames'
import Parse from 'parse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faFolder,
  faFile,
  faBars,
  faStar,
  faUsers,
  faCheck,
  faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons'
import { useContext, useState } from 'react'
import { Button } from '../../../components/Button'
import { UserStoreContext } from '../../../store/userStore'
import { DocumentStoreContext } from '../../../store/documentStore'
import { ShareDocumentModal } from './ShareDocumentModal'
import { EditNameModal } from './EditNameModal'
import { MoveFileModal } from './MoveFileModal'
import { CopyFileModal } from './CopyFileModal'
import { OverviewModal } from './OverviewModal'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import DocSettings from '../../../components/DocSettings'
import { UserRoleConfigurationBoolean } from '../../../App/intefaces/configuration'

export const DocumentItem = ({
  id,
  title,
  icon,
  className,
  owner,
  count,
  countSign,
  unReadCount,
  sharedWith,
  hasUnRead,
  favorite,
  onClick,
  isFile,
  requireSign,
  signed,
  onUpdate,
  hideSettings,
  type,
  mimeType,
  createdAt,
}: {
  id: string
  title: string
  icon?: any
  className: string
  owner?: any
  count: number
  countSign: number
  unReadCount?: number
  favorite?: boolean
  sharedWith?: boolean
  hasUnRead: boolean
  hideSettings: boolean
  onClick: any
  isFile?: any
  requireSign?: boolean
  signed?: boolean
  onUpdate?: any
  type?: string
  mimeType?: string
  createdAt?: string
}) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [requestSignLoading, setRequestSignLoading] = useState(false)
  const [signLoading, setSignLoading] = useState(false)
  const { savedUserRoleId, currentUserRole, readOnly } =
    useContext(UserStoreContext)
  const {
    addDocumentToFavorite,
    removeFolder,
    removeFile,
    signDocument,
    requestSignDocument,
  } = useContext(DocumentStoreContext)

  const viewPermissionModal = getViewPermissionModal({
    currentUserRole,
    owner,
    type,
    mimeType,
    readOnly,
  })
  return (
    <div
      className={classNames(
        'flex flex-row  items-center text-gray-800  cursor-pointer p w-full shadow-[0px_2px_12px_2px_rgba(0,0,0,0.10)]',
        'relative',
        'border rounded-2xl shadow',
        'p-3  space-x-0  hover:shadow-md md:justify-center px-3',
      )}
    >
      {isFile && (
        <p
          className={'text-gray-500 text-2xs absolute top-2 right-6 md:right-8'}
        >
          {createdAt}
        </p>
      )}
      {!hideSettings && (
        <div
          style={{ zIndex: 1 }}
          onClick={(e) => {
            e.stopPropagation()
            setShowModal(true)
          }}
          className={
            'absolute md:top-3 right-10 md:right-2 text-gray-500 bg-white w-[30px] h-[30px] z-50 flex flex-col items-center justify-center hover:scale-110'
          }
        >
          <FontAwesomeIcon
            className="px-2"
            icon={faEllipsisVertical}
            size={'1x'}
          />
        </div>
      )}
      <div
        style={{ zIndex: 1 }}
        className={
          'absolute bottom-2 right-2 text-gray-500 bg-white z-50 flex flex-col items-center justify-center '
        }
      >
        {requireSign && viewPermissionModal.sign && isFile && !signed && (
          <Button
            // icon={faPenLine}
            className="max-w-[100px] justify-end mr-1"
            size="xs"
            loading={signLoading}
            variant={'signBlue'}
            label={t('documents.sign')}
            onClick={async (e) => {
              if (requireSign) {
                Swal.fire({
                  //title: 'Are you sure?',
                  text: t('documents.areYouSureSign'),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.yes'),
                  cancelButtonText: t('general.cancel'),
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    setSignLoading(true)
                    await signDocument(id, currentUserRole?.id ?? '').finally(
                      () => {
                        setSignLoading(false)
                      },
                    )
                    /*notify({
                      variant: NotificationVariant.OK,
                      title: t('documents.signedSuccessTitle'),
                      message: t('documents.signedSuccessMessage'),
                    })*/
                    Swal.fire({
                      text: t('documents.signedSuccessMessage'),
                      icon: 'success',
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                    })
                    if (onUpdate) {
                      onUpdate()
                    }
                    setShowModal(false)
                  }
                })
              }
            }}
          />
        )}
      </div>

      <div
        onClick={onClick}
        className={classNames(
          'flex justify-center items-center relative ',
          className,
        )}
      >
        {hasUnRead && (
          <div
            className={
              'h-4 w-4 bg-eventsMain rounded-full absolute -top-1 -left-1 z-10'
            }
          />
        )}

        <div className={'relative'}>
          <FontAwesomeIcon
            className={classNames(className)}
            icon={isFile ? faFile : faFolder}
            size="5x"
          />

          <div
            className={
              'absolute top-0 left-0 w-full h-full flex justify-center items-center'
            }
          >
            {(isFile || icon) && (
              <FontAwesomeIcon
                className={'text-white pt-2'}
                icon={isFile ? faBars : icon}
                size="2x"
              />
            )}
          </div>
        </div>
      </div>
      <div
        onClick={onClick}
        className={
          'flex flex-col  justify-center flex-1  h-full border-gray-200 relative w-[150px] overflow-hidden break-all '
        }
      >
        <div className={'flex'}>
          <div className={'flex flex-col p-2'}>
            <p className={classNames('flex-1 line-clamp-1 ', isFile && 'pt-2')}>
              {title}{' '}
              {favorite && type !== 'favorite' && (
                <span>
                  <FontAwesomeIcon
                    icon={faStar}
                    className={'pb-0 text-contactsMain'}
                    size={'xs'}
                  />
                </span>
              )}
              {sharedWith && (
                <span>
                  <FontAwesomeIcon
                    icon={faUsers}
                    className={'pl-1 text-eventsMain'}
                    size={'xs'}
                  />
                </span>
              )}
            </p>
            {!isFile && (
              <div className={'flex w-full r'}>
                <p className={'text-2xs'}>
                  {count} {t('documents.documentTitle')}
                </p>
              </div>
            )}
            {typeof unReadCount == 'number' && !isFile ? (
              <p className={' text-2xs text-eventsMain md:h-4 '}>
                {t('documents.unread')}: {unReadCount}
              </p>
            ) : null}

            {!viewPermissionModal.sign || countSign === 0 ? null : (
              <p className={' text-2xs text-red-400 md:h-4 '}>
                {countSign} {t('documents.toSign')}
              </p>
            )}

            {viewPermissionModal.sign && requireSign && (
              <p className={' text-2xs text-red-400 md:h-4'}>
                {!signed ? t('documents.toSign') : ''}
              </p>
            )}
          </div>
        </div>
        <div className="flex justify-end">
          {signed && viewPermissionModal.sign && isFile && (
            <FontAwesomeIcon
              icon={faCheck}
              className="text-green-400 font-bold"
              size="lg"
            />
          )}
        </div>
        {!isFile && (
          <div>
            <FontAwesomeIcon
              style={{ top: '50%', transform: 'translateY(-50%)' }}
              className={'absolute right-2 text-gray-400 md:hidden'}
              icon={faChevronRight}
            />
          </div>
        )}
      </div>
      <DocSettings
        title={t('documents.docSettings')}
        className={'max-w-sm'}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        bgColor="bg-gray-600"
        closeColor="white"
      >
        <div className={'flex flex-col space-y-5 px-12 py-10 bg-gray-100'}>
          {
            //viewPermissionModal.addToFavorite && isFile &&  (
            viewPermissionModal.addToFavorite && isFile && !favorite && (
              <Button
                size="md"
                variant={'fullWhite'}
                label={t('documents.addToFavorite')}
                onClick={async () => {
                  const actionState = await addDocumentToFavorite({
                    userRoleId: savedUserRoleId ?? '',
                    isFavorite: true,
                    documentId: id,
                  })
                  setShowModal(false)
                  if (actionState) {
                    /*notify({
                    variant: NotificationVariant.OK,
                    title: t('documents.addToFavoriteSuccessTitle'),
                    message: t('documents.addToFavoriteSuccessMessage'),
                  })*/
                    Swal.fire({
                      text: t('documents.addToFavoriteSuccessMessage'),
                      icon: 'success',
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                    })

                    if (onUpdate) {
                      onUpdate()
                    }
                  }
                }}
              />
            )
          }
          {
            //viewPermissionModal.removeFavorite && isFile && (
            favorite && isFile && (
              <Button
                size="md"
                label={t('documents.removeToFavorite')}
                onClick={async () => {
                  const actionState = await addDocumentToFavorite({
                    userRoleId: savedUserRoleId ?? '',
                    isFavorite: false,
                    documentId: id,
                  })
                  setShowModal(false)
                  if (actionState) {
                    /*notify({
                    variant: NotificationVariant.OK,
                    title: t('documents.removeFromFavoriteSuccessTitle'),
                    message: t('documents.removeFromFavoriteSuccessMessage'),
                  })*/
                    Swal.fire({
                      text: t('documents.removeFromFavoriteSuccessMessage'),
                      icon: 'success',
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                    })

                    if (onUpdate) {
                      onUpdate()
                    }
                  }
                }}
              />
            )
          }
          {viewPermissionModal.overview && isFile && (
            <OverviewModal documentId={id} type={type ?? ''} />
          )}

          {viewPermissionModal.requestSign && isFile && (
            <Button
              size="md"
              loading={requestSignLoading}
              label={
                requireSign
                  ? t('documents.removeSign')
                  : t('documents.requestSign')
              }
              onClick={async () => {
                setRequestSignLoading(true)
                await requestSignDocument(
                  id,
                  requireSign ? false : true,
                ).finally(() => {
                  setRequestSignLoading(false)
                })
                if (onUpdate) {
                  onUpdate()
                }
              }}
            />
          )}
          {viewPermissionModal.move && isFile && (
            <MoveFileModal
              documentId={id}
              onSaved={() => {
                setShowModal(false)
                if (onUpdate) {
                  onUpdate()
                }
              }}
            />
          )}
          {viewPermissionModal.copy && isFile && (
            <CopyFileModal
              documentId={id}
              onSaved={() => {
                setShowModal(false)
                if (onUpdate) {
                  onUpdate()
                }
              }}
            />
          )}
          {viewPermissionModal.name && (
            <EditNameModal
              name={title}
              id={id}
              isFile={isFile}
              onSaved={() => {
                setShowModal(false)
                if (onUpdate) {
                  Swal.fire({
                    text: t('documents.docChangeName'),
                    icon: 'success',
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                  })
                  onUpdate()
                }
              }}
            />
          )}
          {viewPermissionModal.share && isFile && (
            <ShareDocumentModal
              documentId={id}
              onSaved={() => {
                setShowModal(false)
              }}
            />
          )}
          {viewPermissionModal.remove && (
            <Button
              size="md"
              variant={'docRemove'}
              label={t('documents.remove')}
              onClick={async () => {
                if (isFile) {
                  if (window.confirm(t('documents.areYouSureRemoveFile'))) {
                    await removeFile({
                      documentId: id,
                    })
                  }
                } else {
                  if (window.confirm(t('documents.areYouSureRemoveFolder'))) {
                    await removeFolder({
                      folderId: id,
                    })
                  }
                }

                setShowModal(false)
                if (onUpdate) {
                  onUpdate()
                }
              }}
            />
          )}
        </div>
      </DocSettings>
    </div>
  )
}

const getViewPermissionModal = ({
  currentUserRole,
  owner,
  type,
  mimeType,
  readOnly,
}: {
  currentUserRole: any
  owner: any
  type?: string
  mimeType?: string
  readOnly?: UserRoleConfigurationBoolean
}) => {
  const currentUser = Parse.User.current()
  const docStaffCreateFolder =
    currentUserRole?.attributes?.school_pointer?.attributes?.school_setting
      ?.attributes?.doc_staff_create_folder

  // VH
  if (
    currentUserRole?.attributes?.role_type === 350 &&
    !currentUserRole?.attributes?.is_upload_document_parent
  ) {
    return {
      remove: owner?.id === currentUser?.id,
      share: false,
      name: owner?.id === currentUser?.id,
      addToFavorite: type === 'favorite' ? false : true,
      removeFavorite: type === 'favorite',
      overview: owner?.id === currentUser?.id,
      sign:
        !type ||
        type === 'kid' ||
        type === 'school' ||
        type === 'favorite' ||
        type === 'shared',
      requestSign: false,
      move: false,
      copy: false,
    }
  }
  // VH UPLOAD
  else if (
    (currentUserRole?.attributes?.role_type === 350 &&
      currentUserRole?.attributes?.is_upload_document_parent) ||
    currentUserRole?.attributes?.role_type === 450
  ) {
    return {
      remove: owner?.id === currentUser?.id,
      share: type === 'my',
      name: !docStaffCreateFolder
        ? owner?.id === currentUser?.id
        : owner?.id === currentUser?.id
        ? true
        : false,
      addToFavorite: type === 'favorite' ? false : true,
      removeFavorite: type === 'favorite',
      overview:
        owner?.id === currentUser?.id
          ? true
          : !readOnly?.documents
          ? true
          : false,
      sign:
        !type ||
        type === 'kid' ||
        type === 'school' ||
        type === 'my' ||
        type === 'favorite' ||
        type === 'shared',
      requestSign: mimeType === 'pdf' ? owner?.id === currentUser?.id : false,
      move: !docStaffCreateFolder
        ? owner?.id === currentUser?.id
        : owner?.id === currentUser?.id
        ? true
        : false,
      copy: !readOnly?.documents ? true : false,
    }
  } else if (currentUserRole?.attributes?.role_type >= 500) {
    return {
      remove: true,
      share: type === 'my',
      name: true,
      addToFavorite: type === 'favorite' ? false : true,
      removeFavorite: type === 'favorite',
      overview: true,
      sign:
        !type ||
        type === 'only_staff' ||
        type === 'favorite' ||
        type === 'my' ||
        type === 'shared',
      requestSign: mimeType === 'pdf' ? true : false,
      move: true,
      copy: true,
    }
  } else {
    return {
      remove: false,
      share: false,
      name: false,
      addToFavorite: false,
      removeFavorite: false,
      overview: false,
      sign: false,
      requestSign: false,
      move: false,
      copy: false,
    }
  }
}
