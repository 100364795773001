import { useContext, useEffect, useState } from 'react'
import Parse from 'parse'
import { observer } from 'mobx-react'
import moment from 'moment'
import Page from '../../components/Page'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useTranslation } from 'react-i18next'
import { Field, FieldProps, Form, Formik } from 'formik'
import { School } from '../../Models/School'
import { Department } from '../../Models/Department'
import { Input } from '../../components/formik/Input'
import Multiselect from '../../components/Multiselect'
import Singleselect from '../../components/Singleselect'
import { useNavigate, useParams } from 'react-router'
import { DepartmentStoreContext } from '../../store/departmentStore'
import { Button } from '../../components/Button'
import DatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
import { UserStoreContext } from '../../store/userStore'
import { Spinner } from '../../components/Spinner'
import classNames from 'classnames'
import Swal from 'sweetalert2'
import heic2any from 'heic2any'
import { Loading } from '../../components/Loading'
registerLocale('sv', sv)
registerLocale('en', en)
registerLocale('es', es)

interface newEvent {
  school: string
  school_pointer: School | null
  school_name: string
  class_pointer: Department[]
  //department: Department | null;
  type: string
  topic: string
  content: string
  date: Date | null
  startingHour: Date | null
  endingHour: Date | null
  pdf: any | null
  picture: any | null
  imagePreview: any | null
}

let initialValues = {
  school: '',
  school_pointer: null,
  school_name: '',
  //department: null,
  class_pointer: [],
  type: '',
  topic: '',
  content: '',
  date: new Date(),
  startingHour: new Date().setHours(8, 0),
  endingHour: null,
  pdf: null,
  picture: null,
  imagePreview: null,
}

export const EventsCreate = observer(() => {
  let reader = new FileReader()
  const { t, i18n } = useTranslation()
  let { schoolId } = useParams()
  const navigate = useNavigate()

  const [content, setContent] = useState('')
  const categories = [
    { id: 'Föräldraförening', label: t('events.parentAssociation') },
    { id: 'Personal', label: t('documents.staff') },
    { id: 'Nyheter', label: t('events.news') },
    { id: 'Föräldramöte', label: t('events.parentMeeting') },
    { id: 'Studiedagar', label: t('events.studyDays') },
    { id: 'Övrigt', label: t('ourKids.other') },
  ]
  const { fetchUserRole, savedUserRoleId, currentUserRole } =
    useContext(UserStoreContext)
  const [imagePreview, setImagePreview] = useState<any>(null)
  const { setTitle } = useContext(NavigationStoreContext)
  const [loading, setLoading] = useState(true)
  const [loadingHeic, setLoadingHeic] = useState(false)
  const [selectedDepartments, setSelectedDepartments] = useState<Department[]>(
    [],
  )
  const { fetchDepartments, departments } = useContext(DepartmentStoreContext)

  const [selectedCategory, setSelectedCategory] = useState(null)

  useEffect(() => {
    let isMounted = true

    if (savedUserRoleId) {
      setLoading(true)

      fetchUserRole(savedUserRoleId).finally(() => {
        if (isMounted) {
          setLoading(false) // Only update state if component is still mounted
        }
      })
    }

    // Cleanup function to set `isMounted` to false when the component unmounts
    return () => {
      isMounted = false
    }
  }, [savedUserRoleId])

  useEffect(() => {
    let isMounted = true

    if (schoolId) {
      setLoading(true)

      fetchDepartments(schoolId).finally(() => {
        if (isMounted) {
          setLoading(false)
        }
      })
    }
    setTitle(t('menu.events'))

    return () => {
      isMounted = false
    }
  }, [schoolId])

  const handleUploadPDF = (event: any, props: FieldProps<newEvent>) => {
    const imageFile = event.target.files[0]
    if (!imageFile) {
      return false
    }

    if (!imageFile.name.match(/\.(pdf|PDF)$/)) {
      //setinvalidImage("Please select valid image JPG,JPEG,PNG");
      return false
    }
    props.form.setFieldValue(props.field.name, imageFile)
  }

  const handleUploadImage = async (event: any, props: FieldProps<newEvent>) => {
    let imageFile = event.target.files[0]
    const imageFilname = event.target.files[0].name.replace(' ', '_')
    if (!imageFile) {
      //setinvalidImage("Please select image.");
      setLoadingHeic(false)
      return false
    }
    if (
      !imageFile.type.match(
        /\/(jpg|jpeg|png|JPG|JPEG|PNG|gif|heic|HEIC|heif|HEIF)$/,
      )
    ) {
      //setinvalidImage("Please select valid image JPG,JPEG,PNG");
      Swal.fire({
        title: t('general.wrongFormatTitle'),
        text: t('general.wrongFormatText'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#7eb5f4',
        cancelButtonColor: '#d33',
        confirmButtonText: t('general.ok'),
        cancelButtonText: t('general.cancel'),
      })
      return false
    }
    if (imageFile.type.match(/\/(heic|HEIC|heif|HEIF)$/)) {
      setLoadingHeic(true)
      imageFile = await heic2any({
        // required: the HEIF blob file
        blob: imageFile,
        // (optional) MIME type of the target file
        // it can be "image/jpeg", "image/png" or "image/gif"
        // defaults to "image/png"
        toType: 'image/jpeg',
        // conversion quality
        // a number ranging from 0 to 1
        quality: 0.5,
      })
    }
    reader.onload = (e) => {
      const img: any = new Image()
      img.onload = () => {
        //------------- Resize img code ----------------------------------
        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(img, 0, 0)
        }

        var MAX_WIDTH = 1800
        var MAX_HEIGHT = 1800
        var width = img.width
        var height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        // eslint-disable-next-line @typescript-eslint/no-redeclare
        var ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height)
          ctx.canvas.toBlob(
            (blob: any) => {
              const file = new File([blob], imageFilname, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              })
              setImagePreview(URL.createObjectURL(imageFile))
              props.form.setFieldValue(props.field.name, file)
            },
            'image/jpeg',
            1,
          )
        }

        //setinvalidImage(null);
      }
      img.onerror = () => {
        //setinvalidImage("Invalid image content.");
        setLoadingHeic(false)
        return false
      }
      //debugger
      img.src = e?.target?.result
    }
    reader.readAsDataURL(imageFile)
    setLoadingHeic(false)
  }

  const uploadImageToParse = async (file: any) => {
    var extension = file.name.split('.').pop()
    var parseImageFile = new Parse.File('tyraimage.' + extension, file)

    await parseImageFile.save()
    return parseImageFile
  }
  const uploadPdfToParse = async (file: any) => {
    var parsePdfFile = new Parse.File('TyraDocument.pdf', file)
    await parsePdfFile.save()
    return parsePdfFile
  }

  const handleSave = async (values: any) => {
    const adressedDepartments: Department[] = []

    if (values.picture) {
      values.picture = await uploadImageToParse(values.picture)
    }
    if (values.pdf) {
      values.pdf = await uploadPdfToParse(values.pdf)
      values.pdf._getId = ''
    }
    //return false;
    const poster = currentUserRole

    //const dep = departments?.find((dep: Department) => dep == newEvent.class_pointer[0]);

    for (var i = 0; i < selectedDepartments.length; i++) {
      let dep = departments?.find(
        // eslint-disable-next-line no-loop-func
        (dep: Department) => dep.id === selectedDepartments[i].id,
      )
      if (dep) {
        adressedDepartments.push(dep)
      }
    }
    // newEvent.class_pointer = adressedDepartments;

    //const createEvent = await Parse.Cloud.run('createEvent', { "user_role": JSON.parse(JSON.stringify(poster)), "event": JSON.parse(JSON.stringify(newEvent)) });
    //const createEvent = await Parse.Cloud.run('createEvent', { "event": newEvent.school_pointer });
    const school_pointer = currentUserRole?.school_pointer
    const school = currentUserRole?.school
    const startingHour = moment(values.startingHour).get('hour')
    const startingMinute = moment(values.startingHour).get('minute')
    const date = values.date.setHours(startingHour, startingMinute)

    const event = new Parse.Object('events')
    event.set('topic', values.topic)
    event.set('author', Parse.User.current())
    //event.set('content', content.toString('html')) // Content is outside Formik because of RichTextEditor didn't work together
    event.set('content', content)
    event.set('type', values.type)
    event.set('school_pointer', school_pointer)
    event.set('school', school)
    event.set('class_pointer', adressedDepartments)
    event.set('date', new Date(parseInt(date)))
    if (values.endingHour) {
      const endingHour = moment(values.endingHour).get('hour')
      const endingMinute = moment(values.endingHour).get('minute')
      const endDate = values.date.setHours(endingHour, endingMinute)
      event.set('stopDate', new Date(parseInt(endDate)))
    }
    event.set('edited_date', new Date())
    event.set('publish_date', new Date())
    event.set('sendPush', true)
    event.set('poster', poster)
    if (values.pdf) {
      event.set('pdf', values.pdf)
    }
    if (values.picture) {
      event.set('picture', values.picture)
    }

    await event
      .save()
      .then(function (response) {
        Swal.fire({
          //title: 'Are you sure?',
          text: t('events.eventCreatedSuccessMesage'),
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.yes'),
          cancelButtonText: t('general.cancel'),
        })
        navigate('/events/' + schoolId)
      })
      .catch(function (error) {
        console.log('Error: ' + error.code + ' ' + error.message)
      })
  }

  if (loading) {
    return (
      <div className={'flex w-full h-12 items-center justify-center'}>
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <Page>
        {loadingHeic && <Loading />}
        <Formik
          initialValues={initialValues}
          // validationSchema={loginSchema}
          onSubmit={(values) => {
            handleSave(values)
          }}
        >
          <Form>
            <div className="ml-auto mr-auto max-w-[1200px] p-8">
              <div className="flex justify-center p-6 sm:space-x-4 space-y-6 sm:space-y-0 flex-wrap">
                <Field name="class_pointer">
                  {(props: FieldProps<newEvent>) =>
                    selectedDepartments && (
                      <Multiselect
                        onChange={(e) => {
                          //let departments = selectedDepartments
                          //departments.push(e)
                          setSelectedDepartments(e)
                          props.form.setFieldValue('class_pointer', e)
                        }}
                        initialSelectedItems={initialValues.class_pointer}
                        items={departments as Department[]}
                        itemVariableKey={'klassName'}
                        placeholder={t('general.department')}
                      />
                    )
                  }
                </Field>
                <Field name="type">
                  {(props: FieldProps<newEvent>) => (
                    <Singleselect
                      onChange={(e) => {
                        /*let departments = selectedDepartments
                    departments.push(e)
                    setSelectedDepartments(departments)*/
                        setSelectedCategory(e)
                        props.form.setFieldValue('type', e ? e.id : null)
                      }}
                      initialSelectedItem={
                        initialValues.type
                          ? {
                              value: initialValues.type,
                              label: initialValues.type,
                            }
                          : null
                      }
                      items={categories}
                      itemVariableKey={'label'}
                      placeholder={t('general.category')}
                      removeSelected
                    />
                  )}
                </Field>
              </div>
              <div className="flex justify-center p-6 mt-0 sm:space-x-10 md:space-x-24 flex-wrap ">
                <div className="grid grid-cols-1 justify-between">
                  <Field name="date">
                    {(props: FieldProps<newEvent>) => {
                      if (
                        props.form.values.type &&
                        props.form.values.class_pointer.length &&
                        props.form.values.type !== 'Nyheter'
                      ) {
                        return (
                          <>
                            <div className="grid grid-cols-1 text-center  2xl:mb-8">
                              <label>{t('general.date')}</label>
                              <DatePicker
                                className="border border-gray-200 shadow p-2 rounded-2xl text-center w-full focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                dateFormat="yyyy-MM-dd"
                                locale={i18n.language}
                                selected={props.field.value as unknown as Date}
                                onChange={(date: Date) =>
                                  props.form.setFieldValue(
                                    props.field.name,
                                    date,
                                  )
                                }
                                minDate={new Date()}
                                onKeyDown={(e) => {
                                  e.preventDefault()
                                }}
                              />
                            </div>
                          </>
                        )
                      }
                      return null
                    }}
                  </Field>
                </div>
                {/*<div className="flex flex-wrap justify-center sm:space-x-6">
                  <Field name="startingHour">
                    {(props: FieldProps<newEvent>) => {
                      return (
                        <>
                          <div className="max-w-[110px] text-center">
                            <label>{t('general.startTime')}</label>
                            <DatePicker
                              className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                              selected={props.field.value as unknown as Date}
                              onChange={(date: Date) =>
                                props.form.setFieldValue(props.field.name, date)
                              }
                              minTime={
                                new Date(moment().format('YYYY-MM-DD 00:01'))
                              }
                              maxTime={
                                props.form.values.endingHour ??
                                new Date(moment().format('YYYY-MM-DD 23:59'))
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption={t('general.timeOfDay')}
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                              locale={i18n.language}
                            />
                          </div>
                        </>
                      )
                    }}
                  </Field>
                  <Field name="endingHour">
                    {(props: FieldProps<newEvent>) => {
                      return (
                        <div className="justify-center">
                          {' '}
                          <div className="max-w-[110px] text-center">
                            <label>{t('general.endTime')}</label>

                            <DatePicker
                              className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                              selected={props.field.value as unknown as Date}
                              onChange={(date: Date) =>
                                props.form.setFieldValue(props.field.name, date)
                              }
                              maxTime={
                                new Date(moment().format('YYYY-MM-DD 23:59'))
                              }
                              minTime={
                                props.form.values.startingHour ??
                                new Date(moment().format('YYYY-MM-DD 00:01'))
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption={t('general.timeOfDay')}
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                              locale={i18n.language}
                            />
                          </div>
                        </div>
                      )
                    }}
                  </Field>
                  </div>*/}
              </div>
              <div className="justify-center pt-6 px-6 max-w-3xl m-auto">
                <Field name="topic">
                  {(props: FieldProps<newEvent>) => (
                    <Input
                      customInputClass="w-full border border-gray-100 font-medium shadow rounded-2xl p-2 pl-4 focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                      label=""
                      placeholder={t('general.topic')}
                      autoFocus
                      {...props}
                    />
                  )}
                </Field>
              </div>
              <div className="flex flex-wrap justify-center w-full p-6 max-w-3xl m-auto">
                <textarea
                  className={classNames(
                    'border border-gray-100 w-full shadow rounded-2xl p-4 h-[200px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain',
                  )}
                  onChange={(e) => {
                    setContent(e.target.value)
                  }}
                  placeholder={t('notes.writeHere')}
                  rows={8}
                >
                  {content ?? ''}
                </textarea>
                {/*<RichTextEditor
                  className="min-h-[300px] !rounded-lg"
                  value={content}
                  onChange={(e: any) => {
                    setContent(e)
                  }}
                />*/}
              </div>

              <div className="flex flex-wrap justify-center w-full p-3">
                <div>
                  <Field name="pdf">
                    {(props: FieldProps<newEvent>) => {
                      if (props.form.values.picture) {
                        return null
                      }
                      return (
                        <>
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500">
                            {t('files.uploadPDF')}
                            <br />
                          </label>
                          <input
                            title={t('files.chooseFile')}
                            type="file"
                            accept=".pdf"
                            className="file:cursor-pointer block w-full text-sm text-gray-500  file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-center file:text-blue-500 hover:file:bg-blue-100"
                            name="upload_file"
                            onChange={(e) => handleUploadPDF(e, props)}
                          />{' '}
                          {props.form.values.pdf && (
                            <label
                              onClick={() => {
                                props.form.setFieldValue(props.field.name, null)
                              }}
                              className="block  mb-[2px] text-sm font-medium hover:text-red-600 hover:scale-110 cursor-pointer text-center text-gray-900 dark:text-gray-500"
                            >
                              <>{t('documents.remove')}</>
                            </label>
                          )}
                        </>
                      )
                    }}
                  </Field>
                </div>
                <div>
                  <Field name="picture">
                    {(props: FieldProps<newEvent>) => {
                      if (props.form.values.pdf) {
                        return null
                      }
                      return (
                        <>
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500">
                            {t('files.uploadImage')}
                            <br />
                          </label>
                          <div>
                            <input
                              title={t('files.chooseFile')}
                              type="file"
                              //accept="image/png, image/jpeg"
                              //accept="png, jpeg"
                              className="file:cursor-pointer block w-full text-sm text-gray-500  file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-center file:text-blue-500 hover:file:bg-blue-100"
                              name="upload_file"
                              onChange={(e) => handleUploadImage(e, props)}
                            />
                          </div>
                          {imagePreview && (
                            <label
                              onClick={() => {
                                props.form.setFieldValue(props.field.name, null)
                                setImagePreview(null)
                              }}
                              className="block  mb-[2px] text-sm font-medium hover:text-red-600 hover:scale-110 cursor-pointer text-center text-gray-900 dark:text-gray-500"
                            >
                              <>{t('documents.remove')}</>
                            </label>
                          )}
                        </>
                      )
                    }}
                  </Field>
                  {imagePreview !== null ? (
                    <img
                      className="previewimg mt-10"
                      src={imagePreview}
                      alt="UploadImage"
                      width={200}
                    />
                  ) : null}
                </div>
              </div>

              <div className="flex justify-center md:justify-end">
                <Button
                  size="custom"
                  loading={loading}
                  type="submit"
                  variant="borderBlue"
                  label={t('notes.publish')}
                  onClick={(e) => {
                    if (selectedDepartments.length < 1 || !selectedCategory) {
                      if (selectedDepartments.length < 1) {
                        Swal.fire({
                          //title: 'Are you sure?',
                          text: t('events.eventAlarmDep'),
                          icon: 'warning',
                          showCancelButton: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                          cancelButtonText: t('general.cancel'),
                        })
                      }
                      if (!selectedCategory) {
                        Swal.fire({
                          //title: 'Are you sure?',
                          text: t('events.eventAlarmCat'),
                          icon: 'warning',
                          showCancelButton: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                          cancelButtonText: t('general.cancel'),
                        })
                      }
                      e.preventDefault()
                    }
                  }}

                  //onClick={submitForm}
                  //disabled={isSubmitting}
                />
              </div>
            </div>
          </Form>
        </Formik>
      </Page>
    </>
  )
})
