import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { School } from '../Models/School'
import { userStore } from './userStore'
import { Department } from '../Models/Department'
import { DefaultKidFolders } from '../Models/SchoolSetting'
import { Document } from '../Models/Document'

export interface LikeComment {
  port_commentable: boolean
  port_emotable: boolean
  last_modified_userId: string
  blog_emotable: boolean
  last_modified_date: string
  blog_commentable: boolean
}
export interface newOpeningHours {
  end: string
  start: string
}

export interface AdminAllowCreateFolder {
  doc_staff_create_folder: boolean
}
export interface Link {
  link: string
  name: string
  visibility: number
}

class SchoolStore {
  school?: School
  contactPersons?: Department[]
  department?: Department[]
  constructor() {
    makeAutoObservable(this)
  }
  setSchool = (school: School) => {
    this.school = school
  }
  setContactPersons = (contactPersons: Department[]) => {
    this.contactPersons = contactPersons
  }
  fetchSingleSchool = async (schoolId: string) => {
    const schoolQuery = new Parse.Query(School)
      .equalTo('objectId', schoolId)
      .include('school_settings')

    const school = await schoolQuery.first()
    if (school) {
      this.setSchool(school)
      return school
    }
  }

  editDefaultKidFolders = async (folder: DefaultKidFolders, school: School) => {
    const settings = await school.school_setting.fetch()
    const defaultKidFolders = settings.attributes.document_default_kid_folders
    if (defaultKidFolders) {
      settings.set(
        'document_default_kid_folders',
        defaultKidFolders?.map((fold: DefaultKidFolders) => {
          if (fold.id === folder.id) {
            return folder
          }
          return fold
        }),
      )

      await settings.save()
      this.setSchool(school)
    }
  }

  createDefaultKidFolder = async (
    folder: DefaultKidFolders,
    school: School,
  ) => {
    const settings = await school.school_setting.fetch()
    let defaultKidFolders = settings.attributes.document_default_kid_folders
    if (!defaultKidFolders) {
      defaultKidFolders = []
    }
    defaultKidFolders.push(folder)
    if (defaultKidFolders) {
      settings.set('document_default_kid_folders', defaultKidFolders)

      await settings.save()
      this.setSchool(school)
    }
  }

  archiveDefaultKidFolder = async (
    folder: DefaultKidFolders,
    school: School,
    deleteFilesInFolder: boolean,
  ) => {
    const settings = await school.school_setting.fetch()
    const defaultKidFolders = settings.attributes.document_default_kid_folders
    if (defaultKidFolders) {
      settings.set(
        'document_default_kid_folders',
        defaultKidFolders?.map((fold: DefaultKidFolders) => {
          if (fold.id === folder.id) {
            return folder
          }
          return fold
        }),
      )

      if (deleteFilesInFolder) {
        await this.deleteFilesInArchivedDefaultFolder(
          school.id,
          school.schoolName,
          folder.id,
        )
      }

      await settings.save()
      this.setSchool(school)
    }
  }

  deleteFilesInArchivedDefaultFolder = async (
    schoolId: string,
    schoolName: string,
    folderId: string,
  ) => {
    const documentsQuery = new Parse.Query(Document)
    documentsQuery.equalTo(Document.Fields.school, schoolName)
    documentsQuery.equalTo(
      Document.Fields.school_pointer,
      School.createWithoutData(schoolId),
    )
    documentsQuery.equalTo('in_default_kid_folder', folderId)

    const documents = await documentsQuery.find()

    for (const doc of documents) {
      await doc.destroy()
    }
  }

  setAbsenceNoLock = async (school: School, value: boolean) => {
    const settings = await school.school_setting.fetch()
    settings.set('absence_no_locking', value)
    await settings.save()
    this.setSchool(school)
  }

  setAbsenceHasReportBack = async (school: School, value: boolean) => {
    const settings = await school.school_setting.fetch()
    settings.set('absence_has_report_back', value)
    await settings.save()
    this.setSchool(school)
  }
  setLikeComment = async (school: School, value: boolean, type: string) => {
    const currentUser = userStore.currentUser
    const settings = await school.school_setting.fetch()
    let likeComment: LikeComment | undefined = settings.attributes.like_comment

    if (!likeComment) {
      likeComment = {
        port_commentable: false,
        port_emotable: false,
        last_modified_userId: 'oqdqRm0FqT',
        blog_emotable: false,
        last_modified_date: new Date().toISOString(),
        blog_commentable: false,
      }
    }
    if (type === 'port_commentable') {
      likeComment.port_commentable = value
    } else if (type === 'port_emotable') {
      likeComment.port_emotable = value
    } else if (type === 'blog_commentable') {
      likeComment.blog_commentable = value
    } else if (type === 'blog_emotable') {
      likeComment.blog_emotable = value
    }

    likeComment.last_modified_userId = currentUser?.id ?? ''
    likeComment.last_modified_date = new Date().toISOString()

    settings.set('like_comment', likeComment)
    await settings.save()
    this.setSchool(school)
  }
  setNewStartingHour = async (
    school: School,
    end: string | null,
    start: string | null,
  ) => {
    let newOpeningHours

    if (start && end) {
      const formattedStart = start
      const formattedEnd = end

      if (start === '' || start === null) {
        newOpeningHours = undefined
      } else {
        newOpeningHours = {
          start: formattedStart,
          end: formattedEnd,
        }
      }
    } else {
      newOpeningHours = undefined
    }

    const settings = await school.school_setting.fetch()
    if (newOpeningHours) {
      settings.set('preschool_open_times', newOpeningHours)
    } else {
      settings.unset('preschool_open_times')
    }
    await settings.save()
    this.setSchool(school)
  }

  setAdminCreateFolder = async (school: School, value: boolean) => {
    const settings = await school.school_setting.fetch()

    settings.set('doc_staff_create_folder', value)
    await settings.save()
    this.setSchool(school)
  }
  addExternalLink = async (
    school: School,
    link: string,
    name: string,
    visibility: boolean,
  ): Promise<boolean> => {
    try {
      const settings = await school.school_setting.fetch()
      let linkObject: Link[] | undefined =
        settings.attributes.document_external_links

      if (!linkObject) {
        linkObject = []
      }
      if (!link.startsWith('https://')) {
        link = 'https://' + link
      }
      const newObject: Link = {
        name: name,
        visibility: visibility ? 0 : 400,
        link: link,
      }
      linkObject.push(newObject)
      settings.set('document_external_links', linkObject)
      await settings.save()
      this.setSchool(school)
      return true // Return true om operationen lyckas
    } catch (error) {
      console.error(error)
      return false // Return false om det uppstår ett fel
    }
  }

  deleteExternalLink = async (school: School, index: number) => {
    const settings = await school.school_setting.fetch()
    let linkObject: Link[] | undefined =
      settings.attributes.document_external_links

    if (!linkObject) {
      linkObject = []
      return false
    }

    linkObject.splice(index, 1)
    settings.set('document_external_links', linkObject)
    await settings.save()
    this.setSchool(school)
  }
  updateExternalLink = async (
    school: School,
    link: string,
    name: string,
    visibility: boolean,
    index: number,
  ) => {
    const settings = await school.school_setting.fetch()
    let linkObject: Link[] | undefined =
      settings.attributes.document_external_links

    if (!linkObject || index < 0 || index >= linkObject.length) {
      return false
    }

    // Always prepend https:// to the link
    if (!link.startsWith('https://')) {
      link = 'https://' + link
    }

    const updatedLink: Link = {
      name: name,
      visibility: visibility ? 0 : 400,
      link: link,
    }

    linkObject[index] = updatedLink

    settings.set('document_external_links', linkObject)
    await settings.save()
    this.setSchool(school)
  }

  setAdminAllowCheckInOutParent = async (school: School, value: boolean) => {
    const updateSchool = await school.fetch()
    updateSchool.set('parentCheckInOutLocked', value)
    await updateSchool.save()
    this.setSchool(updateSchool)
  }
  setAdminAllowCustomTimeEdit = async (school: School, value: boolean) => {
    const updateSchool = await school.fetch()
    updateSchool.set('custom_check_in_out_time', value)
    await updateSchool.save()
    this.setSchool(updateSchool)
  }
  setAdminAllowMassCheckInOut = async (school: School, value: boolean) => {
    const settings = await school.school_setting.fetch()
    settings.set('allow_mass_kid_check', value)
    await settings.save()
    this.setSchool(school)
  }
  setAdminAllowPushInOutCheck = async (school: School, value: boolean) => {
    const settings = await school.school_setting.fetch()
    settings.set('kid_check_push_optional', value)
    await settings.save()
    this.setSchool(school)
  }
  getContactPersons = async (schoolId: string) => {
    const contactPersonsQuery = new Parse.Query(Department)
      .equalTo('school_pointer', School.createWithoutData(schoolId))
      .equalTo('isDep', false)

    const contactPersons = await contactPersonsQuery.find()

    this.setContactPersons(contactPersons)
  }
  createNewContact = async (schoolId: string, values: any) => {
    const newContact = new Department()
    const currentUserRole = userStore.currentUserRole

    newContact.workTitle = values.workTitle
    newContact.klassName = values.klassName
    newContact.lastName = values.lastName
    newContact.teleNumber = values.teleNumber
    newContact.otherMobNum = values.otherMobNum
    newContact.email = values.email
    newContact.adress = values.adress
    newContact.city = values.city

    newContact.areaCode = values.areaCode

    newContact.contactPic = values.contactPic

    newContact.contactPic_thumb = values.contactPic_thumb
    newContact.school_pointer = currentUserRole!.school_pointer
    newContact.school = currentUserRole!.school

    newContact.set('isDep', false)
    await newContact.save()
  }

  deleteContact = async (contact: Department) => {
    await contact?.destroy()
  }

  editContactPerson = async (
    contact: Department,
    //file: Parse.File,
    //type: string,
  ) => {
    //let contactToEdit = await contact.fetch()
    //if (type === 'contactPic') {
    //contactToEdit.set('contactPic', file)
    //contactToEdit.set('contactPic_thumb', file)
    await contact.save()
    //}
  }

  createNewDepartment = async (schoolId: string, values: any) => {
    const newDepartment = new Department()

    const currentUserRole = userStore.currentUserRole

    newDepartment.set('klassName', values.klassName)
    newDepartment.set('teleNumber', values.teleNumber)
    newDepartment.set('otherMobNum', values.otherMobNum)
    newDepartment.set('email', values.email)
    newDepartment.set('adress', values.adress)
    newDepartment.set('city', values.city)
    newDepartment.set('areaCode', values.adress)
    newDepartment.set('contactPic', values.contactPic)
    newDepartment.set('contactPic_thumb', values.contactPic)
    newDepartment.set('school_pointer', currentUserRole?.school_pointer)
    newDepartment.set('school', currentUserRole?.school)

    newDepartment.set('isDep', true)
    await newDepartment.save()
  }
  editDepartment = async (
    department: Department,
    //file: Parse.File,
    //type: string,
  ) => {
    //let contactToEdit = await contact.fetch()
    //if (type === 'contactPic') {
    //contactToEdit.set('contactPic', file)
    //contactToEdit.set('contactPic_thumb', file)
    await department.save()
    //}
  }
  createDepartment = async (schoolId: string, values: any) => {
    const newDepartment = new Department()

    newDepartment.set('klassName', values.klassName)
    newDepartment.set('teleNumber', values.teleNumber)
    newDepartment.set('otherMobNum', values.otherMobNum)
    newDepartment.set('email', values.email)
    newDepartment.set('adress', values.adress)
    newDepartment.set('city', values.city)
    newDepartment.set('areaCode', values.adress)
    newDepartment.set('contactPic', values.contactPic)
    newDepartment.set('contactPic_thumb', values.contactPic_thumb)

    newDepartment.set('isDep', true)
    await newDepartment.save()
  }
  deleteDepartment = async (department: Department) => {
    await Parse.Cloud.run('deleteDepartmentsAndRelatedThings', {
      schoolName: department.school_pointer.schoolName,
      departmentId: department.id,
      sendPush: true,
    })
  }
}

export const schoolStore = (() => {
  return new SchoolStore()
})()
export const SchoolStoreContext: React.Context<SchoolStore> =
  React.createContext(schoolStore)
