import React from 'react'

const Base64Image = ({
  base64,
  altText,
}: {
  base64: string
  altText: string
}) => {
  // Convert Base64 string to a format that can be used in an <img> src attribute
  const svgString = atob(base64) // Decode Base64
  const svgUrl = `data:image/svg+xml;base64,${btoa(svgString)}` // Re-encode to Base64 if needed

  return <img src={svgUrl} alt={altText} />
}

export default Base64Image
