import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from '../../components/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { DepartmentStoreContext } from '../../store/departmentStore'
import { Spinner } from '../../components/Spinner'
import { UserStoreContext } from '../../store/userStore'
import Page from '../../components/Page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressBook,
  faCircleInfo,
  faFileArrowDown,
} from '@fortawesome/free-solid-svg-icons'
import Multiselect from '../../components/Multiselect'
import { Department } from '../../Models/Department'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useTranslation } from 'react-i18next'
import { CSVDownload, CSVLink } from 'react-csv'
import { ContactListStoreContext } from '../../store/contactListStore'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import ColorCard from '../../components/ColorCard'
import MultiSelectOpen from '../../components/MultiSelectOpen'
import { Loading } from '../../components/Loading'
import moment from 'moment'

export const ContactLists: React.FC = observer(() => {
  const { setTitle } = useContext(NavigationStoreContext)
  let { schoolId } = useParams()
  /*const [selectedDepartments, setSelectedDepartments] = useState<Department[]>(
    [],
  )*/
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [exportData, setExportData] = useState<[]>([])
  const navigate = useNavigate()
  const { fetchUserRole, currentUserRole, savedUserRoleId } =
    useContext(UserStoreContext)
  const {
    fetchDepartments,
    departments,
    setSelectedDepartments,
    selectedDepartments,
  } = useContext(DepartmentStoreContext)
  const { fetchContactList, contactList } = useContext(ContactListStoreContext)

  const exportFilterChildren = [
    { label: t('documents.name'), key: 'kid-name' },
    { label: t('general.socialNr'), key: 'kid-kid_pers_id_num' },
    { label: t('general.birthDate'), key: 'kid-birth_date' },
    { label: t('general.startDate'), key: 'kid-start_date' },
    { label: t('general.department'), key: 'kid-department' },
  ]

  const exportFilterCaregiver = [
    { label: t('documents.name'), key: 'cg-name' },
    {
      label: t('general.socialNr'),
      key: 'cg-nin',
    },
    { label: t('contactList.mail'), key: 'cg-email' },
    { label: t('contactList.phone'), key: 'cg-primary_phone' },
    { label: t('contactList.adress'), key: 'cg-street_address' },
  ]

  interface Filter {
    key: string
    label: string
  }

  interface Filters extends Array<Filter> {}
  const [doPrint, setDoPrint] = useState(false)
  const initialFilter = exportFilterChildren
  const [selectedFilter, setSelectedFilter] = useState<Filters>([])

  useEffect(() => {
    if (schoolId) {
      setLoading(true)
      fetchDepartments(schoolId).finally(() => {
        setLoading(false)
      })
    }
    if (savedUserRoleId) {
      setLoading(true)
      fetchUserRole(savedUserRoleId).finally(() => {
        setLoading(false)
      })
    }
  }, [schoolId, savedUserRoleId])

  useEffect(() => {
    if (t) {
      setTitle(t('menu.contactlists'))
    }
  }, [t])

  useEffect(() => {
    if (schoolId) {
      setLoading(true)
      fetchContactList(schoolId, selectedDepartments, selectedFilter).finally(
        () => {
          setLoading(false)
        },
      )
    }
  }, [schoolId, selectedDepartments, selectedFilter])

  const months: string | string[] = []

  return (
    <Page
      naked
      clearFilter={() => {
        setSelectedDepartments([])
      }}
      filterCount={selectedDepartments?.length}
      filter={
        <div className="flex w-full justify-center">
          <MultiSelectOpen
            onChange={(e) => {
              setSelectedDepartments(e)
            }}
            items={departments as Department[]}
            initialSelectedItems={selectedDepartments}
            itemVariableKey={'klassName'}
            placeholder={t('general.department')}
          />
        </div>
      }
      topBar={
        <>
          {currentUserRole &&
          contactList &&
          !loading &&
          currentUserRole.role_type > 350 ? (
            <CSVLink
              headers={selectedFilter}
              data={contactList}
              target="_blank"
              filename={'contactlist_' + moment().format('YYYY-MM-DD') + '.csv'}
              id="#test"
            >
              <Button
                variant="disableBlue"
                size="md"
                disabled={selectedFilter?.length ? false : true}
                icon={faFileArrowDown}
                label={t('contactList.exportCSV')}
                onClick={() => {
                  /*setLoading(true)
                  setDoPrint(true)
                  schoolId &&
                    fetchContactList(
                      schoolId,
                      selectedDepartments,
                      selectedFilter,
                    )
                      .finally(() => {
                        if (contactList) {
                          setExportData(contactList)
                        }
                        setLoading(false)
                        setDoPrint(false)
                        var link = document.getElementById('test')
                        link?.click()
                      })
                      .catch((e) => {
                        // TODO: Error meddelande här
                        setExportData([])
                        console.log('error', e)
                      })*/
                }}
              />
            </CSVLink>
          ) : (
            <Button
              variant="disableBlue"
              size="md"
              disabled={true}
              icon={faFileArrowDown}
              label={t('contactList.exportCSV')}
              onClick={() => {
                /*setLoading(true)
            setDoPrint(true)
            schoolId &&
              fetchContactList(
                schoolId,
                selectedDepartments,
                selectedFilter,
              )
                .finally(() => {
                  if (contactList) {
                    setExportData(contactList)
                  }
                  setLoading(false)
                  setDoPrint(false)
                  var link = document.getElementById('test')
                  link?.click()
                })
                .catch((e) => {
                  // TODO: Error meddelande här
                  setExportData([])
                  console.log('error', e)
                })*/
              }}
            />
          )}
        </>
      }
    >
      <ColorCard
        title={t('contactList.downloadContactList')}
        bgColor="bg-contactsMain"
      >
        <div className="p-10 space-y-4 ">
          <h2 className="px-12 pb-6 font-normal text-sm text-gray-500 italic">
            <FontAwesomeIcon
              className=" mr-2 text-neutral-400"
              icon={faCircleInfo}
            />
            {t('contactList.infoText')}
          </h2>
          {/*<Loading loading={loading} />*/}

          {/*loading && <Loading />*/}
          <div className="flex flex-wrap p-3   text-2xl">
            <h3 className="w-full text-gray-800 mb-4 font-bold">
              {t('general.kids')}
            </h3>
            <div className="flex w-full text-sm italic text-gray-400 font-medium">
              {t('contactList.markTextKid')}
            </div>
            <div className="flex flex-wrap  sm:justify-start justify-center gap-3 mt-4">
              {exportFilterChildren.map((filter) => {
                if (
                  currentUserRole &&
                  !currentUserRole.get('staff_sensetive_info_bool') &&
                  filter.key == 'kid-kid_pers_id_num'
                ) {
                  return null
                }
                return (
                  <Button
                    variant={
                      selectedFilter.find((selFil) => selFil.key == filter.key)
                        ? 'fullGreen'
                        : 'fullWhite'
                    }
                    size="md"
                    label={filter.label}
                    onClick={() => {
                      if (
                        selectedFilter.find(
                          (selFil) => selFil.key == filter.key,
                        )
                      ) {
                        setSelectedFilter((previous) =>
                          previous.filter((fil) => fil.key !== filter.key),
                        )
                      } else {
                        setSelectedFilter([...selectedFilter, filter])
                      }
                    }}
                  />
                )
              })}
            </div>
          </div>
          <hr></hr>
          <div className="flex flex-wrap justify-start p-3   text-2xl">
            <h3 className="w-full mb-4 font-bold text-gray-800">
              {t('documents.parent')}
            </h3>
            <div className="flex text-sm w-full italic text-gray-400 font-medium">
              {t('contactList.markTextParent')}
            </div>

            <div className="flex mt-4 flex-wrap gap-3 sm:justify-start justify-center">
              {exportFilterCaregiver.map((filter) => {
                if (filter.key === 'cg-nin') {
                  if (
                    currentUserRole &&
                    currentUserRole.role_type >= USER_ROLE_TYPE.ADMIN
                  ) {
                  } else {
                    return null
                  }
                }

                return (
                  <Button
                    variant={
                      selectedFilter.find((selFil) => selFil.key == filter.key)
                        ? 'fullYellow'
                        : 'fullWhite'
                    }
                    size="md"
                    label={filter.label}
                    onClick={() => {
                      if (
                        selectedFilter.find(
                          (selFil) => selFil.key == filter.key,
                        )
                      ) {
                        setSelectedFilter((previous) =>
                          previous.filter((fil) => fil.key !== filter.key),
                        )
                      } else {
                        setSelectedFilter([...selectedFilter, filter])
                      }
                    }}
                  />
                )
              })}
            </div>
          </div>
          {/*!loading &&
            contactList &&
            currentUserRole &&
            doPrint &&
            currentUserRole.role_type > USER_ROLE_TYPE.PARENT && (
              <CSVDownload
                headers={selectedFilter}
                data={contactList}
                target="_blank"
                filename={
                  'contactlist_' + moment().format('YYYY-MM-DD') + '.csv'
                }
              />
              )*/}
        </div>
      </ColorCard>
    </Page>
  )
})
