// EmptyPage.js
import { faPen, faUsers } from '@fortawesome/free-solid-svg-icons'
import {
  faGrid,
  faNewspaper,
  faSquare4,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faChartPie,
  faChildReaching,
  faClock,
  faCommentMedical,
  faContactBook,
  faContainerStorage,
  faFile,
  faFileLines,
  faFolder,
  faGrid2,
  faLock,
  faPlus,
  faTag,
  faUserCheck,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons'
import React, { useContext, useEffect, useRef, useState } from 'react'
import AdminCard from './components/AdminCard'
import Card from '../../../components/Card'
import ColorCard from '../../../components/ColorCard'
import Page from '../../../components/Page'
import ModalRegular from '../../../components/ModalRegular'
import { Button } from '../../../components/Button'
import PhoneInput from 'react-phone-input-2'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { Slider } from '../../../components/Slider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DepartmentCard from '../deparments/components/DepartmentCard'
import ContactCard from '../contact/components/ContactCard'
import StatisticCard from '../statistics/components/StatisticCard'
import classNames from 'classnames'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'
import { t } from 'i18next'
import ContactModal from '../contact/contactModal'
import StatisticModal from '../statistics/statisticModal'
import TagsModal from '../tags/TagsModal'
import { AbsenceModalAdmin } from '../absence/AbsenceModal'
import DepartmentsModal from '../deparments/departmentsModal'
import BloggAndPortfolio from '../bloggAndPortfolio/bloggAndPortfolioModal'
import BloggAndPortfolioModal from '../bloggAndPortfolio/bloggAndPortfolioModal'
import { NavigationStoreContext } from '../../../store/navigationStore'
import { UserStoreContext } from '../../../store/userStore'

export const Admin: React.FC<{ onClick?: any }> = ({ onClick }) => {
  const [showCreateSchoolAccount, setShowCreateSchoolAccount] = useState(false)
  const [showDepartments, setShowDepartments] = useState(false)
  const [showTaggs, setShowTaggs] = useState(false)
  const [showBlogg, setShowBlogg] = useState(false)
  const [showContact, setShowContact] = useState(false)
  const [showAddContact, setShowAddContact] = useState(false)
  const [showAbsence, setShowAbsence] = useState(false)
  const [showStatistic, setShowStatistic] = useState(false)
  const { departments } = useContext(DepartmentStoreContext)
  const [expanded, setExpanded] = useState(false)
  const [allowComments, setAllowComments] = useState(false)

  const [showSave, setShowSave] = useState(false)

  const handleCheckboxChange = (checked: boolean) => {
    setShowSave(checked)
  }
  const titleNameInputRef = useRef<HTMLInputElement>(null)

  const nameInputRef = useRef<HTMLInputElement>(null)

  const surNameInputRef = useRef<HTMLInputElement>(null)
  const numberInputRef = useRef<HTMLInputElement>(null)
  const departmentNameInputRef = useRef<HTMLInputElement>(null)
  const numberToDepartmentInputRef = useRef<HTMLInputElement>(null)
  const otherNumberInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const adressInputRef = useRef<HTMLTextAreaElement>(null)

  const [touched, setTouched] = useState(false)

  const handleImageClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  const focusInput = (
    e: React.MouseEvent,
    inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e.stopPropagation()
    inputRef.current?.focus()
  }
  const [fieldsVisible, setFieldsVisible] = useState(false)
  const [animating, setAnimating] = useState(false)
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  const handleSliderClick = () => {
    setAllowComments(!allowComments)
  }

  const navigate = useNavigate()

  const handleBoxClick = () => {
    setExpanded(!expanded)
  }
  const { schoolId } = useParams()
  const { setTitle } = useContext(NavigationStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  useEffect(() => {
    setTitle(t('admin.admin'))
  }, [t])

  return (
    <Page naked>
      <ColorCard
        bgColor="bg-adminMain p-4 justify-center flex"
        title={t('admin.admin')}
      >
        <div className="w-full flex ">
          <div className="flex flex-wrap justify-center gap-4">
            <AdminCard
              iconClassName="text-pink-200"
              icon={faUserPlus}
              title={t('admin.inviteHandleUser')}
              className="hover:bg-red-50"
              onClick={() => {
                navigate(`/admin/${schoolId}/invite`)
                if (onClick) {
                  onClick()
                }
              }}
            ></AdminCard>
            {currentUserRole?.school_pointer.multi_user ? (
              <AdminCard
                icon={faUsers}
                iconClassName="text-green-600"
                className="hover:bg-green-50 opacity-50"
                title={t('admin.handleSchoolAcc')}
                onClick={() => {
                  Swal.fire({
                    //title: 'Are you sure?',
                    text: t('events.eventAlarmNotImp'),
                    icon: 'warning',
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                  })
                  /*
                  navigate(`/admin/${schoolId}/handleSchoolAcc`)
                  if (onClick) {
                    onClick()
                  }*/
                }}
              ></AdminCard>
            ) : (
              <AdminCard
                icon={faUsers}
                iconClassName="text-green-600"
                className="hover:bg-green-50 opacity-50"
                title={t('admin.createSchoolAcc')}
                onClick={() => {
                  Swal.fire({
                    //title: 'Are you sure?',
                    text: t('events.eventAlarmNotImp'),
                    icon: 'warning',
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                  })
                }}
              ></AdminCard>
            )}

            <AdminCard
              iconClassName="text-blue-300"
              icon={faGrid2}
              className="hover:bg-blue-50"
              title={t('admin.departments')}
              onClick={() => setShowDepartments(true)}
            ></AdminCard>
            <DepartmentsModal
              onClose={() => setShowDepartments(false)}
              isOpen={showDepartments}
            />
            <AdminCard
              iconClassName="text-yellow-300"
              icon={faContactBook}
              className="hover:bg-yellow-50"
              title={t('ourKids.contacts')}
              onClick={() => setShowContact(true)}
            ></AdminCard>
            <ContactModal
              onClose={() => setShowContact(false)}
              isOpen={showContact}
            />
            <AdminCard
              iconClassName="text-purple-300"
              icon={faChildReaching}
              className="hover:bg-purple-50"
              title={t('general.kids')}
              onClick={() => {
                navigate(`/admin/${schoolId}/child`)
                if (onClick) {
                  onClick()
                }
              }}
            ></AdminCard>
            <AdminCard
              iconClassName="text-orange-300"
              icon={faClock}
              className="hover:bg-orange-50"
              title={t('admin.attendanceSchedule')}
              onClick={() => {
                navigate(`/admin/${schoolId}/attendance`)
                if (onClick) {
                  onClick()
                }
              }}
            ></AdminCard>
            <AdminCard
              iconClassName="text-red-300"
              icon={faCommentMedical}
              className="hover:bg-red-50"
              title={t('absence.absence')}
              onClick={() => setShowAbsence(true)}
            ></AdminCard>
            <AbsenceModalAdmin
              isOpen={showAbsence}
              onClose={() => {
                setShowAbsence(false)
              }}
            />
            <AdminCard
              iconClassName="text-lime-300"
              icon={faTag}
              className="hover:bg-lime-50"
              title={t('general.tags')}
              onClick={() => setShowTaggs(true)}
            ></AdminCard>
            <TagsModal
              onClose={() => setShowTaggs(false)}
              isOpen={showTaggs}
            ></TagsModal>
            <AdminCard
              iconClassName="text-gray-800"
              icon={faFileLines}
              className="hover:bg-gray-50"
              title={t('admin.bloggPortfolio')}
              onClick={() => setShowBlogg(true)}
            ></AdminCard>
            <BloggAndPortfolioModal
              onClose={() => {
                setShowBlogg(false)
              }}
              isOpen={showBlogg}
            />
            <AdminCard
              iconClassName="text-blue-200"
              icon={faFolder}
              className="hover:bg-blue-50"
              title={t('menu.documents')}
              onClick={() => {
                navigate(`/admin/${schoolId}/documents`)
                if (onClick) {
                  onClick()
                }
              }}
            ></AdminCard>
            {currentUserRole?.school_pointer.event3Mod ? (
              <AdminCard
                iconClassName="text-blue-200"
                icon={faNewspaper}
                className="hover:bg-blue-50"
                title={t('menu.events')}
                onClick={() => {
                  navigate(`/admin/${schoolId}/eventsAdmin`)
                  if (onClick) {
                    onClick()
                  }
                }}
              ></AdminCard>
            ) : null}
          </div>
        </div>
      </ColorCard>
    </Page>
  )
}

export default Admin
