import classNames from 'classnames'
import moment from 'moment'
import { title } from 'process'
import React, { useState } from 'react'

interface Props extends React.PropsWithChildren {
  className?: string
  value?: string
  onChange?: string
  onClick?: () => void
  active?: boolean
  opacity?: boolean
  border?: boolean
}

export const CalendarCell: React.FC<Props> = ({
  className,
  children,
  value,
  onChange,
  onClick,
  active,
  opacity,
  border,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'p-2 sm:p-3 flex items-center justify-center cursor-pointer select-none text-sm relative',
        active
          ? className +
              ' bg-blue-100 rounded-full border-none cursor-pointer text-blue-800 font-bold'
          : className,
        opacity ? 'opacity-60 cursor-not-allowed' : '',
        border && 'border',
      )}
    >
      {children}
      {value}
      {onChange}
    </div>
  )
}
