import moment from 'moment'
import React from 'react'
import { getDateForTranslatation } from '../views/events3/Events3'
import { getDayForTranslatation } from '../views/events/components/EventsInfo'

interface Props extends React.PropsWithChildren {
  date: string
}

export const CalendarDateIcon: React.FC<Props> = ({ date }) => {
  return (
    <div className="flex flex-col w-10 bg-white rounded-xl">
      <div className="flex justify-center text-xs">
        {getDayForTranslatation(moment(date).day())}
      </div>
      <div className="flex justify-center font-bold text-md">
        {moment(date).format('DD')}
      </div>
      <div className="flex justify-center text-xs">
        {getDateForTranslatation(moment(date).format('MM'))}
      </div>
    </div>
  )
}
