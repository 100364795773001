import { useContext, useState } from 'react'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'
import { Note } from '../../../Models/Note'
import { NoteComment } from '../../../Models/NoteComment'
import { NotesOverview } from './NotesOverview'
import { ImageCarousel, MediaItem } from '../../../components/ImageCarousel'
import {
  faBoxArchive,
  faEllipsisVertical,
  faStar,
  faTrashCan,
  faArchive,
  faLock,
  faVolume,
} from '@fortawesome/pro-solid-svg-icons'
import { FullscreenModal } from '../../portfolio/components/FullScreenModal'
import { faStar as farFaStar } from '@fortawesome/pro-regular-svg-icons'
import Swal from 'sweetalert2'
import { t } from 'i18next'
import { ButtonDropdown } from '../../../components/Dropdown'
import { UserStoreContext } from '../../../store/userStore'
import { NoteStoreContext } from '../../../store/noteStore'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { NoteEdit } from './NoteEdit'
import DOMPurify from 'dompurify'
import TimeStamp from '../../../components/TimeStamp'
import { getFullNameFromUser } from '../../../utils/parseUtils'
export const getDateForTranslatation = (months: string) => {
  switch (months) {
    case '01':
      return t('months.january')
    case '02':
      return t('months.february')
    case '03':
      return t('months.march')
    case '04':
      return t('months.april')
    case '05':
      return t('months.may')
    case '06':
      return t('months.june')
    case '07':
      return t('months.july')
    case '08':
      return t('months.august')
    case '09':
      return t('months.september')
    case '10':
      return t('months.october')
    case '11':
      return t('months.november')
    case '12':
      return t('months.december')
  }
}

export interface Props {
  onImageSelected?: any
  note: Note
  isAdmin: boolean
  isTeacher: boolean
  onSave: () => void
  noteMain?: NoteComment
  showArchived: boolean
}

export const NotesRow = ({
  note,
  isAdmin,
  isTeacher,
  onSave,
  showArchived,
}: Props) => {
  const [currentNote, setCurrentNote] = useState<Note | null>(null)
  const [selectedImage, setSelectedImage] = useState('')
  const [showFullScreenModal, setShowFullScreenModal] = useState(false)
  const handleImageClick = (media: MediaItem) => {
    if (media.type === 'image') {
      setSelectedImage(media.src)
      setShowFullScreenModal(true)
    }
  }

  const { currentUser, readOnly } = useContext(UserStoreContext)
  const {
    addFavoritNote,
    removeFavoritNote,
    archiveNote,
    deleteNote,
    read_states,
  } = useContext(NoteStoreContext)
  const [menuRef, togglerRef] = useClickOutside(() => {
    setDropdownVisible(false)
  })

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }
  const [editNote, setEditNote] = useState<Note | null>(null)
  const { t } = useTranslation()
  const isEditable = note.edit_locked
    ? note.notes_owner?.id === currentUser?.id
      ? isTeacher
        ? true
        : false
      : false
    : isTeacher
    ? true
    : false || isAdmin || note.notes_owner?.id === currentUser?.id

  const read_state_exists = read_states?.find((rs) => rs.notes.id === note.id)
  const revisionsMatch =
    note.revision ===
    read_states?.find((rs) => rs.notes.id === note.id)?.read_revision

  const showBlueDot = !read_state_exists ? true : revisionsMatch ? false : true

  return (
    <>
      <div
        className={classNames(
          'relative min-h-[150px] flex flex-col sm:flex-row w-full h-full my-2 border-y shadow border-r rounded-2xl cursor-pointer hover:bg-blue-50',
          note.archive_state ? 'bg-red-100' : null,
        )}
      >
        {showBlueDot ? (
          <div className="flex flex-col justify-between p-4 mr-4 bg-blue-200 w-full sm:min-w-[130px] sm:w-fit sm:rounded-l-2xl rounded-t-2xl sm:rounded-tr-none border-r items-center">
            <div className="text-center">
              <div className="capitalize">
                {getDateForTranslatation(moment(note.createdAt).format('MM'))}
              </div>
              <div className="text-sm">
                {moment(note.createdAt).format('DD')}
              </div>
              <div className="text-xs text-gray-500">
                {moment(note.createdAt).format('YYYY')}
              </div>
            </div>
            <div className="text-xs text-gray-500 text-center">
              {getFullNameFromUser(note.notes_owner)}
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-between p-4 mr-4 bg-red-200 w-full sm:min-w-[130px] sm:w-fit sm:rounded-l-2xl rounded-t-2xl sm:rounded-tr-none border-r items-center">
            <div className="text-center">
              <p className="text-xs">
                <>{t('notes.rowCreated')}</>
              </p>
              <div className="capitalize">
                {getDateForTranslatation(moment(note.createdAt).format('MM'))}
              </div>
              <div className="text-lg">
                {moment(note.createdAt).format('DD')}
              </div>
              <div className="text-xs text-gray-600">
                {moment(note.createdAt).format('YYYY')}
              </div>
            </div>
            <div className="text-xs text-gray-500 text-center">
              {getFullNameFromUser(note.notes_owner)}
            </div>
          </div>
        )}

        <div
          onClick={() => {
            setCurrentNote(note)
          }}
          className="flex flex-col w-full justify-between p-4"
        >
          <div>
            <div className="font-bold flex flex-row gap-x-4 text-lg">
              {/*{showBlueDot && (
                <div className="h-4 w-4 border bg-blue-200 rounded-full"></div>
              )}*/}
              {note.notes_topic}{' '}
              {note?.notes_comments?.find(
                (comment: NoteComment) => comment.attributes.voice_memo,
              ) && (
                <div>
                  <FontAwesomeIcon
                    className="text-eventsMain"
                    icon={faVolume}
                  />
                </div>
              )}
            </div>
            <div>
              <div
                className="font-normal line-clamp-3 whitespace-pre-wrap"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    note.notes_comments?.find(
                      (comment: NoteComment) => comment.attributes.is_main,
                    )?.attributes.comment,
                  ),
                }}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="mr-2">
              {' '}
              <FontAwesomeIcon
                icon={faComment}
                className="text-blue-500 cursor-pointer "
                onClick={() => {}}
              />{' '}
              (
              {
                note.notes_comments
                  ?.filter((comment) => !comment.attributes.is_main)
                  ?.filter((comment: NoteComment) =>
                    showArchived ? true : !comment.attributes.archive_state,
                  )?.length
              }
              )
            </div>

            <div>
              {/*<ReadStatus
                readRevision={readRevision}
                totalRecipients={totalRecipients}
                note={new Note()}
                  />*/}
            </div>
            {/*TODO visa olästa*/}
          </div>
        </div>

        <div className="flex flex-row sm:flex-col gap-y-4  justify-center items-end m-4 sm:m-0 sm:mr-16">
          <div className=" flex flex-col  w-full gap-y-2 mr-6 rounded-2xl overflow-hidden hover:scale-[102%]">
            {note.notes_comments?.find(
              (comment: NoteComment) => comment.attributes.is_main,
            )?.attributes.picture && (
              <div className="flex">
                {' '}
                <ImageCarousel
                  className="max-w-[80px] min-w-[80px] max-h-[80px] min-h-[80px] rounded-2xl"
                  arrowSmall
                  images={[
                    {
                      type: 'image',
                      src: note.notes_comments?.find(
                        (comment: NoteComment) => comment.attributes.is_main,
                      )?.attributes.picture._url,
                    },
                  ]}
                  imageOnClick={handleImageClick}
                />
              </div>
            )}
            <FullscreenModal
              src={selectedImage}
              showModal={showFullScreenModal}
              hideModal={() => {
                setShowFullScreenModal(false)
              }}
            />
          </div>
        </div>
        {isEditable && (
          <div className="relative top-3 -right-1 font-normal flex flex-col-reverse">
            <div className="flex flex-row absolute top-0 right-4">
              <div className="relative flex">
                {!readOnly?.notes && (
                  <button
                    ref={togglerRef}
                    type="button"
                    className="w-full rounded-md group focus:outline-none "
                    aria-haspopup="true"
                    aria-expanded={dropdownVisible}
                    onClick={toggleDropdown}
                  >
                    <FontAwesomeIcon
                      icon={faEllipsisVertical}
                      className="text-gray-600 sm:text-gray-400 absolute top-1 -right-1 px-4 hover:scale-110 "
                    ></FontAwesomeIcon>
                  </button>
                )}

                {dropdownVisible && (
                  <div
                    className="absolute right-2 z-50 mx-3 origin-top divide-y w-[245px] top-0"
                    style={{ display: dropdownVisible ? 'block' : 'none' }}
                    ref={menuRef}
                  >
                    <ButtonDropdown.Menu>
                      <ButtonDropdown.Section>
                        {!note.archive_state && (
                          <>
                            {isTeacher && !note.shared && (
                              <ButtonDropdown.Button
                                label={t('notes.edit')}
                                icon={faPen}
                                onClick={() => setEditNote(note)}
                              ></ButtonDropdown.Button>
                            )}

                            <ButtonDropdown.Button
                              icon={faBoxArchive}
                              label={t('notes.archive')}
                              className="text-blue-300"
                              onClick={(e) => {
                                e.stopPropagation()

                                Swal.fire({
                                  //title: t('documents.areYouSureRemoveFile'),
                                  text: t('notes.alertArchive'),
                                  icon: 'warning',
                                  showCancelButton: true,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  confirmButtonText: t('general.yes'),
                                  cancelButtonText: t('general.cancel'),
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    archiveNote(note)
                                      .catch((e) => {
                                        // gör swal här istället
                                        Swal.fire({
                                          //title: 'Are you sure?',
                                          text: t('events.eventAlarmFail'),
                                          icon: 'warning',
                                          showCancelButton: false,
                                          confirmButtonColor: '#7eb5f4',
                                          cancelButtonColor: '#d33',
                                          confirmButtonText: t('general.yes'),
                                          cancelButtonText: t('general.cancel'),
                                        })
                                        throw e
                                      })
                                      .then(() => {
                                        Swal.fire({
                                          //title: 'Are you sure?',
                                          text: t('statistics.success'),
                                          icon: 'success',
                                          showCancelButton: false,
                                          confirmButtonColor: '#7eb5f4',
                                          cancelButtonColor: '#d33',
                                          confirmButtonText: t('general.ok'),
                                          cancelButtonText: t('general.cancel'),
                                        }).then(async () => {})
                                        onSave()
                                      })
                                      .finally(() => {})
                                  }
                                })
                              }}
                            />
                          </>
                        )}
                        {isAdmin && (
                          <ButtonDropdown.Button
                            icon={faTrashCan}
                            label={t('absence.delete')}
                            className="text-red-700"
                            onClick={(e) => {
                              e.stopPropagation()

                              Swal.fire({
                                //title: 'Are you sure?',
                                text: t('documents.areYouSureRemoveFile'),
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#7eb5f4',
                                cancelButtonColor: '#d33',
                                confirmButtonText: t('general.yes'),
                                cancelButtonText: t('general.cancel'),
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteNote(note)
                                    .catch((e) => {
                                      // gör swal här istället
                                      Swal.fire({
                                        //title: 'Are you sure?',
                                        text: t('events.eventAlarmFail'),
                                        icon: 'warning',
                                        showCancelButton: false,
                                        confirmButtonColor: '#7eb5f4',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: t('general.yes'),
                                        cancelButtonText: t('general.cancel'),
                                      })
                                      throw e
                                    })
                                    .then(() => {
                                      Swal.fire({
                                        //title: 'Are you sure?',
                                        text: t('statistics.success'),
                                        icon: 'success',
                                        showCancelButton: false,
                                        confirmButtonColor: '#7eb5f4',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: t('general.ok'),
                                        cancelButtonText: t('general.cancel'),
                                      }).then(async () => {})
                                      onSave()
                                    })
                                    .finally(() => {})
                                }
                              })
                            }}
                          />
                        )}
                      </ButtonDropdown.Section>
                    </ButtonDropdown.Menu>
                  </div>
                )}
                {/*<div className="text-sm absolute top-40 sm:top-28 -right-1 mr-1 font-bold ">
                {note.revision ? (
                  <div className="text-blue-500">
                    <>{t('notes.seen')}</>
                  </div>
                ) : (
                  <div className="text-red-500">
                    <>{t('notes.notSeen')}</>
                  </div>
                )}
                </div>*/}
              </div>
            </div>
            <div
              className={`text-xs text-gray-400 font-medium absolute bottom-4 ${
                note.edit_locked && !note.shared ? '-right-20' : '-right-20'
              } ${
                moment(note.notes_edited).isSame(moment(), 'day')
                  ? moment().diff(moment(note.notes_edited), 'hours') >= 1
                    ? 'right-minutes-or-hours'
                    : 'right-default'
                  : 'right-default'
              } w-[200px] flex flex-row`}
            >
              {' '}
              <div>
                <div className="pr-1">{t('notes.lastComment')}</div>
                <TimeStamp date={moment(note.notes_edited ?? note.createdAt)} />
              </div>
              {/*<span>
                {moment(note.notes_edited).isSame(moment(), 'day') ? (
                  <>
                    {moment().diff(moment(note.notes_edited), 'hours') >= 1 ? (
                      <>
                        {moment().diff(moment(note.notes_edited), 'hours')}{' '}
                        {moment().diff(moment(note.notes_edited), 'hours') === 1
                          ? t('general.hourAgo')
                          : t('general.hoursAgo')}
                      </>
                    ) : (
                      <>
                        {moment().diff(moment(note.notes_edited), 'minutes')}{' '}
                        {moment().diff(moment(note.notes_edited), 'minutes') ===
                        1
                          ? t('general.minuteAgo')
                          : t('general.minutesAgo')}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {moment(note.notes_edited).format('YYYY')}-
                    {getDateForTranslatation(
                      moment(note.notes_edited).format('MM'),
                    )}
                    -{moment(note.notes_edited).format('DD : HH:mm')}
                  </>
                )}
              </span>*/}
            </div>
          </div>
        )}
        <div className="absolute top-2 right-2 flex">
          <div className="text-lg absolute top-[145px] sm:top-12 right-[9px] ">
            {note.archive_state ? (
              <FontAwesomeIcon icon={faArchive} className="text-red-300" />
            ) : (
              <>
                {note.is_favorite?.find(
                  (userId) => userId === currentUser?.id,
                ) ? (
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-yellow-400 hover:scale-110"
                    onClick={async () => {
                      if (currentUser) {
                        await removeFavoritNote(note, currentUser.id)
                          .catch((e) => {
                            // gör swal här istället
                            Swal.fire({
                              //title: 'Are you sure?',
                              text: t('events.eventAlarmFail'),
                              icon: 'warning',
                              showCancelButton: false,
                              confirmButtonColor: '#7eb5f4',
                              cancelButtonColor: '#d33',
                              confirmButtonText: t('general.yes'),
                              cancelButtonText: t('general.cancel'),
                            })
                            throw e
                          })
                          .then(() => {
                            // gör swal här istället
                            onSave()
                          })
                          .finally(() => {})
                      }
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={farFaStar}
                    className="text-gray-400 hover:scale-110"
                    onClick={async () => {
                      if (currentUser) {
                        await addFavoritNote(note, currentUser.id)
                          .catch((e) => {
                            // gör swal här istället
                            Swal.fire({
                              //title: 'Are you sure?',
                              text: t('events.eventAlarmFail'),
                              icon: 'warning',
                              showCancelButton: false,
                              confirmButtonColor: '#7eb5f4',
                              cancelButtonColor: '#d33',
                              confirmButtonText: t('general.yes'),
                              cancelButtonText: t('general.cancel'),
                            })
                            throw e
                          })
                          .then(() => {
                            // gör swal här istället
                            onSave()
                          })
                          .finally(() => {})
                      }
                    }}
                  />
                )}
              </>
            )}
          </div>
          <div className="absolute top-[210px] sm:top-[100px] right-[12px] flex justify-center">
            {note && note.edit_locked && !note.shared ? (
              <FontAwesomeIcon
                icon={faLock}
                className="text-gray-500"
                title={
                  t('notes.noteLockedTitle') +
                  ' ' +
                  note.notes_owner?.firstName +
                  ' ' +
                  note.notes_owner?.lastName
                }
              />
            ) : null}{' '}
          </div>
        </div>
      </div>
      {editNote && (
        <NoteEdit
          onSave={() => {
            onSave()
            setEditNote(null)
          }}
          onClose={() => {
            setEditNote(null)
          }}
          note={editNote}
          showModal={true}
          kidId={note.kid_owner.id}
        />
      )}
      {currentNote && (
        <NotesOverview
          showModal={true}
          hideModal={() => {
            setCurrentNote(null)
          }}
          onSave={() => {
            onSave()
          }}
          unreadNote={showBlueDot}
          note={currentNote}
          isAdmin={isAdmin}
          showArchived={showArchived}
          isTeacher={isTeacher}
          kid={note.kid_owner}
          noteMain={currentNote.notes_comments?.find(
            (comment: NoteComment) => comment.attributes.is_main,
          )}
          comments={
            (currentNote.notes_comments?.filter(
              (comment: NoteComment) => !comment.attributes.is_main,
            ) as NoteComment[]) ?? []
          }
        />
      )}
    </>
  )
}
