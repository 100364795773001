import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from '../../../components/Button'
import ModalRegular from '../../../components/ModalRegular'
import { DocumentStoreContext } from '../../../store/documentStore'
import { UserStoreContext } from '../../../store/userStore'
import { toJS } from 'mobx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faFolder,
  faChevronDown,
  faFolderOpen,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import {
  NotificationContext,
  NotificationVariant,
} from '../../../App/notification/notificationManger'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import Swal from 'sweetalert2'

export const MoveFileModal: React.FC<{
  documentId?: string
  isFile?: boolean
  onSaved?: any
}> = observer(({ documentId, isFile, onSaved }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const { notify } = useContext(NotificationContext)
  const { moveDocument } = useContext(DocumentStoreContext)
  return (
    <div className={'w-full'}>
      <Button
        size="md"
        className={'w-full'}
        label={t('documents.move')}
        onClick={() => {
          setShowModal(true)
        }}
      />

      <FoldersModal
        documentId={documentId ?? ''}
        setShowModal={setShowModal}
        showModal={showModal}
        loading={loading}
        onSaved={async ({
          type,
          selectedFolder,
        }: {
          type: string
          selectedFolder: any
        }) => {
          if (type.length && documentId) {
            setLoading(true)
            await moveDocument({
              folderId: selectedFolder?.id,
              kidId: selectedFolder?.attributes?.in_kid_folder?.id,
              depId: selectedFolder?.attributes?.in_dep_folder?.id,
              documentId: documentId,
              type: type,
            }).finally(() => {
              setLoading(false)
            })

            /*notify({
              variant: NotificationVariant.OK,
              title: t('general.moved'),
              message: t('documents.docMoved'),
            })*/
            Swal.fire({
              text: t('documents.docMoved'),
              icon: 'success',
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonColor: '#7eb5f4',
              cancelButtonColor: '#d33',
              confirmButtonText: t('general.ok'),
            })
            setShowModal(false)
            onSaved()
          }
        }}
      />
    </div>
  )
})

export const FoldersModal = ({
  documentId,
  onSaved,
  showModal,
  setShowModal,
  loading,
}: {
  documentId: string
  onSaved: any
  showModal: boolean
  loading: boolean
  setShowModal: any
}) => {
  const { getSchoolDocumentFolders, schoolFolders, moveDocument } =
    useContext(DocumentStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  const { notify } = useContext(NotificationContext)
  const [selectedFolder, setSelectedFolder] = useState() as any
  const [type, setType] = useState('')
  useEffect(() => {
    if (currentUserRole?.school_pointer?.id) {
      getSchoolDocumentFolders(currentUserRole?.school_pointer?.id)
    }
  }, [currentUserRole])

  return (
    <ModalRegular
      title={t('documents.move')}
      isOpen={showModal}
      className={'max-w-2xl'}
      onClose={() => {
        setShowModal(false)
      }}
      bgColor="p-4 bg-documentsMain"
      closeColor="white"
    >
      <div className={'pt-10 pb-3 flex flex-col p-4'}>
        {schoolFolders?.my.length ? (
          <Folder
            title={t('documentation.myDocument')}
            onSelect={(folder: any) => {
              setSelectedFolder(folder)
              setType('my')
            }}
            folder={{ id: 'my' }}
            selectedFolder={selectedFolder}
            color={'text-eventsMain'}
            folders={schoolFolders?.my}
          />
        ) : null}
        {schoolFolders?.onlyStaff.length ? (
          <Folder
            folder={{ id: 'only_staff' }}
            onSelect={(folder: any) => {
              setSelectedFolder(folder)
              setType('only_staff')
            }}
            title={t('documentation.onlyStaffDocument')}
            selectedFolder={selectedFolder}
            color={'text-notesMain'}
            folders={schoolFolders?.onlyStaff}
          />
        ) : null}
        {schoolFolders?.school.length ? (
          <Folder
            folder={{ id: 'school' }}
            onSelect={(folder: any) => {
              setSelectedFolder(folder)
              setType('school')
            }}
            title={t('documentation.schoolDocument')}
            selectedFolder={selectedFolder}
            color={'text-classlistMain'}
            folders={schoolFolders?.school}
          />
        ) : null}
        {schoolFolders?.students.length ? (
          <Folder
            folder={{ id: 'students' }}
            onSelect={(folder: any) => {
              setSelectedFolder(folder)
              setType('students')
            }}
            title={t('documentation.kidDocumentation')}
            selectedFolder={selectedFolder}
            color={'text-groupsMain'}
            folders={schoolFolders?.students}
          />
        ) : null}
      </div>
      <div className={'flex w-full justify-end p-4'}>
        <Button
          variant={'fullGreen'}
          label={t('general.save')}
          loading={loading}
          onClick={async () => {
            onSaved({ type, selectedFolder })
          }}
        />
      </div>
    </ModalRegular>
  )
}

const Folder = ({
  title,
  folder,
  color,
  folders,
  selectedFolder,
  onSelect,
}: {
  title: string
  color: string
  folder: any
  selectedFolder: any
  folders: any
  onSelect: any
}) => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()
  return (
    <div>
      <div
        className={classNames(
          'flex space-x-3 items-center cursor-pointer p-3',
          color,
          folder?.id === selectedFolder?.id && 'bg-blue-50',
        )}
      >
        <div
          className="flex space-x-3 items-center cursor-pointer"
          onClick={() => {
            if (folders?.length) {
              setShow(!show)
            }
          }}
        >
          {folders?.length ? (
            <FontAwesomeIcon icon={show ? faChevronDown : faChevronRight} />
          ) : (
            <div className={'w-[10px]'}></div>
          )}
          <FontAwesomeIcon icon={show ? faFolderOpen : faFolder} size={'2x'} />
        </div>
        <div
          className={'flex-1 flex justify-between'}
          onClick={() => {
            if (folder?.id !== 'students' && folder?.id !== 'school') {
              onSelect(folder)
            }
          }}
        >
          <p>
            <>
              {folder.id === 'wholeschool' ? t('documents.wholeSchool') : title}
            </>
          </p>
          {folder?.id === selectedFolder?.id && (
            <FontAwesomeIcon icon={faCheckCircle} />
          )}
        </div>
      </div>
      <div className={'pl-6 flex flex-col'}>
        {show &&
          folders.map((folder: any, index: number) => {
            return (
              <Folder
                onSelect={onSelect}
                folder={folder}
                selectedFolder={selectedFolder}
                key={index}
                folders={folder?.subFolders}
                title={folder?.attributes?.name ?? folder.name ?? ''}
                color={color}
              />
            )
          })}
      </div>
    </div>
  )
}
