import Parse from 'parse'
import { Kid } from './Kid'
import { School } from './School'
import { User } from './User'
import { UserRole } from './UserRole'

export class Notification extends Parse.Object {
  public static readonly Fields = {
    school: 'school',
    school_pointer: 'school_pointer',
    kid_owner: 'kid_owner',
    origin: 'origin',
  }

  constructor() {
    super('notifications')
  }

  // school_pointer only on some Notification.
  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
  get origin(): Parse.Object[] {
    return this.get('origin')
  }
  set origin(value: Parse.Object[]) {
    this.set('origin', value)
  }
  get module(): number {
    return this.get('module')
  }
  get action(): number {
    return this.get('action')
  }
  get readAt(): Date | undefined {
    return this.get('readAt')
  }
  get editedAt(): Date | undefined {
    return this.get('editedAt')
  }
}

Parse.Object.registerSubclass('notifications', Notification)
