import {
  faMapPin,
  faTrashCan,
  faLocation,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components/Button'
import { LocationInterface } from '../../../Models/Location'
import { faLocationPlus } from '@fortawesome/pro-solid-svg-icons'
import Swal from 'sweetalert2'
import classNames from 'classnames'
import { useDaysSelections, useRepeatSelections } from '../CreateEvent3'
import { RecurrenceParams } from '../../../store/event3Store'
import moment from 'moment'
import { getDayForTranslatation } from '../../events/components/EventsInfo'
import { getDateForTranslatation } from '../../../components/ImageWithLetters'

interface DaysCirclesProps {
  selectedDays: string[] | undefined
  days: string[]
  recurrence?: RecurrenceParams
}

export const DaysCircles: React.FC<DaysCirclesProps> = ({
  selectedDays,
  days,
  recurrence,
}) => {
  const { t } = useTranslation()
  const fullDays = useDaysSelections()
  const repeatSelections = useRepeatSelections()
  return (
    <>
      <div className="flex flex-row gap-x-1 text-xs">
        <b>
          {getDayForTranslatation(moment(recurrence?.startsOn).day())}{' '}
          {moment(recurrence?.startsOn).format('D')}{' '}
          {getDateForTranslatation(moment(recurrence?.startsOn).format('MM'))}{' '}
          {moment(recurrence?.startsOn).format('YYYY')} -{' '}
          {getDayForTranslatation(moment(recurrence?.endsOn).day())}{' '}
          {moment(recurrence?.endsOn).format('D')}{' '}
          {getDateForTranslatation(moment(recurrence?.endsOn).format('MM'))}{' '}
          {moment(recurrence?.endsOn).format('YYYY')}
        </b>
      </div>
      <div className="flex flex-row gap-x-1 text-xs items-center">
        <b>
          {
            repeatSelections.find((rep) => rep.id === recurrence?.interval)
              ?.label
          }{' '}
        </b>
        {days.map((day) => {
          return (
            <div
              className={classNames(
                'w-[20px] h-[20px] lh- rounded-full text-center leading-[20px]',
                selectedDays?.find((d) => d === day)
                  ? 'bg-eventsMain text-white'
                  : '',
              )}
            >
              {fullDays.find((d) => d.id === day)?.label.charAt(0)}
            </div>
          )
        })}
      </div>
    </>
  )
}
