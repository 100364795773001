import Parse from 'parse'
import { School } from './School'
import { UserRole } from './UserRole'

export interface LocationInterface {
  id?: string
  type?: string
  shared?: string
  name?: string
  lat?: number
  long?: number
  city?: string
  address?: string
  zipcode?: string
  ownerId?: string
}

export class Location extends Parse.Object {
  public static readonly Fields = {
    className: 'locations',

    SCHOOL_POINTER: 'school_pointer',
    OWNER: 'owner',

    TYPE: 'type',
    SHARED: 'shared',
    NAME: 'name',
    LATITUDE: 'lat',
    LONGITUDE: 'long',
    CITY: 'city',
    ADDRESS: 'address',
    ZIPCODE: 'zipcode',
    ARCHIVE_STATE: 'archive_state',
    ARCHIVED_BY: 'archived_by',
  }
  constructor() {
    super(Location.Fields.className)
  }

  get school(): School {
    return this.get(Location.Fields.SCHOOL_POINTER)
  }
  set school(value: School) {
    this.set(Location.Fields.SCHOOL_POINTER, value)
  }

  get owner(): UserRole | undefined {
    return this.get(Location.Fields.OWNER)
  }
  set owner(value: UserRole | undefined) {
    this.set(Location.Fields.OWNER, value)
  }

  get type(): string | undefined {
    return this.get(Location.Fields.TYPE)
  }
  set type(value: string | undefined) {
    this.set(Location.Fields.TYPE, value)
  }
  get shared(): string | undefined {
    return this.get(Location.Fields.SHARED)
  }
  set shared(value: string | undefined) {
    this.set(Location.Fields.SHARED, value)
  }

  get name(): string | undefined {
    return this.get(Location.Fields.NAME)
  }
  set name(value: string | undefined) {
    this.set(Location.Fields.NAME, value)
  }

  get lat(): number | undefined {
    return this.get(Location.Fields.LATITUDE)
  }
  set lat(value: number | undefined) {
    this.set(Location.Fields.LATITUDE, value)
  }
  get long(): number | undefined {
    return this.get(Location.Fields.LONGITUDE)
  }
  set long(value: number | undefined) {
    this.set(Location.Fields.LONGITUDE, value)
  }

  get city(): string | undefined {
    return this.get(Location.Fields.CITY)
  }
  set city(value: string | undefined) {
    this.set(Location.Fields.CITY, value)
  }
  get address(): string | undefined {
    return this.get(Location.Fields.ADDRESS)
  }
  set address(value: string | undefined) {
    this.set(Location.Fields.ADDRESS, value)
  }
  get zipcode(): string | undefined {
    return this.get(Location.Fields.ZIPCODE)
  }
  set zipcode(value: string | undefined) {
    this.set(Location.Fields.ZIPCODE, value)
  }
  get archive_state(): number | undefined {
    return this.get(Location.Fields.ARCHIVE_STATE)
  }
  set archive_state(value: number | undefined) {
    this.set(Location.Fields.ARCHIVE_STATE, value)
  }
  get archived_by(): UserRole | undefined {
    return this.get(Location.Fields.ARCHIVED_BY)
  }
  set archived_by(value: UserRole | undefined) {
    this.set(Location.Fields.ARCHIVED_BY, value)
  }

  asJson(): LocationInterface {
    return {
      id: this.id,
      type: this.type,
      shared: this.shared,
      name: this.name,
      lat: this.lat,
      long: this.long,
      city: this.city,
      address: this.address,
      zipcode: this.zipcode,
      ownerId: this.owner?.id,
    }
  }
}
Parse.Object.registerSubclass(Location.Fields.className, Location)
