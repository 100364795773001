import React, { useCallback, useState } from 'react'
import ModalRegular from '../../../../components/ModalRegular'
import { Button } from '../../../../components/Button'
import PhoneInput from 'react-phone-input-2'
import { t } from 'i18next'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { School } from '../../../../Models/School'

interface CreateSchoolAccountModalProps {
  onClose: () => void
  isOpen: boolean
}
interface Props {
  school?: School
}

const CreateSchoolAccountModal: React.FC<
  CreateSchoolAccountModalProps & Props
> = ({ onClose, isOpen, school }) => {
  const uploadImageToParse = useCallback(async (file: File) => {
    var extension = file.name.split('.').pop()
    var parseImageFile = new Parse.File(`tyraimage.${extension}`, file)
    await parseImageFile.save()
    return parseImageFile
  }, [])

  const handleImageClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0]
    if (file) {
      const parseFile = await uploadImageToParse(file)

      {
        /*ParsaFile här*/
      }
    }
  }
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  return (
    <ModalRegular
      onClose={onClose}
      isOpen={isOpen}
      bgColor="bg-green-600 p-4"
      title={t('admin.createSchoolAcc')}
      closeColor="white"
    >
      <div className="p-10 gap-y-3 flex flex-col">
        <div className="flex flex-row gap-x-4">
          <label
            className="flex items-center justify-center border rounded-full my-2 max-w-[100px]  max-h-[100px]  min-w-[100px]  min-h-[100px] overflow-hidden cursor-pointer hover:scale-[110%] transition duration-200"
            onClick={handleImageClick}
          >
            <FontAwesomeIcon icon={faPlus} className="text-gray-400" />
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  handleFileChange(e)
                  setImageUrl(URL.createObjectURL(e.target.files[0]))
                }
              }}
            />
          </label>
          <div className="flex w-full justify-end my-[40px] border bg-white rounded-lg p-2 items-end">
            <input
              className="focus:outline-none resize-none w-full"
              placeholder="Kontonamn"
            ></input>
          </div>
        </div>

        <div className="flex flex-row w-full justify-between my-2 border bg-white rounded-lg p-2 items-center">
          <input
            className="focus:outline-none resize-none w-full"
            placeholder="Mobilnummer"
          ></input>
        </div>
        <div className="flex flex-row w-full justify-between my-2 border bg-white rounded-lg p-2 items-center">
          <input
            className="focus:outline-none resize-none w-full"
            placeholder="Lösenord"
            type="password"
          ></input>
        </div>
        <div className="flex flex-row w-full justify-between my-2 border bg-white rounded-lg p-2 items-center">
          <input
            className="focus:outline-none resize-none w-full"
            placeholder="Repetera lösenord"
            type="password"
          ></input>
        </div>
        <div className="flex flex-row justify-center mt-4 w-full">
          <Button
            variant={'borderMain'}
            label={'Skapa'}
            className="w-full"
            size="sm"
          />
        </div>
      </div>
    </ModalRegular>
  )
}

export default CreateSchoolAccountModal
