import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getFullMonthForTranslatation } from '../views/events/Events'

interface Props {
  day: string
}

export const DateCalendarIcon: React.FC<{ day: string }> = observer(
  (props: Props) => {
    const { t } = useTranslation()
    return (
      <>
        <div
          className={
            'h-24 w-24 mb-4 rounded-xl flex justify-center items-center bg-gray-50 text-gray-800 text-center mr-6 shadow-md'
          }
        >
          {props.day && (
            <div className="grid grid-cols-1 w-full h-full  ">
              <p className=" pt-1 w-full bg-red-500 font-bold text-white items-center justify-center flex rounded-t-xl shadow">
                {t('absence.backFrSickness')}
              </p>

              <p className="font-bold text-2xl items-center justify-center flex mt-1">
                {moment(props.day).format('DD')}
              </p>
              <p className="font-medium pb-1 items-center justify-center flex">
                {getFullMonthForTranslatation(moment(props.day).format('MM'))}
              </p>
            </div>
          )}
        </div>
      </>
    )
  },
)
