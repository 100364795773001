export const RandomColors = [
  'bg-[#7eb5f4]',
  'bg-[#c99cf2]',
  'bg-[#ebdb76]',
  'bg-[#505050]',
  'bg-[#f26d6d]',
  'bg-[#a5d6ea]',
  'bg-[#8392a7]',
  'bg-[#91E398]',

  'bg-[#7472e3]',

  'bg-[#f08ad4]',
  'bg-[#bb8af0]',
  'bg-[#de5c59]',
  'bg-[#399fef]',
  'bg-[#16ca3c]',
  'bg-[#08912D]',
  'bg-[#A8092D]',
  'bg-[#071DA9]',
  'bg-[#f1a31e]',
]
//funkar till vit text:
