import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useContext, useState } from 'react'
import { LoadingBar } from '../../../../components/LoadingBar'
import {
  faEllipsisH,
  faEye,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faEllipsisHAlt,
  faEllipsisVertical,
  faFolder,
  faPen,
} from '@fortawesome/pro-solid-svg-icons'
import { ButtonDropdown } from '../../../../components/Dropdown'
import { useClickOutside } from '../../../../hooks/useClickoutside'
import ModalRegular from '../../../../components/ModalRegular'
import { Button } from '../../../../components/Button'
import { t } from 'i18next'
import Swal from 'sweetalert2'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { DefaultKidFolders } from '../../../../Models/SchoolSetting'
import { SchoolStoreContext } from '../../../../store/schoolStore'
import { School } from '../../../../Models/School'

interface Props {
  children?: React.ReactNode
  label?: string
  loading?: boolean
  className?: string
  folder: DefaultKidFolders
  school: School
  onClick?: () => void
  onSave?: () => void
}

export default function FolderCard(props: Props) {
  const [menuRef, togglerRef] = useClickOutside(() => {
    setDropdownVisible(false)
  })
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }
  const { editDefaultKidFolders, archiveDefaultKidFolder } =
    useContext(SchoolStoreContext)
  const [showChangeModal, setShowChangeModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [fakeState, setFakeState] = useState(false)
  const [changeFolder, setChangeFolder] = useState<DefaultKidFolders>(
    props.folder,
  )
  const [deleteFilesInFolder, setDeleteFilesInFolder] = useState<boolean>(false)

  return (
    <div
      className={classNames(
        ' min-h-[60px] min-w-[250px] rounded-2xl shadow-tyraShadow m-4 relative ',
      )}
      onClick={props.onClick}
    >
      {props?.loading && <LoadingBar />}
      <div className="flex space-x-4 rounded-2xl overflow-visible items-center bg-orange-50 font-bold font-sans text-neutral-600 relative">
        <div
          className={classNames(
            'flex flex-wrap relative py-3 font-medium justify-start w-full h-full',
            props.className,
          )}
        >
          <div className="absolute top-1 h-auto w-auto right-2 justify-start flex z-30">
            <button
              ref={togglerRef}
              type="button"
              aria-haspopup="true"
              aria-expanded={dropdownVisible}
              onClick={() => toggleDropdown()}
            >
              <FontAwesomeIcon
                icon={faEllipsisH}
                className="text-gray-400 hover:scale-110 items-start "
              />
            </button>
            {dropdownVisible && (
              <div
                className="absolute right-4 z-50 mx-3 origin-top divide-y w-44 top-2"
                style={{ display: dropdownVisible ? 'block' : 'none' }}
                ref={menuRef}
              >
                <ButtonDropdown.Menu>
                  <ButtonDropdown.Section>
                    <ButtonDropdown.Button
                      key={'showEdit'}
                      icon={faPen}
                      label={t('absence.change')}
                      className="text-yellow-600"
                      onClick={() => {
                        setShowChangeModal(true)
                      }}
                    />
                    <ButtonDropdown.Button
                      key={'showDelete'}
                      icon={faTrashCan}
                      label={t('absence.delete')}
                      className="text-red-600"
                      onClick={() => {
                        setShowDeleteModal(true)
                      }}
                    />
                  </ButtonDropdown.Section>
                </ButtonDropdown.Menu>
              </div>
            )}
          </div>
          <>
            <div className="flex relative flex-row items-center h-16 w-full">
              <FontAwesomeIcon
                size="2x"
                icon={faFolder}
                className="absolute left-3 h-8 w-8 text-orange-300"
              />
              {!props.folder.only_staff && (
                <FontAwesomeIcon
                  icon={faUserGroup}
                  className="absolute top-[26px] left-[20px] h-4 w-4 text-gray-800 opacity-40"
                />
              )}
              <div className="flex w-full justify-center">
                <div className="flex max-w-[130px] line-clamp-2">
                  {props.label}
                </div>
              </div>
            </div>
            <div className="flex-grow flex items-center justify-center">
              {props.children}
            </div>
          </>
        </div>
      </div>
      <ModalRegular
        onClose={() => {
          setShowChangeModal(false)
        }}
        isOpen={showChangeModal}
        bgColor="bg-eventsMain p-4 text-center"
        title={t('absence.change')}
        closeColor="white"
        className="max-w-sm"
      >
        <div className="p-8 flex flex-col gap-y-8">
          <div className="flex flex-col w-full gap-y-2">
            <div>
              <input
                className="border shadow p-2 rounded-lg w-full focus:outline-none focus:ring-eventsMain focus:border-eventsMain "
                placeholder={t('documents.name')}
                value={changeFolder.name}
                onChange={(e) =>
                  setChangeFolder({ ...changeFolder, name: e.target.value })
                }
              />
            </div>
          </div>

          <div className="flex">
            <div className="flex flex-row justify-start w-full items-center gap-x-2">
              <input
                type="checkbox"
                checked={changeFolder.only_staff ? false : true}
                onChange={() => {
                  setChangeFolder({
                    ...changeFolder,
                    only_staff: !changeFolder.only_staff,
                  })
                }}
              />
              <div>
                {' '}
                <> {t('documents.shareWithParent')}</>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center">
            <Button
              label={t('absence.change')}
              variant="fullBlue"
              size="custom"
              onClick={() => {
                editDefaultKidFolders(changeFolder, props.school).finally(
                  () => {
                    setFakeState(!fakeState)
                    setShowChangeModal(false)
                    if (props.onSave) {
                      props.onSave()
                    }
                  },
                )
                /*Swal.fire({
                  text: t('events.eventAlarmFail'),
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.ok'),
                  cancelButtonText: t('general.cancel'),
                })*/
              }}
            />
          </div>
        </div>
      </ModalRegular>
      <ModalRegular
        onClose={() => {
          setShowDeleteModal(false)
        }}
        isOpen={showDeleteModal}
        overlayClassName="text-center"
        bgColor="bg-red-900 opacity-90 p-4"
        //title={t('documents.remove')}
        title={props.folder.name}
        closeColor="white"
        className="max-w-sm"
      >
        <div className="p-8 flex flex-col gap-y-8">
          <div className="flex">
            <div className="flex flex-row justify-start w-full items-center gap-x-2">
              <input
                type="checkbox"
                checked={deleteFilesInFolder}
                onChange={() => setDeleteFilesInFolder(!deleteFilesInFolder)}
              />
              <div>
                <>{t('admin.deleteDocText')}</>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center">
            <Button
              label={t('absence.delete')}
              variant="docAdminRemove"
              size="custom"
              className="opaicty-90"
              onClick={() => {
                props.folder.archive_state = 400
                archiveDefaultKidFolder(
                  props.folder,
                  props.school,
                  deleteFilesInFolder,
                ).finally(() => {
                  setFakeState(!fakeState)
                  setShowDeleteModal(false)
                  if (props.onSave) {
                    props.onSave()
                  }
                })
                /*Swal.fire({
                  text: t('events.eventAlarmFail'),
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.ok'),
                  cancelButtonText: t('general.cancel'),
                }).then(async (result) => {
                  props.folder.archive_state = 400
                  if (result.isConfirmed) {
                    archiveDefaultKidFolder(
                      props.folder,
                      props.school,
                      deleteFilesInFolder,
                    ).finally(() => {
                      setFakeState(!fakeState)
                      setShowDeleteModal(false)
                      if (props.onSave) {
                        props.onSave()
                      }
                    })
                  }
                })*/
              }}
            />
          </div>
        </div>
      </ModalRegular>
    </div>
  )
}
