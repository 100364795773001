import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { SkaOperationsPlanning } from '../Models/SkaOperationsPlanning'
import { SkaProjectPlanning } from '../Models/SkaProjectPlanning'
import { SkaCard } from '../Models/SkaCard'
import { Kid } from '../Models/Kid'
import { User } from '../Models/User'
import { USER_ROLE_TYPE } from '../Enums/TyraEnums'

class DocumentationStore {
  documentations?: SkaOperationsPlanning[] | SkaProjectPlanning[]
  documentation?: any
  selectConnections?: any[]
  cards?: SkaCard[]
  teachers?: User[]
  constructor() {
    makeAutoObservable(this)
  }

  setCards = (cards: SkaCard[]) => {
    this.cards = cards
  }
  setDocumentations = (
    documentations: SkaOperationsPlanning[] | SkaProjectPlanning[],
  ) => {
    this.documentations = documentations
  }

  setTeachers = (teachers: User[]) => {
    this.teachers = teachers
  }

  setSelectConnections = (selectConnections: any[]) => {
    this.selectConnections = selectConnections
  }

  setDocumentation = (
    documentation: SkaOperationsPlanning | SkaProjectPlanning | undefined,
  ) => {
    this.documentation = documentation
  }

  fetchSelectConnections = async ({
    type,
    userRoleId,
  }: {
    type: number
    userRoleId: string
  }) => {
    const connections = await Parse.Cloud.run('getConnections', {
      type,
      userRoleId,
    })
    this.setSelectConnections(connections)
  }

  fetchDocumentations = async ({
    type,
    schoolId,
  }: {
    type: string
    schoolId: string
  }) => {
    const documentations = await Parse.Cloud.run('getDocumentation', {
      type,
      schoolId,
    })
    this.setDocumentations(documentations)
  }

  fetchDocumentation = async ({ type, id }: { type: string; id: string }) => {
    const documentation = await Parse.Cloud.run('getDocumentationById', {
      type,
      id,
    })
    this.setDocumentation(documentation)
  }

  fetchUsers = async (schoolId: string, roleType: number) => {
    const teachers = await Parse.Cloud.run('getUsers', {
      schoolId,
      roleType: roleType,
    })
    if (roleType === USER_ROLE_TYPE.TEACHER) {
      this.setTeachers(teachers)
    }
  }

  fetchCards = async (id: string, type: string) => {
    const cards = await Parse.Cloud.run('getCards', {
      id,
      type,
    })
    this.setCards(cards)
  }

  addDocumentation = async ({
    userRoleId,
    type,
  }: {
    userRoleId: string
    type: string
  }) => {
    return await Parse.Cloud.run('addDocumentation', {
      type,
      userRoleId,
    })
  }

  removeDocumentation = async ({ id, type }: { id: string; type: string }) => {
    await Parse.Cloud.run('removeDocumentation', {
      type,
      id,
    })
  }
  copyDocumentation = async ({ id, type }: { id: string; type: string }) => {
    await Parse.Cloud.run('copyDocumentation', {
      type,
      id,
    })
  }

  addDocumentationFile = async ({
    cardId,
    name,
    imageType,
    file,
  }: {
    cardId: string
    name: string
    imageType: string
    file: any
  }) => {
    await Parse.Cloud.run('addDocumentationFile', {
      cardId,
      name,
      file,
      imageType,
    })
  }

  removeDocumentationFile = async ({
    cardId,
    fileId,
    imageType,
  }: {
    cardId: string
    fileId: string
    imageType: string
  }) => {
    await Parse.Cloud.run('removeDocumentationFile', {
      cardId,
      fileId,
      imageType,
    })
  }

  addCard = async ({
    id,
    schoolId,
    cardType,
    type,
  }: {
    id: string
    schoolId: string
    cardType: number
    type: string
  }) => {
    await Parse.Cloud.run('addCard', {
      id,
      schoolId,
      cardType,
      type,
    })
  }

  addConnectionCards = async ({
    id,
    toIds,
    schoolId,
    cardType,
    relationType,
    type,
  }: {
    id: string
    toIds: string[]
    schoolId: string
    cardType: number
    relationType: number
    type: string
  }) => {
    await Parse.Cloud.run('addConnectionCards', {
      id,
      toIds,
      schoolId,
      cardType,
      relationType,
      type,
    })
  }

  removeCard = async ({
    id,
    cardId,
    type,
  }: {
    id: string
    cardId: string
    type: string
  }) => {
    await Parse.Cloud.run('removeCard', {
      id,
      cardId,
      type,
    })
  }

  editTextCard = async ({
    cardId,
    text,
    tags,
  }: {
    cardId: string
    text: string
    tags: number[]
  }) => {
    await Parse.Cloud.run('editTextCard', {
      cardId,
      text,
      tags,
    })
  }

  addTaggedKidsDocumentation = async ({
    id,
    kids,
    type,
  }: {
    id: string
    kids: Kid[]
    type: string
  }) => {
    await Parse.Cloud.run('addTaggedKidsDocumentation', {
      id,
      kids: kids.length ? kids?.map((k) => k?.id) : [],
      type,
    })
  }
  addSharedUsersDocumentation = async ({
    id,
    users,
    type,
  }: {
    id: string
    users: User[]
    type: string
  }) => {
    await Parse.Cloud.run('addSharedUsersDocumentation', {
      id,
      users: users.length ? users?.map((k) => k?.id) : [],
      type,
    })
  }
  editDocumentation = async ({
    id,
    type,
    name,
    visibility,
  }: {
    id: string
    type: string
    name: string
    visibility: number
  }) => {
    await Parse.Cloud.run('editDocumentation', {
      id,
      type,
      name,
      visibility,
    })
  }
}

export const documentationStore = (() => {
  return new DocumentationStore()
})()
export const DocumentationStoreContext: React.Context<DocumentationStore> =
  React.createContext(documentationStore)
