import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilePdf,
  faMapMarkerAlt,
  faMapPin,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { EventListItemData, IFile } from './Events3'
import { UserStoreContext } from '../../store/userStore'
import { EVENT3_TYPE, Event3StoreContext } from '../../store/event3Store'
import { Tag } from '../../components/Tag'
import { Button } from '../../components/Button'
import { faWaveform } from '@fortawesome/pro-solid-svg-icons'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import { CommentInput } from './components/CommentInput'
import { CommentDetailCard } from './components/CommentDetailCard'
import EventParticipatingPopUp from './components/EventParticipatingPopUp'
import { observer } from 'mobx-react'
import { EventOverview } from './components/EventOverview'
import { IKidRelation } from './components/OverviewUsersList'
import { EventReqResOverview } from './EventReqResOverview'
import { SettingsDetailView } from './components/SettingsDetailView'
import { EventExpendableCardHeader } from './components/EventDetailCard'

export interface IEventInfoOverviewUser {
  id: string
  userId: string
  name: string
  thumbnail?: string
  departmentIds: (string | undefined)[]
  departmentNames?: (string | undefined)[]
  kids?: IKidRelation[]
  hasRead?: boolean
  readAt?: string
  isUser: boolean
}

export interface IEventActivitiesOverviewUser {
  id: string
  userId: string
  kidId?: string
  name: string
  thumbnail?: string
  departmentIds: Array<string | undefined>
  relatives?: IKidRelation[]
  kids?: IKidRelation[]
  status?: string
  message?: string
  cancelledAt?: string
  extraParticipants?: number
  isUser: boolean
}

export interface IEventOverview {
  eventTimeId: string
  eventGroupInfoId: string
  departmentIds: string[]
  readCount: number
  unreadCount: number
  allCount: number
  readUsers: IEventInfoOverviewUser[]
  unreadUsers: IEventInfoOverviewUser[]
  notRepliedParticipants: IEventInfoOverviewUser[]
  lastReminderSent?: string
}

export interface IEventDetails extends IEventOverview {
  presentKidsCount: number
  acceptedCount: number
  declinedCount: number
  hasMessageCount: number
  notRepliedCount: number
  presentKids: IEventActivitiesOverviewUser[]
  acceptedParticipants: IEventInfoOverviewUser[]
  declinedParticipants: IEventInfoOverviewUser[]
  hasMessageParticipants: IEventInfoOverviewUser[]
}
export const EventDetailPage: React.FC<{
  eventTimeId?: string
  fetchedEventDetail?: EventListItemData
  triggerHasResponded?: () => void
  hasResponded: boolean
  isExpanded?: boolean
  handleExpaneded: () => void
}> = observer(
  ({
    eventTimeId,
    fetchedEventDetail,
    triggerHasResponded,
    hasResponded,
    isExpanded,
    handleExpaneded,
  }) => {
    const { t } = useTranslation()
    const { currentUserRole } = useContext(UserStoreContext)
    const { getEvent3Overview, declineParticipatingInEvent } =
      useContext(Event3StoreContext)
    /* const [fetchedEventDetail, setFetchedEventDetail] =
    useState<EventListItemData>() */
    const [fetchedEventOverview, setFetchedEventOverview] =
      useState<IEventDetails>()
    const [isPopupVisible, setPopupVisible] = useState(false)
    const [isOverviewVisible, setOverviewVisible] = useState(false)
    const [openSttings, setOpenSettings] = useState<boolean>(false)

    const createdAt = moment(fetchedEventDetail?.createdAt).format(
      'DD MMMM YYYY',
    )
    const validUserToSeeOverview = currentUserRole?.role_type
      ? currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
      : false

    const infoType = fetchedEventDetail?.type === EVENT3_TYPE.INFO
    const importantInfoType = fetchedEventDetail?.type === EVENT3_TYPE.IMPORTANT
    const activityType = fetchedEventDetail?.type === EVENT3_TYPE.ACTIVITIES
    const meetingType = fetchedEventDetail?.type === EVENT3_TYPE.MEETING
    const bookingType = fetchedEventDetail?.type === EVENT3_TYPE.BOOKING
    const hasComments = fetchedEventDetail?.comments
      ? fetchedEventDetail?.comments.length >= 0
      : false
    const locationType = fetchedEventDetail?.location?.type
    const sortedComments = fetchedEventDetail?.comments

    useEffect(() => {
      let isMounted = true
      if (validUserToSeeOverview) {
        const fetchEventOverview = async () => {
          try {
            const eventOverview = await getEvent3Overview(
              eventTimeId,
              fetchedEventDetail?.eventGroupInfoId,
              fetchedEventDetail?.departmentIds ?? [],
            )
            if (isMounted) {
              setFetchedEventOverview(eventOverview)
            }
          } catch (error) {
            console.error('Error fetching event detail: ', error)
          }
        }
        fetchEventOverview()
      }
      return () => {
        isMounted = false
      }
    }, [fetchedEventDetail, hasResponded])

    const handlePlayAudio = (file: IFile) => {
      const newWindow = window.open('', '_blank')
      newWindow?.document.write('<!DOCTYPE html>')
      newWindow?.document.write('<html lang="en">')
      newWindow?.document.write('<head>')
      newWindow?.document.write('<meta charset="UTF-8">')
      newWindow?.document.write(
        '<meta name="viewport" content="width=device-width, initial-scale=1.0">',
      )
      newWindow?.document.write('<title>Audio Player</title>')
      newWindow?.document.write('<style>')
      newWindow?.document.write(`
    body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin: 0;
    }
    audio {
      width: 50%;
      max-width: 500px;
    }
  `)
      newWindow?.document.write('</style>')
      newWindow?.document.write('</head>')
      newWindow?.document.write('<body>')
      newWindow?.document.write('<audio controls autoplay>')
      newWindow?.document.write(
        `<source src="${file.file}" type="${file.mime_type}">`,
      )
      newWindow?.document.write(
        'Your browser does not support the audio element.',
      )
      newWindow?.document.write('</audio>')
      newWindow?.document.write('</body>')
      newWindow?.document.write('</html>')
      newWindow?.document.close()
    }

    const handleOpenPopup = () => {
      setPopupVisible(true)
    }

    const handleClosePopup = () => {
      setPopupVisible(false)
    }

    const handleDeclineParticipatingInEvent = async () => {
      await declineParticipatingInEvent(
        fetchedEventDetail?.eventTimeId,
        [currentUserRole?.user?.id ?? ''],
        [currentUserRole?.kid?.id ?? ''],
      )
      triggerHasResponded?.()
    }

    const handleAcceptParticipatingInEvent = async () => {
      setPopupVisible(true)
      triggerHasResponded?.()
    }

    const handleEditParticipating = (
      fetchedEventDetail: EventListItemData | undefined,
    ) => {
      const eventHasPassed = moment(fetchedEventDetail?.eventDate).isBefore(
        moment().startOf('day'),
      )

      let content = null

      if (!eventHasPassed) {
        if (fetchedEventDetail?.bookingStatus === 'attending') {
          if (
            fetchedEventDetail?.ownParticipantsData?.extraParticipants ===
            undefined
          ) {
            content = (
              <div className="flex flex-col justify-center items-center w-full mt-auto">
                <p className="font-semibold text-md">
                  {fetchedEventDetail.eventFor === 'kid' &&
                  currentUserRole?.attributes.kid
                    ? currentUserRole?.attributes.kid.kidName +
                      ' ' +
                      t('events.registered')
                    : t('events.youRegistered')}
                </p>
                <p className="font-semibold my-3 px-3 w-[90%] flex justify-center">
                  {fetchedEventDetail?.ownParticipantsData?.message ?? ''}{' '}
                </p>
                <p
                  className="font-semibold text-xl text-blue-600 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleOpenPopup()
                  }}
                >
                  {t('blog.edit')}
                </p>
              </div>
            )
          } else {
            content = (
              <div className="flex flex-col justify-center items-center w-full mt-auto">
                <p className="font-bold text-xl">
                  {fetchedEventDetail?.ownParticipantsData?.extraParticipants +
                    1}{' '}
                  {t('events.manyRegistered')}
                </p>
                <p className="font-semibold my-3 px-3 w-[90%] flex justify-center">
                  {fetchedEventDetail?.ownParticipantsData?.message ?? ''}{' '}
                </p>
                <p
                  className="font-semibold text-xl text-blue-600 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleOpenPopup()
                  }}
                >
                  {t('blog.edit')}
                </p>
              </div>
            )
          }
        } else if (fetchedEventDetail?.bookingStatus === 'not_attending') {
          content = (
            <div className="flex flex-col justify-center items-center w-full mt-auto">
              <p className="font-bold text-xl">{t('events.youDeclined')}</p>
              <p className="font-semibold my-3 px-3 w-[90%] flex justify-center">
                {fetchedEventDetail?.ownParticipantsData?.message ?? ''}{' '}
              </p>
              <p
                className="font-semibold text-xl text-blue-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  handleOpenPopup()
                }}
              >
                {t('blog.edit')}
              </p>
            </div>
          )
        }
      }

      return <>{content}</>
    }

    const displayAttachmentsContainer = (
      fetchedEventDetail?: EventListItemData,
    ) => {
      return (
        <div
          className={`flex  justify-start overflow-x-auto gap-x-2 w-full min-h-[83px] items-center scrollbar-hide p-3`}
        >
          {/********************************* attachments **************************************** */}
          {fetchedEventDetail?.files?.map((file) => (
            <div id="attachments-content" className="flex " key={file.id}>
              {(file.mime_type === 'image/jpeg' ||
                file.mime_type === 'image/png') && (
                <img
                  src={file.file}
                  alt=""
                  className={`flex flex-row rounded-md object-cover h-16 w-16 mx-1 cursor-pointer shadow-sm`}
                  onClick={(e) => {
                    e.stopPropagation()
                    window.open(file.file, '_blank')
                  }}
                />
              )}
              {file.mime_type === 'audio/mpeg' && (
                <div
                  className={`flex flex-col border-2 justify-center mx-1 items-center rounded-md h-16 w-16 border-blue-300 cursor-pointer shadow-sm`}
                  onClick={(e) => {
                    e.stopPropagation()
                    handlePlayAudio(file)
                  }}
                >
                  <FontAwesomeIcon
                    icon={faWaveform}
                    className="w-full text-sm text-blue-500"
                  />
                  <p className="text-sm">
                    {moment.utc(file.metadata.durationInMs).format('m:ss')}
                  </p>
                </div>
              )}
              {file.mime_type === 'application/pdf' && (
                <div
                  className={`flex flex-col border-2 justify-center items-center rounded-md h-16 w-16 mx-1  border-red-300 cursor-pointer text-red-500 shadow-sm`}
                  onClick={(e) => {
                    e.stopPropagation()
                    window.open(file.file, '_blank')
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    className="w-full text-sm "
                  />
                  <p className="text-sm text-center w-full break-words line-clamp-1">
                    {file.name}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      )
    }

    return (
      <>
        {!isOverviewVisible ? (
          <div
            className={`flex flex-col h-[400px] w-full rounded-md mr-1 overflow-y-auto justify-between bg-[#F6F6F6] scrollbar-hide relative ${
              isPopupVisible ? 'filter blur-sm' : ''
            }`}
          >
            {/****************************** HEADER & content  div1 ******************************************* */}
            <div>
              {fetchedEventDetail && (
                <EventExpendableCardHeader
                  event={fetchedEventDetail}
                  openSetting={openSttings}
                  onOpenSetting={() => setOpenSettings(!openSttings)}
                  isExpanded={isExpanded}
                />
              )}
              {openSttings && (
                <SettingsDetailView
                  setOpenSettings={setOpenSettings}
                  openSttings
                  fetchedEventDetail={fetchedEventDetail}
                  handleExpaneded={handleExpaneded}
                />
              )}

              {/****************************** END OF HEADER ******************************************* */}
              {fetchedEventDetail?.content && (
                <div className="px-2">{fetchedEventDetail?.content}</div>
              )}

              {/****************************** Start OF Settings faEllipsisV ******************************************* */}
            </div>
            <div className="flex flex-col mt-2 justify-start">
              {/*********************************** Attachments (all events) container ************************************** */}
              {displayAttachmentsContainer(fetchedEventDetail)}

              {/********************************* Post av info **************************************** */}
              {fetchedEventDetail?.type !== EVENT3_TYPE.BOOKING && (
                <div
                  id="publisher-info"
                  className="flex justify-between p-2  items-center my-2"
                >
                  {fetchedEventDetail?.location && (
                    <div
                      id="location-container"
                      className={`rounded-md flex flex-col shadow-md w-1/4 h-16 ml-2 text-xs bg-white`}
                    >
                      <div className="flex justify-start items-center gap-2 p-2">
                        <p className="flex flex-col font-semibold text-gray-700 w-full">
                          <div className="flex">
                            <FontAwesomeIcon
                              icon={faMapPin}
                              size="lg"
                              className="text-blue-400 pr-2"
                            />
                            {fetchedEventDetail?.location?.name}
                          </div>
                          {locationType === 'address' ? (
                            <>
                              <div>{fetchedEventDetail?.location?.address}</div>
                              <div>
                                {fetchedEventDetail?.location?.zipcode}{' '}
                                {fetchedEventDetail?.location?.city}
                              </div>
                            </>
                          ) : locationType === 'coord' ? (
                            <div>
                              {fetchedEventDetail?.location?.lat}
                              {'°, '}
                              {fetchedEventDetail?.location?.long}
                              {'°'}
                            </div>
                          ) : (
                            <></>
                          )}
                        </p>
                        {fetchedEventDetail?.location?.type !== 'freetext' ? (
                          <div className="flex justify-end w-[80%]">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              size="xl"
                              className="text-green-400"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="flex w-full justify-end">
                    <img
                      src={fetchedEventDetail?.publisherImage}
                      className={`rounded-full h-10 w-10 mr-2 ${
                        !fetchedEventDetail?.publisherImage && 'bg-gray-400'
                      }`}
                      alt={`${
                        fetchedEventDetail?.publisherImage
                          ? 'Publisher Avatar'
                          : ''
                      }`}
                    />

                    <div className="flex flex-col justify-center">
                      <p className="text-xs font-semibold text-gray-400">
                        !!Postat av
                      </p>
                      <p className="font-bold text-xs">
                        {fetchedEventDetail?.publisherName}
                      </p>
                      <p className="text-gray-700 text-xs">{createdAt}</p>
                    </div>
                  </div>
                </div>
              )}

              {/************************************* Makw a resppnse yes/no for req a res events ************************************ */}
              <div className={`h-3/7 pb-2 flex flex-col justify-between `}>
                <div>
                  {fetchedEventDetail?.bookingStatus === 'not_replied' && (
                    <div className="flex flex-col items-center justify-center w-full">
                      <p className="font-semibold pb-2">
                        {t('events.willYouParticipate')}
                      </p>
                      <div className="flex justify-center items-center gap-2">
                        <Button
                          size="sm"
                          variant="borderRed"
                          label={t('general.no') + ' 👎'}
                          onClick={async (e) => {
                            e.stopPropagation()
                            await handleDeclineParticipatingInEvent()
                          }}
                        />
                        <Button
                          size="sm"
                          variant="borderGreen"
                          label={t('general.yes') + ' 👍'}
                          onClick={async (e) => {
                            e.stopPropagation()
                            await handleAcceptParticipatingInEvent()
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/*************************************** Response status for req a res events ********************************** */}
                <div>
                  {fetchedEventDetail?.bookingStatus === 'fully_booked' && (
                    <p className="flex w-[24%] mx-[38%] justify-center font-bold text-xl h-[75%] items-end">
                      {t('events.fullyBooked') + '  🥳'}
                    </p>
                  )}
                </div>
                {/************************************************************************* */}
                {handleEditParticipating(fetchedEventDetail)}
                {/************************************************************************* */}
                <div>
                  {fetchedEventDetail?.statusSummary &&
                    fetchedEventDetail.type !== EVENT3_TYPE.BOOKING && (
                      <div
                        id="status-summary"
                        className="flex justify-center w-full items-center gap-2 mt-2 text-sm"
                      >
                        <FontAwesomeIcon
                          size="sm"
                          icon={faCircleCheck}
                          className="text-[#6BBD73]"
                        />
                        <p className="font-bold">
                          {fetchedEventOverview?.acceptedCount}
                        </p>
                        <FontAwesomeIcon
                          size="sm"
                          icon={faCircleXmark}
                          className="text-red-400"
                        />
                        <p className="font-bold">
                          {fetchedEventOverview?.declinedCount}
                        </p>
                        <FontAwesomeIcon
                          icon={faCircleExclamation}
                          size="sm"
                          className="text-purple-400"
                        />
                        <p className="font-bold">
                          {fetchedEventOverview?.notRepliedCount}
                        </p>
                        <div className="rounded-full w-3 h-3 bg-gray-400 flex items-center justify-center">
                          <FontAwesomeIcon
                            size="2xs"
                            icon={faEnvelope}
                            className="text-gray-100"
                          />
                        </div>
                        <p className="font-bold">
                          {fetchedEventOverview?.hasMessageCount}
                        </p>
                      </div>
                    )}
                </div>
                {validUserToSeeOverview &&
                  (fetchedEventDetail?.type === 'info' ||
                    fetchedEventDetail?.type === 'important') && (
                    <div className={`flex justify-center `}>
                      <div className="flex items-center text-sm">
                        <FontAwesomeIcon
                          size="xs"
                          icon={faEye}
                          className="text-green-400 px-1"
                        />
                        {t('documents.read') + ': '}
                        <p className="font-bold pl-2">
                          {fetchedEventOverview?.readCount}
                        </p>
                      </div>
                      <div className="flex items-center text-sm">
                        <FontAwesomeIcon
                          size="xs"
                          icon={faEyeSlash}
                          className="text-gray-400 px-1"
                        />
                        {t('documents.unread') + ': '}
                        <p className="font-bold pl-2">
                          {fetchedEventOverview?.unreadCount}
                        </p>
                      </div>
                    </div>
                  )}
                {/************************************************************************* */}
                {fetchedEventDetail?.type === EVENT3_TYPE.BOOKING ? (
                  <div className={`flex justify-center w-full p-1 `}>
                    <Button
                      className="w-[200px]"
                      size="sm"
                      variant="borderBlue"
                      label={t('absence.change')}
                      onClick={(e) => {
                        e.stopPropagation()
                        setOverviewVisible(!isOverviewVisible)
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    {currentUserRole?.role_type &&
                      currentUserRole?.role_type > USER_ROLE_TYPE.PARENT && (
                        <div className={`flex justify-center w-full p-1 `}>
                          <Button
                            className="w-[200px]"
                            size="sm"
                            variant="fullBlue"
                            fontSize="font-bold"
                            label={t('documents.overview')}
                            onClick={(e) => {
                              e.stopPropagation()
                              setOverviewVisible(!isOverviewVisible)
                            }}
                          />
                        </div>
                      )}
                  </div>
                )}

                {/************************************************************************* */}
                {/****************************** Comments inside Info Event ******************************* */}

                {hasComments && (
                  <div className="w-full">
                    <p className="font-semibold text-gray-500 pl-2 w-full mb-2 text-sm">
                      {fetchedEventDetail?.comments
                        ? fetchedEventDetail?.comments.length
                        : 0}{' '}
                      {t('blog.comments')}
                    </p>
                    {sortedComments?.map((comment) => (
                      <CommentDetailCard {...comment} key={comment.id} />
                    ))}
                  </div>
                )}

                {/* ********************************* Add comment in Info event *************************************** */}

                {fetchedEventDetail?.onlyStaff && infoType && (
                  <div
                    className={`flex items-center justify-center  w-[100%]  h-14 sticky bottom-0 -mb-2 backdrop-blur-sm`}
                  >
                    <CommentInput
                      eventTimeId={fetchedEventDetail.eventTimeId}
                      eventGroupInfoId={fetchedEventDetail.eventGroupInfoId}
                      refreshDetail={() => triggerHasResponded?.()}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : fetchedEventDetail?.statusSummary ? (
          <EventReqResOverview
            eventDetail={fetchedEventDetail}
            setOverviewVisible={setOverviewVisible}
            isOverviewVisible={isOverviewVisible}
            eventOverview={fetchedEventOverview}
            refreshDetail={() => triggerHasResponded?.()}
            hasResponded={hasResponded}
          />
        ) : (
          <EventOverview
            eventDetail={fetchedEventDetail}
            setOverviewVisible={setOverviewVisible}
            isOverviewVisible={isOverviewVisible}
            eventOverview={fetchedEventOverview}
            refreshDetail={() => triggerHasResponded?.()}
          />
        )}
        <div className="flex items-center justify-center">
          {isPopupVisible && (
            <EventParticipatingPopUp
              eventDetail={fetchedEventDetail}
              onClose={handleClosePopup}
              refreshDetail={() => triggerHasResponded?.()}
            />
          )}
        </div>
      </>
    )
  },
)
