import React, { useContext, useEffect, useState } from 'react'
import Page from '../../../../components/Page'

import ModalRegular from '../../../../components/ModalRegular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { Slider } from '../../../../components/Slider'
import { Button } from '../../../../components/Button'
import Swal from 'sweetalert2'
import { t } from 'i18next'
import ColorCard from '../../../../components/ColorCard'

import { NavigationStoreContext } from '../../../../store/navigationStore'
import { AdminStoreContext } from '../../../../store/adminStore'
import { USER_ROLE_STATUS, USER_ROLE_TYPE } from '../../../../Enums/TyraEnums'
import { UserRole } from '../../../../Models/UserRole'
import VHModal from '../components/VHModal'
import { VHCard } from '../components/VHCard'
import { TeacherCard } from '../components/TeacherCard'
import TeacherModal from '../components/TeacherModal'
import { Loading } from '../../../../components/Loading'

export const WaitingApproval: React.FC<{ onClick?: any }> = ({ onClick }) => {
  const [loading, setLoading] = useState(false)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [isBlueBackground, setIsBlueBackground] = useState(false)
  const [showWaitingApprovalModal, setShowWaitingApprovalModal] =
    useState<UserRole | null>(null)
  const [showWaitingApprovalVHModal, setShowWaitingApprovalVHModal] =
    useState<UserRole | null>(null)
  const { fetchPendingUserRoles, pendingUserRoles } =
    useContext(AdminStoreContext)

  const handleClick = () => {
    setIsBlueBackground(!isBlueBackground)
  }
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setLoading(true)
    setTitle(t('admin.waitingAccept'))
    fetchPendingUserRoles().finally(() => {
      setLoading(false)
    })
  }, [t])
  let renderedVhs: string[] = []
  return (
    <Page naked>
      {loading && <Loading />}

      <ColorCard
        bgColor="bg-pink-200 p-4 justify-center flex"
        title={t('documents.staff')}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 ml-5">
          {(() => {
            /*const pendingNonParentUsers = pendingUserRoles?.filter(
                (userRole: UserRole) =>
                  userRole.role_type > USER_ROLE_TYPE.PARENT,
              )*/

            if (
              pendingUserRoles &&
              pendingUserRoles.filter(
                (userRole: UserRole) =>
                  userRole.role_type > USER_ROLE_TYPE.PARENT,
              ).length > 0
            ) {
              return pendingUserRoles
                .filter(
                  (userRole: UserRole) =>
                    userRole.role_type > USER_ROLE_TYPE.PARENT,
                )
                .map((userRole) => (
                  <>
                    <TeacherCard
                      onClick={() => {
                        setShowWaitingApprovalModal(userRole)
                      }}
                      title=""
                      type="waiting"
                      userRole={userRole}
                    />
                    {/*<InvitedUsersCard
                        onClick={() => {
                          setShowWaitingApprovalModal(true)
                        }}
                        title=""
                      />
                      <WaitingApprovalModal
                        isOpen={showWaitingApprovalModal}
                        onClose={() => {
                          setShowWaitingApprovalModal(null)
                        }}
                      />*/}
                  </>
                ))
            } else {
              return (
                <div className="col-span-full place-items-center">
                  <div className="p-10 text-center">
                    <>{t('admin.noUsersAccept')}</>
                  </div>
                </div>
              )
            }
          })()}
        </div>
        {showWaitingApprovalModal && (
          <TeacherModal
            isOpen={showWaitingApprovalModal ? true : false}
            onClose={() => {
              setShowWaitingApprovalModal(null)
            }}
            type="waiting"
            userRole={showWaitingApprovalModal}
          />
        )}
      </ColorCard>

      <ColorCard
        bgColor="bg-pink-200 p-4 justify-center flex"
        title={t('general.guardian')}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 ml-5">
          {pendingUserRoles && pendingUserRoles.length ? (
            pendingUserRoles
              .filter(
                (userRole) => userRole.role_type === USER_ROLE_TYPE.PARENT,
              )
              .map((userRole) => {
                if (renderedVhs.includes(userRole.user?.id ?? 'xyz')) {
                  return null
                }
                if (userRole.user) {
                  renderedVhs.push(userRole.user.id)
                }

                return (
                  <>
                    <VHCard
                      onClick={() => {
                        setShowWaitingApprovalVHModal(userRole)
                      }}
                      title=""
                      userRole={userRole}
                      type="waiting"
                      allUserRolesForUser={pendingUserRoles.filter(
                        (uR) => uR.user?.id === userRole.user?.id,
                      )}
                    />
                  </>
                )
              })
          ) : (
            <div className="col-span-full place-items-center">
              <div className="p-10 text-center">
                <>{t('admin.noUsersAccept')}</>
              </div>
            </div>
          )}
        </div>

        {showWaitingApprovalVHModal && (
          <VHModal
            isOpen={showWaitingApprovalVHModal ? true : false}
            onClose={() => {
              setShowWaitingApprovalVHModal(null)
            }}
            userRole={showWaitingApprovalVHModal}
            allUserRolesForUser={
              pendingUserRoles?.filter(
                (uR) => uR.user?.id === showWaitingApprovalVHModal.user?.id,
              ) ?? []
            }
            type="waiting"
          />
        )}
      </ColorCard>
    </Page>
  )
}
