import Parse from 'parse'
import { boolean } from 'yup'
import { ARCHIVE_STATE } from '../Enums/TyraEnums'
import { Kid } from './Kid'
import { NoteComment } from './NoteComment'
import { School } from './School'
import { User } from './User'
import { UserRole } from './UserRole'

export class Note extends Parse.Object {
  public static readonly Fields = {
    school: 'school',
    className: 'notes',
    shared: 'shared',
    dest_type: 'dest_type',
    notice_user_id: 'notice_user_id',
    notes_comments: 'notes_comments',
    edit_locked: 'edit_locked',
  }
  constructor() {
    super('notes')
  }

  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
  get shared(): boolean {
    return this.get('shared')
  }
  set shared(value: boolean) {
    this.set('shared', value)
  }
  get edit_locked(): boolean {
    return this.get('edit_locked')
  }
  set edit_locked(value: boolean) {
    this.set('edit_locked', value)
  }
  get kid_owner(): Kid {
    return this.get('kid_owner')
  }
  set kid_owner(value: Kid) {
    this.set('kid_owner', value)
  }
  get notes_comments(): NoteComment[] | undefined {
    return this.get('notes_comments')
  }
  get archive_state(): ARCHIVE_STATE | undefined {
    return this.get('archive_state')
  }
  set archive_state(value: ARCHIVE_STATE | undefined) {
    value ? this.set('archive_state', value) : this.unset('archive_state')
  }
  get notes_topic(): string {
    return this.get('notes_topic')
  }
  get notes_owner(): User | undefined {
    return this.get('notes_owner')
  }
  get author(): UserRole {
    return this.get('author')
  }
  get createdAt(): Date {
    return this.get('createdAt')
  }
  get editedAt(): Date {
    return this.get('editedAt')
  }
  get notes_edited(): Date {
    return this.get('notes_edited')
  }
  get is_favorite(): string[] | undefined {
    return this.get('is_favorite')
  }
  get revision(): number {
    return this.get('revision')
  }

  /*get main_note(): string | undefined {
    return this.notes_comments?.find(
      (note: NoteComment) => note.is_main == true,
    )?.comment
  }*/

  // * Signal variables *
  get dest_type(): number {
    return this.get('dest_type')
  }
  get notice_user_id(): string {
    return this.get('notice_user_id')
  }
}
Parse.Object.registerSubclass('notes', Note)
