import React, { useContext, useEffect } from 'react'
import { UserStoreContext } from '../../store/userStore'
import { useNavigate } from 'react-router-dom'

export const SelectDashboard: React.FC = () => {
  const { selectFirstUserRole, savedUserRoleId } = useContext(UserStoreContext)
  const navigate = useNavigate()
  useEffect(() => {
    if (!savedUserRoleId) {
      selectFirstUserRole().then((id) => {
        navigate('/dashboard/' + id)
      })
    } else {
      navigate('/dashboard/' + savedUserRoleId)
    }
  }, [])

  // todo some loading?
  return <div></div>
}
