import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { UserRole } from '../Models/UserRole'

class DragAndDropStore {
  dragging: boolean = false
  constructor() {
    makeAutoObservable(this)
  }

  setDragging = (dragging: boolean) => {
    this.dragging = dragging
  }
}
export const dragAndDropStore = (() => {
  return new DragAndDropStore()
})()
export const DragAndDropStoreContext: React.Context<DragAndDropStore> =
  React.createContext(dragAndDropStore)
