import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { School } from '../Models/School'
import { BloggDoc } from '../Models/BloggDoc'
import { BloggComments } from '../Models/BloggComments'
import { Kid } from '../Models/Kid'
import { Department } from '../Models/Department'
import { DocTag } from '../Models/DocTag'
import { userStore } from './userStore'
import { BloggReflections } from '../Models/BloggReflections'
import { User } from '../Models/User'
import { USER_ROLE_TYPE } from '../Enums/TyraEnums'
import { UserRole } from '../Models/UserRole'

class PortfolioBlogStore {
  bloggDocs?: BloggDoc[]
  bloggDocsCount?: number
  portfolioDocs?: BloggDoc[]
  selectedComments?: BloggComments[]
  tags?: DocTag[]
  selectedTags?: DocTag[]
  blogDocReflections?: BloggReflections[]
  constructor() {
    makeAutoObservable(this)
  }

  setTags = (tags: DocTag[]) => {
    this.tags = tags
  }

  setSelectedTags = (tags: DocTag[]) => {
    this.selectedTags = tags
  }

  setBloggDocs = (docs: BloggDoc[]) => {
    this.bloggDocs = docs
  }

  setBloggDocsCount = (bloggDocsCount: number) => {
    this.bloggDocsCount = bloggDocsCount
  }
  setPortfolioDocs = (docs: BloggDoc[]) => {
    this.portfolioDocs = docs
  }

  setSelectedComments = (comments: BloggComments[]) => {
    this.selectedComments = comments
  }
  setBlogDocReflections = (reflections: BloggReflections[]) => {
    this.blogDocReflections = reflections
  }

  fetchKid = async (kidId: string) => {
    if (kidId) {
      const kid = await new Parse.Query(Kid).get(kidId)
      return kid
    }
  }

  fetchPortfolio = async ({
    school,
    startDate,
    endDate,
    kid,
    selectedTags,
    skip,
  }: {
    school: School
    startDate?: Date
    endDate?: Date
    kid?: Kid
    selectedTags?: DocTag[]
    skip?: number
  }) => {
    /*const kidsConnected = []
    kidsConnected.push(kid)

    const parseQueryKidOwner = new Parse.Query(BloggDoc).equalTo(
      'kid_owner',
      kid,
    )

    const parseQueryPortKidsConnected = new Parse.Query(BloggDoc).containedIn(
      'port_kids_connected',
      kidsConnected,
    )

    const blogQuery = Parse.Query.or(
      parseQueryKidOwner,
      parseQueryPortKidsConnected,
    )
      .equalTo('school_pointer', school)
      .equalTo('type', 3)
      .include('images')
      .include('author.user')
      .include('doc_tags')
      .include('curriculums')
      .descending('createdAt')

    if (selectedTags?.length) {
      blogQuery.containedIn('doc_tags', selectedTags)
    }
    if (startDate) {
      blogQuery.greaterThanOrEqualTo('createdAt', startDate)
    }
    if (endDate) {
      blogQuery.lessThan('createdAt', endDate)
    }

    const docs = await blogQuery.find()*/
    const docs = await Parse.Cloud.run('getPortfolioItems', {
      schoolId: school.id,
      kidId: kid?.id,
      tagIds: selectedTags?.map((tag) => tag?.id) ?? [],
      startDate: startDate,
      endDate: endDate,
      skip,
    })
    this.setPortfolioDocs(docs)
  }

  fetchTags = async (schoolId: string) => {
    const tags = await Parse.Cloud.run('getTags', {
      schoolId,
    })
    this.setTags(tags)
  }

  fetchBlog = async ({
    schoolId,
    departmentPointers,
    selectedTags,
    startDate,
    endDate,
    skip,
    limit = 10,
    currentUserRole,
  }: {
    schoolId: string
    departmentPointers?: Department[]
    selectedTags?: DocTag[]
    startDate?: Date
    endDate?: Date
    skip?: number
    limit?: number
    currentUserRole: UserRole
  }) => {
    let kidIds: string[] = []

    //const currentUserRole = userStore.currentUserRole

    if (currentUserRole?.role_type === USER_ROLE_TYPE.PARENT) {
      const kids = await userStore.fetchKidsForUserOnSchool(schoolId)

      if (kids) {
        for (const kid of kids) {
          if (kid) {
            kidIds.push(kid.id)
          }
        }
      }
    }
    console.log('currentUser', currentUserRole)

    const docs = await Parse.Cloud.run('getBlogItems', {
      schoolId,
      departmentIds: currentUserRole?.kid
        ? currentUserRole.kid.departmentPointer.id
        : departmentPointers?.map((dep) => dep?.id) ?? [],
      tagIds: selectedTags?.map((tag) => (tag?.id ? tag?.id : tag)) ?? [],
      startDate,
      endDate,
      skip,
      limit,
      kidIds: kidIds.length ? kidIds : undefined,
    })
    if (skip && skip > 0) {
      this.setBloggDocs([...(this.bloggDocs ?? []), ...docs?.docs])
    } else {
      this.setBloggDocs(docs?.docs)
    }
    this.setBloggDocsCount(docs?.count)
  }

  fetchComments = async (bloggDoc: BloggDoc) => {
    const comments = await Parse.Cloud.run('getBlogComments', {
      blogId: bloggDoc.id,
    })

    this.setSelectedComments(comments)
    /*
    const commentsQuery = new Parse.Query(BloggComments).equalTo(
      'blogg_doc',
      bloggDoc,
    )
    const comments = await commentsQuery.find()
    this.setSelectedComments(comments)*/
  }

  fetchReflections = async (bloggIds: string[]) => {
    /*const reflectionsQuery = await new Parse.Query(BloggReflections)
    reflectionsQuery
      .containedIn('blogg_doc', bloggIds)
      .include('user_author')
      .doesNotExist('archive_state')

    const reflections = await reflectionsQuery.find()*/

    const reflections = await Parse.Cloud.run('getBlogReflections', {
      blogIds: bloggIds,
    })

    this.setBlogDocReflections(reflections)
  }

  createReflection = async (bloggDoc: BloggDoc, value: string, user: User) => {
    const reflection = new BloggReflections()
    reflection.message = value
    reflection.user_author = user
    reflection.blogg_doc = bloggDoc
    reflection.school_pointer = bloggDoc.attributes.school_pointer
    reflection.school = bloggDoc.attributes.school
    reflection.edited_date = new Date()
    reflection.kid_owner_id = bloggDoc.attributes.kid_owner?.id

    bloggDoc.increment('reflections_count', +1)

    reflection.save()
    bloggDoc.save()
  }

  deleteReflection = async (
    bloggDoc: BloggDoc,
    reflection: BloggReflections,
    user: Parse.User,
  ) => {
    reflection.set('archive_state', 400)
    reflection.set('archived_at', new Date())
    reflection.set('archived_by_user', user as User)
    await reflection.save()
    bloggDoc.increment('reflections_count', -1)

    await bloggDoc.save()
  }

  addNewComment = async (bloggDoc: BloggDoc, comment: string) => {
    // set & get på BloggComments behövs
    const currentUserRole = userStore.currentUserRole
    const currentUser = userStore.currentUser
    const newComment = new BloggComments()
    newComment.set('message', comment)
    newComment.set('edited_date', new Date())
    newComment.set('school_pointer', currentUserRole?.school_pointer)
    newComment.set('school', currentUserRole?.school)
    newComment.set('blogg_doc', bloggDoc)
    newComment.set('user_author', currentUser)

    const crementCommentCount = await Parse.Cloud.run('crementCommentCount', {
      bloggDocId: bloggDoc.id,
      doIncrement: 1,
    })

    await newComment.save()
  }
  editComment = async (comment: BloggComments, editedMessage: string) => {
    comment.set('message', editedMessage)
    comment.set('edited_date', new Date())

    await comment.save()
  }

  deleteComment = async (comment: BloggComments, user: Parse.User) => {
    comment.set('archive_state', 400)
    comment.set('archived_at', new Date())
    comment.set('archived_by_user', user as User)
    await comment.save()
    const crementCommentCount = await Parse.Cloud.run('crementCommentCount', {
      bloggDocId: comment.attributes.blogg_doc.id,
      doIncrement: 0,
    })
  }

  updateReflection = async (bloggDoc: BloggDoc, newReflection: string) => {
    bloggDoc.set('blog_reflection', newReflection)
    await bloggDoc.save()
  }

  editReflection = async (ref: BloggReflections, message: string) => {
    ref.set('message', message)
    ref.set('edited_date', new Date())

    await ref.save()
  }
  updateHeart = async (bloggDoc: BloggDoc, value: boolean) => {
    const currentUser = userStore.currentUser
    /*if (value) {
      bloggDoc.set(
        'emote_heart',
        bloggDoc.attributes.emote_heart.filter(
          (userId: string) => userId !== currentUser?.id,
        ),
      )
    } else {
      bloggDoc.add('emote_heart', currentUser?.id)
    }*/

    const emote = await Parse.Cloud.run('emote', {
      bloggDocId: bloggDoc.id,
      emoteType: value ? 0 : 1,
      userId: currentUser?.id,
    }).finally(async () => {
      await bloggDoc.fetch()
    })
  }
}

export const portfolioBlogStore = (() => {
  return new PortfolioBlogStore()
})()
export const PortfolioBlogStoreContext: React.Context<PortfolioBlogStore> =
  React.createContext(portfolioBlogStore)
