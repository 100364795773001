import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { School } from '../Models/School'
import { Department } from '../Models/Department'
import { Kid } from '../Models/Kid'
import { userStore } from './userStore'
import { USER_ROLE_TYPE } from '../Enums/TyraEnums'

class KidStore {
  kids?: Kid[]
  kid?: Kid | null
  selectedKids?: Kid[]
  kidsForEvent3Booking?: Kid[]
  constructor() {
    makeAutoObservable(this)
  }

  setSelectedKids = (kids: Kid[]) => {
    this.selectedKids = kids
  }

  setKids = (kids: Kid[]) => {
    this.kids = kids
  }
  setKid = (kid: Kid) => {
    this.kid = kid
  }

  setKidsForEvent3Booking = (kids: Kid[]) => {
    this.kidsForEvent3Booking = kids
  }

  fetchKid = async (kidId: string) => {
    const kidQuery = new Parse.Query(Kid).equalTo('objectId', kidId)
    const kid = await kidQuery.first()
    if (kid) {
      this.setKid(kid)
    }
  }

  fetchKids = async (
    schoolId: string,
    departmentPointers: Department[] = [],
  ) => {
    const schoolQuery = new Parse.Query(School)
    const school = await schoolQuery.get(schoolId)
    const kidsQuery = new Parse.Query(Kid)
      .equalTo('school_pointer', school)
      /*.exclude('kid_pers_id_num')*/
      .select('objectId', 'kidName', 'departmentPointer.id')
      .include('departmentPointer')
      .descending('date')
      .limit(10000)
    if (departmentPointers.length) {
      kidsQuery.containedIn('departmentPointer', departmentPointers)
    }

    const kids = await kidsQuery.find()
    this.setKids(kids)
  }
  fetchKidsForEvent3Booking = async (departmentIds: string[]) => {
    if (
      userStore.currentUserRole &&
      userStore.currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
    ) {
      const kidsQuery = new Parse.Query(Kid)
        /*.exclude('kid_pers_id_num')*/
        //.select('objectId', 'kidName', 'departmentPointer.id')
        .include('departmentPointer')
        .descending('date')
        .limit(10000)
      kidsQuery.containedIn(
        'departmentPointer',
        departmentIds.map((depId) => Department.createWithoutData(depId)),
      )

      const kids = await kidsQuery.find()
      this.setKidsForEvent3Booking(kids)
    }
  }
}

export const kidStore = (() => {
  return new KidStore()
})()
export const KidStoreContext: React.Context<KidStore> =
  React.createContext(kidStore)
