import React, { useContext, useEffect, useState } from 'react'
import Page from '../../components/Page'
import ColorCard from '../../components/ColorCard'
import AdminCard from '../admin/adminDashboard/components/AdminCard'
import {
  faArrowDownToLine,
  faBell,
  faBook,
  faBracketRound,
  faEnvelope,
  faFile,
  faFileLines,
  faFilePdf,
  faInfoCircle,
  faPhone,
  faThumbsUp,
  faUsersCog,
  faWarning,
} from '@fortawesome/pro-solid-svg-icons'
import { Button } from '../../components/Button'
import { UserStoreContext } from '../../store/userStore'
import { useNavigate, useParams } from 'react-router-dom'
import { t } from 'i18next'
import { faLightSwitch } from '@fortawesome/pro-regular-svg-icons'
import { getDashboardTitle, getImageFromRole } from '../../utils/parseUtils'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import ModalRegular from '../../components/ModalRegular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailForward } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import CustomInput from '../../components/CustomInput'
import { ImageUploader } from '../../components/ImageUploader'
import { KidStoreContext } from '../../store/kidStore'
import { KidsStoreContext } from '../../store/kidsStore'
import { SkaCard } from '../../Models/SkaCard'
import Card from '../../components/Card'
import DownloadCard from './components/DownloadCard'
import { FileJobStoreContext } from '../../store/fileJobStore'
import { FILE_JOB_STATUS, FileJob } from '../../Models/FileJob'

interface SettingsProps {
  // Om du har några props som komponenten behöver, definiera dem här
}

export const Settings: React.FC<{ onClick?: any }> = ({ onClick }) => {
  // Här kan du skriva din komponents kod

  const { logoutUser, currentUser } = useContext(UserStoreContext)
  const navigate = useNavigate()
  const { fetchUserRole, currentUserRole, readOnly } =
    useContext(UserStoreContext)
  const [showDocAndLathunnd, setShowDocAndLathund] = useState(false)
  const { schoolId } = useParams()
  const [showAboutPage, setShowAboutPage] = useState(false)
  const [openParse, setOpenParse] = useState(false)
  const [uploadedImage, setUploadedImage] = useState<File | null>(null)
  const handleImageChange = (file: File) => {
    setUploadedImage(file)
    // Gör något annat med bilden om du vill, till exempel skicka den till en server.
  }
  const statusOrder: { [key in FILE_JOB_STATUS]: number } = {
    [FILE_JOB_STATUS.QUEUED]: 1,
    [FILE_JOB_STATUS.IN_PROGRESS]: 2,
    [FILE_JOB_STATUS.SUCCESS]: 3,
    [FILE_JOB_STATUS.OPENED]: 4,
    [FILE_JOB_STATUS.ERROR]: 5,
  }
  const copyToClipboard = (text: any) => {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }
  const { fileJobs, fetchFileJobs } = useContext(FileJobStoreContext)

  const { fetchUserRoles } = useContext(KidsStoreContext)
  const handleIconClick = (contentToCopy: any) => {
    copyToClipboard(contentToCopy)

    Swal.fire({
      title: t('general.copied'),
      text: `${contentToCopy} har kopierats!`,
      icon: 'success',
      confirmButtonText: 'Okej',
      confirmButtonColor: '#7eb5f4',
    })
  }
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (currentUserRole && currentUserRole?.school_pointer) {
      setLoading(true)
      fetchUserRoles(currentUserRole).finally(() => {
        setLoading(false)
      })
      fetchFileJobs()
    }
  }, [currentUserRole?.id])
  useEffect(() => {
    if (schoolId) {
      fetchUserRole(schoolId)
    }
  }, [schoolId])
  const [showDownloads, setShowDownloads] = useState(false)
  const initialImage = getImageFromRole(currentUserRole)
  const [editModal, setEditModal] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  useEffect(() => {
    currentUser?.attributes.user_extra.fetch()
  })
  return (
    <Page naked topBar={''}>
      <ColorCard title={t('general.settings')} bgColor="bg-eventsMain">
        <div>
          <div className="p-10 border-b bg-gray-50 shadow rounded-2xl mt-5">
            <div className="flex flex-row items-center gap-x-8 justify-center">
              <div>
                <ImageUploader
                  onImageChange={handleImageChange}
                  initialImageUrl={initialImage}
                  size="lg"
                />
                {/* Om du vill visa den uppladdade bilden någon annanstans i din komponent. */}
                {uploadedImage && (
                  <img
                    src={URL.createObjectURL(uploadedImage)}
                    alt="Uploaded"
                    className="...some-styles-here"
                  />
                )}
              </div>
              <div className="flex flex-col items-center">
                <div>
                  {' '}
                  {currentUser?.attributes?.firstName + ' '}
                  {currentUser?.attributes?.lastName}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full flex-wrap justify-center mt-10">
            <AdminCard
              title="Mina nedladdningar"
              onClick={() => {
                setShowDownloads(true)
              }}
              icon={faArrowDownToLine}
              iconClassName="text-black"
              className="hover:bg-gray-50"
            />
            <ModalRegular
              isOpen={showDownloads}
              onClose={() => {
                setShowDownloads(false)
              }}
              title={t('blog.myDownloads')}
              bgColor="bg-gray-400 p-4"
              closeColor="white"
            >
              <div className="flex flex-col ">
                <div className="flex flex-wrap justify-center">
                  <div className="flex flex-col">
                    <>
                      {fileJobs
                        ?.sort(
                          (a, b) =>
                            statusOrder[a.status] - statusOrder[b.status],
                        )
                        .map((fileJob: any, index: number) =>
                          isEmpty ? (
                            <div className="p-10 text-center">
                              <>{t('documents.empty')}</>
                            </div>
                          ) : (
                            <DownloadCard
                              key={fileJob.id}
                              iconleft={faFilePdf}
                              iconClassName="text-red-500"
                              title={fileJob.name}
                              description={`Status: ${fileJob.status}`}
                              fileJobStatus={fileJob.status}
                              pdfUrl={fileJob?.file?._url}
                              updatedAt={fileJob.updatedAt} // Pass the updatedAt date here
                            />
                          ),
                        )}
                    </>
                  </div>
                </div>
              </div>
            </ModalRegular>

            <ModalRegular
              isOpen={showAboutPage}
              onClose={() => {
                setShowAboutPage(false)
              }}
            >
              <div className="flex flex-col ">
                <div className="flex flex-row w-full justify-center">
                  <img
                    className={'w-full max-w-[230px] object-contain mt-12'}
                    src={process.env.PUBLIC_URL + '/images/TYRA_logo_text.png'}
                  />

                  <img
                    className={
                      'w-full animate-wave mt-2 max-w-[110px] object-contain'
                    }
                    src={process.env.PUBLIC_URL + '/images/hand.png'}
                    alt="tyra logo"
                  />
                </div>
                <h1 className="text-eventsMain font-bold flex flex-row w-full justify-center ">
                  <>{t('settings.completeApp')}</>
                </h1>
                <div className="flex flex-row w-full my-8 justify-center items-center">
                  <h2 className="flex justify-center items-center text-start max-w-[400px] relative">
                    <p className="flex flex-col">
                      <>{t('settings.aboutText')}</>
                    </p>
                  </h2>
                </div>

                <div className="flex flex-row  gap-x-20 mt-4 justify-center">
                  <div className="flex flex-col items-center">
                    <FontAwesomeIcon
                      className="text-eventsMain cursor-pointer hover:scale-105"
                      size="3x"
                      icon={faEnvelope}
                      onClick={() => handleIconClick('support@tyra.io')}
                      title={t('general.copyEmail')}
                    />
                    <h3 className="cursor-pointer text-eventsMain font-bold flex items-center">
                      support@tyra.io
                    </h3>
                  </div>
                  <div className="flex flex-col items-center">
                    <FontAwesomeIcon
                      className="text-eventsMain cursor-pointer hover:scale-105"
                      size="3x"
                      icon={faPhone}
                      onClick={() => handleIconClick('+46 40-869 10')}
                      title={t('general.copyNumber')}
                    />
                    <h3 className="cursor-pointer text-eventsMain font-bold flex items-center">
                      +46 40-869 10
                    </h3>
                  </div>
                </div>
                <div className="flex flex-row justify-center py-10">
                  <a
                    href="https://m.facebook.com/tyraappen?wtsid=rdr_0i0dRiSEBRYi7Tj8v"
                    target="_blank"
                  >
                    <Button
                      variant="faceBook"
                      label={t('settings.likeOnFacebook')}
                      icon={faThumbsUp}
                      reverseIcon={true}
                    />
                  </a>
                </div>
              </div>
            </ModalRegular>
            <ModalRegular
              isOpen={openParse}
              onClose={() => {
                setOpenParse(false)
              }}
              bgColor="bg-green-400 p-4"
              title={t('settings.switchaParseServer')}
              closeColor="white"
            >
              <div className=" flex items-center justify-center z-50">
                <div className="bg-white p-4 rounded-lg shadow-xl w-full md:max-w-md">
                  <div>!!Här ska server mapppas upp!!</div>
                  <div className="flex flex-col gap-2 justify-center">
                    {' '}
                    <Button title="enhär" label="enhär "></Button>
                    <Button title="enhär" label="enhär "></Button>
                    <Button title="enhär" label="enhär "></Button>
                    <Button title="enhär" label="enhär "></Button>
                    <Button title="enhär" label="enhär "></Button>
                  </div>
                </div>
              </div>
            </ModalRegular>
          </div>
          <div className="flex flex-row justify-center my-10">
            <Button
              variant="fullRed"
              label={t('login.logout')}
              size={'lg'}
              onClick={async () => {
                logoutUser().finally(() => {
                  navigate('/')
                })
              }}
            />
          </div>
        </div>
      </ColorCard>
    </Page>
  )
}
