import Parse from 'parse'

export const login = async (username: string, password: string) => {
  const parseUser = new Parse.User()

  parseUser.set('username', username)
  parseUser.set('password', password)

  try {
    const user = await parseUser.logIn()
    return user
  } catch (e) {
    console.log(e)
  }
}

export const logout = async () => {
  try {
    await Parse.User.logOut()
  } catch (e) {
    console.log(e)
  }
}
