import { useEffect, useRef } from 'react'

const MOUSEDOWN = 'mousedown'
const TOUCHSTART = 'touchstart'

type HandledEvents = [typeof MOUSEDOWN, typeof TOUCHSTART]
type HandledEventsType = HandledEvents[number]
type PossibleEvent = {
  [Type in HandledEventsType]: HTMLElementEventMap[Type]
}[HandledEventsType]
type Handler = (event: PossibleEvent) => void

const events: HandledEvents = [MOUSEDOWN, TOUCHSTART]

export function useClickOutside(
  // ref: React.RefObject<HTMLElement>,
  handler: Handler | null,
  // togglerRef?: React.RefObject<HTMLElement>,
) {
  const handlerRef = useRef(handler)
  const ref = useRef<HTMLDivElement>()
  const togglerRef = useRef<HTMLDivElement>()

  useEffect(() => {
    handlerRef.current = handler
  })

  useEffect(() => {
    if (!handler) {
      return
    }

    const listener = (event: PossibleEvent) => {
      if (
        togglerRef &&
        togglerRef.current &&
        togglerRef.current.contains(event.target as Node)
      ) {
        event.stopPropagation()
        return
      }
      if (
        !ref?.current ||
        !handlerRef?.current ||
        ref.current.contains(event.target as Node)
      ) {
        return
      }

      handlerRef.current(event)
    }

    events.forEach((event) => {
      document.addEventListener(event, listener)
    })

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, listener)
      })
    }
  }, [handler, ref, togglerRef])
  return [ref as any, togglerRef as any]
}

// type AnyRef =
//   | Ref<HTMLDivElement | HTMLButtonElement | HTMLLinkElement | undefined>
//   | LegacyRef<HTMLDivElement>
//   | undefined
