import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Button } from '../../../components/Button'
import { Slider } from '../../../components/Slider'
import { ButtonDropdown } from '../../../components/Dropdown'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import CustomInput from '../../../components/CustomInput'
import { UserStoreContext } from '../../../store/userStore'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import Swal from 'sweetalert2'
import { t } from 'i18next'
import { UserRole } from '../../../Models/UserRole'
import { getImageFromRole } from '../../../utils/parseUtils'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { translateRelation } from '../../admin/inviteHandleUsers/components/KidCard'
import { errorSwal, successSwal } from '../MyConnections'

export enum TYRA_RELATION {
  MOM = 'Mamma',
  DAD = 'Pappa',
  PARENT = 'Vårdnadshavare',
}

export const MyConnectionsCardKid: React.FC<{
  fullName?: string
  active: boolean
  userRole: UserRole
  showRemove?: boolean
  showApprove?: boolean
  showRelation?: boolean
  saveDirectly?: boolean
  cancelAction?: () => void
  approveAction?: () => void
}> = ({
  fullName,
  approveAction,
  cancelAction,
  active,
  userRole,
  showRemove,
  showApprove,
  showRelation,
  saveDirectly,
}) => {
  const { currentUserRole } = useContext(UserStoreContext)
  const { t } = useTranslation()
  const otherRef = useRef<HTMLInputElement>(null)
  const [customRelation, setCustomRelation] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [sliderState, setSliderState] = useState(false)
  const [menuRef, togglerRef] = useClickOutside(() => setIsOpen(false))
  const [openOtherModal, setOpenOtherModal] = useState(false)
  const handleSliderClick = () => {
    setSliderState(!sliderState)
  }

  const relationText = () => {
    if (userRole.tyraRelation) {
      return translateRelation(userRole.tyraRelation)
    }
    return t('settings.chooseRelation')
  }

  console.log('userRole', userRole)
  return (
    <div className="border p-4 rounded-2xl shadow flex w-full max-w-[400px] justify-between flex-col gap-y-4">
      <div className="flex flex-row items-center justify-between gap-x-10">
        <label className="relative flex items-center justify-center border rounded-full max-w-[60px] bg-gray-300  max-h-[60px]  min-w-[60px]  min-h-[60px] overflow-hidden">
          <img
            src={getImageFromRole(userRole)}
            className={'w-[60px] h-[60px] object-cover rounded-full'}
            alt=""
          />
        </label>

        {showRelation && (
          <div className="relative flex w-full flex-col">
            <button
              ref={togglerRef}
              type="button"
              className="w-full rounded-full group focus:outline-none hover:bg-gray-50"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <div className="flex px-4 py-2 border rounded-full w-full font-normal text-sm items-center justify-between gap-x-4">
                <p className="font-semibold"> {relationText()}</p>
                <FontAwesomeIcon
                  className={`text-gray-400 hover:text-gray-500 ${
                    isOpen ? 'animate-rotate-to-180' : 'animate-rotate-to-0'
                  }`}
                  icon={faChevronDown}
                />{' '}
              </div>
            </button>

            {isOpen && (
              <div
                ref={menuRef as RefObject<HTMLDivElement>}
                className="absolute right-6 z-50 mx-3 origin-top divide-y w-52 top-12"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <ButtonDropdown.Menu>
                  <ButtonDropdown.Section>
                    <>
                      <>
                        <ButtonDropdown.Button
                          label={t('documents.parent')}
                          onClick={async () => {
                            userRole.tyraRelation = TYRA_RELATION.PARENT

                            if (saveDirectly) {
                              await userRole
                                .save()
                                .then(() => {
                                  successSwal()
                                })
                                .catch((e) => {
                                  errorSwal()
                                })
                                .finally(() => {})
                            }
                            setIsOpen(false)
                          }}
                        />
                        <ButtonDropdown.Button
                          label={t('general.mother')}
                          onClick={async () => {
                            userRole.tyraRelation = TYRA_RELATION.MOM

                            if (saveDirectly) {
                              await userRole
                                .save()
                                .then(() => {
                                  successSwal()
                                })
                                .catch((e) => {
                                  errorSwal()
                                })
                                .finally(() => {})
                            }
                            setIsOpen(false)
                          }}
                        />
                        <ButtonDropdown.Button
                          label={t('general.father')}
                          onClick={async () => {
                            userRole.tyraRelation = TYRA_RELATION.DAD

                            if (saveDirectly) {
                              await userRole
                                .save()
                                .then(() => {
                                  successSwal()
                                })
                                .catch((e) => {
                                  errorSwal()
                                })
                                .finally(() => {})
                            }
                            setIsOpen(false)
                          }}
                        />
                        <ButtonDropdown.Button
                          label={t('settings.other')}
                          onClick={() => {
                            setOpenOtherModal(true)
                          }}
                        />
                      </>
                    </>
                  </ButtonDropdown.Section>
                </ButtonDropdown.Menu>
              </div>
            )}
          </div>
        )}
      </div>

      <div>{fullName}</div>

      <div>
        <>
          <div
            className={classNames(
              'flex',
              showApprove && showRemove ? 'justify-between' : 'justify-center',
            )}
          >
            {showRemove && (
              <Button
                size="sm"
                variant={'borderRed'}
                label={t('documents.remove') + ' ' + '😳'}
                onClick={async () => {
                  const result = await Swal.fire({
                    title: t('documents.remove') + '?',
                    text: t('admin.removeActiveOneKid')
                      .replace(
                        'XYC1337',
                        userRole?.user?.firstName +
                          ' ' +
                          userRole?.user?.lastName,
                      )
                      .replace('CYX7331', userRole?.kid?.attributes.kidName),
                    icon: 'info',
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    cancelButtonText: t('general.cancel'),
                    confirmButtonText: t('documents.remove'),
                  })
                  if (result.isConfirmed) {
                    if (cancelAction) {
                      cancelAction()
                    }
                  }
                }}
              />
            )}
            {showApprove && (
              <Button
                size="sm"
                variant={'fullBlue'}
                label={t('settings.accept')}
                onClick={() => {
                  if (approveAction) {
                    approveAction()
                  }
                }}
              />
            )}
          </div>
        </>
      </div>
      <ModalRegular
        isOpen={openOtherModal}
        onClose={() => {
          setCustomRelation('')
          setOpenOtherModal(false)
        }}
        bgColor="bg-eventsMain p-4"
        closeColor="white"
        title={t('settings.chooseRelation')}
      >
        <div className="flex flex-col p-4">
          <div>
            <p>
              <>{t('settings.otherInfo')}</>
            </p>
            <div>
              <CustomInput
                ref={otherRef}
                value={customRelation}
                onChange={(value) => {
                  setCustomRelation(value)
                }}
              />
            </div>
          </div>
          <div className="flex flex-row w-full justify-center mt-10">
            {' '}
            <Button
              onClick={async () => {
                if (customRelation && customRelation !== '') {
                  userRole.tyraRelation = customRelation
                  if (saveDirectly) {
                    await userRole
                      .save()
                      .then(() => {
                        successSwal()
                      })
                      .catch((e) => {
                        errorSwal()
                      })
                      .finally(() => {})
                  }
                }

                setCustomRelation('')
                setOpenOtherModal(false)
              }}
              variant="fullBlue"
              size="custom"
              label={t('general.done')}
            />
          </div>
        </div>
      </ModalRegular>
    </div>
  )
}
