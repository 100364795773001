import React, { useContext, useEffect, useState } from 'react'
import ModalRegular from '../../../components/ModalRegular'
import { Slider } from '../../../components/Slider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { t } from 'i18next'
import { absenceStore } from '../../../store/absenceStore'
import { UserStoreContext } from '../../../store/userStore'
import { SchoolStoreContext } from '../../../store/schoolStore'
import { useParams } from 'react-router-dom'

interface AbsenceModalAdminProps {
  onClose: () => void
  isOpen: boolean
}

export const AbsenceModalAdmin: React.FC<AbsenceModalAdminProps> = ({
  onClose,
  isOpen,
}) => {
  const { currentUserRole } = useContext(UserStoreContext)
  const {
    fetchSingleSchool,
    school,
    setAbsenceHasReportBack,
    setAbsenceNoLock,
  } = useContext(SchoolStoreContext)
  const { schoolId } = useParams()
  useEffect(() => {
    if (schoolId) {
      fetchSingleSchool(schoolId)
    }
  }, [schoolId])
  const [disableNoLock, setDisableNoLock] = useState(false)
  const [disableHasReportBack, setDisableHasReportBack] = useState(false)

  return (
    <ModalRegular
      onClose={onClose}
      isOpen={isOpen}
      bgColor="bg-red-300 p-4"
      title={t('absence.absence')}
      closeColor="white"
      className="bg-gray-50"
    >
      <div className="flex flex-wrap flex-row w-full items-start py-10">
        <div className="flex items-start">
          <h1 className="text-gray-600 font-bold text-xl m-2">
            <>{t('general.settings')}</>
          </h1>
        </div>
        <div className="flex flex-wrap flex-row w-full justify-between border-t border-b py-4 px-4 bg-white">
          <div className="flex flex-row items-center">
            <div>
              <FontAwesomeIcon className="text-green-500" icon={faLock} />
            </div>
            <div className="flex flex-wrap w-fit flex-shrink max-w-[500px] ml-8">
              <h1 className="text-gray-800 font-medium">
                <>{t('admin.absenceLock')}</>
              </h1>
              <h2 className="text-gray-500 font-light">
                <>{t('admin.absenceLockText')}</>
              </h2>
            </div>
          </div>
          <div className="flex items-center">
            <Slider
              value={
                school?.school_setting.attributes.absence_no_locking
                  ? false
                  : true
              }
              id={0}
              onSliderClick={() => {
                if (school && !disableNoLock) {
                  setDisableNoLock(true)
                  setAbsenceNoLock(
                    school,
                    school?.school_setting.attributes.absence_no_locking
                      ? false
                      : true,
                  ).finally(() => {
                    setDisableNoLock(false)
                  })
                }
              }}
              onBoxClick={() => {
                if (school && !disableNoLock) {
                  setDisableNoLock(true)
                  setAbsenceNoLock(
                    school,
                    school?.school_setting.attributes.absence_no_locking
                      ? false
                      : true,
                  ).finally(() => {
                    setDisableNoLock(false)
                  })
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-wrap flex-row w-full justify-between border-b py-4 px-4 bg-white">
          <div className="flex flex-row items-center">
            <div>
              <FontAwesomeIcon className="text-blue-500" icon={faUserCheck} />
            </div>
            <div className="flex flex-wrap w-fit flex-shrink max-w-[500px] ml-8">
              <h1 className="text-gray-800 font-medium">
                <>{t('admin.re-registrationButton')}</>
              </h1>
              <h2 className="text-gray-500 font-light">
                <>{t('admin.re-registrationButtonText')}</>
              </h2>
            </div>
          </div>
          <div className="flex items-center">
            <Slider
              value={school?.school_setting.attributes.absence_has_report_back}
              id={1}
              onSliderClick={() => {
                if (school && !disableHasReportBack) {
                  setDisableHasReportBack(true)
                  setAbsenceHasReportBack(
                    school,
                    school?.school_setting.attributes.absence_has_report_back
                      ? false
                      : true,
                  ).finally(() => {
                    setDisableHasReportBack(false)
                  })
                }
              }}
              onBoxClick={() => {
                if (school && !disableHasReportBack) {
                  setDisableHasReportBack(true)
                  setAbsenceHasReportBack(
                    school,
                    school?.school_setting.attributes.absence_has_report_back
                      ? false
                      : true,
                  ).finally(() => {
                    setDisableHasReportBack(false)
                  })
                }
              }}
            />
          </div>
        </div>
      </div>
    </ModalRegular>
  )
}
