import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import { useTranslation } from 'react-i18next'
import { NavigationStoreContext } from '../../store/navigationStore'
import { UserStoreContext } from '../../store/userStore'
import {
  faAddressBook,
  faArchive,
  faComment,
  faEllipsis,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ColorCard from '../../components/ColorCard'
import { SearchDocument } from '../documents/DocumentsFromType'
import { DocumentStoreContext } from '../../store/documentStore'
import { Button } from '../../components/Button'
import {
  faArrowDownAZ,
  faArrowDownZA,
  faArrowUpAZ,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faBoxArchive,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faFaceHandPeeking,
  faImage,
  faMicrophone,
  faNoteMedical,
  faPen,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
} from '@fortawesome/pro-solid-svg-icons'
import { NotesRow } from './components/NotesRow'
import { noteStore, NoteStoreContext } from '../../store/noteStore'
import { useParams } from 'react-router-dom'
import { Note } from '../../Models/Note'
import ModalRegular from '../../components/ModalRegular'
import { NotesOverview } from './components/NotesOverview'
import {
  getImageFromRole,
  getImageFromUser,
  getImageOrPlaceHolder,
} from '../../utils/parseUtils'
import { UserRole } from '../../Models/UserRole'
import { CheckInOutBlock } from '../statistics/components/CheckInOutBlock'
import { NoteCreate } from './components/NoteCreate'
import { ButtonDropdown } from '../../components/Dropdown'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import { useClickOutside } from '../../hooks/useClickoutside'
import { Kid } from '../../Models/Kid'
import { KidStoreContext } from '../../store/kidStore'
import { faCircleSort } from '@fortawesome/pro-regular-svg-icons'
import { Loading } from '../../components/Loading'
//import AnimatedIcon from '../../components/AnimatedIcon'

enum SelectedView {
  TEACHER = 'teacher',
  SHARED = 'shared',
}

export const Notes: React.FC = observer(() => {
  const dropdownRef = useRef(null)
  const [menuRef, togglerRef] = useClickOutside(() => {
    setDropdownVisible1(false)
    setDropdownVisible2(false)
    setDropdownVisible3(false)
    setDropdownVisible4(false)
  })
  let { kidId } = useParams()
  const { t } = useTranslation()
  const { savedUserRoleId, currentUserRole, fetchUserRole, readOnly } =
    useContext(UserStoreContext)
  const {
    notes,
    notesFavorites,
    notesArchived,
    setNotes,
    fetchNotes,
    search,
    setSearch,
    setNotesFavorites,
    setNotesArchived,
    read_states,
  } = useContext(NoteStoreContext)
  const { setTitle } = useContext(NavigationStoreContext)
  const { kid, fetchKid } = useContext(KidStoreContext)
  const [showModal, setShowModal] = useState(false)
  const [dropdownVisible1, setDropdownVisible1] = useState(false)
  const [dropdownVisible2, setDropdownVisible2] = useState(false)
  const [dropdownVisible3, setDropdownVisible3] = useState(false)
  const [dropdownVisible4, setDropdownVisible4] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showArchived, setShowArchived] = useState<boolean>(false)
  const [selectedView, setSelectedView] = useState<SelectedView>(
    SelectedView.SHARED,
  )
  const toggleDropdown1 = () => {
    setDropdownVisible1(!dropdownVisible1)
  }
  const toggleDropdown2 = () => {
    setDropdownVisible2(!dropdownVisible2)
  }
  const toggleDropdown3 = () => {
    setDropdownVisible3(!dropdownVisible3)
  }
  const toggleDropdown4 = () => {
    setDropdownVisible4(!dropdownVisible4)
  }

  const [sortOrder, setSortOrder] = useState('asc')
  const [sortOrderFavorites, setSortOrderFavorites] = useState('asc')
  const [sortOrderArchived, setSortOrderArchived] = useState('asc')
  const [sortOrderEdit, setSortOrderEdit] = useState('asc')
  const [sortOrderEditFavorites, setSortOrderEditFavorites] = useState('asc')
  const [sortOrderEditArchived, setSortOrderEditArchived] = useState('asc')
  const [isSortAZ, setIsSortAZ] = useState(true)
  const [isSortAZFavorites, setIsSortAZFavorites] = useState(true)
  const [isSortAZArchived, setIsSortAZArchived] = useState(true)
  const toggleSorting = () => {
    setIsSortAZ((prevIsSortAZ) => !prevIsSortAZ)
  }
  const toggleSortingFavorites = () => {
    setIsSortAZFavorites((prevIsSortAZFavorites) => !prevIsSortAZFavorites)
  }
  const toggleSortingArchived = () => {
    setIsSortAZArchived((prevIsSortAZArchived) => !prevIsSortAZArchived)
  }
  useEffect(() => {
    sortNotes(isSortAZ ? 'Visa Ö-A' : 'Visa A-Ö')
  }, [isSortAZ])
  useEffect(() => {
    sortNotesFavorites(isSortAZFavorites ? 'Visa Ö-A' : 'Visa A-Ö')
  }, [isSortAZFavorites])
  useEffect(() => {
    sortNotesArchived(isSortAZArchived ? 'Visa Ö-A' : 'Visa A-Ö')
  }, [isSortAZArchived])
  useEffect(() => {
    sortNotesByUpdatedAt()
  }, [sortOrderEdit])
  useEffect(() => {
    sortNotesByUpdatedAtFavorites()
  }, [sortOrderEditFavorites])
  useEffect(() => {
    sortNotesByUpdatedAtArchived()
  }, [sortOrderEditArchived])
  useEffect(() => {
    sortNotesByCreatedAt()
  }, [sortOrder])
  useEffect(() => {
    sortNotesByCreatedAtFavorites()
  }, [sortOrderFavorites])
  useEffect(() => {
    sortNotesByCreatedAtArchived()
  }, [sortOrderArchived])
  const sortNotesByUpdatedAt = () => {
    if (notes) {
      setNotes(
        [...notes].sort((a, b) => {
          const dateA = new Date(a.notes_edited).getTime()
          const dateB = new Date(b.notes_edited).getTime()
          if (sortOrderEdit === 'asc') {
            return dateA - dateB
          } else {
            return dateB - dateA
          }
        }),
      )
    }
  }
  const sortNotesByUpdatedAtFavorites = () => {
    if (notesFavorites) {
      setNotesFavorites(
        [...notesFavorites].sort((a, b) => {
          const dateA = new Date(a.notes_edited).getTime()
          const dateB = new Date(b.notes_edited).getTime()
          if (sortOrderEditFavorites === 'asc') {
            return dateA - dateB
          } else {
            return dateB - dateA
          }
        }),
      )
    }
  }
  const sortNotesByUpdatedAtArchived = () => {
    if (notesArchived) {
      setNotesArchived(
        [...notesArchived].sort((a, b) => {
          const dateA = new Date(a.notes_edited).getTime()
          const dateB = new Date(b.notes_edited).getTime()
          if (sortOrderEditArchived === 'asc') {
            return dateA - dateB
          } else {
            return dateB - dateA
          }
        }),
      )
    }
  }
  const sortNotesByCreatedAt = () => {
    if (notes) {
      setNotes(
        [...notes].sort((a, b) => {
          const dateA = new Date(a.createdAt).getTime()
          const dateB = new Date(b.createdAt).getTime()
          if (sortOrder === 'asc') {
            return dateA - dateB
          } else {
            return dateB - dateA
          }
        }),
      )
    }
  }
  const sortNotesByCreatedAtFavorites = () => {
    if (notesFavorites) {
      setNotesFavorites(
        [...notesFavorites].sort((a, b) => {
          const dateA: any = new Date(a.createdAt).getTime()
          const dateB: any = new Date(b.createdAt).getTime()
          if (sortOrderFavorites === 'asc') {
            return dateA - dateB
          } else {
            return dateB - dateA
          }
        }),
      )
    }
  }
  const sortNotesByCreatedAtArchived = () => {
    if (notesArchived) {
      setNotesArchived(
        [...notesArchived].sort((a, b) => {
          const dateA = new Date(a.createdAt).getTime()
          const dateB = new Date(b.createdAt).getTime()
          if (sortOrderArchived === 'asc') {
            return dateA - dateB
          } else {
            return dateB - dateA
          }
        }),
      )
    }
  }
  const sortNotes = (option: string) => {
    switch (option) {
      case 'Visa A-Ö':
        if (notes) {
          setNotes(
            [...notes].sort((a, b) =>
              a.notes_topic.localeCompare(b.notes_topic),
            ),
          )
        }
        break
      case 'Visa Ö-A':
        if (notes) {
          setNotes(
            [...notes].sort((a, b) =>
              b.notes_topic.localeCompare(a.notes_topic),
            ),
          )
        }
        break
      default:
        break
    }
  }
  const sortNotesFavorites = (option: string) => {
    switch (option) {
      case 'Visa A-Ö':
        if (notesFavorites) {
          setNotesFavorites(
            [...notesFavorites].sort((a, b) =>
              a.notes_topic.localeCompare(b.notes_topic),
            ),
          )
        }
        break
      case 'Visa Ö-A':
        if (notesFavorites) {
          setNotesFavorites(
            [...notesFavorites].sort((a, b) =>
              b.notes_topic.localeCompare(a.notes_topic),
            ),
          )
        }
        break
      default:
        break
    }
  }
  const sortNotesArchived = (option: string) => {
    switch (option) {
      case 'Visa A-Ö':
        if (notesArchived) {
          setNotesArchived(
            [...notesArchived].sort((a, b) =>
              a.notes_topic.localeCompare(b.notes_topic),
            ),
          )
        }
        break
      case 'Visa Ö-A':
        if (notesArchived) {
          setNotesArchived(
            [...notesArchived].sort((a, b) =>
              b.notes_topic.localeCompare(a.notes_topic),
            ),
          )
        }
        break
      default:
        break
    }
  }
  const countNotShared = () => {
    let countNotShared = 0
    if (notes?.length) {
      countNotShared =
        countNotShared + notes?.filter((note: Note) => !note.shared).length
    }
    if (notesFavorites?.length) {
      countNotShared =
        countNotShared +
        notesFavorites?.filter((note: Note) => !note.shared).length
    }
    return countNotShared
  }
  const countShared = () => {
    let countShared = 0
    if (notes?.length) {
      countShared =
        countShared + notes?.filter((note: Note) => note.shared).length
    }
    if (notesFavorites?.length) {
      countShared =
        countShared + notesFavorites?.filter((note: Note) => note.shared).length
    }
    return countShared
  }
  useEffect(() => {
    setTitle(t('menu.notes'))
  })
  useEffect(() => {
    if (currentUserRole) {
      if (kidId) {
        setLoading(true)
        fetchKid(kidId).finally(() => {
          if (kidId) {
            fetchNotes(kidId).finally(() => {
              setLoading(false)
            })
          }
        })
      }
    }
  }, [currentUserRole?.id, kidId])

  useEffect(() => {
    if (savedUserRoleId) {
      fetchUserRole(savedUserRoleId).finally(() => {})
    }
  }, [savedUserRoleId])

  const isTeacher =
    currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
      ? true
      : false

  const isAdmin =
    currentUserRole && currentUserRole?.role_type >= USER_ROLE_TYPE.ADMIN
      ? true
      : false

  if (loading) {
    return <Loading />
  }

  return (
    <Page
      naked
      topBar={
        <>
          {isTeacher && (
            <div className="flex flex-col gap-y-2 sm:gap-y-0 sm:flex-row gap-x-4 ">
              <Button
                variant={
                  selectedView == SelectedView.TEACHER
                    ? 'fullBlue'
                    : 'fullWhite'
                }
                size="custom"
                label={t('notes.staffNotes') + ' (' + countNotShared() + ')'}
                onClick={() => {
                  setSelectedView(SelectedView.TEACHER)
                }}
              />
              <Button
                variant={
                  selectedView == SelectedView.SHARED ? 'fullBlue' : 'fullWhite'
                }
                size="md"
                label={
                  t('notes.sharedWithGuardian') + ' (' + countShared() + ')'
                }
                onClick={() => {
                  setSelectedView(SelectedView.SHARED)
                }}
              />
            </div>
          )}
          <div className="flex flex-row gap-x-28">
            {!readOnly?.notes && (
              <Button
                icon={faNoteMedical}
                size="lg"
                variant="primary"
                onClick={() => setShowModal(true)}
              />
            )}

            {isAdmin && (
              <div className="relative flex  -top-[1px] right-2">
                <button
                  ref={togglerRef}
                  type="button"
                  className="w-full rounded-md group focus:outline-none "
                  aria-haspopup="true"
                  aria-expanded={dropdownVisible1}
                  onClick={() => toggleDropdown1()}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="text-gray-400 px-4 hover:scale-110 "
                  ></FontAwesomeIcon>
                </button>
                {dropdownVisible1 && (
                  <div
                    className="absolute right-4 z-50 mx-3 origin-top divide-y w-44 top-2"
                    style={{ display: dropdownVisible1 ? 'block' : 'none' }}
                    ref={menuRef}
                  >
                    <ButtonDropdown.Menu>
                      <ButtonDropdown.Section>
                        {showArchived ? (
                          <ButtonDropdown.Button
                            key={'hideArchived'}
                            icon={faEyeSlash}
                            className="text-yellow-600"
                            label={t('notes.hideArchived')}
                            onClick={() => {
                              if (kidId) {
                                fetchNotes(kidId, false)
                                setShowArchived(false)
                              }
                            }}
                          />
                        ) : (
                          <ButtonDropdown.Button
                            key={'showArchived'}
                            icon={faEye}
                            label={t('notes.showArchived')}
                            className="text-blue-600"
                            onClick={() => {
                              if (kidId) {
                                fetchNotes(kidId, true)
                                setShowArchived(true)
                              }
                            }}
                          />
                        )}
                      </ButtonDropdown.Section>
                    </ButtonDropdown.Menu>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      }
      searchFilter={
        <SearchDocument
          onSearch={(s: string) => {
            setSearch(s)
          }}
          initialValue={search ?? ''}
          placeholder={t('notes.searchNote')}
        />
      }
    >
      {notesArchived &&
        notesArchived
          ?.filter((note: Note) =>
            isTeacher
              ? selectedView == SelectedView.TEACHER
                ? !note.shared
                : note.shared
              : true,
          )
          .filter((note: Note) => {
            if (search) {
              return note.notes_topic
                ?.toLowerCase()
                .includes(search.toLowerCase())
            } else {
              return true
            }
          }).length > 0 && (
          <ColorCard
            actionButton={
              <div className="relative flex  -top-[1px] right-2">
                <button
                  ref={togglerRef}
                  type="button"
                  className="w-full rounded-md group focus:outline-none "
                  aria-haspopup="true"
                  aria-expanded={dropdownVisible4}
                  onClick={() => toggleDropdown4()}
                >
                  <FontAwesomeIcon
                    icon={faSort}
                    className="text-red-400 p-2 m-2 hover:scale-110 bg-white rounded-full w-fit items-center flex "
                  ></FontAwesomeIcon>
                </button>
                {dropdownVisible4 && (
                  <div
                    className="absolute right-8 z-50 mx-3 origin-top divide-y w-44 top-2"
                    style={{ display: dropdownVisible4 ? 'block' : 'none' }}
                    ref={menuRef}
                  >
                    <ButtonDropdown.Menu>
                      <ButtonDropdown.Section>
                        <ButtonDropdown.Button
                          icon={isSortAZArchived ? faArrowDownAZ : faArrowUpAZ}
                          label={
                            isSortAZArchived
                              ? t('notes.sortAZ')
                              : t('notes.sortZA')
                          }
                          onClick={() => toggleSortingArchived()}
                        />

                        {sortOrderEditArchived === 'asc' ? (
                          <ButtonDropdown.Button
                            key={2}
                            icon={faSortDown}
                            label={t('notes.changed')}
                            onClick={() => setSortOrderEditArchived('desc')}
                          />
                        ) : (
                          <ButtonDropdown.Button
                            key={3}
                            icon={faSortUp}
                            label={t('notes.changed')}
                            onClick={() => setSortOrderEditArchived('asc')}
                          />
                        )}
                        {sortOrderArchived === 'asc' ? (
                          <ButtonDropdown.Button
                            key={4}
                            icon={faSortDown}
                            label={t('documents.sortCreated')}
                            onClick={() => setSortOrderArchived('desc')}
                          />
                        ) : (
                          <ButtonDropdown.Button
                            key={5}
                            icon={faSortUp}
                            label={t('documents.sortCreated')}
                            onClick={() => setSortOrderArchived('asc')}
                          />
                        )}
                      </ButtonDropdown.Section>
                    </ButtonDropdown.Menu>
                  </div>
                )}
              </div>
            }
            title={
              t('emojis.archiveIcon') +
              ' ' +
              ' ' +
              kid?.firstName +
              ' ' +
              kid?.lastName
            }
            bgColor="bg-gray-300 justify-center flex"
          >
            <>
              {notesArchived &&
              notesArchived
                ?.filter((note: Note) =>
                  isTeacher
                    ? selectedView == SelectedView.TEACHER
                      ? !note.shared
                      : note.shared
                    : true,
                )
                .filter((note: Note) => {
                  if (search) {
                    return note.notes_topic
                      ?.toLowerCase()
                      .includes(search.toLowerCase())
                  } else {
                    return true
                  }
                }).length < 1 ? (
                <div className="flex flex-wrap justify-center py-44 text-xl sm:text-2xl text-gray-300 w-full ">
                  {t('ourKids.noContactInfo')}
                </div>
              ) : (
                notesArchived
                  ?.filter((note: Note) =>
                    isTeacher
                      ? selectedView == SelectedView.TEACHER
                        ? !note.shared
                        : note.shared
                      : true,
                  )
                  .filter((note: Note) => {
                    if (search) {
                      return note.notes_topic
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                    } else {
                      return true
                    }
                  })
                  .map((row: Note) => {
                    return (
                      <>
                        <NotesRow
                          note={row}
                          isAdmin={isAdmin}
                          isTeacher={isTeacher}
                          showArchived={showArchived}
                          onSave={() => {
                            if (kidId) {
                              fetchNotes(kidId)
                            }
                          }}
                        />{' '}
                      </>
                    )
                  })
              )}
            </>
          </ColorCard>
        )}
      {notesFavorites &&
        notesFavorites
          ?.filter((note: Note) =>
            isTeacher
              ? selectedView == SelectedView.TEACHER
                ? !note.shared
                : note.shared
              : true,
          )
          .filter((note: Note) => {
            if (search) {
              return note.notes_topic
                ?.toLowerCase()
                .includes(search.toLowerCase())
            } else {
              return true
            }
          }).length > 0 && (
          <ColorCard
            actionButton={
              <div className="relative flex  -top-[1px] right-2">
                <button
                  ref={togglerRef}
                  type="button"
                  className="w-full rounded-md group focus:outline-none "
                  aria-haspopup="true"
                  aria-expanded={dropdownVisible2}
                  onClick={() => toggleDropdown3()}
                >
                  <FontAwesomeIcon
                    icon={faSort}
                    className="text-red-400 p-2 m-2 hover:scale-110 bg-white rounded-full w-fit items-center flex "
                  ></FontAwesomeIcon>
                </button>
                {dropdownVisible3 && (
                  <div
                    className="absolute right-8 z-50 mx-3 origin-top divide-y w-44 top-2"
                    style={{ display: dropdownVisible3 ? 'block' : 'none' }}
                    ref={menuRef}
                  >
                    <ButtonDropdown.Menu>
                      <ButtonDropdown.Section>
                        <ButtonDropdown.Button
                          icon={isSortAZFavorites ? faArrowDownAZ : faArrowUpAZ}
                          label={
                            isSortAZFavorites
                              ? t('notes.sortAZ')
                              : t('notes.sortZA')
                          }
                          onClick={() => toggleSortingFavorites()}
                        />

                        {sortOrderEditFavorites === 'asc' ? (
                          <ButtonDropdown.Button
                            key={2}
                            icon={faSortDown}
                            label={t('notes.changed')}
                            onClick={() => setSortOrderEditFavorites('desc')}
                          />
                        ) : (
                          <ButtonDropdown.Button
                            key={3}
                            icon={faSortUp}
                            label={t('notes.changed')}
                            onClick={() => setSortOrderEditFavorites('asc')}
                          />
                        )}
                        {sortOrderFavorites === 'asc' ? (
                          <ButtonDropdown.Button
                            key={4}
                            icon={faSortDown}
                            label={t('documents.sortCreated')}
                            onClick={() => setSortOrderFavorites('desc')}
                          />
                        ) : (
                          <ButtonDropdown.Button
                            key={5}
                            icon={faSortUp}
                            label={t('documents.sortCreated')}
                            onClick={() => setSortOrderFavorites('asc')}
                          />
                        )}
                      </ButtonDropdown.Section>
                    </ButtonDropdown.Menu>
                  </div>
                )}
              </div>
            }
            //icon={faStar}
            title={
              (t('emojis.star', '') || '') +
              ' ' +
              kid?.firstName +
              ' ' +
              kid?.lastName
            }
            bgColor="bg-contactsMain justify-center flex"
          >
            <>
              {notesFavorites &&
              notesFavorites
                ?.filter((note: Note) =>
                  isTeacher
                    ? selectedView == SelectedView.TEACHER
                      ? !note.shared
                      : note.shared
                    : true,
                )
                .filter((note: Note) => {
                  if (search) {
                    return note.notes_topic
                      ?.toLowerCase()
                      .includes(search.toLowerCase())
                  } else {
                    return true
                  }
                }).length < 1 ? (
                <div className="flex flex-wrap justify-center py-44 text-xl sm:text-2xl text-gray-300 w-full ">
                  {t('ourKids.noContactInfo')}
                </div>
              ) : (
                notesFavorites
                  ?.filter((note: Note) =>
                    isTeacher
                      ? selectedView == SelectedView.TEACHER
                        ? !note.shared
                        : note.shared
                      : true,
                  )
                  .filter((note: Note) => {
                    if (search) {
                      return note.notes_topic
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                    } else {
                      return true
                    }
                  })
                  .map((row: Note) => {
                    return (
                      <>
                        <NotesRow
                          note={row}
                          isAdmin={isAdmin}
                          isTeacher={isTeacher}
                          showArchived={showArchived}
                          onSave={() => {
                            if (kidId) {
                              fetchNotes(kidId)
                            }
                          }}
                        />{' '}
                      </>
                    )
                  })
              )}
            </>
          </ColorCard>
        )}

      <ColorCard
        actionButton={
          <div className="relative flex  -top-[1px] right-2">
            <button
              ref={togglerRef}
              type="button"
              className="w-full rounded-md group focus:outline-none "
              aria-haspopup="true"
              aria-expanded={dropdownVisible2}
              onClick={() => toggleDropdown2()}
            >
              <FontAwesomeIcon
                icon={faSort}
                className="text-red-400 p-2 m-2 hover:scale-110 bg-white rounded-full w-fit items-center flex "
              ></FontAwesomeIcon>
            </button>
            {dropdownVisible2 && (
              <div
                className="absolute right-8 z-50 mx-3 origin-top divide-y w-44 top-2"
                style={{ display: dropdownVisible2 ? 'block' : 'none' }}
                ref={menuRef}
              >
                <ButtonDropdown.Menu>
                  <ButtonDropdown.Section>
                    <ButtonDropdown.Button
                      icon={isSortAZ ? faArrowDownAZ : faArrowUpAZ}
                      label={isSortAZ ? t('notes.sortAZ') : t('notes.sortZA')}
                      onClick={() => toggleSorting()}
                    />

                    {sortOrderEdit === 'asc' ? (
                      <ButtonDropdown.Button
                        key={123}
                        icon={faSortDown}
                        label={t('notes.changed')}
                        onClick={() => setSortOrderEdit('desc')}
                      />
                    ) : (
                      <ButtonDropdown.Button
                        key={1234}
                        icon={faSortUp}
                        label={t('notes.changed')}
                        onClick={() => setSortOrderEdit('asc')}
                      />
                    )}
                    {sortOrder === 'asc' ? (
                      <ButtonDropdown.Button
                        key={12345}
                        icon={faSortDown}
                        label={t('documents.sortCreated')}
                        onClick={() => setSortOrder('desc')}
                      />
                    ) : (
                      <ButtonDropdown.Button
                        key={123456}
                        icon={faSortUp}
                        label={t('documents.sortCreated')}
                        onClick={() => setSortOrder('asc')}
                      />
                    )}
                  </ButtonDropdown.Section>
                </ButtonDropdown.Menu>
              </div>
            )}
          </div>
        }
        titlePicture={
          <img
            className={
              'w-[45px] h-[45px] rounded-full object-cover shadow mr-4'
            }
            src={getImageOrPlaceHolder(kid?.attributes?.kidImage?._url)}
            alt=""
          />
        }
        title={kid ? kid.firstName + ' ' + kid.lastName : ''}
        bgColor="bg-notesMain justify-center flex"
      >
        <>
          {notes &&
          notes
            ?.filter((note: Note) =>
              isTeacher
                ? selectedView == SelectedView.TEACHER
                  ? !note.shared
                  : note.shared
                : true,
            )
            .filter((note: Note) => {
              if (search) {
                return note.notes_topic
                  ?.toLowerCase()
                  .includes(search.toLowerCase())
              } else {
                return true
              }
            }).length < 1 ? (
            <div className="flex flex-wrap justify-center py-44 text-xl sm:text-2xl text-gray-300 w-full ">
              {t('ourKids.noContactInfo')}
            </div>
          ) : (
            notes
              ?.filter((note: Note) =>
                isTeacher
                  ? selectedView == SelectedView.TEACHER
                    ? !note.shared
                    : note.shared
                  : true,
              )
              .filter((note: Note) => {
                if (search) {
                  return note.notes_topic
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
                } else {
                  return true
                }
              })
              .map((row: Note) => {
                return (
                  <>
                    <NotesRow
                      note={row}
                      isAdmin={isAdmin}
                      isTeacher={isTeacher}
                      showArchived={showArchived}
                      onSave={() => {
                        if (kidId) {
                          fetchNotes(kidId)
                        }
                      }}
                    />{' '}
                  </>
                )
              })
          )}
        </>
      </ColorCard>
      {showModal && (
        <NoteCreate
          onClose={() => {
            setShowModal(false)
          }}
          kidId={kidId ?? ''}
          onSave={() => {
            if (kidId) {
              fetchNotes(kidId)
              setShowModal(false)
            }
          }}
          showModal={showModal}
        />
      )}
    </Page>
  )
})
