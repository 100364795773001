import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useContext, useState } from 'react'
import { LoadingBar } from '../../../../../components/LoadingBar'
import {
  faChevronDown,
  faChevronUp,
  faUserClock,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons'
import { Slider } from '../../../../../components/Slider'
import { Button } from '../../../../../components/Button'
import Swal from 'sweetalert2'
//import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { UserRole } from '../../../../../Models/UserRole'
import { USER_ROLE_TYPE } from '../../../../../Enums/TyraEnums'
import { Kid } from '../../../../../Models/Kid'
import { AdminStoreContext } from '../../../../../store/adminStore'
import { useParams } from 'react-router-dom'
import { UserStoreContext } from '../../../../../store/userStore'
import { getImageFromUser } from '../../../../../utils/parseUtils'

interface Props {
  children?: React.ReactNode
  description?: string
  title?: string
  loading?: boolean
  className?: string
  icon?: IconDefinition
  iconClassName?: string
  onClick?: () => void
  image?: string
  email?: string
  invitationSent?: boolean
  moreDepartments: boolean
  allUserRolesForUser: UserRole[]
  kid: Kid
  userRole: UserRole
}

export default function InviteParentCard({ moreDepartments, ...props }: Props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const { t } = useTranslation()
  const { inviteExistingGuardian } = useContext(AdminStoreContext)
  const { fetchNewUserRoles, statusNewUserRoles } = useContext(UserStoreContext)

  const [fakeState, setFakeState] = useState(true)

  const handleCardClick = () => {
    setIsExpanded(!isExpanded)
  }
  const { schoolId } = useParams()
  const [uploadParent, setUploadParent] = useState(false)
  const inviteSent = statusNewUserRoles?.find(
    (uR) =>
      uR.user?.id === props.userRole.user?.id && uR.kid?.id === props.kid.id,
  )

  return (
    <>
      <div
        className={classNames(
          'w-1/2 min-h-[60px] min-w-[300px] overflow-hidden rounded-2xl shadow-tyraShadow m-4 cursor-pointer bg-white p-3',
          isExpanded ? '' : '',
        )}
      >
        <div
          className="flex flex-row justify-center w-full hover:text-white hover:bg-blue-300 bg-blue-200 relative p-2 rounded-full transition duration-500"
          onClick={handleCardClick}
        >
          {!isExpanded ? (
            <FontAwesomeIcon
              icon={faChevronDown}
              className="flex justify-center px-2"
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronUp}
              className="flex justify-center px-2"
            />
          )}
        </div>
        {props?.loading && <LoadingBar />}
        <div className="flex space-x-4 rounded-b-2xl items-center font-bold font-sans   text-neutral-600 relative px-2">
          <div
            className={classNames(
              'flex py-3 font-medium justify-start w-full',
              props.className,
            )}
          >
            <>
              <div className="flex flex-row">
                <div className="border flex shadow bg-gray-200 max-h-[60px] min-h-[60px] max-w-[60px] min-w-[60px] rounded-full overflow-hidden relative">
                  <img
                    src={getImageFromUser(props.userRole.user)}
                    className={classNames(
                      'h-full w-full rounded-full',
                      props.iconClassName,
                    )}
                    alt="user"
                  />
                </div>
              </div>
            </>
            <div className="flex flex-col ml-3 items-start justify-center">
              <div>{props.title}</div>
              <div className="font-normal text-sm text-gray-500">
                {props.email}
              </div>
            </div>
          </div>
          {!isExpanded && (
            <div className="flex w-fit justify-end ">
              <FontAwesomeIcon
                icon={inviteSent ? faUserClock : props.icon || faUserPlus}
                className={classNames(
                  inviteSent ? 'text-orange-400' : 'text-gray-400',
                )}
                title={
                  props.invitationSent
                    ? t('admin.inviteSent')
                    : t('admin.inviteUser')
                }
              />
            </div>
          )}
        </div>
        {!isExpanded && (
          <div className="flex flex-row w-full justify-between relative pr-14">
            {props.allUserRolesForUser.slice(0, 2).map((userRole, index) => {
              if (userRole.kid) {
                return (
                  <div key={index}>
                    <h1 className="font-medium text-sm">
                      {userRole.kid.kidName}
                    </h1>
                    <h2 className="text-gray-400 font-normal text-sm">
                      {userRole.kid.departmentPointer?.klassName}
                    </h2>
                  </div>
                )
              } else if (userRole.role_type > USER_ROLE_TYPE.PARENT) {
                return (
                  <div key={index}>
                    <h1 className="font-medium text-sm">
                      {t('documents.staff')}
                    </h1>
                    <h2 className="text-gray-400 font-normal text-sm">
                      {userRole.staff_primary_dep?.klassName}
                    </h2>
                  </div>
                )
              }
              return null
            })}

            {/* Display the + sign with the count of additional items */}
            {props.allUserRolesForUser.length > 2 && (
              <div className="text-blue-500 absolute bottom-2 right-2">
                +{props.allUserRolesForUser.length - 2}
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col gap-y-4">
          {isExpanded && (
            <div className="flex flex-col justify-between gap-y-4">
              <div>
                <div className="text-eventsMain font-medium">
                  {props.kid.firstName + ' ' + props.kid.lastName}
                </div>

                <div className="text-gray-400 text-sm">
                  {inviteSent ? (
                    <div className="text-orange-400">
                      <>{t('admin.inviteSent')}</>
                    </div>
                  ) : (
                    <h2 className="text-gray-400 font-normal text-sm">
                      {props.kid.departmentPointer.klassName}
                    </h2>
                  )}
                </div>
              </div>
              {props.allUserRolesForUser.map((userRole) => {
                if (userRole.kid) {
                  return (
                    <div>
                      <h1 className=" font-medium text-sm">
                        {userRole.kid.kidName}
                      </h1>
                      <h2 className="text-gray-400 font-normal text-sm">
                        {userRole.kid.departmentPointer.klassName}
                      </h2>
                    </div>
                  )
                } else if (userRole.role_type > USER_ROLE_TYPE.PARENT) {
                  return (
                    <div>
                      <h1 className=" font-medium text-sm">
                        {t('documents.staff')}
                      </h1>
                      <h2 className="text-gray-400 font-normal text-sm">
                        {userRole.staff_primary_dep?.klassName}
                      </h2>
                    </div>
                  )
                } else {
                  return <></>
                }
              })}
              {!inviteSent && (
                <div className="flex flex-row justify-between">
                  <div className="text-sm mt-2">
                    <>{t('admin.addDocSchool')}</>
                  </div>
                  <div>
                    <Slider
                      value={uploadParent}
                      id={0}
                      onSliderClick={() => {
                        setUploadParent(!uploadParent)
                      }}
                      onBoxClick={() => {}}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {isExpanded && !inviteSent && (
            <div className="flex flex-col w-full items-center gap-y-2 ">
              <Button
                variant="fullBlue"
                size="sm"
                label={t('admin.invite')}
                onClick={() => {
                  Swal.fire({
                    //title: 'Are you sure?',
                    text: t('admin.inviteCheck'),
                    icon: 'info',
                    showCancelButton: true,
                    focusConfirm: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    cancelButtonText: t('general.cancel'),
                    confirmButtonText: t('general.yes'),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      if (schoolId && props.userRole.user) {
                        inviteExistingGuardian(
                          schoolId,
                          props.kid.id,
                          props.userRole.user.id,
                          uploadParent,
                        )
                          .then((response) => {
                            if (response === 'OK') {
                              Swal.fire({
                                text: t('admin.inviteSent'),
                                icon: 'success',
                                showCancelButton: false,
                                showConfirmButton: true,
                                focusConfirm: false,
                                confirmButtonColor: '#7eb5f4',
                                cancelButtonColor: '#d33',
                                cancelButtonText: t('general.cancel'),
                                confirmButtonText: t('general.ok'),
                              }).finally(() => {
                                fetchNewUserRoles(schoolId).finally(() => {
                                  setFakeState(!fakeState)
                                })
                              })
                            }
                            if (response === 'ALREADY_INVITED') {
                              // HANTERA FELMEDDELANDE
                              Swal.fire({
                                text: t('admin.userAlreadyInvited'),
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: true,
                                focusConfirm: false,
                                confirmButtonColor: '#7eb5f4',
                                cancelButtonColor: '#d33',
                                cancelButtonText: t('general.cancel'),
                                confirmButtonText: t('general.ok'),
                              })
                            }
                            if (response === 'ERROR') {
                              // HANTERA FELMEDDELANDE
                              Swal.fire({
                                title: t('events.eventAlarmFail'),
                                text: t('statistics.contactSupport'),
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: true,
                                focusConfirm: false,
                                confirmButtonColor: '#7eb5f4',
                                cancelButtonColor: '#d33',
                                cancelButtonText: t('general.cancel'),
                                confirmButtonText: t('general.ok'),
                              })
                            }
                          })
                          .catch((e) => {
                            console.error(e)
                          })
                          .finally(() => {})
                      }
                    }
                  })
                }}
              />
              <Button
                variant="borderRed"
                size="sm"
                label={t('general.cancel')}
                onClick={handleCardClick}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
