import React, { useContext } from 'react'
import Parse, { User } from 'parse'
import { makeAutoObservable } from 'mobx'
import { Note } from '../Models/Note'
import { Kid } from '../Models/Kid'
import { NewNote } from '../views/notes/components/NoteCreate'
import { NoteComment } from '../Models/NoteComment'
import { userStore } from './userStore'
import { DEST_TYPE, USER_ROLE_TYPE } from '../Enums/TyraEnums'
import { ReadState } from '../Models/ReadState'
import { EditNote } from '../views/notes/components/NoteEdit'

/*
 Blå plupp:
  Det är när det inte finns någon read_state för usern som är inloggad för just denna note eller att revision inte är samma på note som för min readstate.

 Sett / osedd förälder (som lärare):
  Hämta read_state för föräldrar till kid som står på note. Om read_state.revision är samma som note.revision så har man sett allt. Annars inte

 Sett / osedd lärare (som förälder):
  Hämta read_state för för noten som INTE är barnens föräldrar. Om read_state.revision är samma som note.revision så har man sett allt. Annars inte
*/

class NoteStore {
  notes?: Note[]
  notesFavorites?: Note[]
  notesArchived?: Note[]
  search?: string
  newNoteSaved?: NewNote | null
  newNoteImagePreview?: string | null
  read_states?: ReadState[]
  noteReadStates?: ReadState[]
  noteReadStatesUsers?: User[]
  /*parentsReadStates?: ReadState[]
  teachersReadStates?: ReadState[]
  parentUsers?: User[]
  teacherUsers?: User[]*/
  constructor() {
    makeAutoObservable(this)
  }
  setNewNoteSaved = (newNote: NewNote) => {
    this.newNoteSaved = newNote
  }
  unsetNewNoteSaved = () => {
    this.newNoteSaved = null
  }
  setNewNoteImagePreview = (image: string) => {
    this.newNoteImagePreview = image
  }
  unsetNewNoteImagePreview = () => {
    this.newNoteImagePreview = null
  }
  setSearch = (search: string) => {
    this.search = search
  }

  setReadStates = (read_states: ReadState[]) => {
    this.read_states = read_states
  }

  setNoteReadStates = (noteReadStates: ReadState[]) => {
    this.noteReadStates = noteReadStates
  }

  setNoteReadStateUsers = (noteReadStatesUsers: User[]) => {
    this.noteReadStatesUsers = noteReadStatesUsers
  }

  setBothReadState = (noteReadStatesUsers: User[]) => {
    this.noteReadStatesUsers = noteReadStatesUsers
  }

  /*setParentReadStates = (parentsReadStates: ReadState[]) => {
    this.parentsReadStates = parentsReadStates
  }

  setTeacherReadStates = (teachersReadStates: ReadState[]) => {
    this.teachersReadStates = teachersReadStates
  }

  setParentUsers = (parentUsers: User[]) => {
    this.parentUsers = parentUsers
  }

  setTeacherUsers = (teacherUsers: User[]) => {
    this.teacherUsers = teacherUsers
  }*/

  setNotes = (notes: Note[]) => {
    this.notes = notes
  }
  setNotesFavorites = (notesFavorites: Note[]) => {
    this.notesFavorites = notesFavorites
  }
  setNotesArchived = (notesArchived: Note[]) => {
    this.notesArchived = notesArchived
  }

  fetchBothReadStates = async (kidId: string, noteId: string) => {
    const currentUserRole = userStore.currentUserRole
    const readStateQuery = new Parse.Query(ReadState)
    readStateQuery.equalTo('notes', Note.createWithoutData(noteId))
    readStateQuery.equalTo('school_pointer', currentUserRole?.school_pointer)
    readStateQuery.include('user')
    const notesReadStates = await readStateQuery.find()
    this.setNoteReadStateUsers(notesReadStates.map((rs) => rs.user))
    this.setNoteReadStates(notesReadStates)
  }

  fetchParentReadStates = async (kidId: string, noteId: string) => {
    const currentUserRole = userStore.currentUserRole

    const kid = await new Parse.Query(Kid).include('relatives').get(kidId)

    const kidParents = kid.relatives
    let parentUsers: User[] = []
    kidParents.forEach((parent) => {
      if (parent.user && parent.status >= 35) {
        parentUsers.push(parent.user)
      }
    })

    this.setNoteReadStateUsers(parentUsers)

    if (kid) {
      const readStateQuery = new Parse.Query(ReadState)

      readStateQuery.containedIn('user', parentUsers)

      readStateQuery.equalTo('notes', await Note.createWithoutData(noteId))
      readStateQuery.equalTo('school_pointer', currentUserRole?.school_pointer)
      readStateQuery.include('user')
      const parentsReadStates = await readStateQuery.find()
      this.setNoteReadStates(parentsReadStates)
    }
  }

  fetchTeacherReadStates = async (kidId: string, noteId: string) => {
    const currentUserRole = userStore.currentUserRole

    const kid = await new Parse.Query(Kid).include('relatives').get(kidId)

    const kidParents = kid.relatives
    let parentUsers: User[] = []
    kidParents.forEach((parent) => {
      if (parent.user) {
        parentUsers.push(parent.user)
      }
    })
    if (kid) {
      const readStateQuery = new Parse.Query(ReadState)
      readStateQuery.notContainedIn('user', parentUsers)
      readStateQuery.equalTo('notes', await Note.createWithoutData(noteId))
      readStateQuery.equalTo('school_pointer', currentUserRole?.school_pointer)
      readStateQuery.include('user')
      const teachersReadStates = await readStateQuery.find()
      this.setNoteReadStates(teachersReadStates)
      let teacherUsers: User[] = []
      teachersReadStates.forEach((trs) => {
        if (trs.user) {
          teacherUsers.push(trs.user)
        }
      })
      this.setNoteReadStateUsers(teacherUsers)
    }
  }

  fetchNotes = async (kidId: string, showArchived: boolean = false) => {
    const currentUserRole = userStore.currentUserRole
    const currentUser = userStore.currentUser

    /*const kidQuery = new Parse.Query(Kid)
    const kid = await kidQuery.get(kidId)
    const notesQuery = new Parse.Query(Note)
      .equalTo('kid_owner', kid)

      .doesNotExist('notes_comments.archive_state')

      .include('notes_comments')
      .include('notes_owner')
      .addDescending('createdAt')
      .limit(10000)
    if (
      showArchived &&
      currentUserRole &&
      currentUserRole.role_type >= USER_ROLE_TYPE.ADMIN
    ) {
    } else {
      notesQuery.doesNotExist('archive_state')
    }
    if (currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT) {
      // Något här??
    } else {
      notesQuery.equalTo('shared', true)
    }*/

    const readStateQuery = new Parse.Query(ReadState)
    readStateQuery.equalTo('user', currentUser)
    readStateQuery.equalTo('school_pointer', currentUserRole?.school_pointer)

    const read_states = await readStateQuery.find()

    this.setReadStates(read_states)

    const notes = await Parse.Cloud.run('getNotes', {
      kidId,
      showArchived,
      userRoleId: currentUserRole?.id,
    })
    if (currentUserRole?.user) {
      this.setNotes(
        notes?.notes
          .filter(
            (note: Note) =>
              !note.is_favorite ||
              !note.is_favorite.find(
                //@ts-ignore
                (fav: any) => fav === currentUserRole?.user.id,
              ),
          )
          .filter((note: Note) => !note.archive_state) ?? [],
      )
      this.setNotesFavorites(
        notes?.notes
          .filter(
            (note: Note) =>
              note.is_favorite &&
              note.is_favorite.find(
                //@ts-ignore
                (fav: any) => fav === currentUserRole?.user.id,
              ),
          )
          .filter((note: Note) => !note.archive_state) ?? [],
      )
      this.setNotesArchived(
        notes?.notes.filter((note: Note) => note.archive_state) ?? [],
      )
    }
  }

  editNote = async (note: EditNote) => {
    const currentUser = userStore.currentUser as User
    const currentUserRole = userStore.currentUserRole
    const kid = await Kid.createWithoutData(note.kidId)
    const editNote = await new Parse.Query(Note).get(note.note.id)

    const newReadState = new ReadState()

    editNote.set('notes_topic', note.notes_topic)
    editNote.set('shared', note.shared)
    editNote.set('edit_locked', note.edit_locked)
    editNote.set('edited_date', new Date())
    editNote.set('notes_edited', new Date())
    editNote.set('notes_owner', currentUser)

    const editNoteComment = note.note.notes_comments?.find(
      (comment) => comment.is_main,
    )
    if (!editNoteComment) {
      throw 'No main comment'
    }
    editNoteComment.set('edited_date', new Date())
    if (note.voice_memo) {
      editNoteComment.set('voice_memo', note.voice_memo)
    }
    if (note.picture) {
      editNoteComment.set('picture', note.picture)
    } else {
      if (editNoteComment.picture) {
        editNoteComment.unset('picture')
      }
    }
    editNoteComment.set('comment', note.comment)

    /*
    TODO: Kolla med Ove
    editNote.set('sendPush', true) 
    */

    await Parse.Object.saveAll([editNote])
    /*await editNote.save()
    await newReadState.save()*/
    editNoteComment.set('note_id', editNote.id)
    await editNoteComment.save()
  }

  createNoteNew = async (note: NewNote) => {
    const currentUser = userStore.currentUser as User
    const currentUserRole = userStore.currentUserRole
    const kid = await Kid.createWithoutData(note.kidId)
    const newNote = new Note()
    const newReadState = new ReadState()

    newNote.set('notes_topic', note.notes_topic)
    newNote.set('shared', note.shared)
    newNote.set('edit_locked', note.edit_locked)
    newNote.set('school_pointer', currentUserRole?.school_pointer)
    newNote.set('author', currentUserRole)
    newNote.set('edited_date', new Date())
    newNote.set('notes_edited', new Date())
    newNote.set('notice_user_id', currentUser.id)
    newNote.set('is_forum', false)
    newNote.set('school', currentUserRole?.school)
    newNote.set('notes_owner', currentUser)
    const destType =
      currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
        ? DEST_TYPE.PARENT
        : DEST_TYPE.STAFF
    newNote.set('dest_type', destType)
    newNote.set('kid_owner', kid)
    newNote.set('revision', 1)

    const newNoteComment = new NoteComment()
    newNoteComment.set('edited_date', new Date())
    if (note.voice_memo) {
      newNoteComment.set('voice_memo', note.voice_memo)
    }

    newNoteComment.set('note_revision', 1)
    newNoteComment.set('author', currentUserRole)
    newNoteComment.set('is_forum', false)
    newNoteComment.set('is_main', true)
    newNoteComment.set('school_pointer', currentUserRole?.school_pointer)
    newNoteComment.set('comment_owner', currentUser)
    newNoteComment.set('school', currentUserRole?.school)
    if (note.picture) {
      newNoteComment.set('picture', note.picture)
    }
    newNoteComment.set('comment', note.comment)

    newNote.addUnique('notes_comments', newNoteComment)
    newNote.set('sendPush', true)

    newReadState.set('notes', newNote)
    newReadState.set('read_revision', 1)
    newReadState.set('user', currentUser)
    newReadState.set('school_pointer', currentUserRole?.school_pointer)
    newReadState.set('school', currentUserRole?.school)
    newReadState.set('previous_read_revision', 0)
    await Parse.Object.saveAll([newNote, newReadState])
    /*await newNote.save()
    await newReadState.save()*/
    newNoteComment.set('note_id', newNote.id)
    await newNoteComment.save()
  }
  changeShared = async (note: Note, shared: boolean) => {
    note.set('shared', shared)

    await note.save()
  }
  changeEdit_locked = async (note: Note, edit_locked: boolean) => {
    note.set('edit_locked', edit_locked)

    await note.save()
  }

  addCommentToNote = async (comment: string, note: Note) => {
    const currentUser = userStore.currentUser as User
    const currentUserRole = userStore.currentUserRole

    const revision = note.revision + 1

    const newNoteComment = new NoteComment()
    newNoteComment.set('edited_date', new Date())

    newNoteComment.set('note_revision', revision)
    newNoteComment.set('author', currentUserRole)
    newNoteComment.set('is_forum', false)
    newNoteComment.set('is_main', false)
    newNoteComment.set('school_pointer', currentUserRole?.school_pointer)
    newNoteComment.set('comment_owner', currentUser)
    newNoteComment.set('school', currentUserRole?.school)
    newNoteComment.set('comment', comment)

    note.addUnique('notes_comments', newNoteComment)
    note.set('revision', revision)
    note.set('sendPush', true)
    note.set('notice_user_id', currentUser.id)
    note.set('notes_edited', new Date())
    note.set('edited_date', new Date())

    const readStateQuery = new Parse.Query(ReadState)
      .equalTo('user', currentUser)
      .equalTo('notes', note)
    const readState = await readStateQuery.first()
    if (readState) {
      readState.set('previous_read_revision', readState.read_revision)
      readState.set('read_revision', revision)

      await Parse.Object.saveAll([readState, note])
      //await readState.save()
    } else {
      const readState = new ReadState()
      readState.set('previous_read_revision', 0)
      readState.set('read_revision', revision)
      readState.set('notes', note)
      readState.set('user', currentUser)
      readState.set('school_pointer', currentUserRole?.school_pointer)
      readState.set('school', currentUserRole?.school)
      await Parse.Object.saveAll([readState, note])
    }

    newNoteComment.set('note_id', note.id)
    await newNoteComment.save()
  }

  updateReadState = async (note: Note) => {
    const currentUser = userStore.currentUser as User
    const currentUserRole = userStore.currentUserRole
    const revision = note.revision
    const readStateQuery = new Parse.Query(ReadState)
      .equalTo('user', currentUser)
      .equalTo('notes', note)
    const readState = await readStateQuery.first()
    if (readState) {
      if (revision !== readState.read_revision) {
        readState.set('previous_read_revision', readState.read_revision)
        readState.set('read_revision', revision)

        await Parse.Object.saveAll([readState, note])
      }
      //await readState.save()
    } else {
      const readState = new ReadState()
      readState.set('previous_read_revision', 0)
      readState.set('read_revision', revision)
      readState.set('notes', note)
      readState.set('user', currentUser)
      readState.set('school_pointer', currentUserRole?.school_pointer)
      readState.set('school', currentUserRole?.school)

      await readState.save()
    }
  }

  addFavoritNote = async (note: Note, userId: string) => {
    note.addUnique('is_favorite', userId)
    await note.save()
  }
  removeFavoritNote = async (note: Note, userId: string) => {
    note.remove('is_favorite', userId)
    await note.save()
  }
  archiveNote = async (note: Note) => {
    note.set('archive_state', 310)
    note.unset('is_favorite')
    note.notes_comments?.forEach(async (comment: NoteComment) => {
      comment.set('archive_state', 310)
      await comment.save()
    })
    await note.save()
  }

  deleteNote = async (note: Note) => {
    note.notes_comments?.forEach(async (comment: NoteComment) => {
      await comment.destroy()
    })
    await note.destroy()
  }

  archiveComment = async (comment: NoteComment) => {
    comment.set('archive_state', 310)
    await comment.save()
  }

  deleteComment = async (comment: NoteComment) => {
    await comment.destroy()
  }

  updateRevision = async (note: Note) => {
    const currentUser = userStore.currentUser as User
    const currentUserRole = userStore.currentUserRole

    const readStateQuery = new Parse.Query(ReadState)
      .equalTo('user', currentUser)
      .equalTo('notes', note)
    const readState = await readStateQuery.first()

    if (readState) {
      readState.set('previous_read_revision', readState.read_revision)
      readState.set('read_revision', note.revision)

      await readState.save()
    } else {
      const newReadState = new ReadState()
      newReadState.set('notes', note)
      newReadState.set('read_revision', note.revision)
      newReadState.set('user', currentUser)
      newReadState.set('school_pointer', currentUserRole?.school_pointer)
      newReadState.set('school', currentUserRole?.school)
      newReadState.set('previous_read_revision', 0)

      await newReadState.save()
    }
  }
}

export const noteStore = (() => {
  return new NoteStore()
})()
export const NoteStoreContext: React.Context<NoteStore> =
  React.createContext(noteStore)
