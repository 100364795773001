// BlogReflections.tsx

import React, { RefObject, useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPen,
  faPaperPlane,
  faSlash,
  faCogs,
  faSignOutAlt,
  faUser,
  faEllipsis,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { Button } from '../../../components/Button'
import ModalRegular from '../../../components/ModalRegular'
//import { t } from 'i18next'
import { BloggDoc } from '../../../Models/BloggDoc'
import { BloggReflections } from '../../../Models/BloggReflections'
import { getImageFromRole, getImageFromUser } from '../../../utils/parseUtils'
import { faCalendar, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import { getFullMonthForTranslatation } from '../../events/Events'
import moment from 'moment'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { ButtonDropdown } from '../../../components/Dropdown'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { PortfolioBlogStoreContext } from '../../../store/portfolioBlogStore'
import { useParams } from 'react-router-dom'
import { UserStoreContext } from '../../../store/userStore'
import { User } from '../../../Models/User'
import { useTranslation } from 'react-i18next'
import {
  ARCHIVE_STATE,
  USER_ROLE_TYPE,
  USER_TYPE,
} from '../../../Enums/TyraEnums'
import TimeStamp from '../../../components/TimeStamp'

interface BlogReflectionsProps {
  openReflection: boolean
  setOpenReflection: (isOpen: boolean) => void
  reflection: string
  updateReflection: (bloggDoc: any, value: string) => Promise<void>
  bloggDoc: BloggDoc
  reflections?: BloggReflections[]
  onSaveReflections?: () => void
}

export const BlogReflections: React.FC<BlogReflectionsProps> = ({
  openReflection,
  setOpenReflection,
  reflection,
  updateReflection,
  bloggDoc,
  reflections,
  onSaveReflections,
}) => {
  const { departments } = useContext(DepartmentStoreContext)
  const { createReflection, deleteReflection, editReflection } = useContext(
    PortfolioBlogStoreContext,
  )
  const { t } = useTranslation()
  const { currentUser, currentUserRole } = useContext(UserStoreContext)
  const [showInput, setShowInput] = useState(false)
  const [showReflections] = useState(false)
  const [textareaValue, setTextareaValue] = useState('')
  let displayText = ''
  const publishAs = bloggDoc?.attributes?.publish_as

  switch (publishAs) {
    case undefined:
      displayText =
        bloggDoc?.attributes?.user_author?.attributes?.firstName +
        ' ' +
        bloggDoc?.attributes?.user_author?.attributes?.lastName
      break
    case 'school':
      displayText =
        bloggDoc?.attributes?.school_pointer?.attributes?.display_school_name
      break
    case 'anonymous':
      displayText = ''
      break
    default:
      if (publishAs?.startsWith('dep:')) {
        const depId = publishAs.replace('dep:', '')
        const department = departments?.find((id) => id === depId)

        displayText = department?.klassName ?? ''
      } else {
        displayText = ''
      }
      break
  }
  const now = moment()
  const publishDate = moment(bloggDoc?.attributes?.publish_date)
  const updatedAt = bloggDoc?.attributes?.updatedAt

  const isUpdated = updatedAt && moment(updatedAt).diff(publishDate) !== 0
  const dateToDisplay = isUpdated ? moment(updatedAt) : publishDate
  let displayDate = ''

  if (now.diff(dateToDisplay, 'minutes') < 60) {
    displayDate =
      now.diff(dateToDisplay, 'minutes') + ' ' + t('general.minutesAgo')
  } else if (now.diff(dateToDisplay, 'hours') < 24) {
    displayDate = now.diff(dateToDisplay, 'hours') + ' ' + t('general.hoursAgo')
  } else if (now.diff(dateToDisplay, 'days') < 7) {
    displayDate = dateToDisplay.format('ddd HH:mm')
  } else {
    displayDate = dateToDisplay.format('MMM DD')
  }

  if (isUpdated) {
    displayDate = t('blog.blogUpdateTime') + ' ' + displayDate
  }
  const getDisplayDate = (inputDate: Date | string): string => {
    const now = moment()
    const date = moment(inputDate)
    let displayDate = ''

    if (now.diff(date, 'minutes') < 60) {
      displayDate = now.diff(date, 'minutes') + ' ' + t('general.minutesAgo')
    } else if (now.diff(date, 'hours') < 24) {
      displayDate = now.diff(date, 'hours') + ' ' + t('general.hoursAgo')
    } else if (now.diff(date, 'days') < 7) {
      displayDate = date.format('ddd HH:mm')
    } else {
      displayDate = date.format('MMM DD')
    }

    return displayDate
  }

  const [fakeState, setFakeState] = useState(false)
  const { schoolId } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [openDropdownId, setOpenDropdownId] = useState<null | string>(null)

  const [menuRef, togglerRef] = useClickOutside(() => setOpenDropdownId(null))
  const newReflection = new BloggReflections()

  const [editing, setEditing] = useState<string | null>(null)
  const [editedReflection, setEditedReflection] = useState('')
  const isTeacher =
    currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
      ? true
      : false

  const isAdmin =
    currentUserRole && currentUserRole?.role_type >= USER_ROLE_TYPE.ADMIN
      ? true
      : false

  return (
    <ModalRegular
      isOpen={openReflection}
      onClose={() => {
        setOpenReflection(false)
      }}
      closeColor="white"
      bgColor="p-4 bg-eventsMain"
      title={t('general.reflections')}
    >
      <div onClick={(e) => e.stopPropagation()} className="p-10">
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-row gap-x-4 items-center text-center ">
            <FontAwesomeIcon className="text-red-300" icon={faCalendar} />
            <p className={'text-xs text-gray-500 '}>
              {getFullMonthForTranslatation(
                moment(bloggDoc?.attributes?.event_date).format('MM'),
              )}{' '}
              {moment(bloggDoc?.attributes?.event_date).format('Do YYYY')}
            </p>
          </div>
          <div className=" text-2xl">{bloggDoc.attributes.title}</div>
          <div className="flex flex-row-reverse items-center mb-2">
            <div className={'text-xs ml-3 flex flex-col'}>
              <p className={'font-medium whitespace-nowrap'}>{displayText}</p>
              <div className="font-bold">
                <TimeStamp
                  date={moment(
                    bloggDoc.attributes.edited_date ??
                      bloggDoc.attributes.createdAt,
                  )}
                  displayMode="relative"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-y-2 ">
          {reflections &&
            reflections.map((ref, key) => {
              return (
                <div
                  className=" relative border px-4 py-6 rounded-2xl flex  shadow-md"
                  key={key}
                >
                  <div className="flex flex-col text-center">
                    <div className="flex flex-row items-center gap-x-4">
                      <img
                        className={'w-[45px] h-[45px] rounded-full'}
                        src={getImageFromUser(ref.user_author)}
                        alt=""
                      />
                      <p className="font-bold text-md flex items-end">
                        {' '}
                        {ref.user_author.attributes.archive_state ===
                        ARCHIVE_STATE.HISTORIC_ANONYMOUS ? (
                          <>{t('general.unknown')}</>
                        ) : (
                          <>
                            {ref.user_author.firstName +
                              ' ' +
                              ref.user_author.lastName}
                          </>
                        )}
                      </p>
                    </div>
                    <div className="flex font-medium items-start mt-2">
                      {ref.message}
                    </div>
                  </div>
                  <div className="absolute top-1 right-1 ">
                    {(ref.user_author &&
                      currentUser &&
                      ref.user_author.id === currentUser.id) ||
                    isAdmin ? (
                      <button
                        ref={togglerRef}
                        type="button"
                        className="w-full rounded-md group focus:outline-none"
                        id="options-menu"
                        aria-haspopup="true"
                        aria-expanded="true"
                        onClick={() =>
                          openDropdownId === ref.id
                            ? setOpenDropdownId(null)
                            : setOpenDropdownId(ref.id)
                        }
                      >
                        <span className="flex items-center justify-between w-full">
                          <span className="flex items-center justify-between min-w-0">
                            <div className="text-xl group-hover:text-blue-50">
                              <FontAwesomeIcon
                                className="rotate-90 h-4 w-4 text-gray-400 hover:scale-110 hover:text-gray-600"
                                icon={faEllipsis}
                              />
                            </div>
                          </span>
                        </span>
                      </button>
                    ) : (
                      <></>
                    )}

                    {openDropdownId === ref.id && (
                      <div
                        ref={menuRef as RefObject<HTMLDivElement>}
                        className="absolute right-0 z-50 mx-3 origin-top divide-y w-56 top-6"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <ButtonDropdown.Menu>
                          <ButtonDropdown.Section>
                            {ref.user_author &&
                              currentUser &&
                              ref.user_author.id === currentUser.id && (
                                <ButtonDropdown.Button
                                  icon={faEdit}
                                  className="text-blue-300"
                                  label={t('absence.change')}
                                  onClick={() => {
                                    setEditing(ref.id)
                                    setEditedReflection(ref.message)
                                  }}
                                />
                              )}
                            <ButtonDropdown.Button
                              icon={faTrashCan}
                              className="text-red-300"
                              label={t('absence.delete')}
                              onClick={() => {
                                if (currentUser) {
                                  Swal.fire({
                                    title: t('blog.deleteReflektion'),
                                    text: t('blog.deleteReflektionText'),
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#7eb5f4',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: t('general.yes'),
                                    cancelButtonText: t('general.cancel'),
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      deleteReflection(
                                        bloggDoc,
                                        ref,
                                        currentUser,
                                      ).finally(() => {
                                        if (onSaveReflections) {
                                          onSaveReflections()
                                        }
                                        Swal.fire({
                                          icon: 'success',
                                          text: t('blog.reflectionSuccess'),
                                          confirmButtonColor: '#7eb5f4',
                                          confirmButtonText: t('general.ok'),
                                        })
                                        setFakeState(!fakeState)
                                      })
                                    }
                                  })
                                }
                              }}
                            />
                          </ButtonDropdown.Section>
                        </ButtonDropdown.Menu>
                      </div>
                    )}
                  </div>
                  <div className="text-xs text-gray-400 absolute bottom-1 right-2">
                    {getDisplayDate(ref.createdAt)}
                  </div>
                  {editing === ref.id && (
                    <ModalRegular
                      isOpen={editing ? true : false}
                      onClose={() => {
                        setEditing(null)
                        setEditedReflection('')
                      }}
                      closeColor="white"
                    >
                      <div className="p-4 text-center text-white bg-eventsMain font-bold">
                        {t('blog.edit')}
                      </div>
                      <div className="p-5 flex flex-col gap-y-6">
                        <textarea
                          placeholder={t('notes.commentHere')}
                          className="p-3 pr-9 resize-none text-sm border w-full rounded-2xl max-h-[70px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                          value={editedReflection}
                          onChange={(e) => {
                            setEditedReflection(e.target.value)
                          }}
                          rows={4}
                        />
                        <div className="flex justify-between">
                          <Button
                            variant="fullRed"
                            label={t('general.cancel')}
                            onClick={() => {
                              setEditing(null)
                              setEditedReflection('')
                            }}
                          />
                          <Button
                            variant="fullBlue"
                            label={t('general.save')}
                            onClick={() => {
                              editReflection(ref, editedReflection).finally(
                                () => {
                                  setEditing(null)
                                  setEditedReflection('')
                                  if (onSaveReflections) onSaveReflections()
                                },
                              )
                            }}
                          />
                        </div>
                      </div>
                    </ModalRegular>
                  )}
                </div>
              )
            })}
        </div>
        <div className="flex p-2"> {bloggDoc.attributes.blog_reflection}</div>
        {/*<div className="flex flex-row justify-between p-2">
          <FontAwesomeIcon
            className="text-blue-300 hover:text-blue-400 hover:scale-105 cursor-pointer"
            icon={showInput ? faPen : faSlash}
            onClick={() => setShowInput(!showInput)}
          />
          </div>*/}
        {!showInput && (
          <div className="flex flex-row border rounded-2xl relative mt-12">
            <textarea
              className="p-3 pr-9 resize-none text-sm border w-full rounded-2xl max-h-[70px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
              rows={4}
              value={textareaValue}
              onChange={(e) => setTextareaValue(e.target.value)}
              placeholder={t('blog.newReflection')}
            />
            <div className="absolute bottom-2 right-2">
              <Button
                variant="roundBlue"
                size="sm"
                icon={faPaperPlane}
                onClick={async () => {
                  try {
                    if (currentUser) {
                      createReflection(
                        bloggDoc,
                        textareaValue,
                        currentUser as User,
                      ).finally(() => {
                        if (onSaveReflections) {
                          onSaveReflections()
                          setFakeState(!fakeState)
                        }
                      })
                    }
                    setTextareaValue('')
                    setShowInput(false)
                  } catch (error) {
                    console.error(error)
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </ModalRegular>
  )
}
