import {
  faChildReaching,
  faFile,
  faIdCardClip,
  faStar,
  faUser,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons'
import { t } from 'i18next'
import { useContext } from 'react'
import { UserStoreContext } from '../../store/userStore'
import { UserRole } from '../../Models/UserRole'

export const getDocsData = (type: string, currentUserRole?: UserRole) => {
  const documentsText: string = t('documents.kidDocumentTitle')
  if (type === 'my') {
    return {
      title: t('documentation.myDocument'),
      color: 'text-documentsMain',
      icon: faUser,
      showShare: true,
      bgColor: 'bg-documentsMain',
    }
  }
  if (type === 'school') {
    return {
      title: t('documentation.schoolDocument'),
      color: 'text-classlistMain',
      icon: faFile,
      showShare: false,
      bgColor: 'bg-classlistMain',
    }
  }
  if (type === 'only_staff') {
    return {
      title: t('documentation.onlyStaffDocument'),
      color: 'text-notesMain',
      icon: faIdCardClip,
      bgColor: 'bg-notesMain',
    }
  }
  if (type === 'students') {
    return {
      title: t('documentation.kidDocumentation'),
      color: 'text-groupsMain',
      icon: faChildReaching,
      bgColor: 'bg-groupsMain',
    }
  }
  if (type === 'kid') {
    return {
      title: documentsText.replace(
        'XXKIDSNAMEXX',
        currentUserRole?.kid?.firstName ?? '',
      ),
      color: 'text-groupsMain',
      icon: faChildReaching,
      bgColor: 'bg-groupsMain',
    }
  }
  if (type === 'favorite') {
    return {
      title: t('documentation.favourites'),
      color: 'text-contactsMain',
      icon: faStar,
      bgColor: 'bg-contactsMain',
    }
  }
  if (type === 'shared') {
    return {
      title: t('documentation.sharedDocument'),
      color: 'text-blogMain',
      icon: faUserGroup,
      bgColor: 'bg-blogMain',
    }
  }

  return {}
}
