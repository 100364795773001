import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'
import {
  faPause,
  faPlay,
  faForward,
  faBackward,
  faWaveform,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import AudioModal from './AudioModal'
import { faWaveSine } from '@fortawesome/pro-regular-svg-icons'

type AudioPlayerProps = {
  src: any
  size?: 'default' | 'small' | 'play'
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  src = 'defaultAudioPath.mp3',
  size = 'default',
}) => {
  const leftPosition = size === 'default' ? 'left-20' : 'left-12'
  const playerWidth = size === 'default' ? 'w-[200px]' : 'w-[150px]'
  const [isPlaying, setIsPlaying] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const audioRef = useRef<HTMLAudioElement | null>(null)

  useEffect(() => {
    const audio = audioRef.current
    if (!audio) return

    const updateTime = () => setCurrentTime(audio.currentTime)
    const setAudioDuration = () => setDuration(audio.duration)
    const handleAudioEnded = () => {
      setIsPlaying(false)
    }

    audio.addEventListener('timeupdate', updateTime)
    audio.addEventListener('loadedmetadata', setAudioDuration)
    audio.addEventListener('ended', handleAudioEnded)

    return () => {
      audio.removeEventListener('timeupdate', updateTime)
      audio.removeEventListener('loadedmetadata', setAudioDuration)
      audio.removeEventListener('ended', handleAudioEnded)
    }
  }, [])

  const togglePlayback = () => {
    const audio = audioRef.current
    if (audio) {
      if (isPlaying) {
        audio.pause()
      } else {
        audio.play()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const jumpForward = () => {
    const audio = audioRef.current
    if (audio) {
      audio.currentTime = Math.min(audio.currentTime + 10, duration)
    }
  }

  const jumpBackward = () => {
    const audio = audioRef.current
    if (audio) {
      audio.currentTime = Math.max(audio.currentTime - 10, 0)
    }
  }

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60)
    const secs = Math.floor(seconds % 60)
    return `${mins}:${String(secs).padStart(2, '0')}`
  }
  if (size === 'play') {
    return (
      <div
        className={`icon-container cursor-pointer font-medium flex items-center gap-x-3 justify-center flex-row relative hover:scale-[102%] w-[100px] h-10  bg-blue-50  border-[1px] border-blue-400 shadow rounded-xl p-2`}
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon
          className={'flex transform  cursor-pointer text-blue-400'}
          onClick={(e) => {
            e.stopPropagation() // To prevent triggering the enclosing div's click event
            setShowModal(true)
          }}
          icon={faWaveform}
          size="1x"
        />
        <div className="text-xs text-blue-400">{formatTime(duration)}</div>
        {src && <audio ref={audioRef} src={src} />}
        <AudioModal
          src={src}
          showModal={showModal}
          hideModal={() => setShowModal(false)}
        />
      </div>
    )
  }

  return (
    <div
      className={`relative ${playerWidth} h-14 shadow border border-green-100 bg-emerald-100 rounded-2xl p-2`}
    >
      {' '}
      <FontAwesomeIcon
        className={`absolute top-1/2 left-1/3 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer hover:scale-[110%] ${
          isPlaying
            ? 'text-red-600 hover:text-red-600'
            : 'text-eventsMain hover:text-blue-400'
        } `}
        onClick={togglePlayback}
        icon={isPlaying ? faPause : faPlay}
      />
      <div
        className={`absolute ${leftPosition} top-1/2 transform -translate-y-1/2 ml-5 space-x-2`}
      >
        <div className="flex flex-row gap-x-2">
          <div
            className={`bg-gray-600 h-3 w-1 rounded ${
              isPlaying ? 'animate-audiowave' : ''
            }`}
          />
          <div
            className={`bg-gray-600 h-5 w-1 rounded ${
              isPlaying ? 'animate-audiowaveslow' : ''
            }`}
          />
          <div
            className={`bg-gray-600 h-4 w-1 rounded ${
              isPlaying ? 'animate-audiowave' : ''
            }`}
          />
          <div
            className={`bg-gray-600 h-5 w-1 rounded ${
              isPlaying ? 'animate-audiowave' : ''
            }`}
          />
        </div>
      </div>
      <FontAwesomeIcon
        className="absolute top-1/2 left-[18px] transform -translate-x-1/2 -translate-y-1/2 cursor-pointer hover:scale-[110%] hover:text-red-600"
        onClick={jumpBackward}
        icon={faClockRotateLeft}
        title={t('blog.audioPlayerBwr')}
      />
      {/* Tidsinformation */}
      <div className="absolute bottom-0 left-1/3 text-xs">
        {formatTime(currentTime)} / {formatTime(duration)}
      </div>
      <FontAwesomeIcon
        className="absolute top-1/2 right-1 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer  hover:scale-[110%] rotate-180 hover:text-green-600"
        onClick={jumpForward}
        icon={faClockRotateLeft}
        title={t('blog.audioPlayerFwr')}
      />
      {src && <audio ref={audioRef} src={src} />}
    </div>
  )
}

export default AudioPlayer
