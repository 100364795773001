import Parse from 'parse'
import { ARCHIVE_STATE } from '../Enums/TyraEnums'
import { School } from './School'
import { User } from './User'

export class NoteComment extends Parse.Object {
  public static readonly Fields = {
    school: 'school',
    className: 'notes_comments',
  }
  constructor() {
    super('notes_comments')
  }

  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
  get is_main(): boolean {
    return this.get('is_main')
  }
  get comment(): string {
    return this.get('comment')
  }
  get createdAt(): Date {
    return this.get('createdAt')
  }
  get editedAt(): Date {
    return this.get('editedAt')
  }
  get comment_owner(): User {
    return this.get('comment_owner')
  }
  get picture(): Parse.File {
    return this.get('picture')
  }
  get voice_memo(): Parse.File {
    return this.get('voice_memo')
  }
  get archive_state(): ARCHIVE_STATE | undefined {
    return this.get('archive_state')
  }
  set archive_state(value: ARCHIVE_STATE | undefined) {
    value ? this.set('archive_state', value) : this.unset('archive_state')
  }
}
Parse.Object.registerSubclass('notes_comments', NoteComment)
