import classNames from 'classnames'
import React, { useState } from 'react'
import { LoadingBar } from './LoadingBar'

interface Props {
  children: React.ReactNode
  description?: string
  title?: string
  loading?: boolean
}

export default function Card(props: Props) {
  return (
    <div
      className={'w-full overflow-hidden rounded-2xl shadow-tyraShadow mt-2'}
    >
      {props?.loading && <LoadingBar />}
      <div className="flex space-x-4 rounded-b-2xl items-center font-bold font-sans bg-white text-neutral-600  p-3 relative">
        <div
          className={'flex flex-wrap py-3 font-medium justify-center w-full'}
        >
          {props.children}
        </div>
      </div>
    </div>
  )
}
