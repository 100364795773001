import {
  faEnvelope,
  faPersonWalkingArrowLoopLeft,
  faPersonWalkingArrowRight,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button } from '../../../components/Button'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { IKidRelation, User } from './OverviewUsersList'
import moment from 'moment'
import { ContactStoreContext } from '../../../store/contactStore'
import OverviewUserContactDetailsPopUp from './OverviewUserContactDetailsPopUp'
import { Event3StoreContext } from '../../../store/event3Store'
import { EventListItemData } from '../Events3'
import Parse from 'parse'
import { UserRole } from '../../../Models/UserRole'
import { USER_ROLE_STATUS } from '../../../Enums/TyraEnums'

interface PopupProps {
  eventDetail?: EventListItemData
  user: User | undefined
  onClose: () => void
  refreshDetail: () => void
  dataAction?: string
}

const OverviewReqResUserPopUp: React.FC<PopupProps> = observer(
  ({ eventDetail, user, onClose, refreshDetail, dataAction }) => {
    const { t } = useTranslation()
    const popupRef = useRef<HTMLDivElement>(null)
    const { fetchContact, contact } = useContext(ContactStoreContext)
    const {
      isPresentOnEventTime,
      declineParticipatingInEvent,
      acceptParticipatingInEvent,
    } = useContext(Event3StoreContext)
    const [
      isUserContactDetailsPopUpVisible,
      setUserContactDetailsPopUpVisible,
    ] = useState<boolean>(false)
    const [editParticipating, setEditParticipating] = useState<boolean>(false)
    const [personalMadeAction, setPersonalMadeAction] = useState<string>()
    const [relativeContact, setRelativeContact] = useState<
      IKidRelation | User
    >()

    const handleClosePopup = () => {
      setUserContactDetailsPopUpVisible(false)
    }

    const handleClickOutside = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      event.stopPropagation()
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    const handleEditParticipating = () => {
      setEditParticipating(!editParticipating)
    }

    const handleEditResponse = () => {
      if (user?.status === 'accepted') {
        return (
          <div className="flex flex-col justify-center items-center p-2">
            <p className="pb-2 font-bold">!will {user.name} attend?</p>
            <Button
              size="lg"
              variant="borderRed"
              label={t('general.no') + ' 👎'}
              onClick={async (e) => {
                e.stopPropagation()
                declineParticipatingInEvent(
                  eventDetail?.eventTimeId,
                  [user?.userId ?? ''],
                  [user.kidId ?? ''],
                )
                refreshDetail()
                handleEditParticipating()
              }}
            />
          </div>
        )
      } else if (user?.status === 'declined' || user?.status === 'cancelled') {
        return (
          <div className="flex flex-col justify-center items-center p-2">
            <p className="pb-2 font-bold">!will {user.name} attend?</p>
            <Button
              size="lg"
              variant="borderGreen"
              label={t('general.yes') + ' 👍'}
              onClick={async (e) => {
                e.stopPropagation()

                console.log(user)
                acceptParticipatingInEvent(
                  eventDetail?.eventTimeId,
                  [user?.userId ?? ''],
                  [user.kidId ?? ''],
                )
                refreshDetail()
                handleEditParticipating()
              }}
            />
          </div>
        )
      } else if (user?.status === 'invited') {
        return (
          <div className="flex flex-col justify-center items-center p-2">
            <p className="pb-2 font-bold">!will {user.name} attend?</p>
            <div className="flex gap-2">
              <Button
                size="lg"
                variant="borderRed"
                label={t('general.no') + ' 👎'}
                onClick={async (e) => {
                  e.stopPropagation()

                  declineParticipatingInEvent(
                    eventDetail?.eventTimeId,
                    [user?.userId ?? ''],
                    [user.kidId ?? ''],
                  )
                  refreshDetail()
                  handleEditParticipating()
                }}
              />
              <Button
                size="lg"
                variant="borderGreen"
                label={t('general.yes') + ' 👍'}
                onClick={async (e) => {
                  e.stopPropagation()
                  acceptParticipatingInEvent(
                    eventDetail?.eventTimeId,
                    [user?.userId ?? ''],
                    [user.kidId ?? ''],
                  )
                  refreshDetail()
                  handleEditParticipating()
                }}
              />
            </div>
          </div>
        )
      } else {
        return null
      }
    }

    const acceptedAt = moment(user?.acceptedAt).format('YYYY-MM-DD')
    const cancelledAt = moment(user?.cancelledAt).format('YYYY-MM-DD')

    useEffect(() => {
      console.log(user)
      const roleID =
        relativeContact && 'roleId' in relativeContact
          ? relativeContact.roleId
          : relativeContact && 'userRoleId' in relativeContact
          ? relativeContact.userRoleId
          : null

      const fetchContactDetails = async () => {
        if (roleID) await fetchContact(roleID)
      }
      const userRole = async () => {
        const userA = new Parse.Query(UserRole)
          .equalTo('status', USER_ROLE_STATUS.ACTIVE)
          .equalTo(
            'objectId',
            user?.status === 'accepted'
              ? user?.acceptedBy
              : user?.status === 'cancelled' || user?.status === 'declined'
              ? user?.cancelledBy
              : '',
          )
          .include('user')
        const role = await userA.first()
        setPersonalMadeAction(
          `${role?.user?.firstName + ' ' + role?.user?.lastName}`,
        )
      }
      fetchContactDetails()
      userRole()
    }, [user, relativeContact])

    return (
      <>
        <div
          className="fixed inset-0 flex items-center  justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50"
          onClick={handleClickOutside}
        >
          <div
            className="bg-white my-auto w-3/5 p-6 rounded-lg shadow-lg relative sm:h-3/ sm:w-1/2"
            ref={popupRef}
          >
            <div className="flex justify-end">
              <FontAwesomeIcon
                icon={faXmark}
                size="xl"
                className="text-gray-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  onClose()
                }}
              />
            </div>

            <div className="flex flex-col">
              {user?.relatives &&
                user.relatives.map((relative) => (
                  <React.Fragment key={relative.userId}>
                    <div className="flex items-center border-b-2 py-2">
                      <img
                        className={`w-12 h-12 rounded-full object-cover ${
                          !relative?.thumbnail && 'bg-gray-300'
                        }`}
                        src={relative?.thumbnail}
                        alt=""
                      />
                      <div className="flex flex-col font-bold p-2 ">
                        <p className=" text-gray-900 ">{relative?.name}</p>
                        <p
                          className=" text-blue-600 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation()

                            setUserContactDetailsPopUpVisible(
                              !isUserContactDetailsPopUpVisible,
                            )
                            setRelativeContact(relative)
                          }}
                        >
                          See contact details
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              <div className="flex w-[90%] items-center justify-between">
                <div className="flex items-center border-b-2 py-2">
                  <img
                    className={`w-12 h-12 rounded-full object-cover ${
                      !user?.thumbnail && 'bg-gray-300'
                    }`}
                    src={user?.thumbnail}
                    alt=""
                  />
                  <div className="flex flex-col font-bold p-2 ">
                    <p className=" text-gray-900 ">{user?.name}</p>
                    {!!user?.kidId === false ? (
                      <p
                        className=" text-blue-600 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation()

                          setUserContactDetailsPopUpVisible(
                            !isUserContactDetailsPopUpVisible,
                          )
                          setRelativeContact(user)
                        }}
                      >
                        See contact details
                      </p>
                    ) : (
                      <p className="text-gray-500">
                        {user?.departmentNames && user?.departmentNames[0]}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={`${
                  user?.kids && user?.kids?.length > 2 && 'h-[40vh]'
                } overflow-y-auto`}
              >
                {user?.kids?.map((child, index) => (
                  <div
                    className="flex items-center border-b-2 py-1"
                    key={child.kidId}
                  >
                    <img
                      className={`w-12 h-12 rounded-full object-cover ${
                        !child?.thumbnail && 'bg-gray-300'
                      }`}
                      src={child?.thumbnail}
                      alt=""
                    />
                    <div className="flex flex-col items-start p-2">
                      <p key={child.kidId} className="font-bold text-gray-900">
                        {child.name}
                      </p>
                      <p key={index} className="font-semibold text-gray-500">
                        {child.departmentName}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              {user?.acceptedBy && user.status === 'accepted' && (
                <div className="flex flex-col items-end">
                  <p className="font-bold text-gray-500">
                    Added By {personalMadeAction}
                  </p>
                  <p className="font-bold text-gray-500">{acceptedAt}</p>
                </div>
              )}
              {user?.cancelledBy &&
                (user.status === 'cancelled' || user.status === 'declined') && (
                  <div className="flex flex-col items-end">
                    <p className="font-bold text-red-500">
                      !Declined By {personalMadeAction}
                    </p>
                    <p className="font-bold text-red-500">{cancelledAt}</p>
                  </div>
                )}
              {user?.message && (
                <div className="flex flex-col justify-center">
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      size="lg"
                      icon={faEnvelope}
                      className="text-gray-400"
                    />
                    <p className="pl-1 font-semibold">Message</p>
                  </div>
                  <p>{user.message}</p>
                </div>
              )}

              {!!user?.status === true &&
                user?.status === 'accepted' &&
                dataAction !== 'messages' && (
                  <div className="flex justify-center items-center h-[80%]">
                    <div className=" flex flex-col m-1 gap-1">
                      <Button
                        size="lg"
                        icon={
                          user?.isPresent === true
                            ? faPersonWalkingArrowLoopLeft
                            : faPersonWalkingArrowRight
                        }
                        fontSize="text-xl"
                        variant={
                          user?.isPresent === false ||
                          user?.isPresent === undefined
                            ? 'fullGreen'
                            : 'fullRed'
                        }
                        label={
                          user?.isPresent === false ||
                          user?.isPresent === undefined
                            ? 'Present'
                            : 'Leaving'
                        }
                        onClick={(e) => {
                          e.stopPropagation()

                          try {
                            const presentStatus = !!!user?.isPresent
                            isPresentOnEventTime(
                              eventDetail?.eventTimeId,
                              [user?.id ?? ''],
                              presentStatus,
                            )
                            refreshDetail()
                            onClose()
                          } catch (error) {
                            console.error(error)
                          }
                        }}
                      />
                      {user?.isPresent === false ||
                      user?.isPresent === undefined ? (
                        <p className="text-gray-400 font-semibold">
                          !!Confirm present at the event
                        </p>
                      ) : (
                        <p className="text-gray-400 font-semibold">
                          !!Confirm leaving the event
                        </p>
                      )}
                    </div>
                  </div>
                )}
              {dataAction !== 'messages' ? (
                <>
                  {editParticipating === false && user?.status !== 'invited' ? (
                    <p
                      className="flex justify-center font-bold text-blue-400 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleEditParticipating()
                      }}
                    >
                      Edit
                    </p>
                  ) : (
                    handleEditResponse()
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            {/*  {user?.status === 'invited' && (
              <div>
                {!user?.hasRead && (
                  <div className="flex justify-center sticky pt-2">
                    <Button
                      className="w-[50%]"
                      size="lg"
                      fontSize="text-xl"
                      variant="borderMain"
                      label="Send remider"
                      onClick={() =>
                        sendReminder(eventDetail?.eventTimeId, [
                          user?.userId ?? '',
                        ])
                      }
                    />
                  </div>
                )}{' '}
              </div>
            )} */}
          </div>
        </div>
        {isUserContactDetailsPopUpVisible && (
          <OverviewUserContactDetailsPopUp
            user={relativeContact}
            contact={contact}
            onClose={handleClosePopup}
          />
        )}
      </>
    )
  },
)

export default OverviewReqResUserPopUp
