/* eslint-disable no-lone-blocks */
import { useContext, useEffect, useRef, useState } from 'react'
import { Button } from '../../../../components/Button'
import ColorCard from '../../../../components/ColorCard'
import Page from '../../../../components/Page'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Swal from 'sweetalert2'
import { Slider } from '../../../../components/Slider'
import PhoneInput from 'react-phone-input-2'
import { NavigationStoreContext } from '../../../../store/navigationStore'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { Department } from '../../../../Models/Department'
import { UserRole } from '../../../../Models/UserRole'
import { useParams } from 'react-router-dom'
import { AdminStoreContext } from '../../../../store/adminStore'
import { UserStoreContext } from '../../../../store/userStore'
import {
  LOGIN_METHOD,
  TERRITORY,
  USER_ROLE_TYPE,
} from '../../../../Enums/TyraEnums'
import CustomInput from '../../../../components/CustomInput'
import { Loading } from '../../../../components/Loading'
import { UserRoleConfiguration } from '../../../../App/intefaces/configuration'
import { useTranslation } from 'react-i18next'
import React from 'react'

export interface InvitePersonalInterface {
  type: string //'mobile' | 'email' | 'ssn'
  username: string
  name: string
  primary_department: Department | null
  admin: boolean
  school_account: boolean
  staff_scheduele: boolean
  staff_sensitive_information: boolean
  staff_statistics: boolean
  super_admin: boolean
  configuration: UserRoleConfiguration | undefined
}

const initialValues = {
  type: 'mobile',
  username: '',
  name: '',
  primary_department: null,
  admin: false,
  school_account: false,
  staff_scheduele: false,
  staff_sensitive_information: false,
  staff_statistics: false,
  super_admin: false,
  configuration: {
    checking: {
      readOnly: false,
    },
    upload: {
      readOnly: false,
    },
    absence: {
      readOnly: false,
    },
    activities: {
      readOnly: false,
    },
    ska: {
      readOnly: false,
    },
    lostAndFound: {
      readOnly: false,
    },
    events: {
      readOnly: false,
    },
    documents: {
      readOnly: false,
    },
    box: {
      readOnly: false,
    },
    notes: {
      readOnly: false,
    },
    childInfo: {
      readOnly: false,
    },
  },
}

export const InvitePersonal: React.FC<{
  onClick?: any
  userRole?: UserRole
}> = ({ userRole }) => {
  const [newPersonal, setNewPersonal] =
    useState<InvitePersonalInterface>(initialValues)

  const { t } = useTranslation()

  const [showDetailSwitches, setShowDetailSwitches] = useState(false)

  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('admin.inviteStaff'))
  }, [t])
  const { departments, fetchDepartments } = useContext(DepartmentStoreContext)
  const { inviteTeacher } = useContext(AdminStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  const { schoolId } = useParams()

  useEffect(() => {
    if (schoolId) {
      setLoading(true)
      fetchDepartments(schoolId).finally(() => {
        setLoading(false)
      })
    }
  }, [schoolId])
  const ssnRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)

  const [loading, setLoading] = useState(false)

  const countOfSwitches = (configuration: any) => {
    let count = 0
    for (let key in configuration) {
      if (
        configuration.hasOwnProperty(key) &&
        configuration[key].readOnly === false
      ) {
        count++
      }
    }
    return count
  }

  const [fakeState, setFakeState] = useState(false)
  const [emailAllowed, setEmailAllowed] = useState(false)
  const [mobileAllowed, setMobileAllowed] = useState(false)
  const [grandIdAllowed, setGrandIdAllowed] = useState(false)

  const readOnlyState = (configuration: any) => {
    if (!configuration) {
      return true
    }
    const count = countOfSwitches(configuration)
    if (count === 0) {
      return false
    }
    return true
  }
  const updateDetail = (configuration: any, type: string, value: boolean) => {
    if (configuration) {
      for (let key in configuration) {
        if (configuration.hasOwnProperty(key) && key === type) {
          configuration[key].readOnly = value
        }
      }
      setNewPersonal(newPersonal)
    }
    setFakeState(!fakeState)
  }

  useEffect(() => {
    if (currentUserRole?.school_pointer.isIDPSchool) {
      const idpConfiguration =
        currentUserRole.school_pointer.attributes.idp?.attributes.configuration

      if (
        idpConfiguration.approved_invite_methods.find(
          (methods: number) => methods === LOGIN_METHOD.GRANDID,
        )
      ) {
        setGrandIdAllowed(true)
        setNewPersonal({
          ...newPersonal,
          type: 'ssn',
          username: '',
        })
      }
      if (
        idpConfiguration.approved_invite_methods.find(
          (methods: number) => methods === LOGIN_METHOD.EMAIL,
        )
      ) {
        setEmailAllowed(true)
        setNewPersonal({
          ...newPersonal,
          type: 'email',
          username: '',
        })
      }
      if (
        idpConfiguration.approved_invite_methods.find(
          (methods: number) => methods === LOGIN_METHOD.PHONE,
        )
      ) {
        setMobileAllowed(true)
        setNewPersonal({
          ...newPersonal,
          type: 'mobile',
          username: '',
        })
      }
    } else {
      setMobileAllowed(true)
      setEmailAllowed(true)
      setGrandIdAllowed(true)
    }
  }, [currentUserRole])

  return (
    <>
      {loading && <Loading />}
      <Page
        topBar={
          <div className="flex flex-col items-center gap-y-6 sm:flex-row sm:gap-x-10 w-full justify-center">
            {mobileAllowed && (
              <Button
                className={'w-24 sm:w-36 h-10 sm:h-12'}
                variant={
                  newPersonal.type === 'mobile' ? 'borderMain' : 'disableGray'
                }
                label={t('ourKids.mobil')}
                onClick={() =>
                  setNewPersonal({
                    ...newPersonal,
                    type: 'mobile',
                    username: '',
                  })
                }
                size={'customSize'}
              />
            )}
            {emailAllowed && (
              <Button
                className={'w-24 sm:w-36 h-10 sm:h-12'}
                variant={
                  newPersonal.type === 'email' ? 'borderMain' : 'disableGray'
                }
                size={'customSize'}
                label={t('login.email')}
                onClick={() =>
                  setNewPersonal({
                    ...newPersonal,
                    type: 'email',
                    username: '',
                  })
                }
              />
            )}
            {currentUserRole?.school_pointer.territory === TERRITORY.SWEDEN && (
              <>
                {grandIdAllowed && (
                  <Button
                    className={'w-24 sm:w-36 h-10 sm:h-12'}
                    variant={
                      newPersonal.type === 'ssn' ? 'borderMain' : 'disableGray'
                    }
                    size={'customSize'}
                    label={t('general.socialNr')}
                    onClick={() =>
                      setNewPersonal({
                        ...newPersonal,
                        type: 'ssn',
                        username: '',
                      })
                    }
                  />
                )}
              </>
            )}
          </div>
        }
        naked
      >
        <ColorCard bgColor="bg-pink-200 p-7 justify-center flex">
          <div className="flex flex-wrap justify-center gap-4 rounded-2xl">
            <div className="w-full flex flex-col font-normal ">
              <div className="p-10">
                <div className="w-full flex flex-col gap-y-4 mb-4">
                  {newPersonal.type === 'mobile' && (
                    <>
                      <div className="flex w-full ">
                        <div className=" object-cover border items-center flex justify-center flex-col rounded-full px-[1px] md:w-1/2">
                          <PhoneInput
                            containerClass="bb-[10px]"
                            value={newPersonal.username}
                            country={'se'}
                            /*onChange={(value, countryData) => {
                            // Check if the first character is '0' and country is Sweden
                            if (
                              'dialCode' in countryData &&
                              countryData.dialCode === '+46'
                            ) {
                              if (value.startsWith('0')) {
                                // Remove the first '0' and update the state
                                value = '+46' + value.substring(1)
                              } else if (value.startsWith('+46')) {
                                // If the value doesn't start with +46, prepend it
                                value = '+46' + value
                              }
                            }
                            setNewPersonal({ ...newPersonal, username: value })
                          }}*/
                            onChange={(phone, xx, e) => {
                              var s = phone
                              if (s.charAt(2) === '0') {
                                e.preventDefault()
                                var position = 3 // its 1 based
                                s =
                                  s.substring(0, position - 1) +
                                  s.substring(position, s.length)
                              }
                              setNewPersonal({
                                ...newPersonal,
                                username: '+' + s,
                              })
                            }}
                            autoFormat={false}
                          />
                        </div>
                      </div>
                      <p>
                        <>{t('admin.twoFactorSliderText')}</>
                      </p>
                    </>
                  )}
                  {newPersonal.type === 'email' && (
                    <>
                      <div className="w-full md:w-1/2">
                        <CustomInput
                          ref={emailRef}
                          required={true}
                          label={t('login.email')}
                          value={newPersonal.username}
                          onChange={(newValue) => {
                            setNewPersonal({
                              ...newPersonal,
                              username: newValue,
                            })
                          }}
                        />
                      </div>
                      <p>
                        <>{t('admin.userLoginEmail')}</>
                      </p>
                    </>
                  )}
                  {newPersonal.type === 'ssn' && (
                    <>
                      <div className="w-full md:w-1/2">
                        <CustomInput
                          ref={ssnRef}
                          format="YYYYMMDD-XXXX"
                          required={true}
                          label={t('general.socialNr')}
                          value={newPersonal.username}
                          onChange={(newValue) => {
                            setNewPersonal({
                              ...newPersonal,
                              username: newValue,
                            })
                          }}
                        />
                      </div>
                      <p>
                        <>{t('admin.userLoginBankID')}</>
                      </p>
                    </>
                  )}

                  <div className="w-full md:w-1/2">
                    <CustomInput
                      ref={nameRef}
                      required={true}
                      label={t('documents.name')}
                      value={newPersonal.name}
                      onChange={(newValue) => {
                        setNewPersonal({
                          ...newPersonal,
                          name: newValue,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-y-8">
                  <div className="font-normal">
                    <div className="font-bold text-xl">
                      <>{t('admin.choosePrimaryDep')}</>
                    </div>
                    <div className="mt-2">
                      <div>
                        <div className="flex w-full lg:justify-start justify-center p-4 flex-wrap ">
                          {departments?.map((department) => (
                            <div
                              key={department.id}
                              className={`m-2 p-2 rounded-full flex flex-row relative justify-center min-w-[190px] max-w-[200px] overflow-hidden shadow-md cursor-pointer no-select ${
                                newPersonal.primary_department?.id ===
                                department.id
                                  ? 'bg-blue-300 hover:bg-blue-300 border-none text-white'
                                  : 'text-gray-500 border  bg-gray-50'
                              }`}
                              onClick={() => {
                                if (
                                  newPersonal.primary_department?.id ===
                                  department.id
                                ) {
                                  setNewPersonal({
                                    ...newPersonal,
                                    primary_department: null,
                                  })
                                } else {
                                  setNewPersonal({
                                    ...newPersonal,
                                    primary_department: department,
                                  })
                                }
                              }}
                            >
                              {userRole?.staff_primary_dep?.id ===
                                department.id && (
                                <div className="ml-2 absolute top-[9px] right-4 ">
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className="text-lime-300"
                                  />
                                </div>
                              )}
                              <div className="max-w-[120px] flex justify-start overflow-hidden">
                                {department.attributes.klassName}
                              </div>
                              {newPersonal.primary_department?.id ===
                                department.id && (
                                <div className="ml-2 absolute top-[9px] right-4 ">
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className="text-lime-300"
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-4 font-normal">
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('admin.userAdminPermission')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={newPersonal.admin}
                          onSliderClick={() => {
                            if (newPersonal.admin) {
                              setNewPersonal({
                                ...newPersonal,
                                admin: false,
                                super_admin: false,
                                school_account: false,
                                staff_scheduele: false,
                                staff_statistics: false,
                                staff_sensitive_information: false,
                                configuration: undefined,
                              })
                            } else {
                              setNewPersonal({
                                ...newPersonal,
                                admin: true,
                                staff_scheduele: true,
                                staff_statistics: true,
                                staff_sensitive_information: true,
                                configuration: initialValues.configuration,
                                super_admin: false,
                                school_account: true,
                              })
                            }
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>
                    {newPersonal.admin &&
                      currentUserRole?.role_type ===
                        USER_ROLE_TYPE.SUPER_ADMIN && (
                        <div className="flex flex-row justify-between w-full items-center">
                          <h1 className="flex w-full justify-start">
                            <>{t('admin.userAccessModuls')}</>
                          </h1>
                          <div>
                            <Slider
                              id={0}
                              value={newPersonal.super_admin}
                              onSliderClick={() => {
                                setNewPersonal({
                                  ...newPersonal,
                                  super_admin: !newPersonal.super_admin,
                                })
                              }}
                              onBoxClick={() => {}}
                            />
                          </div>
                        </div>
                      )}

                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('admin.userSchoolAcc')}</>
                      </h1>
                      <div>
                        {newPersonal.admin ? (
                          <Slider
                            id={0}
                            onSliderClick={() => {}}
                            onBoxClick={() => {}}
                            value={true}
                            disabled
                          />
                        ) : (
                          <Slider
                            id={0}
                            value={newPersonal.school_account}
                            onSliderClick={() => {
                              setNewPersonal({
                                ...newPersonal,
                                school_account: !newPersonal.school_account,
                              })
                            }}
                            onBoxClick={() => {}}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('admin.userEditSchedule')}</>
                      </h1>
                      <div>
                        {newPersonal.admin ? (
                          <Slider
                            id={0}
                            onSliderClick={() => {}}
                            onBoxClick={() => {}}
                            value={true}
                            disabled
                          />
                        ) : (
                          <Slider
                            id={0}
                            value={newPersonal.staff_scheduele}
                            onSliderClick={() => {
                              setNewPersonal({
                                ...newPersonal,
                                staff_scheduele: !newPersonal.staff_scheduele,
                              })
                            }}
                            onBoxClick={() => {}}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('admin.userSensitiveInfo')}</>
                      </h1>
                      <div>
                        {newPersonal.admin ? (
                          <Slider
                            id={0}
                            onSliderClick={() => {}}
                            onBoxClick={() => {}}
                            value={true}
                            disabled
                          />
                        ) : (
                          <Slider
                            id={0}
                            value={newPersonal.staff_sensitive_information}
                            onSliderClick={() => {
                              setNewPersonal({
                                ...newPersonal,
                                staff_sensitive_information:
                                  !newPersonal.staff_sensitive_information,
                              })
                            }}
                            onBoxClick={() => {}}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('admin.userStatistic')}</>
                      </h1>
                      <div>
                        {newPersonal.admin ? (
                          <Slider
                            id={0}
                            onSliderClick={() => {}}
                            onBoxClick={() => {}}
                            value={true}
                            disabled
                          />
                        ) : (
                          <Slider
                            id={0}
                            value={newPersonal.staff_statistics}
                            onSliderClick={() => {
                              setNewPersonal({
                                ...newPersonal,
                                staff_statistics: !newPersonal.staff_statistics,
                              })
                            }}
                            onBoxClick={() => {}}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('admin.userAllowChangeInfo')}</>
                      </h1>
                      <div>
                        {newPersonal.admin ? (
                          <Slider
                            id={0}
                            onSliderClick={() => {}}
                            onBoxClick={() => {}}
                            value={true}
                          />
                        ) : (
                          <Slider
                            id={0}
                            value={readOnlyState(newPersonal.configuration)}
                            onSliderClick={() => {
                              setNewPersonal((prevState) => {
                                if (!prevState.configuration) {
                                  return {
                                    ...prevState,
                                    configuration: initialValues.configuration, // Återställ till initialt värde eller hantera på annat sätt
                                  }
                                }

                                const shouldBeReadOnly =
                                  !prevState.configuration.notes.readOnly

                                const newConfiguration = Object.keys(
                                  prevState.configuration,
                                ).reduce((acc, key) => {
                                  const configKey =
                                    key as keyof UserRoleConfiguration
                                  acc[configKey] = {
                                    readOnly: shouldBeReadOnly,
                                  }
                                  return acc
                                }, {} as UserRoleConfiguration)

                                return {
                                  ...prevState,
                                  configuration: newConfiguration,
                                }
                              })
                            }}
                            onBoxClick={() => {}}
                          />
                        )}
                      </div>
                    </div>
                    {newPersonal.configuration && (
                      <>
                        <span
                          className="text-eventsMain font-bold cursor-pointer w-full text-right"
                          onClick={() => {
                            setShowDetailSwitches(!showDetailSwitches)
                          }}
                        >
                          {t('admin.showDetailFor')}{' '}
                          {countOfSwitches(newPersonal.configuration)}/11
                        </span>
                        {showDetailSwitches && newPersonal.configuration && (
                          <>
                            <div className="flex flex-row justify-between w-full items-center">
                              <h1 className="flex w-full justify-start">
                                <>{t('menu.events')}</>
                              </h1>
                              <div>
                                <Slider
                                  id={0}
                                  value={
                                    newPersonal.configuration.events.readOnly
                                      ? false
                                      : true
                                  }
                                  onSliderClick={() => {
                                    updateDetail(
                                      newPersonal.configuration,
                                      'events',
                                      newPersonal?.configuration?.events
                                        .readOnly
                                        ? false
                                        : true,
                                    )
                                  }}
                                  onBoxClick={() => {}}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row justify-between w-full items-center">
                              <h1 className="flex w-full justify-start">
                                <>{t('menu.upload')}</>
                              </h1>
                              <div>
                                <Slider
                                  id={0}
                                  value={
                                    newPersonal.configuration.upload.readOnly
                                      ? false
                                      : true
                                  }
                                  onSliderClick={() => {
                                    updateDetail(
                                      newPersonal.configuration,
                                      'upload',
                                      newPersonal?.configuration?.upload
                                        .readOnly
                                        ? false
                                        : true,
                                    )
                                  }}
                                  onBoxClick={() => {}}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row justify-between w-full items-center">
                              <h1 className="flex w-full justify-start">
                                <>{t('menu.lostAndFound')}</>
                              </h1>
                              <div>
                                <Slider
                                  id={0}
                                  value={
                                    newPersonal.configuration.lostAndFound
                                      .readOnly
                                      ? false
                                      : true
                                  }
                                  onSliderClick={() => {
                                    updateDetail(
                                      newPersonal.configuration,
                                      'lostAndFound',
                                      newPersonal?.configuration?.lostAndFound
                                        .readOnly
                                        ? false
                                        : true,
                                    )
                                  }}
                                  onBoxClick={() => {}}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row justify-between w-full items-center">
                              <h1 className="flex w-full justify-start">
                                <>{t('admin.childChecking')}</>
                              </h1>
                              <div>
                                <Slider
                                  id={0}
                                  value={
                                    newPersonal.configuration.checking.readOnly
                                      ? false
                                      : true
                                  }
                                  onSliderClick={() => {
                                    updateDetail(
                                      newPersonal.configuration,
                                      'checking',
                                      newPersonal?.configuration?.checking
                                        .readOnly
                                        ? false
                                        : true,
                                    )
                                  }}
                                  onBoxClick={() => {}}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row justify-between w-full items-center">
                              <h1 className="flex w-full justify-start">
                                <>{t('menu.documentation')}</>
                              </h1>
                              <div>
                                <Slider
                                  id={0}
                                  value={
                                    newPersonal.configuration.ska.readOnly
                                      ? false
                                      : true
                                  }
                                  onSliderClick={() => {
                                    updateDetail(
                                      newPersonal.configuration,
                                      'ska',
                                      newPersonal?.configuration?.ska.readOnly
                                        ? false
                                        : true,
                                    )
                                  }}
                                  onBoxClick={() => {}}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row justify-between w-full items-center">
                              <h1 className="flex w-full justify-start">
                                <>{t('absence.absence')}</>
                              </h1>
                              <div>
                                <Slider
                                  id={0}
                                  value={
                                    newPersonal.configuration.absence.readOnly
                                      ? false
                                      : true
                                  }
                                  onSliderClick={() => {
                                    updateDetail(
                                      newPersonal.configuration,
                                      'absence',
                                      newPersonal?.configuration?.absence
                                        .readOnly
                                        ? false
                                        : true,
                                    )
                                  }}
                                  onBoxClick={() => {}}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row justify-between w-full items-center">
                              <h1 className="flex w-full justify-start">
                                <>{t('general.activities')}</>
                              </h1>
                              <div>
                                <Slider
                                  id={0}
                                  value={
                                    newPersonal.configuration.activities
                                      .readOnly
                                      ? false
                                      : true
                                  }
                                  onSliderClick={() => {
                                    updateDetail(
                                      newPersonal.configuration,
                                      'activities',
                                      newPersonal?.configuration?.activities
                                        .readOnly
                                        ? false
                                        : true,
                                    )
                                  }}
                                  onBoxClick={() => {}}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row justify-between w-full items-center">
                              <h1 className="flex w-full justify-start">
                                <>{t('menu.box')}</>
                              </h1>
                              <div>
                                <Slider
                                  id={0}
                                  value={
                                    newPersonal.configuration.box.readOnly
                                      ? false
                                      : true
                                  }
                                  onSliderClick={() => {
                                    updateDetail(
                                      newPersonal.configuration,
                                      'box',
                                      newPersonal?.configuration?.box.readOnly
                                        ? false
                                        : true,
                                    )
                                  }}
                                  onBoxClick={() => {}}
                                />
                              </div>
                            </div>

                            <div className="flex flex-row justify-between w-full items-center">
                              <h1 className="flex w-full justify-start">
                                <>{t('menu.documents')}</>
                              </h1>
                              <div>
                                <Slider
                                  id={0}
                                  value={
                                    newPersonal.configuration.documents.readOnly
                                      ? false
                                      : true
                                  }
                                  onSliderClick={() => {
                                    updateDetail(
                                      newPersonal.configuration,
                                      'documents',
                                      newPersonal?.configuration?.documents
                                        .readOnly
                                        ? false
                                        : true,
                                    )
                                  }}
                                  onBoxClick={() => {}}
                                />
                              </div>
                            </div>

                            <div className="flex flex-row justify-between w-full items-center">
                              <h1 className="flex w-full justify-start">
                                <>{t('menu.notes')}</>
                              </h1>
                              <div>
                                <Slider
                                  id={0}
                                  value={
                                    newPersonal.configuration.notes.readOnly
                                      ? false
                                      : true
                                  }
                                  onSliderClick={() => {
                                    updateDetail(
                                      newPersonal.configuration,
                                      'notes',
                                      newPersonal?.configuration?.notes.readOnly
                                        ? false
                                        : true,
                                    )
                                  }}
                                  onBoxClick={() => {}}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row justify-between w-full items-center">
                              <h1 className="flex w-full justify-start">
                                <>{t('admin.editChildInfo')}</>
                              </h1>
                              <div>
                                <Slider
                                  id={0}
                                  value={
                                    newPersonal.configuration.childInfo.readOnly
                                      ? false
                                      : true
                                  }
                                  onSliderClick={() => {
                                    updateDetail(
                                      newPersonal.configuration,
                                      'childInfo',
                                      newPersonal?.configuration?.childInfo
                                        .readOnly
                                        ? false
                                        : true,
                                    )
                                  }}
                                  onBoxClick={() => {}}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="w-full flex justify-center">
                    {newPersonal.name && newPersonal.username ? (
                      <Button
                        label={t('admin.inviteStaff')}
                        variant="fullBlue"
                        onClick={() => {
                          if (newPersonal.username && newPersonal.name) {
                            Swal.fire({
                              text: t('admin.addPersonalWarning'),
                              icon: 'info',
                              showCancelButton: true,
                              focusConfirm: false,
                              confirmButtonColor: '#7eb5f4',
                              cancelButtonColor: '#d33',
                              cancelButtonText: t('general.cancel'),
                              confirmButtonText: t('general.yes'),
                            }).then((result) => {
                              if (result.isConfirmed) {
                                if (schoolId) {
                                  inviteTeacher(newPersonal, schoolId)
                                    .then((response) => {
                                      if (response === 'OK') {
                                        Swal.fire({
                                          text: t('admin.inviteSent'),
                                          icon: 'success',
                                          showCancelButton: false,
                                          showConfirmButton: true,
                                          focusConfirm: false,
                                          confirmButtonColor: '#7eb5f4',
                                          cancelButtonColor: '#d33',
                                          cancelButtonText: t('general.cancel'),
                                          confirmButtonText: t('general.ok'),
                                        }).finally(() => {
                                          setNewPersonal(initialValues)
                                        })
                                      }
                                      if (response === 'ALREADY_INVITED') {
                                        // HANTERA FELMEDDELANDE
                                        Swal.fire({
                                          text: t('admin.userAlreadyInvited'),
                                          icon: 'error',
                                          showCancelButton: false,
                                          showConfirmButton: true,
                                          focusConfirm: false,
                                          confirmButtonColor: '#7eb5f4',
                                          cancelButtonColor: '#d33',
                                          cancelButtonText: t('general.cancel'),
                                          confirmButtonText: t('general.ok'),
                                        })
                                      }
                                      if (response === 'ERROR') {
                                        // HANTERA FELMEDDELANDE
                                        Swal.fire({
                                          title: t('events.eventAlarmFail'),
                                          text: t('statistics.contactSupport'),
                                          icon: 'error',
                                          showCancelButton: false,
                                          showConfirmButton: true,
                                          focusConfirm: false,
                                          confirmButtonColor: '#7eb5f4',
                                          cancelButtonColor: '#d33',
                                          cancelButtonText: t('general.cancel'),
                                          confirmButtonText: t('general.ok'),
                                        })
                                      }
                                    })
                                    .catch((e) => {
                                      console.error(e)
                                    })
                                    .finally(() => {})
                                }
                              }
                            })
                          }
                        }}
                      />
                    ) : (
                      <Button
                        variant={'disableGray'}
                        label={t('admin.inviteStaff')}
                        onClick={() => {
                          // eslint-disable-next-line no-lone-blocks
                          {
                            newPersonal.type === 'mobile' &&
                              Swal.fire({
                                text: t('admin.teleNameCheck'),
                                icon: 'warning',
                                showCancelButton: false,
                                showConfirmButton: true,
                                focusConfirm: false,
                                confirmButtonColor: '#7eb5f4',
                                cancelButtonColor: '#d33',
                                cancelButtonText: t('general.cancel'),
                                confirmButtonText: t('general.ok'),
                              })
                          }
                          {
                            newPersonal.type === 'email' &&
                              Swal.fire({
                                text: t('admin.emailNameCheck'),
                                icon: 'warning',
                                showCancelButton: false,
                                showConfirmButton: true,
                                focusConfirm: false,
                                confirmButtonColor: '#7eb5f4',
                                cancelButtonColor: '#d33',
                                cancelButtonText: t('general.cancel'),
                                confirmButtonText: t('general.ok'),
                              })
                          }
                          {
                            newPersonal.type === 'ssn' &&
                              Swal.fire({
                                text: t('admin.psnNameCheck'),
                                icon: 'warning',
                                showCancelButton: false,
                                showConfirmButton: true,
                                focusConfirm: false,
                                confirmButtonColor: '#7eb5f4',
                                cancelButtonColor: '#d33',
                                cancelButtonText: t('general.cancel'),
                                confirmButtonText: t('general.ok'),
                              })
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ColorCard>
      </Page>
    </>
  )
}
