import classNames from 'classnames'

interface StatusBoxProps {
  isActive: boolean
  label: string
  className?: string
}

export const StatusBox: React.FC<StatusBoxProps> = ({
  isActive,
  label,
  className,
}) => (
  <div
    className={classNames(
      'border rounded-full text-center h-10 items-center flex sm:text-sm text-xs justify-center',
      className,
      { 'text-white': isActive },
      { 'bg-transparent text-gray-300': !isActive },
    )}
  >
    <div>{label}</div>
  </div>
)
