import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, useEffect, useState } from 'react'
import { faAngleDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Listbox, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { isTemplateSpan } from 'typescript'

interface Props {
  items: any[]
  onChange: (e: any) => void
  itemVariableKey: string
  placeholder: string
  initialSelectedItems?: any[]
  className?: string
  alwaysShow?: boolean
}

const Multiselect = (props: Props) => {
  const [selectedItems, setSelectedItems] = useState(
    props.initialSelectedItems ?? [],
  ) as any

  const { t } = useTranslation()

  useEffect(() => {
    setSelectedItems(props.initialSelectedItems ?? [])
  }, [props.initialSelectedItems])

  const isSelected = (id: string) => {
    return selectedItems.find((s: any) => s?.id === id)
  }

  const selectAll = () => {
    if (props.placeholder === t('general.tags')) {
      setSelectedItems([...props.items, 'no_tag'])
      props.onChange([...props.items, 'no_tag'])
    } else {
      setSelectedItems([...props.items, 'no_tag'])
      props.onChange([...props.items, 'no_tag'])
    }
  }

  const unSelectAll = () => {
    setSelectedItems([])
    props.onChange([])
  }

  /*if (props.alwaysShow){
    return (
      
    )
  }*/
  const translateTags = (tag: string) => {
    switch (tag) {
      case 'Språk':
        return t('tags.language')
      case 'Matematik':
        return t('tags.math')
      case 'Motorik':
        return t('tags.motor_skills')
      case 'Skapande':
        return t('tags.creativity')
      case 'EQ':
        return t('tags.eq')
      default:
        return tag
    }
  }
  let itemLength = 0
  if (props.placeholder === t('general.tags')) {
    itemLength = props.items?.length + 1
  } else {
    itemLength = props.items?.length
  }
  return (
    <Listbox
      value={selectedItems}
      onChange={(items) => {
        const uniqueValues: string | any[] = []
        let duplicate: string | null = null
        items.map((item: any) => {
          if (uniqueValues.includes(item.id)) {
            duplicate = item.id
          } else {
            uniqueValues.push(item.id)
          }
        })

        setSelectedItems(items.filter((item: any) => item.id !== duplicate))
        props.onChange(items.filter((item: any) => item.id !== duplicate))
      }}
      multiple
    >
      <div className=" relative bg-white shadow-md rounded-2xl border overflow-x-hidden border-gray-200 min-w-[150px] max-w-[470px] w-full h-auto min-h-[100px] p-3">
        <div className="flex flex-wrap justify-center text-xl font-bold rounded-2xl py-4  ">
          {props.placeholder}
        </div>
        <div className=" relative overflow-y-scroll bg-white w-full h-auto min-h-[100px] max-h-[40vh]">
          {selectedItems.length !== itemLength ? (
            <div className="w-full font-bol cursor-pointer">
              <div
                className="relative cursor-default pl-4 hover:bg-blue-50 select-none py-2 font-bold"
                onClick={() => selectAll()}
              >
                {t('multiselect.selectAll')}
              </div>
            </div>
          ) : (
            <div
              className="relative cursor-default flex justify-between pl-4 hover:bg-blue-50 select-none py-2 font-bold"
              onClick={() => unSelectAll()}
            >
              {t('multiselect.deSelectAll')}
              {/*<FontAwesomeIcon
                className=" mr-4 text-green-500"
                icon={faCheck}
          />*/}
            </div>
          )}
          {props.items?.map((item) => {
            if (selectedItems.find((i: any) => i?.id == item?.id)) {
              return (
                <Listbox.Option
                  key={item.id}
                  value={translateTags(item)}
                  className="relative bg-green-50 flex justify-between hover:bg-blue-50 cursor-default select-none py-2 pl-5"
                >
                  {translateTags(item[props.itemVariableKey])}{' '}
                  <FontAwesomeIcon
                    className="mr-4 text-green-500"
                    icon={faCheck}
                  ></FontAwesomeIcon>
                </Listbox.Option>
              )
            } else {
              return (
                <Listbox.Option
                  key={item.id}
                  value={translateTags(item)}
                  className="relative hover:bg-blue-50 flex cursor-default select-none py-2 pl-5"
                >
                  {translateTags(item[props.itemVariableKey])}
                </Listbox.Option>
              )
            }
          })}
          {props.placeholder === t('general.tags') && (
            <>
              {selectedItems.find((item: any) => item === 'no_tag') ? (
                <Listbox.Option
                  key={'no_tags'}
                  value={'no_tag'}
                  className="relative bg-green-50 flex justify-between hover:bg-blue-50 cursor-default select-none py-2 pl-5"
                >
                  <p>{t('blog.postsWithoutTag')}</p>
                  <FontAwesomeIcon
                    className="mr-4 text-green-500"
                    icon={faCheck}
                  ></FontAwesomeIcon>
                </Listbox.Option>
              ) : (
                <Listbox.Option
                  key={'no_tags'}
                  value={'no_tag'}
                  className="relative hover:bg-blue-50 flex cursor-default select-none py-2 pl-5"
                >
                  <p>{t('blog.postsWithoutTag')}</p>
                </Listbox.Option>
              )}
            </>
          )}
          {/*           <Listbox.Options className="absolute top-[100%] justify-between z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"></Listbox.Options>
           */}{' '}
        </div>
      </div>
    </Listbox>
  )
}

export default Multiselect
