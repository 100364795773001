import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { PortfolioBlogStoreContext } from '../../../store/portfolioBlogStore'
import { UserStoreContext } from '../../../store/userStore'
import Multiselect from '../../../components/Multiselect'
import { DocTag } from '../../../Models/DocTag'
import MultiSelectOpen from '../../../components/MultiSelectOpen'
import { t } from 'i18next'

export const Tags: React.FC<{
  onSelectedTags: any
  initialSelectedTags?: DocTag[]
}> = observer(({ onSelectedTags, initialSelectedTags }) => {
  const { fetchTags, tags } = useContext(PortfolioBlogStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)

  useEffect(() => {
    if (currentUserRole) {
      fetchTags(currentUserRole?.attributes?.school_pointer?.id)
    }
  }, [currentUserRole?.id])

  return (
    <MultiSelectOpen
      onChange={(items) => {
        onSelectedTags(items)
      }}
      items={
        (tags as DocTag[])?.map((t) => {
          return { ...t, id: t.id, name: t?.attributes?.name }
        }) ?? []
      }
      initialSelectedItems={initialSelectedTags}
      itemVariableKey={'name'}
      placeholder={t('general.tags')}
    />
  )
})
