import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { School } from '../Models/School'
import { Event } from '../Models/Event'
import { Department } from '../Models/Department'
import { async } from 'q'
import { ActionStates } from '../Models/ActionStates'

interface Category {
  id: string
  label: string
}

class ContactListStore {
  contactList?: []

  constructor() {
    makeAutoObservable(this)
  }

  setContactList = (contactList: []) => {
    this.contactList = contactList
  }

  fetchContactList = async (
    schoolId: string,
    departmentPointers: Department[] = [],
    headers: any[] = [],
  ) => {
    const departmentIds: string[] = []

    departmentPointers.map((dep: Department) => {
      departmentIds.push(dep.id)
    })

    const contactList = await Parse.Cloud.run('getContactList', {
      schoolId,
      departmentIds,
      headers,
    })

    this.setContactList(contactList)
  }
}

export const contactListStore = (() => {
  return new ContactListStore()
})()
export const ContactListStoreContext: React.Context<ContactListStore> =
  React.createContext(contactListStore)
