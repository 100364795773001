import React, { useContext, useEffect, useState } from 'react'
import Page from '../../../../components/Page'
import ColorCard from '../../../../components/ColorCard'

import { t } from 'i18next'

import { NavigationStoreContext } from '../../../../store/navigationStore'
import { AdminStoreContext } from '../../../../store/adminStore'
import { USER_ROLE_TYPE } from '../../../../Enums/TyraEnums'
import { UserRole } from '../../../../Models/UserRole'
import { useParams } from 'react-router-dom'
import { TeacherCard } from '../components/TeacherCard'
import { VHCard } from '../components/VHCard'
import VHModal from '../components/VHModal'
import TeacherModal from '../components/TeacherModal'
import { Loading } from '../../../../components/Loading'

export const InvitedUsers: React.FC<{ onClick?: any }> = ({ onClick }) => {
  const [loading, setLoading] = useState(false)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [isBlueBackground, setIsBlueBackground] = useState(false)
  const [showInviteUserModal, setShowInviteUserModal] =
    useState<UserRole | null>(null)
  const [showInviteVHModal, setShowInviteVHModal] = useState<UserRole | null>(
    null,
  )
  const { fetchNewUserRoles, newUserRoles } = useContext(AdminStoreContext)
  const handleClick = () => {
    setIsBlueBackground(!isBlueBackground)
  }
  const { schoolId } = useParams()
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('admin.invitedUsers'))
    if (schoolId) {
      setLoading(true)
      fetchNewUserRoles(schoolId).finally(() => {
        setLoading(false)
      })
    }
  }, [schoolId])

  const staffRoles = newUserRoles?.filter(
    (userRole: UserRole) => userRole.role_type > USER_ROLE_TYPE.PARENT,
  )

  const parentRoles = newUserRoles?.filter(
    (userRole: UserRole) => userRole.role_type <= USER_ROLE_TYPE.PARENT,
  )
  let renderedVhs: string[] = []
  return (
    <Page naked>
      {loading && <Loading />}
      <ColorCard bgColor="bg-pink-200 p-4" title={t('documents.staff')}>
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 ml-5">
            {newUserRoles &&
            newUserRoles.filter((uR) => uR.role_type > USER_ROLE_TYPE.PARENT)
              .length ? (
              newUserRoles
                .filter((uR) => uR.role_type > USER_ROLE_TYPE.PARENT)
                .map((userRole) => (
                  <>
                    <TeacherCard
                      onClick={() => {
                        setShowInviteUserModal(userRole)
                      }}
                      title=""
                      userRole={userRole}
                      type="invited"
                    />

                    {/*<InviteUsersModal
                      isOpen={showInviteUserModal ? true : false}
                      onClose={() => {
                        setShowInviteUserModal(null)
                      }}
                    />*/}
                  </>
                ))
            ) : (
              <div className="col-span-full place-items-center">
                <div className="p-10 text-center">
                  <>{t('documents.empty')}</>
                </div>
              </div>
            )}
            {showInviteUserModal && (
              <TeacherModal
                isOpen={showInviteUserModal ? true : false}
                onClose={() => {
                  setShowInviteUserModal(null)
                }}
                type="invited"
                userRole={showInviteUserModal}
              />
            )}
          </div>
        </div>
      </ColorCard>
      <ColorCard bgColor="bg-pink-200 p-4" title={t('documents.parent')}>
        <div className="grid grid-cols-1 sm:grid-cols-2 ml-5">
          {newUserRoles &&
          newUserRoles.filter((uR) => uR.role_type <= USER_ROLE_TYPE.PARENT)
            .length ? (
            newUserRoles
              .filter((uR) => uR.role_type <= USER_ROLE_TYPE.PARENT)
              .map((userRole) => {
                if (renderedVhs.includes(userRole.user?.id ?? 'xyz')) {
                  return null
                }
                if (userRole.user) {
                  renderedVhs.push(userRole.user.id)
                }
                return (
                  <>
                    <VHCard
                      onClick={() => {
                        setShowInviteVHModal(userRole)
                      }}
                      title=""
                      userRole={userRole}
                      type="invited"
                      allUserRolesForUser={newUserRoles.filter(
                        (uR) => uR.user?.id === userRole.user?.id,
                      )}
                    />
                  </>
                )
              })
          ) : (
            <div className="col-span-full place-items-center">
              <div className="p-10 text-center">
                <>{t('documents.empty')}</>
              </div>
            </div>
          )}
          {showInviteVHModal && newUserRoles && (
            <>
              <VHModal
                onClose={() => {
                  setShowInviteVHModal(null)
                }}
                userRole={showInviteVHModal}
                isOpen={showInviteVHModal ? true : false}
                allUserRolesForUser={newUserRoles.filter(
                  (uR) => uR.user?.id === showInviteVHModal.user?.id,
                )}
                type="invited"
              />
              {/*<InviteVHModal
              onClose={() => {
                setShowInviteVHModal(null)
              }}
              userRole={showInviteVHModal}
              isOpen={showInviteVHModal ? true : false}
              allUserRolesForUser={newUserRoles.filter(
                (uR) => uR.user?.id === showInviteVHModal.user?.id,
              )}
              />*/}
            </>
          )}
        </div>
      </ColorCard>
    </Page>
  )
}
