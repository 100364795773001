import React, { useState } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faCircle,
  faStar as fasStar,
} from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import { faMailBulk, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import Swal from 'sweetalert2'
import { t } from 'i18next'

type NoticeItemProps = {
  content?: string
  editedAt?: string
  readAt?: string
  updatedAt?: string
  relation?: string
  createdAt?: string
  createdBy?: string
  header?: string
  index: number
  toggleMarkNotice?: (index: number) => void
  toggleSeen?: (index: number) => void
}

export const NoticeItem: React.FC<NoticeItemProps> = ({
  content,
  editedAt,
  readAt,
  updatedAt,
  relation,
  createdAt,
  createdBy,
  header,
  index,
  toggleMarkNotice,
  toggleSeen,
}) => {
  const getIconForNotice = (type: string) => {
    switch (type) {
      case 'success':
        return faCheckCircle
      case 'warning':
        return faExclamationTriangle
      case 'info':
      default:
        return faInfoCircle
    }
  }

  const getBackgroundColorForNotice = (type: string) => {
    switch (type) {
      case 'success':
        return 'bg-green-100' // green background for success
      case 'warning':
        return 'bg-yellow-100' // yellow background for warning
      case 'info':
      default:
        return 'bg-blue-100' // blue background for info
    }
  }

  const handleSeenButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (toggleSeen) toggleSeen(index)
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (toggleMarkNotice) toggleMarkNotice(index)
  }

  const [isFavorite, setIsFavorite] = useState(false)
  return (
    <div
      key={index}
      className="relative group flex flex-row justify-between items-center border-b py-4 px-2 w-full hover:bg-gray-50 transition duration-300 cursor-pointer"
    >
      <div className="flex items-center justify gap-x-4">
        {/*} <label className="custom-checkbox">
          <input
            type="checkbox"
            //checked={notice.marked}
            className="hidden-checkbox"
            onChange={handleCheckboxChange}
          />
          <span className="checkbox-style"></span>
        </label>*/}
        <div className="p-4 rounded-2xl border flex justify-center w-fit items-center ">
          {' '}
          <FontAwesomeIcon icon={fasStar} />
        </div>
        <div className="flex flex-col">
          <div>{header}</div>
          <div className=" text-sm font-normal text-gray-400">{content}</div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-end gap-x-2 sm:min-w-[300px]">
        <div className="flex flex-row items-center  justify-start gap-x-2 sm:min-w-[200px]">
          <div className="p-6 border rounded-full relative">
            <div className="text-xs absolute bottom-4 right-3">bild</div>
          </div>
          <div className="flex flex-col">
            <div className="text-gray-400 font-medium text-sm">{createdBy}</div>
            <div className="text-gray-400 font-normal text-xs">{relation}</div>
          </div>
        </div>
      </div>
      <div className="text-gray-500 mr-10 flex flex-row justify-center items-center object-center">
        <div className="flex gap-x-2 mr-2"></div>
        <div className="flex flex-col gap-2">
          <div className="flex items-end font-semibold text-xs">
            skapat: {moment(createdAt).format('YYYY-MM-DD')}
          </div>
          <div className="flex items-end font-semibold text-xs">
            updaterat: {moment(updatedAt).format('YYYY-MM-DD')}
          </div>
          <div className="flex items-end font-semibold text-xs">
            läst: {moment(readAt).format('YYYY-MM-DD')}
          </div>
          <div className="flex items-end font-semibold text-xs">
            editerat: {editedAt}
          </div>
        </div>
      </div>

      <FontAwesomeIcon
        icon={faCircle}
        className="text-blue-500 absolute right-4 top-1/2 transform -translate-y-1/2 h-[10px] w-[10px]"
      />
    </div>
  )
}

export default NoticeItem
