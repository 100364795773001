import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import {
  CreateEventParams,
  EVENT3_POST_TO,
  EVENT3_TYPE,
  EVENT3_USER_FOR,
  Event3StoreContext,
  IsoDate,
  IsoDateTime,
} from '../../store/event3Store'
import { t } from 'i18next'
import { useParams } from 'react-router-dom'
import { UserStoreContext } from '../../store/userStore'
import { CreateLocation, LOCATION_TYPE } from './components/CreateLocation'
import { TypeOfEventSelections } from './components/TypeOfEvent'
import { PostEventToSelections } from './components/PostEventTo'
import { DepartmentStoreContext } from '../../store/departmentStore'
import { DateToEvent, DateToEventSelections } from './components/DateToEvent'
import moment from 'moment'
import { TagsToEvent, TagsToEventSelections } from './components/TagsToEvent'
import { TagStoreContext } from '../../store/tagStore'
import { PublishEventModal } from './components/PublishEventModal'
import { ChooseSchoolsSelections } from './components/ChooseSchools'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChildReaching, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../components/Button'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import { StepButton } from './components/StepButton'
import ModalRegular from '../../components/ModalRegular'
import { LocationToEvent } from './components/LocationToEvent'
import {
  faCalendarDay,
  faCalendarHeart,
  faCalendarLines,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { CreateEvent3Start } from './CreateEvent3Start'
import { AddFileModalNew } from './components/AddFileModalNew'
import { MultipleTimes } from '../../components/Calendar'
import {
  SeparateParentsToBooking,
  SeparateParentsToBookingSelections,
} from './components/SeparateParentsToBooking'
import { v4 as uuidv4 } from 'uuid'
import { AddParticipatingChildren } from './components/AddParticipatingChildren'
import { Kid } from '../../Models/Kid'
import Swal from 'sweetalert2'

export interface IbgColors {
  bgColors: {
    bgInfoColor: boolean
    bgImportantInfoColor: boolean
    bgActivityColor: boolean
    bgMeetingColor: boolean
    bgBookingColor: boolean
  }
}

export const useEventTypeSelections = () => {
  const { t } = useTranslation()

  return [
    {
      value: EVENT3_TYPE.INFO,
      label: t('events.information'),
      icon: faCalendarLines,
      color: '#7eb5f4',
    },
    {
      value: EVENT3_TYPE.IMPORTANT,
      label: t('events.extraImportantInfo'),
      icon: faCalendarLines,
      color: '#f26d6d',
    },
    {
      value: EVENT3_TYPE.BOOKING,
      label: t('events.booking'),
      icon: faCalendarDay,
      color: '#C99CF2',
    },
    {
      value: EVENT3_TYPE.MEETING,
      label: t('events.groupMeeting'),
      icon: faCalendarDay,
      color: '#ffa948',
    },
    {
      value: EVENT3_TYPE.ACTIVITIES,
      label: t('events.activity'),
      icon: faCalendarHeart,
      color: '#6BBD73',
    },
  ]
}

export const usePostToSelections = () => {
  const { t } = useTranslation()
  return [
    /*{
      value: EVENT3_POST_TO.ALL,
      label: t('events.postToEventText1'),
      text: t('events.postToEventText1'),
    },*/
    {
      value: EVENT3_POST_TO.ONLY_STAFF,
      label: t('documents.staff'),
      text: t('events.postToEventText1'),
    },
  ]
}

export const useDaysSelections = () => {
  const { t } = useTranslation()

  return [
    { id: 'mon', label: t('daysFull.monday') },
    { id: 'tue', label: t('daysFull.tuesday') },
    { id: 'wed', label: t('daysFull.wednesday') },
    { id: 'thu', label: t('daysFull.thursday') },
    { id: 'fri', label: t('daysFull.friday') },
  ]
}

export const useRepeatSelections = () => {
  const { t } = useTranslation()

  return [
    { id: 1, label: t('repeat.every1week') },
    { id: 2, label: t('repeat.every2week') },
    { id: 3, label: t('repeat.every3week') },
    { id: 4, label: t('repeat.every4week') },
    { id: 5, label: t('repeat.every5week') },
  ]
}

export const useLengthsToMeeting = () => {
  const lengths = []
  let theNumber = 5
  for (var i = 5; i < 65; i++) {
    lengths.push({ value: '' + theNumber, label: '' + theNumber })
    theNumber = theNumber + 5
  }

  return lengths
}

export const useCountToLengthsToMeeting = () => {
  const amount = []
  for (var i = 1; i < 30; i++) {
    amount.push({ value: '' + i, label: '' + i })
  }

  return amount
}

export const createManyEventTimesForBooking = (
  multipleTimes: MultipleTimes,
  times: {
    date: IsoDate
    start?: IsoDateTime
    end?: IsoDateTime
    fakeId: string
  }[],
) => {
  const newTimes: {
    date: IsoDate
    start?: IsoDateTime
    end?: IsoDateTime
    fakeId: string
  }[] = []

  const start = multipleTimes.start
  const pause = multipleTimes.pause
  const length = multipleTimes.length
  const dates: IsoDate[] = []
  for (const time of times) {
    dates.push(time.date)
    let current: {
      date: IsoDate
      start?: IsoDateTime
      end?: IsoDateTime
      fakeId: string
    } = {
      date: time.date,
      start: start,
      end: moment(time.date + ' ' + start)
        .add(Number(length), 'minutes')
        .format('HH:mm'),
      fakeId: uuidv4(),
    }
    for (var i = 0; i < Number(multipleTimes.amount); i++) {
      if (i === 0) {
        newTimes.push(current)
      } else {
        const newTime: {
          date: IsoDate
          start?: IsoDateTime
          end?: IsoDateTime
          fakeId: string
        } = {
          date: time.date,
          start: moment(time.date + ' ' + current.end)
            .add(Number(pause), 'minutes')
            .format('HH:mm'),
          end: moment(time.date + ' ' + current.end)
            .add(Number(pause), 'minutes')
            .add(Number(length), 'minutes')
            .format('HH:mm'),
          fakeId: uuidv4(),
        }
        current = newTime
        newTimes.push(newTime)
      }
    }
  }

  return { newTimes, dates }
}

export enum CREATE_EVENT_STEP {
  MULTISCHOOL_OPTION = 'multischool_option',
  CHOOSE_SCHOOLS = 'choose_schools',
  TYPE = 'type',
  POST_TO = 'post_to',
  DATE = 'date',
  TAGS = 'tags',
  CONTENT = 'content',
  LOCATION = 'location',
  SEPARATE_PARENTS = 'separate_parents',
}

export const initialValues: CreateEventParams = {
  multiSchools: undefined,
  //schoolId: userStore.currentUserRole?.school_pointer.id,
  //userRoleId: 'xxx',
  eventType: EVENT3_TYPE.INFO,
  eventFor: undefined,
  startDate: moment().format('YYYY-MM-DD'),
  stopDate: '',
  dates: [], //deprecated
  times: undefined,
  topic: '',
  content: '',
  files: [],
  fileIds: undefined,
  tagIds: [],
  locationType: LOCATION_TYPE.SAVED,
  location: undefined,
  depIds: [],
  postTo: EVENT3_POST_TO.ALL,
  onlyStaff: false,
  destKids: undefined,
  destRoles: undefined,
  isPinned: false,
  isDraft: false,
  maxParticipants: undefined,
  publishAs: undefined,
  eventResponsible: undefined,
  isRecurrence: false,
  recurrence: {
    startsOn: moment().format('YYYY-MM-DD'),
    endsOn: moment().add(1, 'months').format('YYYY-MM-DD'),
    interval: 1,
    days: [],
    startTime: undefined,
    endTime: undefined,
  },
  allowSeparateBookings: undefined,
  addParticipatingKids: undefined,
}

export const getDateForTranslatation = (months: string) => {
  switch (months) {
    case '01':
      return t('monthsShort.january')
    case '02':
      return t('monthsShort.february')
    case '03':
      return t('monthsShort.march')
    case '04':
      return t('monthsShort.april')
    case '05':
      return t('monthsShort.may')
    case '06':
      return t('monthsShort.june')
    case '07':
      return t('monthsShort.july')
    case '08':
      return t('monthsShort.august')
    case '09':
      return t('monthsShort.september')
    case '10':
      return t('monthsShort.october')
    case '11':
      return t('monthsShort.november')
    case '12':
      return t('monthsShort.december')
  }
}

export const getFullMonthForTranslatation = (months: string) => {
  switch (months) {
    case '01':
      return t('months.january')
    case '02':
      return t('months.february')
    case '03':
      return t('months.march')
    case '04':
      return t('months.april')
    case '05':
      return t('months.may')
    case '06':
      return t('months.june')
    case '07':
      return t('months.july')
    case '08':
      return t('months.august')
    case '09':
      return t('months.september')
    case '10':
      return t('months.october')
    case '11':
      return t('months.november')
    case '12':
      return t('months.december')
  }
}

export const CreateEvent3: React.FC = observer(() => {
  const {
    currentUserRole,
    getSchoolsForUserRoleAdmins,
    schoolsForUserRoleAdmins,
  } = useContext(UserStoreContext)
  const { fetchDepartmentsForMultiSchools, departmentsForMultiSchool } =
    useContext(DepartmentStoreContext)
  const { tagsForEvent, fetchTagsForEvent } = useContext(TagStoreContext)
  const { schoolId } = useParams()
  const [newEvent, setNewEvent] = useState<CreateEventParams>({
    ...initialValues,
  })
  const [currentStep, setCurrentStep] = useState<CREATE_EVENT_STEP>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allowMultiSchools, setAllowMultiSchools] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [choosenKids, setChoosenKids] = useState<Kid[]>([])
  const { t } = useTranslation()
  const [openAddParticipatingChildren, setOpenAddParticipatingChildren] =
    useState(false)

  const toggleAddParticipatingChildrenPopUp = () => {
    setOpenAddParticipatingChildren(!openAddParticipatingChildren)
  }
  useEffect(() => {
    if (currentUserRole) {
      setCurrentStep(CREATE_EVENT_STEP.LOCATION)
    }
  }, [currentUserRole])

  useEffect(() => {
    if (currentUserRole && schoolsForUserRoleAdmins) {
      const isAdmin =
        currentUserRole?.attributes?.role_type >= USER_ROLE_TYPE.ADMIN
      if (isAdmin && schoolsForUserRoleAdmins?.length > 1) {
        setAllowMultiSchools(true)
      }
    }
  }, [schoolsForUserRoleAdmins, currentUserRole])

  useEffect(() => {
    if (schoolId) {
      getSchoolsForUserRoleAdmins()
    }
  }, [schoolId])
  useEffect(() => {
    fetchTagsForEvent(
      newEvent.multiSchools
        ? newEvent.multiSchools
        : [currentUserRole?.school_pointer.id ?? ''],
      newEvent.eventType,
      newEvent.onlyStaff ?? false,
    )
    setNewEvent({
      ...newEvent,
      tagIds: [],
      times: [],
      recurrence: initialValues.recurrence,
      isRecurrence: false,
      eventFor:
        newEvent.eventType === EVENT3_TYPE.BOOKING
          ? EVENT3_USER_FOR.KID
          : newEvent.eventFor,
      location:
        newEvent.eventType === EVENT3_TYPE.INFO ||
        newEvent.eventType === EVENT3_TYPE.IMPORTANT
          ? undefined
          : newEvent.location,
      isPinned: newEvent.eventType === EVENT3_TYPE.IMPORTANT ? true : false,
      allowSeparateBookings: undefined,
      eventResponsible: undefined,
      publishAs: undefined,
    })
  }, [newEvent.eventType, newEvent.multiSchools])

  useEffect(() => {
    if (newEvent.eventFor && newEvent.eventType === EVENT3_TYPE.ACTIVITIES) {
      setNewEvent({
        ...newEvent,
        eventFor:
          newEvent.postTo === EVENT3_POST_TO.ONLY_STAFF
            ? EVENT3_USER_FOR.KID
            : newEvent.eventFor,
      })
    }
  }, [newEvent.postTo])

  useEffect(() => {
    fetchDepartmentsForMultiSchools(
      newEvent.multiSchools
        ? newEvent.multiSchools
        : [currentUserRole?.school_pointer.id ?? ''],
    )
    fetchTagsForEvent(
      newEvent.multiSchools
        ? newEvent.multiSchools
        : [currentUserRole?.school_pointer.id ?? ''],
      newEvent.eventType,
      newEvent.onlyStaff ?? false,
    )
  }, [schoolId, newEvent.multiSchools, currentUserRole])

  const { fetchLocations, locations } = useContext(Event3StoreContext)
  useEffect(() => {
    if (schoolId) {
      fetchLocations([schoolId])
    }
  }, [schoolId])

  console.log(newEvent)

  const [eventStep, setEventStep] = useState<number>(1)
  const [showModal, setShowModal] = useState(false)

  const eventTypeSelections = useEventTypeSelections()

  const bgColor = () => {
    if (eventTypeSelections) {
      const color = eventTypeSelections.find(
        (eT) => eT.value === newEvent.eventType,
      )?.color

      return color
    }
  }

  const eventTypeName = () => {
    if (eventTypeSelections) {
      const title = eventTypeSelections.find(
        (eT) => eT.value === newEvent.eventType,
      )?.label

      return title
    } else {
      return ''
    }
  }

  // Determine the background color for different types of events
  const bgInfoColor = bgColor() === '#7eb5f4'
  const bgImportantInfoColor = bgColor() === '#f26d6d'
  const bgActivityColor = bgColor() === '#6BBD73'
  const bgMeetingColor = bgColor() === '#ffa948'
  const bgBookingColor = bgColor() === '#C99CF2'

  const bgColors: IbgColors = {
    bgColors: {
      bgInfoColor,
      bgImportantInfoColor,
      bgActivityColor,
      bgMeetingColor,
      bgBookingColor,
    },
  }

  const handleDateChange = () => {
    setCurrentStep(CREATE_EVENT_STEP.DATE)
    setOpenModal(true)
  }
  const handleTagsChange = () => {
    setCurrentStep(CREATE_EVENT_STEP.TAGS)
    setOpenModal(true)
  }
  const handlePlatsChange = () => {
    setCurrentStep(CREATE_EVENT_STEP.LOCATION)
    if (!newEvent.location) {
      setOpenModal(true)
    }
  }

  const handleSeparateParentsChange = () => {
    setCurrentStep(CREATE_EVENT_STEP.SEPARATE_PARENTS)
    setOpenModal(true)
  }

  const [wave, setWave] = useState(false)
  const triggerWave = () => {
    setWave(true)
    setTimeout(() => {
      setWave(false)
    }, 1000)
  }

  console.log('wave', wave)

  return (
    <>
      {eventStep === 1 && (
        <CreateEvent3Start
          newEvent={newEvent}
          onChange={setNewEvent}
          departments={departmentsForMultiSchool ?? []}
          schools={schoolsForUserRoleAdmins}
          setEventStep={setEventStep}
        />
      )}
      {eventStep === 2 && (
        <Page>
          <div className="flex flex-col justify-between h-full">
            <div
              className={classNames(
                `items-center w-full p-4 flex text-white h-[10%] ${
                  bgActivityColor
                    ? 'bg-[#6BBD73]'
                    : bgMeetingColor
                    ? 'bg-[#FFC27E]'
                    : bgInfoColor
                    ? 'bg-[#7eb5f4]'
                    : bgImportantInfoColor
                    ? 'bg-[#f26d6d]'
                    : bgBookingColor
                    ? 'bg-[#C99CF2]'
                    : 'bg-eventsMain'
                }`,
              )}
            >
              <div className="w-1/5">
                <Button
                  variant="custom"
                  textColor={`${
                    bgActivityColor
                      ? '6BBD73'
                      : bgMeetingColor
                      ? 'ffa948'
                      : bgInfoColor
                      ? '7eb5f4'
                      : bgImportantInfoColor
                      ? 'f26d6d'
                      : bgBookingColor
                      ? 'C99CF2'
                      : 'eventsMain'
                  }`}
                  size="sm"
                  label={t('login.back')}
                  onClick={() => {
                    setEventStep(1)
                  }}
                />
              </div>
              <div className="text-center text-2xl font-bold w-3/5 ">
                {eventTypeName()}
              </div>
              <div className="w-1/5"></div>
            </div>
            <div className="flex h-[75%] p-4 ">
              <div className="basis-8/12 pr-2 mr-2 ">
                <>
                  <div className="p-6 w-full bg-white rounded-lg shadow-md flex flex-col border-2">
                    <div className="flex flex-col">
                      <div className="flex items-center space-x-2">
                        <div className="flex-1">
                          <input
                            type="text"
                            id="header"
                            className={`appearance-none border-b ${
                              !newEvent.topic || newEvent.topic.trim() === ''
                                ? 'border-black-700 placeholder-black'
                                : 'border-gray-300'
                            } w-full py-2 px-3 text-black-100 leading-tight focus:outline-none focus:shadow-outline font-extrabold`}
                            placeholder={t('notes.header')}
                            value={newEvent.topic || ''}
                            onChange={(e) => {
                              const value = e.target.value
                              // Kapitalisera det första tecknet i strängen och lägg till resten av strängen som den är.
                              const capitalizedValue =
                                value.charAt(0).toUpperCase() + value.slice(1)
                              setNewEvent({
                                ...newEvent,
                                topic: capitalizedValue,
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col ">
                      <textarea
                        id="description"
                        className="appearance-none border-none rounded w-full h-56 py-2 px-3 placeholder-gray-500 leading-tight focus:outline-none focus:shadow-outline text-md"
                        placeholder={t('events.contentPlaceholder')}
                        onChange={(e) =>
                          setNewEvent({
                            ...newEvent,
                            content: e.target.value,
                          })
                        }
                        value={newEvent.content}
                      ></textarea>
                    </div>
                  </div>
                </>

                <ModalRegular
                  isOpen={openModal}
                  hideCloseButton={true}
                  onClose={() => {
                    setOpenModal(false)
                  }}
                  className={classNames(
                    ' transition',
                    currentStep === CREATE_EVENT_STEP.DATE ||
                      currentStep === CREATE_EVENT_STEP.SEPARATE_PARENTS
                      ? 'max-w-4xl p-4 sm:h-4/4 sm:w-3/4'
                      : 'max-w-lg p-12',
                  )}
                >
                  {currentStep === CREATE_EVENT_STEP.POST_TO && (
                    <PostEventToSelections
                      onChange={setNewEvent}
                      newEvent={newEvent}
                      departments={departmentsForMultiSchool ?? []}
                      schools={schoolsForUserRoleAdmins}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.TYPE && (
                    <TypeOfEventSelections
                      onChange={setNewEvent}
                      newEvent={newEvent}
                      closeModal={() => {
                        setOpenModal(false)
                      }}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.DATE && (
                    <DateToEventSelections
                      onChange={setNewEvent}
                      newEvent={newEvent}
                      recurringDaysWave={wave}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.TAGS && (
                    <TagsToEventSelections
                      newEvent={newEvent}
                      tags={tagsForEvent ?? []}
                      schools={schoolsForUserRoleAdmins}
                      onChange={setNewEvent}
                      onDone={() => {}}
                      onSave={() => {}}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.CHOOSE_SCHOOLS && (
                    <ChooseSchoolsSelections
                      newEvent={newEvent}
                      schools={schoolsForUserRoleAdmins ?? []}
                      onChange={setNewEvent}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.LOCATION && (
                    <CreateLocation
                      value={newEvent.locationType}
                      newEvent={newEvent}
                      onChange={setNewEvent}
                      onDone={() => {}}
                      onSave={() => {}}
                      locations={locations ?? []}
                      onClose={() => {
                        setOpenModal(false)
                        //setNewEvent
                      }}
                    />
                  )}
                  {currentStep === CREATE_EVENT_STEP.SEPARATE_PARENTS && (
                    <SeparateParentsToBookingSelections
                      newEvent={newEvent}
                      departments={
                        departmentsForMultiSchool?.filter((dep) =>
                          newEvent.depIds?.includes(dep.id),
                        ) ?? []
                      }
                      onChange={setNewEvent}
                    />
                  )}
                  {currentStep !== CREATE_EVENT_STEP.LOCATION && (
                    <div className="flex  justify-center items-center w-full pt-2 bg-gradient-to-t from-white">
                      <Button
                        size="lg"
                        fontSize="font-bold"
                        className="min-w-[250px] my-1.5"
                        variant={'fullBlue'}
                        label={t('events.add')}
                        onClick={() => {
                          if (
                            newEvent.isRecurrence &&
                            currentStep === CREATE_EVENT_STEP.DATE
                          ) {
                            if (newEvent.recurrence) {
                              if (newEvent.recurrence.days.length) {
                                setOpenModal(false)
                              } else {
                                triggerWave()
                              }
                            }
                          } else {
                            setOpenModal(false)
                          }
                        }}
                      />
                    </div>
                  )}
                </ModalRegular>
              </div>
              <div className="basis-4/12 flex flex-col gap-y-2 h-[90%] overflow-auto scrollbar-hide relative">
                <StepButton>
                  <div className="flex flex-row w-full items-center relative">
                    <DateToEvent
                      newEvent={newEvent}
                      onChange={setNewEvent}
                      onDone={() => {}}
                      onSave={() => {}}
                      onClick={handleDateChange}
                    />
                  </div>
                </StepButton>

                <StepButton

                //active={currentStep === CREATE_EVENT_STEP.TAGS}
                >
                  <div className="flex flex-row justify-between w-full items-center ">
                    <TagsToEvent
                      newEvent={newEvent}
                      tags={tagsForEvent ?? []}
                      schools={schoolsForUserRoleAdmins}
                      onChange={setNewEvent}
                      onDone={() => {}}
                      onSave={() => {}}
                      onClick={handleTagsChange}
                    />
                  </div>
                </StepButton>
                {newEvent.eventType === EVENT3_TYPE.ACTIVITIES ||
                newEvent.eventType === EVENT3_TYPE.BOOKING ||
                newEvent.eventType === EVENT3_TYPE.MEETING ? (
                  <StepButton>
                    <div className="flex flex-row justify-between w-full items-center relative">
                      <LocationToEvent
                        newEvent={newEvent}
                        onChange={setNewEvent}
                        onDone={() => {}}
                        onSave={() => {}}
                        onClick={handlePlatsChange}
                      />
                    </div>
                  </StepButton>
                ) : null}
                {newEvent.eventType === EVENT3_TYPE.BOOKING && (
                  <StepButton>
                    <div className="flex flex-row justify-between w-full items-center relative">
                      <SeparateParentsToBooking
                        newEvent={newEvent}
                        onChange={setNewEvent}
                        onDone={() => {}}
                        onSave={() => {}}
                        onClick={handleSeparateParentsChange}
                      />
                    </div>
                  </StepButton>
                )}
                <StepButton>
                  <AddFileModalNew onChange={setNewEvent} newEvent={newEvent} />
                </StepButton>
                {newEvent.eventType === EVENT3_TYPE.ACTIVITIES &&
                  newEvent.onlyStaff === false && (
                    <StepButton>
                      <div className="flex flex-col  justify-between items-center w-full">
                        <div className="font-bold flex flex-col items-center justify-between w-full">
                          <div className="font-bold flex flex-wrap items-center justify-between gap-x-1 w-full overflow-x-auto overflow-y-hidden">
                            {''}
                            {!newEvent.addParticipatingKids?.length ? (
                              <div className="flex items-center">
                                <FontAwesomeIcon
                                  icon={faChildReaching}
                                  className="text-[#C99CF2] text-xl p-1"
                                />
                                <p>{t('events.addParticipatingChildren')} : </p>
                              </div>
                            ) : (
                              <div className="flex items-center">
                                <FontAwesomeIcon
                                  icon={faChildReaching}
                                  className="text-[#C99CF2] text-xl p-1"
                                />
                                <p>
                                  Selected children{'('}
                                  {newEvent.addParticipatingKids?.length}
                                  {')'}
                                </p>
                              </div>
                            )}
                            <Button
                              label={t('events.add')}
                              variant="darkBorderAndText"
                              size="xs"
                              onClick={() =>
                                toggleAddParticipatingChildrenPopUp()
                              }
                            />
                          </div>
                          <div className="flex flex-row items-center w-full  gap-1 mt-2 overflow-auto scrollbar-hide p-2">
                            {choosenKids.map((kid) => (
                              <div className="flex flex-col items-center justify-around space-x-1">
                                <img
                                  className={`w-12 h-12 rounded-full object-cover bg-slate-400`}
                                  alt=""
                                  key={kid?.id}
                                  src={
                                    kid?.kidImage_thumb?._url ??
                                    '/images/placeholder-image.png'
                                  }
                                >
                                  {}
                                </img>
                                <p className="text-xs break-words truncate w-14">
                                  {kid.kidName}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {openAddParticipatingChildren && (
                        <AddParticipatingChildren
                          newEvent={newEvent}
                          onClose={() => setOpenAddParticipatingChildren(false)}
                          openAddParticipatingChildren={
                            openAddParticipatingChildren
                          }
                          depIds={newEvent.depIds}
                          onChange={setNewEvent}
                          choosenKids={choosenKids}
                          onChangeChoosenKids={setChoosenKids}
                          toggleAddParticipatingChildrenPopUp={
                            toggleAddParticipatingChildrenPopUp
                          }
                        />
                      )}
                    </StepButton>
                  )}
              </div>
            </div>
            <div className="flex w-full justify-center h-[15%] items-center">
              <Button
                className="w-[40%]"
                size="lg"
                variant="fullBlue"
                fontSize="font-bold"
                label={t('login.nextTitle')}
                onClick={() => {
                  if (
                    !newEvent.eventType ||
                    !newEvent.depIds ||
                    newEvent.depIds?.length < 1 ||
                    !newEvent.topic
                  ) {
                    Swal.fire({
                      icon: 'warning',
                      text: t('events.addHeaderText'),
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                    })
                  } else {
                    if (newEvent.isRecurrence) {
                      if (
                        newEvent.recurrence?.startsOn &&
                        newEvent.recurrence.endsOn &&
                        newEvent.recurrence.days &&
                        newEvent.recurrence.interval
                      ) {
                        setShowModal(true)
                      } else {
                        Swal.fire({
                          icon: 'warning',
                          text: t('events.addHeaderText'),
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      }
                    } else {
                      if (newEvent.eventType === EVENT3_TYPE.BOOKING) {
                        if (
                          newEvent.times?.length &&
                          newEvent.times.find((time) => time.start && time.end)
                        ) {
                          setShowModal(true)
                        } else {
                          Swal.fire({
                            icon: 'warning',
                            text: t('events.noSetTime'),
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                          })
                        }
                      } else {
                        setShowModal(true)
                      }
                    }
                  }
                }}
              />
            </div>
            <PublishEventModal
              bgColors={bgColors}
              newEvent={newEvent}
              departments={departmentsForMultiSchool ?? []}
              showModal={showModal}
              setShowModal={setShowModal}
              onChange={setNewEvent}
              onDone={() => {}}
              onSave={() => {
                setNewEvent({
                  ...initialValues,
                  fileIds: [],
                  files: [],
                  depIds: [],
                })
                setChoosenKids([])

                setEventStep(1)
              }}
            />
          </div>
        </Page>
      )}
    </>
  )
})
