import classNames from 'classnames'
import moment from 'moment'
import { title } from 'process'
import React, { useState } from 'react'

interface Props extends React.PropsWithChildren {
  className?: string
  value?: string
  onChange?: string
  onClick?: () => void
  absence?: boolean
  opacity?: boolean
}

export const AbsenceCalendarCell: React.FC<Props> = ({
  className,
  children,
  value,
  onChange,
  onClick,
  absence,
  opacity,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'h-10 sm:h-16 flex items-center justify-center border ',
        absence ? className + ' bg-absenceMain border-none' : className,
        opacity ? 'opacity-60 border-r border-b ' : '',
      )}
    >
      {children}
      {value}
      {onChange}
    </div>
  )
}
