import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { DocumentStoreContext } from '../../../store/documentStore'
import Page from '../../../components/Page'
import { User } from '../../../Models/User'
import classNames from 'classnames'
import { NavigationStoreContext } from '../../../store/navigationStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPenNib, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import { UserStoreContext } from '../../../store/userStore'
import { useTranslation } from 'react-i18next'
import { toJS } from 'mobx'
import moment from 'moment'
import { Loading } from '../../../components/Loading'

export const Overview: React.FC<{ documentId: string; type: string }> =
  observer(({ documentId, type }) => {
    const { t } = useTranslation()
    const [tab, setTab] = useState('unread')
    const { setTitle } = useContext(NavigationStoreContext)
    const { savedUserRoleId } = useContext(UserStoreContext)
    const [sort, setSort] = useState('')

    const {
      getDoc,
      activeDocument,
      getDocumentOverview,
      setActiveDocument,
      setDocumentOverview,
      setSelectedUserUserRoles,
      documentOverview,
      getUserRolesFromUser,
      selectedUserUserRoles,
      overviewLoading,
    } = useContext(DocumentStoreContext)

    useEffect(() => {
      if (documentId) {
        //getDoc(documentId)
        getDocumentOverview({ documentId, type })
      }
      return () => {
        setActiveDocument(null)
        setDocumentOverview(null)
      }
    }, [documentId])

    useEffect(() => {
      setTitle(activeDocument?.attributes?.name)
    }, [activeDocument])

    const getActionStateText = (user?: User) => {
      if (user) {
        if (sort === 'unread') {
          return 'Oläst'
        }

        const actionState = documentOverview?.actionStates?.find(
          (as) => as?.attributes?.user_owner?.id === user?.id,
        )
        if (actionState) {
          if (sort === 'read') {
            return moment(actionState?.attributes?.viewed).format('D MMM YYYY')
          } else if (sort === 'signed') {
            return moment(actionState?.attributes?.signed).format('D MMM YYYY')
          }
        }
      }

      return ''
    }
    if (overviewLoading) {
      return (
        <Page>
          <Loading />
        </Page>
      )
    }
    return (
      <Page>
        <div className={'flex'}>
          <div
            className={classNames(
              'flex-1 flex justify-center items-center h-[40px] text-sm border cursor-pointer hover:text-eventsMain',
              tab === 'unread' && 'border-b-2 border-b-eventsMain',
            )}
            onClick={() => {
              setTab('unread')
            }}
          >
            <p
              className={classNames(
                tab === 'unread' && 'text-eventsMain font-medium',
              )}
            >
              {t('documents.overviewUnread')} (
              {documentOverview?.unRead?.length ?? 0})
            </p>
          </div>
          <div
            className={classNames(
              'flex-1 flex justify-center items-center h-[40px] text-sm border cursor-pointer hover:text-eventsMain',
              tab === 'read' && 'border-b-2 border-b-eventsMain',
            )}
            onClick={() => {
              setTab('read')
            }}
          >
            <p
              className={classNames(
                tab === 'read' && 'text-eventsMain font-medium',
              )}
            >
              {t('documents.read')} ({documentOverview?.read?.length ?? 0})
            </p>
          </div>
          <div
            className={classNames(
              'flex-1 flex justify-center items-center h-[40px] text-sm border cursor-pointer hover:text-eventsMain',
              tab === 'signed' && 'border-b-2 border-b-eventsMain',
            )}
            onClick={() => {
              setTab('signed')
            }}
          >
            <p
              className={classNames(
                tab === 'signed' && 'text-eventsMain font-medium',
              )}
            >
              {t('documents.signed')} ({documentOverview?.signed?.length ?? 0})
            </p>
          </div>
        </div>
        <div
          className={
            'flex gap-y-3 gap-x-3 items-center flex-col sm:flex-row sm:flex-wrap p-3'
          }
        >
          {tab === 'unread' ? (
            documentOverview?.unRead?.length ? (
              documentOverview?.unRead?.map((user, index) => {
                return (
                  <Item
                    onClick={() => {
                      setSelectedUserUserRoles([])
                      getUserRolesFromUser({
                        userRoleId: savedUserRoleId ?? '',
                        userId: user?.id,
                      })
                      setSort('unread')
                    }}
                    key={index}
                    user={user}
                  />
                )
              })
            ) : (
              <Empty />
            )
          ) : null}
          {tab === 'read' ? (
            documentOverview?.read.length ? (
              documentOverview?.read?.map((user, index) => {
                return (
                  <Item
                    onClick={() => {
                      setSelectedUserUserRoles([])
                      getUserRolesFromUser({
                        userRoleId: savedUserRoleId ?? '',
                        userId: user?.id,
                      })
                      setSort('read')
                    }}
                    key={index}
                    user={user}
                  />
                )
              })
            ) : (
              <Empty />
            )
          ) : null}
          {tab === 'signed' ? (
            documentOverview?.signed.length ? (
              documentOverview?.signed?.map((user, index) => {
                return (
                  <Item
                    onClick={() => {
                      setSelectedUserUserRoles([])
                      getUserRolesFromUser({
                        userRoleId: savedUserRoleId ?? '',
                        userId: user.id,
                      })
                      setSort('signed')
                    }}
                    key={index}
                    user={user}
                  />
                )
              })
            ) : (
              <Empty />
            )
          ) : null}
        </div>

        <ModalRegular
          title={''}
          isOpen={sort?.length > 0}
          className={'max-w-2xl'}
          onClose={() => {
            setSort('')
          }}
          bgColor="p-4"
        >
          <div className={'flex flex-col space-y-3 pt-10 p-4'}>
            <div className={'pb-3 border-b flex justify-between'}>
              <p>
                {
                  selectedUserUserRoles?.[0]?.attributes?.user?.attributes
                    ?.firstName
                }{' '}
                {
                  selectedUserUserRoles?.[0]?.attributes?.user?.attributes
                    ?.lastName
                }
              </p>
              <p
                className={classNames(
                  'text-sm ',
                  sort === 'unread' ? 'text-red-500' : 'text-eventsMain',
                )}
              >
                {getActionStateText(
                  selectedUserUserRoles?.[0]?.attributes?.user,
                )}

                <span>
                  {sort === 'read' && (
                    <FontAwesomeIcon
                      className="ml-2 text-eventsMain"
                      icon={faEye}
                    />
                  )}
                  {sort === 'signed' && (
                    <FontAwesomeIcon
                      className="ml-2 text-eventsMain"
                      icon={faPenNib}
                    />
                  )}
                </span>
              </p>
            </div>
            {selectedUserUserRoles?.map((role) => {
              if (role?.attributes?.role_type > 350) {
                return (
                  <div className={'flex space-x-3 items-center '}>
                    <img
                      className={'w-[45px] h-[45px] rounded-full'}
                      src={
                        role?.attributes?.staff_primary_dep?.attributes
                          ?.contactPic_thumb?._url
                      }
                      alt=""
                    />
                    <div className={'text-sm'}>
                      <p className={'text-eventsMain'}>
                        {role?.attributes?.relation}
                      </p>
                      <p>{role?.attributes?.kid?.attributes?.kidName}</p>
                      <p className={'text-xs text-gray-400'}>
                        {
                          role?.attributes?.staff_primary_dep?.attributes
                            ?.klassName
                        }
                      </p>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className={'flex space-x-3 items-center '}>
                    <img
                      className={'w-[45px] h-[45px] rounded-full'}
                      src={role?.attributes?.kid?.attributes?.kidImage?._url}
                      alt=""
                    />
                    <div className={'text-sm'}>
                      <p className={'text-eventsMain'}>
                        {role?.attributes?.relation}
                      </p>
                      <p>{role?.attributes?.kid?.attributes?.kidName}</p>
                      <p className={'text-xs text-gray-400'}>
                        {role?.attributes?.kid?.attributes?.department}
                      </p>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </ModalRegular>
      </Page>
    )
  })

const Empty = () => {
  const { t } = useTranslation()

  return (
    <div className={'flex w-full justify-center pt-10'}>
      <div className={'flex flex-col p-10 space-y-3'}>
        <FontAwesomeIcon className="text-xl text-gray-400" icon={faUserGroup} />
        <p>{t('documents.empty')}</p>
      </div>
    </div>
  )
}

const Item = ({ user, onClick }: { user: User; onClick: any }) => {
  return (
    <div
      className={
        'flex gap-x-3 items-center px-3 sm:min-w-[190px] hover:bg-gray-50 hover:shadow-lg cursor-pointer sm:max-w-[190px] sm:min-h-[60px] sm:max-h-[60px] w-full py-2 shadow-md border-[1px] rounded-2xl'
      }
      onClick={onClick}
    >
      <img
        className={
          'min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] rounded-full'
        }
        src={user?.attributes?.myPic_thumb?._url}
        alt=""
      />
      <div className="text-xs flex justify-end">
        {user?.attributes?.firstName} {user?.attributes?.lastName}
      </div>
    </div>
  )
}
