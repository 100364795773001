import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { NavigationStoreContext } from '../../store/navigationStore'
import { DocumentationStoreContext } from '../../store/documentationStore'
import Page from '../../components/Page'
import { UserStoreContext } from '../../store/userStore'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DocumentationItem } from './components/DocumentationItem'
import { getDocsData } from '../documents/getDocsData'
import classNames from 'classnames'
import { SkaOperationsPlanning } from '../../Models/SkaOperationsPlanning'
import { SkaProjectPlanning } from '../../Models/SkaProjectPlanning'
import Parse from 'parse'
import { SKA_VISIBILITY, USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import { faPlus, faSliders, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { KidsModal } from './components/KidsModal'
import { Kid } from '../../Models/Kid'
import { getImageOrPlaceHolder } from '../../utils/parseUtils'

export const Documentation: React.FC = observer(() => {
  const [loading, setLoading] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [showKidsModal, setShowKidsModal] = useState(false)
  const [selectedKids, setSelectedKids] = useState<Kid[]>([])
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const folder = searchParams.get('folder')
  const type = searchParams.get('type')
  const { t } = useTranslation()
  const { setTitle } = useContext(NavigationStoreContext)
  const {
    fetchDocumentations,
    documentations,
    addDocumentation,
    setCards,
    setDocumentation,
  } = useContext(DocumentationStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  useEffect(() => {
    if (type) {
      if (folder) {
        if (folder === 'my') {
          setTitle(t('documentation.myDocument'))
        } else if (folder === 'school') {
          setTitle(t('documentation.schoolDocument'))
        } else if (folder === 'onlystaff') {
          setTitle(t('documentation.onlyStaffDocument'))
        } else if (folder === 'shared') {
          setTitle(t('documentation.sharedDocument'))
        }
      } else {
        setTitle(t('documentation.' + type))
      }
    }
  }, [t, type, folder])

  const fetch = () => {
    setLoading(true)
    fetchDocumentations({
      type: type ?? '',
      schoolId: currentUserRole?.attributes?.school_pointer?.id,
    }).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    if (currentUserRole && type) {
      fetch()
    }
  }, [currentUserRole?.id, type])

  const myData = getDocsData('my')
  const schoolData = getDocsData('school')
  const onlyStaffData = getDocsData('only_staff')
  const sharedData = getDocsData('shared')
  return (
    <Page
      topBar={
        <>
          {currentUserRole && !currentUserRole.kid ? (
            <div className="flex items-center">
              <Button
                label={t('general.filter')}
                icon={faSliders}
                className="text-eventsMain cursor-pointer hover:scale-[102%%] relative"
                onClick={() => {
                  setShowKidsModal(true)
                }}
              />
            </div>
          ) : null}
          <div className="flex-1 max-w-[500px]">
            <input
              className={classNames(
                'w-full px-7 py-5 flex flex-wrap placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
              )}
              type={'text'}
              value={search}
              placeholder={t('documentation.searchDocumentation')}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </div>
          <div>
            <Button
              size="lg"
              variant="primary"
              loading={addLoading}
              label={t('documentation.addDocumentation')}
              icon={faPlus}
              onClick={async () => {
                setAddLoading(true)
                const doc = await addDocumentation({
                  type: type ?? '',
                  userRoleId: currentUserRole?.id ?? '',
                })
                setAddLoading(false)
                if (doc) {
                  setCards([])
                  setDocumentation(undefined)
                  navigate('/documentation/' + doc?.id + '?type=' + type)
                }
              }}
            />
          </div>
        </>
      }
    >
      <div>
        {!folder && (
          <div
            className={'flex flex-wrap justify-center gap-x-16 gap-y-4 mt-4'}
          >
            <div>
              <Button
                className="hover:shadow-tyraShadow"
                variant={type === 'operation' ? 'borderMain' : 'default'}
                label={t('documentation.operation')}
                onClick={() => {
                  if (type !== 'operation') {
                    navigate('?type=operation')
                    setLoading(true)
                  }
                }}
              />
            </div>
            {currentUserRole?.attributes?.role_type > USER_ROLE_TYPE.PARENT && (
              <div>
                <Button
                  className="hover:shadow-tyraShadow"
                  variant={type === 'project' ? 'borderMain' : 'default'}
                  label={t('documentation.project')}
                  onClick={() => {
                    if (type !== 'project') {
                      navigate('?type=project')
                      setLoading(true)
                    }
                  }}
                />
              </div>
            )}
            <div>
              <Button
                className="hover:shadow-tyraShadow"
                variant={type === 'kidDocumentation' ? 'borderMain' : 'default'}
                label={t('documentation.kidDocumentation')}
                onClick={() => {
                  if (type !== 'kidDocumentation') {
                    navigate('?type=kidDocumentation')
                    setLoading(true)
                  }
                }}
              />
            </div>
          </div>
        )}

        <div className={'flex flex-col mt-10 mb-10 items-center'}>
          <div
            className={
              'flex flex-wrap space-x-2 px-2 pt-2 w-full md:max-w-md mt-3 md:mt-0 md:mr-[300px]'
            }
          >
            {selectedKids.map((kid: Kid) => {
              return (
                <div className={'flex space-x-2 items-center'}>
                  <img
                    className={'w-[15px] h-[15px] rounded-full'}
                    src={getImageOrPlaceHolder(kid?.attributes?.kidImage?._url)}
                    alt=""
                  />
                  <p className={'text-xs'}>{kid?.attributes?.kidName}</p>
                </div>
              )
            })}

            {selectedKids?.length > 0 && (
              <FontAwesomeIcon
                className="text-gray-500 cursor-pointer"
                icon={faXmark}
                onClick={() => {
                  setSelectedKids([])
                }}
              />
            )}
          </div>
        </div>

        {!folder && myData?.icon && (
          <div
            className={
              'grid gap-3 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 max-w-7xl px-3 '
            }
          >
            <DocumentationItem
              title={t('documentation.myDocument')}
              icon={myData?.icon}
              className={myData?.color ?? ''}
              onClick={() => {
                navigate('?folder=my' + '&type=' + type)
              }}
              hideSettings
            />
            <DocumentationItem
              title={t('documentation.schoolDocument')}
              icon={schoolData?.icon}
              className={schoolData?.color ?? ''}
              onClick={() => {
                navigate('?folder=school' + '&type=' + type)
              }}
              hideSettings
            />
            {currentUserRole?.attributes?.role_type > USER_ROLE_TYPE.PARENT && (
              <DocumentationItem
                title={t('documentation.onlyStaffDocument')}
                icon={onlyStaffData?.icon}
                className={onlyStaffData?.color ?? ''}
                onClick={() => {
                  navigate('?folder=onlystaff' + '&type=' + type)
                }}
                hideSettings
              />
            )}
            <DocumentationItem
              title={t('documentation.sharedDocument')}
              icon={sharedData?.icon}
              className={sharedData?.color ?? ''}
              onClick={() => {
                navigate('?folder=shared' + '&type=' + type)
              }}
              hideSettings
            />
          </div>
        )}

        {!loading && (
          <div
            className={
              'md:pt-10 grid gap-3 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 max-w-7xl px-3 pb-10'
            }
          >
            {type &&
              documentationFilter({
                documentations: documentations,
                folder: folder ?? '',
                search: search ?? '',
                selectedKids: selectedKids,
              })?.map((doc) => {
                return (
                  <DocumentationItem
                    title={doc?.attributes?.name}
                    id={doc?.id}
                    isFile={true}
                    type={type}
                    className={getItemColor(type, folder ?? '') ?? ''}
                    onClick={() => {
                      setCards([])
                      setDocumentation(undefined)
                      navigate('/documentation/' + doc?.id + '?type=' + type)
                    }}
                    onRemove={() => {
                      fetch()
                    }}
                  />
                )
              })}
          </div>
        )}

        <KidsModal
          initialKids={[]}
          showModal={showKidsModal}
          onClose={() => {
            setShowKidsModal(false)
          }}
          onSave={async (kids: Kid[]) => {
            setSelectedKids(kids)
            setShowKidsModal(false)
          }}
        />
      </div>
    </Page>
  )
})

const getItemColor = (tab: string, folder: string) => {
  if (folder) {
    if (folder === 'my') {
      return 'text-documentsMain'
    } else if (folder === 'shared') {
      return 'text-blogMain'
    } else if (folder === 'onlystaff') {
      return 'text-notesMain'
    } else if (folder === 'school') {
      return 'text-classlistMain'
    }
  } else {
    if (tab === 'operation') {
      return 'text-groupsMain'
    } else if (tab === 'project') {
      return 'text-kidcheckingInMain'
    } else if (tab === 'kidDocumentation') {
      return 'text-blue-400'
    }
  }
}

const documentationFilter = ({
  documentations,
  folder,
  search,
  selectedKids,
}: {
  documentations?: SkaOperationsPlanning[] | SkaProjectPlanning[]
  folder?: string
  search?: string
  selectedKids?: Kid[]
}) => {
  const currentUser = Parse.User.current()

  if (search && search.length && documentations) {
    documentations = documentations.filter((d: any) => {
      return d?.attributes.name?.toLowerCase().includes(search.toLowerCase())
    })
  }

  if (selectedKids && selectedKids.length && documentations) {
    documentations = documentations.filter((d: any) => {
      return d?.attributes?.tagged_kids?.find((kid: Kid) =>
        selectedKids.find((k: Kid) => k.id === kid.id),
      )
    })
  }

  if (folder === 'my') {
    return documentations?.filter(
      (d) => d?.attributes?.author?.id === currentUser?.id,
    )
  } else if (folder === 'shared') {
    return documentations?.filter(
      (d) =>
        (d?.attributes?.author?.id === currentUser?.id &&
          d?.attributes?.visibility === SKA_VISIBILITY.SHARED_DRAFT) ||
        d?.attributes?.draft_shared_users?.find(
          (id: string) => id === currentUser?.id,
        ),
    )
  } else if (folder === 'onlystaff') {
    return documentations?.filter(
      (d) => d?.attributes?.visibility === SKA_VISIBILITY.PUBLISHED_TEACHERS,
    )
  } else if (folder === 'school') {
    return documentations?.filter(
      (d) =>
        d?.attributes?.visibility ===
        SKA_VISIBILITY.PUBLISHED_TEACHERS_AND_PARENTS,
    )
  }

  return documentations ?? []
}
