import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { NavigationStoreContext } from '../../store/navigationStore'
import Page from '../../components/Page'
import classNames from 'classnames'

export const Present: React.FC = observer(() => {
  const [tab, setTab] = useState('present')
  const { t } = useTranslation()
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle('Idag')
  }, [t])

  return (
    <Page>
      <div className={'flex w-full justify-center'}>
        <div className={'flex  w-full max-w-lg bg-gray-200 mt-3 rounded p-1'}>
          <div
            onClick={() => {
              setTab('present')
            }}
            className={classNames(
              'flex-1 text-center py-3 cursor-pointer',
              tab === 'present' && 'bg-eventsMain rounded  text-white',
            )}
          >
            Närvarande
          </div>
          <div
            onClick={() => {
              setTab('absent')
            }}
            className={classNames(
              'flex-1 text-center py-3 cursor-pointer',
              tab === 'absent' && 'bg-eventsMain rounded  text-white',
            )}
          >
            Frånvarande
          </div>
          <div
            onClick={() => {
              setTab('count')
            }}
            className={classNames(
              'flex-1 text-center py-3 cursor-pointer',
              tab === 'count' && 'bg-eventsMain rounded  text-white',
            )}
          >
            Räkna in
          </div>
        </div>
      </div>
    </Page>
  )
})
