import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { observer } from 'mobx-react-lite'

import React, { useCallback, useContext } from 'react'
import {
  Notification,
  NotificationContext,
  NotificationVariant,
} from './notificationManger'

const defaultTransitionValues = {
  default: { type: 'spring', damping: 16, stiffness: 750 },
  opacity: { duration: 0.2 },
}

const cardVariants: Variants = {
  enter: {
    scale: 0.9,
    opacity: 0,
    transition: defaultTransitionValues,
  },
  visible: {
    opacity: 1,
    bottom: '0px',
    scale: 1,
    transition: defaultTransitionValues,
  },
  exit: {
    opacity: 0,
    scale: 0.9,
    transition: defaultTransitionValues,
  },
}

export const Notifications: React.FC<{}> = observer(() => {
  const { notifications } = useContext(NotificationContext)

  if (!notifications) {
    return null
  }
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-[10000] flex flex-col items-center justify-end w-full max-w-full px-4 pb-5 pr-8 pointer-events-none">
      <AnimatePresence>
        {notifications.map((notification: any) => (
          <motion.div
            key={notification.id}
            layout
            initial="enter"
            animate="visible"
            exit="exit"
            variants={cardVariants}
          >
            <NotificationCard notification={notification} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  )
})

export const NotificationCard: React.FC<{
  notification: Notification
}> = observer(({ notification }) => {
  const { closeNotification } = useContext(NotificationContext)
  const onClose = useCallback(
    () => closeNotification(notification.id),
    [notification],
  )

  const [bgStyles, titleStyles, buttonStyles] = notificationColor(
    notification.variant,
  )

  return (
    <div className="w-full max-w-lg mb-4 overflow-hidden rounded-lg shadow-lg pointer-events-auto">
      <div
        className={classNames(
          `p-4 flex justify-between space-x-10 relative items-center`,
          bgStyles,
        )}
      >
        <div className="space-y-1">
          <h1 className={classNames('font-bold', titleStyles)}>
            {notification.title}
          </h1>
          {notification.message && (
            <p className={classNames('text-sm', titleStyles)}>
              {notification.message.split('\n')[0]}
            </p>
          )}
        </div>
        <button
          className={classNames(
            'flex items-center ml-3 p-0 text-lg hover:text-white transition-all',
            buttonStyles,
          )}
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
        </button>
      </div>
    </div>
  )
})

const notificationColor = (
  variant: NotificationVariant,
): [string, string, string] => {
  switch (variant) {
    case NotificationVariant.ERROR:
      return ['bg-red-100', 'text-red-700', 'text-red-500']
    case NotificationVariant.INFO:
      return ['bg-indigo-100', 'text-indigo-700', 'text-indigo-500']
    case NotificationVariant.OK:
      return ['bg-green-100', 'text-green-700', 'text-green-500']
    case NotificationVariant.WARN:
      return ['bg-orange-100', 'text-orange-700', 'text-orange-500']
    default:
      return ['bg-gray-200', 'text-gray-700', 'text-gray-500']
  }
}
