import React, { useContext, useState } from 'react'
import { DocumentationStoreContext } from '../../../store/documentationStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import { SKA_CARD_TYPE } from '../../../Enums/TyraEnums'

export const AddModal = ({
  schoolId,
  id,
  type,
  onAdd,
  onAddConnection,
}: {
  schoolId: string
  id: string
  type: string
  onAdd: any
  onAddConnection: any
}) => {
  const { addCard } = useContext(DocumentationStoreContext)

  const [showModal, setShowModal] = useState(false)

  const add = async (cardType: number) => {
    await addCard({ cardType, id: id, schoolId: schoolId, type })
  }
  return (
    <>
      <div
        onClick={() => {
          setShowModal(true)
        }}
        className={
          'fixed bottom-5 right-10 bg-eventsMain flex justify-center rounded-full items-center h-[60px] w-[60px] cursor-pointer hover:opacity-60'
        }
      >
        <FontAwesomeIcon className={'text-white'} size={'2x'} icon={faPlus} />
      </div>
      <ModalRegular
        title={''}
        isOpen={showModal}
        className={'max-w-2xl'}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <div className={'mt-10 border-t'}>
          <div
            className={
              'w-full flex justify-center py-6 border-b hover:bg-blue-100 cursor-pointer'
            }
            onClick={async () => {
              await add(SKA_CARD_TYPE.TEXT)
              onAdd()
              setShowModal(false)
            }}
          >
            <p>Text</p>
          </div>
          <div
            className={
              'w-full flex justify-center py-6 border-b hover:bg-blue-100 cursor-pointer'
            }
            onClick={async () => {
              await add(SKA_CARD_TYPE.PDFS)
              onAdd()
              setShowModal(false)
            }}
          >
            <p>PDF</p>
          </div>
          <div
            className={
              'w-full flex justify-center py-6 border-b hover:bg-blue-100 cursor-pointer'
            }
            onClick={async () => {
              await add(SKA_CARD_TYPE.IMAGES)
              onAdd()
              setShowModal(false)
            }}
          >
            <p>Bilder</p>
          </div>
          <div
            className={
              'w-full flex justify-center py-6 border-b hover:bg-blue-100 cursor-pointer'
            }
            onClick={async () => {
              setShowModal(false)
              onAddConnection()
            }}
          >
            <p>Kopplingar</p>
          </div>
        </div>
      </ModalRegular>
    </>
  )
}
