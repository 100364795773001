import React, { useContext, useEffect, useState } from 'react'
import ModalRegular from '../../../../components/ModalRegular'
import { Button } from '../../../../components/Button'
import Swal from 'sweetalert2'
import { Slider } from '../../../../components/Slider'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserRole } from '../../../../Models/UserRole'
import { AdminStoreContext } from '../../../../store/adminStore'
import { useParams } from 'react-router-dom'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { Department } from '../../../../Models/Department'
import { USER_ROLE_TYPE } from '../../../../Enums/TyraEnums'
import {
  getImageFromRole,
  getUserNameFromRole,
} from '../../../../utils/parseUtils'
import { UserStoreContext } from '../../../../store/userStore'
import { useTranslation } from 'react-i18next'

interface ActiveUsersModalProps {
  onClose: () => void
  isOpen: boolean
  userRole: UserRole
  type: 'paused' | 'active' | 'waiting' | 'invited'
}

const TeacherModal: React.FC<ActiveUsersModalProps> = ({
  onClose,
  isOpen,
  userRole,
  type,
}) => {
  const {
    pauseUserRole,
    fetchActiveUserRoles,
    approveUserRole,
    fetchPendingUserRoles,
    setStaffPrimaryDepartment,
    fetchNewUserRoles,
  } = useContext(AdminStoreContext)

  const { departments, fetchDepartments } = useContext(DepartmentStoreContext)
  const { t } = useTranslation()
  const [showDetailSwitches, setShowDetailSwitches] = useState(false)
  const [fakeState, setFakeState] = useState(false)
  const { schoolId } = useParams()
  useEffect(() => {
    if (schoolId) {
      fetchDepartments(schoolId, true)
    }
  }, [schoolId])
  const {
    setUpdateStaffSchedule,
    setUpdateStaffSensetive,
    setUpdateStaffStatistics,
    setUpdateAdminPermission,
    setReadOnlyPermission,
    setUpdateSuperAdmin,
    setUserToSchoolAccount,
    cancelUserRole,
    updateSpecificReadOnlyPermission,
  } = useContext(AdminStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDepartment, setSelectedDepartment] =
    useState<Department | null>(null)

  const valueReadOnly = (configuration: any) => {
    if (!configuration) {
      return true
    }
    let count = 0
    for (let key in configuration) {
      if (
        configuration.hasOwnProperty(key) &&
        configuration[key].readOnly === false
      ) {
        count++
      }
    }
    return count ? true : false
  }
  const countOfSwitches = (configuration: any) => {
    if (!configuration) {
      return 11
    }
    let count = 0
    for (let key in configuration) {
      if (
        configuration.hasOwnProperty(key) &&
        configuration[key].readOnly === false
      ) {
        count++
      }
    }
    return count
  }
  return (
    <ModalRegular
      isOpen={isOpen}
      onClose={onClose}
      bgColor="p-4 bg-pink-200"
      className="bg-gray-50"
      title={t('documents.staff')}
      closeColor="white"
    >
      <div className="p-10 flex-col flex justify-between">
        <div className="flex flex-col gap-y-8">
          <div className="flex">
            <label className="relative flex items-center justify-center border rounded-full max-w-[60px] bg-gray-300  max-h-[60px]  min-w-[60px]  min-h-[60px] overflow-hidden">
              <img
                className={'w-[60px] h-[60px] object-cover rounded-full'}
                src={getImageFromRole(userRole)}
                alt=""
              />
            </label>
            <div className="flex flex-col w-full justify-center pl-2">
              <h1 className="font-bold">
                {type === 'invited'
                  ? userRole.invite_note
                  : userRole.user?.firstName + ' ' + userRole.user?.lastName}
              </h1>
              <h2 className="font-thin text-gray-600">
                {getUserNameFromRole(userRole)}
              </h2>
            </div>
          </div>
          <div>
            <div>
              <>{t('admin.choosePrimaryDep')}</>
            </div>
            <div className="mt-2">
              <div className="h-full w-full flex flex-col">
                <div className="flex w-full justify-center p-4 flex-wrap">
                  {departments?.map((department) => (
                    <div
                      key={department.id}
                      className={`m-2 p-2 border max-w-[200px]  rounded-full flex flex-row relative justify-center min-w-[190px] text-center shadow-md cursor-pointer  no-select ${
                        userRole.staff_primary_dep?.id === department.id &&
                        userRole.staff_primary_dep?.id
                          ? 'bg-blue-300 hover:bg-blue-300 text-white border-none'
                          : 'text-gray-400 bg-white'
                      }`}
                      onClick={() => {
                        if (selectedDepartment?.id === department.id) {
                          setStaffPrimaryDepartment(null, userRole).finally(
                            () => {
                              setFakeState(!fakeState)
                            },
                          )
                        } else {
                          setStaffPrimaryDepartment(
                            department.id,
                            userRole,
                          ).finally(() => {
                            setFakeState(!fakeState)
                          })
                        }
                      }}
                    >
                      {userRole.staff_primary_dep?.id === department.id && (
                        <div className="ml-2 absolute top-[9px] right-4 ">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-lime-200"
                          />
                        </div>
                      )}
                      <div className="flex max-w-[120px] overflow-hidden">
                        {department.attributes.klassName}
                      </div>
                      {selectedDepartment?.id === department.id && (
                        <div className="ml-2 absolute top-[9px] right-4 ">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-green-500"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-row justify-between w-full items-center text-start">
              <h1>
                <>{t('admin.userAdminPermission')}</>
              </h1>
              <div>
                <Slider
                  id={0}
                  onSliderClick={() => {
                    setUpdateAdminPermission(
                      userRole.role_type >= USER_ROLE_TYPE.ADMIN
                        ? USER_ROLE_TYPE.TEACHER
                        : USER_ROLE_TYPE.ADMIN,
                      [userRole],
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }}
                  onBoxClick={() => {}}
                  value={
                    userRole.role_type >= USER_ROLE_TYPE.ADMIN ? true : false
                  }
                />
              </div>
            </div>
            {currentUserRole &&
              currentUserRole.role_type > USER_ROLE_TYPE.ADMIN &&
              userRole.role_type >= USER_ROLE_TYPE.ADMIN && (
                <div className="flex flex-row justify-between w-full items-center text-start">
                  <h1>
                    <>{t('admin.userAccessModuls')}</>
                  </h1>
                  <div>
                    <Slider
                      id={0}
                      onSliderClick={() => {
                        setUpdateSuperAdmin(
                          userRole.role_type === USER_ROLE_TYPE.ADMIN
                            ? USER_ROLE_TYPE.SUPER_ADMIN
                            : USER_ROLE_TYPE.ADMIN,
                          [userRole],
                        ).finally(() => {
                          setFakeState(!fakeState)
                        })
                      }}
                      onBoxClick={() => {}}
                      value={
                        userRole.role_type === USER_ROLE_TYPE.ADMIN
                          ? false
                          : true
                      }
                    />
                  </div>
                </div>
              )}
            {userRole.school_pointer.multi_user && (
              <div className="flex flex-row justify-between w-full items-center text-start">
                <h1>
                  <>{t('admin.userSchoolAcc')}</>
                </h1>
                <div>
                  <Slider
                    id={0}
                    onSliderClick={() => {
                      setUserToSchoolAccount(
                        userRole.multi_user ? false : true,
                        [userRole],
                      ).finally(() => {
                        userRole.fetch()
                        setFakeState(!fakeState)
                      })
                    }}
                    onBoxClick={() => {}}
                    value={userRole.multi_user ? true : false}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-row justify-between w-full items-center text-start">
              <h1>
                <>{t('admin.userEditSchedule')}</>
              </h1>
              <div>
                {userRole.role_type >= USER_ROLE_TYPE.ADMIN ? (
                  <Slider
                    id={0}
                    onSliderClick={() => {}}
                    onBoxClick={() => {}}
                    value={true}
                  />
                ) : (
                  <Slider
                    id={0}
                    onSliderClick={() =>
                      setUpdateStaffSchedule(
                        !userRole.staff_schedule_responsable,
                        [userRole],
                      ).finally(() => {
                        setFakeState(!fakeState)
                      })
                    }
                    onBoxClick={() => {}}
                    value={userRole.staff_schedule_responsable}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-row justify-between w-full items-center text-start">
              <h1>
                <>{t('admin.userSensitiveInfo')}</>
              </h1>
              <div>
                {userRole.role_type >= USER_ROLE_TYPE.ADMIN ? (
                  <Slider
                    id={0}
                    onSliderClick={() => {}}
                    onBoxClick={() => {}}
                    value={true}
                  />
                ) : (
                  <Slider
                    id={0}
                    onSliderClick={() => {
                      setUpdateStaffSensetive(
                        !userRole.staff_sensetive_info_bool,
                        [userRole],
                      )
                        .catch((e) => {
                          Swal.fire({
                            text: t('events.eventAlarmFail'),
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                            cancelButtonText: t('general.cancel'),
                          })
                        })
                        .finally(() => {
                          setFakeState(!fakeState)
                        })
                    }}
                    onBoxClick={() => {}}
                    value={userRole.staff_sensetive_info_bool}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-row justify-between w-full items-center text-start">
              <h1>
                {' '}
                <>{t('admin.userStatistic')}</>
              </h1>
              <div>
                {userRole.role_type >= USER_ROLE_TYPE.ADMIN ? (
                  <Slider
                    id={0}
                    onSliderClick={() => {}}
                    onBoxClick={() => {}}
                    value={true}
                  />
                ) : (
                  <Slider
                    id={0}
                    onSliderClick={() => {
                      setUpdateStaffStatistics(!userRole.stat_access, [
                        userRole,
                      ]).finally(() => {
                        setFakeState(!fakeState)
                      })
                    }}
                    onBoxClick={() => {}}
                    value={userRole.stat_access}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-row justify-between w-full items-center text-start">
              <h1>
                <>{t('admin.userAllowChangeInfo')}</>
              </h1>
              <div>
                {userRole.role_type >= USER_ROLE_TYPE.ADMIN ? (
                  <Slider
                    id={0}
                    onSliderClick={() => {}}
                    onBoxClick={() => {}}
                    value={true}
                  />
                ) : (
                  <Slider
                    id={0}
                    onSliderClick={() => {
                      setReadOnlyPermission(
                        valueReadOnly(userRole.configuration),
                        [userRole],
                      ).finally(() => {
                        setFakeState(!fakeState)
                      })
                    }}
                    onBoxClick={() => {}}
                    value={valueReadOnly(userRole.configuration)} //userRole?.configuration ? false : true}
                  />
                )}
              </div>
            </div>
            {valueReadOnly(userRole.configuration) && (
              <>
                <span
                  className="text-eventsMain font-bold cursor-pointer w-full text-right"
                  onClick={() => {
                    setShowDetailSwitches(!showDetailSwitches)
                  }}
                >
                  {t('admin.showDetailFor')}{' '}
                  {countOfSwitches(userRole.configuration)}/11
                </span>
                {showDetailSwitches && (
                  <>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('menu.events')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={
                            userRole?.configuration?.events.readOnly
                              ? false
                              : true
                          }
                          onSliderClick={() => {
                            updateSpecificReadOnlyPermission(
                              userRole.configuration,
                              'events',
                              userRole?.configuration?.events.readOnly
                                ? false
                                : true,
                              [userRole],
                            ).finally(() => {
                              setFakeState(!fakeState)
                            })
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('menu.upload')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={
                            userRole?.configuration?.upload.readOnly
                              ? false
                              : true
                          }
                          onSliderClick={() => {
                            updateSpecificReadOnlyPermission(
                              userRole.configuration,
                              'upload',
                              userRole?.configuration?.upload.readOnly
                                ? false
                                : true,
                              [userRole],
                            ).finally(() => {
                              setFakeState(!fakeState)
                            })
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>

                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('menu.lostAndFound')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={
                            userRole?.configuration?.lostAndFound.readOnly
                              ? false
                              : true
                          }
                          onSliderClick={() => {
                            updateSpecificReadOnlyPermission(
                              userRole.configuration,
                              'lostAndFound',
                              userRole?.configuration?.lostAndFound.readOnly
                                ? false
                                : true,
                              [userRole],
                            ).finally(() => {
                              setFakeState(!fakeState)
                            })
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('admin.childChecking')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={
                            userRole?.configuration?.checking.readOnly
                              ? false
                              : true
                          }
                          onSliderClick={() => {
                            updateSpecificReadOnlyPermission(
                              userRole.configuration,
                              'checking',
                              userRole?.configuration?.checking.readOnly
                                ? false
                                : true,
                              [userRole],
                            ).finally(() => {
                              setFakeState(!fakeState)
                            })
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('menu.documentation')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={
                            userRole?.configuration?.ska.readOnly ? false : true
                          }
                          onSliderClick={() => {
                            updateSpecificReadOnlyPermission(
                              userRole.configuration,
                              'ska',
                              userRole?.configuration?.ska.readOnly
                                ? false
                                : true,
                              [userRole],
                            ).finally(() => {
                              setFakeState(!fakeState)
                            })
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('absence.absence')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={
                            userRole?.configuration?.absence.readOnly
                              ? false
                              : true
                          }
                          onSliderClick={() => {
                            updateSpecificReadOnlyPermission(
                              userRole.configuration,
                              'absence',
                              userRole?.configuration?.absence.readOnly
                                ? false
                                : true,
                              [userRole],
                            ).finally(() => {
                              setFakeState(!fakeState)
                            })
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('general.activities')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={
                            userRole?.configuration?.activities.readOnly
                              ? false
                              : true
                          }
                          onSliderClick={() => {
                            updateSpecificReadOnlyPermission(
                              userRole.configuration,
                              'activities',
                              userRole?.configuration?.activities.readOnly
                                ? false
                                : true,
                              [userRole],
                            ).finally(() => {
                              setFakeState(!fakeState)
                            })
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('menu.box')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={
                            userRole?.configuration?.box.readOnly ? false : true
                          }
                          onSliderClick={() => {
                            updateSpecificReadOnlyPermission(
                              userRole.configuration,
                              'box',
                              userRole?.configuration?.box.readOnly
                                ? false
                                : true,
                              [userRole],
                            ).finally(() => {
                              setFakeState(!fakeState)
                            })
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('menu.documents')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={
                            userRole?.configuration?.documents.readOnly
                              ? false
                              : true
                          }
                          onSliderClick={() => {
                            updateSpecificReadOnlyPermission(
                              userRole.configuration,
                              'documents',
                              userRole?.configuration?.documents.readOnly
                                ? false
                                : true,
                              [userRole],
                            ).finally(() => {
                              setFakeState(!fakeState)
                            })
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('menu.notes')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={
                            userRole?.configuration?.notes.readOnly
                              ? false
                              : true
                          }
                          onSliderClick={() => {
                            updateSpecificReadOnlyPermission(
                              userRole.configuration,
                              'notes',
                              userRole?.configuration?.notes.readOnly
                                ? false
                                : true,
                              [userRole],
                            ).finally(() => {
                              setFakeState(!fakeState)
                            })
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <h1 className="flex w-full justify-start">
                        <>{t('admin.editChildInfo')}</>
                      </h1>
                      <div>
                        <Slider
                          id={0}
                          value={
                            userRole?.configuration?.childInfo.readOnly
                              ? false
                              : true
                          }
                          onSliderClick={() => {
                            updateSpecificReadOnlyPermission(
                              userRole.configuration,
                              'childInfo',
                              userRole?.configuration?.childInfo.readOnly
                                ? false
                                : true,
                              [userRole],
                            ).finally(() => {
                              setFakeState(!fakeState)
                            })
                          }}
                          onBoxClick={() => {}}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          {type === 'waiting' && (
            <div className="flex w-full justify-center gap-x-4">
              <Button
                variant="default"
                label={t('admin.acceptInvite')}
                onClick={() => {
                  Swal.fire({
                    text: t('admin.accept'),
                    icon: 'warning',
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      approveUserRole([userRole]).finally(() => {
                        if (schoolId) {
                          fetchPendingUserRoles().finally(() => {
                            onClose()
                          })
                        }
                      })
                    }
                  })
                }}
              />
              <Button
                variant="fullRed"
                label={t('general.cancel')}
                onClick={() => {
                  Swal.fire({
                    text: t('admin.cancelInvite'),
                    icon: 'warning',
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      cancelUserRole([userRole]).finally(() => {
                        if (schoolId) {
                          fetchPendingUserRoles().finally(() => {
                            onClose()
                          })
                        }
                      })
                    }
                  })
                }}
              />
            </div>
          )}
          {type === 'invited' && (
            <div className="w-full flex justify-center">
              <Button
                label={t('admin.cancelInvite')}
                variant="fullRed"
                onClick={() => {
                  Swal.fire({
                    text: t('admin.cancelInviteWarningText'),
                    icon: 'warning',
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.yes'),
                    cancelButtonText: t('general.cancel'),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      cancelUserRole([userRole]).finally(() => {
                        if (schoolId) {
                          fetchNewUserRoles(schoolId).finally(() => {
                            onClose()
                          })
                        }
                      })
                    }
                  })
                }}
              />
            </div>
          )}
          {type === 'active' && (
            <div className="w-full flex justify-center">
              <div className="flex flex-col w-fit items-center gap-y-2">
                {type === 'active' && (
                  <div className="flex w-full">
                    <Button
                      className="flex-grow"
                      label={t('admin.pause')}
                      variant="fullYellow"
                      onClick={() => {
                        Swal.fire({
                          text: t('admin.pausedUserText'),
                          icon: 'warning',
                          showCancelButton: true,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                          cancelButtonText: t('general.cancel'),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            pauseUserRole([userRole]).finally(() => {
                              if (schoolId) {
                                fetchActiveUserRoles(schoolId).finally(() => {
                                  onClose()
                                })
                              }
                            })
                          }
                        })
                      }}
                    />
                  </div>
                )}
                <div>
                  <Button
                    className="flex-grow"
                    label={t('documents.remove')}
                    variant="fullRed"
                    onClick={() => {
                      Swal.fire({
                        text: t('admin.removePersonalPermission'),
                        icon: 'warning',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          cancelUserRole([userRole]).finally(() => {
                            if (schoolId) {
                              fetchActiveUserRoles(schoolId).finally(() => {
                                onClose()
                              })
                            }
                          })
                        }
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalRegular>
  )
}

export default TeacherModal
