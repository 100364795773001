import React, { createContext, forwardRef, useContext, useMemo } from 'react'
import classNames from 'classnames'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useBoolean, UseBoolean } from 'react-hanger'
import { useClickOutside } from '../hooks/useClickoutside'
import { useButtonGroupIndex } from './Group'

interface ButtonProps {
  icon?: IconDefinition
  label?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  size?: ButtonSize
  variant?: ButtonVariant
  dropdown?: React.ReactNode
  dropdownIcon?: IconDefinition | boolean
  dropdownClassName?: String
  loading?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  overrideGroup?: boolean
  reverseIcon?: boolean
  countPrompt?: number
  fontSize?: string
  iconColor?: string
  color?: string
  textColor?: string
}

interface ButtonClassnames {
  base: string
  hover: string
  disabled: string
}

export type ButtonOrAnchorProps = ButtonProps &
  (React.ButtonHTMLAttributes<any> | React.AnchorHTMLAttributes<any>)

const buttonDropdownContext = createContext(
  null as null | { isOpen: UseBoolean },
)

export function useButtonDropdown() {
  return useContext(buttonDropdownContext)
}

export const Button = forwardRef<HTMLDivElement, ButtonOrAnchorProps>(
  (
    {
      className = '',
      icon,
      iconColor,
      label,
      disabled = false,
      loading = false,
      onClick,
      size = 'lg',
      dropdown,
      dropdownIcon = faChevronDown,
      dropdownClassName,
      variant = 'default',
      type = 'button',
      overrideGroup,
      reverseIcon,
      countPrompt,
      fontSize,
      textColor,
      ...props
    },
    ref,
  ) => {
    const hasDropdown = !!dropdown
    if (hasDropdown && onClick) {
      console.warn(
        'passing onClick to button with dropdown. The onClick will be overridden',
      )
    }
    const isDropdownOpen = useBoolean(false)

    const dropdownContext = useMemo(
      () => ({ isOpen: isDropdownOpen }),
      [isDropdownOpen],
    )
    const [dropdownMenuRef, dropdownToggleRef] = useClickOutside(
      isDropdownOpen.setFalse,
    )
    const groupIndex = useButtonGroupIndex()
    const isGroup = !overrideGroup && groupIndex !== null
    const isGroupStart = isGroup && groupIndex === 0
    const isGroupEnd = isGroup && groupIndex === 1

    let classes: ButtonClassnames
    if (variant === 'custom' && textColor) {
      const customVariant = variantClassnames[variant]
      if (isFunction(customVariant)) {
        classes = customVariant(textColor)
      } else {
        classes = customVariant
      }
    } else {
      const standardVariant = variantClassnames[variant]
      if (isFunction(standardVariant)) {
        classes = standardVariant('')
      } else {
        classes = standardVariant
      }
    }

    return (
      <div
        className={classNames(
          'relative inline-block ',
          variant !== 'transparent' &&
            !(loading || disabled || isGroup) &&
            'transition duration-500',
          isGroup && !isGroupStart && '-mx-px',
          !isGroup && 'rounded-full',
          isGroupStart && 'rounded-full',
          isGroupEnd && 'rounded-full',
          className,
        )}
        ref={ref}
      >
        {countPrompt ? (
          <div className="absolute top-0 right-0 z-50 bg-eventsMain text-white rounded-full w-[20px] h-[20px] leading-[20px] text-center text-xs">
            {countPrompt.toString()}
          </div>
        ) : null}
        <ButtonOrAnchor
          {...props}
          type={type}
          ref={dropdownToggleRef}
          disabled={disabled || loading}
          onClick={
            hasDropdown
              ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.stopPropagation()
                  isDropdownOpen.toggle()
                }
              : onClick
          }
          className={classNames(
            !label && icon ? squareSizeClasses[size] : sizeClasses[size],
            'flex items-center justify-center leading-5 font-medium focus:outline-none transition ease-out duration-75 w-full whitespace-no-wrap h-full ',
            !(loading || disabled) && classes.hover,
            (loading || disabled) && 'cursor-not-allowed border-2 bg-white ',
            disabled || loading ? classes.disabled : classes.base,
            !isGroup && 'rounded-full',
            isGroupStart && 'rounded-full',
            isGroupEnd && 'rounded-full',
          )}
        >
          {loading && (
            <div className="absolute flex items-center justify-center w-full h-full">
              <div
                className={classNames(
                  variant === 'primary' && 'text-indigo-100',
                  variant === 'default' && 'text-gray-500',
                  variant === 'transparent' && 'text-gray-500',
                )}
              >
                <FontAwesomeIcon
                  className="w-5 h-5 text-base animate-spin"
                  icon={faSpinner}
                />
              </div>
            </div>
          )}
          <div
            className={classNames(
              'flex items-center space-x-2 whitespace-no-wrap',
              fontSize ?? null,
            )}
          >
            {icon && !reverseIcon && (
              <span
                className={classNames(
                  'flex items-center justify-center w-4 h-4',
                  { 'opacity-75': !!label },
                )}
              >
                <FontAwesomeIcon icon={icon} className={iconColor} />
              </span>
            )}
            {label && (
              <span className={classNames(loading && 'invisible')}>
                {label}
              </span>
            )}
            {hasDropdown && dropdownIcon && (
              <span style={{ marginTop: '1px', marginBottom: '-1px' }}>
                <FontAwesomeIcon
                  icon={dropdownIcon as IconDefinition}
                  className={iconColor}
                />
              </span>
            )}
            {icon && reverseIcon && (
              <span
                className={classNames(
                  'flex items-center justify-center w-4 h-4',
                  { 'opacity-75': !!label },
                )}
              >
                <FontAwesomeIcon icon={icon} className={iconColor} />
              </span>
            )}
          </div>
        </ButtonOrAnchor>
        {hasDropdown && isDropdownOpen.value && (
          <buttonDropdownContext.Provider value={dropdownContext}>
            <div
              className={classNames(
                'absolute right-0 z-20 w-48',
                dropdownClassName,
              )}
              ref={dropdownMenuRef}
            >
              {dropdown}
            </div>
          </buttonDropdownContext.Provider>
        )}
      </div>
    )
  },
)

const ButtonOrAnchor = forwardRef(({ type, ...props }: any, ref) => {
  if (props.href) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...props} ref={ref} />
  }
  return <button type={type} {...props} ref={ref} />
})

export type ButtonSize =
  | 'xs2'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'custom'
  | 'customSize'
  | 'setWidth'
const sizeClasses: { [key in ButtonSize]: string } = {
  xs2: 'text-xs px-2 py-0.25',
  xs: 'text-xs px-3 py-0.5',
  sm: 'text-sm px-6 py-2',
  md: 'text-base px-6 py-3',
  lg: 'text-md px-8 py-[16px]',
  custom: 'text-base px-12 py-3',
  customSize: 'text-md py-3 w-[170px]',
  setWidth: 'text-sm py-3 w-[200px]',
}
const squareSizeClasses: { [key in ButtonSize]: string } = {
  xs2: 'text-xs p-0.5',
  xs: 'text-xs p-1',
  sm: 'text-sm p-1.5',
  md: 'text-base p-2',
  lg: 'text-lg p-3',
  custom: '',
  customSize: '',
  setWidth: '',
}

export type ButtonVariant =
  | 'primary'
  | 'borderMain'
  | 'default'
  | 'transparent'
  | 'secondary'
  | 'danger'
  | 'red'
  | 'green'
  | 'orange'
  | 'fullWhite'
  | 'fullWhiteGrayText'
  | 'fullBlue'
  | 'roundOrange'
  | 'roundGreen'
  | 'roundBlue'
  | 'roundWhiteCircleGreen'
  | 'fullGreen'
  | 'fullYellow'
  | 'roundHistory'
  | 'signBlue'
  | 'scrollToTop'
  | 'filter'
  | 'bankId'
  | 'disableBlue'
  | 'disableRed'
  | 'docRemove'
  | 'docAdminRemove'
  | 'disableGray'
  | 'play'
  | 'blue'
  | 'borderBlue'
  | 'borderGray'
  | 'fullRed'
  | 'borderGrayBoxRed'
  | 'borderGrayBoxYellow'
  | 'borderGrayBoxGreen'
  | 'fullBlueExport'
  | 'fullYellowExport'
  | 'fullGreenExport'
  | 'borderRed'
  | 'fullGray'
  | 'fullOrange'
  | 'borderGreen'
  | 'faceBook'
  | 'selectedBlue'
  | 'squaredShadow'
  | 'squaredShadowAccept'
  | 'gridButton'
  | 'pictureButton'
  | 'basicRound'
  | 'basicSquare'
  | 'lightPurple'
  | 'darkBorderAndText'
  | 'custom'
  | 'fullWhiteDarkText'
  | 'fullGreenSelected'
  | 'remindStyle'

const variantClassnames: {
  [key in ButtonVariant]:
    | ButtonClassnames
    | ((textColor: string) => ButtonClassnames)
} = {
  primary: {
    base: 'text-white bg-eventsMain relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-gray-400 cursor-not-allowed border-2 bg-white',
  },
  borderMain: {
    base: 'text-eventsMain bg-white relative border-2 border-eventsMain drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  borderRed: {
    base: 'text-red-400 bg-white relative border-2 border-red-200 drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  secondary: {
    base: 'text-groupsMain bg-white relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  red: {
    base: 'text-red-400 bg-white relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-gray-400',
  },
  green: {
    base: 'text-green-500 bg-white relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-green-500',
  },
  blue: {
    base: 'text-eventsMain bg-white relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-blue-500',
  },
  orange: {
    base: 'text-orange-400 bg-white relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-orange-500',
  },
  default: {
    base: 'text-eventsMain bg-white relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  transparent: {
    base: 'text-gray-700 border-transparent border-opacity-0 font-light rounded-full',
    hover: 'hover:shadow hover:bg-white',
    disabled: 'text-gray-400 border-transparent',
  },
  danger: {
    base: 'text-red-500 bg-white  relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  fullWhite: {
    base: 'text-eventsMain text-md  border-[1px] bg-white relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  fullWhiteGrayText: {
    base: 'text-gray-500 font-semibold  border-[1px] bg-white relative drop-shadow-sm rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  fullBlue: {
    base: 'text-white bg-eventsMain border border-eventsMain relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },

  roundOrange: {
    base: 'text-white bg-orange-400 relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  roundGreen: {
    base: 'text-white bg-eventsMain relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  roundBlue: {
    base: 'text-white bg-blue-400 relative drop-shadow-lg rounded-full border-2 border-blue-400',
    hover: 'hover:shadow-md',
    disabled: 'text-gray-400',
  },
  roundWhiteCircleGreen: {
    base: 'text-green-600 bg-white relative drop-shadow-lg rounded-full border border-green-600',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  fullGreen: {
    base: 'text-white text-sm bg-emerald-300  border-[1px] border-emerald-300 relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  fullYellow: {
    base: 'text-white text-md bg-amber-300 border-[1px] border-amber-300 relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  roundHistory: {
    base: 'text-red-500 bg-white relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  signBlue: {
    base: 'text-white bg-blue-900 relative rounded-full drop-shadow-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  scrollToTop: {
    base: 'text-white text-gray-400 border-[1px] relative rounded-lg drop-shadow-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  filter: {
    base: 'text-gray-500 bg-zinc-50 border relative rounded-xl drop-shadow-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  bankId: {
    base: 'text-white bg-bankIdMain relative rounded-full drop-shadow-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  disableBlue: {
    base: 'text-eventsMain bg-white relative border-2 border-blue-300 drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-gray-400',
  },
  disableRed: {
    base: 'text-gray-400 cursor-not-allowed border bg-white rounded-full drop-shadow-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-gray-400 cursor-not-allowed border-2 bg-white',
  },
  docRemove: {
    base: 'text-white bg-red-500 cursor-pointer rounded-full drop-shadow-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-gray-400 cursor-not-allowed border-2 bg-white',
  },
  docAdminRemove: {
    base: 'text-white bg-red-900 opacity-90 cursor-pointer rounded-full drop-shadow-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-gray-400 cursor-not-allowed border-2 bg-white',
  },
  disableGray: {
    base: 'text-gray-400 bg-white rounded-full drop-shadow-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-gray-400 cursor-not-allowed border-2 bg-white',
  },
  play: {
    base: 'text-eventsMain bg-white relative shadow-md rounded-full drop-shadow-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  borderBlue: {
    base: 'text-eventsMain bg-white relative border-2 border-eventsMain drop-shadow-lg transition duration-500 rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  borderGray: {
    base: 'text-gray-300 bg-white relative border border-gray-200 drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  fullRed: {
    base: 'text-white text-md bg-red-400 relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  borderGrayBoxRed: {
    base: 'text-gray-300 bg-white relative border border-gray-200 drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  borderGrayBoxYellow: {
    base: 'text-gray-300 bg-white relative border border-gray-200 drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  borderGrayBoxGreen: {
    base: 'text-gray-300 bg-white relative border border-gray-200 drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  fullGreenExport: {
    base: 'text-white text-sm bg-[#6BBD73] relative rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  fullYellowExport: {
    base: 'text-white text-sm bg-[#E19526] relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  fullBlueExport: {
    base: 'text-white text-sm bg-[#2F8FF8] relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  fullGray: {
    base: 'text-white text-sm bg-gray-600 relative rounded-full drop-shadow-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  fullOrange: {
    base: 'text-white text-sm bg-orange-400 relative transition duration-500 rounded-full drop-shadow-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  borderGreen: {
    base: 'text-[#6BBD73] bg-white relative border border-[#6BBD73] drop-shadow-lg transition duration-500 rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  faceBook: {
    base: 'text-white bg-facebook relative drop-shadow-lg rounded-lg',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  selectedBlue: {
    base: 'text-eventsMain bg-white relative border-2 border-eventsMain drop-shadow-lg transition duration-300 rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  squaredShadow: {
    base: 'rounded-full bg-white relative border border-gray-500 shadow-md transition duration-300',
    hover: 'hover:shadow-lg',
    disabled: 'text-eventsMain',
  },
  squaredShadowAccept: {
    base: 'rounded-full bg-eventsMain relative border border-eventsMain shadow-md transition duration-300',
    hover: 'hover:shadow-lg',
    disabled: 'text-eventsMain',
  },
  gridButton: {
    base: 'rounded-full bg-eventsMain text-white relative border border-eventsMain shadow-md transition duration-300',
    hover: 'hover:shadow-lg',
    disabled: 'text-eventsMain',
  },
  pictureButton: {
    base: 'rounded-full bg-eventsMain text-white relative border border-eventsMain shadow-md transition duration-300',
    hover: 'hover:shadow-lg',
    disabled: 'text-eventsMain',
  },
  basicSquare: {
    base: 'rounded-lg bg-white relative border border-gray-500 shadow-md transition duration-300',
    hover: 'hover:shadow-lg',
    disabled: 'text-eventsMain',
  },
  basicRound: {
    base: 'rounded-full bg-white relative border border-gray-500 shadow-md transition duration-300',
    hover: 'hover:shadow-lg',
    disabled: 'text-eventsMain',
  },
  lightPurple: {
    base: 'text-md bg-lightPurple text-white relative drop-shadow-lg rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-eventsMain',
  },
  darkBorderAndText: {
    base: 'text-gray-600 border-[1px] border-gray-400 relative rounded-full drop-shadow-lg font-semibold',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  custom: (textColor: string) => ({
    base: `text-[#${textColor}] bg-white relative  drop-shadow-lg rounded-full text-lg`,
    hover: 'hover:shadow-md',
    disabled: `text-gray-400 cursor-not-allowed border-2 bg-white`,
  }),
  fullGreenSelected: {
    base: 'text-white bg-greenSelected border border-greenSelected relative rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  fullWhiteDarkText: {
    base: 'text-black bg-white border border-black relative rounded-full',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
  remindStyle: {
    base: 'bg-[#F6F6F6] rounded-full text-[#1398F7] text-xs  px-1',
    hover: 'hover:shadow-md',
    disabled: 'text-white',
  },
}

function isFunction(
  variant: ButtonClassnames | ((textColor: string) => ButtonClassnames),
): variant is (textColor: string) => ButtonClassnames {
  return typeof variant === 'function'
}
