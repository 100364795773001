import React from 'react'
import ModalRegular from './ModalRegular'

type VideoModalProps = {
  src: any
  showModal: boolean
  hideModal: () => void
}

const VideoModal: React.FC<VideoModalProps> = ({
  src,
  showModal,
  hideModal,
}) => {
  return (
    <ModalRegular
      isOpen={showModal}
      onClose={() => hideModal()}
      closeColor="white"
    >
      <div className={'flex justify-center'}>
        <video src={src?._url} controls className={'w-full rounded-2xl shadow'}>
          <source src={src?._url} type="video/mp4" />
        </video>
      </div>
    </ModalRegular>
  )
}

export default VideoModal
