import { motion } from 'framer-motion'
import classNames from 'classnames'
import { faRotate } from '@fortawesome/free-solid-svg-icons'
import { useContext, useEffect, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import React from 'react'
import { HolderContent } from './components/HolderContent'
import { Blocks } from './components/Blocks'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Page from './components/Page'
import { PageNew } from './components/PageNew'
import { useParams } from 'react-router-dom'
import { ExportStoreContext } from '../../store/exportStore'
import { observer } from 'mobx-react'

interface Props {
  loading?: boolean
}

export const PdfBuilder: React.FC<Props> = observer(({ loading }) => {
  /*  const { skaId } = useParams()
  const { getSkaReflectionItems, skaItems } = useContext(ExportStoreContext)
  useEffect(() => {
    if (skaId) {
      getSkaReflectionItems(skaId)
    }
  }, [skaId])*/
  return (
    <div className="testhejhej">
      <DndProvider backend={HTML5Backend}>
        <Page skaItems2={[]} />
      </DndProvider>
    </div>
  )
})
