import React from 'react'
import { BloggDoc } from '../../../Models/BloggDoc'
import moment, { Moment as MomentType } from 'moment'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/sv'

export const HeaderDays = ({
  firstDate,
  amountOfDays,
  typeOfView,
}: {
  firstDate: MomentType
  amountOfDays: number
  typeOfView: string
}) => {
  const showDays = () => {
    var dateToShow = moment(firstDate)
    var dateToShow2 = moment(firstDate)
    var dayPercentage = 100 / amountOfDays
    var rows = []
    if (typeOfView == 'oneWeek' || typeOfView == 'day') {
      var col = {
        width: '100%',
        backgroundColor: '#7eb5f4',
        float: 'left',
        textAlign: 'center',
        color: 'white',
        borderLeft: '1px solid white',
      }
      rows.push(
        <div
          style={{
            width: '100%',
            backgroundColor: '#7eb5f4',
            float: 'left',
            textAlign: 'center',
            color: 'white',
            borderLeft: '1px solid white',
          }}
        >
          Vecka{' '}
          <Moment locale="sv" format="ww">
            {firstDate}
          </Moment>
        </div>,
      )

      for (var i = 0; i < amountOfDays; i++) {
        var day1 = moment(dateToShow).get('date')
        var month1 = moment(dateToShow).get('month')
        var year1 = moment(dateToShow).get('year')

        var day2 = moment().get('date')
        var month2 = moment().get('month')
        var year2 = moment().get('year')

        var activeDay = false
        if (day1 == day2 && month1 == month2 && year1 == year2) {
          var activeDay = true
        }

        rows.push(
          <div
            key={i}
            className="timme_header_entrepenad"
            style={{
              textAlign: 'center',
              width: dayPercentage + '%',
              position: 'relative',
              float: 'left',
            }}
          >
            {activeDay ? (
              <div
                style={{
                  width: '100%',
                  height: 15500,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  paddingTop: '6px',
                }}
              ></div>
            ) : (
              <div>
                {moment(dateToShow).day() == 6 ||
                moment(dateToShow).day() == 0 ? (
                  <div
                    style={{
                      width: '100%',
                      height: 15500,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      backgroundColor: 'rgba(203,69,98,0.2)',
                      paddingTop: '6px',
                    }}
                  ></div>
                ) : null}
              </div>
            )}
            <b>
              <Moment format="D/M">{dateToShow}</Moment>
            </b>
            <br />
            <small>
              <Moment locale="sv" format="ddd">
                {dateToShow}
              </Moment>
            </small>
            <br />
            <div className="relative w-full ml-[-8px]">
              <small style={{ position: 'absolute', left: '25%' }}>06</small>
              <small style={{ position: 'absolute', left: '50%' }}>12</small>
              <small style={{ position: 'absolute', left: '75%' }}>18</small>
            </div>
          </div>,
        )

        var dateToShow = moment(dateToShow).add(1, 'days')
      }
    } else if (typeOfView == 'month') {
      var startOfMonthForWeeks = dateToShow.startOf('month')
      //var test = dateToShow.endOf('month')

      var days = dateToShow.daysInMonth()

      var weeks: any = []
      for (var i = 0; i < days; i++) {
        if (!weeks.includes(startOfMonthForWeeks.week())) {
          weeks.push(startOfMonthForWeeks.week())
        }
        startOfMonthForWeeks.add(1, 'days')
      }
      //var test3 = moment(Number(moment(test)) - Number(moment(test2))).weeks()

      const antaldagar = amountOfDays
      const antalveckor = antaldagar / weeks.length

      const totaltid = antalveckor // 16 veckor i millisekunder

      var datumvisa = moment(dateToShow2)
      var datumvisa2 = moment(dateToShow2)

      var utrakningdatum1 = moment(dateToShow2)
      var utrakningdatum2 = moment(dateToShow2)

      var test = utrakningdatum1.startOf('month').get('day')
      var test2 = utrakningdatum2.endOf('month').get('day')
      if (test2 == 0) {
        test2 = 7
      }

      var antal = 1

      var forstaDagen1 = moment(dateToShow2)

      for (var x = 0; x < weeks.length; x++) {
        var tjabbaaa = weeks.length - 1

        if (x == tjabbaaa) {
          var forstaDagen = forstaDagen1.endOf('month').get('day')
          if (forstaDagen == 0) {
            forstaDagen = 7
          }
          var forstaWidth1 = 100 / amountOfDays //  3,22
          var hurMangaDagar = 0 + forstaDagen
          var hurLang = forstaWidth1 * hurMangaDagar
        } else if (x > 0) {
          var forstaDagen = forstaDagen1.startOf('week').get('day')
          var forstaWidth1 = 100 / amountOfDays //  3,22
          var hurMangaDagar = 8 - forstaDagen
          var hurLang = forstaWidth1 * hurMangaDagar
        } else {
          var forstaDagen = forstaDagen1.startOf('month').get('day')
          if (forstaDagen == 0) {
            forstaDagen = 7
          }
          var forstaWidth1 = 100 / amountOfDays //  3,22
          var hurMangaDagar = 8 - forstaDagen
          var hurLang = forstaWidth1 * hurMangaDagar
        }

        var divWidth = hurLang

        rows.push(
          <div
            style={{
              width: divWidth + '%',
              backgroundColor: 'rgb(126 181 244 / var(--tw-bg-opacity)',
              borderLeft: '1px solid white',
              boxSizing: 'border-box',
              float: 'left',
              textAlign: 'center',
              color: 'white',
            }}
          >
            V. <Moment format="ww">{datumvisa2}</Moment>
          </div>,
        )

        var forstaDagen1 = moment(forstaDagen1).add(1, 'weeks')

        antal++
        var datumvisa2 = moment(datumvisa2).add(1, 'weeks')
        //var weeksInMonth = test3
        //var veckorImanaden = moment(this.state.forstadatum).weeks();
        /*if (moment(this.state.forstadatum).get('day') == 0){
    veckorImanaden++;
  }*/
      }
      for (var i = 0; i < amountOfDays; i++) {
        var day1 = moment(dateToShow2).get('date')
        var month1 = moment(dateToShow2).get('month')
        var year1 = moment(dateToShow2).get('year')

        var day2 = moment().get('date')
        var month2 = moment().get('month')
        var year2 = moment().get('year')

        var activeDay = false
        if (day1 == day2 && month1 == month2 && year1 == year2) {
          var activeDay = true
        }

        rows.push(
          <div
            key={i}
            className="timme_header_entrepenad"
            style={{
              textAlign: 'center',
              width: dayPercentage + '%',
              position: 'relative',
              float: 'left',
            }}
          >
            {activeDay ? (
              <div
                style={{
                  width: '100%',
                  height: 15500,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  paddingTop: '6px',
                }}
              ></div>
            ) : (
              <div>
                {moment(dateToShow2).day() == 6 ||
                moment(dateToShow2).day() == 0 ? (
                  <div
                    style={{
                      width: '100%',
                      height: 15500,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      backgroundColor: 'rgba(203,69,98,0.2)',
                      paddingTop: '6px',
                    }}
                  ></div>
                ) : null}
              </div>
            )}
            <b>
              <Moment format="D/M">{dateToShow2}</Moment>
            </b>
            <br />
            <small>
              <Moment locale="sv" format="ddd">
                {dateToShow2}
              </Moment>
            </small>
            <br />
            <div className="relative w-full ml-[-8px]">
              <small style={{ position: 'absolute', left: '25%' }}>06</small>
              <small style={{ position: 'absolute', left: '50%' }}>12</small>
              <small style={{ position: 'absolute', left: '75%' }}>18</small>
            </div>
          </div>,
        )

        var dateToShow2 = moment(dateToShow2).add(1, 'days')
      }
    }

    return rows
  }
  return <>{showDays()}</>
}
