import Parse from 'parse'
import { School } from './School'

export class Department extends Parse.Object {
  public static readonly Fields = {
    school: 'school',
    isDep: 'isDep',
  }
  constructor() {
    super('dep_con')
  }

  /*get id(): string {
    return this.get('id')
  }*/

  get contactPic(): Parse.File | undefined {
    return this.get('contactPic')
  }
  set contactPic(value: Parse.File | undefined) {
    value ? this.set('contactPic', value) : this.unset('contactPic')
  }
  get contactPic_thumb(): Parse.File | undefined {
    return this.get('contactPic_thumb')
  }
  set contactPic_thumb(value: Parse.File | undefined) {
    value ? this.set('contactPic_thumb', value) : this.unset('contactPic_thumb')
  }
  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
  get isDep(): boolean {
    return this.get('isDep')
  }
  set isDep(value: boolean) {
    this.set('isDep', value)
  }
  get klassName(): string | undefined {
    return this.get('klassName')
  }
  set klassName(value: string | undefined) {
    value ? this.set('klassName', value) : this.unset('klassName')
  }
  get lastName(): string | undefined {
    return this.get('lastName')
  }
  set lastName(value: string | undefined) {
    value ? this.set('lastName', value) : this.unset('lastName')
  }
  get workTitle(): string | undefined {
    return this.get('workTitle')
  }
  set workTitle(value: string | undefined) {
    value ? this.set('workTitle', value) : this.unset('workTitle')
  }
  get teleNumber(): string | undefined {
    return this.get('teleNumber')
  }
  set teleNumber(value: string | undefined) {
    value ? this.set('teleNumber', value) : this.unset('teleNumber')
  }
  get otherMobNum(): string | undefined {
    return this.get('otherMobNum')
  }
  set otherMobNum(value: string | undefined) {
    value ? this.set('otherMobNum', value) : this.unset('otherMobNum')
  }
  get email(): string | undefined {
    return this.get('email')
  }
  set email(value: string | undefined) {
    value ? this.set('email', value) : this.unset('email')
  }
  get adress(): string | undefined {
    return this.get('adress')
  }
  set adress(value: string | undefined) {
    value ? this.set('adress', value) : this.unset('adress')
  }
  get city(): string | undefined {
    return this.get('city')
  }
  set city(value: string | undefined) {
    value ? this.set('city', value) : this.unset('city')
  }
  get areaCode(): string | undefined {
    return this.get('areaCode')
  }
  set areaCode(value: string | undefined) {
    value ? this.set('areaCode', value) : this.unset('areaCode')
  }
}

Parse.Object.registerSubclass('dep_con', Department)
