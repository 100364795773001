import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { Button } from '../../../components/Button'
import { CreateEventParams } from '../../../store/event3Store'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Department } from '../../../Models/Department'
import { KidStoreContext } from '../../../store/kidStore'
import { Kid } from '../../../Models/Kid'
import ColorCard from '../../../components/ColorCard'
import { getImageOrPlaceHolder } from '../../../utils/parseUtils'
import { RandomColors } from '../../../components/RandomColors'
import classNames from 'classnames'

interface Props2 {
  onChange: (kidId: string) => void
  selectedKidIds: string[]
  departments: Department[]
  kids?: Kid[]
}

export const ChooseKids: React.FC<Props2> = observer((props: Props2) => {
  return (
    <div className="">
      {props.departments?.map((department: Department, index: number) => {
        return (
          <>
            <ColorCard
              title={department?.attributes?.klassName + ''}
              bgColor={
                department.get('departmentColor')
                  ? 'bg-[' + department.get('departmentColor') + ']'
                  : RandomColors[index]
              }
            >
              <div
                className={
                  'flex flex-wrap max-w-7xl py-3 gap-2 font-medium justify-center'
                }
              >
                {props.kids
                  ?.filter((k: Kid) => {
                    return (
                      k?.attributes?.departmentPointer?.id === department?.id
                    )
                  })
                  .sort((a, b) => {
                    return a?.attributes?.kidName.localeCompare(
                      b?.attributes?.kidName,
                    )
                  })
                  .map((kid) => {
                    return (
                      <div
                        key={kid?.id}
                        onClick={() => {
                          //setSelectedKid(kid)
                          props.onChange(kid.id)
                        }}
                        className={
                          'flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:shadow-md hover:bg-gray-50 rounded-2xl '
                        }
                      >
                        <div className="relative">
                          <img
                            className={classNames(
                              'w-[40px] h-[40px] rounded-full object-cover shadow',
                              props.selectedKidIds?.includes(kid.id) &&
                                ' border-[7px] border-eventsMain',
                            )}
                            src={getImageOrPlaceHolder(
                              kid?.attributes?.kidImage?._url,
                            )}
                            alt=""
                          />
                        </div>
                        <p>{kid?.attributes.kidName}</p>
                      </div>
                    )
                  })}
              </div>
            </ColorCard>
          </>
        )
      })}
    </div>
  )
})
