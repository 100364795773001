import Parse from 'parse'
import { Note } from './Note'
import { School } from './School'
import { User } from './User'

export class ReadState extends Parse.Object {
  public static readonly Fields = {
    school: 'school',
    className: 'read_state',
    notes: 'notes',
  }
  constructor() {
    super('read_state')
  }

  get notes(): Note {
    return this.get('notes')
  }

  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
  get read_revision(): Number {
    return this.get('read_revision')
  }
  set read_revision(value: Number) {
    this.set('read_revision', value)
  }
  get user(): User {
    return this.get('user')
  }
  set user(value: User) {
    this.set('user', value)
  }
  get previous_read_revision(): Number {
    return this.get('previous_read_revision')
  }
  set previous_read_revision(value: Number) {
    this.set('previous_read_revision', value)
  }
}
Parse.Object.registerSubclass('read_state', ReadState)
