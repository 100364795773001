import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, useEffect, useState } from 'react'
import { faAngleDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Listbox, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

interface Props {
  items: any[]
  onChange: (e: any) => void
  itemVariableKey: string
  placeholder: string
  initialSelectedItems?: any[]
  className?: string
  alwaysShow?: boolean
}

const Multiselect = (props: Props) => {
  const [selectedItems, setSelectedItems] = useState(
    props.initialSelectedItems ?? [],
  ) as any

  const { t } = useTranslation()

  useEffect(() => {
    setSelectedItems(props.initialSelectedItems ?? [])
  }, [props.initialSelectedItems])

  const isSelected = (id: string) => {
    return selectedItems.find((s: any) => s?.id === id)
  }

  const selectAll = () => {
    setSelectedItems(props.items)
    props.onChange(props.items)
  }

  /*if (props.alwaysShow){
    return (
      
    )
  }*/

  return (
    <Listbox
      value={selectedItems}
      onChange={(items) => {
        const uniqueValues: string | any[] = []
        let duplicate: string | null = null
        items.map((item: any) => {
          if (uniqueValues.includes(item.id)) {
            duplicate = item.id
          } else {
            uniqueValues.push(item.id)
          }
        })

        setSelectedItems(items.filter((item: any) => item.id !== duplicate))
        props.onChange(items.filter((item: any) => item.id !== duplicate))
      }}
      multiple
    >
      <div className="  relative pl-4 pt-[3px] bg-white shadow-md pb-2 pr-4 rounded-full border border-gray-200 inline-block space-x-2 min-w-[250px] w-auto max-w-[10%] h-auto min-h-[50px] max-h-[10px]">
        {/*<div
          className={
            selectedItems.length
              ? ' absolute top-[-9px] rounded-xl shadow left-10 bg-white pl-2 pr-2 text-sm font-bold'
              : 'w-full text-center text-sm font-bold mt-[10px]'
          }
        >*/}
        {!selectedItems.length && (
          <div className="w-full text-center text-sm font-bold mt-[10px]">
            {props.placeholder}
          </div>
        )}
        {props.items && selectedItems.length == props.items.length ? (
          <>
            <div className="border border-gray-200 p-2 m-1 rounded-full w-auto float-left flex space-x-1 justify-center">
              <p
                className="w-[15px] h-[15px] rounded-full text-red-500 border border-red-500 float-left text-center text-xs cursor-pointer"
                style={{ lineHeight: '13px' }}
                onClick={() => {
                  setSelectedItems([])
                  props.onChange([])
                }}
              >
                x
              </p>
              <p className="float-left text-xs">
                {t('multiselect.allSelected')}
              </p>
            </div>
          </>
        ) : (
          <div className="w-full h-auto overflow-y-scroll scrollbar-hide overflow-hidden max-h-[40px]">
            {selectedItems.map((item: any) => {
              return (
                <div
                  key={item.id}
                  className="border border-gray-200 p-2 m-1 rounded-full w-auto float-left flex space-x-1 justify-center"
                >
                  <p
                    className="w-[15px] h-[15px] rounded-full text-red-500 border border-red-500 float-left text-center text-xs cursor-pointer"
                    style={{ lineHeight: '13px' }}
                    onClick={() => {
                      const items = selectedItems.filter(
                        (i: any) => i.id !== item.id,
                      )
                      setSelectedItems(items)
                      props.onChange(items)
                    }}
                  >
                    x
                  </p>
                  <p className="float-left text-xs">
                    {item[props.itemVariableKey]}
                  </p>
                </div>
              )
            })}
          </div>
        )}
        <Listbox.Button className="absolute top-[60%] mt-[-22px] hover:scale-[105%] right-2 px-2 py-1 rounded-full border border-gray-200">
          <FontAwesomeIcon icon={faAngleDown} />
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute top-[100%] justify-between z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <div
              className="relative cursor-default hover:bg-blue-50 select-none py-2 pl-5"
              onClick={() => selectAll()}
            >
              {t('multiselect.selectAll')}
            </div>
            {/*<Listbox.Option
              value={props.items}
              className="relative cursor-default select-none py-2 pl-5"
            >
              !Välj alla
      </Listbox.Option>*/}
            {props.items?.map((item) => {
              if (selectedItems.find((i: any) => i?.id == item?.id)) {
                return (
                  <Listbox.Option
                    key={item.id}
                    value={item}
                    className="relative bg-green-50 flex justify-between hover:bg-blue-50 cursor-default select-none py-2 pl-5"
                  >
                    {item[props.itemVariableKey]}{' '}
                    <FontAwesomeIcon
                      className="mr-4 text-green-500"
                      icon={faCheck}
                    ></FontAwesomeIcon>
                  </Listbox.Option>
                )
              }
              return (
                <Listbox.Option
                  key={item.id}
                  value={item}
                  className="relative hover:bg-blue-50 cursor-default select-none py-2 pl-5"
                >
                  {item[props.itemVariableKey]}
                </Listbox.Option>
              )
            })}
            {/*<Listbox.Option
              key={'saknar_avdelning'}
              value={{ label: 'Saknar avdelning', id: 'saknar_avdelning' }}
              className="relative hover:bg-blue-50 cursor-default select-none py-2 pl-5"
            >
              Saknar avdelning
          </Listbox.Option>*/}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default Multiselect
