import { t } from 'i18next'
import ColorCard from '../../../../components/ColorCard'
import Page from '../../../../components/Page'
import { RandomColors } from '../../../../components/RandomColors'
import { getImageOrPlaceHolder } from '../../../../utils/parseUtils'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { KidsStoreContext } from '../../../../store/kidsStore'
import { UserStoreContext } from '../../../../store/userStore'
import { Button } from '../../../../components/Button'
import ModalRegular from '../../../../components/ModalRegular'
import { Kid } from '../../../../Models/Kid'
import { NavigationStoreContext } from '../../../../store/navigationStore'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'
import CustomInput from '../../../../components/CustomInput'
import { ImageUploader } from '../../../../components/ImageUploader'
import Parse from 'parse'
import { Loading } from '../../../../components/Loading'
interface Props {
  kid: Kid
}

export const ChangeChildPSU: React.FC<Props> = (props: Props) => {
  const { departments, selectedDepartments, fetchDepartments } = useContext(
    DepartmentStoreContext,
  )
  const { fetchKids, kids, search, fetchUserRoles, editKidInfo } =
    useContext(KidsStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  const [selectedKids] = useState<string[]>([])
  const [showModal, setShowModal] = useState(false)

  const [selectedKid, setSelectedKid] = useState<Kid | null>(null)

  const { schoolId } = useParams()

  useEffect(() => {
    if (currentUserRole && currentUserRole?.school_pointer) {
      setLoading(true)
      fetchKids(currentUserRole)
      fetchDepartments(currentUserRole?.attributes?.school_pointer?.id, true)
      fetchUserRoles(currentUserRole).finally(() => {
        setLoading(false)
      })
    }
  }, [currentUserRole?.id])
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('admin.changeKidPSN'))
  }, [t])

  const [imageUrl, setImageUrl] = useState<string | null>(null)

  const firstNameInputRef = useRef<HTMLInputElement>(null)
  const lastNameInputRef = useRef<HTMLInputElement>(null)
  const displayNameInputRef = useRef<HTMLInputElement>(null)
  const psnInputRef = useRef<HTMLInputElement>(null)
  const birthDayInputRef = useRef<HTMLInputElement>(null)
  const startDateInputRef = useRef<HTMLInputElement>(null)

  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fakeState, setFakeState] = useState('')
  const uploadImageToParse = useCallback(async (file: File) => {
    var extension = file.name.split('.').pop()
    var parseImageFile = new Parse.File(`tyraimage.${extension}`, file)
    await parseImageFile.save()
    return parseImageFile
  }, [])

  return (
    <Page naked>
      {loading && <Loading />}
      <div className={'flex justify-center w-full flex-col md:space-y-10 '}>
        {departments
          ?.filter((dep) => {
            if (!selectedDepartments?.length) {
              return true
            }

            return selectedDepartments?.find((selDep) => selDep.id === dep.id)
          })
          .map((department, index) => {
            const selectedKidsInDepartment = selectedKids.filter((kidId) =>
              kids?.find(
                (kid) =>
                  kid?.id === kidId &&
                  kid?.attributes?.departmentPointer?.id === department?.id,
              ),
            )
            return (
              <>
                <ColorCard
                  title={department?.attributes?.klassName + ''}
                  bgColor={
                    department.get('departmentColor')
                      ? 'bg-[' + department.get('departmentColor') + ']'
                      : RandomColors[index]
                  }
                >
                  {department.get('departmentColor') ? (
                    <div className="flex flex-wrap justify-center py-44 text-lg sm:text-2xl text-gray-300 w-full ">
                      <> {t('ourKids.noContactInfo')}</>
                    </div>
                  ) : (
                    <div
                      className={
                        'flex flex-wrap max-w-7xl py-3 font-medium justify-center'
                      }
                    >
                      {kids
                        ?.filter((k) => {
                          if (search?.length) {
                            return (
                              k?.attributes?.departmentPointer?.id ===
                                department?.id &&
                              k?.attributes?.kidName
                                ?.toLowerCase()
                                .includes(search.toLowerCase())
                            )
                          }
                          return (
                            k?.attributes?.departmentPointer?.id ===
                            department?.id
                          )
                        })
                        .sort((a, b) => {
                          return a?.attributes?.kidName.localeCompare(
                            b?.attributes?.kidName,
                          )
                        })
                        .map((kid) => {
                          const isSelected = selectedKids.includes(
                            kid?.id || '',
                          )

                          return (
                            <div
                              key={kid?.id}
                              onClick={() => {
                                setShowModal(true)
                                setSelectedKid(kid)
                              }}
                              className={`flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:bg-gray-50 hover:shadow-md rounded-2xl`}
                            >
                              <div className="relative ">
                                {isSelected && (
                                  <div className="absolute border-[4px] border-eventsMain rounded-full w-[80px] h-[80px] top-0 left-0" />
                                )}
                                {kid.removed_from_ss12k && (
                                  <div className="z-10 w-full h-full absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] rounded-full font-bold text-red-400 flex items-center justify-center text-xs text-center">
                                    <>{t('general.endDate')}</>
                                    <br />
                                    {
                                      kid.attributes.placement.attributes
                                        .endDate
                                    }
                                  </div>
                                )}
                                <img
                                  className={`w-[80px] h-[80px] rounded-full object-cover shadow`}
                                  src={getImageOrPlaceHolder(
                                    kid?.attributes?.kidImage?._url,
                                  )}
                                  alt=""
                                />
                              </div>
                              <p>{kid?.attributes.kidName}</p>
                            </div>
                          )
                        })}
                    </div>
                  )}
                  {selectedKidsInDepartment.length > 0 && (
                    <div className="absolute bottom-4 right-4">
                      <Button
                        label={t('admin.kidChangeDep')}
                        onClick={() => {
                          setShowModal(true)
                        }}
                      />
                    </div>
                  )}
                </ColorCard>
              </>
            )
          })}
      </div>
      <ModalRegular
        onClose={() => {
          setShowModal(false)
          setSelectedKid(null)
        }}
        isOpen={showModal}
        bgColor="bg-eventsMain p-4"
        title={t('admin.changeKidPSNButton')}
        closeColor="white"
        className="bg-gray-50"
      >
        <div className="w-full h-full flex flex-col gap-y-2 p-4 sm:px-20 sm:pt-10 ">
          <div className="flex gap-y-2 flex-col">
            <div className="flex flex-row w-full relative">
              <div className="flex w-full flex-row">
                <div className="flex w-full flex-col gap-y-2">
                  <div className="flex flex-row items-center justify-between pt-2">
                    <div className="flex justify-center w-full">
                      <ImageUploader
                        onImageChange={async (file: File) => {
                          const parseFile = await uploadImageToParse(file)
                          selectedKid?.set('kidImage', parseFile)
                          selectedKid?.set('kidImage_thumb', parseFile)
                          setImageUrl(parseFile.url())
                        }}
                        initialImageUrl={
                          imageUrl
                            ? imageUrl
                            : selectedKid?.kidImage
                            ? selectedKid.kidImage._url
                            : undefined
                        }
                        size="lg"
                      />
                    </div>
                  </div>
                  <CustomInput
                    label={t('admin.kidFirstName')}
                    value={selectedKid?.attributes.firstName}
                    onChange={(value) => {
                      selectedKid?.set('firstName', value)
                      setFakeState(value)
                    }}
                    required={true}
                    ref={firstNameInputRef}
                  />
                  <CustomInput
                    label={t('admin.kidLastName')}
                    value={selectedKid?.attributes.lastName}
                    onChange={(value) => {
                      selectedKid?.set('lastName', value)
                      setFakeState(value)
                    }}
                    ref={lastNameInputRef}
                    required={true}
                  />
                  <CustomInput
                    label={t('admin.kidDisplayName')}
                    value={selectedKid?.attributes.kidName}
                    onChange={(value) => {
                      selectedKid?.set('kidName', value)
                      setFakeState(value)
                    }}
                    ref={displayNameInputRef}
                    required={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-y-2 flex-col">
            <CustomInput
              label={t('admin.kidPSN')}
              value={selectedKid?.attributes?.kid_pers_id_num?.toString()}
              onChange={(value) => {
                selectedKid?.set('kid_pers_id_num', value)
                setFakeState(value)
              }}
              format="YYYYMMDD-LLXX"
              ref={psnInputRef}
              onlyNumbers={false}
            />

            <CustomInput
              label={t('admin.kidBirthDay')}
              value={
                selectedKid && selectedKid.attributes.birth_date
                  ? moment(selectedKid.attributes.birth_date).format(
                      'YYYY-MM-DD',
                    )
                  : ''
              }
              onChange={(dateValue) => {
                selectedKid?.set(
                  'birth_date',
                  moment(dateValue, 'YYYY-MM-DD').toDate(),
                )
                setFakeState(dateValue)
              }}
              format="DATE"
              ref={birthDayInputRef}
            />

            <CustomInput
              label={t('admin.kidStartDate')}
              value={
                selectedKid && selectedKid.attributes.start_date
                  ? moment(selectedKid.attributes.start_date).format(
                      'YYYY-MM-DD',
                    )
                  : ''
              }
              onChange={(dateValue) => {
                selectedKid?.set(
                  'start_date',
                  moment(dateValue, 'YYYY-MM-DD').toDate(),
                )
                setFakeState(dateValue)
              }}
              format="DATE"
              ref={startDateInputRef}
            />

            <div className="flex justify-center py-8">
              <Button
                size="custom"
                variant="primary"
                label={t('admin.changeKidPSNButton')}
                onClick={() => {
                  if (selectedKid) {
                    editKidInfo(selectedKid).then(() => {
                      if (schoolId) {
                        Swal.fire({
                          //title: 'Are you sure?',
                          text: t('admin.informationChange'),
                          icon: 'success',
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                          cancelButtonText: t('general.cancel'),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setShowModal(false)
                          }
                        })
                      }
                    })
                  }
                }}
                disabled={
                  !selectedKid?.firstName ||
                  !selectedKid?.lastName ||
                  !selectedKid?.kidName
                }
              />
            </div>
          </div>
        </div>
      </ModalRegular>
    </Page>
  )
}

export default ChangeChildPSU
