import {
  faCheck,
  faHouse,
  faPerson,
  faPlus,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components/Button'
import { faGrid2 } from '@fortawesome/pro-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import classNames from 'classnames'
import {
  CreateEventParams,
  EVENT3_TYPE,
  Event3StoreContext,
} from '../../../store/event3Store'
import CustomRadioButton from '../../../components/CustomRadioButton'
import { Department } from '../../../Models/Department'
import { initialValues } from '../CreateEvent3'
import { Slider } from '../../../components/Slider'
import { UserStoreContext } from '../../../store/userStore'
import Swal from 'sweetalert2'
import { IbgColors } from '../CreateEvent3'
import { getImageFromRole } from '../../../utils/parseUtils'
import { v4 as uuidv4 } from 'uuid'
import React from 'react'

export const PublishEventModal: React.FC<{
  bgColors?: IbgColors
  newEvent: CreateEventParams
  departments: Department[]
  showModal: boolean
  setShowModal: (value: boolean) => void
  onChange: (e: CreateEventParams) => void
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
  dataAction?: {
    eventTimeId: string
    eventGroupInfoId: string
  }
}> = ({
  newEvent,
  departments,
  showModal,
  setShowModal,
  onChange,
  onSave,
  dataAction,
}) => {
  const { t } = useTranslation()

  /* const [showModal, setShowModal] = useState(false) */

  const { createEvent, editEvent } = useContext(Event3StoreContext)

  const [searchAdd, setSearchAdd] = useState<string>('')

  const [freeTextUserRoles, setFreeTextUserRoles] = useState<
    { id: string; text: string }[]
  >([])

  const {
    currentUser,
    currentUserRole,
    fetchTeacherUserRoles,
    teacherUserRoles,
    schoolsForUserRoleAdmins,
  } = useContext(UserStoreContext)

  useEffect(() => {
    if (newEvent.eventType === EVENT3_TYPE.BOOKING) {
      if (newEvent.multiSchools) {
        if (schoolsForUserRoleAdmins) {
          fetchTeacherUserRoles(
            schoolsForUserRoleAdmins.map((school) => school.id),
          )
        }
      } else {
        if (currentUserRole && currentUserRole.school_pointer) {
          fetchTeacherUserRoles([currentUserRole.school_pointer.id])
        }
      }
    }
  }, [])

  const publishAsSelections = [
    {
      value: 'undefined',
      label: currentUser
        ? currentUser.attributes.firstName +
          ' ' +
          currentUser.attributes.lastName
        : t('events.asYou'),
      icon: faPerson,
      color: '#8ED974',
    },
    {
      value: 'school',
      label: t('events.asSchool'),
      icon: faHouse,
      color: '#ffc27e',
    },
    {
      value: 'dep:',
      label: t('events.asDepartment'),
      icon: faGrid2,
      color: '#c99cf2',
    },
  ]

  console.log('eventResponsible', newEvent.eventResponsible)
  console.log('publishAs', newEvent.publishAs)
  return (
    <div className="flex w-full justify-center">
      <ModalRegular
        title={
          newEvent.eventType === EVENT3_TYPE.BOOKING
            ? t('events.addResponsible')
            : t('events.publishAs')
        }
        isOpen={showModal}
        /* bgColor="bg-eventsMain p-4" */
        closeColor="white"
        className={'!max-w-xl'}
        classNameTitle="p-4 w-full text-black !important flex justify-center"
        onClose={() => {
          setShowModal(false)
        }}
        dataAction="createEvent"
      >
        <div className="p-10">
          <CustomRadioButton
            customDep={true}
            options={
              newEvent.multiSchools
                ? publishAsSelections.filter(
                    (sel) => !sel.value.includes('dep:'),
                  )
                : publishAsSelections
            }
            onChange={(value) => {
              console.log('value', value)
              if (value === 'undefined') {
                /* DETTA KAN VÄL ÄNDÅ INTE VARA RÄTT ??
                onChange({
                  ...newEvent,
                  eventFor: undefined,
                  publishAs: value,
                })*/
                if (newEvent.eventType === EVENT3_TYPE.BOOKING) {
                  onChange({
                    ...newEvent,
                    eventResponsible: undefined,
                  })
                } else {
                  onChange({
                    ...newEvent,
                    publishAs: undefined,
                  })
                }
              } else if (value.includes('dep:')) {
                if (newEvent.eventType === EVENT3_TYPE.BOOKING) {
                  onChange({
                    ...newEvent,
                    eventResponsible: [value],
                  })
                } else {
                  onChange({
                    ...newEvent,
                    publishAs: value,
                  })
                }
              } else if (value === 'school') {
                if (newEvent.eventType === EVENT3_TYPE.BOOKING) {
                  onChange({
                    ...newEvent,
                    eventResponsible: [value],
                  })
                } else {
                  onChange({
                    ...newEvent,
                    publishAs: value,
                  })
                }
              }
            }}
            value={
              newEvent.eventType === EVENT3_TYPE.BOOKING
                ? newEvent.eventResponsible
                  ? newEvent.eventResponsible[0]
                  : 'undefined'
                : newEvent.publishAs ?? 'undefined'
            }
          />
          {(newEvent.publishAs?.includes('dep:') ||
            (newEvent.eventResponsible?.length &&
              newEvent.eventResponsible[0]?.includes('dep:'))) && (
            <div className="flex flex-col sm:flex-row flex-wrap gap-2 my-2 mx-4">
              {departments
                .filter((dep) => newEvent.depIds?.includes(dep.id))
                .map((dep) => {
                  return (
                    <Button
                      size="sm"
                      variant={
                        newEvent.eventType === EVENT3_TYPE.BOOKING
                          ? newEvent.eventResponsible?.length &&
                            newEvent.eventResponsible[0]?.includes(dep.id)
                            ? 'fullBlue'
                            : 'fullWhite'
                          : newEvent.publishAs?.includes(dep.id)
                          ? 'fullBlue'
                          : 'fullWhite'
                      }
                      label={dep.klassName}
                      onClick={() => {
                        if (newEvent.eventType === EVENT3_TYPE.BOOKING) {
                          onChange({
                            ...newEvent,
                            eventResponsible: ['dep:' + dep.id],
                          })
                        } else {
                          onChange({
                            ...newEvent,
                            publishAs: 'dep:' + dep.id,
                          })
                        }
                      }}
                    />
                  )
                })}
            </div>
          )}
          {newEvent.eventType === EVENT3_TYPE.BOOKING ? (
            <>
              <div className="flex mb-8 mt-4">
                <input
                  className={classNames(
                    'w-full px-4 py-3 placeholder-gray-400 border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
                  )}
                  type={'text'}
                  //defaultValue={}
                  value={searchAdd}
                  placeholder={
                    t('documents.search').replace('...', '') +
                    '/' +
                    t('events.add') +
                    '...'
                  }
                  onChange={(e) => {
                    setSearchAdd(e.target.value)
                  }}
                />
                <Button
                  className="rounded-full ml-4"
                  icon={faPlus}
                  variant="roundBlue"
                  disabled={searchAdd === '' ? true : false}
                  onClick={() => {
                    const newSearchAdd: { id: string; text: string } = {
                      id: uuidv4(),
                      text: searchAdd,
                    }
                    setFreeTextUserRoles((prevFreeTextUserRoles) => {
                      return [
                        ...prevFreeTextUserRoles,
                        { id: newSearchAdd.id, text: newSearchAdd.text },
                      ]
                    })

                    const selected = newEvent.eventResponsible?.find(
                      (evRe) =>
                        evRe.includes('text:') || evRe.includes('role:'),
                    )
                      ? newEvent.eventResponsible
                      : []

                    selected.push('text:' + newSearchAdd.text)
                    onChange({ ...newEvent, eventResponsible: selected })

                    setSearchAdd('')
                  }}
                />
              </div>
              <div className="w-full h-auto relative">
                <div className="flex flex-col gap-y-4 max-h-[40vh] pb-[5vh] overflow-scroll mb-8 relative">
                  {freeTextUserRoles.map((row) => {
                    let isChecked = newEvent.eventResponsible?.find((evRe) =>
                      evRe.includes(row.text),
                    )
                    return (
                      <div
                        className="flex flex-row cursor-pointer"
                        onClick={() => {
                          let selected = newEvent.eventResponsible?.find(
                            (evRe) =>
                              evRe.includes('text:') || evRe.includes('role:'),
                          )
                            ? newEvent.eventResponsible
                            : []
                          if (isChecked) {
                            console.log('HEJ1')
                            console.log('row text', row.text)
                            console.log('selected', selected)
                            selected = selected?.filter(
                              (sel) => !sel.includes(row.text),
                            )
                          } else {
                            console.log('HEJ2')
                            selected.push('text:' + row.text)
                          }
                          onChange({ ...newEvent, eventResponsible: selected })
                        }}
                      >
                        <div className="w-[40px] h-[40px] flex justify-center items-center">
                          <FontAwesomeIcon
                            icon={faUser}
                            size="xl"
                            className="text-eventsMain"
                          />
                        </div>
                        <div className="flex flex-1 items-center ml-4 font-bold text-sm">
                          {row.text}
                        </div>
                        <div
                          className={`w-6 h-6 rounded-full border flex justify-center items-center ${
                            isChecked
                              ? 'border-transparent bg-eventsMain'
                              : 'border-gray-400 bg-transparent'
                          }`}
                        >
                          {isChecked && (
                            <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                          )}
                        </div>
                      </div>
                    )
                  })}
                  {teacherUserRoles?.map((userRole) => {
                    let isChecked = newEvent.eventResponsible?.find((evRe) =>
                      evRe.includes(userRole.id),
                    )
                    return (
                      <div
                        className="flex flex-row cursor-pointer"
                        onClick={() => {
                          /*
                          setSelectedUserRoles((prevSelectedUserRoles) => {
                            if (isChecked) {
                              return prevSelectedUserRoles.filter(
                                (sel) => sel !== userRole.id,
                              )
                            } else {
                              return [...prevSelectedUserRoles, userRole.id]
                            }
                          })*/
                          let selected = newEvent.eventResponsible?.find(
                            (evRe) =>
                              evRe.includes('text:') || evRe.includes('role:'),
                          )
                            ? newEvent.eventResponsible
                            : []
                          if (isChecked) {
                            selected = selected?.filter(
                              (sel) => !sel.includes(userRole.id),
                            )
                          } else {
                            selected.push('role:' + userRole.id)
                          }
                          onChange({ ...newEvent, eventResponsible: selected })
                        }}
                      >
                        <img
                          className={
                            'w-[40px] h-[40px] rounded-full object-cover shadow  '
                          }
                          src={getImageFromRole(userRole)}
                          alt=""
                        />
                        <div className="flex flex-1 items-center ml-4 font-bold text-sm">
                          {userRole.user?.firstName +
                            ' ' +
                            userRole.user?.lastName}
                        </div>
                        <div
                          className={`w-6 h-6 rounded-full border flex justify-center items-center ${
                            isChecked
                              ? 'border-transparent bg-eventsMain'
                              : 'border-gray-400 bg-transparent'
                          }`}
                        >
                          {isChecked && (
                            <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="absolute h-[5vh] bg-gradient-to-t from-white to-[rgba(255,255,255,0)] bottom-0 left-0 w-full"></div>
              </div>
            </>
          ) : (
            <div className="flex justify-between p-2 my-4 items-center">
              <div>
                <b>{t('general.pinToNewsBanner')}</b>
                <br />
                <small>{t('general.newsBannerOnStartScreen')}</small>
              </div>
              <div>
                <Slider
                  id={0}
                  value={newEvent.isPinned}
                  onSliderClick={() => {
                    onChange({ ...newEvent, isPinned: !newEvent.isPinned })
                  }} // Assuming this is the correct prop for handling clicks
                  onBoxClick={() => {}}
                />
              </div>
            </div>
          )}
          <div className="flex flex-col justify-center gap-y-3 mb-2">
            <div className="flex justify-between gap-x-3 ">
              <Button
                label={t('general.publishWithoutPush')}
                variant="darkBorderAndText"
                size="sm"
                onClick={() => {
                  if (dataAction?.eventTimeId && dataAction.eventGroupInfoId) {
                    editEvent(
                      dataAction?.eventTimeId,
                      dataAction.eventGroupInfoId,
                      newEvent.topic,
                      newEvent.content,
                      newEvent.files,
                      newEvent.fileIds,
                      newEvent.tagIds,
                      newEvent.location?.id,
                      newEvent.maxParticipants,
                      newEvent.depIds,
                      newEvent.times?.map((time) => JSON.stringify(time)),
                      newEvent.onlyStaff,
                    )
                  } else {
                    createEvent(newEvent, false)
                      .catch(() => {
                        throw new Error()
                      })
                      .then(() => {
                        onChange(initialValues)
                        Swal.fire({
                          icon: 'success',
                          text: t('events.eventCreatedSuccessMesage'),
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                        onSave()
                      })
                      .finally(() => {
                        setShowModal(false)
                      })
                  }
                }}
              />
              <Button
                variant="fullBlue"
                label={t('general.publishWithPush')}
                size="sm"
                onClick={() => {
                  createEvent(newEvent, true)
                    .catch(() => {
                      throw new Error()
                    })
                    .then(() => {
                      onChange(initialValues)
                      Swal.fire({
                        icon: 'success',
                        text: t('events.eventCreatedSuccessMesage'),
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      })
                      onSave()
                    })
                    .finally(() => {
                      setShowModal(false)
                    })
                }}
              />
            </div>
          </div>
        </div>
      </ModalRegular>
    </div>
  )
}
