import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { Button } from '../../../components/Button'
import { CreateEventParams } from '../../../store/event3Store'
import { Tag } from '../../../Models/Tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import { faBookmark } from '@fortawesome/pro-solid-svg-icons'
import { School } from '../../../Models/School'

export enum LOCATION_TYPE {
  FREE_TEXT = 'freetext',
  COORD = 'coord',
  ADDRESS = 'address',
  SAVED = 'saved',
}

interface Props {
  newEvent: CreateEventParams
  schools?: School[]
  tags: Tag[]
  onChange: (e: CreateEventParams) => void
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
  onClick: () => void
}

interface Props2 {
  newEvent: CreateEventParams
  schools?: School[]
  tags: Tag[]
  onChange: (e: CreateEventParams) => void
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
}

export const TagsToEventSelections: React.FC<Props2> = (props: Props2) => {
  return (
    <div className="">
      <h4 className="bold font-semibold flex justify-center items-center w-full pb-2">
        <>{t('events.selectTag')}</>
      </h4>
      <div className="flex text-sm font-semibold text-gray-500 px-14 pb-3">
        <>{t('events.tagSelectingDescribtion')}</>
      </div>
      {props.newEvent.multiSchools ? (
        <>
          {props.newEvent.multiSchools.map((multiSchoolId) => {
            return (
              <div className="flex flex-col gap-y-1">
                <div className="flex-1 w-full mt-2">
                  <b>
                    {
                      props.schools?.find(
                        (school) => school.id === multiSchoolId,
                      )?.display_school_name
                    }
                  </b>
                </div>
                <div className="cursor-pointer">
                  {props.tags
                    .filter((tag) => tag.school.id === multiSchoolId)
                    .map((tag) => {
                      return (
                        <div
                          className="rounded-xl  border-2 px-2 py-1 text-2xs gap-x-2 flex m-1 cursor-pointer float-left"
                          style={
                            props.newEvent.tagIds?.find(
                              (tagId) => tagId === tag.id,
                            )
                              ? { borderColor: tag.color }
                              : {}
                          }
                          onClick={(e) => {
                            e.stopPropagation()
                            let chosenTags = props.newEvent.tagIds

                            if (chosenTags?.find((tagId) => tagId === tag.id)) {
                              // Remove from array
                              chosenTags = chosenTags.filter(
                                (tagId) => tagId !== tag.id,
                              )
                              props.onChange({
                                ...props.newEvent,
                                tagIds: chosenTags,
                              })
                            } else {
                              // Add to array
                              //chosenTags?.push(tag.id)
                              if (
                                props.newEvent.multiSchools &&
                                chosenTags?.length
                              ) {
                                // se om tagg redan finns på skolan

                                let add = true
                                let tagToRemove = ''

                                const schoolIdForTag = tag.school.id

                                for (const tagId of chosenTags) {
                                  const tagInLoop = props.tags.find(
                                    (t) => t.id === tagId,
                                  )

                                  if (tagInLoop?.school.id === schoolIdForTag) {
                                    add = false
                                    tagToRemove = tagId
                                  }
                                }

                                if (add) {
                                  chosenTags?.push(tag.id)
                                  props.onChange({
                                    ...props.newEvent,
                                    tagIds: chosenTags,
                                  })
                                } else {
                                  chosenTags = chosenTags.filter(
                                    (tagId) => tagId !== tagToRemove,
                                  )
                                  chosenTags?.push(tag.id)
                                  props.onChange({
                                    ...props.newEvent,
                                    tagIds: chosenTags,
                                  })
                                }
                              } else {
                                chosenTags = [] // Remove this in the future if able to have more than one
                                chosenTags?.push(tag.id)
                                props.onChange({
                                  ...props.newEvent,
                                  tagIds: chosenTags,
                                })
                              }
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            color={tag.color}
                            icon={faBookmark}
                            size="lg"
                          />
                          {tag.name}
                        </div>
                      )
                    })}
                </div>
              </div>
            )
          })}
        </>
      ) : (
        <>
          <div className="w-full flex whitespace-nowrap overflow-x-auto hide-scrollbar p-4">
            {props.tags.length > 0 &&
              props.tags.map((tag) => {
                const isSelected = props.newEvent.tagIds?.find(
                  (tagId) => tagId === tag.id,
                )
                const borderColor = isSelected
                  ? tag.color ?? '#565656'
                  : '#d1d5db' // Använder Tailwind-grå färg som fallback

                return (
                  <div
                    key={tag.id} // Glöm inte lägga till en key när du mapar över en lista
                    className={`rounded-full cursor-pointer border-2 px-2 py-1 text-xs gap-x-2 flex items-center justify-center m-1 float-left
                    ${isSelected ? 'border-blue-500' : ''}`}
                    style={{
                      borderColor: borderColor, // Sätter border-färg baserat på om den är vald eller ej
                    }}
                    onMouseEnter={(e) => {
                      e.stopPropagation()
                      e.currentTarget.style.borderColor = tag.color ?? '#565656' // Ändrar border-färg vid hover
                    }}
                    onMouseLeave={(e) => {
                      e.stopPropagation()
                      e.currentTarget.style.borderColor = borderColor // Återställer border-färg när musen lämnar
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      let chosenTags = props.newEvent.tagIds
                      if (chosenTags?.find((tagId) => tagId === tag.id)) {
                        chosenTags = chosenTags.filter(
                          (tagId) => tagId !== tag.id,
                        )
                      } else {
                        chosenTags = [tag.id]
                      }
                      props.onChange({
                        ...props.newEvent,
                        tagIds: chosenTags,
                      })
                    }}
                  >
                    {/* FontAwesomeIcon och tagg namnet */}
                    <FontAwesomeIcon
                      // Sätt ikonens färg baserat på taggens färg
                      style={{ color: tag.color ?? '#565656' }}
                      icon={faTag}
                      size="lg"
                      flip="horizontal"
                    />
                    {tag.name}
                  </div>
                )
              })}
          </div>
          {props.tags.length === 0 && (
            <div className="flex flex-wrap break-words w-full px-14">
              <>{t('events.noTagsAdded')}</>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export const TagsToEvent: React.FC<Props> = observer((props: Props) => {
  const [openSelections, setOpenSelections] = useState(false)

  const selectedTagsComponents = props.newEvent.tagIds?.map((tagId) => {
    const tag = props.tags.find((t) => t.id === tagId)

    if (!tag) return null

    return (
      <div
        key={tag.id}
        className="rounded-full items-center border-2 px-2 py-1 text-2xs gap-x-1 flex m-1"
        style={{ borderColor: tag.color, color: '#565656' }}
      >
        <FontAwesomeIcon
          className="w-3 h-3"
          icon={faTag}
          color={tag.color}
          flip="horizontal"
        />{' '}
        <span className="text-xs">{tag.name}</span>
      </div>
    )
  })

  return (
    <>
      <div
        onClick={() => setOpenSelections(!openSelections)}
        className="w-full"
      >
        <div className="flex justify-between gap-y-2 items-center">
          <div className="font-bold flex items-center gap-x-2">
            <FontAwesomeIcon
              icon={faTag}
              className="text-[#FF8E8D] text-xl p-1"
              flip="horizontal"
            />
            <>{t('general.tags')}:</>{' '}
          </div>
          <Button
            label={t('blog.edit')}
            variant="darkBorderAndText"
            size="xs"
            onClick={() => props.onClick()}
          />
        </div>
        <div className="flex pl-2 mt-1">
          {props.newEvent.tagIds && props.newEvent.tagIds.length > 0 ? (
            <div className="flex flex-wrap items-center">
              {!!selectedTagsComponents && selectedTagsComponents}
            </div>
          ) : (
            <>{t('events.noTagsChoosen')}</>
          )}
        </div>
      </div>
    </>
  )
})
