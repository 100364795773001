import React, { useContext, useEffect, useState } from 'react'
import Page from '../../../../components/Page'
import { t } from 'i18next'
import ColorCard from '../../../../components/ColorCard'
import { USER_ROLE_TYPE } from '../../../../Enums/TyraEnums'
import { UserRole } from '../../../../Models/UserRole'
import { AdminStoreContext } from '../../../../store/adminStore'
import { NavigationStoreContext } from '../../../../store/navigationStore'
import { useParams } from 'react-router-dom'
import { TeacherCard } from '../components/TeacherCard'
import { VHCard } from '../components/VHCard'
import { observer } from 'mobx-react'
import TeacherModal from '../components/TeacherModal'
import VHModal from '../components/VHModal'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { Loading } from '../../../../components/Loading'

export const ActiveUsers: React.FC<{ onClick?: any }> = observer(
  ({ onClick }) => {
    const [loading, setLoading] = useState(false)
    const [showActiveUsersModal, setShowActiveUsersModal] =
      useState<UserRole | null>(null)
    const [showActiveUsersVHModal, setShowActiveUsersVHModal] =
      useState<UserRole | null>(null)
    const [cardMode, setCardMode] = useState('users') // 'users' or 'vh'
    const { fetchDepartments } = useContext(DepartmentStoreContext)
    const { setTitle } = useContext(NavigationStoreContext)
    const { fetchActiveUserRoles, activeUserRoles } =
      useContext(AdminStoreContext)

    const { schoolId } = useParams()

    useEffect(() => {
      setTitle(t('admin.activeUsers'))
      if (schoolId) {
        setLoading(true)
        fetchActiveUserRoles(schoolId)
        fetchDepartments(schoolId).finally(() => {
          setLoading(false)
        })
      }
    }, [schoolId])
    const roleTypeForCardMode =
      cardMode === 'users' ? USER_ROLE_TYPE.TEACHER : USER_ROLE_TYPE.PARENT
    /*const activeTeacherUsers = newUserRoles?.filter(
    (userRole: UserRole) =>
      userRole.role_type === USER_ROLE_TYPE.TEACHER &&
      userRole.status === USER_ROLE_STATUS.ACTIVE,
  )
  console.log('läraa', activeTeacherUsers)
  const activeParentUsers = newUserRoles?.filter(
    (userRole: UserRole) =>
      userRole.role_type === USER_ROLE_TYPE.PARENT &&
      userRole.status === USER_ROLE_STATUS.ACTIVE,
  )*/

    /*const showModal =
    cardMode === 'users' ? setShowActiveUsersModal : setShowActiveUsersVHModal
  const ModalComponent =
    cardMode === 'users' ? ActiveUsersModal : ActiveUsersVHModal
  console.log('föräldrar', activeParentUsers)*/
    let renderedVhs: string[] = []
    return (
      <Page naked>
        {loading && <Loading />}
        <ColorCard
          bgColor="bg-pink-200 p-4 justify-center flex"
          title={t('documents.staff')}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 ml-5">
            {activeUserRoles && activeUserRoles.length ? (
              activeUserRoles
                .filter(
                  (userRole) => userRole.role_type > USER_ROLE_TYPE.PARENT,
                )
                .map((userRole) => (
                  <>
                    <TeacherCard
                      onClick={() => {
                        setShowActiveUsersModal(userRole)
                      }}
                      title=""
                      userRole={userRole}
                      type="active"
                    />
                  </>
                ))
            ) : (
              <div className="col-span-full place-items-center">
                <div className="p-10 text-center">
                  <>{t('documents.empty')}</>
                </div>
              </div>
            )}
            {showActiveUsersModal && (
              <TeacherModal
                isOpen={showActiveUsersModal ? true : false}
                onClose={() => {
                  setShowActiveUsersModal(null)
                }}
                userRole={showActiveUsersModal}
                type="active"
              />
              /*<ActiveUsersModal
                  isOpen={showActiveUsersModal ? true : false}
                  onClose={() => {
                    setShowActiveUsersModal(null)
                  }}
                  userRole={showActiveUsersModal}
                />*/
            )}
          </div>
        </ColorCard>
        <ColorCard
          bgColor="bg-pink-200 p-4 justify-center flex"
          title={t('documents.parent')}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 ml-5">
            {activeUserRoles && activeUserRoles.length ? (
              activeUserRoles
                .filter(
                  (userRole) => userRole.role_type === USER_ROLE_TYPE.PARENT,
                )
                .map((userRole) => {
                  if (renderedVhs.includes(userRole.user?.id ?? 'xyz')) {
                    return null
                  }
                  if (userRole.user) {
                    renderedVhs.push(userRole.user.id)
                  }

                  return (
                    <>
                      <VHCard
                        onClick={() => {
                          setShowActiveUsersVHModal(userRole)
                        }}
                        title=""
                        userRole={userRole}
                        type="active"
                        allUserRolesForUser={activeUserRoles.filter(
                          (uR) => uR.user?.id === userRole.user?.id,
                        )}
                      />
                    </>
                  )
                })
            ) : (
              <div className="col-span-full place-items-center">
                <div className="p-10 text-center">
                  <>{t('documents.empty')}</>
                </div>
              </div>
            )}
          </div>
          {showActiveUsersVHModal && (
            <VHModal
              isOpen={showActiveUsersVHModal ? true : false}
              onClose={() => {
                setShowActiveUsersVHModal(null)
              }}
              userRole={showActiveUsersVHModal}
              allUserRolesForUser={
                activeUserRoles?.filter(
                  (uR) => uR.user?.id === showActiveUsersVHModal.user?.id,
                ) ?? []
              }
              type="active"
            />
            /*<ActiveUsersVHModal
                isOpen={showActiveUsersVHModal ? true : false}
                onClose={() => {
                  setShowActiveUsersVHModal(null)
                }}
                userRole={showActiveUsersVHModal}
                allUserRolesForUser={
                  activeUserRoles?.filter(
                    (uR) => uR.user?.id === showActiveUsersVHModal.user?.id,
                  ) ?? []
                }
              />*/
          )}
        </ColorCard>
      </Page>
    )
  },
)
