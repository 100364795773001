import React, { useContext, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import { useNavigate } from 'react-router-dom'
import { ExportStoreContext } from '../../store/exportStore'
import { UserStoreContext } from '../../store/userStore'
import { PDFExportRoutes } from './skaExport'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faUser } from '@fortawesome/free-solid-svg-icons'
import { useClickOutside } from '../../hooks/useClickoutside'
import { ButtonDropdown } from '../../components/Dropdown'
import Swal from 'sweetalert2'
import { Loading } from '../../components/Loading'
import { t } from 'i18next'
import { NavigationStoreContext } from '../../store/navigationStore'

export const Utbildning: React.FC = observer(({}) => {
  const navigate = useNavigate()
  const { getSkaOperationsPlanning, skaOperationsPlanning } =
    useContext(ExportStoreContext)
  const [loading, setLoading] = useState(true)

  const { currentUserRole } = useContext(UserStoreContext)
  useEffect(() => {
    if (currentUserRole) {
      getSkaOperationsPlanning(currentUserRole.school_pointer.id).finally(
        () => {
          setLoading(false)
        },
      )
    }
  }, [currentUserRole])
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('documentation.operation'))
  }, [t])

  return (
    <Page>
      {loading && <Loading />}

      <div className="flex flex-col">
        <h1 className="flex justify-center py-4 text-3xl font-medium bg-eventsMain text-white">
          <>{t('documentation.operation')}</>
        </h1>
        <div className="py-10">
          {skaOperationsPlanning.map((item: any) => {
            return (
              <>
                {' '}
                <div className="flex flex-col w-full justify-center items-center">
                  <div
                    className="p-4 border shadow  text-center cursor-pointer w-[300px] rounded-full bg-blue-50 hover:bg-blue-100 my-2"
                    onClick={() => {
                      navigate(
                        '/pdf-export/' +
                          PDFExportRoutes.UTBILDNING +
                          '/' +
                          item.id,
                      )
                    }}
                  >
                    {item.attributes.name}
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </Page>
  )
})
