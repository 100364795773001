import ModalRegular from '../../../components/ModalRegular'
import React from 'react'

export const FullscreenModal = ({
  src,
  showModal,
  hideModal,
}: {
  src: any
  showModal: boolean
  hideModal: any
}) => {
  return (
    <ModalRegular
      title={''}
      isOpen={showModal}
      onClose={() => {
        hideModal()
      }}
      closeColor="white"
      bgColor="p-7 bg-eventsMain"
    >
      <div className="flex justify-center items-center w-full h-[90vh] p-4 ">
        <img
          className="max-w-full max-h-full rounded-2xl shadow object-cover"
          src={src}
          alt=""
        />
      </div>
    </ModalRegular>
  )
}
