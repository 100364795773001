import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { Spinner } from '../../components/Spinner'
import Page from '../../components/Page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '../../components/Button'
import { UserStoreContext } from '../../store/userStore'
import { BlogGrid } from '../portfolio/components/BlogGrid'
import { FullscreenModal } from '../portfolio/components/FullScreenModal'
import { PortfolioBlogStoreContext } from '../../store/portfolioBlogStore'
import { DepartmentStoreContext } from '../../store/departmentStore'
import { Department } from '../../Models/Department'
import Multiselect from '../../components/Multiselect'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useTranslation } from 'react-i18next'
import { Tags } from './components/Tags'
import { DocTag } from '../../Models/DocTag'
import { DateChooser } from './components/DateChooser'
import { LoadMoreButton } from '../../components/LoadMoreButton'
import moment from 'moment'
import {
  faAddressCard,
  faFileExport,
  faImage,
  faList,
} from '@fortawesome/free-solid-svg-icons'
import {
  faEllipsisVertical,
  faFaceZipper,
  faFilePdf,
  faFileZipper,
  faGrid,
  faGrid2,
  faPrint,
  faTrashCan,
  faFolder,
} from '@fortawesome/pro-solid-svg-icons'
import MultiSelectOpen from '../../components/MultiSelectOpen'
import { Loading } from '../../components/Loading'
import { ExportStoreContext } from '../../store/exportStore'
import { AdminStoreContext } from '../../store/adminStore'
import { ButtonDropdown } from '../../components/Dropdown'
import { useClickOutside } from '../../hooks/useClickoutside'
import ExportModal from '../portfolio/components/ExportModal'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import { FileJobStoreContext } from '../../store/fileJobStore'
import { LoadMoreButtonOnClick } from '../../components/LoadMoreButtonOnClick'

export const Blog: React.FC = observer(() => {
  let { schoolId } = useParams()
  const { t } = useTranslation()
  const {
    fetchDepartments,
    departments,
    setSelectedDepartments,
    selectedDepartments,
  } = useContext(DepartmentStoreContext)
  const { fetchActiveUserRoles, activeUserRoles } =
    useContext(AdminStoreContext)

  /* FOR NEW EXPORT */
  const { exportBlogPortfolio } = useContext(ExportStoreContext)
  /* END */
  /*const [selectedDepartments, setSelectedDepartments] = useState<Department[]>(
    [],
  )*/
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('menu.blog'))
  }, [t])
  const [changeView, setChangeView] = useState(false)
  const [changeGrid, setChangeGrid] = useState(false)
  const [showOnlyImages, setShowOnlyImages] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [openExportModal, setOpenExportModal] = useState(false)
  const [startDate, setStartDate] = useState<Date>(
    new Date(moment().subtract(3, 'months').format('YYYY-MM-DD')),
  )
  const [endDate, setEndDate] = useState<Date>(
    new Date(moment().format('YYYY-MM-DD')),
  )

  const ref: any = useRef<HTMLDivElement>()

  const [skip, setSkip] = useState<number>(0)
  //const [selectedTags, setSelectedTags] = useState<DocTag[]>([])
  const [showFullScreenModal, setShowFullScreenModal] = useState(false)
  const {
    fetchBlog,
    bloggDocs,
    bloggDocsCount,
    setSelectedTags,
    selectedTags,
    fetchReflections,
    blogDocReflections,
  } = useContext(PortfolioBlogStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  const { downloadAndZip } = useContext(FileJobStoreContext)
  const isTeacher =
    currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
      ? true
      : false

  const isAdmin =
    currentUserRole && currentUserRole?.role_type >= USER_ROLE_TYPE.ADMIN
      ? true
      : false
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (currentUserRole) {
      setLoading(true)
      fetchBlog({
        schoolId: schoolId ?? '',
        departmentPointers: selectedDepartments?.length
          ? selectedDepartments
          : undefined,
        selectedTags,
        startDate,
        endDate,
        skip,
        currentUserRole,
      }).finally(() => setLoading(false))
    }
  }, [schoolId, selectedDepartments, selectedTags, skip])

  useEffect(() => {
    if (currentUserRole) {
      setLoading(true)
      setSkip(0)
      fetchBlog({
        schoolId: schoolId ?? '',
        departmentPointers: selectedDepartments?.length
          ? selectedDepartments
          : undefined,
        selectedTags,
        startDate,
        endDate,
        skip,
        currentUserRole,
      }).finally(() => setLoading(false))
    }
  }, [startDate, endDate, currentUserRole])

  useEffect(() => {
    if (bloggDocs && bloggDocs?.length > 0) {
      fetchReflections(bloggDocs?.map((doc) => doc.id))
    }
  }, [bloggDocs])

  useEffect(() => {
    fetchDepartments(schoolId ?? '', true)
  }, [schoolId])

  useEffect(() => {
    if (schoolId) {
      fetchActiveUserRoles(schoolId)
    }
  }, [schoolId, currentUserRole])
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }
  const [menuRef, togglerRef] = useClickOutside(() => {
    setDropdownVisible(false)
  })
  const [modalType, setModalType] = useState<
    'All' | 'OnlySliders' | 'OnlySelectDate' | null
  >(null)
  const [buttonType, setButtonType] = useState<'Print' | 'Generate'>()

  const handlePrintClick = () => {
    setButtonType('Print')
    setOpenExportModal(true)
  }
  const handleGeneratePdfClick = () => {
    setButtonType('Generate')
    setOpenExportModal(true)
  }
  const loadMorePosts = () => {
    setLoading(true)
    setSkip((prevSkip) => prevSkip + 10)
  }
  return (
    <Page
      clearFilter={() => {
        setSelectedDepartments([])
        setSelectedTags([])
      }}
      filterCount={
        (selectedDepartments?.length ? selectedDepartments.length : 0) +
        (selectedTags?.length ? selectedTags.length : 0)
      }
      filter={
        <div className="flex items-center justify-center">
          <div className="flex items-center flex-col gap-2">
            <div className="font-semibold">
              <>{t('blog.chooseDate')}</>
            </div>
            <DateChooser
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              onChangeDate={(newStartDate: Date, newEndDate: Date) => {
                setStartDate(newStartDate)
                setEndDate(newEndDate)
              }}
            />
            <div className="flex flex-row gap-2 w-fit">
              {currentUserRole && !currentUserRole.kid ? (
                <MultiSelectOpen
                  onChange={(items) => {
                    setSkip(0)
                    setSelectedDepartments(items)
                  }}
                  alwaysShow={true}
                  items={(departments as Department[]) ?? []}
                  initialSelectedItems={selectedDepartments}
                  itemVariableKey={'klassName'}
                  placeholder={t('general.department')}
                ></MultiSelectOpen>
              ) : null}
              <Tags
                onSelectedTags={(tags: DocTag[]) => {
                  setSkip(0)
                  setSelectedTags(tags)
                }}
                initialSelectedTags={selectedTags}
              />
            </div>
          </div>
        </div>
      }
      topBar={
        <>
          <div className=" flex flex-row gap-x-4">
            {showOnlyImages ? (
              <Button
                variant={'gridButton'}
                icon={changeView ? faGrid2 : faGrid}
                onClick={() => {
                  setChangeView(!changeView)
                }}
              />
            ) : (
              <Button
                variant={'gridButton'}
                icon={changeView ? faGrid : faList}
                onClick={() => {
                  setChangeView(!changeView)
                }}
              />
            )}
            <Button
              icon={showOnlyImages ? faAddressCard : faImage}
              size="lg"
              variant={'pictureButton'}
              title={
                showOnlyImages ? t('blog.showCards') : t('blog.showImages')
              }
              onClick={() => {
                setChangeView(false)
                setShowOnlyImages(!showOnlyImages)
              }}
            />{' '}
            {isTeacher && (
              <div className="relative items-center flex">
                <button
                  type="button"
                  className="w-full rounded-md group focus:outline-none "
                  aria-haspopup="true"
                  aria-expanded={dropdownVisible}
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleDropdown()
                  }}
                  ref={togglerRef}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="text-gray-400 px-4 hover:scale-110 "
                  ></FontAwesomeIcon>
                </button>

                {dropdownVisible && (
                  <div
                    className="absolute right-5 top-3 z-50 mx-3 origin-top divide-y font-bold w-[280px]"
                    style={{
                      display: dropdownVisible ? 'block' : 'none',
                    }}
                    ref={menuRef}
                  >
                    <ButtonDropdown.Menu>
                      <ButtonDropdown.Section>
                        <>
                          <ButtonDropdown.Button
                            icon={faPrint}
                            label={t('blog.print')}
                            className="text-purple-300 "
                            onClick={(e) => {
                              e.stopPropagation()
                              setOpenExportModal(true)
                              handlePrintClick()
                            }}
                          />
                          <ButtonDropdown.Button
                            icon={faFilePdf}
                            label={t('blog.generatePDF')}
                            className="text-green-300"
                            onClick={(e) => {
                              e.stopPropagation()
                              setOpenExportModal(true)
                              handleGeneratePdfClick()
                            }}
                          />
                          <>
                            <ButtonDropdown.Button
                              icon={faFolder}
                              label={t('blog.exportMedia')}
                              className="text-blue-300"
                              onClick={(e) => {
                                setLoading(true)
                                downloadAndZip(
                                  bloggDocs ?? [],
                                  'blogg',
                                ).finally(() => {
                                  setLoading(false)
                                })
                                e.stopPropagation()
                              }}
                            />
                          </>
                        </>
                      </ButtonDropdown.Section>
                    </ButtonDropdown.Menu>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      }
    >
      <div className={'flex flex-col justify-center items-center px-3 pb-3'}>
        {loading && <Loading />}
        {schoolId && openExportModal && (
          <ExportModal
            typeOfDoc="Blog"
            buttonType={buttonType}
            type={modalType ? modalType : 'All'}
            isOpen={openExportModal}
            bloggDocs={bloggDocs}
            onClose={() => {
              setOpenExportModal(false)
              setModalType(null)
            }}
            onImageSelected={undefined}
            selectedBlogDocId={''}
            schoolId={schoolId}
            onChangeStartDate={(date) => setStartDate(date)}
            onChangeEndDate={(date) => setEndDate(date)}
            startDate={startDate}
            endDate={endDate}
          />
        )}

        {bloggDocs?.length === 0 && (
          <div
            className={
              'flex flex-col text-center justify-center items-center space-y-4 text-lg sm:text-2xl text-gray-300 font-bold my-44'
            }
          >
            <p>{t('blog.empty')}</p>
          </div>
        )}

        <BlogGrid
          type={'Blog'}
          bloggDocs={bloggDocs ?? []}
          onImageSelected={(src: string) => {
            setSelectedImage(src)
            setShowFullScreenModal(true)
          }}
          showOnlyImages={showOnlyImages}
          changeView={changeView}
          reflections={blogDocReflections}
          onSaveReflections={() => {
            if (bloggDocs) {
              fetchReflections(bloggDocs?.map((doc) => doc.id))
            }
            if (currentUserRole) {
              fetchBlog({
                schoolId: schoolId ?? '',
                departmentPointers: selectedDepartments?.length
                  ? selectedDepartments
                  : departments,
                selectedTags: selectedTags,
                startDate: startDate,
                endDate: endDate,
                skip: skip,
                currentUserRole,
              }).finally(() => {
                setLoading(false)
              })
            }
          }}
        />
        {(bloggDocs?.length || 0) < (bloggDocsCount || 0) ? (
          <div className="pt-4">
            <LoadMoreButton
              loadMore={loadMorePosts}
              loading={loading}
              needMore={(bloggDocs?.length || 0) < (bloggDocsCount || 0)}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      <FullscreenModal
        src={selectedImage}
        showModal={showFullScreenModal}
        hideModal={() => {
          setShowFullScreenModal(false)
        }}
      />
    </Page>
  )
})
