import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../../components/Button'
import {
  faChevronDown,
  faPlus,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons'
import { t } from 'i18next'
import { Department } from '../../../../Models/Department'
import { SchoolStoreContext } from '../../../../store/schoolStore'
import Parse from 'parse'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import CustomInput from '../../../../components/CustomInput'
import { ImageUploader } from '../../../../components/ImageUploader'
import { faUser } from '@fortawesome/pro-solid-svg-icons'

interface Props {
  contact: Department
}
interface EditContact {
  klassName: string
  lastName: string
  contactPic: any | null
  workTitle: string
  teleNumber: string
  otherMobNum: string
  email: string
  adress: string
}

const ContactCard: React.FC<Props> = (props: Props) => {
  const [expanded, setExpanded] = useState(false)
  const titleNameInputRef = useRef<HTMLInputElement>(null)

  const nameInputRef = useRef<HTMLInputElement>(null)

  const surNameInputRef = useRef<HTMLInputElement>(null)
  const numberInputRef = useRef<HTMLInputElement>(null)
  const otherNumberInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const adressInputRef = useRef<HTMLInputElement>(null)
  const areaCodeInputRef = useRef<HTMLInputElement>(null)

  const cityInputRef = useRef<HTMLInputElement>(null)

  const [fakeState, setFakeState] = useState('')
  const [editContact, setEditContact] = useState<EditContact>()

  const { editContactPerson, getContactPersons, deleteContact } =
    useContext(SchoolStoreContext)
  const { schoolId } = useParams()
  const [touched, setTouched] = useState(false)
  useEffect(() => {
    if (schoolId) {
      getContactPersons(schoolId)
    }
  }, [schoolId])
  const handleBoxClick = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).nodeName !== 'INPUT') {
      setTouched(true)
      setAnimating(true)
      setExpanded((prevState) => !prevState)
      setTimeout(() => {
        setAnimating(false)
      }, 800)
    }
    if (expanded) {
      getContactPersons(schoolId!)
    }
  }

  const uploadImageToParse = useCallback(async (file: File) => {
    var extension = file.name.split('.').pop()
    var parseImageFile = new Parse.File(`tyraimage.${extension}`, file)
    await parseImageFile.save()
    return parseImageFile
  }, [])
  const handleFileChange = async (file: File) => {
    if (file) {
      const parseFile = await uploadImageToParse(file)

      props.contact.set('contactPic', parseFile)
      props.contact.set('contactPic_thumb', parseFile)
    }
  }
  const focusInput = (
    e: React.MouseEvent,
    inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e.stopPropagation()
    inputRef.current?.focus()
  }
  const [animating, setAnimating] = useState(false)
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  return (
    <div className="flex flex-wrap flex-col w-full items-center gap-y-2">
      <div
        className={`py-5 relative w-[80%] border shadow-md mt-3 p-3 bg-gray-50 rounded-2xl transition-all ease-in-out delay-10 hover:scale-[102%] ${
          touched
            ? expanded
              ? 'animate-expandingContact hover:scale-[100%]'
              : 'animate-collapsingContact'
            : ''
        }`}
      >
        <div className="flex flex-row items-center justify-between">
          <div className="absolute top-11 left-3">
            {expanded ? (
              <div>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="text-red-500 cursor-pointer hover:scale-105"
                  onClick={(e) =>
                    Swal.fire({
                      text: t('admin.deleteContactWarning'),
                      icon: 'warning',
                      showCancelButton: true,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        await deleteContact(props.contact)
                      }

                      if (schoolId) {
                        handleBoxClick(e)
                      }
                    })
                  }
                />
              </div>
            ) : (
              ''
            )}
          </div>
          {!expanded ? (
            <div className="flex flex-row items-center ">
              <div
                className=" relative flex items-center justify-center border rounded-full max-w-[40px]  max-h-[40px]  min-w-[40px]  min-h-[40px] overflow-hidden"
                style={
                  props.contact.contactPic
                    ? {
                        backgroundImage: `url(${props.contact.contactPic?._url})`,
                        backgroundSize: 'cover',
                      }
                    : {}
                }
              >
                {!props.contact.contactPic && (
                  <FontAwesomeIcon
                    icon={faUser}
                    className="text-gray-300 h-8 w-8 absolute -bottom-[1px]"
                  />
                )}
              </div>
              <div>
                <h2 className="ml-2 font-bold text-gray-600">
                  {props.contact.klassName + ' ' + props.contact.lastName}
                </h2>
                <h2 className="ml-2 text-gray-400 font-light">
                  {props.contact.workTitle}
                </h2>
              </div>
            </div>
          ) : (
            <div className="flex flex-row justify-center w-full">
              <ImageUploader
                onImageChange={handleFileChange}
                initialImageUrl={props.contact.contactPic?._url}
              />
            </div>
          )}

          <div>
            {expanded ? (
              <div className={'flex-1 flex justify-end cursor-pointer'}>
                <FontAwesomeIcon
                  className="animate-rotate180 rotate-180"
                  icon={faChevronDown}
                  size={'sm'}
                  onClick={(e) => {
                    handleBoxClick(e)
                    props.contact.revert()
                  }}
                />
              </div>
            ) : (
              <div className={'flex-1 flex justify-end cursor-pointer'}>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size={'sm'}
                  onClick={(e) => {
                    handleBoxClick(e)
                    props.contact.revert()
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {expanded && (
          <div
            className="flex flex-col gap-y-4 transition-opacity duration-200 ease-in"
            style={{
              opacity: animating ? 0 : 1,
            }}
          >
            <CustomInput
              label={t('admin.title')}
              value={props.contact.workTitle}
              onChange={(value) => {
                props.contact.set('workTitle', value)
                setFakeState(value)
              }}
              required={props.contact.workTitle === ''}
              placeholder={t('admin.title')}
              ref={titleNameInputRef}
            />
            <CustomInput
              label={t('admin.firstName')}
              value={props.contact.klassName}
              onChange={(value) => {
                props.contact.set('klassName', value)
                setFakeState(value)
              }}
              required={props.contact.klassName === ''}
              placeholder={t('admin.firstName')}
              ref={nameInputRef}
            />
            <CustomInput
              label={t('admin.surName')}
              value={props.contact.lastName}
              onChange={(value) => {
                props.contact.set('lastName', value)
                setFakeState(value)
              }}
              required={props.contact.lastName === ''}
              placeholder={t('admin.surName')}
              ref={surNameInputRef}
            />
            <CustomInput
              label={t('ourKids.phone')}
              value={props.contact.teleNumber}
              onChange={(value) => {
                props.contact.set('teleNumber', value)
                setFakeState(value)
              }}
              format="default"
              //required={true}
              onlyNumbers={true}
              placeholder={t('ourKids.phone')}
              ref={numberInputRef}
            />
            <CustomInput
              label={t('admin.otherNumber')}
              value={props.contact.otherMobNum}
              onChange={(value) => {
                props.contact.set('otherMobNum', value)
                setFakeState(value)
              }}
              format="default"
              //required={true}
              onlyNumbers={true}
              placeholder={t('admin.otherNumber')}
              ref={otherNumberInputRef}
            />
            <CustomInput
              label={t('login.email')}
              value={props.contact.email}
              onChange={(value) => {
                props.contact.set('email', value)
                setFakeState(value)
              }}
              format="default"
              //required={true}
              placeholder={t('login.email')}
              ref={emailInputRef}
            />
            <CustomInput
              label={t('contactList.adress')}
              value={props.contact.adress}
              onChange={(value) => {
                props.contact.set('adress', value)
                setFakeState(value)
              }}
              format="default"
              //required={true}
              placeholder={t('contactList.adress')}
              ref={adressInputRef}
            />
            <CustomInput
              label={t('admin.areaCode')}
              value={props.contact.areaCode}
              onChange={(value) => {
                props.contact.set('areaCode', value)
                setFakeState(value)
              }}
              onlyNumbers={true}
              format="default"
              //required={true}
              placeholder={t('admin.areaCode')}
              ref={areaCodeInputRef}
            />
            <CustomInput
              label={t('admin.city')}
              value={props.contact.city}
              onChange={(value) => {
                props.contact.set('city', value)
                setFakeState(value)
              }}
              format="default"
              //required={true}
              placeholder={t('admin.city')}
              ref={cityInputRef}
            />
            <div className="flex w-full justify-between mt-1">
              <Button
                size="sm"
                variant="fullRed"
                label={t('general.cancel')}
                onClick={(e) => {
                  if (schoolId) {
                    handleBoxClick(e)
                    props.contact.revert()
                  }
                }}
              />
              <Button
                size="sm"
                variant="fullBlue"
                label={t('general.save')}
                disabled={
                  !props.contact.klassName ||
                  !props.contact.lastName ||
                  !props.contact.workTitle
                }
                onClick={(e) => {
                  editContactPerson(props.contact).finally(() => {
                    if (schoolId) {
                      handleBoxClick(e)
                      Swal.fire({
                        //title: 'Are you sure?',
                        text: t('admin.informationChange'),
                        icon: 'success',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      }).finally(() => {
                        if (schoolId) {
                          getContactPersons(schoolId)
                        }
                      })
                    }
                  })
                }}
              />
            </div>{' '}
          </div>
        )}
      </div>
    </div>
  )
}

export default ContactCard
