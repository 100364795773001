import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useNavigate, useParams } from 'react-router-dom'
import { Dashboard } from './components/Dashboard'
import Page from '../../components/Page'
import { useTranslation } from 'react-i18next'
import { NavigationStoreContext } from '../../store/navigationStore'
import { UserStoreContext } from '../../store/userStore'

export const DashboardView: React.FC = observer(() => {
  let { userRoleId } = useParams()
  const navigate = useNavigate()

  const { t } = useTranslation()
  const { setTitle } = useContext(NavigationStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  useEffect(() => {
    setTitle(
      currentUserRole?.attributes?.school_pointer?.attributes
        ?.display_school_name ??
        currentUserRole?.attributes?.school_pointer?.attributes?.schoolName ??
        '',
    )
  }, [currentUserRole?.id])

  return (
    <Page>
      {/* WIP <div className={'w-full flex justify-center'}>
        <div className={'w-full flex max-w-lg shadow h-[60px]'}>
          <div
            onClick={() => {
              navigate('/present')
            }}
            className={
              'flex-1 flex flex-col items-center justify-center border-r cursor-pointer'
            }
          >
            <p className={'font-bold text-xl'}>7</p>
            <p className={'text-xs text-gray-600'}>Alla avdelningar</p>
          </div>
          <div className={'flex-1 flex flex-col items-center justify-center'}>
            <p className={'font-bold text-xl'}>7</p>
            <p className={'text-xs text-gray-600'}>Alla avdelningar</p>
          </div>
        </div>
      </div> */}
      <Dashboard userRoleId={userRoleId ?? ''} />
    </Page>
  )
})
