import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { Button } from '../../components/Button'
import ModalRegular from '../../components/ModalRegular'
import { Event as EventModel } from '../../Models/Event'
import { useNavigate, useParams } from 'react-router-dom'
import { EventStoreContext } from '../../store/eventStore'
import { DepartmentStoreContext } from '../../store/departmentStore'
import { EventsRow } from './components/EventsRow'
import { EventsInfo } from './components/EventsInfo'
import { UserStoreContext } from '../../store/userStore'
import Page from '../../components/Page'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Department } from '../../Models/Department'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { CategoryStoreContext } from '../../store/categoryStore'
import { LoadMoreButtonOnClick } from '../../components/LoadMoreButtonOnClick'
import MultiSelectOpen from '../../components/MultiSelectOpen'
import { Loading } from '../../components/Loading'
export const getDateForTranslatation = (months: string) => {
  switch (months) {
    case '01':
      return t('monthsShort.january')
    case '02':
      return t('monthsShort.february')
    case '03':
      return t('monthsShort.march')
    case '04':
      return t('monthsShort.april')
    case '05':
      return t('monthsShort.may')
    case '06':
      return t('monthsShort.june')
    case '07':
      return t('monthsShort.july')
    case '08':
      return t('monthsShort.august')
    case '09':
      return t('monthsShort.september')
    case '10':
      return t('monthsShort.october')
    case '11':
      return t('monthsShort.november')
    case '12':
      return t('monthsShort.december')
  }
}

export const getFullMonthForTranslatation = (months: string) => {
  switch (months) {
    case '01':
      return t('months.january')
    case '02':
      return t('months.february')
    case '03':
      return t('months.march')
    case '04':
      return t('months.april')
    case '05':
      return t('months.may')
    case '06':
      return t('months.june')
    case '07':
      return t('months.july')
    case '08':
      return t('months.august')
    case '09':
      return t('months.september')
    case '10':
      return t('months.october')
    case '11':
      return t('months.november')
    case '12':
      return t('months.december')
  }
}

export const Events: React.FC = observer(() => {
  const { setTitle } = useContext(NavigationStoreContext)
  let { schoolId } = useParams()

  const { selectedCategories, setSelectedCategories } =
    useContext(CategoryStoreContext)

  const { t } = useTranslation()
  const categories = [
    { id: 'Föräldraförening', label: t('events.parentAssociation') },
    { id: 'Personal', label: t('documents.staff') },
    { id: 'Nyheter', label: t('events.news') },
    { id: 'Föräldramöte', label: t('events.parentMeeting') },
    { id: 'Studiedagar', label: t('events.studyDays') },
    { id: 'Övrigt', label: t('ourKids.other') },
  ]

  const ref: any = useRef<HTMLDivElement>()
  const [skip, setSkip] = useState<number>(0)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { fetchUserRole, currentUserRole, savedUserRoleId, readOnly } =
    useContext(UserStoreContext)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [clickedEvent, setClickedEvent] = React.useState<EventModel | null>(
    null,
  )
  const { fetchEvents, events, eventsCount, nearestDate } =
    useContext(EventStoreContext)

  const {
    fetchDepartments,
    departments,
    setSelectedDepartments,
    selectedDepartments,
  } = useContext(DepartmentStoreContext)

  const executeScroll = () => {
    if (ref) {
      // @ts-ignore
      ref?.current?.scrollIntoView()
    }
  }
  useEffect(() => {
    if (schoolId) {
      setLoading(true)
      fetchEvents(
        schoolId,
        selectedDepartments,
        selectedCategories,
        skip,
      ).finally(() => {
        //if (events) {
        if (!skip) {
          executeScroll()
        }
        //}
        setLoading(false)
      })
      fetchDepartments(schoolId).finally(() => {
        setLoading(false)
      })
    }
    if (savedUserRoleId) {
      setLoading(true)
      fetchUserRole(savedUserRoleId).finally(() => {
        setLoading(false)
      })
    }
  }, [schoolId, savedUserRoleId, skip])

  useEffect(() => {
    if (t) {
      setTitle(t('menu.events'))
    }
  }, [t])

  const months: string | string[] = []

  // FIXA, BYTER MAN ROLL - SÅ LIGGER DET GAMLA KVAR I 0.2 SEKUNDER
  // Detta behövs inte längre?
  /*if (loading) {
    return <Spinner />
  }
  if (schoolId && loading) {
    fetchEvents(schoolId, selectedDepartments).finally(() => {
      setLoading(false)
    })
  } */

  /*
  const removeSelectedDepartment = (dep: Department) => {
    const deps = selectedDepartments.filter((d) => d.id !== dep.id)
    setSelectedDepartments(deps)
    if (schoolId) {
      setLoading(true)
      fetchEvents(schoolId, deps, selectedCategories).finally(() => {
        setLoading(false)
      })
    }
  }

  const removeSelectedCategory = (cat: Category) => {
    const cats = selectedCategories.filter((d) => d.id !== cat.id)
    setSelectedCategories(cats)
    if (schoolId) {
      setLoading(true)
      fetchEvents(schoolId, selectedDepartments, cats).finally(() => {
        setLoading(false)
      })
    }
  } */
  return (
    <Page
      clearFilter={() => {
        setSelectedDepartments([])
        setSelectedCategories([])
        setLoading(true)
        if (schoolId) {
          fetchEvents(schoolId, [], []).finally(() => {
            setLoading(false)
          })
        }
      }}
      filterCount={
        (selectedCategories?.length ? selectedCategories.length : 0) +
        (selectedDepartments?.length ? selectedDepartments.length : 0)
      }
      filter={
        <>
          <div className="w-full flex flex-col md:flex-row items-center justify-center md:items-start gap-4">
            {currentUserRole && !currentUserRole.kid ? (
              <MultiSelectOpen
                alwaysShow
                className="w-full"
                onChange={(e) => {
                  setSelectedDepartments(e)
                  if (schoolId) {
                    setLoading(true)
                    fetchEvents(schoolId, e, selectedCategories).finally(() => {
                      setLoading(false)
                    })
                  }
                }}
                items={departments as Department[]}
                initialSelectedItems={selectedDepartments}
                itemVariableKey={'klassName'}
                placeholder={t('general.department')}
              />
            ) : null}
            <MultiSelectOpen
              onChange={(e: any) => {
                setSelectedCategories(e)
                if (schoolId) {
                  setLoading(true)
                  fetchEvents(schoolId, selectedDepartments, e).finally(() => {
                    setLoading(false)
                  })
                }
              }}
              items={
                currentUserRole && currentUserRole.kid
                  ? categories.filter((cat) => cat.id !== 'Personal')
                  : categories
              }
              initialSelectedItems={selectedCategories}
              itemVariableKey={'label'}
              placeholder={t('general.category')}
            />
          </div>

          {/*<div className="flex w-full justify-end mt-4">
            <Button size="md" label={t('general.save')} onClick={() => {}} />
            </div>*/}
        </>
      }
      topBar={
        <>
          {currentUserRole &&
          currentUserRole.role_type > 350 &&
          !readOnly?.events ? (
            <Button
              label={t('events.eventCreate')}
              icon={faPlus}
              size="md"
              variant="primary"
              onClick={() => navigate('/events/create/' + schoolId)}
            />
          ) : null}
        </>
      }
    >
      <>
        {loading && <Loading />}

        {/*loading && (
          <div className={'flex w-full justify-center py-3'}>
            <Loading />
          </div>
        )*/}
        {!loading && (
          <LoadMoreButtonOnClick
            loading={loading}
            needMore={(events ?? []).length < (eventsCount ?? 0)}
            loadMore={() => {
              setSkip(skip + 50)
            }}
          />
        )}
        {events && events?.length < 1 && (
          <div className="h-[400px] w-full flex justify-center gap-y-2 flex-col items-center mb-8 text-lg sm:text-2xl font-medium text-gray-300">
            {t('documents.empty')}
          </div>
        )}
        {events?.map((event) => {
          let showMonth = true
          if (
            months.includes(
              moment(event.get('date')).format('MMM') +
                '-' +
                moment(event.get('date')).format('YYYY'),
            )
          ) {
            showMonth = false
          } else {
            months.push(
              moment(event.get('date')).format('MMM') +
                '-' +
                moment(event.get('date')).format('YYYY'),
            )
          }

          return (
            <>
              {showMonth ? (
                <div
                  ref={
                    moment(nearestDate).format('YYYY-MM') ===
                    moment(event.get('date')).format('YYYY-MM')
                      ? ref
                      : null
                  }
                  className="w-full grid grid-cols-1 gap-2 justify-center items-center text-center bg-blue-200 text-blue-500 font-bold text-lg py-[20px]"
                >
                  {getDateForTranslatation(
                    moment(event.get('date')).format('MM'),
                  )}{' '}
                  {moment(event.get('date')).format('YYYY')}
                </div>
              ) : null}
              <EventsRow
                key={event.id}
                event={event}
                onClick={() => {
                  setShowModal(true)
                  setClickedEvent(event)
                }}
              />
            </>
          )
        })}
        {showModal && clickedEvent ? (
          <ModalRegular
            isOpen={showModal}
            onClose={() => {
              setShowModal(false)
            }}
            closeColor="white"
            bgColor="bg-eventsMain p-7"
          >
            <EventsInfo
              event={clickedEvent}
              userType={currentUserRole?.role_type}
              departments={departments}
              fetchEvents={() => {
                if (schoolId) {
                  fetchEvents(schoolId)
                }
              }}
              closeModalAndRefetch={() => {
                setShowModal(false)
                if (schoolId) {
                  fetchEvents(schoolId)
                }
              }}
            />
          </ModalRegular>
        ) : null}
      </>
    </Page>
  )
})
