import { motion } from 'framer-motion'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

interface Props {
  loading?: boolean
}

export const Loading: React.FC<Props> = ({ loading }) => {
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    if (loading) {
      if (show) {
        setTimeout(function () {
          setShow(false)
        }, 1000)
      } else {
        setShow(true)
        setTimeout(function () {
          setShow(false)
        }, 7000)
      }
    } else {
      setShow(true)
    }
  }, [loading])

  if (!show) {
    return null
  }
  return (
    <motion.div
      className={classNames(
        'flex flex-col fixed justify-center items-center bg-[rgba(0,0,0,0.46)] overflow-hidden z-[12000]',
      )}
      initial={{
        opacity: 0,
        /*width: '0%',
        height: '0%',
        left: '50%',
        top: '50%',*/
        width: `100%`,
        height: '100%',
        left: 0,
        top: 0,
        //borderRadius: '180%',
      }}
      animate={{
        opacity: 1,
        width: `100%`,
        height: '100%',
        left: 0,
        top: 0,
        borderRadius: '0%',
      }}
      transition={{ delay: 0, duration: 0.5 }}
    >
      <div className="w-[200px] h-[200px] flex  justify-center items-center">
        <motion.div
          initial={{ opacity: 1, width: '200px', transform: 'rotate(0deg)' }}
          animate={{
            opacity: 1,
            width: `200px`,
            transform: 'rotate(360deg)',
          }}
          transition={{ transform: { duration: 2.5, repeat: Infinity } }}
          className="h-[200px]] w-[200px] rounded-full absolute top-[50%] left-[50%] ml-[-100px] mt-[-100px]"
        >
          <img src="/images/Circle.png" alt="circle" />
        </motion.div>
        <div className="width: 100%;">
          <img
            src="/images/hand.png"
            width={'40%'}
            className="m-auto"
            alt="hand"
          />
        </div>
      </div>
    </motion.div>
  )
}
