import Parse from 'parse'
import { School } from './School'
import { User } from './User'
import { BloggDoc } from './BloggDoc'
import { ARCHIVE_STATE } from '../Enums/TyraEnums'

export class BloggReflections extends Parse.Object {
  constructor() {
    super('blogg_reflections')
  }

  get id(): string {
    return this.get('id')
  }
  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
  get message(): string {
    return this.get('message')
  }
  set message(value: string) {
    this.set('message', value)
  }
  get blogg_doc(): BloggDoc {
    return this.get('blogg_doc')
  }
  set blogg_doc(value: BloggDoc) {
    this.set('blogg_doc', value)
  }
  get kid_owner_id(): string | undefined {
    return this.get('kid_owner_id')
  }
  set kid_owner_id(value: string | undefined) {
    this.set('kid_owner_id', value)
  }
  get user_author(): User {
    return this.get('user_author')
  }
  set user_author(value: User) {
    this.set('user_author', value)
  }
  get archived_by_user(): User {
    return this.get('archived_by_user')
  }
  set archived_by_user(value: User) {
    this.set('archived_by_user', value)
  }
  get archive_state(): ARCHIVE_STATE | undefined {
    return this.get('archive_state')
  }
  set archive_state(value: ARCHIVE_STATE | undefined) {
    value ? this.set('archive_state', value) : this.unset('archive_state')
  }
  get edited_date(): Date {
    return this.get('edited_date')
  }
  set edited_date(input: Date) {
    this.set('edited_date', input)
  }
  get updatedAt(): Date {
    return this.get('updatedAt')
  }
  set updatedAt(input: Date) {
    this.set('updatedAt', input)
  }
  get createdAt(): Date {
    return this.get('createdAt')
  }
  set createdAt(input: Date) {
    this.set('createdAt', input)
  }
}
Parse.Object.registerSubclass('blogg_reflections', BloggReflections)
