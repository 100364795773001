import {
  faArchive,
  faHeart,
  faPersonCircleCheck,
  faPersonCircleXmark,
  faShare,
} from '@fortawesome/free-solid-svg-icons'
import {
  faBars,
  faBoxArchive,
  faEllipsis,
  faEllipsisVertical,
  faEyes,
  faLock,
  faPen,
  faPlay,
  faStar,
  faTrashCan,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Note } from '../../../Models/Note'
import { NoteComment } from '../../../Models/NoteComment'
import { NoteStoreContext } from '../../../store/noteStore'
import {
  getImageFromRole,
  getImageFromUser,
  getImageFromUserBall,
  getTitleFromRole,
} from '../../../utils/parseUtils'
import { NewNote } from './NoteCreate'
import { Button } from '../../../components/Button'
import { ImageCarousel } from '../../../components/ImageCarousel'
import NotesOverviewModal from './NotesOverviewModal'
import { Kid } from '../../../Models/Kid'
import { ButtonDropdown } from '../../../components/Dropdown'
import Swal from 'sweetalert2'
import { UserStoreContext } from '../../../store/userStore'
import { faStar as farFaStar } from '@fortawesome/pro-regular-svg-icons'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { NoteEdit } from './NoteEdit'
import DOMPurify from 'dompurify'
import { FullscreenModal } from '../../portfolio/components/FullScreenModal'
import { User } from '../../../Models/User'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import ImageWithLetters, {
  getDateForTranslatation,
} from '../../../components/ImageWithLetters'
import TimeStamp from '../../../components/TimeStamp'
import ModalRegular from '../../../components/ModalRegular'
import { KidStoreContext } from '../../../store/kidStore'
import { KidsStoreContext } from '../../../store/kidsStore'
import { useParams } from 'react-router-dom'

const translateRelation = (relation: string) => {
  switch (relation) {
    case 'Personal':
      return t('documents.staff')
    case 'Mamma':
      return t('general.mother')
    case 'Pappa':
      return t('general.father')
    case 'Vårdnadshavare':
      return t('documents.parent')
    default:
      return relation
  }
}

export const NotesOverview = observer(
  ({
    showModal,
    hideModal,
    note,
    onImageSelected,
    noteMain,
    comments,
    onSave,
    isTeacher,
    isAdmin,
    kid,
    unreadNote,
    showArchived,
  }: {
    showModal: boolean
    hideModal: any
    note: Note
    onImageSelected?: any
    noteMain?: NoteComment
    comments: NoteComment[]
    onSave: () => void
    isTeacher: boolean
    isAdmin: boolean
    kid?: Kid
    unreadNote: boolean
    showArchived: boolean
  }) => {
    const [newComment, setNewComment] = useState<string>('')
    const {
      addCommentToNote,
      archiveComment,
      deleteComment,
      deleteNote,
      archiveNote,
      changeShared,
      fetchParentReadStates,
      fetchTeacherReadStates,
      fetchBothReadStates,
      noteReadStates,
      noteReadStatesUsers,
      updateReadState,
    } = useContext(NoteStoreContext)
    const {
      fetchUserRole,
      savedUserRoleId,
      currentUserRole,
      readOnly,
      currentUser,
    } = useContext(UserStoreContext)

    const { userRoles, fetchUserRoles } = useContext(KidsStoreContext)

    const [loading, setLoading] = useState(true)
    useEffect(() => {
      if (savedUserRoleId) {
      }
    })
    const {} = useParams()
    useEffect(() => {
      if (currentUserRole) {
        setLoading(true)
        fetchUserRoles(currentUserRole).finally(() => {
          setLoading(false)
        })
      }
    }, [currentUserRole])

    useEffect(() => {
      if (isTeacher) {
        if (isAdmin) {
          fetchBothReadStates(note.kid_owner.id, note.id)
        } else {
          fetchParentReadStates(note.kid_owner.id, note.id)
        }
      } else {
        fetchTeacherReadStates(note.kid_owner.id, note.id)
      }
      if (unreadNote) {
        updateReadState(note).finally(() => {
          onSave()
        })
      }
    }, [note])
    const ref = React.useRef() as any
    /*} const [height, setHeight] = useState(0)
    const measuredRef = useCallback((node: any) => {
      if (node !== null) {
        setHeight(node.getBoundingClientRect().height)
      }
    }, [])*/
    const [menuRef, togglerRef] = useClickOutside(() => {
      setDropdownVisible(false)
      setDropdownVisibleMain(false)
    })

    const [currentCommentId, setCurrentCommentId] = useState<string | null>(
      null,
    )
    const [selectedImage, setSelectedImage] = useState(false)
    const [showFullScreenModal, setShowFullScreenModal] = useState(false)
    const handleImageClick = (image: any) => {
      setSelectedImage(image)
      setShowFullScreenModal(true)
    }
    const saveNote = async () => {
      await addCommentToNote(newComment, note)
        .catch((e) => {
          Swal.fire({
            text: t('events.eventAlarmFail'),
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            confirmButtonText: t('general.ok'),
            cancelButtonText: t('general.cancel'),
          })
          throw e
        })
        .then(() => {
          Swal.fire({
            text: t('statistics.success'),
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            confirmButtonText: t('general.ok'),
            cancelButtonText: t('general.cancel'),
          }).then(async (result) => {})
          setNewComment('')
          onSave()
        })
        .finally(() => {
          // gör swal här istället
        })
    }
    const [ViewModal, setViewModal] = useState(false)
    const [names, setNames] = useState<string[]>([])

    const handleClick = () => {
      const userNames =
        noteReadStatesUsers
          ?.filter((pu) => pu.id !== note?.notes_owner?.id)
          .map((pu) => `${pu.get('firstName')} ${pu.get('lastName')}`) || []
      setNames(userNames)
      setViewModal(true)
    }
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible)
    }
    const [dropdownVisibleMain, setDropdownVisibleMain] = useState(false)
    const toggleDropdownMain = () => {
      setDropdownVisibleMain(!dropdownVisibleMain)
    }

    const { addFavoritNote, removeFavoritNote } = useContext(NoteStoreContext)

    const isEditable = note.edit_locked
      ? note.notes_owner?.id === currentUser?.id
        ? isTeacher
          ? true
          : false
        : false
      : isTeacher
      ? true
      : false || isAdmin || note.notes_owner?.id == currentUser?.id

    const [editNote, setEditNote] = useState<Note | null>(null)
    const canSeeSensetive =
      currentUserRole?.attributes?.staff_sensetive_info_bool ||
      kid?.id === currentUserRole?.attributes?.kid?.id

    return (
      <NotesOverviewModal
        title={t('menu.notes')}
        isOpen={showModal}
        onClose={() => {
          hideModal()
        }}
        className={'w-full p-0'}
        bgColor="bg-eventsMain p-4 justify-center"
      >
        <div className="relative flex flex-col justify-between p-8 bg-gray-50">
          <div className="flex flex-col sm:flex-row justify-between items-end">
            <div className="text-2xl font-bold  text-gray-800 flex flex-wrap justify-between w-full mb-4 items-center">
              {note.notes_topic}{' '}
            </div>
          </div>
          {editNote && (
            <NoteEdit
              onSave={() => {
                onSave()
                setEditNote(null)
              }}
              onClose={() => {
                setEditNote(null)
              }}
              note={editNote}
              showModal={true}
              kidId={note.kid_owner.id}
            />
          )}

          <div>
            <div className="flex flex-col sm:flex-row justify-between">
              <div className="flex flex-col justify-between gap-y-10">
                <div
                  className="whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(noteMain?.attributes?.comment),
                  }}
                />
                <div className="flex flex-row items-end w-full gap-x-2">
                  <>{/*<Button className="max-w-[80px]" icon={faEyes} />*/}</>
                  {noteMain?.attributes?.picture && (
                    <>
                      {' '}
                      <div>
                        <ImageCarousel
                          className="max-w-[80px] min-w-[80px] max-h-[80px] min-h-[80px] rounded-2xl hover:scale-[102%]"
                          images={[noteMain.attributes?.picture._url]}
                          imageOnClick={handleImageClick}
                        />
                      </div>
                      <div>
                        <FullscreenModal
                          src={selectedImage}
                          showModal={showFullScreenModal}
                          hideModal={() => {
                            setShowFullScreenModal(false)
                          }}
                        />
                      </div>
                    </>
                  )}{' '}
                  {noteMain?.attributes?.voice_memo && (
                    <div className=" hover:scale-[102%] mt-[1px]">
                      <a
                        target={'_blank'}
                        href={noteMain.attributes.voice_memo._url}
                      >
                        <Button
                          variant="play"
                          className="min-w-[80px]"
                          icon={faPlay}
                        />
                      </a>
                    </div>
                  )}
                </div>
                <div className="text-sm text-gray-400 font-normal gap-y-2 flex flex-col">
                  <div className="flex flex-row items-center gap-x-2">
                    <ImageWithLetters user={note.notes_owner as User} />
                    {note.notes_owner && note.notes_owner?.firstName ? (
                      <>
                        {note.notes_owner?.firstName +
                          ' ' +
                          note.notes_owner?.lastName}
                      </>
                    ) : (
                      <>{t('general.unknown')}</>
                    )}{' '}
                    |{' '}
                    {moment(note.createdAt).isSame(moment(), 'day') ? (
                      <>{moment(note.createdAt).format('YYYY-MM-DD : HH:mm')}</>
                    ) : (
                      <>{moment(note.createdAt).format('YYYY-MM-DD')}</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-4">
            <hr />
            <div className="mt-4">
              {note.shared && isTeacher && (
                <div className="flex flex-row justify-between ">
                  <div className="flex flex-row gap-x-2">
                    {noteReadStatesUsers?.map((pu) => {
                      const parentReadState = noteReadStates?.find(
                        (prs) => prs.user.id == pu.id,
                      )
                      if (pu.id !== note?.notes_owner?.id) {
                        return (
                          <div>
                            <ImageWithLetters
                              user={pu as User}
                              opacity={
                                Number(parentReadState?.read_revision ?? 0) >= 1
                                  ? false
                                  : true
                              }
                            />
                          </div>
                        )
                      }
                      return (
                        <div>
                          <ImageWithLetters
                            user={pu as User}
                            opacity={
                              Number(parentReadState?.read_revision ?? 0) >= 1
                                ? false
                                : true
                            }
                          />
                        </div>
                      )
                    })}
                  </div>
                  <div>
                    {isTeacher && (
                      <FontAwesomeIcon
                        icon={faBars}
                        onClick={handleClick}
                        className="text-eventsMain cursor-pointer hover:scale-105 hover:text-blue-400"
                      />
                    )}
                    <ModalRegular
                      isOpen={ViewModal}
                      onClose={() => {
                        setViewModal(false)
                      }}
                      bgColor="bg-eventsMain p-7"
                      closeColor="white"
                    >
                      <div className="p-4">
                        <div className="flex flex-row justify-between pr-4 pb-4">
                          <div className="text-2xl text-gray-400">
                            <>{t('notes.receiver')}</>
                          </div>
                          <div className="text-2xl text-gray-400">
                            <>{t('documents.read')}</>
                          </div>
                        </div>
                        <div>
                          {noteReadStatesUsers?.map((pu) => {
                            const parentReadState = noteReadStates?.find(
                              (prs) => prs.user.id == pu.id,
                            )
                            {
                              /*Kodsnutt under är ifall man vill rendera personer som inte läst*/
                            }
                            //if (!parentReadState) return null
                            const updatedAt = parentReadState?.updatedAt
                            const name = `${pu.get('firstName')} ${pu.get(
                              'lastName',
                            )}`
                            const userRole = userRoles?.find(
                              (role) =>
                                role?.attributes?.kid?.id === kid?.id &&
                                role?.attributes?.user?.id === pu.id,
                            )
                            let relation =
                              userRole?.attributes?.relation ?? 'Personal'

                            if (
                              userRole &&
                              !userRole?.attributes?.alternative_contact?.id
                            ) {
                              relation = translateRelation(relation)
                            }

                            return (
                              <div
                                key={pu.id}
                                className="flex items-center space-x-2 space-y-3 hover:bg-blue-50 rounded-2xl cursor-not-allowed px-2"
                              >
                                <ImageWithLetters
                                  user={pu as User}
                                  className="min-w-[40px] max-w-[40px] max-h-[40px] min-h-[40px] rounded-full"
                                  opacity={
                                    Number(
                                      parentReadState?.read_revision ?? 0,
                                    ) >= 1
                                      ? false
                                      : true
                                  }
                                />
                                <div className="flex flex-row justify-between w-full">
                                  <div className="flex flex-col items-start mb-[10px]">
                                    <div className="flex">{name}</div>
                                    <div className="text-gray-400">
                                      {translateRelation(
                                        userRole?.attributes?.relation ??
                                          'Personal',
                                      )}
                                    </div>
                                  </div>
                                  <div className="text-gray-400 items-start mt-[10px] flex pr-4">
                                    {updatedAt ? (
                                      <>
                                        {moment(updatedAt).format('DD ')}
                                        {getDateForTranslatation(
                                          moment(updatedAt).format('MM'),
                                        )}
                                        {moment(updatedAt).format(' HH:mm')}
                                      </>
                                    ) : (
                                      <div className="text-red-500 flex items-center">
                                        <>{t('notes.notRead')}</>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </ModalRegular>
                  </div>
                </div>
              )}
            </div>
            <div className="text-gray-400 mt-6 text-2xl font-bold">
              <>
                {' '}
                {t('blog.comments')} (
                {
                  note.notes_comments
                    ?.filter((comment) => !comment.attributes.is_main)
                    ?.filter((comment) =>
                      showArchived ? true : !comment.attributes.archive_state,
                    )?.length
                }
                )
              </>
            </div>
            <div className="flex flex-row my-4 items-baseline">
              <div className="font-bold text-md"></div>
              <div className="text-sm w-full">
                {comments
                  .filter((comment: NoteComment) =>
                    showArchived ? true : !comment.attributes.archive_state,
                  )
                  ?.map((comment: NoteComment, index) => {
                    /*if (comment.archive_state) {
                    //TODO: Fixa detta i cloud
                    return null
                  }*/
                    const isCurrentUserComment =
                      comment.attributes.comment_owner &&
                      comment.attributes.comment_owner.id === currentUser?.id

                    return (
                      <>
                        <div
                          className={`flex flex-wrap w-full flex-col mt-3 p-3 relative rounded-2xl shadow overflow-hidden ${
                            isCurrentUserComment
                              ? comment.attributes.archive_state
                                ? 'w-[400px] ml-auto bg-red-50'
                                : 'w-[400px] ml-auto bg-green-50'
                              : comment.attributes.archive_state
                              ? 'w-[400px] mr-auto bg-red-50'
                              : 'w-[400px] mr-auto bg-gray-100'
                          }`}
                        >
                          <div className="flex flex-col items-start space-x-1 ">
                            <div className="flex flex-row space-x-4">
                              <ImageWithLetters
                                user={comment.attributes.comment_owner as User}
                              />
                              <div className="font-medium text-sm mt-1 text-baseline">
                                {comment.attributes.comment_owner &&
                                comment.attributes.comment_owner.firstName ? (
                                  <>
                                    {
                                      comment.attributes.comment_owner
                                        ?.firstName
                                    }{' '}
                                    {comment.attributes.comment_owner?.lastName}
                                  </>
                                ) : (
                                  <>{t('general.unknown')}</>
                                )}
                              </div>
                            </div>

                            <div className="my-4 whitespace-pre-wrap">
                              {' '}
                              {comment.attributes.comment}
                            </div>

                            {comment.archive_state && (
                              <div className="absolute right-5 bottom-5">
                                <FontAwesomeIcon
                                  icon={faArchive}
                                  className="text-red-300"
                                />
                              </div>
                            )}
                          </div>
                          <div className="flex flex-row justify-between items-baseline">
                            {note.shared && isTeacher && (
                              <div className="flex flex-row-reverse">
                                {noteReadStatesUsers?.map((pu) => {
                                  const parentReadState = noteReadStates?.find(
                                    (prs) => prs.user.id === pu.id,
                                  )
                                  /*console.log(
                                    'parentReadState',
                                    parentReadState,
                                  )*/
                                  /*
                                  Detta är väl fel??
                                  const isNoteOwner =
                                    pu.id === note.notes_owner?.id
                                  */
                                  let archivedComments =
                                    note.notes_comments?.filter(
                                      (com) => com.archive_state,
                                    ).length
                                  if (!archivedComments) {
                                    archivedComments = 0
                                  }
                                  const isNoteOwner =
                                    pu.id === comment.comment_owner.id
                                  const isLatestComment = note.notes_comments
                                    ? index + (2 + archivedComments) ===
                                      note.notes_comments.length
                                    : false

                                  /*if (index === 3) {
                                    console.log(
                                      '1',
                                      note.notes_comments?.length,
                                    )
                                    console.log(
                                      '2',
                                      index + (1 + archivedComments),
                                    )
                                    console.log(
                                      'isLatestComment',
                                      isLatestComment,
                                    )
                                    console.log(
                                      'isNoteOwner - ' + pu.attributes.lastName,
                                      isNoteOwner,
                                    )
                                  }*/
                                  if (
                                    Number(
                                      parentReadState?.read_revision ?? 0,
                                    ) ===
                                      index + (2 + archivedComments) ||
                                    (isNoteOwner && isLatestComment)
                                  ) {
                                    return (
                                      <>
                                        <ImageWithLetters user={pu as User} />
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            )}

                            <div className="text-xs text-gray-400 flex flex-row   mt-2 pl-1 ">
                              <TimeStamp date={moment(comment.createdAt)} />
                            </div>
                          </div>
                          <>
                            {isAdmin ||
                            (!readOnly?.notes &&
                              comment.comment_owner.id === currentUser?.id) ? (
                              <button
                                type="button"
                                className="w-full rounded-md group focus:outline-none"
                                aria-haspopup="true"
                                aria-expanded={dropdownVisible}
                                onClick={() => {
                                  toggleDropdown()
                                  setCurrentCommentId(comment.id)
                                }}
                                ref={togglerRef}
                              >
                                <FontAwesomeIcon
                                  icon={faEllipsisVertical}
                                  className="text-gray-400 absolute top-3 right-1 px-4 hover:scale-110"
                                />
                              </button>
                            ) : null}

                            {dropdownVisible &&
                              currentCommentId == comment.id && (
                                <div className="absolute right-4 z-50 mx-3 origin-top divide-y w-44 top-0">
                                  <ButtonDropdown.Menu key={comment.id}>
                                    <ButtonDropdown.Section>
                                      {/* Arkivera-knapp */}
                                      {!note.archive_state && (
                                        <ButtonDropdown.Button
                                          icon={faBoxArchive}
                                          label={t('notes.archive')}
                                          className="text-blue-300"
                                          onClick={(e) => {
                                            Swal.fire({
                                              text: t(
                                                'documents.areYouSureRemoveFile',
                                              ),
                                              icon: 'warning',
                                              showCancelButton: true,
                                              confirmButtonColor: '#7eb5f4',
                                              cancelButtonColor: '#d33',
                                              confirmButtonText:
                                                t('general.yes'),
                                              cancelButtonText:
                                                t('general.cancel'),
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                archiveComment(comment)
                                                  .catch((e) => {
                                                    Swal.fire({
                                                      text: t(
                                                        'events.eventAlarmFail',
                                                      ),
                                                      icon: 'warning',
                                                      showCancelButton: false,
                                                      confirmButtonColor:
                                                        '#7eb5f4',
                                                      cancelButtonColor: '#d33',
                                                      confirmButtonText:
                                                        t('general.ok'),
                                                      cancelButtonText:
                                                        t('general.cancel'),
                                                    })
                                                    throw e
                                                  })
                                                  .finally(() => {
                                                    Swal.fire({
                                                      text: t(
                                                        'statistics.success',
                                                      ),
                                                      icon: 'success',
                                                      showCancelButton: false,
                                                      confirmButtonColor:
                                                        '#7eb5f4',
                                                      cancelButtonColor: '#d33',
                                                      confirmButtonText:
                                                        t('general.ok'),
                                                      cancelButtonText:
                                                        t('general.cancel'),
                                                    })
                                                    onSave()
                                                  })
                                              }
                                            })
                                          }}
                                        />
                                      )}

                                      {isAdmin && (
                                        <ButtonDropdown.Button
                                          key={comment.id}
                                          icon={faTrashCan}
                                          label={t('absence.delete')}
                                          className="text-red-700"
                                          onClick={(e) => {
                                            Swal.fire({
                                              text: t(
                                                'documents.areYouSureRemoveFile',
                                              ),
                                              icon: 'warning',
                                              showCancelButton: true,
                                              confirmButtonColor: '#7eb5f4',
                                              cancelButtonColor: '#d33',
                                              confirmButtonText:
                                                t('general.yes'),
                                              cancelButtonText:
                                                t('general.cancel'),
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                deleteComment(comment)
                                                  .catch((e) => {
                                                    Swal.fire({
                                                      text: t(
                                                        'events.eventAlarmFail',
                                                      ),
                                                      icon: 'warning',
                                                      showCancelButton: false,
                                                      confirmButtonColor:
                                                        '#7eb5f4',
                                                      cancelButtonColor: '#d33',
                                                      confirmButtonText:
                                                        t('general.ok'),
                                                      cancelButtonText:
                                                        t('general.cancel'),
                                                    })
                                                  })
                                                  .finally(() => {
                                                    Swal.fire({
                                                      text: t(
                                                        'statistics.success',
                                                      ),
                                                      icon: 'success',
                                                      showCancelButton: false,
                                                      confirmButtonColor:
                                                        '#7eb5f4',
                                                      cancelButtonColor: '#d33',
                                                      confirmButtonText:
                                                        t('general.ok'),
                                                      cancelButtonText:
                                                        t('general.cancel'),
                                                    })
                                                    onSave()
                                                  })
                                              }
                                            })
                                          }}
                                        />
                                      )}
                                    </ButtonDropdown.Section>
                                  </ButtonDropdown.Menu>
                                </div>
                              )}
                          </>
                        </div>
                      </>
                    )
                  })}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap ">
            <div className="p-2 w-full flex flex-col gap-y-4">
              {!readOnly?.notes && (
                <>
                  <textarea
                    placeholder={t('notes.writeComment')}
                    className=" border border-gray-100 shadow rounded-2xl p-4 h-[200px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain "
                    value={newComment}
                    onChange={(e) => {
                      setNewComment(e.target.value)
                    }}
                    //disabled={readOnly?.notes}
                  />
                  <div className="w-full flex justify-end">
                    <Button
                      variant="fullBlue"
                      size="md"
                      label={t('notes.comment')}
                      onClick={() => {
                        if (newComment !== '') {
                          saveNote()
                        } else {
                          Swal.fire({
                            //title: 'Are you sure?',
                            text: t('notes.noComment'),
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                            cancelButtonText: t('general.cancel'),
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                            }
                          })
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {isEditable && (
            <div className="absolute top-3 -right-1">
              <div className="flex flex-row absolute top-0 right-4">
                <div className="relative flex">
                  {!readOnly?.notes && (
                    <button
                      type="button"
                      className="w-full rounded-md group focus:outline-none "
                      aria-haspopup="true"
                      aria-expanded={dropdownVisible}
                      onClick={toggleDropdownMain}
                      ref={togglerRef}
                    >
                      <FontAwesomeIcon
                        icon={faEllipsis}
                        className=" text-gray-400 absolute top-1 -right-2 px-4 hover:scale-110"
                      ></FontAwesomeIcon>
                    </button>
                  )}

                  {dropdownVisibleMain && (
                    <div
                      className="absolute right-4 z-50 mx-3 origin-top divide-y w-[245px] top-0"
                      style={{
                        display: dropdownVisibleMain ? 'block' : 'none',
                      }}
                      ref={menuRef}
                    >
                      <ButtonDropdown.Menu>
                        <ButtonDropdown.Section>
                          {!note.archive_state && (
                            <>
                              {isTeacher && !note.shared && (
                                <ButtonDropdown.Button
                                  label={t('notes.edit')}
                                  icon={faPen}
                                  onClick={() => setEditNote(note)}
                                ></ButtonDropdown.Button>
                              )}

                              <ButtonDropdown.Button
                                icon={faBoxArchive}
                                label={t('notes.archive')}
                                className="text-blue-300"
                                onClick={(e) => {
                                  e.stopPropagation()

                                  Swal.fire({
                                    //title: 'Are you sure?',
                                    text: t('documents.areYouSureRemoveFile'),
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#7eb5f4',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: t('general.yes'),
                                    cancelButtonText: t('general.cancel'),
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      archiveNote(note)
                                        .catch((e) => {
                                          Swal.fire({
                                            text: t('events.eventAlarmFail'),
                                            icon: 'warning',
                                            showCancelButton: false,
                                            confirmButtonColor: '#7eb5f4',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: t('general.ok'),
                                            cancelButtonText:
                                              t('general.cancel'),
                                          })
                                          throw e
                                        })
                                        .then(() => {
                                          Swal.fire({
                                            //title: 'Are you sure?',
                                            text: t('statistics.success'),
                                            icon: 'success',
                                            showCancelButton: false,
                                            confirmButtonColor: '#7eb5f4',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: t('general.ok'),
                                            cancelButtonText:
                                              t('general.cancel'),
                                          }).then(async (result) => {})
                                          onSave()
                                          hideModal()
                                        })
                                        .finally(() => {})
                                    }
                                  })
                                }}
                              />
                              {isAdmin && (
                                <ButtonDropdown.Button
                                  icon={faTrashCan}
                                  label={t('absence.delete')}
                                  className="text-red-700"
                                  onClick={(e) => {
                                    e.stopPropagation()

                                    Swal.fire({
                                      //title: 'Are you sure?',
                                      text: t('documents.areYouSureRemoveFile'),
                                      icon: 'warning',
                                      showCancelButton: true,
                                      confirmButtonColor: '#7eb5f4',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: t('general.yes'),
                                      cancelButtonText: t('general.cancel'),
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        deleteNote(note)
                                          .catch((e) => {
                                            Swal.fire({
                                              text: t('events.eventAlarmFail'),
                                              icon: 'warning',
                                              showCancelButton: false,
                                              confirmButtonColor: '#7eb5f4',
                                              cancelButtonColor: '#d33',
                                              confirmButtonText:
                                                t('general.ok'),
                                              cancelButtonText:
                                                t('general.cancel'),
                                            })
                                            throw e
                                          })
                                          .then(() => {
                                            Swal.fire({
                                              text: t('statistics.success'),
                                              icon: 'success',
                                              showCancelButton: false,
                                              confirmButtonColor: '#7eb5f4',
                                              cancelButtonColor: '#d33',
                                              confirmButtonText:
                                                t('general.ok'),
                                              cancelButtonText:
                                                t('general.cancel'),
                                            }).then(async (result) => {})
                                            onSave()
                                            hideModal()
                                          })
                                          .finally(() => {})
                                      }
                                    })
                                  }}
                                />
                              )}
                              {/*{!note.shared && (
                                <ButtonDropdown.Button
                                  icon={faPersonCircleCheck}
                                  label={t('notes.shareParent')}
                                  className="text-eventsMain"
                                  onClick={() => {
                                    Swal.fire({
                                      title: t('notes.shareParent'),
                                      text: t('notes.alertShare'),
                                      icon: 'warning',
                                      showCancelButton: true,
                                      confirmButtonColor: '#7eb5f4',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: t('general.yes'),
                                      cancelButtonText: t('general.cancel'),
                                      html: `
                                          <style>
                                          .note-preview {
                                            padding: 1rem;
                                            border: 1px solid #ccc;
                                            border-radius: 1.5rem;
                                            margin-top: 10px;
                                          }
                                          .note-details p {
                                            margin: 0;
                                          }
                                          .note-details p:first-child {
                                            font-size: 1.2em;
                                            font-weight: bold;
                                            margin-bottom: 5px;
                                          }
                                          .note-details p:last-child {
                                            font-size: 0.9em;
                                            font-weight: normal;
                                            text-align: center;
                                          }
                                        </style>
                                          <p>${t('notes.alertShare')}</p>
                                          <div class="note-preview">
                                            <div class="note-details">
                                              <p><strong>${
                                                note.notes_topic || ''
                                              }</strong></p>
                                              <p>${
                                                noteMain?.attributes?.comment ||
                                                ''
                                              }</p>
                                            </div>
                                          </div>
                                        `,
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        Swal.fire({
                                          text: t('notes.alertConfirmShare'),
                                          icon: 'success',
                                          showCancelButton: false,
                                          confirmButtonColor: '#7eb5f4',
                                          cancelButtonColor: '#d33',
                                          confirmButtonText: t('general.ok'),
                                          cancelButtonText: t('general.cancel'),
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            changeShared(note, true)
                                          }
                                        })
                                      }
                                    })
                                  }}
                                />
                                )}*/}
                            </>
                          )}
                        </ButtonDropdown.Section>
                      </ButtonDropdown.Menu>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="abslute">
            <div className="absolute top-[45px] right-[19px]">
              {note.shared && (
                <FontAwesomeIcon
                  icon={faUsers}
                  className="text-eventsMain h-4 w-4"
                  title={t('notes.sharedWithGuardian')}
                />
              )}
            </div>
            <div className="absolute  top-[80px] right-[18px]">
              {note.is_favorite?.find((userId) => userId == currentUser?.id) ? (
                <FontAwesomeIcon
                  icon={faStar}
                  className="text-yellow-400 hover:scale-110 cursor-pointer"
                  onClick={async () => {
                    if (currentUser) {
                      await removeFavoritNote(note, currentUser.id)
                        .catch((e) => {
                          Swal.fire({
                            text: t('events.eventAlarmFail'),
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                            cancelButtonText: t('general.cancel'),
                          })
                          throw e
                        })
                        .then(() => {
                          onSave()
                        })
                        .finally(() => {})
                    }
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={farFaStar}
                  className="text-gray-400 hover:scale-110 cursor-pointer"
                  onClick={async () => {
                    if (currentUser) {
                      await addFavoritNote(note, currentUser.id)
                        .catch((e) => {
                          Swal.fire({
                            text: t('events.eventAlarmFail'),
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                            cancelButtonText: t('general.cancel'),
                          })
                          throw e
                        })
                        .then(() => {
                          onSave()
                        })
                        .finally(() => {})
                    }
                  }}
                />
              )}
            </div>
            <div className="absolute top-[143px] right-[20px] flex justify-center">
              {note && note.edit_locked && !note.shared ? (
                <FontAwesomeIcon
                  icon={faLock}
                  className="text-gray-500"
                  title={
                    t('notes.noteLockedTitle') +
                    ' ' +
                    note.notes_owner?.firstName +
                    ' ' +
                    note.notes_owner?.lastName
                  }
                />
              ) : null}{' '}
            </div>
          </div>
        </div>
      </NotesOverviewModal>
    )
  },
)
