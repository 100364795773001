import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import DatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import es from 'date-fns/locale/es'
import { Loading } from '../../../components/Loading'
import { useTranslation } from 'react-i18next'
registerLocale('sv', sv)
registerLocale('en', en)
registerLocale('es', es)

export const DateChooser: React.FC<{
  onChangeDate: any
  defaultStartDate: Date | undefined
  defaultEndDate: Date | undefined
}> = observer(({ onChangeDate, defaultStartDate, defaultEndDate }) => {
  const { t, i18n } = useTranslation()

  const [startDate, setStartDate] = useState<Date | undefined>(defaultStartDate)
  const [endDate, setEndDate] = useState<Date | undefined>(defaultEndDate)

  useEffect(() => {
    if (startDate && endDate) {
      onChangeDate(startDate, endDate)
    }
  }, [startDate, endDate])
  const [focusRing1, setFocusRing1] = useState(false)
  const [focusRing2, setFocusRing2] = useState(false)
  return (
    <div>
      <div className="flex justify-center bg-white rounded-lg border  w-full p-1">
        <div className="flex justify-center items-center flex-col sm:flex-row gap-x-4">
          <div className="relative">
            <DatePicker
              className="border border-gray-200 hover:border-eventsMain text-xs sm:text-sm cursor-pointer  max-w-[200px] p-[1px] sm:p-2 rounded-lg text-center focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
              dateFormat="yyyy-MM-dd"
              locale={i18n.language}
              selected={defaultStartDate as unknown as Date}
              //onChange={(date: Date) => setStartDate(date)}
              onChange={(date: Date) => {
                if (endDate && endDate < date) {
                  setFocusRing1(false)
                  setStartDate(date)
                  setEndDate(date)
                } else {
                  setStartDate(date)
                  setFocusRing1(false)
                }
              }}
              onFocus={() => setFocusRing1(true)}
              onBlur={() => {
                setFocusRing1(false)
              }}
            />
          </div>
          <div className="text-gray-600 font-normal text-xs sm:text-sm">
            {t('general.to')}
          </div>
          <div className="relative">
            <DatePicker
              className="border border-gray-200 hover:border-eventsMain text-xs sm:text-sm cursor-pointer  max-w-[200px] p-[1px] sm:p-2 rounded-lg text-center focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
              dateFormat="yyyy-MM-dd"
              locale={i18n.language}
              selected={defaultEndDate as unknown as Date}
              onChange={(date: Date) => {
                setFocusRing2(false)
                setEndDate(date)
              }}
              minDate={startDate as unknown as Date}
              onFocus={() => setFocusRing2(true)}
              onBlur={() => {
                setFocusRing2(false)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
})
