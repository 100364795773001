import React, { useContext, useEffect, useRef, useState } from 'react'
import Page from '../../components/Page'
import { Button } from '../../components/Button'
import ModalRegular from '../../components/ModalRegular'
import { NavigationStoreContext } from '../../store/navigationStore'
import { t } from 'i18next'
import { UserStoreContext } from '../../store/userStore'
import { TERRITORY } from '../../Enums/TyraEnums'
import i18n from '../../i18nextConfig'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface TermsAndPrivacy {
  loginTerritory?: number
}

export const TermsAndPrivacy: React.FC<{
  onClick?: any
  loginTerritory: number
}> = ({ onClick, loginTerritory }) => {
  const [termsOfUse, setTermsOfUse] = useState(false)
  const [freeText, setFreeText] = useState(false)
  const [userInfo, setUserInfo] = useState(false)
  const { setTitle } = useContext(NavigationStoreContext)
  const [activeTab, setActiveTab] = useState('Allt')
  const [highlightStyle, setHighlightStyle] = useState({})

  const tabsRef = useRef<(HTMLDivElement | null)[]>([])
  const [showFirstGroup, setShowFirstGroup] = useState(true)
  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 450)
  useEffect(() => {
    setTitle(t('settings.termsOfUseAndPrivacy'))
  }, [t])
  const tabs = [
    t('settings.termsOfUse'),
    t('settings.freeTextField'),
    t('settings.staffInfoAndStorage'),
  ]
  const { currentUserRole } = useContext(UserStoreContext)
  const territory =
    currentUserRole?.school_pointer.attributes.territory ?? loginTerritory
  useEffect(() => {
    const visibleTabs = tabs.filter((_, index) =>
      isNarrowScreen ? (showFirstGroup ? index < 3 : index >= 3) : true,
    )

    const activeTabIndex = visibleTabs.indexOf(activeTab)

    const currentTab = tabsRef.current[activeTabIndex]
    if (currentTab) {
      setHighlightStyle({
        width: `${currentTab.offsetWidth}px`,
        transform: `translateX(${currentTab.offsetLeft}px)`,
      })
    }
  }, [isNarrowScreen, activeTab])
  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 450)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return (
    <Page
      topBar={
        <>
          <div className="w-full rounded p-1 relative">
            <div className="flex items-center justify-start w-full overflow-x-auto">
              {tabs
                .filter((tab, index) =>
                  isNarrowScreen
                    ? showFirstGroup
                      ? index < 3
                      : index >= 3
                    : true,
                )
                .map((tab, index) => (
                  <div
                    key={tab}
                    ref={(el) => (tabsRef.current[index] = el)}
                    onClick={() => setActiveTab(tab)}
                    className={`flex-1 select-none text-center py-3 cursor-pointer text-xs ${
                      activeTab === tab ? 'text-blue-500' : 'text-gray-600'
                    }`}
                  >
                    {tab}
                  </div>
                ))}
            </div>
            <div
              className="absolute bottom-0 h-1 rounded-full bg-blue-500 transition-transform duration-300 ease-in-out"
              style={highlightStyle}
            />
          </div>
        </>
      }
    >
      {activeTab === t('settings.termsOfUse') && (
        <div>
          {' '}
          <div className="p-4">
            {loginTerritory === TERRITORY.SPAIN ? (
              <>
                <p>
                  <strong>
                    Términos de uso y condiciones para el uso de TYRA
                  </strong>
                </p>
                <p>
                  <strong>1. General</strong>
                </p>
                <p>
                  Bayou AB (&ldquo;Bayou&rdquo;) provee del servicio Tyra
                  (&ldquo;Tyra&rdquo;). Tyra es una herramienta diseñada para el
                  uso de escuelas infantiles (la &ldquo;Escuela&rdquo;) y padres
                  cuyos hijos asistan a esa escuela. La Escuela&nbsp;puede
                  informar a los padres a través de la aplicación y almacenar
                  información necesaria sobre los niños (el
                  &ldquo;Servicio&rdquo;). Desde que la Escuela comienza el
                  acuerdo con Bayou acerca del uso de Tyra, usted como padre ya
                  tiene la oportunidad de acceder al Servicio.
                </p>
                <p>
                  Al instalar o utilizar el Servicio, como padre, usted entra en
                  un contrato legal vinculante con Bayou, en lo que respecta al
                  uso de Tyra. Los términos generales y condiciones fijados
                  abajo (los &ldquo;Términos&rdquo;) se aplican en el momento de
                  la instalación y uso del Servicio. Por favor, lea atentamente
                  los Términos antes de aceptarlos y empezar a utilizar el
                  Servicio.
                </p>
                <p>
                  Al aceptar los Términos, usted obtiene el derecho a usar el
                  software del que la aplicación consta en conformidad con estos
                  Términos. Si usted no cumple con dichos Términos, Bayou se
                  reserva el derecho a retirarle el uso del Servicio.
                </p>
                <p>
                  <strong>2. Licencia</strong>
                </p>
                <p>
                  2.1&nbsp;&nbsp;&nbsp; Todos los derechos, no explícitamente
                  concedidos bajo estos Términos en su forma actual o adicional,
                  están retenidos por Bayou. El material que contiene la
                  aplicación puede estar protegido por propiedad intelectual y
                  eventualmente pertenecer a una tercera entidad. Estos Términos
                  no autorizan a nadie a utilizar dicho material.
                </p>
                <p>
                  2.2&nbsp;&nbsp;&nbsp; Su derecho a usar Tyra requiere que la
                  Escuela haya llegado a un acuerdo con Bayou. Si el acuerdo
                  entre la Escuela y Bayou se rompe, usted dejará de tener
                  derecho a utilizar Tyra.
                </p>
                <p>
                  <strong>3. Uso permitido</strong>
                </p>
                <p>
                  3.1&nbsp;&nbsp;&nbsp; El contrato firmado por la Escuela
                  implica el derecho a descargar, instalar, crear un usuario, y
                  usar Tyra en teléfono móvil, tablet, o cualquier otro
                  dispositivo preparado para ello (a partir de ahora referidos
                  como &ldquo;Dispositivos&rdquo;). Usted no debe intentar
                  copiar, descompilar, deconstruir, desarmar, derivar el código,
                  descifrar, modificar, o crear productos derivados basados
                  total o parcialmente en el software de la aplicación o de los
                  servicios dentro de ella, exceptuando lo establecido en la
                  legislación obligatoria, aquellos Términos y/o términos
                  contractuales respecto a cualquier componente basado en un
                  código abierto.
                </p>
                <p>
                  3.2&nbsp;&nbsp;&nbsp; Usted no debe usar el Servicio de manera
                  ilegal, así que usted se compromete a cumplir con todas las
                  leyes, normas y guías aplicables.&nbsp;Por ejemplo la Ley de
                  protección de datos personales, RGPD y la ley sobre la
                  propiedad intelectual.
                </p>
                <p>
                  3.3&nbsp;&nbsp;&nbsp; Usted no debe publicar contenido
                  discriminatorio, ilegal, humillante o que constituya una
                  infracción de los derechos de otras personas. Tyra no debe ser
                  usada de manera que implique difamación, bullying, persecución
                  o violación de la dignidad de otra persona.
                </p>
                <p>
                  3.4&nbsp;&nbsp;&nbsp; Los padres del niño, guardianes legales
                  u otros usuarios tienen que vigilar el uso del niño de la
                  aplicación.
                </p>
                <p>
                  3.5&nbsp;&nbsp;&nbsp; Si usted usa Tyra de manera que viole
                  alguno de estos Términos, o le da a Tyra cualquier otro uso
                  indebido, Bayou tiene el derecho de cancelar su cuenta de
                  manera unilateral y de bloquearle frente a futuros usos de
                  Tyra. Además, Bayou puede elegir retirar, editar, bloquear o
                  manejar de otra manera cualquier contenido, si Bayou considera
                  que puede estar violando estos Términos.
                </p>
                <p>
                  <strong>4. Limitación de la responsabilidad etc.</strong>
                </p>
                <p>
                  4.1&nbsp;&nbsp;&nbsp; Usted es consciente y está de acuerdo en
                  que Tyra se provee &ldquo;tal cual&rdquo;. A no ser que sea
                  requerido por legislación obligatoria, Bayou no se
                  responsabiliza de ninguna lesión personal, daño directo o
                  indirecto, o pérdidas consecuentes no relacionadas con Bayou o
                  cualquier otra circunstancia dentro del control de Bayou.
                </p>
                <p>
                  4.2&nbsp;&nbsp;&nbsp; Usted accede a defender y a mantener a
                  Bayou indemne frente a cualquier reclamación de terceras
                  partes (incluyendo pero no limitado a costes&nbsp;legales
                  razonables) que surjan de, estén conectadas con su
                  inclumplimiento de los Términos o de cualquier ley, regulación
                  o derecho de terceras partes.
                </p>
                <p>
                  <strong>5. Tratamiento de los datos personales</strong>
                </p>
                <p>
                  5.1&nbsp;&nbsp;&nbsp; La Escuela es responsable del
                  tratamiento tanto de sus datos personales como de los de su
                  hijo, cuando estos datos son recogidos por la Escuela, o
                  cuando usted como padre envía la información a través de Tyra
                  a la Escuela. Como responsable del tratamiento, la Escuela es
                  responsable de la protección de la integridad de sus datos y
                  los de su hijo, de acuerdo con Reglamento (UE) 2016/679 del
                  Parlamento Europeo y del Consejo, de 27 de abril de 2016,
                  relativo a la protección de las personas físicas en lo que
                  respecta al tratamiento de sus datos personales y a la libre
                  circulación de estos datos y por el que se deroga la Directiva
                  95/46/CE (Reglamento general de protección de datos), y de la
                  Ley Orgánica 3/2018, 5 Diciembre, sobre protección de datos
                  personales y garantía de derechos digitales, la Escuela en su
                  capacidad de responsable del tratamiento, está obligado a
                  informarle a usted como padre que tanto sus datos como los de
                  su hijo son recogidos, qué datos son almacenados, el motivo
                  del almacenaje y para qué son utilizados esos&nbsp;datos.
                </p>
                <p>
                  5.2&nbsp;&nbsp;&nbsp; Bayou es el procesador de datos
                  personales de la Escuela. Como procesador, Bayou solamente
                  puede procesar los datos de la manera especificada en el
                  contrato establecido entre Bayou y la Escuela, y solamente de
                  acuerdo con las instrucciones de procesamiento de personal
                  provistas por La Escuela a Bayou. Los datos personales
                  procesados o almacenados relacionados con el registro y uso de
                  Tyra son: nombre, dirección, número personal, alergias,
                  ausencias, imágenes y otros datos personales provistos en
                  Tyra&nbsp;en relación con los niños de La Escuela, sus padres,
                  otros parientes y personal de la Escuela.
                </p>
                <p>
                  5.3&nbsp;&nbsp;&nbsp; El propósito de la recolección y
                  procesamiento de datos personales en Tyra es para que usted,
                  como padre, pueda registrarse como usuario y ser capaz de
                  utilizar el Servicio de manera adecuada. Bayou solamente puede
                  utilizar sus datos y los de su hijo para este propósito y
                  solamente durante el tiempo necesario que le permita a usted
                  usar el Servicio.
                </p>
                <p>
                  5.4&nbsp;&nbsp;&nbsp; Bayou deberá proveer la documentación
                  acerca de sus datos personales si la Escuela o usted como
                  padre la solicitas. Usted como padre tiene derecho a solicitar
                  que se elimine su información, o rectifique información
                  incorrecta directamente a Bayou. Si usted como padre desea
                  recibir información acerca de los datos personales procesados
                  por Bayou en nombre de la Escuela, o solicitar la
                  rectificación de datos, dicha solicitud se debe hacer por
                  escrito a La Escuela o directamente a Bayou y debe contener su
                  nombre, número personal, dirección y debe estar firmada por
                  usted. Si quiere transmitir la solicitud directamente a Bayou,
                  esta debe ser enviada a: Bayou, Paseo de la Castellana 121,
                  28046 Madrid o por email a hello@tyra.io
                </p>
                <p>
                  5.5&nbsp;&nbsp;&nbsp; Bayou le ha dado a la Escuela
                  suficientes garantías para implementar las medidas técnicas y
                  organizativas apropiadas para que el procesamiento de datos
                  cumpla con los requerimientos de la Regulación General de
                  Protección de Datos o cualquier otra regulación aplicable y
                  para asegurar que tanto sus derechos como los de su hijo están
                  protegidos.
                </p>
                <p>
                  5.6&nbsp;&nbsp;&nbsp; Bayou almacena los datos relativos a
                  usted como usuario de manera que asegura que se cumplen los
                  reglamentos presentes acerca del procesamiento de datos, y que
                  los datos se almacenan de manera segura. Bayou usa
                  Elastx&nbsp;para las operaciones técnicas de Tyra.
                  Elastx&nbsp;cumple los requisitos de la UE para el
                  procesamiento de datos personales. Si Elastx&nbsp;falla a la
                  hora de cumplir con los requisitos de la UE para el
                  procesamiento de datos, Bayou tiene el derecho de contratar a
                  otro subcontratista que asegure que los datos personales se
                  manejan con el nivel de protección adecuado y de acuerdo con
                  la ley.
                </p>
                <p>
                  5.7&nbsp;&nbsp;&nbsp; Bayou se compromete a asegurar que todas
                  las personas que trabajan para Bayou y que tengan acceso a
                  datos personales solo los procesan bajo las instrucciones de
                  la Escuela, salvo en caso que esas instrucciones vayan contra
                  la ley. Bayou no tiene derecho a usar los datos personales de
                  Tyra para sus propósitos particulares. Bayou debe cumplir con
                  las instrucciones de la Escuela respecto a los datos
                  personales de Tyra, siempre y cuando estas no entren en
                  conflicto con la ley. Bayou debe informar inmediatamente a la
                  Escuela si considera que alguna de sus instrucciones puede ir
                  en contra de la ley, y esperar nuevas instrucciones por parte
                  de La Escuela. Bayou debe asistir a la Escuela si ésta así lo
                  solicita cuando la Escuela vaya a cumplir sus obligaciones
                  legales, ej: aquellas obligaciones que surgen del Capítulo 3,
                  y Artículos 32-36 de la regulación de protección de datos.
                </p>
                <p>
                  5.8&nbsp;&nbsp;&nbsp; Bayou se compromete, teniendo en cuenta
                  el estado de la técnica, el coste de implementación y la
                  naturaleza, alcance, contexto y propósitos del procesamiento,
                  además de los riesgos de variar la probabilidad y gravedad de
                  los derechos y libertades de personas naturales planteados por
                  el procesamiento, a implementar las medidas técnicas y
                  organizativas adecuadas para asegurar un nivel de seguridad
                  acorde al riesgo, incluyendo, cuando se deba:
                </p>
                <ul>
                  <li>
                    &nbsp;&nbsp;&nbsp;pseudonimización&nbsp;y encriptación de
                    datos personales (ej. sus datos personales serán
                    anonimizados o pseudonimizados&nbsp;cuando borre su cuenta,
                    pero los datos tienen que seguir siendo procesados por la
                    conexión con su hijo).
                  </li>
                  <li>
                    &nbsp;&nbsp;&nbsp;la capacidad de asegurar la constante
                    confidencialidad, integridad, disponibilidad y resiliencia
                    de los dispositivos y sistemas de procesamiento.
                  </li>
                  <li>
                    &nbsp;&nbsp;&nbsp;la capacidad de restaurar la
                    disponibilidad y acceso a datos personales revirtiendo Tyra
                    a una versión anterior, en el caso de un problema físico o
                    técnico fuera del control de Bayou.
                  </li>
                  <li>
                    &nbsp;&nbsp;&nbsp;un proceso de comprobación regular,
                    evaluando la efectividad de las medidas técnicas y
                    organizativas para asegurar la seguridad del proceso.
                  </li>
                </ul>
                <p>
                  5.9&nbsp;&nbsp;&nbsp; Si un niño, y/o usuario vinculado al
                  niño, no se conecta en un periodo mayor a seis meses, Bayou se
                  reserva el derecho a borrar el perfil.
                </p>
                <p>
                  5.10&nbsp;&nbsp;&nbsp; En el caso de que una Escuela finalice
                  un acuerdo, Bayou puede exportar todos los datos de Tyra para
                  La Escuela. Cuando Bayou deja de procesar los datos personales
                  de su hijo y suyos en nombre de La Escuela, Bayou debe
                  destruir y borrar cualquier dato relacionado con el acuerdo
                  iniciado entre Bayou y La Escuela. En el caso de que un niño
                  sea borrado en Tyra, existe la posibilidad de exportar los
                  datos a través de funciones de Tyra antes de borrar el perfil.
                </p>
                <p>
                  5.11&nbsp;&nbsp;&nbsp; Después de que el contrato entre Bayou
                  y La Escuela se termine, por cualquiera de las partes o por
                  cualquier otra causa, este deja de ser válido. Los derechos de
                  Bayou de procesar o utilizar datos personales pertenecientes a
                  La Escuela también cesan. Bayou borrará todos los datos
                  personales procesados para la cuenta pertenecientes a La
                  Escuela, antes de que pasen 30 días desde la finalización del
                  contrato. Así, Bayou no tiene el derecho de guardar ningún
                  dato personal sobre usted o su hijo (a menos que se requiera
                  el almacenamiento de datos personales de acuerdo con la
                  legislación nacional o de la Unión o el proveedor tenga una
                  base legal para procesar los datos personales relevantes).
                </p>
                <p>
                  <strong>6. Datos</strong>
                </p>
                <p>
                  Al aceptar estos Términos, usted está de acuerdo con que Bayou
                  pueda usar los datos registrados, direcciones, y mails para la
                  distribución de información relevante para el uso de productos
                  y servicios bajo estos Términos o el uso de otros productos de
                  Bayou. Bayou también puede utilizar la información para enviar
                  cuestionarios con el objetivo de desarrollar Tyra de la mejor
                  manera posible.
                </p>
                <p>
                  <strong>7. Otros</strong>
                </p>
                <p>
                  7.1&nbsp;&nbsp;&nbsp; Bayou se reserva el derecho de, en
                  cualquier momento, sin responsabilidad por daños y perjuicios,
                  de actualizar, mejorar, modificar, restringir temporalmente o
                  cerrar Tyra o su acceso a Tyra. Esto puede ocurrir, por
                  ejemplo, si usted retira su consentimiento para procesar datos
                  personales.
                </p>
                <p>
                  7.2&nbsp;&nbsp;&nbsp; Estos Términos pueden ser cambiados de
                  manera unilateral por Bayou si se le manda un mensaje claro y
                  conciso a través de Tyra. En el caso de que usted esté de
                  acuerdo con los nuevos Términos o continúe usando Tyra después
                  de que se hayan aplicado estos Términos, se considerará que
                  usted los ha aceptado. En caso contrario, deberá desinstalar
                  Tyra de todos sus dispositivos.
                </p>
                <p>
                  7.3&nbsp;&nbsp;&nbsp; Los Términos también se aplican a las
                  futuras actualizaciones de Tyra provistas por Bayou y que
                  reemplaza y/o constituye una adición a Tyra, siempre y cuando
                  esto no esté cubierto por un acuerdo por separado.
                </p>
                <p>
                  7.4&nbsp;&nbsp;&nbsp; Cualquier disputa surgida entre las
                  partes deberá ser resuelta en un tribunal general con Malmö
                  District Court como primera instancia y con la aplicación de
                  la ley sueca.
                </p>
                <p>
                  7.5&nbsp;&nbsp;&nbsp; Al aceptar estos Términos usted acepta
                  también el uso regular de la firma electrónica a distancia
                  entre usted y la Escuela para las comunicaciones y
                  consentimientos que esta última pueda necesitar.
                </p>
                <p>
                  <strong>8. Información de contacto</strong>
                </p>
                <p>
                  Bayou AB, 556983-2867
                  <br />
                  Paseo de la Castellana 121
                  <br />
                  28046 Madrid
                  <br />
                  España
                  <br />
                  <a href="mailto:hello@tyra.io">hello@tyra.io</a>
                </p>
              </>
            ) : (
              <>
                {' '}
                <strong>
                  <p>
                    <>{t('settings.infoUserHeader')}</>
                  </p>
                </strong>
                <strong>
                  <p>
                    1. <>{t('settings.infoUserHeader1')}</>
                  </p>
                </strong>
                <p>
                  <>{t('settings.infoUserText1')}</>
                </p>
                <p>
                  <>{t('settings.infoUserText2')}</>
                </p>
                <p>
                  <>{t('settings.infoUserText3')}</>
                </p>
                <strong>
                  <p>
                    2. <>{t('settings.infoUserHeader2')}</>
                  </p>
                </strong>
                <p>
                  2.1 <>{t('settings.infoUserText4')}</>
                </p>
                <p>
                  2.2 <>{t('settings.infoUserText5')}</>
                </p>
                <strong>
                  <p>
                    3. <>{t('settings.infoUserHeader3')}</>
                  </p>
                </strong>
                <p>
                  3.1 <>{t('settings.infoUserText6')}</>
                </p>
                <p>
                  3.2 <>{t('settings.infoUserText7')}</>
                </p>
                <p>
                  3.3 <>{t('settings.infoUserText8')}</>
                </p>
                <p>
                  3.4 <>{t('settings.infoUserText9')}</>
                </p>
                <p>
                  3.5 <>{t('settings.infoUserText10')}</>
                </p>
                <strong>
                  <p>
                    4. <>{t('settings.infoUserHeader4')}</>
                  </p>
                </strong>
                <p>
                  4.1 <>{t('settings.infoUserText11')}</>
                </p>
                <p>
                  4.2 <>{t('settings.infoUserText12')}</>
                </p>
                <strong>
                  <p>
                    5. <>{t('settings.infoUserHeader5')}</>
                  </p>
                </strong>
                <p>
                  5.1 <>{t('settings.infoUserText13')}</>
                  <a href="mailto:hello@tyra.io">hello@tyra.io</a>{' '}
                  <>{t('settings.infoUserText14')}</>
                </p>
                <p>
                  5.2 <>{t('settings.infoUserText15')}</>
                </p>
                <strong>
                  <p>
                    6. <>{t('settings.infoUserHeader6')}</>
                  </p>
                </strong>
                <p>
                  6.1 <>{t('settings.infoUserText16')}</>
                </p>
                <p>
                  6.2 <>{t('settings.infoUserText17')}</>
                </p>
                <p>
                  6.3 <>{t('settings.infoUserText18')}</>
                </p>
                <strong>
                  <p>
                    7. <>{t('settings.infoUserHeader7')}</>
                  </p>
                </strong>
                Bayou AB, 556983-2867
                <br />
                Grisslevägen 19
                <br />
                227 32 Lund
                <br />
                <>{t('settings.infoUserCountry')}</>
                <br />
                <a href="mailto:hello@tyra.io">hello@tyra.io</a>
              </>
            )}
          </div>
        </div>
      )}
      {activeTab === t('settings.freeTextField') && (
        <div>
          {' '}
          <div className="p-4">
            <>{t('settings.freeTextFieldText')}</>
          </div>
        </div>
      )}
      {activeTab === t('settings.staffInfoAndStorage') && (
        <div>
          {' '}
          <div className="p-4 flex flex-col gap-y-4">
            <p>
              {' '}
              <>{t('settings.infoDataHeader')} </>
            </p>
            <>{t('settings.infoDataText1')}</>
            <p>
              <>{t('settings.infoDataText2')}</>
            </p>
            <div>
              <p>
                <>{t('settings.infoDataText3')}</>
              </p>
              <p className="flex flex-row gap-x-2">
                {' '}
                <p className="font-bold">1.</p>{' '}
                <p>
                  {' '}
                  <>{t('settings.infoDataPoint1')}</>{' '}
                </p>
              </p>
              <p className="flex flex-row gap-x-2">
                <p className="font-bold">2.</p>
                <p>
                  {' '}
                  <>{t('settings.infoDataPoint2')}</>
                </p>
              </p>
            </div>
            <div>
              <p>
                {' '}
                <>{t('settings.infoDataPointBreak')}</>
              </p>
              <p className="flex flex-row gap-x-2">
                <p className="font-bold">3.</p>{' '}
                <p>
                  {' '}
                  <>{t('settings.infoDataPoint3')}</>
                </p>
              </p>
              <p className="flex flex-row gap-x-2">
                <p className="font-bold">4.</p>
                <p>
                  {' '}
                  <>{t('settings.infoDataPoint4')}</>
                </p>
              </p>
            </div>
            <>{t('settings.infoDataEndText')}</>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-y-4 w-full justify-center items-center">
        {' '}
      </div>
    </Page>
  )
}
