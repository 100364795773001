import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { Event } from '../../../Models/Event'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

interface Props {
  event: Event
  dateStamp: Boolean
  day: string
}

export const EventsDateCircle = observer((props: Props) => {
  let bgClass = 'xxx'
  if (props.event.type == 'Föräldramöte') {
    bgClass = 'bg-foraldraMote'
  } else if (props.event.type == 'Övrigt') {
    bgClass = 'bg-ovrigt'
  } else if (props.event.type == 'Föräldraförening') {
    bgClass = 'bg-foraldraForening'
  } else if (props.event.type == 'Nyheter') {
    bgClass = 'bg-nyheter'
  } else if (props.event.type == 'Studiedagar') {
    bgClass = 'bg-studiodagar'
  } else if (props.event.type == 'Personal') {
    bgClass = 'bg-personal'
  }

  return (
    <>
      <div
        className={
          'h-16 w-16 2xl:h-24 2xl:w-24 rounded-full flex justify-center items-center bg-eventsMain text-white text-center ' +
          bgClass
        }
      >
        {props.dateStamp && (
          <div className="grid grid-cols-1">
            <p className="font-bold text-sm 2xl:text-2xl">{props.day}</p>

            <p className="font-bold text-lg 2xl:text-2xl">
              {moment(props.event.date).format('DD')}
            </p>
          </div>
        )}
      </div>
    </>
  )
})
