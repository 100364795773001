import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  isOpen: boolean
  onClose: any
  title?: string
  className?: string
  overlayClassName?: string
  bgColor?: string
  closeColor?: string | undefined
}

export default function DocSettings({
  bgColor,
  children,
  isOpen,
  onClose,
  title,
  className,
  overlayClassName,
  closeColor,
}: Props) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        style={{ zIndex: 11000 }}
        as="div"
        className="fixed inset-0 overflow-y-auto "
        onClose={() => onClose()}
      >
        <Dialog.Overlay
          className={classNames(
            'fixed inset-0 bg-black opacity-50',
            overlayClassName,
          )}
        />

        <div className="min-h-screen px-3 text-center">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                'relative rounded-3xl inline-block w-full',
                'max-w-md my-8 overflow-hidden text-left align-middle',
                ' transition-all transform bg-white shadow-xl',
                className,
              )}
            >
              <div
                className={classNames(
                  'flex rounded-t-2xl justify-center  ',

                  bgColor,
                )}
              >
                <Dialog.Title
                  as="p"
                  className="text-xl font-medium  justify-center p-4 leading-6 text-white"
                >
                  {title}
                </Dialog.Title>
                <button
                  type="button"
                  className="-m-4 p-2 text-gray-500 absolute right-6 top-6 outline-0"
                  onClick={(e) => {
                    e.stopPropagation()
                    onClose()
                  }}
                  style={{
                    color: closeColor || '#e5e5e5', // Replace 'DEFAULT_COLOR' with a suitable default
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = '#e5e5e5')}
                  onMouseOut={(e) =>
                    (e.currentTarget.style.color = closeColor || '#e5e5e5')
                  }
                >
                  <span className="sr-only">Close panel</span>
                  <FontAwesomeIcon className="h-6 w-6" icon={faClose} />
                </button>
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
