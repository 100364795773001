import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Listbox } from '@headlessui/react'

interface Props {
  items: any[]
  onChange: (e: any) => void
  itemVariableKey: string
  placeholder: string
  initialSelectedItem?: any | null
}

const Singleselect = (props: Props) => {
  const [selectedItem, setSelectedItem] = useState(
    props.initialSelectedItem ?? null,
  ) as any
  console.log('props.initialSelectedItem', props.initialSelectedItem)
  console.log('selectedItem', selectedItem)
  useEffect(() => {
    setSelectedItem(props.initialSelectedItem ?? null)
  }, [props.initialSelectedItem])

  return (
    <Listbox
      value={selectedItem ? selectedItem : ''}
      onChange={(items) => {
        setSelectedItem(items ? items : null)
        props.onChange(items ? items : null)
      }}
    >
      {/*<Listbox.Options className="absolute top-[100%] z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">*/}
      <div className=" relative bg-white shadow-md rounded-2xl border overflow-x-hidden border-gray-200 min-w-[150px] max-w-[470px] w-full h-auto min-h-[100px] max-h-[300px] p-3 list-none">
        {props.items?.map((item) => {
          return (
            <Listbox.Option
              key={item.id}
              value={item}
              className="relative hover:bg-blue-50 select-none py-2 text-center cursor-pointer"
            >
              {item[props.itemVariableKey]}
              {selectedItem && selectedItem === item && (
                <FontAwesomeIcon
                  className="mr-4 text-green-500 absolute right-0 top-[12px]"
                  icon={faCheck}
                ></FontAwesomeIcon>
              )}
              {props.initialSelectedItem &&
                props.initialSelectedItem.value === item.value && (
                  <FontAwesomeIcon
                    className="mr-4 text-green-500 absolute right-0 top-[12px]"
                    icon={faCheck}
                  ></FontAwesomeIcon>
                )}
            </Listbox.Option>
          )
        })}
        {/*</Listbox.Options>*/}
      </div>
    </Listbox>
  )
}

export default Singleselect
