import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { DocumentStoreContext } from '../../../store/documentStore'
import { UserStoreContext } from '../../../store/userStore'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'

export const ReadAllModal: React.FC<{
  type: string
  onDone: any
  folderId?: string
  kidId?: string
  depId?: string
  hideReadAll?: boolean
}> = observer(({ type, onDone, folderId, kidId, depId }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { savedUserRoleId, currentUserRole } = useContext(UserStoreContext)
  const { readAll } = useContext(DocumentStoreContext)
  return (
    <div
      className={
        'text-eventsMain bg-blue-50 cursor-pointer py-3 px-4 border-eventsMain hover:scale-[102%] hover:bg-red-50 rounded-3xl shadow hover:text-red-400'
      }
      onClick={async () => {
        Swal.fire({
          //title: 'Are you sure?',
          text: t('documents.readAllMessage'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.yes'),
          cancelButtonText: t('general.cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true)
            await readAll({
              type,
              userRoleId: savedUserRoleId ?? '',
              folderId: folderId,
              kidId: kidId,
              depId: depId,
            }).finally(() => {
              setLoading(false)
            })

            onDone()
          }
        })
      }}
    >
      {loading ? (
        <div className="flex items-center justify-center ">
          <div className={classNames('transparent' && 'text-eventsMain')}>
            <FontAwesomeIcon
              className="w-5 h-5 text-base animate-spin"
              icon={faSpinner}
            />
          </div>
        </div>
      ) : (
        <p>{t('documents.readAll')}</p>
      )}
    </div>
  )
})
