// AudioModal.tsx
import React, { useContext, useEffect, useRef, useState } from 'react'
import ModalRegular from '../../../components/ModalRegular'
import { useTranslation } from 'react-i18next'
import { UserStoreContext } from '../../../store/userStore'
import { Loading } from '../../../components/Loading'
import CustomInput from '../../../components/CustomInput'
import {
  getImageFromRole,
  getUserNameFromRole,
} from '../../../utils/parseUtils'
import { Button } from '../../../components/Button'
import { ImageUploader } from '../../../components/ImageUploader'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDotCircle,
  faEye,
  faEyeSlash,
} from '@fortawesome/pro-solid-svg-icons'
import { User } from 'parse'
import { faDeleteLeft } from '@fortawesome/pro-light-svg-icons'
import {
  LOGIN_METHOD,
  TERRITORY,
  USER_SETTING,
  USER_TYPE,
} from '../../../Enums/TyraEnums'
import PinInputModal from './PinInputModal'

import { useNavigate } from 'react-router-dom'
import { AdminStoreContext } from '../../../store/adminStore'

type EditProfilModalProps = {
  showModal: boolean
  hideModal: () => void
  user?: User
}

const EditProfilModal: React.FC<EditProfilModalProps> = ({
  showModal,
  hideModal,
  user,
}) => {
  const {
    currentUser,
    getCurrentUserInfo,
    changeUserSetting,
    currentUserRole,
    saveUserChanges,
    deleteUser,
  } = useContext(UserStoreContext)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const handleImageChange = (file: File) => {
    setUploadedImage(file)
    // Gör något annat med bilden om du vill, till exempel skicka den till en server.
  }

  const initialImage = getImageFromRole(currentUserRole)
  const [uploadedImage, setUploadedImage] = useState<File | null>(null)
  useEffect(() => {
    setLoading(true)
    getCurrentUserInfo()
  }, [])
  function confirmAction() {
    if (currentUserRole) {
      deleteUserAccount([currentUserRole], 200)
        .then(() => {
          logoutUser()
        })
        .finally(() => {
          navigate('/')
        })
    }
  }

  function cancelAction() {
    Swal.close()
  }

  function extraAction() {
    if (currentUserRole) {
      deleteUserAccount([currentUserRole], 220)
        .then(() => {
          logoutUser()
        })
        .finally(() => {
          navigate('/')
        })
    }
  }
  const [openPinCodeModal, setOpenPinCodeModal] = useState(false)
  const [openDisplayModal, setOpenDisplayModal] = useState(false)
  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)
  //const chooseDisplayRef = useRef<HTMLInputElement>(null)
  const psnRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const phoneRef = useRef<HTMLInputElement>(null)
  const otherPhoneRef = useRef<HTMLInputElement>(null)
  const adressRef = useRef<HTMLInputElement>(null)
  const zipRef = useRef<HTMLInputElement>(null)
  const cityRef = useRef<HTMLInputElement>(null)
  const [selectedObjectIds, setSelectedObjectIds] = useState<string[]>([])
  const [enteredPin, setEnteredPin] = useState('')

  const { deleteUserAccount } = useContext(AdminStoreContext)
  const handleNumberClick = (number: any) => {
    if (enteredPin.length < 4) {
      setEnteredPin(enteredPin + number)
    }
  }
  const handleDeleteClick = () => {
    setEnteredPin(enteredPin.slice(0, -1))
  }
  const [showPin, setShowPin] = useState(false)
  const [fakeState, setFakeState] = useState(false)

  const showSetting = () => {
    if (currentUser?.attributes.user_setting === USER_SETTING.SHOW_ALL) {
      return t('settings.displayInfoHeader1')
    } else if (
      currentUser?.attributes.user_setting === USER_SETTING.HIDE_CONTACT_INFO
    ) {
      return t('settings.displayInfoHeader2')
    } else if (currentUser?.attributes.user_setting === USER_SETTING.HIDE_ALL) {
      return t('settings.displayInfoHeader3')
    } else {
      return t('settings.displayInfoHeader1')
    }
  }

  const formatNIN = (nin: string) => {
    if (
      nin &&
      nin.length > 8 &&
      currentUserRole?.school_pointer.attributes.territory !== TERRITORY.SPAIN
    ) {
      return nin.slice(0, 8) + '-' + nin.slice(8)
    }
    return nin || ''
  }
  const showLoginMethod = () => {
    if (currentUser?.attributes?.user_type === LOGIN_METHOD.EMAIL) {
      return t('login.email')
    } else if (currentUser?.attributes?.user_type === LOGIN_METHOD.GRANDID) {
      return t('login.bankid')
    } else if (currentUser?.attributes?.user_type === LOGIN_METHOD.PHONE) {
      return t('settings.mobilNumberPassword')
    } else {
      return ''
    }
  }
  const handlePinConfirmed = (confirmedPin: string) => {
    setEnteredPin(confirmedPin)
    setOpenPinCodeModal(false)
  }
  const [openNin, setOpenNin] = useState(false)

  const { logoutUser } = useContext(UserStoreContext)
  const navigate = useNavigate()

  return (
    <>
      {loading && <Loading />}

      <ModalRegular
        isOpen={showModal}
        onClose={() => {
          hideModal()
          if (currentUser) {
            currentUser.revert()
            currentUser.attributes.user_extra.revert()
          }
        }}
        bgColor="bg-eventsMain p-4"
        title={t('settings.myProfile')}
        closeColor="white"
      >
        <div className="p-10 gap-y-2 flex flex-col">
          <div className="flex flex-row w-full justify-center items-center mb-2">
            <div>
              <ImageUploader
                onImageChange={handleImageChange}
                initialImageUrl={initialImage}
                size="lg"
              />
            </div>
          </div>
          <div className="w-full">
            {' '}
            <CustomInput
              ref={firstNameRef}
              label={t('admin.firstName')}
              value={currentUser?.attributes?.firstName}
              onChange={(value) => {
                if (currentUser) {
                  currentUser.set('firstName', value)
                }
                setFakeState(!fakeState)
              }}
            />
          </div>
          <div className="w-full">
            {' '}
            <CustomInput
              ref={lastNameRef}
              label={t('admin.surName')}
              value={currentUser?.attributes?.lastName}
              onChange={(value) => {
                if (currentUser) {
                  currentUser.set('lastName', value)
                }
                setFakeState(!fakeState)
              }}
            />
          </div>
          <h1 className=" pt-2 text-sm font-bold text-gray-400">
            <>{t('settings.chooseDisplayVH')}</>
          </h1>
          <CustomInput
            onChange={function (value: string): void {
              throw new Error('Function not implemented.')
            }}
            value={showSetting()}
            onClick={() => {
              setOpenDisplayModal(true)
            }}
            //ref={chooseDisplayRef}
          />
          <ModalRegular
            isOpen={openDisplayModal}
            onClose={() => {
              setOpenDisplayModal(false)
            }}
            className="max-w-xl"
            bgColor="bg-eventsMain p-7"
            closeColor="white"
          >
            <div className="p-10">
              <h1 className="text-eventsMain font-bold mb-10">
                <>{t('settings.chooseDisplayVH')}</>
              </h1>

              <div
                onClick={() => {
                  changeUserSetting(
                    currentUser as User,
                    USER_SETTING.SHOW_ALL,
                  ).finally(() => {
                    setFakeState(!fakeState)
                  })
                }}
                className="cursor-pointer"
              >
                <h2 className="text-lg font-medium">
                  <>{t('settings.displayInfoHeader1')}</>
                </h2>
                <div className="mb-4 pb-4 border-b flex flex-row items-center justify-between">
                  <p className="max-w-[500px]">
                    <>{t('settings.displayInfo1')}</>
                  </p>
                  <FontAwesomeIcon
                    icon={faDotCircle}
                    className={
                      currentUser?.attributes.user_setting ===
                        USER_SETTING.SHOW_ALL ||
                      currentUser?.attributes.user_setting === undefined
                        ? 'text-blue-400'
                        : 'text-gray-400'
                    }
                  />
                </div>
              </div>
              <div
                onClick={() => {
                  changeUserSetting(
                    currentUser as User,
                    USER_SETTING.HIDE_CONTACT_INFO,
                  ).finally(() => {
                    setFakeState(!fakeState)
                  })
                }}
                className="cursor-pointer"
              >
                <h2 className="text-lg font-medium">
                  {' '}
                  <>{t('settings.displayInfoHeader2')}</>
                </h2>
                <div className="border-b mb-4 pb-4 flex flex-row items-center justify-between">
                  <p className="max-w-[450px]">
                    <>{t('settings.displayInfo2')}</>
                  </p>
                  <FontAwesomeIcon
                    icon={faDotCircle}
                    className={
                      currentUser?.attributes.user_setting ===
                      USER_SETTING.HIDE_CONTACT_INFO
                        ? 'text-blue-400'
                        : 'text-gray-400'
                    }
                  />
                </div>
              </div>
              <div
                onClick={() => {
                  changeUserSetting(
                    currentUser as User,
                    USER_SETTING.HIDE_ALL,
                  ).finally(() => {
                    setFakeState(!fakeState)
                  })
                }}
                className="cursor-pointer"
              >
                <h2 className="text-lg font-medium">
                  {' '}
                  <>{t('settings.displayInfoHeader3')}</>
                </h2>
                <div className="mb-2 flex flex-row items-center justify-between">
                  <p className="max-w-[500px]">
                    <>{t('settings.displayInfo3')}</>
                  </p>
                  <FontAwesomeIcon
                    icon={faDotCircle}
                    className={
                      currentUser?.attributes.user_setting ===
                      USER_SETTING.HIDE_ALL
                        ? 'text-blue-400'
                        : 'text-gray-400'
                    }
                  />
                </div>
              </div>
            </div>
          </ModalRegular>
          <h1 className=" pt-2 text-sm font-bold text-gray-400">
            <>{t('settings.loginMethod')}</>
          </h1>
          <CustomInput onChange={() => {}} value={showLoginMethod()} />

          <h1 className=" pt-2 text-sm font-bold text-gray-400">
            <>{t('settings.myInfo')}</>
          </h1>
          {currentUser?.attributes.user_type === LOGIN_METHOD.GRANDID ? (
            <CustomInput
              label={t('general.socialNr')}
              format={'YYYYMMDD-XXXX'}
              onChange={() => {}}
              ref={psnRef}
              value={formatNIN(
                currentUser?.attributes?.user_nin.attributes.nin,
              )}
            />
          ) : (
            <CustomInput
              label={t('general.socialNr')}
              format={'YYYYMMDD-XXXX'}
              onChange={() => {}}
              ref={psnRef}
              value={formatNIN(
                currentUser?.attributes?.user_nin.attributes.nin,
              )}
              onClick={() => {
                setOpenNin(true)
              }}
            />
          )}
          <ModalRegular
            isOpen={openNin}
            onClose={() => {
              setOpenNin(false)
            }}
            bgColor="bg-eventsMain p-7"
            closeColor="white"
            className="max-w-xl"
          >
            <div className="p-10">
              <div className="flex flex-col items-center gap-y-4 ">
                <div className="flex flex-col gap-x-2">
                  <p className="text-sm font-semibold text-eventsMain mb-2">
                    <>{t('general.personalNumberFormat')}</>
                  </p>
                  <div className="flex flex-col sm:flex-row w-full justify-between gap-y-4 gap-x-4">
                    <input className="border shadow p-2 rounded-lg  focus:outline-none focus:ring-eventsMain focus:border-eventsMain " />

                    <Button
                      label={t('login.verifyWithBankId')}
                      variant={'bankId'}
                      size="sm"
                    />
                  </div>
                </div>

                <div className="flex justify-between w-full sm:px-[53px]">
                  <Button
                    size="sm"
                    label={t('general.cancel')}
                    variant="fullRed"
                  />
                  <Button
                    size="sm"
                    label={t('general.save')}
                    variant="fullBlue"
                  />
                </div>
              </div>
            </div>
          </ModalRegular>

          {currentUser?.attributes.user_type === LOGIN_METHOD.EMAIL ? (
            <CustomInput
              label={t('login.email')}
              onChange={() => {}}
              ref={emailRef}
              value={currentUser?.attributes?.email}
              //DISABLED
            />
          ) : (
            <CustomInput
              label={t('login.email')}
              onChange={(value) => {
                if (currentUser) {
                  currentUser.set('email', value)
                }
                setFakeState(!fakeState)
              }}
              ref={emailRef}
              value={currentUser?.attributes?.email}
            />
          )}
          {currentUser?.attributes.user_type === LOGIN_METHOD.PHONE ? (
            <CustomInput
              label={t('ourKids.phone')}
              onChange={() => {}}
              ref={phoneRef}
              value={
                currentUser?.attributes?.user_extra.attributes.primary_phone
              }
            />
          ) : (
            <CustomInput
              label={t('ourKids.phone')}
              onChange={(value) => {
                if (currentUser) {
                  currentUser.attributes.user_extra?.set('primary_phone', value)
                }
                setFakeState(!fakeState)
              }}
              ref={phoneRef}
              value={
                currentUser?.attributes?.user_extra.attributes.primary_phone
              }
            />
          )}

          <CustomInput
            label={t('admin.otherNumber')}
            onChange={(value) => {
              if (currentUser) {
                currentUser.attributes.user_extra?.set('secondary_phone', value)
              }
              setFakeState(!fakeState)
            }}
            ref={otherPhoneRef}
            value={
              currentUser?.attributes?.user_extra?.attributes?.secondary_phone
            }
          />
          <CustomInput
            label={t('contactList.adress')}
            onChange={(value) => {
              if (currentUser) {
                currentUser.attributes.user_extra?.set('street_address', value)
              }
              setFakeState(!fakeState)
            }}
            ref={adressRef}
            value={
              currentUser?.attributes?.user_extra?.attributes?.street_address
            }
          />
          <CustomInput
            label={t('admin.areaCode')}
            onChange={(value) => {
              if (currentUser) {
                currentUser.attributes.user_extra?.set('zip_code', value)
              }
              setFakeState(!fakeState)
            }}
            ref={zipRef}
            value={currentUser?.attributes?.user_extra?.attributes?.zip_code}
          />
          <CustomInput
            label={t('admin.city')}
            onChange={(value) => {
              if (currentUser) {
                currentUser.attributes.user_extra?.set('city', value)
              }
              setFakeState(!fakeState)
            }}
            ref={cityRef}
            value={currentUser?.attributes?.user_extra?.attributes?.city}
          />
          <div className="flex flex-row justify-between w-full mt-5">
            <Button
              size="custom"
              variant={'fullRed'}
              label={t('settings.deleteAccount')}
              onClick={() => {
                Swal.fire({
                  html: `
                      <style>
                        .swal2-styled-button {
                          display: block;
                          width: 85%;
                          padding: 8px 15px;
                          border-radius: 20px;
                          color: white;
                          border: none;
                          cursor: pointer;
                          font-weight: 420; /* Adjusts the font-weight */
                        }
                        .red-button {
                          background-color: #d33;
                        }
                        .red-button:hover {
                          background-color: #e35d5b;
                        }
                        .blue-button {
                          background-color: #7eb5f4;
                        }
                        .blue-button:hover {
                          background-color: #9ec6f7;
                        }
                        .swal2-styled-button:first-child {
                          margin-top: 20px; /* Adds margin-top to the first button */
                        }
                      </style>
                      <p>${t('settings.deleteAccountText1')} ${t(
                    'settings.deleteAccountText2',
                  )} ${t('settings.deleteAccountText3')}</p>
                      <div style="display: flex; flex-direction: column; align-items: center; gap: 10px;">
                        <button id="extraButton" class="swal2-styled-button red-button">Radera och avidentifiera</button>
                        <button id="confirmButton" class="swal2-styled-button red-button">Radera</button>
                        <button id="cancelButton" class="swal2-styled-button blue-button">Avbryt</button>
                      </div>`,
                  icon: 'warning',
                  showConfirmButton: false,
                  focusConfirm: false,
                  showCancelButton: false,
                  cancelButtonColor: '#7eb5f4',
                  didOpen: () => {
                    document
                      .getElementById('confirmButton')
                      ?.addEventListener('click', confirmAction)
                    document
                      .getElementById('cancelButton')
                      ?.addEventListener('click', cancelAction)
                    document
                      .getElementById('extraButton')
                      ?.addEventListener('click', extraAction)
                  },
                })
              }}
            />
            <Button
              variant={'fullBlue'}
              size="custom"
              label={t('general.save')}
              onClick={() => {
                if (currentUser) {
                  saveUserChanges(currentUser).finally(() => {
                    hideModal()
                  })
                }
              }}
            />
          </div>
        </div>
      </ModalRegular>
    </>
  )
}

export default EditProfilModal
