import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import React from 'react'

class NotificationManager {
  _notifications: Notification[] = []

  constructor() {
    makeAutoObservable(this)
  }

  get notifications() {
    return this._notifications
  }

  notify = (notification: NotificationInput) => {
    const id = nanoid()
    this._notifications.unshift({
      id,
      ...notification,
      timeout: setTimeout(() => {
        this.closeNotification(id)
      }, notification.timeout ?? 5000),
    })

    this._notifications = this._notifications.slice(0, 3)
  }

  closeNotification = (notificationId: string) => {
    clearTimeout(
      this._notifications.find((n) => n.id === notificationId)
        ?.timeout as number,
    )
    this._notifications = this._notifications.filter(
      (n) => n.id !== notificationId,
    )
  }
}

export const notificationManager = (() => {
  return new NotificationManager()
})()
export const NotificationContext: React.Context<NotificationManager> =
  React.createContext(notificationManager)

export enum NotificationVariant {
  DEFAULT = 'default',
  WARN = 'warning',
  ERROR = 'error',
  INFO = 'info',
  OK = 'success',
}

export type Notification = {
  id: string
  timeout: NodeJS.Timeout | number
  title: string
  message?: string
  variant: NotificationVariant
}

export type NotificationInput = {
  timeout?: number
  title: string
  message?: string
  variant: NotificationVariant
}
