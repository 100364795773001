import React, { Fragment, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import { DocumentationStoreContext } from '../../store/documentationStore'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  SKA_CARD_TYPE,
  SKA_RELATION_TYPE,
  SKA_TEXT_TAGS,
} from '../../Enums/TyraEnums'
import {
  faBullhorn,
  faChevronRight,
  faCog,
  faEye,
  faFile,
  faLink,
  faPen,
  faPenToSquare,
  faTrash,
  faUser,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FullscreenModal } from '../portfolio/components/FullScreenModal'
import { UserStoreContext } from '../../store/userStore'
import { Images } from './components/Images'
import { Pdfs } from './components/Pdfs'
import { AddModal } from './components/AddModal'
import { EditTitleModal } from './components/EditTitleModal'
import { TextCard } from './components/TextCard'
import { ButtonDropdown } from '../../components/Dropdown'
import moment from 'moment'
import ModalRegular from '../../components/ModalRegular'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import classNames from 'classnames'
import { Button } from '../../components/Button'
import { Spinner } from '../../components/Spinner'
import { AddConnectionsModal } from './components/AddConnectionsModal'
import DOMPurify from 'dompurify'

export const DocumentationViewer: React.FC = observer(() => {
  const { setTitle } = useContext(NavigationStoreContext)
  const [isOpen, setIsOpen] = useState(false)
  const { currentUserRole } = useContext(UserStoreContext)
  const [selectedImage, setSelectedImage] = useState('')
  const [showFullScreenModal, setShowFullScreenModal] = useState(false)
  const [showAddConnectionModal, setShowAddConnectionModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [searchParams] = useSearchParams()
  const { id } = useParams()
  const type = searchParams.get('type')
  const navigate = useNavigate()
  const {
    fetchDocumentation,
    documentation,
    fetchCards,
    cards,
    removeCard,
    removeDocumentation,
  } = useContext(DocumentationStoreContext)
  useEffect(() => {
    setTitle(documentation?.attributes?.name)
  }, [documentation])

  useEffect(() => {
    if (documentation) {
      fetch()
    }
  }, [documentation?.id])

  const fetch = () => {
    setLoading(true)
    fetchCards(documentation?.id, type ?? '').finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (id && type) {
      fetchDocumentation({
        type: type,
        id: id,
      })
    }
  }, [id, type])

  const getTagName = (tag: number) => {
    if (tag === SKA_TEXT_TAGS.ANALYSIS) {
      return 'Analys'
    } else if (tag === SKA_TEXT_TAGS.REFLECTION) {
      return 'Reflektion'
    }
  }

  const relations = cards?.filter(
    (c) =>
      c?.attributes.card_type === SKA_CARD_TYPE.RELATION_PROJECT_PLANS ||
      c?.attributes.card_type === SKA_CARD_TYPE.RELATION_OPERATION_PLANS ||
      c?.attributes.card_type === SKA_CARD_TYPE.RELATION_REFLECTIONS ||
      c?.attributes.card_type === SKA_CARD_TYPE.RELATION_BLOG_DOCS,
  )

  return (
    <Page loading={loading}>
      <div className={'px-3 pb-[100px]'}>
        <div className={'flex justify-center py-6  border-b relative'}>
          {editMode ? (
            <EditTitleModal documentation={documentation} type={type ?? ''} />
          ) : (
            <p className={'text-2xl text-gray-400 '}>
              {documentation?.attributes?.name}
            </p>
          )}

          <div className={'absolute right-0 top-3 flex items-center h-full'}>
            <div
              className={
                'flex items-center justify-center pb-3 text-gray-500 cursor-pointer'
              }
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <FontAwesomeIcon icon={faCog} />
            </div>
          </div>
          {isOpen && (
            <div
              className="absolute right-0  z-50 mx-3 origin-top divide-y w-44 top-14"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <ButtonDropdown.Menu>
                <ButtonDropdown.Section>
                  <ButtonDropdown.Button
                    icon={editMode ? faEye : faPenToSquare}
                    label={editMode ? 'View' : 'Edit'}
                    onClick={async () => {
                      setIsOpen(false)
                      setEditMode(!editMode)
                    }}
                  />
                </ButtonDropdown.Section>
                <ButtonDropdown.Section>
                  <ButtonDropdown.Button
                    icon={faTrash}
                    label={'Remove'}
                    onClick={async () => {
                      if (window.confirm('Är du säker?')) {
                        await removeDocumentation({
                          id: id ?? ' ',
                          type: type ?? '',
                        })
                        navigate(-1)
                      }
                    }}
                  />
                </ButtonDropdown.Section>
              </ButtonDropdown.Menu>
            </div>
          )}
        </div>

        <div className={'flex flex-col space-y-6 py-6 '}>
          {cards?.map((card, index) => {
            return (
              <Fragment key={card?.id}>
                {card?.attributes.card_type === SKA_CARD_TYPE.IMAGES && (
                  <Images
                    key={card?.id}
                    setSelectedImage={setSelectedImage}
                    setShowFullScreenModal={setShowFullScreenModal}
                    editMode={editMode}
                    card={card}
                    onAdd={() => {
                      fetch()
                    }}
                    onRemove={() => {
                      fetch()
                    }}
                    onRemoveCard={async () => {
                      await removeCard({
                        id: id ?? '',
                        cardId: card?.id,
                        type: type ?? '',
                      })
                      fetch()
                    }}
                  />
                )}

                {card?.attributes.card_type === SKA_CARD_TYPE.PDFS && (
                  <Pdfs
                    card={card}
                    editMode={editMode}
                    onAdd={() => {
                      fetch()
                    }}
                    onRemove={() => {
                      fetch()
                    }}
                    onRemoveCard={async () => {
                      await removeCard({
                        id: id ?? '',
                        cardId: card?.id,
                        type: type ?? '',
                      })
                      fetch()
                    }}
                  />
                )}

                {card?.attributes.card_type === SKA_CARD_TYPE.TEXT && (
                  <div className={'flex flex-col min-h-[200px] border-b pb-3'}>
                    <div className={'flex w-full justify-between mb-3'}>
                      <div>
                        <p className={'w-full text-orange-300 '}>
                          <span className={'mr-2'}>
                            <FontAwesomeIcon icon={faPen} />
                          </span>
                          Text{' '}
                        </p>
                        {card?.attributes?.text_tags?.length &&
                          card?.attributes?.text_tags.map((tag: number) => (
                            <div className={'mt-2'}>
                              <div
                                className={
                                  'p-2 border rounded-3xl text-white text-xs bg-eventsMain flex justify-center items-center'
                                }
                              >
                                <p>{getTagName(tag)}</p>
                              </div>
                            </div>
                          ))}
                      </div>

                      {editMode && (
                        <FontAwesomeIcon
                          icon={faTrash}
                          className={
                            'text-gray-400 hover:text-red-500 cursor-pointer'
                          }
                          onClick={async () => {
                            if (
                              window.confirm('Är du säker du vill ta bort?')
                            ) {
                              await removeCard({
                                id: id ?? '',
                                cardId: card?.id,
                                type: type ?? '',
                              })
                              fetch()
                            }
                          }}
                        />
                      )}
                    </div>

                    {!editMode && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(card?.attributes?.text),
                        }}
                      />
                    )}
                    {editMode && (
                      <TextCard
                        initialTags={card?.attributes?.text_tags ?? []}
                        cardId={card?.id}
                        initialHtml={card?.attributes?.text}
                        onSave={() => {
                          fetch()
                        }}
                      />
                    )}
                  </div>
                )}
              </Fragment>
            )
          })}
        </div>

        {relations && relations?.length > 0 && (
          <div className={'flex flex-wrap justify-center border-b pb-3 pb'}>
            <div className={'flex justify-between w-full'}>
              <p className={'w-full text-eventsMain'}>
                <span className={'mr-2'}>
                  <FontAwesomeIcon icon={faLink} />
                </span>
                Kopplingar ({relations.length ?? 0})
              </p>
            </div>
            <div className={'flex w-full justify-between items-center'}>
              <div className={'flex flex-col w-full pt-3'}>
                <Relations
                  relations={relations}
                  editMode={editMode}
                  onRemove={async (cardId: string) => {
                    await removeCard({
                      id: id ?? '',
                      cardId: cardId,
                      type: type ?? '',
                    })
                    fetch()
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <AddModal
        type={type ?? ''}
        id={id ?? ''}
        schoolId={currentUserRole?.attributes?.school_pointer?.id}
        onAdd={() => {
          fetch()
        }}
        onAddConnection={() => {
          setShowAddConnectionModal(true)
        }}
      />

      <FullscreenModal
        src={selectedImage}
        showModal={showFullScreenModal}
        hideModal={() => {
          setShowFullScreenModal(false)
        }}
      />

      <AddConnectionsModal
        schoolId={currentUserRole?.attributes?.school_pointer?.id}
        currentUserRoleId={currentUserRole?.id ?? ' '}
        type={type ?? ''}
        id={id ?? ''}
        showModal={showAddConnectionModal}
        onClose={() => {
          setShowAddConnectionModal(false)
        }}
        onAdd={() => {
          setShowAddConnectionModal(false)
          fetch()
        }}
      />
    </Page>
  )
})

const Relations = ({
  relations,
  editMode,
  onRemove,
}: {
  relations: any[]
  editMode: boolean
  onRemove: any
}) => {
  const navigate = useNavigate()

  const projects = relations?.filter(
    (r) => r?.attributes?.relation_type === SKA_RELATION_TYPE.PROJECT_PLANING,
  )
  const operations = relations?.filter(
    (r) => r?.attributes?.relation_type === SKA_RELATION_TYPE.OPERATION_PLANING,
  )
  const blog = relations?.filter(
    (r) => r?.attributes?.relation_type === SKA_RELATION_TYPE.BLOG,
  )
  const reflections = relations?.filter(
    (r) => r?.attributes?.relation_type === SKA_RELATION_TYPE.REFLECTION,
  )
  const portfolios = relations?.filter(
    (r) => r?.attributes?.relation_type === SKA_RELATION_TYPE.PORTFOLIO,
  )

  const Item = ({
    title,
    cardId,
    date,
    onClick,
  }: {
    title: string
    cardId: string
    date: string
    onClick: any
  }) => {
    return (
      <div
        className={
          'flex justify-between items-center border p-3 mb-3 hover:bg-blue-50 cursor-pointer'
        }
        onClick={onClick}
      >
        <div className={'flex justify-center flex-col rounded '}>
          <p className={'text-gray-300 text-xs'}>{date}</p>
          <p className={'line-clamp-1'}>{title}</p>
        </div>
        <div className={'flex items-center space-x-3'}>
          <FontAwesomeIcon
            icon={faTrash}
            className={'text-gray-500 hover:text-red-500'}
            onClick={async (e) => {
              e.stopPropagation()
              if (window.confirm('Är du säker?')) {
                onRemove(cardId)
              }
            }}
          />

          <FontAwesomeIcon icon={faChevronRight} className={'text-gray-500'} />
        </div>
      </div>
    )
  }

  const isEditMode = () => {
    return (
      <div className={'pt-3'}>
        {projects?.length > 0 && (
          <div className={'mb-3'}>
            {getIcon(SKA_RELATION_TYPE.PROJECT_PLANING)}{' '}
            {getTitle(SKA_RELATION_TYPE.PROJECT_PLANING)} ({projects?.length})
          </div>
        )}
        {projects?.map((p) => {
          return (
            <Item
              key={p.id}
              cardId={p?.id}
              title={p?.attributes?.relation_to?.[0]?.attributes?.name}
              date={moment(
                p?.attributes?.relation_to?.[0]?.attributes?.createdAt,
              ).format('D MMM YYYY - HH:mm')}
              onClick={() => {
                navigate(
                  '/documentation/' +
                    p?.attributes?.relation_to?.[0]?.id +
                    '?type=project',
                )
              }}
            />
          )
        })}
        {operations?.length > 0 && (
          <div className={'mb-3'}>
            {getIcon(SKA_RELATION_TYPE.OPERATION_PLANING)}{' '}
            {getTitle(SKA_RELATION_TYPE.OPERATION_PLANING)} (
            {operations?.length})
          </div>
        )}
        {operations?.map((p) => {
          return (
            <Item
              key={p.id}
              cardId={p?.id}
              title={p?.attributes?.relation_to?.[0]?.attributes?.name}
              date={moment(
                p?.attributes?.relation_to?.[0]?.attributes?.createdAt,
              ).format('D MMM YYYY - HH:mm')}
              onClick={() => {
                navigate(
                  '/documentation/' +
                    p?.attributes?.relation_to?.[0]?.id +
                    '?type=operation',
                )
              }}
            />
          )
        })}
        {reflections?.length > 0 && (
          <div className={'mb-3 '}>
            {getIcon(SKA_RELATION_TYPE.REFLECTION)}{' '}
            {getTitle(SKA_RELATION_TYPE.REFLECTION)} ({reflections?.length})
          </div>
        )}
        {reflections?.map((p) => {
          return (
            <Item
              key={p.id}
              cardId={p?.id}
              title={p?.attributes?.relation_to?.[0]?.attributes?.name}
              date={moment(
                p?.attributes?.relation_to?.[0]?.attributes?.createdAt,
              ).format('D MMM YYYY - HH:mm')}
              onClick={() => {
                alert('Not implemented')
              }}
            />
          )
        })}
        {blog?.length > 0 && (
          <div className={'mb-3 '}>
            {getIcon(SKA_RELATION_TYPE.BLOG)} {getTitle(SKA_RELATION_TYPE.BLOG)}{' '}
            ({blog?.length})
          </div>
        )}
        {blog?.map((p) => {
          return (
            <Item
              key={p.id}
              cardId={p?.id}
              title={p?.attributes?.relation_to?.[0]?.attributes?.blogg_content}
              date={moment(
                p?.attributes?.relation_to?.[0]?.attributes?.createdAt,
              ).format('D MMM YYYY - HH:mm')}
              onClick={() => {
                alert('Not implemented')
              }}
            />
          )
        })}
        {portfolios?.length > 0 && (
          <div className={'mb-3 '}>
            {getIcon(SKA_RELATION_TYPE.PORTFOLIO)}{' '}
            {getTitle(SKA_RELATION_TYPE.PORTFOLIO)} ({portfolios?.length})
          </div>
        )}
        {portfolios?.map((p) => {
          return (
            <Item
              key={p.id}
              cardId={p?.id}
              title={p?.attributes?.relation_to?.[0]?.attributes?.org_content}
              date={moment(
                p?.attributes?.relation_to?.[0]?.attributes?.createdAt,
              ).format('D MMM YYYY - HH:mm')}
              onClick={() => {
                alert('Not implemented')
              }}
            />
          )
        })}
      </div>
    )
  }

  const isViewMode = () => {
    return (
      <div className={'flex flex-col'}>
        {projects?.length > 0 && (
          <div>
            {getIcon(SKA_RELATION_TYPE.PROJECT_PLANING)}{' '}
            {getTitle(SKA_RELATION_TYPE.PROJECT_PLANING)} ({projects?.length})
          </div>
        )}
        {operations?.length > 0 && (
          <div>
            {getIcon(SKA_RELATION_TYPE.OPERATION_PLANING)}{' '}
            {getTitle(SKA_RELATION_TYPE.OPERATION_PLANING)} (
            {operations?.length})
          </div>
        )}
        {reflections?.length > 0 && (
          <div>
            {getIcon(SKA_RELATION_TYPE.REFLECTION)}{' '}
            {getTitle(SKA_RELATION_TYPE.REFLECTION)} ({reflections?.length})
          </div>
        )}
        {blog?.length > 0 && (
          <div>
            {getIcon(SKA_RELATION_TYPE.BLOG)} {getTitle(SKA_RELATION_TYPE.BLOG)}{' '}
            ({blog?.length})
          </div>
        )}
        {portfolios?.length > 0 && (
          <div>
            {getIcon(SKA_RELATION_TYPE.PORTFOLIO)}{' '}
            {getTitle(SKA_RELATION_TYPE.PORTFOLIO)} ({portfolios?.length})
          </div>
        )}
      </div>
    )
  }

  return editMode ? isEditMode() : isViewMode()
}

export const getIcon = (relationType: SKA_RELATION_TYPE) => {
  if (relationType === SKA_RELATION_TYPE.PROJECT_PLANING) {
    return (
      <FontAwesomeIcon
        icon={faFile}
        className={
          'text-kidcheckingInMain hover:text-red-500 cursor-pointer mr-2'
        }
      />
    )
  }
  if (relationType === SKA_RELATION_TYPE.OPERATION_PLANING) {
    return (
      <FontAwesomeIcon
        icon={faFile}
        className={'text-groupsMain hover:text-red-500 cursor-pointer mr-2'}
      />
    )
  } else if (relationType === SKA_RELATION_TYPE.BLOG) {
    return (
      <FontAwesomeIcon
        icon={faBullhorn}
        className={'text-blogMain hover:text-red-500 cursor-pointer mr-2'}
      />
    )
  } else if (relationType === SKA_RELATION_TYPE.REFLECTION) {
    return (
      <FontAwesomeIcon
        icon={faFile}
        className={'text-eventsMain hover:text-red-500 cursor-pointer mr-2'}
      />
    )
  } else if (relationType === SKA_RELATION_TYPE.PORTFOLIO) {
    return (
      <FontAwesomeIcon
        icon={faUser}
        className={'text-eventsMain hover:text-red-500 cursor-pointer mr-2'}
      />
    )
  }
}

export const getTitle = (relationType: SKA_RELATION_TYPE) => {
  if (relationType === SKA_RELATION_TYPE.PROJECT_PLANING) {
    return 'Undervisning'
  } else if (relationType === SKA_RELATION_TYPE.OPERATION_PLANING) {
    return 'Utbildning'
  } else if (relationType === SKA_RELATION_TYPE.BLOG) {
    return 'Blogg'
  } else if (relationType === SKA_RELATION_TYPE.REFLECTION) {
    return 'Barndokumentation'
  } else if (relationType === SKA_RELATION_TYPE.PORTFOLIO) {
    return 'Portfolio'
  }
}
