import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useState } from 'react'
import { LoadingBar } from '../../../../components/LoadingBar'

interface Props {
  children?: React.ReactNode
  description?: string
  title?: string
  loading?: boolean
  className?: string
  icon?: IconDefinition
  iconClassName?: string
  titleContainerClassName?: string
  onClick?: () => void
}

export default function ChildCard(props: Props) {
  return (
    <div
      className={classNames(
        'w-1/3 min-h-[60px] min-w-[300px] overflow-hidden rounded-2xl shadow-tyraShadow cursor-pointer',
      )}
      onClick={props.onClick}
    >
      {props?.loading && <LoadingBar />}
      <div className="flex space-x-4 rounded-b-2xl items-center font-bold font-sans bg-white text-neutral-600 relative">
        <div
          className={classNames(
            'flex flex-wrap py-3 font-medium justify-start w-full h-full',
            props.className,
          )}
        >
          <>
            <div className="flex relative flex-row justify-center items-center h-16 w-full">
              {props.icon && (
                <FontAwesomeIcon
                  size="2x"
                  icon={props.icon}
                  className={classNames(
                    'absolute left-3 h-8 w-8',
                    props.iconClassName,
                  )}
                />
              )}
              <div
                className={classNames(
                  'flex flex-wrap justify-center  text-center w-full max-w-[220px] text-sm',
                  props.titleContainerClassName,
                )}
              >
                {props.title}
              </div>
            </div>
            <div className="flex-grow flex items-center justify-center">
              {props.children}
            </div>
          </>
        </div>
      </div>
    </div>
  )
}
