import { BlogImage } from './BlogImage'
import { BlogItem } from './BlogItem'
import React, { useState } from 'react'
import { BloggDoc } from '../../../Models/BloggDoc'
import classNames from 'classnames'
import { BlogRow } from '../../blog/components/BlogRow'
import { BloggReflections } from '../../../Models/BloggReflections'
import { ImageCarousel } from '../../../components/ImageCarousel'
import VideoModal from '../../../components/VideoModal'
import { Kid } from '../../../Models/Kid'

export const BlogGrid = ({
  bloggDocs,
  showOnlyImages,
  onImageSelected,
  changeView,
  reflections,
  onSaveReflections,
  type,
  kidName,
  kidFirstName,
  kid,
}: {
  bloggDocs: BloggDoc[]
  showOnlyImages: boolean
  onImageSelected: any
  changeView: boolean
  reflections?: BloggReflections[]
  onSaveReflections?: () => void
  type: 'Blog' | 'Portfolio'
  kidName?: string
  kidFirstName?: string
  kid?: Kid
}) => {
  const [selectedVideoSrc, setSelectedVideoSrc] = useState<string>('')
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false)

  const hideVideoModalFunction = () => {
    setShowVideoModal(false)
  }
  const handleVideoClick = (media: { type: string; src: string }) => {
    setSelectedVideoSrc(media.src)
    setShowVideoModal(true)
  }
  return (
    <div
      className={classNames(
        'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 font-bold',
        showOnlyImages && changeView
          ? 'grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
          : '',
        !showOnlyImages && changeView
          ? 'grid-cols-1 md:grid-cols-1 lg:grid-cols-1 w-full'
          : '',
      )}
    >
      {bloggDocs?.map((bloggDoc, index) => {
        return showOnlyImages ? (
          <>
            {bloggDoc?.attributes?.images?.filter(
              (item: any) => !item?.attributes?.audio_file,
            ).length && (
              <div className="relative">
                <ImageCarousel
                  className={'md:h-[450px] hover:cursor-pointer'}
                  images={bloggDoc?.attributes?.images
                    ?.filter((item: any) => !item?.attributes?.audio_file)
                    ?.map((item: any) => {
                      if (item?.attributes?.image?._url) {
                        return {
                          type: item?.attributes?.is_video ? 'video' : 'image',
                          thumbnailSrc: item?.attributes?.image?._url,
                          src: item?.attributes?.is_video
                            ? item?.attributes?.video_file
                            : item?.attributes?.image?._url,
                        }
                      }
                      return null
                    })
                    .filter(Boolean)}
                  displayMode="grid"
                  imageOnClick={(media: { type: string; src: string }) => {
                    onImageSelected(media.src)
                  }}
                  videoOnClick={handleVideoClick}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {changeView ? (
              <>
                {/*<BlogItem
                  key={index}
                  bloggDoc={bloggDoc}
                  onImageSelected={(src: string) => {
                    onImageSelected(src)
                  }}
                />*/}
                <BlogRow
                  key={index}
                  bloggDoc={bloggDoc}
                  onImageSelected={(src: string) => {
                    onImageSelected(src)
                  }}
                  kid={kid}
                  kidName={kidName}
                  kidFirstName={kidFirstName}
                  reflections={reflections?.filter(
                    (ref) => ref.blogg_doc?.id === bloggDoc.id,
                  )}
                  onSaveReflections={() => {
                    if (onSaveReflections) onSaveReflections()
                  }}
                  type={type}
                />
              </>
            ) : (
              <BlogItem
                type={type}
                key={index}
                bloggDoc={bloggDoc}
                kid={kid}
                kidName={kidName}
                kidFirstName={kidFirstName}
                onImageSelected={(src: string) => {
                  onImageSelected(src)
                }}
                reflections={reflections?.filter(
                  (ref) => ref.blogg_doc?.id === bloggDoc.id,
                )}
                onSaveReflections={() => {
                  if (onSaveReflections) onSaveReflections()
                }}
              />
            )}
          </>
        )
      })}
      <VideoModal
        src={selectedVideoSrc}
        showModal={showVideoModal}
        hideModal={hideVideoModalFunction}
      />
    </div>
  )
}
