import React, { useContext, useEffect, useState } from 'react'
import Page from '../../../components/Page'
import ColorCard from '../../../components/ColorCard'
import AdminCard from '../adminDashboard/components/AdminCard'
import {
  faEyeSlash,
  faUserGear,
  faUserLargeSlash,
  faUserMinus,
  faUserPen,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons'
import ChildCard from './components/ChildCard'
import { useNavigate, useParams } from 'react-router-dom'
import { t } from 'i18next'
import AddKidModal from './components/AddKidModal'
import { NavigationStoreContext } from '../../../store/navigationStore'
import { KidsStoreContext } from '../../../store/kidsStore'
import { UserStoreContext } from '../../../store/userStore'

export const Child: React.FC<{ onClick?: any }> = ({ onClick }) => {
  const [showKid, setShowKid] = useState(false)

  const navigate = useNavigate()

  const { schoolId } = useParams()
  const { setTitle } = useContext(NavigationStoreContext)
  const { fetchKids } = useContext(KidsStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  useEffect(() => {
    if (currentUserRole && currentUserRole?.school_pointer) {
      fetchKids(currentUserRole)
    }
  }, [currentUserRole?.id])
  useEffect(() => {
    setTitle(t('general.kids'))
  }, [t])

  return (
    <Page naked>
      <ColorCard
        bgColor="bg-purple-300 p-4 justify-center flex"
        title={t('admin.kidSettings')}
      >
        <div className="w-full flex ">
          <div className="flex flex-wrap justify-center gap-4">
            {currentUserRole?.school_pointer.isSs12kSchool ? (
              <ChildCard
                iconClassName="text-blue-300"
                icon={faUserPlus}
                className="hover:bg-blue-50 cursor-not-allowed opacity-60"
                title={t('admin.addKid')}
                onClick={() => {
                  //TODO: Swal with text to inform to do it in the app instead
                  /*setShowKid(true)*/
                }}
              />
            ) : (
              <ChildCard
                iconClassName="text-blue-300"
                icon={faUserPlus}
                className="hover:bg-blue-50"
                title={t('admin.addKid')}
                onClick={() => setShowKid(true)}
              />
            )}

            <AddKidModal
              onClose={() => {
                setShowKid(false)
              }}
              isOpen={showKid}
            />
            <ChildCard
              iconClassName="text-orange-300"
              icon={faUserGear}
              className="hover:bg-orange-50"
              title={t('admin.changeKidDep')}
              onClick={() => {
                navigate(`dep`)
                if (onClick) {
                  onClick()
                }
                if (currentUserRole && currentUserRole?.school_pointer) {
                  fetchKids(currentUserRole)
                }
              }}
            />
            <ChildCard
              iconClassName="text-purple-300"
              icon={faUserPen}
              className="hover:bg-purple-50"
              title={t('admin.changeKidPSN')}
              onClick={() => {
                navigate(`psu`)
                if (onClick) {
                  onClick()
                }
                if (currentUserRole && currentUserRole?.school_pointer) {
                  fetchKids(currentUserRole)
                }
              }}
            />
            <ChildCard
              iconClassName="text-gray-600"
              icon={faUserLargeSlash}
              className="hover:bg-gray-50"
              title={t('admin.kidNoBlogg')}
              onClick={() => {
                navigate(`/admin/${schoolId}/child/blogg`)
                if (onClick) {
                  onClick()
                }
                if (currentUserRole && currentUserRole?.school_pointer) {
                  fetchKids(currentUserRole)
                }
              }}
            />
            <ChildCard
              iconClassName="text-blue-300"
              icon={faEyeSlash}
              className="hover:bg-blue-50"
              title={t('admin.hideKidFromParents')}
              onClick={() => {
                navigate(`/admin/${schoolId}/child/parent`)
                if (onClick) {
                  onClick()
                }
                if (currentUserRole && currentUserRole?.school_pointer) {
                  fetchKids(currentUserRole)
                }
              }}
            />
            <ChildCard
              iconClassName="text-red-300"
              icon={faUserMinus}
              className="hover:bg-red-50"
              title={t('admin.deleteKid')}
              onClick={() => {
                navigate(`/admin/${schoolId}/child/delete`)
                if (onClick) {
                  onClick()
                }
                if (currentUserRole && currentUserRole?.school_pointer) {
                  fetchKids(currentUserRole)
                }
              }}
            />
          </div>
        </div>
      </ColorCard>
    </Page>
  )
}
