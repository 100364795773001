import React, { useContext, createContext } from 'react'
import classNames from 'classnames'

type GroupProps = {
  children: React.ReactElement[]
} & React.HTMLAttributes<HTMLDivElement>

const buttonGroupContext = createContext(null as null | number)

export function useButtonGroupIndex() {
  return useContext(buttonGroupContext)
}

export const ButtonGroup: React.FC<GroupProps> = ({
  children,
  className,
  ...props
}) => {
  const count = React.Children.count(children)
  return (
    <div
      className={classNames('inline-flex shadow-sm rounded-md', className)}
      {...props}
    >
      {React.Children.map(children, (child, i) => (
        <buttonGroupContext.Provider value={i / Math.max(1, count - 1)}>
          {child && React.cloneElement(child as any, { key: i })}
        </buttonGroupContext.Provider>
      ))}
    </div>
  )
}
