import { t } from 'i18next'
import { UserRoleConfigurationBoolean } from '../App/intefaces/configuration'
import { ARCHIVE_STATE, USER_ROLE_TYPE, USER_TYPE } from '../Enums/TyraEnums'
import { User } from '../Models/User'
import { UserRole } from '../Models/UserRole'

export const getRoleFromId = ({ roles, id }: { roles: any; id?: string }) => {
  return roles?.find((role: any) => role.id === id)
}

// todo more cases?
export const getImageFromRole = (role: any) => {
  if (
    role?.attributes?.role_type === USER_ROLE_TYPE.PARENT &&
    role?.attributes?.kid?.attributes?.kidImage_thumb?._url
  ) {
    return role?.attributes?.kid?.attributes?.kidImage_thumb?._url
  }
  if (
    role?.attributes?.role_type === USER_ROLE_TYPE.PARENT &&
    role?.attributes?.kid?.attributes?.kidImage?._url
  ) {
    return role?.attributes?.kid?.attributes?.kidImage?._url
  }
  if (role?.attributes?.user?.attributes?.myPic_thumb?._url) {
    return role?.attributes?.user?.attributes?.myPic_thumb?._url
  }

  if (role?.attributes?.user?.attributes?.myPic?._url) {
    return role?.attributes?.user?.attributes?.myPic?._url
  }

  return process.env.PUBLIC_URL + '/images/placeholder-image.png'
}

export const getImageFromUser = (user?: User) => {
  if (user?.attributes.archive_state === ARCHIVE_STATE.HISTORIC_ANONYMOUS) {
    return process.env.PUBLIC_URL + '/images/placeholder-image.png'
  }
  if (user?.attributes?.myPic?._url) {
    return user?.attributes?.myPic?._url
  }

  return process.env.PUBLIC_URL + '/images/placeholder-image.png'
}

export const getImageFromUserBall = (user: User) => {
  if (user?.attributes?.myPic?._url) {
    return user?.attributes?.myPic?._url
  }

  return null
}

export const getDashboardTitle = (role: any) => {
  if (role?.attributes?.role_type === USER_ROLE_TYPE.PARENT) {
    return role?.attributes?.kid?.attributes?.firstName
  }

  return role?.attributes?.user?.attributes?.firstName ?? ''
}

// todo more cases?
export const getTitleFromRole = (role: any) => {
  if (role?.attributes?.role_type === USER_ROLE_TYPE.PARENT) {
    return role?.attributes?.kid?.attributes?.firstName
  }

  return (
    role?.attributes?.school_pointer?.attributes?.display_school_name ??
    role?.attributes?.school_pointer?.attributes?.schoolName ??
    ''
  )
}

export const getSubTitleFromRole = (role: any) => {
  if (role?.attributes?.role_type === USER_ROLE_TYPE.PARENT) {
    return (
      role?.attributes?.school_pointer?.attributes?.display_school_name ??
      role?.attributes?.school_pointer?.attributes?.schoolName ??
      ''
    )
  }

  return role?.attributes?.relation
}

export const getUserNameFromRole = (role: any) => {
  if (role?.attributes?.user?.attributes?.user_type === USER_TYPE.BANKID) {
    //return role?.attributes?.person?.attributes?.emails[0]?.value
    return role?.attributes?.user?.attributes?.user_nin?.attributes?.nin
  }
  if (role?.attributes?.user?.attributes?.user_type === USER_TYPE.EMAIL) {
    return role?.attributes?.user?.attributes?.user_extra.attributes?.email
  }
  if (role?.attributes?.user?.attributes?.user_type === USER_TYPE.PHONE) {
    return role?.attributes?.user?.attributes?.user_extra.attributes
      ?.primary_phone
  }

  return ''
}

export const getFullNameFromUser = (user?: User) => {
  if (user?.attributes?.archive_state === ARCHIVE_STATE.HISTORIC_ANONYMOUS) {
    return t('general.unknown')
  } else {
    if (user?.attributes?.firstName) {
      return user?.attributes?.firstName + ' ' + user?.attributes?.lastName
    } else {
      return ''
    }
  }
}

export const getImageOrPlaceHolder = (img: string) => {
  if (img) {
    return img
  }

  return process.env.PUBLIC_URL + '/images/placeholder-image.png'
}

export const getReadOnly = (role: UserRole): UserRoleConfigurationBoolean => {
  if (role.configuration) {
    if (role.role_type >= 500) {
      return {
        checking: false,
        upload: false,
        absence: false,
        activities: false,
        ska: false,
        lostAndFound: false,
        events: false,
        documents: false,
        box: false,
        childInfo: false,
        notes: false,
      }
    } else {
      return {
        checking: role.configuration.checking
          ? role.configuration.checking.readOnly
          : false,
        upload: role.configuration.upload
          ? role.configuration.upload.readOnly
          : false,
        absence: role.configuration.absence
          ? role.configuration.absence.readOnly
          : false,
        activities: role.configuration.activities
          ? role.configuration.activities.readOnly
          : false,
        ska: role.configuration.ska ? role.configuration.ska.readOnly : false,
        lostAndFound: role.configuration.ska
          ? role.configuration.ska.readOnly
          : false,
        events: role.configuration.events
          ? role.configuration.events.readOnly
          : false,
        documents: role.configuration.documents
          ? role.configuration.documents.readOnly
          : false,
        box: role.configuration.box ? role.configuration.box.readOnly : false,
        notes: role.configuration.notes
          ? role.configuration.notes.readOnly
          : false,
        childInfo: role.configuration.childInfo
          ? role.configuration.childInfo.readOnly
          : false,
      }
    }
  } else {
    return {
      checking: false,
      upload: false,
      absence: false,
      activities: false,
      ska: false,
      lostAndFound: false,
      events: false,
      documents: false,
      box: false,
      childInfo: false,
      notes: false,
    }
  }
}
