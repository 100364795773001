import Parse from 'parse'
//import { TyraACL } from "../tyra-classes/TyraACL"
import { TERRITORY } from '../Enums/TyraEnums'
import { User } from './User'
import { SchoolSetting } from './SchoolSetting'

export class School extends Parse.Object {
  public static readonly Fields = {
    schoolName: 'schoolName',
    className: 'schools',
  }
  constructor() {
    super('schools')
  }

  get multi_user_id(): string | undefined {
    return this.get('multi_user_id')
  }
  get territory(): TERRITORY {
    return this.get('territory')
  }
  set territory(value: TERRITORY) {
    this.set('territory', value)
  }
  //get ACL(): TyraACL | undefined { return this.get("ACL") }

  get timezone(): string {
    return this.get('timezone')
  }
  set timezone(value: string) {
    this.set('timezone', value)
  }
  get multi_user(): User | undefined {
    return this.get('multi_user')
  }
  set multi_user(value: User | undefined) {
    this.set('multi_user', value)
  }
  get schoolName(): string {
    return this.get('schoolName')
  }
  set schoolName(value: string) {
    this.set('schoolName', value)
  }
  get display_school_name(): string {
    return this.get('display_school_name')
  }
  set display_school_name(value: string) {
    this.set('display_school_name', value)
  }

  get displayNameOfSchool(): string {
    return this.get('display_school_name')
      ? this.get('display_school_name')
      : this.get('schoolName')
  }
  get school_setting(): SchoolSetting {
    return this.get('school_setting')
  }
  set school_setting(value: SchoolSetting) {
    this.set('school_setting', value)
  }
  get event3Mod(): boolean | undefined {
    return this.get('event3Mod')
  }
  get parentCheckInOutLocked(): boolean | undefined {
    return this.get('parentCheckInOutLocked')
  }
  set parentCheckInOutLocked(value: boolean | undefined) {
    this.set('parentCheckInOutLocked', value)
  }
  get custom_check_in_out_time(): boolean | undefined {
    return this.get('custom_check_in_out_time')
  }
  set custom_check_in_out_time(value: boolean | undefined) {
    this.set('custom_check_in_out_time', value)
  }

  get isIDPSchool(): boolean {
    return this.has('idp')
  }
  get isSs12kSchool(): boolean {
    return this.has('organisation_context')
  }
}

Parse.Object.registerSubclass('schools', School)
