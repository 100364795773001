import React, { useCallback, useEffect } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useButtonDropdown } from './Button'

const Menu: React.FC<{
  children?: any
  style?: any
  onClose?: VoidFunction
}> = ({ children, onClose, ...props }) => {
  useEffect(() => {
    if (onClose) {
      onClose()
    }
  }, [])

  return (
    <div
      className="absolute left-0 right-0 z-10 mt-0.5 origin-top bg-white divide-y divide-gray-200 rounded shadow-xl ring-1 ring-black ring-opacity-10"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
      {...props}
    >
      {children}
    </div>
  )
}

const Button: React.FC<{
  icon?: IconDefinition
  label: string
  disabled?: boolean
  loading?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  closeOnClick?: boolean
  style?: any
  className?: string
}> = ({
  icon,
  label,
  disabled,
  onClick,
  closeOnClick = true,
  loading = false,
  style,
  className,
}) => {
  const ctx = useButtonDropdown()

  const handleClick = useCallback(
    (e: any) => {
      if (ctx?.isOpen.setFalse && closeOnClick) {
        ctx.isOpen.setFalse()
      }
      if (onClick) {
        onClick(e)
      }
    },
    [ctx, ctx?.isOpen],
  )
  return (
    <button
      disabled={disabled || loading}
      onClick={handleClick}
      style={style ?? undefined}
      className={classNames(
        'relative w-full flex items-center justify-start px-4 py-4 text-sm group  cursor-pointer   focus:outline-none',
        { 'cursor-not-allowed': disabled && !loading },
        { 'cursor-wait': loading },
        disabled
          ? 'text-gray-500'
          : 'text-gray-700 hover:bg-blue-50 hover:text-gray-900',
      )}
      role="menuitem"
    >
      <div className="absolute left-0 invisible w-1 h-full bg-eventsMain group-hover:visible" />
      {icon && (
        <span className="mr-2 text-center text-gray-500 group-hover:text-gray-700 ">
          <FontAwesomeIcon className={className} icon={icon} fixedWidth />
        </span>
      )}
      <span className="truncate">{label}</span>
      {loading && (
        <span className="ml-auto text-center text-gray-400 justify-self-end group-hover:text-gray-500 ">
          <FontAwesomeIcon
            className="animate-spin"
            icon={faSpinner}
            fixedWidth
          />
        </span>
      )}
    </button>
  )
}

const Section: React.FC<{ className?: string; children: any }> = ({
  children,
  className,
}) => <div className={classNames('py-0', className)}>{children}</div>

export const ButtonDropdown = { Menu, Section, Button }
