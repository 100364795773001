import React, { useContext, useMemo } from 'react'
import { observer } from 'mobx-react'
import {
  Event3StoreContext,
  EventResponsibleResponse,
} from '../../store/event3Store'
import { t } from 'i18next'
import { EventDetailCard } from './components/EventDetailCard'
import moment from 'moment'
import { IEventComment } from './components/CommentDetailCard'

export interface TimeData {
  id?: string
  date: string
  start?: string
  end?: string
  end_time?: string
  start_time?: string
  start_isodate?: string
  stop_isodate?: string
}

interface ITag {
  id?: string
  name?: string
  color?: string
  type?: string
  category?: string
  only_staff?: boolean
}

export interface IFile {
  id?: string
  name?: string
  file: string
  thumbnail?: string
  mime_type?: string
  metadata?: any
}
export interface ILocation {
  id?: string
  type?: string
  shared?: string
  name?: string
  lat?: number
  long?: number
  city?: string
  address?: string
  zipcode?: string
}
interface IStatusSummary {
  attending: number
  messages: number
  not_attending: number
  not_replied: number
}

interface IOwnParticipantsData {
  extraParticipants?: number | undefined
  message?: string
  id: string
}

export interface EventListItemData {
  createdAt: string
  endTime?: string
  eventDate: string // startDate
  eventGroupInfoId: string
  eventTimeId: string
  eventFor?: string // same in CreateEventParams
  hasComments: boolean
  departmentIds: string[] //depIds
  isFavourite: boolean
  isFileAttached: boolean
  isRead: boolean
  startTime: string
  stopDate: string //same
  times: TimeData[] /* times?: {
    date: IsoDate
    start?: IsoDateTime
    end?: IsoDateTime
    fakeId: string
  }[] */
  topic: string
  type: string // eventType in CreateEventParams
  content?: string
  onlyStaff: boolean
  bookingStatus?: string
  tags?: ITag[]
  publisherName?: string
  publisherImage?: string
  files?: IFile[]
  location?: ILocation
  comments: IEventComment[]
  statusSummary: IStatusSummary
  ownParticipantsData: IOwnParticipantsData
  isRecurring?: boolean
  maxParticipants?: number
  kidFullName?: string
  eventResponsible?: EventResponsibleResponse[]
  kidImageUrl?: string
  kidName?: string
}

const eventTypeOrder: Record<string, number> = {
  ACTIVITIES: 0,
  IMPORTANT: 1,
  INFO: 2,
  MEETING: 3,
  BOOKING: 4,
}

interface IProps {
  showUnread: boolean
}

export const getDateForTranslatation = (months: string) => {
  switch (months) {
    case '01':
      return t('monthsShort.january')
    case '02':
      return t('monthsShort.february')
    case '03':
      return t('monthsShort.march')
    case '04':
      return t('monthsShort.april')
    case '05':
      return t('monthsShort.may')
    case '06':
      return t('monthsShort.june')
    case '07':
      return t('monthsShort.july')
    case '08':
      return t('monthsShort.august')
    case '09':
      return t('monthsShort.september')
    case '10':
      return t('monthsShort.october')
    case '11':
      return t('monthsShort.november')
    case '12':
      return t('monthsShort.december')
  }
}

export const getFullMonthForTranslatation = (months: string) => {
  switch (months) {
    case '01':
      return t('months.january')
    case '02':
      return t('months.february')
    case '03':
      return t('months.march')
    case '04':
      return t('months.april')
    case '05':
      return t('months.may')
    case '06':
      return t('months.june')
    case '07':
      return t('months.july')
    case '08':
      return t('months.august')
    case '09':
      return t('months.september')
    case '10':
      return t('months.october')
    case '11':
      return t('months.november')
    case '12':
      return t('months.december')
  }
}

export const Events3: React.FC<IProps> = observer(({ showUnread }) => {
  const { events } = useContext(Event3StoreContext)
  let prevDay = ''
  let prevMonth = ''
  let prevYear = ''

  const eventItemComparator = (
    a: EventListItemData,
    b: EventListItemData,
  ): number => {
    // Compare by eventDate
    if (a.eventDate < b.eventDate) return -1
    if (a.eventDate > b.eventDate) return 1

    // Compare by whether the first start time in times is null
    const aStartAtNull = a.times?.[0]?.start === null
    const bStartAtNull = b.times?.[0]?.start === null
    if (aStartAtNull && !bStartAtNull) return 1
    if (!aStartAtNull && bStartAtNull) return -1

    // Compare by start time of the first item in times
    const aStartAt = a.times?.[0]?.start
    const bStartAt = b.times?.[0]?.start
    if (aStartAt && bStartAt) {
      if (aStartAt < bStartAt) return -1
      if (aStartAt > bStartAt) return 1
    } else if (aStartAt && !bStartAt) {
      return -1
    } else if (!aStartAt && bStartAt) {
      return 1
    }

    // Compare by eventType
    const aEventTypeOrder = eventTypeOrder[a.type.toUpperCase()]
    const bEventTypeOrder = eventTypeOrder[b.type.toUpperCase()]
    if (aEventTypeOrder < bEventTypeOrder) return -1
    if (aEventTypeOrder > bEventTypeOrder) return 1

    // Compare by createdAt
    if (a.createdAt < b.createdAt) return -1
    if (a.createdAt > b.createdAt) return 1

    // If all criteria are equal
    return 0
  }

  const sortedEvents = useMemo(() => {
    if (!events) return []
    return [...events].sort(eventItemComparator)
  }, [events])
  /* setFetchedEvents(sortedEvents) */

  const allEvents = () =>
    sortedEvents.map((event) => {
      const currentDay = moment(event.eventDate).format('DD')
      const currentMonth = moment(event.eventDate).format('MM')
      const currentYear = moment(event.eventDate).format('YYYY')

      const sameMonthAndYear =
        currentMonth === prevMonth && currentYear === prevYear

      const sameDay = currentDay === prevDay && currentMonth === prevMonth

      prevDay = currentDay
      prevMonth = currentMonth
      prevYear = currentYear

      return (
        <React.Fragment key={event.eventTimeId}>
          {!sameMonthAndYear && (
            <h3 className="flex justify-center  rounded-md font-bold">
              {getFullMonthForTranslatation(currentMonth)} {currentYear}
            </h3>
          )}
          {!sameDay && (
            <h3 className="p-2 font-bold">
              {moment(event.eventDate).format('dddd DD')}
            </h3>
          )}

          <EventDetailCard key={event.eventTimeId} event={event} />
        </React.Fragment>
      )
    })

  const unReadEvents = () =>
    sortedEvents
      .filter((item) => item.isRead === false)
      .map((event) => {
        const currentDay = moment(event.eventDate).format('DD')
        const currentMonth = moment(event.eventDate).format('MM')
        const currentYear = moment(event.eventDate).format('YYYY')

        const sameMonthAndYear =
          currentMonth === prevMonth && currentYear === prevYear

        const sameDay = currentDay === prevDay && currentMonth === prevMonth

        prevDay = currentDay
        prevMonth = currentMonth
        prevYear = currentYear

        return (
          <React.Fragment key={event.eventTimeId}>
            {!sameMonthAndYear && (
              <h3 className="flex justify-center bg-slate-100 sticky top-0 rounded-md font-bold">
                {getFullMonthForTranslatation(currentMonth)} {currentYear}
              </h3>
            )}
            {!sameDay && (
              <h3 className="p-2 font-bold">
                {moment(event.eventDate).format('dddd DD')}
              </h3>
            )}

            <EventDetailCard key={event.eventTimeId} event={event} />
          </React.Fragment>
        )
      })

  return (
    <div className="relative">{showUnread ? unReadEvents() : allEvents()}</div>
  )
})
