import ModalRegular from '../../../components/ModalRegular'
import React, { useContext, useEffect, useState } from 'react'
import { UserStoreContext } from '../../../store/userStore'
import { Button } from '../../../components/Button'
import { User } from '../../../Models/User'
import { DocumentationStoreContext } from '../../../store/documentationStore'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { getImageOrPlaceHolder } from '../../../utils/parseUtils'
import { toJS } from 'mobx'

export const UsersModal = ({
  showModal,
  onClose,
  onSave,
  initialUsers,
  role,
}: {
  showModal: boolean
  onClose: any
  onSave: any
  role: number
  initialUsers: User[]
}) => {
  const { currentUserRole } = useContext(UserStoreContext)
  const { fetchUsers, teachers } = useContext(DocumentationStoreContext)
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])

  useEffect(() => {
    setSelectedUsers(initialUsers)
  }, [initialUsers])

  const users = role === USER_ROLE_TYPE.TEACHER ? teachers : []

  return (
    <ModalRegular title={''} onClose={onClose} isOpen={showModal}>
      <div className={'mt-10'}>
        <div className={'absolute top-2 right-14'}>
          <Button
            label={'save'}
            onClick={() => {
              onSave(selectedUsers)
            }}
          />
        </div>
        <div className={'flex justify-center flex-wrap max-w-4xl py-3'}>
          {users?.map((user) => {
            return (
              <div
                key={user?.id}
                onClick={() => {
                  if (!selectedUsers.find((u: User) => u?.id === user?.id)) {
                    setSelectedUsers([...selectedUsers, user])
                  } else {
                    setSelectedUsers(
                      selectedUsers.filter((u) => u.id !== user?.id),
                    )
                  }
                }}
                className={
                  'flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:scale-110 relative'
                }
              >
                {selectedUsers.find((u: User) => u?.id === user?.id) && (
                  <FontAwesomeIcon
                    className="absolute top-5 right-3 text-eventsMain"
                    icon={faCircleCheck}
                  />
                )}
                <img
                  className={
                    'w-[60px] h-[60px] rounded-full object-cover border'
                  }
                  src={getImageOrPlaceHolder(user?.attributes?.myPic?._url)}
                  alt=""
                />

                <p>
                  {user?.attributes.firstName} {user?.attributes?.lastName?.[0]}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </ModalRegular>
  )
}
