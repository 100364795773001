import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import { USER_ROLE_TYPE } from '../../../../Enums/TyraEnums'
import ColorCard from '../../../../components/ColorCard'
import Page from '../../../../components/Page'
import { RandomColors } from '../../../../components/RandomColors'
import { getImageOrPlaceHolder } from '../../../../utils/parseUtils'
import { useContext, useEffect, useState } from 'react'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { KidsStoreContext } from '../../../../store/kidsStore'
import { UserStoreContext } from '../../../../store/userStore'
import { Button } from '../../../../components/Button'
import ModalRegular from '../../../../components/ModalRegular'
import Swal from 'sweetalert2'
import { Department } from '../../../../Models/Department'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { NavigationStoreContext } from '../../../../store/navigationStore'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../../components/Loading'

export const DeleteChild = () => {
  const { departments, selectedDepartments, fetchDepartments } = useContext(
    DepartmentStoreContext,
  )
  const { fetchKids, kids, search, fetchUserRoles, deleteKid } =
    useContext(KidsStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  const [selectedKids, setSelectedKids] = useState<string[]>([])
  const toggleKidSelection = (kidId: string) => {
    if (selectedKids.includes(kidId)) {
      setSelectedKids(selectedKids.filter((id) => id !== kidId))
    } else {
      setSelectedKids([...selectedKids, kidId])
    }
  }
  const allSelectedKidsForDeletion = kids?.filter(
    (kid) =>
      selectedKids.includes(kid?.id) &&
      departments?.some(
        (department) =>
          kid?.attributes?.departmentPointer?.id === department?.id,
      ),
  )
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { schoolId } = useParams()
  const [selectedDepartment, setSelectedDepartment] =
    useState<Department | null>(null)
  useEffect(() => {
    if (currentUserRole && currentUserRole?.school_pointer) {
      setLoading(true)
      fetchKids(currentUserRole)
      fetchDepartments(currentUserRole?.attributes?.school_pointer?.id, true)
      fetchUserRoles(currentUserRole).finally(() => {
        setLoading(false)
      })
    }
  }, [currentUserRole?.id])
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('admin.deleteKid'))
  }, [t])
  return (
    <Page
      topBar={
        <>
          {allSelectedKidsForDeletion &&
            allSelectedKidsForDeletion.length > 0 && (
              <Button
                size="sm"
                variant="borderRed"
                label={t('documents.remove')}
                onClick={async () => {
                  const kidIdsForDeletion = allSelectedKidsForDeletion.map(
                    (kid) => kid.id,
                  )
                  if (schoolId && kidIdsForDeletion) {
                    const result = await Swal.fire({
                      text: t('admin.kidRemoveText'),
                      icon: 'warning',
                      showCancelButton: true,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    }).then(async (result) => {
                      if (result.isConfirmed && currentUserRole) {
                        deleteKid(kidIdsForDeletion, schoolId).finally(() => {
                          fetchKids(currentUserRole).finally(() => {})
                        })
                      }
                    })
                  }
                }}
              />
            )}
        </>
      }
      naked
    >
      {loading && <Loading />}

      <div className={'flex justify-center w-full flex-col md:space-y-10 '}>
        {departments
          ?.filter((dep) => {
            if (!selectedDepartments?.length) {
              return true
            }

            return selectedDepartments?.find((selDep) => selDep.id === dep.id)
          })
          .map((department, index) => {
            const selectedKidsInDepartment = selectedKids.filter((kidId) =>
              kids?.find(
                (kid) =>
                  kid?.id === kidId &&
                  kid?.attributes?.departmentPointer?.id === department?.id,
              ),
            )
            return (
              <>
                <ColorCard
                  title={department?.attributes?.klassName + ''}
                  bgColor={
                    department.get('departmentColor')
                      ? 'bg-[' + department.get('departmentColor') + ']'
                      : RandomColors[index]
                  }
                >
                  {department.get('departmentColor') ? (
                    <div className="flex flex-wrap justify-center py-44 text-lg sm:text-2xl text-gray-300 w-full ">
                      <> {t('ourKids.noContactInfo')}</>
                    </div>
                  ) : (
                    <div
                      className={
                        'flex flex-wrap max-w-7xl py-3 font-medium justify-center'
                      }
                    >
                      {kids
                        ?.filter((k) => {
                          if (search?.length) {
                            return (
                              k?.attributes?.departmentPointer?.id ===
                                department?.id &&
                              k?.attributes?.kidName
                                ?.toLowerCase()
                                .includes(search.toLowerCase())
                            )
                          }
                          return (
                            k?.attributes?.departmentPointer?.id ===
                            department?.id
                          )
                        })
                        .sort((a, b) => {
                          return a?.attributes?.kidName.localeCompare(
                            b?.attributes?.kidName,
                          )
                        })
                        .map((kid) => {
                          const isSelected = selectedKids.includes(
                            kid?.id || '',
                          )

                          return (
                            <div
                              key={kid?.id}
                              onClick={() => {
                                toggleKidSelection(kid?.id || '')
                              }}
                              className={`flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:bg-gray-50 hover:shadow-md rounded-2xl`}
                            >
                              <div className="relative ">
                                {isSelected && (
                                  <div className="absolute border-[4px] border-orange-600 rounded-full w-[80px] h-[80px] top-0 left-0" />
                                )}
                                {kid.removed_from_ss12k && (
                                  <div className="z-10 w-full h-full absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] rounded-full font-bold text-red-400 flex items-center justify-center text-xs text-center">
                                    <>{t('general.endDate')}</>
                                    <br />
                                    {
                                      kid.attributes.placement.attributes
                                        .endDate
                                    }
                                  </div>
                                )}
                                <img
                                  className={`w-[80px] h-[80px] rounded-full object-cover shadow`}
                                  src={getImageOrPlaceHolder(
                                    kid?.attributes?.kidImage?._url,
                                  )}
                                  alt=""
                                />
                              </div>
                              <p>{kid?.attributes.kidName}</p>
                            </div>
                          )
                        })}
                    </div>
                  )}
                </ColorCard>
              </>
            )
          })}
      </div>
    </Page>
  )
}

export default DeleteChild
