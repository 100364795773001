// LoadMoreButton.tsx
import React, { useRef, useEffect, MutableRefObject } from 'react'
import { useOnScreen } from '../hooks/useOnScreen'
import { Button } from './Button'
import { useTranslation } from 'react-i18next' // Assuming you're using react-i18next

interface LoadMoreButtonProps {
  loadMore: () => void
  loading: boolean
  needMore: boolean
}

export const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
  loadMore,
  loading,
  needMore,
}) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const onScreen = useOnScreen<HTMLDivElement>(
    ref as MutableRefObject<HTMLDivElement>,
    '0px',
  )

  /*useEffect(() => {
    if (!loading && onScreen && needMore) {
      loadMore()
    }
  }, [onScreen, loading, needMore])*/

  return (
    <div ref={ref} className={'w-full flex justify-center mt-4 mb-4'}>
      <Button
        onClick={() => {
          loadMore()
        }}
        variant={'scrollToTop'}
        loading={loading}
        label={t('general.loadMore')}
      />
    </div>
  )
}
