import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from '../../../components/Button'
import ModalRegular from '../../../components/ModalRegular'
import classNames from 'classnames'
import { DocumentStoreContext } from '../../../store/documentStore'
import { useTranslation } from 'react-i18next'

export const EditNameModal: React.FC<{
  name: string
  id: string
  isFile: boolean
  onSaved: any
}> = observer(({ name, id, isFile, onSaved }) => {
  const { t } = useTranslation()
  const { editDocument, editFolder } = useContext(DocumentStoreContext)
  const [showModal, setShowModal] = useState(false)
  const [text, setText] = useState(name)
  const [loading, setLoading] = useState(false)

  return (
    <div className={'w-full'}>
      <Button
        variant="fullWhite"
        size="md"
        className={'w-full'}
        label={t('documents.rename')}
        onClick={() => {
          setShowModal(true)
        }}
      />

      <ModalRegular
        title={t('documents.rename')}
        isOpen={showModal}
        className={'max-w-2xl'}
        onClose={() => {
          setShowModal(false)
        }}
        bgColor="p-4 bg-amber-200"
        closeColor="white"
      >
        <div className="p-4">
          <div className="">
            <input
              className={classNames(
                'block w-full px-3 py-2 bg placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
              )}
              type={'text'}
              value={text}
              placeholder={''}
              onChange={(e) => {
                setText(e.target.value)
              }}
            />
            <div className={'flex w-full justify-end mt-4'}>
              <Button
                size="md"
                variant={'roundGreen'}
                loading={loading}
                label={t('documents.save')}
                onClick={async () => {
                  setLoading(true)
                  if (isFile) {
                    await editDocument({
                      name: text,
                      documentId: id,
                    }).finally(() => {
                      setLoading(false)
                    })
                  } else {
                    await editFolder({
                      name: text,
                      folderId: id,
                    }).finally(() => {
                      setLoading(false)
                    })
                  }
                  if (onSaved) {
                    onSaved()
                  }
                }}
              />
            </div>
          </div>
        </div>
      </ModalRegular>
    </div>
  )
})
