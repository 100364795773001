import React, { useContext, useEffect, useState } from 'react'
import Parse from 'parse'
import { observer } from 'mobx-react'
import ModalRegular from '../../../components/ModalRegular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faMicrophone } from '@fortawesome/pro-solid-svg-icons'
import { Button } from '../../../components/Button'
import { NoteStoreContext } from '../../../store/noteStore'
import { getImageFromRole, getImageFromUser } from '../../../utils/parseUtils'
import { UserStoreContext } from '../../../store/userStore'
import { User } from '../../../Models/User'
import { Kid } from '../../../Models/Kid'
import { t } from 'i18next'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import Swal from 'sweetalert2'
import heic2any from 'heic2any'
import { Loading } from '../../../components/Loading'

export interface NewNote {
  notes_topic: string
  comment: string
  shared: boolean
  kidId: string
  voice_memo: any // Parse.File här??
  picture: any // Parse.File här??
  edit_locked: boolean
}

export const NoteCreate: React.FC<{
  showModal: boolean
  onClose: () => void
  onSave: () => void
  kidId: string
}> = observer(({ showModal, onClose, onSave, kidId }) => {
  let reader = new FileReader()
  const [loading, setLoading] = useState<boolean>(false)
  const [imagePreview, setImagePreview] = useState<any>(null)

  const {
    createNoteNew,
    setNewNoteSaved,
    unsetNewNoteSaved,
    setNewNoteImagePreview,
    unsetNewNoteImagePreview,
    newNoteSaved,
    newNoteImagePreview,
  } = useContext(NoteStoreContext)
  const { currentUser, currentUserRole } = useContext(UserStoreContext)

  const isTeacher =
    currentUserRole && currentUserRole.role_type > USER_ROLE_TYPE.PARENT
  const initialValue = {
    notes_topic: '',
    comment: '',
    shared: isTeacher ? false : true,
    kidId: kidId,
    voice_memo: null,
    picture: null,
    //edit_locked: isTeacher ? true : false,
    edit_locked: true,
  }
  const [newNote, setNewNote] = useState<NewNote>(initialValue)

  const saveNote = async () => {
    if (newNote.picture) {
      newNote.picture = await uploadImageToParse(newNote.picture)
    }
    await createNoteNew(newNote)
      .catch((e) => {
        // swal här
        Swal.fire({
          text: t('events.eventAlarmFail'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
          cancelButtonText: t('general.cancel'),
        })
        throw e
      })
      .then(() => {
        // swal här
        Swal.fire({
          text: t('notes.noteCreated'),
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
          cancelButtonText: t('general.cancel'),
        })
        onSave()
        unsetNewNoteImagePreview()
        unsetNewNoteSaved()
        setNewNote(initialValue)
      })
      .finally(() => {})
  }

  const [textSize, setTextSize] = useState('text-2xl')

  const handleTextareaChange = (e: any) => {
    setNewNote({ ...newNote, comment: e.target.value })
    setTextSize(e.target.value ? 'text-lg' : 'text-2xl')
  }

  const handleUploadImage = async (event: any) => {
    let imageFile = event.target.files[0]
    const imageFilname = event.target.files[0].name.replace(' ', '_')
    if (!imageFile) {
      //setinvalidImage("Please select image.");
      setLoading(false)
      return false
    }

    if (
      !imageFile.type.match(
        /\/(jpg|jpeg|png|JPG|JPEG|PNG|gif|heic|HEIC|heif|HEIF)$/,
      )
    ) {
      //setinvalidImage("Please select valid image JPG,JPEG,PNG");
      Swal.fire({
        title: t('general.wrongFormatTitle'),
        text: t('general.wrongFormatText'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#7eb5f4',
        cancelButtonColor: '#d33',
        confirmButtonText: t('general.ok'),
        cancelButtonText: t('general.cancel'),
      })
      return false
    }
    if (imageFile.type.match(/\/(heic|HEIC|heif|HEIF)$/)) {
      setLoading(true)
      imageFile = await heic2any({
        // required: the HEIF blob file
        blob: imageFile,
        // (optional) MIME type of the target file
        // it can be "image/jpeg", "image/png" or "image/gif"
        // defaults to "image/png"
        toType: 'image/jpeg',
        // conversion quality
        // a number ranging from 0 to 1
        quality: 0.5,
      })
    }
    reader.onload = (e) => {
      const img: any = new Image()
      img.onload = () => {
        //------------- Resize img code ----------------------------------
        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(img, 0, 0)
        }

        var MAX_WIDTH = 1800
        var MAX_HEIGHT = 1800
        var width = img.width
        var height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        var ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height)
          ctx.canvas.toBlob(
            (blob: any) => {
              const file = new File([blob], imageFilname, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              })
              setImagePreview(URL.createObjectURL(imageFile))
              //props.form.setFieldValue(props.field.name, file)
              setNewNote({ ...newNote, picture: file })
            },
            'image/jpeg',
            1,
          )
        }

        //setinvalidImage(null);
      }
      img.onerror = () => {
        //setinvalidImage("Invalid image content.");
        setLoading(false)
        return false
      }
      //debugger
      img.src = e?.target?.result
    }
    reader.readAsDataURL(imageFile)
    setLoading(false)
  }

  const uploadImageToParse = async (file: any) => {
    var extension = file.name.split('.').pop()
    var parseImageFile = new Parse.File('tyraimage.' + extension, file)

    await parseImageFile.save()
    return parseImageFile
  }
  const saveDataToLocal = () => {
    setNewNoteSaved(newNote)
    setNewNoteImagePreview(imagePreview)
  }
  const handleDiscardChanges = () => {
    if (
      newNote.notes_topic !== '' ||
      newNote.comment !== '' ||
      imagePreview !== null
    ) {
      Swal.fire({
        title: t('notes.alertChanges'),
        text: t('notes.alertChangesTxt'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7eb5f4',
        cancelButtonColor: '#d33',
        confirmButtonText: t('general.save'),
        cancelButtonText: t('absence.delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          saveDataToLocal()
        } else {
          unsetNewNoteImagePreview()
          unsetNewNoteSaved()
        }
        setNewNote(initialValue)
        setImagePreview(null)
        onClose()
      })
    } else {
      onClose()
    }
  }

  const loadDataFromLocal = () => {
    if (newNoteSaved) {
      setNewNote(newNoteSaved)
    }
    if (newNoteImagePreview) {
      setImagePreview(newNoteImagePreview)
    }
  }
  useEffect(() => {
    loadDataFromLocal()
  }, [newNoteSaved])
  const [error, setError] = useState(false)

  return (
    <ModalRegular
      title={t('notes.newNote')}
      bgColor="bg-eventsMain p-4 justify-center"
      isOpen={showModal}
      onClose={handleDiscardChanges}
      closeColor="white"
    >
      {loading && <Loading />}
      <div className="flex flex-wrap flex-col p-10 w-full h-auto mx-auto gap-y-10 bg-gray-50">
        <div className="flex flex-wrap flex-col sm:flex-row justify-start sm:justify-between">
          <div className="flex flex-row gap-x-4 w-fit items-start">
            <div className="flex flex-row justify-start overflow-hidden rounded-full  w-fit">
              <img
                className={
                  'w-[70px] h-[70px] sm:w-[80px]  sm:h-[80px] object-cover rounded-full'
                }
                src={getImageFromUser(currentUser as User)}
                alt=""
              />
              <div className="flex flex-wrap items-center sm:text-2xl ml-2 text-gray-600 font-bold tracking-wide">
                {currentUser?.get('firstName')} {currentUser?.get('lastName')}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap flex-col ">
            <div
              title={t('files.chooseFile')}
              className="relative hover:scale-[102%] cursor-pointer flex"
            >
              <input
                title={t('files.chooseFile')}
                type="file"
                className="absolute inset-0 opacity-0 cursor-pointer"
                name="upload_file"
                onChange={(e) => handleUploadImage(e)}
              />
              <div className=" object-cover border border-gray-50 rounded-2xl overflow-hidden ">
                {imagePreview !== null ? (
                  <img
                    className="previewimg "
                    src={imagePreview}
                    alt="UploadImage"
                    width={200}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faImage}
                    className="text-eventsMain cursor-pointer w-[120px] h-[120px]"
                    onClick={() =>
                      document.getElementsByName('upload_file')[0].click()
                    }
                  />
                )}
              </div>
            </div>
            <div className="flex flex-row gap-x-10 justify-center mt-2 max-w-[200px] w-full">
              {imagePreview && (
                <label
                  onClick={() => {
                    setNewNote({ ...newNote, picture: null })
                    setImagePreview(null)
                  }}
                  className="block  mb-[2px] text-sm font-medium hover:text-red-600 hover:scale-110 cursor-pointer text-center text-gray-900 dark:text-gray-500"
                >
                  <>{t('documents.remove')}</>
                </label>
              )}
              <label
                onClick={() =>
                  document.getElementsByName('upload_file')[0].click()
                }
                className="block mb-[2px]  bg-gray-50 text-sm font-medium hover:text-eventsMain hover:scale-110 cursor-pointer text-center text-gray-900 dark:text-gray-500"
              >
                <>
                  {imagePreview ? t('notes.changeImg') : t('files.uploadImage')}
                </>
              </label>
            </div>
          </div>
        </div>
        {isTeacher && (
          <>
            <div className="flex flex-wrap w-fit font-bold gap-x-4 text-gray-500">
              <>{t('notes.shareParent')}</>
              <input
                className="cursor-pointer"
                type={'checkbox'}
                checked={newNote.shared}
                onChange={(e) => {
                  if (e.target.checked) {
                    setNewNote({
                      ...newNote,
                      edit_locked: false,
                      shared: e.target.checked,
                    })
                  } else {
                    setNewNote({ ...newNote, shared: e.target.checked })
                  }
                  setError(false)
                }}
              />
            </div>
            <div className="flex w-fit font-bold gap-x-4 text-gray-500">
              <>{t('notes.noteLockedinput')}</>
              <div
                className="relative"
                onClick={() => {
                  if (newNote.shared) {
                    setError(true)
                  }
                }}
              >
                <input
                  className="cursor-pointer"
                  type={'checkbox'}
                  checked={newNote.edit_locked}
                  disabled={newNote.shared}
                  onChange={(e) => {
                    setNewNote({ ...newNote, edit_locked: e.target.checked })
                  }}
                />
                {newNote.shared && <div className="absolute inset-0" />}
              </div>
              {error && (
                <span className="text-red-500 text-sm mt-[2px] ">
                  <>{t('notes.editableWarning')}</>
                </span>
              )}
            </div>
          </>
        )}
        <div className="flex flex-col gap-y-2">
          <input
            placeholder={t('notes.header')}
            className="w-full border text-2xl border-gray-100 font-medium shadow rounded-2xl p-2 pl-4 focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
            type="text"
            value={newNote.notes_topic}
            onChange={(e) => {
              setNewNote({ ...newNote, notes_topic: e.target.value })
            }}
          />
          <div></div>
          <textarea
            placeholder={t('general.message')}
            className={`border border-gray-100 shadow font-medium rounded-2xl p-4 h-[200px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain ${textSize}`}
            onChange={handleTextareaChange}
            value={newNote.comment}
          />
        </div>
        <div className="flex flex-wrap w-full">
          <div className="flex justify-center w-full">
            <Button
              variant="borderBlue"
              size="md"
              label={t('notes.publish')}
              onClick={() => {
                if (newNote.notes_topic !== '' && newNote.comment !== '') {
                  saveNote()
                } else {
                  Swal.fire({
                    text: t('notes.noteAlarmTxtHeader'),
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                    cancelButtonText: t('general.cancel'),
                  })
                }
              }}
            />
          </div>
        </div>
      </div>
    </ModalRegular>
  )
})
