import classNames from 'classnames'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  label: string
  color?: string
  onClick?: () => void
  variant: 'fullColor' | 'borderColor' | 'default'
  size?: 'xs' | 'sm' | 'md' | 'lg'
  keyTag: string
}

export const Tag: React.FC<Props> = ({
  label,
  color,
  onClick,
  variant,
  size,
  keyTag: key,
}) => {
  const tagStyle = () => {
    if (variant === 'fullColor') {
      return {
        backgroundColor: color,
        borderColor: color,
      }
    }
    if (variant === 'borderColor') {
      return {
        borderColor: color,
        //color: color,
      }
    }
    return {}
  }

  return (
    <div
      key={key}
      className={classNames(
        'rounded-full items-center border-2 px-4 py-1 relative text-2xs gap-x-1 flex m-1 text-black border-black font-bold',
        onClick ? 'cursor-pointer' : '',
      )}
      style={tagStyle()}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <FontAwesomeIcon
        className="w-3 h-3"
        style={variant === 'borderColor' ? { color: color } : {}}
        icon={faTag}
        flip="horizontal"
      />{' '}
      <span className="text-xs">{label}</span>
    </div>
  )
}
