import React, { useContext, useEffect, useState } from 'react'
import { DocumentationStoreContext } from '../../../store/documentationStore'
import { Button } from '../../../components/Button'
// @ts-ignore
import RichTextEditor from 'react-rte'
import classNames from 'classnames'
import { SKA_TEXT_TAGS } from '../../../Enums/TyraEnums'

export const TextCard = ({
  initialHtml,
  cardId,
  onSave,
  initialTags,
}: {
  initialHtml: string
  cardId: string
  onSave: any
  initialTags: number[]
}) => {
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState<number[]>([])
  const [value, setValue] = useState(
    RichTextEditor.createValueFromString(initialHtml ?? '', 'html'),
  )

  useEffect(() => {
    setTags(initialTags)
  }, [initialTags])

  const { editTextCard } = useContext(DocumentationStoreContext)

  return (
    <>
      <div className={'flex w-full justify-end mb-3'}>
        <Button
          loading={loading}
          label={'save'}
          onClick={async () => {
            setLoading(true)
            await editTextCard({
              cardId: cardId,
              text: value.toString('html'),
              tags: tags,
            }).finally(() => {
              setLoading(false)
              onSave()
            })
          }}
        />
      </div>
      <RichTextEditor
        value={value}
        onChange={(e: any) => {
          setValue(e)
        }}
      />

      <div className={'flex space-x-3 py-3'}>
        <div
          className={classNames(
            'p-2 border rounded-3xl  text-xs cursor-pointer',
            tags.find((n) => n === SKA_TEXT_TAGS.ANALYSIS) &&
              'bg-eventsMain text-white ',
          )}
          onClick={() => {
            if (tags.find((n) => n === SKA_TEXT_TAGS.ANALYSIS)) {
              setTags(tags.filter((n) => n !== SKA_TEXT_TAGS.ANALYSIS))
            } else {
              setTags([...tags, SKA_TEXT_TAGS.ANALYSIS])
            }
          }}
        >
          Analys
        </div>
        <div
          className={classNames(
            'p-2 border rounded-3xl  text-xs cursor-pointer',
            tags.find((n) => n === SKA_TEXT_TAGS.REFLECTION) &&
              'bg-eventsMain text-white ',
          )}
          onClick={() => {
            if (tags.find((n) => n === SKA_TEXT_TAGS.REFLECTION)) {
              setTags(tags.filter((n) => n !== SKA_TEXT_TAGS.REFLECTION))
            } else {
              setTags([...tags, SKA_TEXT_TAGS.REFLECTION])
            }
          }}
        >
          Reflektion
        </div>
      </div>
    </>
  )
}
