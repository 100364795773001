import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from '../../../components/Button'
import { FoldersModal } from './MoveFileModal'
import {
  NotificationContext,
  NotificationVariant,
} from '../../../App/notification/notificationManger'
import { DocumentStoreContext } from '../../../store/documentStore'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'

export const CopyFileModal: React.FC<{
  documentId: string
  onSaved?: any
}> = observer(({ documentId, onSaved }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const { notify } = useContext(NotificationContext)
  const { copyDocument } = useContext(DocumentStoreContext)

  return (
    <div className={'w-full'}>
      <Button
        size="md"
        className={'w-full'}
        label={t('documents.copy')}
        onClick={() => {
          setShowModal(true)
        }}
      />

      <FoldersModal
        documentId={documentId ?? ''}
        setShowModal={setShowModal}
        loading={loading}
        showModal={showModal}
        onSaved={async ({
          type,
          selectedFolder,
        }: {
          type: string
          selectedFolder: any
        }) => {
          if (type.length && documentId) {
            setLoading(true)
            await copyDocument({
              folderId: selectedFolder?.id,
              kidId: selectedFolder?.attributes?.in_kid_folder?.id,
              depId: selectedFolder?.attributes?.in_dep_folder?.id,
              documentId: documentId,
              type: type,
            }).finally(() => {
              setLoading(false)
            })

            /* notify({
              variant: NotificationVariant.OK,
              title: t('general.copied'),
              message: t('documents.docCopied'),
            })*/
            Swal.fire({
              text: t('documents.docCopied'),
              icon: 'success',
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonColor: '#7eb5f4',
              cancelButtonColor: '#d33',
              confirmButtonText: t('general.ok'),
            })
            setShowModal(false)
            onSaved()
          }
        }}
      />
    </div>
  )
})
