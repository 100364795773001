import React, { useContext, useState } from 'react'
import { User } from './OverviewUsersList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChild,
  faChildReaching,
  faEnvelope,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../components/Button'
import OverviewUserPopUp from './OverviewUserPopUp'
import { Event3StoreContext } from '../../../store/event3Store'
import { useTranslation } from 'react-i18next'
import OverviewReqResUserPopUp from './OverviewReqResUserPopUp'
import { EventListItemData } from '../Events3'
import moment from 'moment'

const OverviewUserCard: React.FC<{
  user?: User
  eventDetail?: EventListItemData
  refreshDetail: () => void
  dataAction?: string
}> = ({ user, eventDetail, refreshDetail, dataAction }) => {
  const displayedKids = user?.kids?.slice(0, 2) ?? []
  const remainingKidsCount = (user?.kids?.length ?? 0) - 2
  const [isUserInfoPopUpVisible, setUserInfoPopUpVisible] =
    useState<boolean>(false)
  const { sendReminder } = useContext(Event3StoreContext)
  const { t } = useTranslation()

  const handleClosePopup = () => {
    setUserInfoPopUpVisible(false)
  }
  const relatives =
    user?.relatives && user?.relatives.map((parent) => parent.name)

  const cancelledAt = moment(user?.cancelledAt).format('YYYY-MM-DD')

  return (
    <>
      <div className="flex flex-col items-center p-1 rounded-lg shadow-lg bg-[#FFFFFF] my-1">
        <div
          className="flex ml-1 w-full cursor-pointer items-center"
          onClick={(e) => {
            e.stopPropagation()
            setUserInfoPopUpVisible(!isUserInfoPopUpVisible)
          }}
        >
          <div className="flex w-full items-start">
            <img
              className={`w-10 h-10 rounded-full object-cover ${
                !user?.thumbnail && 'bg-gray-300'
              }`}
              src={user?.thumbnail || '/images/placeholder-image.png'}
              alt=""
              key={user?.userId}
            />
            <div className=" flex flex-col pl-1 pt-1 justify-center w-[90%]">
              <p className="font-semibold text-gray-900 w-full text-xs">
                {user?.name}
              </p>
              {user?.isUser === false
                ? relatives?.map((relative, index) => (
                    <span
                      className="flex flex-col break-words text-xs"
                      key={user.id}
                    >
                      <span className="line-clamp-1">
                        {relatives && index < 2 && relative}
                        {relatives && index === 1 && relatives.length > 2 && (
                          <span className="text-blue-500 ml-1">
                            +{relatives.length - 2}
                          </span>
                        )}
                      </span>
                    </span>
                  ))
                : displayedKids.map((child, index) => (
                    <span
                      className="flex flex-col break-words text-xs"
                      key={child.kidId}
                    >
                      <span className="line-clamp-1">
                        {child.name}
                        {remainingKidsCount > 0 && index >= 1 && (
                          <span className="text-blue-500 ml-1">
                            +{remainingKidsCount}
                          </span>
                        )}
                      </span>
                    </span>
                  ))}{' '}
            </div>
            {(user?.status === 'cancelled' || user?.status === 'declined') && (
              <div className="flex flex-col  items-start pr-1 text-red-500 text-sm w-[40%]">
                <p>Declined</p>
                <p className="text-xs">{cancelledAt}</p>
              </div>
            )}
          </div>

          <div className="justify-end items-center flex">
            {dataAction !== 'messages' && user?.isPresent !== undefined && (
              <div
                className="flex justify-end items-end w-full cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  setUserInfoPopUpVisible(!isUserInfoPopUpVisible)
                }}
              >
                <FontAwesomeIcon
                  icon={user?.isPresent === true ? faChildReaching : faChild}
                  size="lg"
                  className={`p-1 ${
                    user?.isPresent === true ? 'text-green-600' : 'text-red-300'
                  }`}
                />
              </div>
            )}
            {user?.message ? (
              <FontAwesomeIcon
                size="lg"
                icon={faEnvelope}
                className="text-gray-400"
              />
            ) : user?.status === 'invited' || user?.hasRead === false ? (
              <Button
                className="flex flex-grow  justify-end mx-2"
                size="xs"
                variant="remindStyle"
                label={t('events.remind')}
                type="button"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation()
                  sendReminder(eventDetail?.eventTimeId, [user?.userId ?? ''])
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        {dataAction === 'messages' && (
          <p className="flex w-full p-4">{user?.message}</p>
        )}
      </div>
      {isUserInfoPopUpVisible &&
        (user?.status ? (
          <OverviewReqResUserPopUp
            user={user}
            onClose={handleClosePopup}
            eventDetail={eventDetail}
            refreshDetail={refreshDetail}
            dataAction={dataAction}
          />
        ) : (
          <OverviewUserPopUp
            user={user}
            onClose={handleClosePopup}
            eventTimeId={eventDetail?.eventTimeId}
            refreshDetail={refreshDetail}
          />
        ))}
    </>
  )
}

export default OverviewUserCard
