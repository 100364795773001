import React, { useContext, useEffect, useState } from 'react'
import Page from '../../../../components/Page'

import { t } from 'i18next'
import ColorCard from '../../../../components/ColorCard'

import { NavigationStoreContext } from '../../../../store/navigationStore'
import { AdminStoreContext } from '../../../../store/adminStore'
import { USER_ROLE_STATUS, USER_ROLE_TYPE } from '../../../../Enums/TyraEnums'
import { UserRole } from '../../../../Models/UserRole'
import { useParams } from 'react-router-dom'

import { TeacherCard } from '../components/TeacherCard'
import { VHCard } from '../components/VHCard'
import { UserStoreContext } from '../../../../store/userStore'
import { observer } from 'mobx-react'
import { Loading } from '../../../../components/Loading'

export const PausedUsers: React.FC<{ onClick?: any }> = observer(
  ({ onClick }) => {
    const [loading, setLoading] = useState(false)
    const [showStatusModal, setShowStatusModal] = useState(false)
    const [imageUrl, setImageUrl] = useState<string | null>(null)
    const [isBlueBackground, setIsBlueBackground] = useState(false)
    const [showWaitingApprovalModal, setShowWaitingApprovalModal] =
      useState(false)
    const [showWaitingApprovalVHModal, setShowWaitingApprovalVHModal] =
      useState(false)
    const [cardMode, setCardMode] = useState('users')
    //const [pausedUserRoles, setPausedUserRoles] = useState<UserRole[]>([])
    const [pausedVHRoles, setPausedVHRoles] = useState<UserRole[]>([])

    /*const areTherePausedUsers = pausedUserRoles.length > 0
  const areTherePausedVH = pausedVHRoles.length > 0*/

    const handleClick = () => {
      setIsBlueBackground(!isBlueBackground)
    }

    const { fetchPausedUserRoles, pausedUserRoles } =
      useContext(AdminStoreContext)
    const { fetchAllUserRolesWithKid, allUserRolesWithKid } =
      useContext(UserStoreContext)
    const { setTitle } = useContext(NavigationStoreContext)
    const [showInviteUserModal, setShowInviteUserModal] = useState(false)
    const [showInviteVHModal, setShowInviteVHModal] = useState(false)
    const { schoolId } = useParams()

    useEffect(() => {
      setTitle(t('admin.pausedUsers'))
      if (schoolId) {
        setLoading(true)
        fetchAllUserRolesWithKid(schoolId).finally(() => {
          setLoading(false)
        })
      }
      /*fetchPausedUserRoles().then((newUserRoles: UserRole[]) => {
      console.log('test', newUserRoles)
      if (newUserRoles) {
        const userRoles = newUserRoles.filter(
          (userRole) => userRole.status === USER_ROLE_STATUS.PAUSED,
        )
        const userRolesForUsers = userRoles.filter(
          (userRole) => userRole.role_type >= USER_ROLE_TYPE.TEACHER,
        )
        const userRolesForVH = userRoles.filter(
          (userRole) => userRole.role_type === USER_ROLE_TYPE.PARENT,
        )
        setPausedUserRoles(userRolesForUsers)
        setPausedVHRoles(userRolesForVH)
      }
    })*/
      fetchPausedUserRoles()
    }, [schoolId])

    let renderedVhs: string[] = []
    return (
      <Page naked>
        {loading && <Loading />}
        <ColorCard
          bgColor="bg-pink-200 p-4 justify-center flex"
          title={t('documents.staff')}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 ml-5">
            {pausedUserRoles &&
            pausedUserRoles.filter(
              (userRole) => userRole.role_type >= USER_ROLE_TYPE.TEACHER,
            ).length ? (
              pausedUserRoles
                .filter(
                  (userRole) => userRole.role_type >= USER_ROLE_TYPE.TEACHER,
                )
                .map((userRole) => {
                  return (
                    <>
                      <TeacherCard
                        onClick={() => {}}
                        title=""
                        userRole={userRole}
                        type="paused"
                      />
                    </>
                  )
                })
            ) : (
              <div className="col-span-full place-items-center">
                <div className="p-10 text-center">
                  <>{t('documents.empty')}</>
                </div>
              </div>
            )}
          </div>
        </ColorCard>
        <ColorCard
          bgColor="bg-pink-200 p-4 justify-center flex"
          title={t('documents.parent')}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 ml-5">
            {pausedUserRoles &&
            pausedUserRoles.filter(
              (userRole) => userRole.role_type === USER_ROLE_TYPE.PARENT,
            ).length ? (
              pausedUserRoles
                .filter(
                  (userRole) => userRole.role_type === USER_ROLE_TYPE.PARENT,
                )
                .map((userRole) => {
                  if (renderedVhs.includes(userRole.user?.id ?? 'xyz')) {
                    return null
                  }
                  if (userRole.user) {
                    renderedVhs.push(userRole.user.id)
                  }

                  return (
                    <>
                      <VHCard
                        key={userRole.id}
                        onClick={() => {}}
                        allUserRolesWithKid={allUserRolesWithKid}
                        title=""
                        userRole={userRole}
                        type="paused"
                        allUserRolesForUser={pausedUserRoles.filter(
                          (uR) => uR.user?.id === userRole.user?.id,
                        )}
                      />
                    </>
                  )
                })
            ) : (
              <div className="col-span-full place-items-center">
                <div className="p-10 text-center">
                  <>{t('documents.empty')}</>
                </div>
              </div>
            )}
          </div>
        </ColorCard>
        {/*cardMode === 'users' ? (
        <ColorCard
          bgColor="bg-pink-200 p-4 justify-center flex"
          title={t('documents.staff')}
          actionButton={
            <div className="flex flex-row gap-x-2">
              <Button
                label={t('documents.staff')}
                onClick={() => setCardMode('users')}
                size="custom"
              ></Button>
              <Button
                size="custom"
                label={t('general.guardian')}
                onClick={() => setCardMode('vh')}
              ></Button>
            </div>
          }
        >
          <div className="w-full flex flex-col ">
            <div className="flex flex-wrap justify-center gap-4">
              {areTherePausedUsers ? (
                <PausedUsersCard />
              ) : (
                <div className="p-10">
                  <>{t('documents.empty')}</>
                </div>
              )}
            </div>
          </div>
        </ColorCard>
      ) : (
        <ColorCard
          bgColor="bg-pink-200 p-4 justify-center flex"
          title={t('general.guardian')}
          actionButton={
            <div className="flex flex-row gap-x-2">
              <Button
                label={t('documents.staff')}
                onClick={() => setCardMode('users')}
                size="custom"
              ></Button>
              <Button
                size="custom"
                label={t('general.guardian')}
                onClick={() => setCardMode('vh')}
              ></Button>
            </div>
          }
        >
          <div className="flex flex-wrap justify-center gap-4">
            {areTherePausedVH ? (
              <PausedVHCard />
            ) : (
              <div className="p-10">
                <>{t('documents.empty')}</>
              </div>
            )}
          </div>
        </ColorCard>
            )*/}
      </Page>
    )
  },
)
