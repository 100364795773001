import { ARCHIVE_STATE } from '../Enums/TyraEnums'
import { School } from './School'
import Parse from 'parse'

export class DocTag extends Parse.Object {
  public static readonly Fields = {
    school: 'school',
    className: 'doc_tags',
  }
  constructor() {
    super('doc_tags')
  }
  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }

  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
  get name(): string {
    return this.get('name')
  }
  set name(value: string) {
    this.set('name', value)
  }
  get bg_color(): string {
    return this.get('bg_color')
  }
  set bg_color(value: string) {
    this.set('bg_color', value)
  }
  get archive_state(): ARCHIVE_STATE | undefined {
    return this.get('archive_state')
  }
  set archive_state(value: ARCHIVE_STATE | undefined) {
    value ? this.set('archive_state', value) : this.unset('archive_state')
  }
  get order(): number {
    return this.get('order')
  }
  set order(value: number) {
    this.set('order', value)
  }
  get updatedAt(): Date {
    return this.get('updatedAt')
  }
  set updatedAt(value: Date) {
    this.set('updatedAt', value)
  }
  get createdAt(): Date {
    return this.get('createdAt')
  }
  set createdAt(value: Date) {
    this.set('createdAt', value)
  }
  get is_base(): boolean {
    return this.get('is_base')
  }
  set is_base(value: boolean) {
    this.set('is_base', value)
  }
}
Parse.Object.registerSubclass('doc_tags', DocTag)
