import Parse from 'parse'
import { SkaDocument } from './SkaDocument'

export class SkaReflections extends SkaDocument {
  public static readonly Fields = {
    school: 'school_pointer',
    visibility: 'visibility',
  }
  constructor() {
    super('ska_reflections')
  }

  get name(): string {
    return this.get('name')
  }
}
Parse.Object.registerSubclass('ska_reflections', SkaReflections)
