import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { PortfolioBlogStoreContext } from '../../store/portfolioBlogStore'
import { Spinner } from '../../components/Spinner'
import Page from '../../components/Page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faImage } from '@fortawesome/free-regular-svg-icons'
import { Button } from '../../components/Button'
import { ButtonDropdown } from '../../components/Dropdown'
import { UserStoreContext } from '../../store/userStore'
import { getSemesters } from '../../utils/getSemesters'
import { FullscreenModal } from './components/FullScreenModal'
import { BlogGrid } from './components/BlogGrid'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useTranslation } from 'react-i18next'
import { Kid } from '../../Models/Kid'
import { DocTag } from '../../Models/DocTag'
import { Tags } from '../blog/components/Tags'
import moment from 'moment'
import { DateChooser } from '../blog/components/DateChooser'
import {
  faEllipsisVertical,
  faFilePdf,
  faGrid,
  faGrid2,
  faList,
  faPrint,
  faFolder,
} from '@fortawesome/pro-solid-svg-icons'
import { Loading } from '../../components/Loading'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import { useClickOutside } from '../../hooks/useClickoutside'
import ExportModal from './components/ExportModal'
import { FileJobStoreContext } from '../../store/fileJobStore'

export const Portfolio: React.FC = observer(() => {
  let { kidId } = useParams()
  const { setTitle } = useContext(NavigationStoreContext)
  const { t } = useTranslation()
  /*const [selectedTags, setSelectedTags] = useState<DocTag[]>([])*/
  const [skip, setSkip] = useState<number>(0)
  const [changeView, setChangeView] = useState(false)

  const [showOnlyImages, setShowOnlyImages] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [kid, setKid] = useState<Kid>()
  const [selectedSemester, setSelectedSemester] = useState() as any
  const [showFullScreenModal, setShowFullScreenModal] = useState(false)
  const {
    fetchPortfolio,
    portfolioDocs,
    fetchKid,
    setSelectedTags,
    selectedTags,
    fetchReflections,
    blogDocReflections,
    bloggDocs,
  } = useContext(PortfolioBlogStoreContext)
  const { savedUserRoleId, fetchUserRole, currentUserRole } =
    useContext(UserStoreContext)
  const { downloadAndZip } = useContext(FileJobStoreContext)
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState<Date>(
    new Date(moment().subtract(3, 'months').format('YYYY-MM-DD')),
  )
  const [endDate, setEndDate] = useState<Date>(
    new Date(moment().format('YYYY-MM-DD')),
  )

  useEffect(() => {
    if (selectedSemester) {
      setLoading(true)
      /*let startDate
      let endDate
      if (selectedSemester.semester === 1) {
        startDate = new Date(selectedSemester.year + '-01-01')
        endDate = new Date(selectedSemester.year + '-06-30')
      } else {
        startDate = new Date(selectedSemester.year + '-07-01')
        endDate = new Date(selectedSemester.year + '-12-31')
      }*/
      fetchPortfolio({
        school: currentUserRole?.attributes?.school_pointer,
        startDate: startDate,
        endDate: endDate,
        kid: kid,
        selectedTags: selectedTags,
        skip: skip,
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [selectedSemester, skip, selectedTags, startDate, endDate])

  useEffect(() => {
    if (portfolioDocs && portfolioDocs?.length > 0) {
      fetchReflections(portfolioDocs?.map((doc) => doc.id))
    }
  }, [portfolioDocs])

  useEffect(() => {
    if (kidId) {
      fetchKid(kidId ?? '').then((kid) => {
        if (kid) {
          setKid(kid)
        }
      })
    } else {
      setKid(currentUserRole?.attributes?.kid)
    }
  }, [kidId, currentUserRole])

  useEffect(() => {
    if (savedUserRoleId) {
      setLoading(true)
      fetchUserRole(savedUserRoleId).finally(() => {
        setLoading(false)
      })
    }
  }, [])

  const semesters = getSemesters(
    new Date(kid?.attributes.createdAt),
    new Date(),
  )

  useEffect(() => {
    if (!selectedSemester && semesters?.length) {
      setSelectedSemester({
        year: semesters[0].year,
        semester: semesters[0].semesters.length,
      })
    }
  }, [currentUserRole])

  useEffect(() => {
    setTitle(t('menu.portfolio'))
  }, [t])

  const getSemesterName = (s: number) => {
    if (s == 1) {
      return t('portfolio.spring')
    } else {
      return t('portfolio.fall')
    }
  }
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [openExportModal, setOpenExportModal] = useState(false)

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }
  const [menuRef, togglerRef] = useClickOutside(() => {
    setDropdownVisible(false)
  })
  const isTeacher =
    currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
      ? true
      : false
  const [buttonType, setButtonType] = useState<'Print' | 'Generate'>()

  const handlePrintClick = () => {
    setButtonType('Print')
    setOpenExportModal(true)
  }
  const handleGeneratePdfClick = () => {
    setButtonType('Generate')
    setOpenExportModal(true)
  }
  let { schoolId } = useParams()
  const [modalType, setModalType] = useState<
    'All' | 'OnlySliders' | 'OnlySelectDate' | null
  >(null)
  return (
    <Page
      clearFilter={() => {
        setSelectedTags([])
      }}
      filterCount={selectedTags?.length ? selectedTags.length : 0}
      filter={
        <div className="w-full flex justify-center">
          <Tags
            onSelectedTags={(tags: DocTag[]) => {
              setSkip(0)
              setSelectedTags(tags)
            }}
            initialSelectedTags={selectedTags}
          />
        </div>
      }
      topBar={
        <>
          {/*<Button
            className="my-1"
            size="lg"
            label={
              selectedSemester?.year
                ? getSemesterName(selectedSemester.semester) +
                  ' ' +
                  selectedSemester.year
                : ''
            }
            dropdownClassName={'left-0'}
            dropdown={
              <div className={'mt-2'}>
                <ButtonDropdown.Menu>
                  <ButtonDropdown.Section>
                    {semesters.reverse().map((semester) => {
                      return semester.semesters.reverse().map((s, index) => {
                        return (
                          <ButtonDropdown.Button
                            key={semester.year + index}
                            label={getSemesterName(s) + ' ' + semester.year}
                            onClick={() => {
                              setSelectedSemester({
                                year: semester.year,
                                semester: s,
                              })
                            }}
                          />
                        )
                      })
                    })}
                  </ButtonDropdown.Section>
                </ButtonDropdown.Menu>
              </div>
            }
          />*/}
          <div className="sm:ml-12">
            <DateChooser
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              onChangeDate={(startDate: Date, endDate: Date) => {
                setSkip(0)
                setStartDate(startDate)
                setEndDate(endDate)
              }}
            />
          </div>
          <div className=" flex flex-row gap-x-4">
            {showOnlyImages ? (
              <Button
                variant={'gridButton'}
                icon={changeView ? faGrid2 : faGrid}
                onClick={() => {
                  setChangeView(!changeView)
                }}
              />
            ) : (
              <Button
                variant={'gridButton'}
                icon={changeView ? faGrid : faList}
                onClick={() => {
                  setChangeView(!changeView)
                }}
              />
            )}

            <Button
              icon={showOnlyImages ? faAddressCard : faImage}
              size="lg"
              variant={'pictureButton'}
              title={
                showOnlyImages ? t('blog.showCards') : t('blog.showImages')
              }
              onClick={() => {
                setShowOnlyImages(!showOnlyImages)
              }}
            />
            <div className="relative items-center flex">
              <button
                type="button"
                className="w-full rounded-md group focus:outline-none "
                aria-haspopup="true"
                aria-expanded={dropdownVisible}
                onClick={(e) => {
                  e.stopPropagation()
                  toggleDropdown()
                }}
                ref={togglerRef}
              >
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="text-gray-400 px-4 hover:scale-110 "
                ></FontAwesomeIcon>
              </button>

              {dropdownVisible && (
                <div
                  className="absolute right-5  top-3 z-50 mx-3 origin-top divide-y font-bold w-[280px]"
                  style={{
                    display: dropdownVisible ? 'block' : 'none',
                  }}
                  ref={menuRef}
                >
                  <ButtonDropdown.Menu>
                    <ButtonDropdown.Section>
                      <>
                        <ButtonDropdown.Button
                          icon={faPrint}
                          label={t('blog.print')}
                          className="text-purple-300 "
                          onClick={(e) => {
                            e.stopPropagation()
                            setOpenExportModal(true)
                            handlePrintClick()
                          }}
                        />
                        <ButtonDropdown.Button
                          icon={faFilePdf}
                          label={t('blog.generatePDF')}
                          className="text-green-300"
                          onClick={(e) => {
                            e.stopPropagation()
                            setOpenExportModal(true)
                            handleGeneratePdfClick()
                          }}
                        />
                        <>
                          <ButtonDropdown.Button
                            icon={faFolder}
                            label={t('blog.exportMedia')}
                            className="text-blue-300"
                            onClick={(e) => {
                              setLoading(true)
                              downloadAndZip(
                                portfolioDocs ?? [],
                                'portfolio',
                                kid?.firstName,
                              ).finally(() => {
                                setLoading(false)
                              })
                              e.stopPropagation()
                            }}
                          />
                        </>
                      </>
                    </ButtonDropdown.Section>
                  </ButtonDropdown.Menu>
                </div>
              )}
            </div>
          </div>
        </>
      }
    >
      <div className={'flex flex-col justify-center items-center p-3'}>
        {loading && <Loading />}

        {openExportModal && (
          <ExportModal
            typeOfDoc="Portfolio"
            buttonType={buttonType}
            type={modalType ? modalType : 'All'}
            isOpen={openExportModal}
            bloggDocs={portfolioDocs}
            onClose={() => {
              setOpenExportModal(false)
              setModalType(null)
            }}
            onImageSelected={undefined}
            selectedBlogDocId={''}
            onChangeStartDate={(date) => setStartDate(date)}
            onChangeEndDate={(date) => setEndDate(date)}
            startDate={startDate}
            endDate={endDate}
            schoolId={schoolId ?? currentUserRole?.school_pointer.id}
            kidName={kid?.kidName}
            kidId={kidId ?? kid?.id}
          />
        )}

        {/*loading && <Loading />*/}

        {portfolioDocs?.length === 0 && (
          <div
            className={
              'flex flex-col text-center justify-center items-center space-y-4 text-lg sm:text-2xl text-gray-300 font-bold my-44'
            }
          >
            <p>{t('blog.empty')}</p>
          </div>
        )}
        <BlogGrid
          type="Portfolio"
          kidName={kid?.kidName}
          kid={kid}
          kidFirstName={kid?.firstName}
          bloggDocs={portfolioDocs ?? []}
          onImageSelected={(src: string) => {
            setSelectedImage(src)
            setShowFullScreenModal(true)
          }}
          showOnlyImages={showOnlyImages}
          changeView={changeView}
          reflections={blogDocReflections}
          onSaveReflections={() => {
            if (portfolioDocs) {
              fetchReflections(portfolioDocs?.map((doc) => doc.id))
            }
            fetchPortfolio({
              school: currentUserRole?.attributes?.school_pointer,
              startDate: startDate,
              endDate: endDate,
              kid: kid,
              selectedTags: selectedTags,
              skip: skip,
            }).finally(() => {
              setLoading(false)
            })
          }}
        />
      </div>

      <FullscreenModal
        src={selectedImage}
        showModal={showFullScreenModal}
        hideModal={() => {
          setShowFullScreenModal(false)
        }}
      />
    </Page>
  )
})
