import React, { useContext, useState } from 'react'
import Parse from 'parse'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import classNames from 'classnames'
import { Button } from '../../../components/Button'
import { DocumentationStoreContext } from '../../../store/documentationStore'

export const AddFileModal: React.FC<{
  buttonClassName?: string
  cardId: string
  onFileAdded: any
  accept: any
  imageType: string
}> = ({ onFileAdded, cardId, buttonClassName, accept, imageType }) => {
  const { addDocumentationFile } = useContext(DocumentationStoreContext)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [myFiles, setMyFiles] = useState([] as any)
  const onDrop = (files: any) => {
    files.map((file: any) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        setMyFiles(
          files.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              base64Image: e?.target?.result,
            }),
          ),
        )
      }
      reader.readAsDataURL(file)
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: accept,
  })

  const files = myFiles.map((file: any, i: number) => (
    <div
      key={file?.path}
      onClick={() => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
      }}
    >
      {file.path}
      <FontAwesomeIcon
        icon={faTrash}
        className={'pl-2 text-xs cursor-pointer'}
      />
    </div>
  ))

  const [showModal, setShowModal] = useState(false)

  return (
    <div>
      <div
        className={classNames(
          'aspect-square mr-3 mt-3 h-[196px] object-cover rounded-2xl cursor-pointer bg-gray-200 flex justify-center items-center',
          buttonClassName,
        )}
        onClick={() => {
          setShowModal(true)
        }}
      >
        <FontAwesomeIcon
          className={' aspect-square h-[70px]'}
          icon={faPlus}
          size="4x"
        />
      </div>
      <ModalRegular
        title={''}
        isOpen={showModal}
        className={'max-w-2xl'}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <div className={'p-3'}>
          <div className="container mt-6">
            <div
              {...getRootProps({
                className:
                  'dropzone flex flex-col p-10 item-center justify-center bg-gray-50 border ',
              })}
            >
              <input {...getInputProps()} />
              <p className={'w-full text-center'}>
                {t('documents.dragAndDropTitle')}
              </p>
            </div>
            <div className={'mt-6 mb-6'}>
              <ul>{files}</ul>
            </div>

            <div className={'flex w-full justify-end mt-3'}>
              <Button
                loading={loading}
                label={t('documents.saveFile')}
                disabled={myFiles?.length === 0}
                onClick={async () => {
                  setLoading(true)
                  const file = myFiles[0]
                  const parseFile = new Parse.File('tyrafile', file)
                  const f = await parseFile.save()

                  await addDocumentationFile({
                    cardId: cardId,
                    file: f,
                    name: file?.name,
                    imageType: imageType,
                  })
                  setShowModal(false)
                  onFileAdded()
                }}
              />
            </div>
          </div>
        </div>
      </ModalRegular>
    </div>
  )
}
