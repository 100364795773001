import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

export const Spinner: React.FC<{ variant?: string }> = ({
  variant = 'default',
}) => {
  return (
    <div
      className={classNames(
        variant === 'important' && 'text-teal-50',
        variant === 'primary' && 'text-indigo-100',
        variant === 'default' && 'text-gray-500',
        variant === 'transparent' && 'text-indigo-500',
        variant === 'white' && 'text-white',
      )}
    >
      <FontAwesomeIcon
        className="w-5 h-5 text-base animate-spin"
        icon={faSpinner}
      />
    </div>
  )
}
