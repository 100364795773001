import React, { useContext, useState } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser } from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'
import ModalRegular from '../../../../../components/ModalRegular'
import { Button } from '../../../../../components/Button'
import PhoneInput from 'react-phone-input-2'
import { Slider } from '../../../../../components/Slider'
import { SE } from 'country-flag-icons/react/3x2'
import InviteParentCard from './InviteParentCard'
import { t } from 'i18next'
import { UserStoreContext } from '../../../../../store/userStore'
import { Kid } from '../../../../../Models/Kid'
import {
  getImageFromRole,
  getImageOrPlaceHolder,
  getUserNameFromRole,
} from '../../../../../utils/parseUtils'

interface Props {
  image?: string
  icon?: IconDefinition
  iconClassName?: string
  onClose: () => void
  isOpen: boolean
  kid: Kid
}

const InviteExistingModal: React.FC<Props> = ({
  image,
  icon,
  iconClassName,
  isOpen,
  onClose,
  kid,
}) => {
  const { userRolesToInviteToKid } = useContext(UserStoreContext)
  let renderedUsers: string[] = []
  const [search, setSearch] = useState('')
  return (
    <ModalRegular
      isOpen={isOpen}
      onClose={onClose}
      bgColor="bg-blue-300 p-4"
      title={t('admin.existingUser')}
      closeColor="white"
      className="bg-gray-50"
    >
      <div className="p-4 gap-y-4 flex flex-col">
        <div className="flex flex-row items-center">
          <div className="border shadow items-center bg-gray-200 max-h-[60px] min-h-[60px] max-w-[60px] min-w-[60px] rounded-full overflow-hidden relative">
            <img
              src={getImageOrPlaceHolder(kid?.attributes?.kidImage?._url)}
              className={classNames(
                'w-full h-full rounded-full',
                iconClassName,
              )}
            />
          </div>
          <div className="flex flex-col ml-4 items-start">
            <div className="font-bold text-lg">
              {kid.firstName + ' ' + kid.lastName}
            </div>
            {/*<div className="text-gray-400">20000823-XXXX</div>*/}
            <div className="text-gray-400">
              {kid.departmentPointer.klassName}
            </div>
          </div>
        </div>
        <div>
          <>{t('admin.existingUser')}</>
        </div>
        <div className="relative w-full flex flex-col">
          <input
            value={search}
            className="w-full border shadow rounded-full py-2 pl-7 focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
            placeholder={t('documents.search')}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-2 top-3 text-gray-400"
          />
        </div>
        <div className="flex flex-col w-full items-center">
          {userRolesToInviteToKid
            ?.filter((uR) => {
              if (search.length) {
                //@ts-ignore
                const name = uR.user?.firstName + uR.user?.lastName
                if (name) {
                  return name
                    ?.toLowerCase()
                    .includes(search.toLowerCase().replace(' ', ''))
                }
                return null
              }
              return uR
            })
            ?.sort((a, b) => {
              if (a.user?.firstName && b.user?.firstName) {
                if (a.user?.firstName < b.user?.firstName) {
                  return -1
                }
                if (a.user?.firstName > b.user?.firstName) {
                  return 1
                }
              }
              return 0
            })
            .map((userRole) => {
              if (renderedUsers.includes(userRole.user?.id ?? 'xyz')) {
                return null
              }
              if (userRole.user) {
                renderedUsers.push(userRole.user.id)
              }
              return (
                <>
                  <InviteParentCard
                    email={getUserNameFromRole(userRole)} //skrivs inte ut?! gustav
                    title={
                      userRole.user?.firstName + ' ' + userRole.user?.lastName
                    }
                    invitationSent={true}
                    moreDepartments={false}
                    allUserRolesForUser={userRolesToInviteToKid.filter(
                      (uR) => uR.user?.id === userRole.user?.id,
                    )}
                    kid={kid}
                    userRole={userRole}
                  />
                </>
              )
            })}
        </div>
      </div>
    </ModalRegular>
  )
}

export default InviteExistingModal
