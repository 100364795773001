//import { t } from 'i18next'
import ModalRegular from '../../../components/ModalRegular'
import { Button } from '../../../components/Button'
import { Slider } from '../../../components/Slider'
import { FileJobStoreContext, fileJobStore } from '../../../store/fileJobStore'
import Parse from 'parse'
import { useContext, useEffect, useState } from 'react'
import {
  ExportType,
  FILE_JOB_TYPE,
  RequestBlogPortfolioObject,
} from '../../../Models/FileJob'
import Swal from 'sweetalert2'
import { DateChooser } from '../../blog/components/DateChooser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/pro-solid-svg-icons'
import { BloggDoc } from '../../../Models/BloggDoc'
import BlogItem from './BlogItem'
import { BloggReflections } from '../../../Models/BloggReflections'
import { BlogRow } from '../../blog/components/BlogRow'
import moment from 'moment'
import { DateCalendarIconSmall } from '../../../components/DateCalendarIconSmall'
import { ImageCarousel, MediaItem } from '../../../components/ImageCarousel'
import { faDotCircle } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { PortfolioBlogStoreContext } from '../../../store/portfolioBlogStore'
import SwalCustom from '../../../components/SwalCustom'
import { useTranslation } from 'react-i18next'
import { DateCalendarIcon } from '../../../components/DateCalendarIcon'
import { getDateForTranslatation } from '../../events/Events'
import { FullscreenModal } from './FullScreenModal'
import DownloadModal from '../../settings/components/DownloadModal'

const ExportModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  selectedBlogDocId?: string
  bloggDocs?: BloggDoc[]
  bloggDoc?: BloggDoc
  onImageSelected: any
  reflections?: BloggReflections[]
  onSaveReflections?: () => void
  schoolId?: string
  type: 'All' | 'OnlySliders' | 'OnlySelectDate'
  buttonType?: 'Print' | 'Generate'
  onChangeStartDate?: (date: Date) => void
  onChangeEndDate?: (date: Date) => void
  startDate?: Date
  endDate?: Date
  typeOfDoc: 'Blog' | 'Portfolio'
  kidName?: string
  kidId?: string
}> = ({
  buttonType,
  isOpen,
  onClose,
  selectedBlogDocId,
  bloggDoc,
  type,
  bloggDocs,
  onImageSelected,
  reflections,
  schoolId,
  onSaveReflections,
  onChangeStartDate,
  onChangeEndDate,
  startDate,
  endDate,
  typeOfDoc,
  kidName,
  kidId,
}) => {
  const [selectedObjectIds, setSelectedObjectIds] = useState<string[]>([])
  const [openChooserModal, setOpenChooserModal] = useState(false)
  const [skip, setSkip] = useState<number>(0)
  const [selectedVideoSrc, setSelectedVideoSrc] = useState<string>('')
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false)
  const handleVideoClick = (media: { type: string; src: string }) => {
    setSelectedVideoSrc(media.src)
    setShowVideoModal(true)
  }
  const { t, i18n } = useTranslation()
  const { enableProclaimInFileJob, fetchCurrentFileJob } =
    useContext(FileJobStoreContext)
  const [currentFileJobId, setCurrentFileJobId] = useState<string | null>(null)
  const { selectedDepartments } = useContext(DepartmentStoreContext)
  const { selectedTags } = useContext(PortfolioBlogStoreContext)
  const [isClicked, setIsClicked] = useState(false)
  const [exportValues, setExportValues] = useState<RequestBlogPortfolioObject>({
    objectIds: bloggDoc ? [bloggDoc.id] : undefined,
    fromDate: undefined,
    toDate: undefined,
    filter: {
      departmentIds: undefined,
      tagIds: undefined,
      kidIds: kidId ? [kidId] : undefined,
      textSearch: undefined,
    },
    type: typeOfDoc === 'Blog' ? ExportType.BLOGG : ExportType.PORTFOLIO,
    optimizeImages: false,
    includeReflections: false,
    includeCurriculums: false,
    includeTags: false,
    createPrePage: false,
    prePageTitle: undefined,
    pdfName: undefined,
    languageCode: i18n.language, // add language Code - dont forget.
    schoolId: schoolId,
    enableProclaim: undefined, // Send notice or not
  })

  const handleDivClick = () => {
    setIsClicked((prevState) => !prevState)
  }

  const handleGeneratePdf = async () => {
    if (!exportValues.objectIds?.length) {
      exportValues.fromDate = startDate
      exportValues.toDate = endDate
    }
    if (selectedDepartments && exportValues.filter) {
      exportValues.filter.departmentIds = selectedDepartments.map(
        (dep) => dep.id,
      )
    }
    if (selectedTags && exportValues.filter) {
      exportValues.filter.tagIds = selectedTags.map((tag) => tag.id)
    }
    if (buttonType === 'Print') {
      exportValues.enableProclaim = false
    }
    exportValues.pdfName = ''
    if (exportValues.prePageTitle) {
      exportValues.pdfName = exportValues.prePageTitle
    } else {
      if (exportValues.objectIds && exportValues.objectIds.length === 1) {
        const currId = exportValues.objectIds[0]
        let singleBloggDoc = bloggDocs?.find((doc) => doc.id === currId)

        if (!singleBloggDoc) {
          singleBloggDoc = bloggDoc
        }
        if (singleBloggDoc && singleBloggDoc.attributes.title) {
          exportValues.pdfName = singleBloggDoc.attributes.title
        } else {
          if (exportValues.type === ExportType.BLOGG) {
            exportValues.pdfName =
              t('menu.blog') +
              '_' +
              moment(singleBloggDoc?.attributes.event_date).format('YYYY-MM-DD')
          } else {
            exportValues.pdfName =
              t('menu.portfolio') +
              '_' +
              (kidName ? kidName + '_' : '') +
              moment(singleBloggDoc?.attributes.event_date).format('YYYY-MM-DD')
          }
        }
      } else {
        if (exportValues.type === ExportType.BLOGG) {
          if (exportValues.fromDate && exportValues.toDate) {
            exportValues.pdfName = exportValues.pdfName =
              t('menu.blog') +
              '_' +
              moment(exportValues.fromDate).format('YYYY-MM-DD') +
              '_' +
              moment(exportValues.toDate).format('YYYY-MM-DD')
          } else {
            exportValues.pdfName = exportValues.pdfName =
              t('menu.blog') + '_' + moment().format('YYYY-MM-DD')
          }
        } else {
          if (exportValues.fromDate && exportValues.toDate) {
            exportValues.pdfName = exportValues.pdfName =
              t('menu.portfolio') +
              '_' +
              (kidName ? kidName + '_' : '') +
              moment(exportValues.fromDate).format('YYYY-MM-DD') +
              '_' +
              moment(exportValues.toDate).format('YYYY-MM-DD')
          } else {
            exportValues.pdfName = exportValues.pdfName =
              t('menu.portfolio') +
              '_' +
              (kidName ? kidName + '_' : '') +
              moment().format('YYYY-MM-DD')
          }
        }
      }
    }
    if (!exportValues.pdfName?.includes('.pdf')) {
      exportValues.pdfName = exportValues.pdfName + '.pdf'
    }
    try {
      const pdfUrl = await fileJobStore
        .generatePdf(exportValues)
        .then((filejob) => {
          if (filejob) {
            setCurrentFileJobId(filejob.fileJobObjectId)
          }
        })

      /* if (pdfUrl && pdfUrl !== 'undefined' && pdfUrl !== 'null') {
        window.open(pdfUrl, '_blank')
      } else {
        console.error('Invalid PDF URL:', pdfUrl)
      }

      console.log('Returned PDF URL:', pdfUrl)*/
    } catch (error) {}
  }
  const navigate = useNavigate()
  /*let modalTitleKey = 'general.export'

  if (buttonType === 'Print') {
    modalTitleKey = 'general.print'
  } else if (buttonType === 'Generate') {
    modalTitleKey = 'blog.export'
  }*/
  const [openSwal1, setOpenSwal1] = useState(false)
  const [showSelected, setShowSelected] = useState(false)
  const [showFullScreenModal, setShowFullScreenModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [isRotated, setIsRotated] = useState(false)
  var myTimeout: string | number | NodeJS.Timer | undefined
  useEffect(() => {
    let elapsed = 0
    const timeoutDuration = 20000

    if (currentFileJobId && buttonType === 'Print') {
      myTimeout = setInterval(() => {
        fetchCurrentFileJob(currentFileJobId).then((response) => {
          if (response && response.file?._url) {
            window.open(response.file?._url, '_blank')
            setOpenSwal1(false)
            clearInterval(myTimeout)
          }
        })
        elapsed += 1000
        if (elapsed >= timeoutDuration) {
          clearInterval(myTimeout)
          setOpenSwal1(false)

          Swal.fire({
            text: t('blog.workingOnIt'),
            icon: 'info',
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            confirmButtonText: t('general.ok'),
          })
        }
      }, 1000)
    }

    return () => clearInterval(myTimeout)
  }, [currentFileJobId, buttonType])

  const [openDownload, setOpenDownload] = useState(false)
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <ModalRegular
        isOpen={isOpen}
        onClose={onClose}
        bgColor="bg-eventsMain p-4"
        title={t(buttonType === 'Print' ? 'blog.print' : 'general.export')}
        closeColor="white"
      >
        <div className="p-10">
          <div className="flex flex-col w-full gap-y-6">
            {type === 'All' || type === 'OnlySelectDate' ? (
              <>
                <div>
                  <div className="flex flex-row justify-center">
                    {startDate && endDate && (
                      <DateChooser
                        defaultStartDate={startDate}
                        defaultEndDate={endDate}
                        onChangeDate={(startDate: Date, endDate: Date) => {
                          setSkip(0)
                          if (endDate < startDate) {
                            endDate = startDate
                          }
                          if (onChangeEndDate && onChangeStartDate) {
                            onChangeStartDate(startDate)
                            onChangeEndDate(endDate)
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
                {type !== 'OnlySelectDate' && (
                  <div>
                    <div
                      onClick={() => {
                        setOpenChooserModal(true)
                      }}
                      className="flex flex-row p-4 py-5 border w-full h-8 rounded-2xl justify-between cursor-pointer shadow hover:bg-blue-50"
                    >
                      <p className="flex items-center gap-x-1 font-bold text-eventsMain">
                        <> {t('blog.chooseSingle')}</>
                        <p>({selectedObjectIds.length})</p>
                      </p>
                      <div className="flex items-center">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="text-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col">
                <h1 className="text-eventsMain font-bold text-lg">
                  <>{t('blog.blogExportPrint')}</>{' '}
                </h1>
                <p className="font-normal text-gray-700">
                  <>{t('blog.blogExportPrintText')}</>
                </p>
              </div>
              <Slider
                id={1}
                value={exportValues.optimizeImages}
                onSliderClick={() => {
                  setExportValues({
                    ...exportValues,
                    optimizeImages: !exportValues.optimizeImages,
                  })
                }}
                onBoxClick={() => {}}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col">
                <h1 className="text-eventsMain font-bold text-lg">
                  <>{t('blog.blogIncludeGovDoc')}</>
                </h1>
                <p className="font-normal text-gray-700">
                  <>{t('blog.blogIncludeGovDocText')}</>
                </p>
              </div>
              <Slider
                id={2}
                value={exportValues.includeCurriculums}
                onSliderClick={() => {
                  setExportValues({
                    ...exportValues,
                    includeCurriculums: !exportValues.includeCurriculums,
                  })
                }}
                onBoxClick={() => {}}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col">
                <h1 className="text-eventsMain font-bold text-lg">
                  <>{t('blog.blogIncludeReflection')}</>
                </h1>
                <p className="font-normal text-gray-700">
                  <>{t('blog.blogIncludeReflectionText')}</>
                </p>
              </div>
              <Slider
                id={3}
                value={exportValues.includeReflections}
                onSliderClick={() => {
                  setExportValues({
                    ...exportValues,
                    includeReflections: !exportValues.includeReflections,
                  })
                }}
                onBoxClick={() => {}}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col">
                <h1 className="text-eventsMain font-bold text-lg">
                  <>{t('blog.blogAddCoverSheet')}</>
                </h1>
                <p className="font-normal text-gray-700">
                  <>{t('blog.blogAddCoverSheetText')}</>
                </p>
              </div>
              <Slider
                id={4}
                value={exportValues.createPrePage}
                onSliderClick={() => {
                  setExportValues({
                    ...exportValues,
                    createPrePage: !exportValues.createPrePage,
                  })
                }}
                onBoxClick={() => {}}
              />
            </div>

            {exportValues.createPrePage && (
              <div>
                <input
                  value={exportValues.prePageTitle}
                  placeholder={t('general.title')}
                  className="border relative border-gray-300 p-2 rounded-2xl text-center w-full shadow focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                  onChange={(e) => {
                    setExportValues({
                      ...exportValues,
                      prePageTitle: e.target.value,
                    })
                  }}
                />
              </div>
            )}
            {buttonType === 'Generate' && (
              <div className="flex flex-row justify-center w-full">
                <Button
                  label={t('blog.generatePDF')}
                  variant={'bankId'}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleGeneratePdf().finally(() => {
                      Swal.fire({
                        html: `
                        <div style="text-align: center;">
                          <div>${t('blog.creatingPDFText')}</div>
                          <div style="margin-top: 10px;">${t(
                            'blog.goToDownloadText',
                          )}</div>
                        </div>
                      `,
                        text: '',
                        showCancelButton: true,
                        showConfirmButton: true,
                        confirmButtonColor: '#7eb5f4',
                        confirmButtonText: t('blog.myDownloads'),
                        width: '600px',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setOpenDownload(true)
                        }
                        if (result.isDismissed) {
                          onClose()
                        }
                      })
                    })
                  }}
                />{' '}
              </div>
            )}
            {openDownload && (
              <DownloadModal
                showModal={openDownload}
                hideModal={() => {
                  setOpenDownload(false)
                  onClose()
                }}
              />
            )}
            {buttonType === 'Print' && (
              <div className="flex flex-row justify-center w-full">
                <Button
                  label={t('blog.print')}
                  variant={'bankId'}
                  onClick={() => {
                    handleGeneratePdf().finally(() => {
                      setOpenSwal1(true)
                    })
                  }}
                />
              </div>
            )}
          </div>
          <SwalCustom
            isOpen={openSwal1}
            onClose={() => {
              setOpenSwal1(false)
              if (currentFileJobId) {
                enableProclaimInFileJob(currentFileJobId).finally(() => {
                  setCurrentFileJobId(null)
                  onClose()
                  clearInterval(myTimeout)
                })
              }
            }}
            showCancelButton={true}
            showOkButton={false}
            header={t('blog.fileGenerating')}
            spinner={true}
          >
            <div className="p-10">
              <>{t('blog.waitCreatePDF')}</>
            </div>
          </SwalCustom>
        </div>
        <div>
          {' '}
          <ModalRegular
            isOpen={openChooserModal}
            onClose={() => {
              setOpenChooserModal(false)
            }}
            bgColor="bg-eventsMain p-4"
            title={t('blog.chooseSingle')}
            closeColor="white"
          >
            <div className="p-10 flex flex-col gap-y-5">
              <div className="font-bold text-md">
                {startDate && endDate ? (
                  <p>
                    {' '}
                    {`${startDate.getDate()} ${getDateForTranslatation(
                      String(startDate.getMonth() + 1).padStart(2, '0'),
                    )} ${startDate.getFullYear()}`}{' '}
                    -{' '}
                    {`${endDate.getDate()} ${getDateForTranslatation(
                      String(endDate.getMonth() + 1).padStart(2, '0'),
                    )} ${endDate.getFullYear()}`}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              {bloggDocs?.map((bloggDoc, index) => (
                <div
                  key={index}
                  className="flex flex-row items-center gap-y-4 "
                  onClick={() => {
                    let selected = [...selectedObjectIds]
                    if (selectedObjectIds.find((obj) => obj === bloggDoc.id)) {
                      selected = selected.filter((obj) => obj !== bloggDoc.id)
                      setSelectedObjectIds(selected)
                    } else {
                      selected.push(bloggDoc.id)
                      setSelectedObjectIds(selected)
                    }
                    exportValues.objectIds =
                      selected.length > 0 ? selected : undefined
                    setExportValues(exportValues)
                  }}
                >
                  <div
                    className={`flex-grow border-2 p-4 shadow cursor-pointer hover:bg-blue-50 rounded-2xl ${
                      selectedObjectIds.find((obj) => obj === bloggDoc.id)
                        ? 'border-blue-300'
                        : ''
                    }`}
                  >
                    <div className="flex flex-row items-center justify-between">
                      <div className="flex flex-row">
                        <DateCalendarIconSmall
                          day={bloggDoc.attributes.publish_date}
                          mode="month"
                        />
                        <div className="flex flex-col max-w-[300px] flex-wrap">
                          <p className="font-bold">
                            {bloggDoc.attributes.title}
                          </p>
                          <p>{bloggDoc.attributes.content}</p>
                        </div>
                      </div>
                      <div className="flex rounded-2xl h-[81px] w-[81px]">
                        <div className="overflow-hidden object-cover border bg-gray-50 max-w-[80px] max-h-[80px] min-h-[80px] min-w-[80px] rounded-2xl">
                          <ImageCarousel
                            className="w-full h-full object-cover"
                            arrowSmall
                            images={bloggDoc?.attributes?.images
                              ?.filter(
                                (item: any) =>
                                  !item?.attributes?.audio_file &&
                                  !item?.attributes?.video_file,
                              )
                              ?.map((item: any) => {
                                if (item?.attributes?.image?._url) {
                                  return {
                                    type: item?.attributes?.is_video
                                      ? 'video'
                                      : 'image',
                                    thumbnailSrc: item?.attributes?.image?._url,
                                    src: item?.attributes?.is_video
                                      ? item?.attributes?.video_file
                                      : item?.attributes?.image?._url,
                                  }
                                }
                                return null
                              })
                              .filter(Boolean)}
                            imageOnClick={(media: {
                              type: string
                              src: string
                            }) => {
                              setSelectedImage(media.src) // Assuming setSelectedImage updates the state for selectedImage
                              setShowFullScreenModal(true) // This line is crucial, it should set the state to true to show the modal
                            }}
                          />
                          <FullscreenModal
                            src={selectedImage}
                            showModal={showFullScreenModal}
                            hideModal={() => {
                              setShowFullScreenModal(false)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center min-w-[50px] min-h-[50px] max-h-[50px] max-w-[50px]">
                    <FontAwesomeIcon
                      icon={faDotCircle}
                      className={
                        selectedObjectIds.find((obj) => obj === bloggDoc.id)
                          ? 'text-blue-300'
                          : 'text-gray-400'
                      }
                    />
                  </div>
                </div>
              ))}
              <div className="flex flex-row w-full justify-center">
                {/*<Button
                label="Stäng"
                variant={'fullRed'}
                onClick={() => {
                  setOpenChooserModal(false)
                }}
              />*/}

                <Button
                  label={t('general.done')}
                  variant={'fullBlue'}
                  onClick={() => {
                    setOpenChooserModal(false)
                  }}
                />
              </div>
              <div className="flex flex-row w-full justify-center">
                {' '}
                <div
                  className="font-bold hover:scale-105 cursor-pointer flex flex-col"
                  onClick={() => {
                    if (selectedObjectIds.length > 0) {
                      setShowSelected(!showSelected) // Toggle the display of selected objects
                      setIsRotated(!isRotated) // Toggle the rotation state
                    } else {
                      setShowSelected(false)
                      setIsRotated(false) // Ensure it's reset if there are no selected objects
                    }
                  }}
                >
                  {`Valda (${selectedObjectIds.length})`}
                  <FontAwesomeIcon
                    className={`transition-transform duration-300 ${
                      isRotated ? 'rotate-180' : 'rotate-0'
                    } ${
                      showSelected
                        ? 'animate-rotate-to-180'
                        : 'animate-rotate-to-0'
                    }`}
                    icon={faChevronDown}
                  />
                </div>
              </div>
              {showSelected && (
                <div className="flex flex-col gap-y-5 transition ease-in-out duration-150">
                  <div className="font-bold">{t('blog.choosenPosts')}:</div>
                  {bloggDocs
                    ?.filter((doc) => selectedObjectIds.includes(doc.id))
                    .map((bloggDoc, index) => (
                      <div
                        key={index}
                        className="flex flex-row items-center gap-y-4 "
                        onClick={() => {
                          let selected = [...selectedObjectIds]
                          const isCurrentlySelected = selected.includes(
                            bloggDoc.id,
                          )
                          if (isCurrentlySelected) {
                            selected = selected.filter(
                              (id) => id !== bloggDoc.id,
                            )
                            setSelectedObjectIds(selected)

                            if (selected.length === 0 && showSelected) {
                              setShowSelected(false)
                            }
                          } else {
                            selected.push(bloggDoc.id)
                            setSelectedObjectIds(selected)
                          }
                          exportValues.objectIds =
                            selected.length > 0 ? selected : undefined
                          setExportValues({ ...exportValues })
                        }}
                      >
                        <div
                          className={`flex-grow border-2 p-4 shadow cursor-pointer hover:bg-blue-50 rounded-2xl ${
                            selectedObjectIds.find((obj) => obj === bloggDoc.id)
                              ? 'border-blue-300'
                              : ''
                          }`}
                        >
                          <div className="flex flex-row items-center justify-between">
                            <div className="flex flex-row">
                              <DateCalendarIconSmall
                                day={bloggDoc.attributes.publish_date}
                                mode="month"
                              />
                              <div className="flex flex-col max-w-[300px] flex-wrap">
                                <p className="font-bold">
                                  {bloggDoc.attributes.title}
                                </p>
                                <p>{bloggDoc.attributes.content}</p>
                              </div>
                            </div>
                            <div className="flex rounded-2xl h-[81px] w-[81px]">
                              <div className="overflow-hidden object-cover border bg-gray-50 max-w-[80px] max-h-[80px] min-h-[80px] min-w-[80px] rounded-2xl">
                                {' '}
                                <ImageCarousel
                                  className="w-full h-full object-cover"
                                  arrowSmall
                                  images={bloggDoc?.attributes?.images
                                    ?.filter(
                                      (item: any) =>
                                        !item?.attributes?.audio_file &&
                                        !item?.attributes?.video_file,
                                    )
                                    ?.map((item: any) => {
                                      if (item?.attributes?.image?._url) {
                                        return {
                                          type: item?.attributes?.is_video
                                            ? 'video'
                                            : 'image',
                                          thumbnailSrc:
                                            item?.attributes?.image?._url,
                                          src: item?.attributes?.is_video
                                            ? item?.attributes?.video_file
                                            : item?.attributes?.image?._url,
                                        }
                                      }
                                      return null
                                    })
                                    .filter(Boolean)}
                                  imageOnClick={(media: {
                                    type: string
                                    src: string
                                  }) => {
                                    setSelectedImage(media.src) // Assuming setSelectedImage updates the state for selectedImage
                                    setShowFullScreenModal(true) // This line is crucial, it should set the state to true to show the modal
                                  }}
                                />
                              </div>
                            </div>{' '}
                          </div>
                        </div>
                        <div className="flex justify-center items-center min-w-[50px] min-h-[50px] max-h-[50px] max-w-[50px]">
                          <FontAwesomeIcon
                            icon={faDotCircle}
                            className={
                              selectedObjectIds.find(
                                (obj) => obj === bloggDoc.id,
                              )
                                ? 'text-blue-300'
                                : 'text-gray-400'
                            }
                          />
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </ModalRegular>
        </div>
      </ModalRegular>
    </div>
  )
}

export default ExportModal
