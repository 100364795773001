import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { UserRole } from '../Models/UserRole'
import { Box } from '../Models/Box'
import { Category } from '../components/CategoryBox'
import { Kid } from '../Models/Kid'

class BoxStore {
  boxItems?: Box[]
  constructor() {
    makeAutoObservable(this)
  }

  setBoxItems = (boxItems: Box[]) => {
    this.boxItems = boxItems
  }

  getBoxItems = async (kidId: string) => {
    const kid = await new Parse.Query(Kid).get(kidId)

    const boxItemsQuery = await new Parse.Query(Box)
    boxItemsQuery.equalTo('kid_owner', kid)
    boxItemsQuery.equalTo('school_pointer', kid.school_pointer)

    const boxItems = await boxItemsQuery.find()

    this.setBoxItems(boxItems)
  }

  changeBoxStatus = async (boxId: string, status: number) => {
    const box = await new Parse.Query(Box).get(boxId)
    box.set('status', status)
    await box.save()
  }

  saveCategory = async (category: Category, kidId: string) => {
    const kid = await new Parse.Query(Kid).get(kidId)
    const box = new Parse.Object('box')
    box.set('items', category.title)
    box.set('kid_owner', kid)
    box.set('school_pointer', kid.school_pointer)
    box.set('school', kid.school_pointer.schoolName)
    box.set('edited_date', new Date())

    await box.save()
  }

  deleteCategory = async (boxId: string) => {
    const box = await new Parse.Query(Box).get(boxId)

    await box.destroy()
  }
}

export const boxStore = (() => {
  return new BoxStore()
})()
export const BoxStoreContext: React.Context<BoxStore> =
  React.createContext(boxStore)
