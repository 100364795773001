import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

type ImageUploaderSize = 'sm' | 'md' | 'lg'

interface ImageUploaderProps {
  onImageChange: (file: File) => void
  initialImageUrl?: string
  size?: ImageUploaderSize
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  onImageChange,
  initialImageUrl,
  size = 'md',
}) => {
  const [localImageUrl, setLocalImageUrl] = useState(initialImageUrl || '')
  const sizeStyles: Record<ImageUploaderSize, string> = {
    sm: 'max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
    md: 'max-w-[60px] max-h-[60px] min-w-[60px] min-h-[60px]',
    lg: 'max-w-[100px] max-h-[100px] min-w-[100px] min-h-[100px]',
  }

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const newUrl = URL.createObjectURL(file)
      setLocalImageUrl(newUrl)

      if (onImageChange) {
        onImageChange(file)
      }
    }
  }

  return (
    <div>
      <label
        className={`flex items-center justify-center bg-blue-50 border-2 border-blue-300 rounded-full my-2 overflow-hidden cursor-pointer hover:scale-[110%] transition duration-200 ${sizeStyles[size]}`}
        style={
          localImageUrl
            ? {
                backgroundImage: `url(${localImageUrl})`,
                backgroundSize: 'cover',
              }
            : {}
        }
      >
        {!localImageUrl && (
          <FontAwesomeIcon icon={faPlus} className="text-blue-300" />
        )}
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleImageUpload}
        />
      </label>
    </div>
  )
}
