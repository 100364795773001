import {
  faEllipsisVertical,
  faPen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import { faCross, faX } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import React, { useContext, useState } from 'react'
import { Box } from '../Models/Box'
import { BoxStoreContext } from '../store/boxStore'
import { Button } from './Button'
import { ButtonDropdown } from './Dropdown'
import { Loading } from './Loading'
import { UserStoreContext } from '../store/userStore'

export interface Category {
  key: number
  title: string
}

const CategoryBox: React.FC<{
  boxItem: Box
  removeCategory: (boxId: string) => void
  onSave: () => void
}> = ({ boxItem, removeCategory, onSave }) => {
  const [loading, setLoading] = useState(false)
  const [selectedButton, setSelectedButton] = useState<string | null>(null)
  const { changeBoxStatus, getBoxItems } = useContext(BoxStoreContext)

  const { readOnly } = useContext(UserStoreContext)

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }

  return (
    <div className="flex w-full justify-between flex-col sm:flex-row gap-y-4 pl-4 items-start sm:items-center border-b border-b-gray-200 pb-4">
      {loading && <Loading />}
      <div className="flex ">{boxItem.items}</div>
      <div className="flex items-center justify-between w-full sm:justify-end">
        <div className="gap-x-2 sm:gap-x-4 flex ">
          <Button
            size="sm"
            variant={boxItem.status === 0 ? 'fullRed' : 'borderGrayBoxRed'}
            label={t('box.out')}
            onClick={() => {
              setLoading(true)
              changeBoxStatus(boxItem.id, 0)
                .then(() => {
                  onSave()
                })
                .catch((e) => {
                  throw e
                })
                .finally(() => {
                  setLoading(true)
                })
            }}
          />
          <Button
            size="sm"
            variant={
              boxItem.status === 1 ? 'fullYellow' : 'borderGrayBoxYellow'
            }
            label={t('box.few')}
            onClick={() => {
              setLoading(true)
              changeBoxStatus(boxItem.id, 1)
                .then(() => {
                  onSave()
                })
                .catch((e) => {
                  throw e
                })
                .finally(() => {
                  setLoading(false)
                })
            }}
          />
          <Button
            size="sm"
            variant={boxItem.status === 2 ? 'fullGreen' : 'borderGrayBoxGreen'}
            label={t('box.many')}
            onClick={() => {
              setLoading(true)
              changeBoxStatus(boxItem.id, 2)
                .then(() => {
                  onSave()
                })
                .catch((e) => {
                  throw e
                })
                .finally(() => {
                  setLoading(false)
                })
            }}
          />
        </div>
        <div className="relative flex sm:ml-8">
          {!readOnly?.box && (
            <FontAwesomeIcon
              size="1x"
              icon={faTrashCan}
              className="text-red-700 cursor-pointer pl-4 "
              onClick={() => {
                removeCategory(boxItem.id)
              }}
            />
          )}
          {/*<button
              type="button"
              className="w-full rounded-md group focus:outline-none "
              aria-haspopup="true"
              aria-expanded="true"
              onClick={toggleDropdown}
            >
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="text-gray-600 sm:text-gray-400 px-4 hover:scale-110 "
              ></FontAwesomeIcon>
            </button>

            <div
              className="absolute right-4 z-50 mx-3 origin-top divide-y w-44 top-0"
              style={{
                display: dropdownVisible ? 'block' : 'none',
              }}
            >
              <ButtonDropdown.Menu>
                <ButtonDropdown.Section>
                  <ButtonDropdown.Button
                    icon={faPen}
                    label={t('absence.change')}
                    className="text-yellow-600"
                  />
                </ButtonDropdown.Section>

                <ButtonDropdown.Section>
                  <ButtonDropdown.Button
                    icon={faTrashCan}
                    label={t('absence.delete')}
                    className="text-red-700"
                    onClick={() => {
                      removeCategory(boxItem.id)
                    }}
                  />
                </ButtonDropdown.Section>
              </ButtonDropdown.Menu>
                  </div>*/}
        </div>
      </div>
    </div>
  )
}

export default CategoryBox
