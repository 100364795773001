import React from 'react'
import { BloggDoc } from '../../../Models/BloggDoc'
import moment, { Moment as MomentType } from 'moment'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/sv'

export const ScheduleBlock = ({
  schedule,
  firstDate,
  amountOfDays,
  typeOfView,
}: {
  schedule: any
  firstDate: MomentType
  amountOfDays: number
  typeOfView: string
}) => {
  const renderDiv = () => {
    const div: any = []

    const amountOfHours = amountOfDays * 24
    const totalTime = amountOfHours * 60 * 60 * 1000 // Time in miliseconds

    schedule.map((sched: any) => {
      if (sched.from && sched.from !== 'ledig') {
        const startTime = sched.from
          ? moment(sched.date + sched.from, 'Y-M-D HH:mm')
          : moment(sched.date + sched.to, 'Y-M-D HH:mm').startOf('day')

        const endTime = sched.to
          ? moment(sched.date + sched.to, 'Y-M-D HH:mm')
          : moment(sched.date + sched.from, 'Y-M-D HH:mm').endOf('day')

        const timeDiff: any = moment.duration(endTime.diff(startTime))
        const timeDiffInMiliseconds = timeDiff * 60 * 60 * 1000

        const firstHour = moment(firstDate).startOf('day')

        const fromLeft: any = moment.duration(startTime.diff(firstHour))
        const fromLeftInMilliseconds = fromLeft * 1000 // Time in miliseconds
        const fromLeftPercentage = (fromLeft / totalTime) * 100
        //const checkInOutLength = timeDiffInMiliseconds / totalTime
        const checkInOutLength = timeDiff / totalTime
        const checkInOutWidth = checkInOutLength * 100

        let left = fromLeftPercentage + '%'
        let width = checkInOutWidth + '%'

        let divLength = {
          width: width,
          left: left,
        }
        div.push(
          <div className="scheduleBlock" style={divLength}>
            <small>
              {moment(sched.date + sched.from, 'Y-M-D HH:mm').format('HH:mm')}
              {' - '}
              {moment(sched.date + sched.to, 'Y-M-D HH:mm').format('HH:mm')}
            </small>
          </div>,
        )
      }
    })
    return div
  }
  return <>{renderDiv()}</>
}
