import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons'
import { AddFileModal } from './AddFileModal'
import { downloadFile } from '../../../utils/downloadFile'
import React from 'react'

export const Pdfs = ({
  card,
  editMode,
  onAdd,
  onRemove,
  onRemoveCard,
}: {
  card: any
  editMode: boolean
  onAdd: any
  onRemove: any
  onRemoveCard: any
}) => {
  return (
    <div
      className={'flex flex-wrap justify-center min-h-[200px] border-b pb-3 pb'}
    >
      <div className={'flex justify-between w-full'}>
        <p className={'w-full text-red-400'}>
          <span className={'mr-2'}>
            <FontAwesomeIcon icon={faFilePdf} />
          </span>
          PDF ({card?.attributes?.pdfs?.length ?? 0})
        </p>
        {editMode && (
          <FontAwesomeIcon
            icon={faTrash}
            className={'text-gray-400 hover:text-red-500 cursor-pointer'}
            onClick={async () => {
              if (window.confirm('Är du säker du vill ta bort?')) {
                onRemoveCard()
              }
            }}
          />
        )}
      </div>
      {editMode && (
        <AddFileModal
          cardId={card?.id}
          imageType={'pdf'}
          accept={{
            'application/pdf': ['.pdf'],
          }}
          onFileAdded={onAdd}
          buttonClassName={'text-red-500'}
        />
      )}
      {card?.attributes?.pdfs?.map((pdf: any, index: number) => {
        if (pdf?.attributes?.pdf_thumbnail) {
          return (
            <img
              key={index}
              className={
                'aspect-square mr-3 mt-3 h-[196px] object-cover rounded-2xl cursor-pointer'
              }
              onClick={() => {
                downloadFile(pdf?.attributes?.pdf?._url)
              }}
              src={pdf?.attributes?.pdf_thumbnail?._url}
              alt=""
            />
          )
        } else {
          return (
            <div
              key={index}
              className={
                'flex flex-col items-center justify-center cursor-pointer'
              }
              onClick={() => {
                downloadFile(pdf?.attributes?.pdf?._url)
              }}
            >
              <FontAwesomeIcon
                className={'text-red-500 aspect-square mr-3 mt-3 h-[100px]'}
                icon={faFilePdf}
                size="4x"
              />
              <p className={'w-[80px] overflow-hidden line-clamp-2 h-[50px]'}>
                {pdf?.attributes?.name}
              </p>
            </div>
          )
        }
      })}
    </div>
  )
}
