import Parse from 'parse'
import { School } from './School'
import { User } from './User'
import { UserRole } from './UserRole'
import { ARCHIVE_STATE } from '../Enums/TyraEnums'

export interface FileInterface {
  id?: string
  name?: string
  file: string
  thumbnail?: string
  mime_type?: string
  metadata?: any
}

export class File extends Parse.Object {
  public static readonly Fields = {
    className: 'files',

    FILE: 'file',
    NAME: 'name',
    ORIGINS: 'origins',
    THUMBNAIL: 'thumbnail',
    MIME_TYPE: 'mime_type',
    META_DATA: 'metadata',
    UPLOADER: 'uploader',
    SCHOOL_POINTER: 'school_pointer',
    ARCHIVE_STATE: 'archive_state',
  }
  constructor() {
    super(File.Fields.className)
  }

  get id(): string {
    return this.get('id')
  }

  get uploader(): UserRole | undefined {
    return this.get(File.Fields.UPLOADER)
  }
  set uploader(value: UserRole | undefined) {
    this.set(File.Fields.UPLOADER, value)
  }
  get school_pointer(): School {
    return this.get(File.Fields.SCHOOL_POINTER)
  }
  set school_pointer(value: School) {
    this.set(File.Fields.SCHOOL_POINTER, value)
  }

  get file(): Parse.File {
    return this.get(File.Fields.FILE)
  }
  set file(value: Parse.File) {
    this.set(File.Fields.FILE, value)
  }

  get name(): string | undefined {
    return this.get(File.Fields.NAME)
  }
  set name(value: string | undefined) {
    this.set(File.Fields.NAME, value)
  }

  get thumbnail(): Parse.File | undefined {
    return this.get(File.Fields.THUMBNAIL)
  }
  set thumbnail(value: Parse.File | undefined) {
    this.set(File.Fields.THUMBNAIL, value)
  }

  get mime_type(): string | undefined {
    return this.get(File.Fields.MIME_TYPE)
  }
  set mime_type(value: string | undefined) {
    this.set(File.Fields.MIME_TYPE, value)
  }
  get metadata(): any | undefined {
    return this.get(File.Fields.META_DATA)
  }
  set metadata(value: any | undefined) {
    this.set(File.Fields.META_DATA, value)
  }

  get origins(): [Parse.Object] | undefined {
    return this.get(File.Fields.ORIGINS)
  }
  set origins(value: [Parse.Object] | undefined) {
    this.set(File.Fields.ORIGINS, value)
  }
  get archive_state(): ARCHIVE_STATE | undefined {
    return this.get(File.Fields.ARCHIVE_STATE)
  }
  set archive_state(value: ARCHIVE_STATE | undefined) {
    this.set(File.Fields.ARCHIVE_STATE, value)
  }

  asJson(): FileInterface {
    return {
      id: this.id,
      name: this.name,
      file: this.file?._url,
      thumbnail: this.thumbnail?._url,
      mime_type: this.mime_type,
      metadata: this.metadata,
    }
  }
}
Parse.Object.registerSubclass(File.Fields.className, File)
