// BackButton.tsx
import React from 'react'

interface BackButtonProps {
  className?: string
  text?: string
  onClick: () => void
}

const BackButton: React.FC<BackButtonProps> = ({
  className,
  text = 'Tillbaka',
  onClick,
}) => {
  return (
    <div className={className} onClick={onClick}>
      {text}
    </div>
  )
}

export default BackButton
