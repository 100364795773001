import React, { useContext, useEffect, useState } from 'react'
import Page from '../../components/Page'
import ColorCard from '../../components/ColorCard'
import AdminCard from '../admin/adminDashboard/components/AdminCard'
import { faBookOpen, faFolder, faVideo } from '@fortawesome/pro-solid-svg-icons'
import { Button } from '../../components/Button'
import { UserStoreContext } from '../../store/userStore'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalRegular from '../../components/ModalRegular'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { t } from 'i18next'
import { NavigationStoreContext } from '../../store/navigationStore'
import MultiSelectOpen from '../../components/MultiSelectOpen'
import { SearchKids } from '../ourKids/OurKids'
import { SearchDocument } from '../documents/DocumentsFromType'
import VideoModal from '../../components/VideoModal'
import i18n from '../../i18nextConfig'
import DocAndLathundCard from './components/DocAndLathundCard'
import { Loading } from '../../components/Loading'
import { title } from 'process'
import { values } from 'mobx'
import { Tags } from '../blog/components/Tags'
import { number } from 'yup'

interface DocAndLatHundInterface {
  type: string
  username: string
}
const initialValues = {
  type: 'document',
  username: '',
}

export const DocAndLathund: React.FC<{ onClick?: any }> = ({ onClick }) => {
  // Här kan du skriva din komponents kod
  const { setTitle } = useContext(NavigationStoreContext)
  const { logoutUser, currentUser, fetchManualDoc, manuals } =
    useContext(UserStoreContext)
  const navigate = useNavigate()
  const { fetchUserRole, currentUserRole, readOnly } =
    useContext(UserStoreContext)
  const [search, setSearch] = useState('')
  const [showCategoryModal, setShowCategortModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [variant, setVariant] = useState<DocAndLatHundInterface>(initialValues)
  const [openVideoModal, setOpenVideoModal] = useState(false)
  const territory = currentUserRole?.school_pointer.attributes.territory
  useEffect(() => {
    if (t) {
      setLoading(true)
      fetchManualDoc(territory).finally(() => {
        setLoading(false)
      })
      setTitle(t('settings.lazyDogAndDoc'))
    }
  }, [t])

  const getFilterData = (manuals: any) => {
    const filterData = manuals.map((manual: any) => {
      const currentManual = getManualData(manual)
      return {
        title: currentManual?.title,
        tags: currentManual?.tags,
      }
    })

    console.log('Filter Data', filterData)
    return filterData
  }

  const allFilters: any[] = []
  const [selectedFilters, setSelectedFilters] = useState([])

  if (manuals) {
    let idCounter = 0
    for (const manual of manuals) {
      if (!allFilters.some((item) => item.value === manual.title)) {
        allFilters.push({
          id: idCounter++,
          label: manual.title,
          value: manual.title,
        })
      }

      if (manual.tags) {
        for (const tag of manual?.tags) {
          if (!allFilters.some((item) => item.value === tag)) {
            allFilters.push({
              id: idCounter++,
              label: manual.tag,
              value: manual.tag,
            })
          }
        }
      }
    }
  }

  useEffect(() => {
    if (manuals) {
      getFilterData(manuals)
    }
  }, [manuals])

  const getManualData = (manual: any) => {
    if (i18n.language === 'sv') {
      return {
        title: manual?.attributes?.sv_title,
        content: manual?.attributes?.sv_content,
        category: manual.attributes.sv_category,
        tags: manual.attributes.sv_tags,
        link: manual.attributes.sv_link,
      }
    } else if (i18n.language === 'en') {
      return {
        title: manual?.attributes?.en_title,
        content: manual?.attributes?.en_content,
        category: manual.attributes.en_category,
        tags: manual.attributes.en_tags,
        link: manual.attributes.en_link,
      }
    } else if (i18n.language === 'es') {
      return {
        title: manual?.attributes?.es_title,
        content: manual?.attributes?.es_content,
        category: manual.attributes.es_category,
        tags: manual.attributes.es_tags,
        link: manual.attributes.es_link,
      }
    }
  }

  const openLink = (link: string) => {
    window.open(link, '_blank')
  }
  return (
    <Page
      naked
      topBar={
        <div className="flex flex-col items-center gap-y-6 sm:flex-row sm:gap-x-10 w-full justify-center">
          <Button
            size={'setWidth'}
            variant={variant.type === 'document' ? 'borderMain' : 'disableGray'}
            label={t('menu.documents')}
            onClick={() =>
              setVariant({ ...variant, type: 'document', username: '' })
            }
          />
          <Button
            size={'setWidth'}
            variant={variant.type === 'latdogs' ? 'borderMain' : 'disableGray'}
            label={t('settings.lazyDog')}
            onClick={() =>
              setVariant({ ...variant, type: 'latdogs', username: '' })
            }
          />
        </div>
      }
    >
      {loading && <Loading />}

      <ColorCard
        title={
          variant.type === 'document'
            ? t('menu.documents')
            : variant.type === 'latdogs'
            ? t('settings.lazyDog')
            : t('general.documentsAndHelpTools')
        }
        bgColor="bg-eventsMain"
        icon={
          variant.type === 'document'
            ? faFolder
            : variant.type === 'latdogs'
            ? faBookOpen
            : faBookOpen
        }
        searchFilter={
          <SearchDocument
            onSearch={(s: string) => {
              setSearch(s)
            }}
            initialValue={search}
            placeholder={t('documents.search')}
          />
        }
      >
        <div className="flex flex-row flex-wrap">
          <div className="flex flex-row flex-wrap gap-10 justify-center items-center m-10">
            {variant.type === 'document' ? (
              <>
                {manuals
                  ?.filter(
                    (manual) =>
                      manual.attributes.en_catalog === 'Documents' &&
                      getManualData(manual)
                        ?.title.toLowerCase()
                        .includes(search.toLowerCase()),
                  )
                  .map((manual) => {
                    const currMan = getManualData(manual)
                    return (
                      <div>
                        <DocAndLathundCard
                          tags={currMan?.tags}
                          role={currMan?.title}
                          content={currMan?.content}
                          isFixedSize={true}
                          onClick={() => openLink(currMan?.link)}
                          link={currMan?.link}
                        />
                      </div>
                    )
                  })}
              </>
            ) : variant.type === 'latdogs' ? (
              <>
                {manuals
                  ?.filter((manual) => {
                    const currMan = getManualData(manual)
                    const titleMatch = currMan?.title
                      .toLowerCase()
                      .includes(search.toLowerCase())
                    const tagsMatch = currMan?.tags?.some((tag: string) =>
                      tag.toLowerCase().includes(search.toLowerCase()),
                    )
                    return (
                      manual.attributes.en_catalog === 'Manuals' &&
                      (titleMatch || tagsMatch)
                    )
                  })
                  .map((manual) => {
                    const currMan = getManualData(manual)
                    return (
                      <div>
                        <DocAndLathundCard
                          tags={currMan?.tags}
                          role={currMan?.title}
                          content={currMan?.content}
                          onClick={() => openLink(currMan?.link)}
                          link={currMan?.link}
                          isFixedSize={false}
                        />
                      </div>
                    )
                  })}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </ColorCard>
    </Page>
  )
}
export const SearchDoc = ({
  onSearch,
  initialValue,
}: {
  onSearch: any
  initialValue?: string
}) => {
  const { t } = useTranslation()

  const [text, setText] = useState('')
  return (
    <input
      className={classNames(
        'w-full px-4 py-3 placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
      )}
      type={'text'}
      defaultValue={initialValue}
      value={text ? text : initialValue}
      placeholder={t('documents.search')}
      onChange={(e) => {
        setText(e.target.value)
        onSearch(e.target.value)
      }}
    />
  )
}
