import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useState } from 'react'
import { LoadingBar } from '../../../../../components/LoadingBar'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'
import Moment from 'react-moment'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import ModalRegular from '../../../../../components/ModalRegular'
import KidCardModal from './KidCardModal'
import { Kid } from '../../../../../Models/Kid'

interface Props {
  children?: React.ReactNode
  description?: string
  title?: string
  loading?: boolean
  className?: string
  icon?: IconDefinition
  iconClassName?: string
  onClick?: () => void
  image?: string
  PSN?: string
}

export default function KidCard(props: Props) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div
        className={classNames(
          'w-1/5 min-h-[60px] min-w-[300px] overflow-hidden rounded-2xl shadow-tyraShadow m-4 cursor-pointer',
        )}
        onClick={props.onClick}
      >
        {props?.loading && <LoadingBar />}
        <div className="flex space-x-4 rounded-b-2xl items-center font-bold font-sans bg-white hover:bg-pink-50 text-neutral-600 relative px-2">
          <div
            className={classNames(
              'flex py-3 font-medium justify-start w-full',
              props.className,
            )}
          >
            <>
              <div className="flex flex-row">
                <div className="border shadow items-center bg-gray-200 max-h-[60px] min-h-[60px] max-w-[60px] min-w-[60px] rounded-full overflow-hidden relative">
                  {props.image ? (
                    <img
                      src={props.image}
                      className={classNames(
                        'absolute inset-0 object-cover rounded-full',
                        props.iconClassName,
                      )}
                    />
                  ) : (
                    <FontAwesomeIcon
                      size="2x"
                      icon={props.icon || faUser}
                      className={classNames(
                        'absolute -bottom-1 left-[5px] h-12 w-12 text-gray-400',
                        props.iconClassName,
                      )}
                    />
                  )}
                </div>
              </div>
            </>
            <div className="flex flex-col ml-3 items-start justify-center">
              <div>{props.title}</div>
              <div className="font-normal text-sm text-gray-500">
                {props.PSN}
              </div>
            </div>
          </div>
          <div className="flex w-fit justify-end ">
            <FontAwesomeIcon icon={faChevronRight} className="text-gray-400" />
          </div>
        </div>
      </div>
      <KidCardModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        image={props.image}
      />
    </>
  )
}
