import React from 'react'
import { makeAutoObservable } from 'mobx'

class NavigationStore {
  title?: string
  constructor() {
    makeAutoObservable(this)
  }

  setTitle = (title: string) => {
    this.title = title
  }
}

export const navigationStore = (() => {
  return new NavigationStore()
})()
export const NavigationStoreContext: React.Context<NavigationStore> =
  React.createContext(navigationStore)
