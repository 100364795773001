import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { DocumentStoreContext } from '../../store/documentStore'
import { UserStoreContext } from '../../store/userStore'
import { DocumentItem } from './components/DocumentItem'
import { getDocsData } from './getDocsData'
import { downloadFile } from '../../utils/downloadFile'
import { CreateFolderButton } from './components/CreateFolderButton'
import { Sort, sortDocuments, sortName, SortOptions } from './Documents'
import { DepartmentStoreContext } from '../../store/departmentStore'
import { ReadAllModal } from './components/ReadAllModal'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import moment from 'moment'
import { UserRole } from '../../Models/UserRole'
import ColorCard from '../../components/ColorCard'
import { Loading } from '../../components/Loading'
import { DOCUMENT_VISIBILITY_TYPE, USER_ROLE_TYPE } from '../../Enums/TyraEnums'

export const DocumentsFromType: React.FC = observer(() => {
  const { t } = useTranslation()
  const {
    getDocsFromFolderType,
    docs,
    docsLoading,
    setDocs,
    setDocumentViewed,
    search,
    setSearch,
    sort,
    setSort,
  } = useContext(DocumentStoreContext)
  const { savedUserRoleId, currentUserRole, fetchUserRole, readOnly } =
    useContext(UserStoreContext)
  const { setSelectedDepartments, selectedDepartments } = useContext(
    DepartmentStoreContext,
  )
  const { setTitle } = useContext(NavigationStoreContext)
  let { schoolId } = useParams()
  const [searchParams] = useSearchParams()
  const type = searchParams.get('type')
  const navigate = useNavigate()
  /*const [sort, setSort] = useState(SortOptions.UPDATED)*/
  /*const [search, setSearch] = useState('')*/
  const [showAdd, setShowAdd] = useState({ addFile: false, addFolder: false })

  const data = getDocsData(type ?? '')
  const documentsText: string = t('documents.kidDocumentTitle')

  useEffect(() => {
    const kidName = currentUserRole?.kid?.firstName
    if (type === 'kid') {
      setTitle(documentsText.replace('XXKIDSNAMEXX', kidName ?? ''))
    } else {
      setTitle(data?.title ?? '')
    }
  }, [data, currentUserRole])

  const fetch = () => {
    getDocsFromFolderType(savedUserRoleId ?? '', type ?? '')
  }

  useEffect(() => {
    fetch()
    return () => {
      // Reset docs when unmount
      setDocs([])
    }
  }, [])
  useEffect(() => {
    if (savedUserRoleId) {
      fetchUserRole(savedUserRoleId).finally(() => {})
    }
  }, [savedUserRoleId])
  useEffect(() => {
    if (currentUserRole) {
      //fetchUserRole(savedUserRoleId).finally(() => {
      const data = showAddPermissions(currentUserRole, type ?? '')
      if (data) {
        setShowAdd(data)
      }
      //})
    }
  }, [currentUserRole, savedUserRoleId])
  //const showAdd = showAddPermissions(currentUserRole, type ?? '')
  /*
    ((type === 'my' || type === 'only_staff') &&
      currentUserRole?.attributes?.role_type > 350) ||
    type === 'kid' */

  let documents = docs
  if (selectedDepartments?.length && type === 'students') {
    documents = docs.filter(
      (d: any) =>
        d?.depId ===
        selectedDepartments.find((selDep) => selDep.id === d.depId)?.id,
    )
  }

  documents = sortDocuments({
    documents: documents,
    option: sort ?? SortOptions.UPDATED,
    search: search,
  })

  const anyFoldersUnread = (folderDocs: any) => {
    let unreadExists = false
    if (folderDocs) {
      // eslint-disable-next-line array-callback-return
      folderDocs.map((folderDoc: any) => {
        if (folderDoc.hasUnReadCount > 0 || folderDoc.unread) {
          unreadExists = true
        }
      })
    }
    return unreadExists
  }
  const anyFilesUnread = (fileDocs: any) => {
    let unreadExists = false
    if (fileDocs) {
      // eslint-disable-next-line array-callback-return
      fileDocs.map((fileDoc: any) => {
        if (fileDoc.hasUnReadCount > 0 || fileDoc.unread) {
          unreadExists = true
        }
      })
    }
    return unreadExists
  }
  const showFoldersMarkAsRead = anyFoldersUnread(docs)
  const showFilesMarkAsRead = anyFilesUnread(docs)

  const requireSign = (item: any) => {
    if (currentUserRole) {
      if (
        currentUserRole?.role_type > 350 &&
        (item.visibility_type === DOCUMENT_VISIBILITY_TYPE.STAFF ||
          item.visibility_type === DOCUMENT_VISIBILITY_TYPE.MINE) &&
        item?.requireSign
      ) {
        return true
      } else if (
        currentUserRole?.role_type <= 350 &&
        (item.visibility_type === DOCUMENT_VISIBILITY_TYPE.SCHOOL ||
          item.visibility_type === DOCUMENT_VISIBILITY_TYPE.CHILD ||
          item.visibility_type === DOCUMENT_VISIBILITY_TYPE.MINE) &&
        item?.requireSign
      ) {
        return true
      }
    }
    return false
  }
  const favoriteItemColor = (visibility_type: number) => {
    if (visibility_type === 1) {
      return 'text-notesMain'
    } else if (visibility_type === 2) {
      return 'text-classlistMain'
    } else if (visibility_type === 3) {
      return 'text-documentsMain'
    } else if (visibility_type === 4) {
      return 'text-groupsMain'
    }
    return ''
  }

  const renderItem = (item: any, index: number) => {
    let title = item?.title
    if (item.id === 'wholeschool') {
      title = t('documents.wholeSchool')
    } else if (item.id === 'missingdepartment') {
      title = t('documents.missingDepartment')
    }
    const ext = item?.url?.split('.').pop()

    if (item.id === 'missingdepartment' && item.docCount < 1) {
      return null
    }
    return (
      <DocumentItem
        id={item?.id}
        hideSettings={type === 'school' || type === 'students'}
        isFile={item?.isFile}
        key={index}
        title={title}
        owner={item?.owner}
        //requireSign={item?.requireSign}
        requireSign={
          type === 'favorite' && item?.isFile
            ? requireSign(item)
            : item?.requireSign
        }
        favorite={item?.favorite}
        sharedWith={type === 'my' && item?.userSharedWith?.length > 1}
        signed={item?.signed}
        //icon={data?.icon}
        className={
          item?.isFile && type === 'favorite'
            ? favoriteItemColor(item.visibility_type)
            : data?.color ?? ''
        }
        count={item?.docCount ?? 0}
        countSign={item?.unSignedDocsCount ?? 0}
        unReadCount={item?.hasUnReadCount}
        createdAt={moment(item.createdAt).format('DD/MM/YYYY')}
        hasUnRead={
          item?.isFile ? item?.unread : (item?.hasUnReadCount ?? 0) > 0
        }
        mimeType={ext}
        type={type ?? ''}
        onUpdate={() => {
          fetch()
        }}
        onClick={() => {
          if (item?.isFile) {
            setDocumentViewed({
              documentId: item.id,
              userRoleId: savedUserRoleId ?? '',
            }).then(() => {
              fetch()
            })
            downloadFile(item?.url)
          }
          if (item?.isFolder || item?.isStudents || item?.isDep) {
            navigate(
              '/documents-id/' +
                schoolId +
                '?type=' +
                type +
                '&id=' +
                item.id +
                '&title=' +
                title,
            )
          }
        }}
      />
    )
  }
  return (
    <Page
      naked
      loading={docsLoading}
      clearFilter={() => {
        setSelectedDepartments([])
        if (type === 'students') {
          getDocsFromFolderType(savedUserRoleId ?? '', type ?? '')
        }
      }}
      filterCount={
        (search ? 1 : 0) +
        (selectedDepartments?.length ? selectedDepartments.length : 0)
      }
      topBar={
        <>
          <Sort
            onSort={(s: string) => {
              setSort(s)
            }}
            sort={' ' + sortName(sort ?? SortOptions.UPDATED, t) ?? ''}
            initialValue={sort ?? SortOptions.UPDATED}
          />
          {type !== 'favorite' &&
            type !== 'school' &&
            type !== 'students' &&
            type !== 'shared' &&
            showAdd?.addFile &&
            !readOnly?.documents && (
              <CreateFolderButton
                kidId={currentUserRole?.attributes?.kid?.id}
                type={type ?? ''}
                hideFolder={!showAdd.addFolder}
                onFolderAdded={() => {
                  fetch()
                }}
                onFileAdded={() => {
                  fetch()
                }}
              />
            )}
        </>
      }
      searchFilter={
        <SearchDocument
          onSearch={(s: string) => {
            setSearch(s)
          }}
          initialValue={search ?? ''}
          placeholder={t('documents.searchDocuments')}
        />
      }
    >
      {docsLoading && <Loading />}
      {type !== 'shared' && type !== 'favorite' && (
        <ColorCard title={t('documents.folders')} bgColor={data?.bgColor ?? ''}>
          <div className="mb-2"></div>
          {showFoldersMarkAsRead && (
            <div className="w-max-[20px] flex flex-fit justify-end mb-4 mx-4">
              <ReadAllModal
                type={type ?? ''}
                onDone={() => {
                  fetch()
                }}
              />
            </div>
          )}
          {!docsLoading && !documents?.folders?.length && (
            <div
              className={
                'w-full flex flex-col justify-center text-lg sm:text-2xl items-center sm:mt-14 space-y-12 text-gray-300'
              }
            >
              <p className="text-gray-300 my-12">
                {t('ourKids.noContactInfo')}
              </p>
            </div>
          )}
          <div
            className={
              ' grid gap-3 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 max-w-7xl px-3 font-medium text-gray-800'
            }
          >
            {documents?.folders?.map((item: any, index: number) => {
              return renderItem(item, index)
            })}
          </div>
          <div className={'pb-7'} />
        </ColorCard>
      )}
      {type !== 'school' && type !== 'students' && (
        <ColorCard title={t('documents.files')} bgColor="bg-gray-400">
          <div className="mb-2"></div>
          {showFilesMarkAsRead && (
            <div className="w-max-[20px] flex flex-fit justify-end mb-4 mx-4">
              <ReadAllModal
                type={type ?? ''}
                onDone={() => {
                  fetch()
                }}
              />
            </div>
          )}
          {!docsLoading && !documents?.documents?.length && (
            <div
              className={
                'w-full flex flex-col justify-center text-lg sm:text-2xl items-center sm:mt-14 space-y-12 text-gray-300'
              }
            >
              <p className="text-gray-300 my-12">
                {t('ourKids.noContactInfo')}
              </p>
            </div>
          )}
          <div
            className={
              ' grid gap-3 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 max-w-7xl px-3 font-medium text-gray-800'
            }
          >
            {documents?.documents?.map((item: any, index: number) => {
              return renderItem(item, index)
            })}
          </div>
          <div className={'pb-7'} />
        </ColorCard>
      )}

      {/*<div
        className={
          'grid sm:grid-cols-2 items-center sm:mx-3 mx-12 my-12 object-center'
        }
      >
        <ReadAllModal
          type={type ?? ''}
          onDone={() => {
            fetch()
          }}
        />
      </div>
      {!docsLoading &&
        !documents?.documents?.length &&
        !documents?.folders?.length && (
          <div
            className={
              'w-full flex flex-col justify-center items-center sm:mt-14 space-y-12 text-gray-200'
            }
          >
            <FontAwesomeIcon icon={faFolder} size={'6x'} />
            <p>{t('documents.empty')}</p>
          </div>
        )}

      {documents?.folders?.length > 0 && (
        <p
          className={
            'w-full text-center px-3 text-2xl py-3 md:py-6 font-medium '
          }
        >
          {t('documents.folders')}
        </p>
      )}

      <div
        className={
          ' grid gap-3 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 max-w-7xl px-3 font-medium text-gray-800'
        }
      >
        {documents?.folders?.map((item: any, index: number) => {
          return renderItem(item, index)
        })}
      </div>

      {documents?.documents?.length > 0 && (
        <p className={'w-full text-center px-3 text-2xl py-3 md:py-6 '}>
          {t('documents.files')}
        </p>
      )}
      <div
        className={
          ' grid gap-3 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 max-w-7xl px-3 font-medium text-gray-800'
        }
      >
        {documents?.documents?.map((item: any, index: number) => {
          return renderItem(item, index)
        })}
      </div>

      <div className={'pb-32'} />*/}
    </Page>
  )
})

export const showAddPermissions = (
  currentUserRole?: UserRole,
  type?: string,
) => {
  const docStaffCreateFolder =
    currentUserRole?.attributes?.school_pointer?.attributes?.school_setting
      ?.attributes?.doc_staff_create_folder
  // VH
  if (currentUserRole && type) {
    if (
      currentUserRole?.attributes?.role_type === USER_ROLE_TYPE.PARENT &&
      !currentUserRole?.attributes?.is_upload_document_parent
    ) {
      return {
        addFile: type === 'kid',
        addFolder: false,
      }
    }

    // VH UPLOAD
    else if (
      currentUserRole?.attributes?.role_type === USER_ROLE_TYPE.PARENT &&
      currentUserRole?.attributes?.is_upload_document_parent
    ) {
      return {
        addFile: type === 'kid' || type === 'school' || type === 'my',
        addFolder: !docStaffCreateFolder
          ? type === 'my'
          : type === 'kid'
          ? false
          : true,
      }
    } else if (
      currentUserRole?.attributes?.role_type === USER_ROLE_TYPE.TEACHER
    ) {
      return {
        addFile: true,
        addFolder: !docStaffCreateFolder ? type === 'my' : true,
      }
    } else if (currentUserRole?.attributes?.role_type >= USER_ROLE_TYPE.ADMIN) {
      return {
        addFile: true,
        addFolder: true,
      }
    } else {
      return {
        addFile: false,
        addFolder: false,
      }
    }
  }
}

/*  return {
        addFile:
          (!id && type === 'school') || (!id && type === 'students')
            ? false
            : type !== 'favorite' && type !== 'shared',
        addFolder:
          (!id && type === 'school') || (!id && type === 'students')
            ? false
            : type !== 'favorite' && type !== 'shared',
      }*/

export const SearchDocument = ({
  onSearch,
  initialValue,
  placeholder,
}: {
  onSearch: any
  initialValue?: string
  className?: string
  placeholder?: string
}) => {
  const [text, setText] = useState(initialValue ?? '')
  return (
    <div
      className={
        ' flex-wrap w-full max-w-[100px] sm:max-w-[510px] justify-center'
      }
    >
      <input
        className={classNames(
          'w-full px-1 py-0.5 sm:px-4 sm:py-3 placeholder-gray-400 border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain text-xs sm:text-sm',
        )}
        type={'text'}
        value={text}
        placeholder={placeholder}
        onChange={(e) => {
          setText(e.target.value)
          onSearch(e.target.value)
        }}
      />
    </div>
  )
}
