import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { Button } from '../../../components/Button'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { ButtonDropdown } from '../../../components/Dropdown'
import { UserStoreContext } from '../../../store/userStore'
import { useTranslation } from 'react-i18next'
import Singleselect from '../../../components/Singleselect'

export const DepartmentChooser: React.FC<{}> = observer(({}) => {
  const {
    departments,
    fetchDepartmentsFromUserRole,
    selectedDepartment,
    setSelectedDepartment,
  } = useContext(DepartmentStoreContext)
  const { t } = useTranslation()
  const { currentUserRole } = useContext(UserStoreContext)
  useEffect(() => {
    if (currentUserRole) {
      fetchDepartmentsFromUserRole(currentUserRole)
    }
  }, [currentUserRole?.id])
  return (
    <div className={' flex justify-center'}>
      <Singleselect
        items={departments ?? []}
        onChange={(e) => {
          setSelectedDepartment(e)
        }}
        initialSelectedItem={selectedDepartment}
        placeholder={t('documents.department')}
        itemVariableKey="klassName"
      />
      {/*<Button
        variant="transparent"
        icon={faSort}
        label={
          t('documents.department') +
          ': ' +
          (selectedDepartment
            ? selectedDepartment?.attributes?.klassName
            : t('documents.all'))
        }
        dropdownIcon={false}
        reverseIcon
        dropdown={
          <ButtonDropdown.Menu>
            <ButtonDropdown.Button
              key={'all'}
              label={t('documents.all')}
              onClick={() => {
                // @ts-ignore
                setSelectedDepartment(null)
              }}
            />
            {departments?.map((dep) => {
              return (
                <ButtonDropdown.Button
                  key={dep?.id}
                  label={dep?.attributes?.klassName}
                  onClick={() => {
                    setSelectedDepartment(dep)
                  }}
                />
              )
            })}
          </ButtonDropdown.Menu>
        }
      />*/}
    </div>
  )
})
