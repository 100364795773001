import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { Button } from '../../../components/Button'
import { Event as EventModel } from '../../../Models/Event'
import { EventsDateCircle } from './EventsDateCircle'
import {
  faPen,
  faFilePdf,
  faTrash,
  faUserGroup,
  faEye,
  faPenNib,
  faPaperclip,
  faInfo,
  faCircleInfo,
  faBell,
} from '@fortawesome/free-solid-svg-icons'
import { Department } from '../../../Models/Department'
import { FullscreenModal } from '../../portfolio/components/FullScreenModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router'
import { eventStore, EventStoreContext } from '../../../store/eventStore'
import {
  NotificationContext,
  NotificationVariant,
} from '../../../App/notification/notificationManger'
import { useTranslation } from 'react-i18next'
import { getDateForTranslatation } from '../Events'
import { t } from 'i18next'
import classNames from 'classnames'
import ModalRegular from '../../../components/ModalRegular'
import { User } from '../../../Models/User'
import { DocumentStoreContext } from '../../../store/documentStore'
import { UserStoreContext } from '../../../store/userStore'
import { getImageFromRole, getImageFromUser } from '../../../utils/parseUtils'
import Swal from 'sweetalert2'
import Parse from 'parse'
import DOMPurify from 'dompurify'

interface Props {
  event: EventModel
  userType: number | undefined
  departments: Department[] | undefined
  closeModalAndRefetch: any
  fetchEvents: () => void
}
export const getEventTypeTranslatation = (category: string) => {
  switch (category) {
    case 'Föräldramöte':
      return t('events.parentMeeting')
    case 'Personal':
      return t('documents.staff')
    case 'Nyheter':
      return t('events.news')
    case 'Föräldraförening':
      return t('events.parentAssociation')
    case 'Studiedagar':
      return t('events.studyDays')
    case 'Övrigt':
      return t('ourKids.other')
  }
}
export const getDayForTranslatation = (days: number) => {
  switch (days) {
    case 1:
      return t('days.monday')
    case 2:
      return t('days.tuesday')
    case 3:
      return t('days.wednesday')
    case 4:
      return t('days.thursday')
    case 5:
      return t('days.friday')
    case 6:
      return t('days.saturday')
    case 0:
      return t('days.sunday')
    default:
      return 'unknown'
  }
}
export const getDaysFullForTranslatation = (days: number) => {
  switch (days) {
    case 1:
      return t('daysFull.monday')
    case 2:
      return t('daysFull.tuesday')
    case 3:
      return t('daysFull.wednesday')
    case 4:
      return t('daysFull.thursday')
    case 5:
      return t('daysFull.friday')
    case 6:
      return t('daysFull.saturday')
    case 0:
      return t('daysFull.sunday')
    default:
      return 'unknown'
  }
}
export const EventsInfo = observer((props: Props) => {
  const [tab, setTab] = useState('read')
  const [showFullScreenModal, setShowFullScreenModal] = useState(false)
  const [showEventOverview, setShowEventOverview] = useState(false)
  const { savedUserRoleId, readOnly } = useContext(UserStoreContext)
  const {
    deleteSingleEvent,
    setEventAsRead,
    getEventOverview,
    events,
    eventOverview,
  } = useContext(EventStoreContext)
  const { notify } = useContext(NotificationContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [sort, setSort] = useState('')
  const [reminderSent, setReminderSent] = useState(false)
  const {
    setSelectedUserUserRoles,
    getUserRolesFromUser,
    selectedUserUserRoles,
  } = useContext(DocumentStoreContext)

  let textClass = 'xxx'
  if (props.event.type == 'Föräldramöte') {
    textClass = 'text-foraldraMote'
  } else if (props.event.type == 'Övrigt') {
    textClass = 'text-ovrigt'
  } else if (props.event.type == 'Föräldraförening') {
    textClass = 'text-foraldraForening'
  } else if (props.event.type == 'Nyheter') {
    textClass = 'text-nyheter'
  } else if (props.event.type == 'Studiedagar') {
    textClass = 'text-studiodagar'
  } else if (props.event.type == 'Personal') {
    textClass = 'text-personal'
  }

  const deleteEvent = () => {
    Swal.fire({
      text: t('documents.areYouSureRemoveFile'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7eb5f4',
      cancelButtonColor: '#d33',
      confirmButtonText: t('general.yes'),
      cancelButtonText: t('general.cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSingleEvent(props.event).then(() => {
          /*notify({
            variant: NotificationVariant.OK,
            //title: t('events.eventSuccessfullyDeletedTitle'),
            //message: t('events.eventSuccessfullyDeletedMessage'),
            title: t('events.deleted'),
            message: t('events.willNotShowAnymore'),
          })*/
          Swal.fire({
            text: t('events.willNotShowAnymore'),
            icon: 'success',
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            confirmButtonText: t('general.ok'),
          })
          props.closeModalAndRefetch()
        })
      }
    })
  }

  useEffect(() => {
    if (!props.event.read) {
      setEventAsRead(props.event)
      const event = events?.find((ev) => ev.id == props.event.id)
      if (event) {
        event.read = true
      }
    }
  })

  const getActionStateText = (user?: User) => {
    if (user) {
      if (sort === 'unread') {
        return 'Oläst'
      }

      const actionState = eventOverview?.actionStates?.find(
        (as) => as?.attributes?.user_owner?.id === user?.id,
      )
      if (actionState) {
        if (sort === 'read') {
          return moment(actionState?.attributes?.viewed).format('D MMM YYYY')
        } else if (sort === 'signed') {
          return moment(actionState?.attributes?.signed).format('D MMM YYYY')
        }
      }
    }

    return ''
  }

  return (
    <>
      {/*content*/}
      <div className="">
        {/*header*/}

        <div className="flex flex-end p-5 items-center align-middle justify-center">
          <EventsDateCircle
            event={props.event}
            dateStamp={true}
            day={getDayForTranslatation(moment(props.event.date).day())}
          />
          <div className={'m-5 font-medium ' + textClass}>
            {getEventTypeTranslatation(props.event.type)}
            <br />
            <p className="text-gray-500">
              {props.event.class_pointer
                ?.map((dep: Department) => {
                  return dep.klassName
                })
                .join(', ')}
            </p>
          </div>
          <div className="flex-1 m-5 items-center align-middle justify-center">
            {props.event.picture ? (
              <img
                src={props.event.picture._url}
                className="object-contain h-32 w-32 float-right cursor-pointer"
                onClick={() => setShowFullScreenModal(true)}
              />
            ) : null}
            {props.event.pdf ? (
              <a
                className="float-right cursor-pointer mt-7 mr-2"
                href={props.event.pdf._url}
                target="_blank"
              >
                <FontAwesomeIcon
                  className="w-16 h-16 text-base text-eventsMain"
                  icon={faFilePdf}
                />
              </a>
            ) : null}
          </div>
        </div>
        <p className="flex items-start pl-5 text-gray pt-2 text-eventsMain">
          {moment(props.event.get('date')).format('D')}{' '}
          {getDateForTranslatation(
            moment(props.event.get('date')).format('MM'),
          )}{' '}
          {moment(props.event.get('date')).format('YYYY')}
        </p>
        <div className="flex items-start justify-between pl-5">
          <h3 className="text-3xl font-semibold">{props.event.topic}</h3>
        </div>
        {/*body*/}
        <div className="relative pl-5 pt-2 flex-auto">
          <div className="text-lg leading-relaxed">
            <div
              className="whitespace-pre-wrap"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.event.get('content')),
              }}
            />
          </div>
        </div>
        {/*footer*/}
        <div className="flex-end items-center justify-end mt-5 p-5 border-t border-solid border-slate-200 rounded-b">
          <div className="flex items-center justify-start">
            <img
              className={'w-[60px] h-[60px] object-cover rounded-full'}
              src={getImageFromRole(props.event.get('author'))}
              alt=""
            />
            <div className="grid grid-cols-1 ml-3">
              <div>
                <b>
                  {props.event.get('author') ? (
                    props.event.get('author').get('firstName') ? (
                      props.event.get('author').get('firstName') +
                      ' ' +
                      props.event.get('author').get('lastName')
                    ) : (
                      <> {t('general.unknown')}</>
                    )
                  ) : (
                    ''
                  )}
                </b>
              </div>
              <div>
                <p>
                  {moment(
                    props.event.updatedAt
                      ? props.event.updatedAt
                      : props.event.createdAt,
                  ).format('D MMM YYYY')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'flex items-end mt-5 p-5 rounded-b clear-both',
            props.userType && props.userType > 350
              ? 'justify-between'
              : 'justify-center',
          )}
        >
          {props.userType && props.userType > 350 ? (
            <>
              {!readOnly?.events && (
                <Button
                  icon={faTrash}
                  size="lg"
                  variant="danger"
                  onClick={() => deleteEvent()}
                />
              )}

              <Button
                size="md"
                variant="primary"
                label={t('documents.overview')}
                onClick={() => {
                  getEventOverview({ eventId: props.event.id }).finally(() => {
                    setShowEventOverview(true)
                  })
                }}
              />

              {!readOnly?.events && (
                <Button
                  icon={faPen}
                  size="lg"
                  variant="primary"
                  onClick={() =>
                    navigate(
                      '/events/edit/' +
                        props.event.school_pointer.id +
                        '/' +
                        props.event.id,
                    )
                  }
                />
              )}
            </>
          ) : null}
        </div>
      </div>
      <ModalRegular
        bgColor="p-4 bg-gray-300"
        title={t('documents.overview')}
        isOpen={showEventOverview}
        className={'max-w-xl p-0'}
        onClose={() => {
          setShowEventOverview(false)
        }}
        closeColor="white"
      >
        <div className={'flex relative px-2 pt-2'}>
          <div>
            <div
              className="absolute left-4 top-[45px] z-20 rounded-full bg-eventsMain h-1 transition-all duration-500 ease-in-out"
              style={{
                width: 'calc(50% - 16px)',
                left: tab === 'read' ? 'calc(1.5% + 4px)' : 'calc(50% + 4px)',
              }}
            ></div>
          </div>
          <div
            className={classNames(
              'flex-1 flex relative justify-center  z-10 items-center transition-all duration-300 ease-in-out rounded-tl-2xl border-t border-l font-medium hover:bg-blue-50  h-[40px] borde-b cursor-pointer',
              tab === 'read' && '',
            )}
            onClick={() => {
              setTab('read')
            }}
          >
            <p
              className={classNames(
                tab === 'read' && 'text-eventsMain font-medium',
              )}
            >
              {t('documents.all')} (
              {(eventOverview?.read.length ?? 0) +
                (eventOverview?.unRead.length ?? 0)}
              )
            </p>
          </div>
          <div
            className={classNames(
              'flex-1 flex justify-center items-center transition duration-200 ease-in rounded-tr-2xl border-t border-r border-l font-medium hover:bg-blue-50 h-[40px] cursor-pointer',
              tab === 'unread' && ' border-b-eventsMain',
            )}
            onClick={() => {
              setTab('unread')
            }}
          >
            <p
              className={classNames(
                tab === 'unread' && 'text-eventsMain font-medium ',
              )}
            >
              <div>
                {t('documents.unread')} ({eventOverview?.unRead?.length ?? 0})
              </div>
            </p>
          </div>
        </div>
        <div
          className={
            'grid grid-cols-2 min-h-[200px]  gap-3 mb-2 ml-2 mr-2 md:gap-x-3 p-3 border shadow-tyraShadow rounded-b-2xl'
          }
        >
          {tab === 'read' ? (
            eventOverview?.read.length || eventOverview?.unRead.length ? (
              <>
                {eventOverview?.read?.map((user: User, index: number) => {
                  return (
                    <Item
                      read={true}
                      onClick={() => {
                        /* setSelectedUserUserRoles([])
                        getUserRolesFromUser({
                          userRoleId: savedUserRoleId ?? '',
                          userId: user?.id,
                        })
                        setSort('read')*/
                      }}
                      key={index}
                      user={user}
                    />
                  )
                })}
                {eventOverview?.unRead?.map((user: User, index: number) => {
                  return (
                    <Item
                      onClick={() => {
                        /*setSelectedUserUserRoles([])
                        getUserRolesFromUser({
                          userRoleId: savedUserRoleId ?? '',
                          userId: user?.id,
                        })
                        setSort('unread')*/
                      }}
                      key={index}
                      user={user}
                    />
                  )
                })}
              </>
            ) : (
              <div className="absolute top-[140px] ">
                <Empty />
              </div>
            )
          ) : null}
          {tab === 'unread' ? (
            eventOverview?.unRead?.length ? (
              eventOverview?.unRead?.map((user: User, index: number) => {
                return (
                  <Item
                    read={false}
                    onClick={() => {
                      /*setSelectedUserUserRoles([])
                      getUserRolesFromUser({
                        userRoleId: savedUserRoleId ?? '',
                        userId: user?.id,
                      })
                      setSort('unread')*/
                    }}
                    key={index}
                    user={user}
                  />
                )
              })
            ) : (
              <div className="absolute top-[140px] ">
                <Empty />
              </div>
            )
          ) : null}

          <ModalRegular
            isOpen={sort?.length > 0}
            className={'max-w-2xl p-0'}
            onClose={() => {
              setSort('')
            }}
          >
            <p className="font-bold text-gray-800 p-4 bg-green-50">
              {
                selectedUserUserRoles?.[0]?.attributes?.user?.attributes
                  ?.firstName
              }{' '}
              {
                selectedUserUserRoles?.[0]?.attributes?.user?.attributes
                  ?.lastName
              }
            </p>
            <div className={'flex flex-col gap-y-3 p-4 divide-y'}>
              <div className={'pb-3  border-b flex  justify-end'}>
                <p
                  className={classNames(
                    'text-sm ',
                    sort === 'unread' ? 'text-red-500' : 'text-eventsMain',
                  )}
                >
                  {getActionStateText(
                    selectedUserUserRoles?.[0]?.attributes?.user,
                  )}

                  <span>
                    {sort === 'read' && (
                      <FontAwesomeIcon
                        className="ml-2 text-eventsMain"
                        icon={faEye}
                      />
                    )}
                    {sort === 'signed' && (
                      <FontAwesomeIcon
                        className="ml-2 text-eventsMain"
                        icon={faPenNib}
                      />
                    )}
                  </span>
                </p>
              </div>
              {selectedUserUserRoles?.map((role) => {
                if (role?.attributes?.role_type > 350) {
                  return (
                    <div className={'flex space-x-3 items-center  '}>
                      <img
                        className={'w-[45px] h-[45px] rounded-full'}
                        src={
                          role?.attributes?.staff_primary_dep?.attributes
                            ?.contactPic_thumb?._url
                        }
                        alt=""
                      />
                      <div className={'text-sm'}>
                        <p className={'text-eventsMain'}>
                          {role?.attributes?.relation}
                        </p>
                        <p>{role?.attributes?.kid?.attributes?.kidName}</p>
                        <p className={'text-xs text-gray-400'}>
                          {
                            role?.attributes?.staff_primary_dep?.attributes
                              ?.klassName
                          }
                        </p>
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div className={'flex space-x-3 items-center '}>
                      <img
                        className={'w-[45px] h-[45px] rounded-full'}
                        src={role?.attributes?.kid?.attributes?.kidImage?._url}
                        alt=""
                      />
                      <div className={'text-sm'}>
                        <p className={'text-eventsMain'}>
                          {role?.attributes?.relation}
                        </p>
                        <p>{role?.attributes?.kid?.attributes?.kidName}</p>
                        <p className={'text-xs text-gray-400'}>
                          {role?.attributes?.kid?.attributes?.department}
                        </p>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </ModalRegular>
        </div>
        <div className="flex justify-end items-center">
          {!reminderSent && (
            <>
              {tab === 'unread' &&
                eventOverview &&
                eventOverview.unRead.length > 0 && (
                  <Button
                    size="md"
                    className=" m-2"
                    icon={faBell}
                    variant="roundBlue"
                    onClick={async () => {
                      await Parse.Cloud.run('sendEventReminder', {
                        eventId: props.event.id,
                        unreadUserIds: eventOverview?.unRead?.map(
                          (user: User, index: number) => {
                            return user.id
                          },
                        ),
                      })
                        .then(
                          () => {
                            /*notify({
                        variant: NotificationVariant.OK,
                        title: '',
                        message: t('general.reminderSent'),
                      })*/
                            Swal.fire({
                              text: t('general.reminderSent'),
                              icon: 'success',
                              showCancelButton: false,
                              focusConfirm: false,
                              confirmButtonColor: '#7eb5f4',
                              cancelButtonColor: '#d33',
                              confirmButtonText: t('general.ok'),
                            })
                            setReminderSent(true)
                          },
                          (error) => {},
                        )
                        .finally(() => {})
                      /*Swal.fire({
                //title: 'Are you sure?',
                text: t('events.eventAlarmNotImp'),
                icon: 'warning',
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonColor: '#7eb5f4',
                cancelButtonColor: '#d33',
                confirmButtonText: t('general.ok'),
              })*/
                    }}
                    title={t('absence.sendNotice')}
                    label={t('absence.sendNotice')}
                  ></Button>
                )}
            </>
          )}
        </div>
      </ModalRegular>

      {props.event.picture ? (
        <FullscreenModal
          src={props.event.picture._url}
          showModal={showFullScreenModal}
          hideModal={() => {
            setShowFullScreenModal(false)
          }}
        />
      ) : null}
    </>
  )
})

const Empty = () => {
  const { t } = useTranslation()

  return (
    <div
      className={
        'flex flex-1 w-full p-10 justify-center space-y-3 text-gray-300 text-3xl'
      }
    >
      <p>{t('documents.empty')}</p>
    </div>
  )
}

const Item = ({
  user,
  onClick,
  read,
}: {
  user: User
  onClick: any
  read?: boolean
}) => {
  return (
    <div
      className={classNames(
        'flex max-h-[70px] space-x-3 items-center px-3 py-3 shadow rounded-2xl cursor-pointer ',
        read ? 'bg-green-200' : '',
      )}
      onClick={onClick}
    >
      <img
        className={'w-[30px] h-[30px] rounded-full'}
        src={getImageFromUser(user)}
        alt=""
      />
      <div>
        {user?.attributes?.firstName} {user?.attributes?.lastName}
      </div>
    </div>
  )
}
