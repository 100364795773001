import React from 'react'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

import { getImageFromUserBall } from '../utils/parseUtils'
import { User } from '../Models/User'

export const getDateForTranslatation = (months: string) => {
  switch (months) {
    case '01':
      return t('monthsShort.january')
    case '02':
      return t('monthsShort.february')
    case '03':
      return t('monthsShort.march')
    case '04':
      return t('monthsShort.april')
    case '05':
      return t('monthsShort.may')
    case '06':
      return t('monthsShort.june')
    case '07':
      return t('monthsShort.july')
    case '08':
      return t('monthsShort.august')
    case '09':
      return t('monthsShort.september')
    case '10':
      return t('monthsShort.october')
    case '11':
      return t('monthsShort.november')
    case '12':
      return t('monthsShort.december')
  }
}

interface ImageWithLettersProps {
  className?: string
  user: User
  opacity?: boolean
}

const ImageWithLetters: React.FC<ImageWithLettersProps> = ({
  className,
  user,
  opacity,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={`${className}`}>
        {getImageFromUserBall(user) ? (
          <img
            src={getImageFromUserBall(user)}
            className={`${className} ${
              opacity
                ? 'opacity-20 h-7 w-7 border rounded-full overflow-hidden'
                : 'opacity-100 h-7 w-7 border rounded-full overflow-hidden'
            }`}
          />
        ) : (
          <div
            className={`${className} ${
              opacity
                ? 'opacity-20 h-7 w-7 text-center bg-gray-200 border rounded-full overflow-hidden flex items-center justify-center'
                : 'opacity-100 h-7 w-7 text-center bg-gray-200 border rounded-full overflow-hidden flex items-center justify-center'
            }`}
          >
            <p className={'text-gray-700 font-bold text-xs'}>
              {user?.get('firstName')?.charAt(0)?.toUpperCase()}
              {user?.get('lastName')?.charAt(0)?.toUpperCase()}
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default ImageWithLetters
