import React, { useContext, useEffect, useState } from 'react'
import ModalRegular from '../../../components/ModalRegular'
import { Button } from '../../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import Swal from 'sweetalert2'
import { t } from 'i18next'
import { TagStoreContext } from '../../../store/tagStore'
import { useParams } from 'react-router-dom'
import Parse from 'parse'
import { values } from 'mobx'
import { number } from 'yup'

interface TagsModalProps {
  onClose: () => void
  isOpen: boolean
}

const TagsModal: React.FC<TagsModalProps> = ({ onClose, isOpen }) => {
  const [showCreateTag, setShowCreateTag] = useState(false)
  const [selectedColor, setSelectedColor] = useState('')
  const [tagName, setTagName] = useState('')
  const [showEditTag, setShowEditTag] = useState(false)
  const [selectedTag, setSelectedTag] = useState<any>(null)
  const [fakeState, setFakeState] = useState(false)
  const {
    fetchDefultTags,
    fetchCustomTags,
    defaultTags,
    customTags,
    createCustomTags,
    editCustomTag,
    deleteTag,
  } = useContext(TagStoreContext)
  const createCustomTag = async () => {
    const newTag = {
      createdAt: Date(),
      updatedAt: Date(),
      name: tagName,
      color: selectedColor,
      order: number,
    }

    setTagName('')
    setSelectedColor('')
    setShowCreateTag(false)

    // Check if schoolId is defined before trying to create a tag
    if (schoolId) {
      try {
        await createCustomTags(schoolId, {
          name: newTag.name,
          bg_color: newTag.color,
          createdAt: new Date(),
          updatedAt: new Date(),
          is_base: false,
          order: newTag.order,
        })
        Swal.fire({
          text: t('admin.tagCreated'),
          icon: 'success',
          confirmButtonColor: '#7eb5f4',
          confirmButtonText: 'OK',
        }).then(() => {
          fetchDefultTags(schoolId)
          fetchCustomTags(schoolId)
        })
      } catch (error) {
        Swal.fire({
          text: t('admin.errorCreatingTag'),
          icon: 'error',
          confirmButtonColor: '#7eb5f4',
          confirmButtonText: 'OK',
        })
      }
    } else {
      Swal.fire({
        text: t('admin.errorMissingSchoolId'),
        icon: 'error',
        confirmButtonColor: '#7eb5f4',
        confirmButtonText: 'OK',
      })
    }
  }

  const colors = [
    // blå
    '#728eae', // mörkare nyans
    '#8ea5be', // ursprunglig färg
    '#aabbce', // ljusare nyans
    '#c7d2df',

    // lila
    '#bd7ec9', // mörkare nyans
    '#ca98d4', // ursprunglig färg
    '#d7b2df', // ljusare nyans
    '#e5cbe9',

    // grön
    '#a0bd82', // mörkare nyans
    '#b3ca9b', // ursprunglig färg
    '#c6d7b4', // ljusare nyans
    '#d9e5cd',

    // röd
    '#c86868', // mörkare nyans
    '#d38686', // ursprunglig färg
    '#dea4a4', // ljusare nyans
    '#e9c3c3',

    // gul
    '#ffee81', // mörkare nyans
    '#fff19a', // ursprunglig färg
    '#fff5b3', // ljusare nyans
    '#fff8cd',

    // rosa
    '#9d8f82', // mörkare nyans
    '#b1a59b', // ursprunglig färg
    '#c4bcb4', // ljusare nyans
    '#d8d2cd',

    // orange
    '#ebaf5b', // mörkare nyans
    '#efbf7c', // ursprunglig färg
    '#f3cf9d', // ljusare nyans
    '#f7dfbd',
  ]

  const { schoolId } = useParams()
  useEffect(() => {
    if (schoolId) {
      fetchDefultTags(schoolId)
      fetchCustomTags(schoolId)
    }
  }, [schoolId])
  const archiveTag = async (tagId: string) => {
    try {
      // Call the API endpoint to delete the tag
      await fetch(`/tags/${tagId}`, {
        method: 'DELETE',
      })
      Swal.fire({
        text: t('admin.tagDeleted'),
        icon: 'success',
        confirmButtonColor: '#7eb5f4',
        confirmButtonText: 'OK',
      }).then(() => {
        if (schoolId) {
          fetchDefultTags(schoolId)
          fetchCustomTags(schoolId)
        }
      })
    } catch (error) {
      Swal.fire({
        text: t('admin.errorDeletingTag'),
        icon: 'error',
        confirmButtonColor: '#7eb5f4',
        confirmButtonText: 'OK',
      })
    }
  }
  const [showTooltip, setShowTooltip] = useState(false)
  const translateTags = (tag: string) => {
    switch (tag) {
      case 'Språk':
        return t('tags.language')
      case 'Matematik':
        return t('tags.math')
      case 'Motorik':
        return t('tags.motor_skills')
      case 'Skapande':
        return t('tags.creativity')
      case 'EQ':
        return t('tags.eq')
      default:
        return tag
    }
  }
  return (
    <>
      <ModalRegular
        onClose={onClose}
        isOpen={isOpen}
        bgColor="bg-blue-300 p-4"
        title={t('general.tags')}
        closeColor="white"
        className="bg-gray-50"
      >
        <div className="flex flex-col relative">
          <div className="bg-gray-50 p-4 mt-4">
            <div className="flex flex-col mb-4">
              <div className="font-bold text-gray-500">
                {' '}
                <>{t('admin.createTag')}</>
              </div>
              <div className="flex flex-row gap-x-1">
                <p className="text-red-500">*</p>
                <p className="text-gray-500">
                  <>{t('admin.createTagInfo')}</>
                </p>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <input
                type="text"
                className=" w-auto p-2 border mb-12 border-gray-300 rounded-2xl text-center  focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                placeholder={t('admin.writeNamehere')}
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
                maxLength={30}
                minLength={3}
              />
            </div>
            <div className="font-medium text-gray-500 -pl-1 mb-4">
              <>{t('admin.chooseColor')}</>
            </div>
            <div className="flex flex-wrap justify-center gap-2 mb-4">
              {colors.map((color, index) => (
                <div
                  key={index}
                  style={{ backgroundColor: color }}
                  className="w-8 h-8 hover:scale-[105%] cursor-pointer"
                  onClick={() => setSelectedColor(color)}
                ></div>
              ))}{' '}
            </div>
            <div className="flex flex-col">
              <div className="w-full flex justify-center">
                <div
                  className="rounded-full border min-w-[90px] text-center py-2 w-fit px-4 text-sm bg-white"
                  style={{ backgroundColor: selectedColor }}
                >
                  <div>{tagName || <>{t('admin.nameOnTag')}</>}</div>
                </div>
              </div>
              <div className="flex justify-end mt-5">
                {tagName && selectedColor ? (
                  <Button
                    size="sm"
                    variant="fullGreen"
                    onClick={() => {
                      createCustomTag()
                      setFakeState(true)
                    }}
                    label={t('admin.createTag')}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="font-bold text-gray-500 pl-4 pt-4">
            <>{t('admin.basicTags')}</>
          </div>
          <div className="flex flex-wrap bg-white border-t border-b justify-center gap-4 py-4">
            {defaultTags?.map((tag) => {
              return (
                <div
                  className="rounded-full shadow py-2 w-auto px-4 text-sm"
                  style={{ backgroundColor: tag.bg_color }}
                >
                  {translateTags(tag.name)}
                </div>
              )
            })}
          </div>
          <div className="font-bold text-gray-500  pl-4 pt-4">
            <>{t('admin.myTags')}</>
            <div className="flex flex-row gap-x-1 font-normal">
              <p className="text-red-500">*</p>
              <>{t('admin.editTagInfo')}</>
            </div>
          </div>
          <div className="flex  flex-wrap bg-white border-t border-b justify-center py-12 gap-4">
            <div></div>
            {customTags?.map((tag) => {
              return (
                <div
                  className="relative shadow cursor-pointer rounded-full min-w-[90px] text-center py-2 w-fit px-4 text-sm"
                  style={{ backgroundColor: tag.bg_color }}
                  onClick={() => {
                    setSelectedTag(tag)
                    setShowEditTag(true)
                  }}
                >
                  <div
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    onClick={(e) => {
                      e.stopPropagation()
                      Swal.fire({
                        text: t('admin.archiveWarningText'),
                        icon: 'warning',
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          // Call the deleteTag function when "OK" is clicked
                          deleteTag(tag.id).then(() => {
                            // Notify the user after the tag is deleted
                            Swal.fire({
                              text: t('admin.tagArchived'),
                              icon: 'success',
                              showCancelButton: false,
                              focusConfirm: false,
                              confirmButtonColor: '#7eb5f4',
                              confirmButtonText: t('general.ok'),
                            }).finally(() => {
                              if (schoolId) {
                                fetchCustomTags(schoolId)
                              }
                            })
                          })
                        }
                      })
                    }}
                    //className="absolute p-2 -top-1 bg-[#96a2b0] -right-1 hover:scale-[120%] rounded-full "
                  >
                    {/*showTooltip && (
                      <div className="absolute text-xs w-max top-8 bg-gray-200 text-gray-800 px-1 rounded-md -translate-y-full left-10 transform -translate-x-1/2">
                        <> {t('notes.archive')}</>
                      </div>
                    )*/}
                    {/* <p className="absolute -top-[3px] right-[4px] text-white">
                      x
                    </p>*/}
                  </div>
                  {tag.name}
                </div>
              )
            })}
          </div>
        </div>
      </ModalRegular>
      <ModalRegular
        isOpen={showEditTag}
        onClose={() => {
          setShowEditTag(false)
          setSelectedTag(null) // Reset selected tag when closing modal
        }}
        bgColor="bg-red-300 p-4"
        closeColor="white"
        title={t('admin.editTagg')}
      >
        <div className="p-4">
          <div className="p-10 flex">
            <div className="flex">
              <div className="flex flex-wrap justify-center gap-2 mb-4">
                {colors.map((color, index) => (
                  <div className="flex gap-4">
                    <div
                      key={index}
                      style={{ backgroundColor: color }}
                      className="w-8 h-8 hover:scale-[105%] cursor-pointer"
                      onClick={async () => {
                        setSelectedColor(color)
                        if (selectedTag) {
                          selectedTag.set('bg_color', color) // Update the bg_color field of the selected tag
                          await editCustomTag(selectedTag) // Save the updated tag to the database
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex w-full justify-center">
            {selectedTag && (
              <div
                className="rounded-full border min-w-[90px] text-center py-2 w-fit px-4 text-sm"
                style={{ backgroundColor: selectedTag.bg_color }}
              >
                {selectedTag.name}
              </div>
            )}
          </div>
          <div className="flex w-full justify-between">
            <Button
              size="sm"
              variant={'borderRed'}
              label={t('notes.archive')}
              onClick={() => {
                Swal.fire({
                  text: t('admin.archiveWarningText'),
                  icon: 'warning',
                  showCancelButton: true,
                  focusConfirm: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.ok'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    // Call the deleteTag function when "OK" is clicked
                    deleteTag(selectedTag.id).then(() => {
                      // Notify the user after the tag is deleted
                      Swal.fire({
                        text: t('admin.tagArchived'),
                        icon: 'success',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        confirmButtonText: t('general.ok'),
                      }).finally(() => {
                        if (schoolId) {
                          setShowEditTag(false)
                          fetchDefultTags(schoolId)
                          fetchCustomTags(schoolId)
                        }
                      })
                    })
                  }
                })
              }}
            />

            <Button
              size="sm"
              variant={'fullGreen'}
              label={t('absence.change')}
              onClick={() => {
                Swal.fire({
                  text: t('admin.tagChangeText'),
                  icon: 'success',
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.ok'),
                }).finally(() => {
                  setShowEditTag(false)
                })
              }}
            />
          </div>
        </div>
      </ModalRegular>
    </>
  )
}

export default TagsModal
