import { FC, useContext, useEffect, useState } from 'react'
import Page from '../../../components/Page'
import ModulesCard from './components/ModulesCard'
import { faBlock, faElephant } from '@fortawesome/pro-solid-svg-icons'
import { UserStoreContext } from '../../../store/userStore'
import { SchoolStoreContext } from '../../../store/schoolStore'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import { t } from 'i18next'
import { NavigationStoreContext } from '../../../store/navigationStore'
import { DashboardItem } from '../../dashboard/components/DashboardItem'
import classNames from 'classnames'
import { Button } from '../../../components/Button'
import Swal from 'sweetalert2'

type ModulesAddServiceProps = {}

export const ModulesAddService: FC<ModulesAddServiceProps> = ({}) => {
  const { school, fetchSingleSchool } = useContext(SchoolStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  const { schoolId } = useParams()
  useEffect(() => {
    if (schoolId) {
      fetchSingleSchool(schoolId)
    }
  }, [schoolId])
  const [activeModal, setActiveModal] = useState(false)
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('admin.modalsAddServices'))
  }, [t])
  return (
    <Page naked>
      <div>
        <div className="p-4 border rounded-2xl shadow-tyraShadow bg-white">
          <div className="border-b pb-4">
            {school && (
              <div
                className="flex flex-col gap-y-2 items-center justify-center "
                key={school.id}
              >
                <p className="text-xl">{school.schoolName}</p>
                <div className="flex flex-row gap-x-10">
                  <div className="flex flex-row gap-x-2">
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="text-eventsMain"
                    />
                    <div className="flex flex-col">
                      <p>
                        <>{t('admin.bindingTime')}</>
                      </p>
                      <p>2021-02-02</p>
                    </div>
                  </div>
                  <div className="flex flex-row gap-x-2">
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="text-eventsMain"
                    />
                    <div className="flex flex-col">
                      <p>
                        <>{t('admin.nextInvoice')}</>
                      </p>
                      <p>2021-02-02</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="py-2 text-3xl font-medium text-gray-500">
            <>{t('admin.modules')}</>
          </div>
          <div className="">
            <ModulesCard
              icon={faElephant}
              title={t('absence.absence')}
              statusCheck={true}
              onClick={() => {
                setActiveModal(true)
              }}
            />
          </div>
          <div className="py-2 text-3xl font-medium text-gray-500">
            <>Tilläggstjänster</>
          </div>
          <div className="">
            <ModulesCard
              icon={faBlock}
              title={t('absence.absence')}
              statusCheck={true}
              onClick={() => {
                setActiveModal(true)
              }}
              iconClassName="text-blue-500"
              iconBgColor="bg-green-500"
            />
          </div>
        </div>
      </div>
      <ModuleRegularModal
        activeModal={activeModal}
        setActiveModal={setActiveModal}
        status={true}
        icon={faCalendar}
      />
    </Page>
  )
}
type ModuleRegularModalProps = {
  activeModal: boolean
  setActiveModal: (active: boolean) => void
  status: boolean
  className?: string
  icon: any
}

const ModuleRegularModal: React.FC<ModuleRegularModalProps> = ({
  activeModal,
  setActiveModal,
  status,
  className,
  icon,
}) => {
  return (
    <ModalRegular
      isOpen={activeModal}
      onClose={() => {
        setActiveModal(false)
      }}
      closeColor="white"
      title={t('events.contentPlaceholder')}
      bgColor="bg-eventsMain p-4"
    >
      <div className="flex flex-col p-4">
        <div className="flex flex-col">
          <div className="flex flex-row gap-x-4">
            <div className="flex flex-col items-center justify-start gap-y-2">
              <div
                className={classNames(
                  'h-[80px] w-[80px] sm:h-[88px] sm:w-[88px] 2xl:h-[100px] 2xl:w-[100px] flex justify-center items-center rounded-3xl shadow',
                  className,
                )}
              >
                <FontAwesomeIcon className="text-white" icon={icon} size="3x" />
              </div>
              {status ? (
                <div className="text-green-500">Aktiv</div>
              ) : (
                <div className="text-red-500">Inaktiv</div>
              )}
            </div>
            <div>
              <h1 className="font-bold text-lg">
                Här är beskrivningen/header av modulen
              </h1>
              <p>
                Här är massa text om varje modul Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Ipsum eligendi, fugit ut
                consequuntur doloremque enim saepe quod perspiciatis
                repudiandae. Quod. modul Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Ipsum eligendi, fugit ut consequuntur
                doloremque enim saepe quod perspiciatis repudiandae. Quod. modul
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
                eligendi, fugit ut consequuntur doloremque enim saepe quod
                perspiciatis repudiandae. Quod. modul Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Ipsum eligendi, fugit ut
                consequuntur doloremque enim saepe quod perspiciatis
                repudiandae. Quod.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-y-4 mt-4 justify-center items-center">
            <Button
              variant="fullGray"
              size="custom"
              label={t('general.cancel')}
              className="flex justify-center min-w-[271px]"
              onClick={() => {
                Swal.fire({
                  icon: 'warning',
                  title: t('blog.confirmGreat'),
                  text: t('swal.deActivateAService'),
                  showCancelButton: true,
                  focusConfirm: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.cancel'),
                })
              }}
            />
            <Button
              size="custom"
              label={t('general.cancelAndRemove')}
              variant="fullRed"
              onClick={() => {
                Swal.fire({
                  icon: 'warning',
                  //Vilken modul title som behövs här?!
                  title: t('general.cancelAndRemove') + '(vald modultitle)',
                  text: t('general.cancelAndRemoveForAllUsers'),
                  showCancelButton: true,
                  focusConfirm: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.cancel'),
                })
              }}
            />
          </div>
        </div>
      </div>
    </ModalRegular>
  )
}
