import React, { RefObject, useContext, useState } from 'react'
import { faCog, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { useClickOutside } from '../../hooks/useClickoutside'
import { ButtonDropdown } from '../../components/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { UserStoreContext } from '../../store/userStore'
import { useNavigate } from 'react-router-dom'
import {
  CHANGE_TYPES,
  ChangeTranslation,
} from '../../components/ChangeTranslation'
import { faGlobe } from '@fortawesome/pro-regular-svg-icons'

export const LanguageGlobe: React.FC<{}> = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [menuRef, togglerRef] = useClickOutside(() => setIsOpen(false))
  const { t } = useTranslation()
  const { logoutUser } = useContext(UserStoreContext)
  const navigate = useNavigate()

  return (
    <div className="relative flex items-end">
      <button
        ref={togglerRef}
        type="button"
        className="w-full rounded-md group focus:outline-none"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className="flex items-center justify-between w-full">
          <span className="flex items-center justify-between min-w-0">
            <div className="text-xl group-hover:text-blue-50">
              <FontAwesomeIcon icon={faGlobe} />
            </div>
          </span>
          <svg
            className="flex-shrink-0 w-5 h-5 text-transparent group-hover:text-blue-50"
            x-description="Heroicon name: selector"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </span>
      </button>

      {isOpen && (
        <div
          ref={menuRef as RefObject<HTMLDivElement>}
          className="absolute right-0 z-50 mx-3 origin-top divide-y w-44 top-10"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <ButtonDropdown.Menu>
            <ButtonDropdown.Section>
              <div className={'flex justify-center p-3 w-full'}>
                <ChangeTranslation changeType={CHANGE_TYPES.FLAGS} />
              </div>
            </ButtonDropdown.Section>
          </ButtonDropdown.Menu>
        </div>
      )}
    </div>
  )
}
