import React, { useContext, useEffect, useRef, useState } from 'react'
import DroppableContainer from './DroppableContainer'
import { jsPDF } from 'jspdf'
import DraggableItem from './DraggableItem'
import StartingContainer from './StartingContainer'
import { PDFExport } from '@progress/kendo-react-pdf'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { ExportStoreContext } from '../../../store/exportStore'
import { PDFExportRoutes } from '../../skaExport/skaExport'
import Swal from 'sweetalert2'
import { t } from 'i18next'
import { NavigationStoreContext } from '../../../store/navigationStore'
import { Loading } from '../../../components/Loading'

const initialItems = [
  { id: '1', content: 'Text 1', type: 'text', size: 'sm' },
  { id: '2', content: 'Text 2', type: 'text', size: 'lg' },
  {
    id: '3',
    content:
      'https://login-alfa-2.tyra-appen.se/parse/files/ESR3nG8xRl6qaBahb31D3g5zBeyj7OjC/33d009ddd6863844935ffdaef5b3aa31_image_and_thumb.jpg',
    type: 'image',
  },
  {
    id: '4',
    content:
      'Har kommer lite text. Har kommer lite text. Har kommer lite text. Har kommer lite text. ',
    type: 'text',
  },
  { id: '5', content: 'Text 2', type: 'text' },
  {
    id: '6',
    content:
      'https://login-alfa-2.tyra-appen.se/parse/files/ESR3nG8xRl6qaBahb31D3g5zBeyj7OjC/b714e46aa07a2c39fc27c034d21ab0d9_image.png',
    type: 'image',
  },
  {
    id: '7',
    content:
      'Har kommer lite text. Har kommer lite text. Har kommer lite text. Har kommer lite text. Har kommer lite text. Har kommer lite text. Har kommer lite text. Har kommer lite text. Har kommer lite text. ',
    type: 'text',
  },
  { id: '8', content: 'Text 2', type: 'text' },
  {
    id: '9',
    content:
      'https://login-alfa-2.tyra-appen.se/parse/files/ESR3nG8xRl6qaBahb31D3g5zBeyj7OjC/97de120a65a76c6be8dadec8d7c10804_image_and_thumb.jpg',
    type: 'image',
  },
  {
    id: '10',
    content:
      'https://login-alfa-2.tyra-appen.se/parse/files/ESR3nG8xRl6qaBahb31D3g5zBeyj7OjC/eb8a61bbdea67c35e95cc0e78857430e_ios_thumb.jpg',
    type: 'image',
  },
]

interface ItemContainer {
  id: string
  container?: Container
}

interface Container {
  id: string
  rows?: number
  columns?: number
  container?: Container[]
  items?: any
  itemContainers?: ItemContainer[]
}

interface Page {
  id: string
  container?: Container[]
  allItemContainers?: any[]
}
/*
    Pages kan ha containers
    - Varje container kan ha container
        - Har en container itemContainer, så har har den droppableContainers i sig

    Pages har en container från början
    - Jag kan sen välja att lägga till antingen rader eller kolumner


    NYTT:
    Skapa en sida:
    1. Hur många rader ska sidan ha
    2. Hur många kolumner ska första raden ha
    3. Hur många kolumner ska andra OSV ha
    
*/
interface Props {
  skaItems2: any
}

const Page: React.FC<Props> = observer(({ skaItems2 }) => {
  const { skaId, skaType } = useParams()
  const { setTitle } = useContext(NavigationStoreContext)
  const [loading, setLoading] = useState(true)
  const {
    getSkaReflectionItems,
    getSkaOperationItems,
    getSkaProjectItems,
    getSkaProjectPlanningTitle,
    getSkaOperationsPlanningTitle,
    getSkaReflectionsTitle,
    skaItems,
    title,
  } = useContext(ExportStoreContext)
  useEffect(() => {
    /*

  ProjectPlanning -> Undervisning -> 120
  OperationsPlanning -> Utbildning -> 110
  Reflections -> Barndokumentation -> 130

*/
    if (skaId) {
      if (skaType === PDFExportRoutes.BARNDOKUMENTATION) {
        getSkaReflectionItems(skaId).then(() => {
          setLoading(false)
        })
        getSkaReflectionsTitle(skaId)

        //setTitle(title ?? '')
      }
      if (skaType === PDFExportRoutes.UTBILDNING) {
        getSkaOperationItems(skaId).finally(() => {
          setLoading(false)
        })
        getSkaOperationsPlanningTitle(skaId)
        //setTitle(title ?? '')
      }
      if (skaType === PDFExportRoutes.UNDERVISNING) {
        getSkaProjectItems(skaId).finally(() => {
          setLoading(false)
        })
        getSkaProjectPlanningTitle(skaId)
        // setTitle(title ?? '')
      }
      //getSkaOperationItems(skaId)
      //getSkaProjectItems(skaId)
    }
  }, [skaId])

  useEffect(() => {
    setTitle(title ?? '')
  }, [title])

  const pdfExportComponent = React.useRef(null)
  const [exporting, setExporting] = useState<boolean>(false)
  const [pagesNew, setPagesNew] = useState<Page[]>([])

  const pagesToBuildWith: any = [
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-1-1-3' },
        { id: 'page-1-1-2-1' },
        { id: 'page-1-1-2-2' },
        { id: 'page-1-1-2-3' },
        { id: 'page-1-1-3-1' },
        { id: 'page-1-1-3-2' },
        { id: 'page-1-1-3-3' },
        { id: 'page-1-1-4-1' },
        { id: 'page-1-1-4-2' },
        { id: 'page-1-1-4-3' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 4,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 3,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                    { id: 'page-1-1-1-1-3' },
                  ],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 3,
              itemContainers: [
                { id: 'page-1-1-2-1' },
                { id: 'page-1-1-2-2' },
                { id: 'page-1-1-2-3' },
              ],
            },
            {
              id: 'page-1-1-3',
              rows: 1,
              columns: 3,
              itemContainers: [
                { id: 'page-1-1-3-1' },
                { id: 'page-1-1-3-2' },
                { id: 'page-1-1-3-3' },
              ],
            },
            {
              id: 'page-1-1-4',
              rows: 1,
              columns: 3,
              itemContainers: [
                { id: 'page-1-1-4-1' },
                { id: 'page-1-1-4-2' },
                { id: 'page-1-1-4-3' },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-1-1-3' },
        { id: 'page-1-1-2-1' },
        { id: 'page-1-1-2-2' },
        { id: 'page-1-1-2-3' },
        { id: 'page-1-1-3-1' },
        { id: 'page-1-1-3-2' },
        { id: 'page-1-1-3-3' },
        { id: 'page-1-1-4-1' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 4,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 3,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                    { id: 'page-1-1-1-1-3' },
                  ],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 3,
              itemContainers: [
                { id: 'page-1-1-2-1' },
                { id: 'page-1-1-2-2' },
                { id: 'page-1-1-2-3' },
              ],
            },
            {
              id: 'page-1-1-3',
              rows: 1,
              columns: 3,
              itemContainers: [
                { id: 'page-1-1-3-1' },
                { id: 'page-1-1-3-2' },
                { id: 'page-1-1-3-3' },
              ],
            },
            {
              id: 'page-1-1-4',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-4-1' }],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-1-1-3' },
        { id: 'page-1-1-2-1' },
        { id: 'page-1-1-2-2' },
        { id: 'page-1-1-2-3' },
        { id: 'page-1-1-3-1' },
        { id: 'page-1-1-4-1' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 4,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 3,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                    { id: 'page-1-1-1-1-3' },
                  ],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 3,
              itemContainers: [
                { id: 'page-1-1-2-1' },
                { id: 'page-1-1-2-2' },
                { id: 'page-1-1-2-3' },
              ],
            },
            {
              id: 'page-1-1-3',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-3-1' }],
            },
            {
              id: 'page-1-1-4',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-4-1' }],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-1-1-3' },
        { id: 'page-1-1-1-2-1' },
        { id: 'page-1-1-2-1' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 2,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 2,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 3,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                    { id: 'page-1-1-1-1-3' },
                  ],
                },
                {
                  id: 'page-1-1-1-2',
                  rows: 1,
                  columns: 1,
                  itemContainers: [{ id: 'page-1-1-1-2-1' }],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-2-1' }],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-1-1-3' },
        { id: 'page-1-1-2-1' },
        { id: 'page-1-1-2-2' },
        { id: 'page-1-1-2-3' },
        { id: 'page-1-1-3-1' },
        { id: 'page-1-1-3-2' },
        { id: 'page-1-1-3-3' },
        { id: 'page-1-1-4-1' },
        { id: 'page-1-1-1-1-4' },
        { id: 'page-1-1-2-4' },
        { id: 'page-1-1-3-4' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 4,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 4,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                    { id: 'page-1-1-1-1-3' },
                    { id: 'page-1-1-1-1-4' },
                  ],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 4,
              itemContainers: [
                { id: 'page-1-1-2-1' },
                { id: 'page-1-1-2-2' },
                { id: 'page-1-1-2-3' },
                { id: 'page-1-1-2-4' },
              ],
            },
            {
              id: 'page-1-1-3',
              rows: 1,
              columns: 4,
              itemContainers: [
                { id: 'page-1-1-3-1' },
                { id: 'page-1-1-3-2' },
                { id: 'page-1-1-3-3' },
                { id: 'page-1-1-3-4' },
              ],
            },
            {
              id: 'page-1-1-4',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-4-1' }],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-1-1-3' },
        { id: 'page-1-1-2-1' },
        { id: 'page-1-1-2-2' },
        { id: 'page-1-1-2-3' },
        { id: 'page-1-1-3-1' },
        { id: 'page-1-1-3-2' },
        { id: 'page-1-1-3-3' },
        { id: 'page-1-1-4-1' },
        { id: 'page-1-1-1-1-4' },
        { id: 'page-1-1-2-4' },
        { id: 'page-1-1-3-4' },
        { id: 'page-1-1-5-1' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 4,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 4,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                    { id: 'page-1-1-1-1-3' },
                    { id: 'page-1-1-1-1-4' },
                  ],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 4,
              itemContainers: [
                { id: 'page-1-1-2-1' },
                { id: 'page-1-1-2-2' },
                { id: 'page-1-1-2-3' },
                { id: 'page-1-1-2-4' },
              ],
            },
            {
              id: 'page-1-1-3',
              rows: 1,
              columns: 4,
              itemContainers: [
                { id: 'page-1-1-3-1' },
                { id: 'page-1-1-3-2' },
                { id: 'page-1-1-3-3' },
                { id: 'page-1-1-3-4' },
              ],
            },
            {
              id: 'page-1-1-4',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-4-1' }],
            },
            {
              id: 'page-1-1-5',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-5-1' }],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-1-1-3' },
        { id: 'page-1-1-2-1' },
        { id: 'page-1-1-2-2' },
        { id: 'page-1-1-2-3' },
        { id: 'page-1-1-4-1' },
        { id: 'page-1-1-1-1-4' },
        { id: 'page-1-1-2-4' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 4,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 4,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                    { id: 'page-1-1-1-1-3' },
                    { id: 'page-1-1-1-1-4' },
                  ],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 4,
              itemContainers: [
                { id: 'page-1-1-2-1' },
                { id: 'page-1-1-2-2' },
                { id: 'page-1-1-2-3' },
                { id: 'page-1-1-2-4' },
              ],
            },
            {
              id: 'page-1-1-4',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-4-1' }],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-1-1-3' },

        { id: 'page-1-1-3-1' },
        { id: 'page-1-1-3-2' },
        { id: 'page-1-1-3-3' },
        { id: 'page-1-1-4-1' },
        { id: 'page-1-1-1-1-4' },
        { id: 'page-1-1-3-4' },
        { id: 'page-1-1-5-1' },
        { id: 'page-1-1-6-1' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 4,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 4,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                    { id: 'page-1-1-1-1-3' },
                    { id: 'page-1-1-1-1-4' },
                  ],
                },
              ],
            },

            {
              id: 'page-1-1-3',
              rows: 1,
              columns: 4,
              itemContainers: [
                { id: 'page-1-1-3-1' },
                { id: 'page-1-1-3-2' },
                { id: 'page-1-1-3-3' },
                { id: 'page-1-1-3-4' },
              ],
            },
            {
              id: 'page-1-1-4',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-4-1' }],
            },
            {
              id: 'page-1-1-5',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-5-1' }],
            },
            {
              id: 'page-1-1-6',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-6-1' }],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-1-1-3' },
        { id: 'page-1-1-4-1' },
        { id: 'page-1-1-1-1-4' },
        { id: 'page-1-1-5-1' },
        { id: 'page-1-1-6-1' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 4,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 4,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                    { id: 'page-1-1-1-1-3' },
                    { id: 'page-1-1-1-1-4' },
                  ],
                },
              ],
            },
            {
              id: 'page-1-1-4',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-4-1' }],
            },
            {
              id: 'page-1-1-5',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-5-1' }],
            },
            {
              id: 'page-1-1-6',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-6-1' }],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1' },
        { id: 'page-1-1-2' },
        { id: 'page-1-1-3' },
        { id: 'page-1-1-4' },
        { id: 'page-1-2-1' },
        { id: 'page-1-2-2' },
        { id: 'page-1-3-1-1' },
        { id: 'page-1-3-1-2' },
        { id: 'page-1-3-1-3' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 2,
          columns: 2,
          itemContainers: [
            { id: 'page-1-1-1' },
            { id: 'page-1-1-2' },
            { id: 'page-1-1-3' },
            { id: 'page-1-1-4' },
          ],
        },
        {
          id: 'page-1-2',
          rows: 1,
          columns: 2,
          itemContainers: [{ id: 'page-1-2-1' }, { id: 'page-1-2-2' }],
        },
        {
          id: 'page-1-3',
          container: [
            {
              id: 'page-1-3-1',
              rows: 1,
              columns: 3,
              itemContainers: [
                { id: 'page-1-3-1-1' },
                { id: 'page-1-3-1-2' },
                { id: 'page-1-3-1-3' },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-1-1-3' },
        { id: 'page-1-1-2-1' },
        { id: 'page-1-2-1' },
        { id: 'page-1-2-2' },
        { id: 'page-1-2-3' },
        { id: 'page-1-2-4' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 2,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 3,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                    { id: 'page-1-1-1-1-3' },
                  ],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-2-1' }],
            },
          ],
          /*itemContainers: [
            { id: 'page-1-1-1' },
            { id: 'page-1-1-2' },
            { id: 'page-1-1-3' },
            { id: 'page-1-1-4' },
          ],*/
        },
        {
          id: 'page-1-2',
          rows: 1,
          columns: 4,
          itemContainers: [
            { id: 'page-1-2-1' },
            { id: 'page-1-2-2' },
            { id: 'page-1-2-3' },
            { id: 'page-1-2-4' },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },

        { id: 'page-1-1-2-1' },
        { id: 'page-1-2-1' },
        { id: 'page-1-2-2' },
        { id: 'page-1-2-3' },
        { id: 'page-1-2-4' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 2,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 1,
                  itemContainers: [{ id: 'page-1-1-1-1-1' }],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 1,
              itemContainers: [{ id: 'page-1-1-2-1' }],
            },
          ],
          /*itemContainers: [
            { id: 'page-1-1-1' },
            { id: 'page-1-1-2' },
            { id: 'page-1-1-3' },
            { id: 'page-1-1-4' },
          ],*/
        },
        {
          id: 'page-1-2',
          rows: 1,
          columns: 4,
          itemContainers: [
            { id: 'page-1-2-1' },
            { id: 'page-1-2-2' },
            { id: 'page-1-2-3' },
            { id: 'page-1-2-4' },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-2-1' },
        { id: 'page-1-1-2-2' },
        { id: 'page-1-1-3-1' },
        { id: 'page-1-1-3-2' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 3,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 2,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                  ],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 2,
              itemContainers: [{ id: 'page-1-1-2-1' }, { id: 'page-1-1-2-2' }],
            },
            {
              id: 'page-1-1-3',
              rows: 1,
              columns: 2,
              itemContainers: [{ id: 'page-1-1-3-1' }, { id: 'page-1-1-3-2' }],
            },
          ],
          /*itemContainers: [
            { id: 'page-1-1-1' },
            { id: 'page-1-1-2' },
            { id: 'page-1-1-3' },
            { id: 'page-1-1-4' },
          ],*/
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-2-1' },
        { id: 'page-1-1-2-2' },
        { id: 'page-1-1-3-1' },
        { id: 'page-1-1-3-2' },
        { id: 'page-1-1-4-1' },
        { id: 'page-1-1-4-2' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 3,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 2,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                  ],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 2,
              itemContainers: [{ id: 'page-1-1-2-1' }, { id: 'page-1-1-2-2' }],
            },
            {
              id: 'page-1-1-3',
              rows: 1,
              columns: 2,
              itemContainers: [{ id: 'page-1-1-3-1' }, { id: 'page-1-1-3-2' }],
            },
            {
              id: 'page-1-1-4',
              rows: 1,
              columns: 2,
              itemContainers: [{ id: 'page-1-1-4-1' }, { id: 'page-1-1-4-2' }],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [
        { id: 'page-1-1-1-1-1' },
        { id: 'page-1-1-1-1-2' },
        { id: 'page-1-1-1-1-3' },
        { id: 'page-1-1-2-1' },
        { id: 'page-1-1-2-2' },
        { id: 'page-1-1-2-3' },
        { id: 'page-1-1-3-1' },
        { id: 'page-1-1-3-2' },
        { id: 'page-1-1-3-3' },
      ],
      container: [
        {
          id: 'page-1-1',
          rows: 3,
          columns: 2,
          container: [
            {
              id: 'page-1-1-1',
              rows: 1,
              columns: 1,
              container: [
                {
                  id: 'page-1-1-1-1',
                  rows: 1,
                  columns: 3,
                  itemContainers: [
                    { id: 'page-1-1-1-1-1' },
                    { id: 'page-1-1-1-1-2' },
                    { id: 'page-1-1-1-1-3' },
                  ],
                },
              ],
            },
            {
              id: 'page-1-1-2',
              rows: 1,
              columns: 3,
              itemContainers: [
                { id: 'page-1-1-2-1' },
                { id: 'page-1-1-2-2' },
                { id: 'page-1-1-2-3' },
              ],
            },
            {
              id: 'page-1-1-3',
              rows: 1,
              columns: 3,
              itemContainers: [
                { id: 'page-1-1-3-1' },
                { id: 'page-1-1-3-2' },
                { id: 'page-1-1-3-3' },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [{ id: 'page-1-1-1' }],
      container: [
        {
          id: 'page-1-1',
          rows: 1,
          columns: 1,
          itemContainers: [{ id: 'page-1-1-1' }],
        },
      ],
    },
    {
      id: 'page-1',
      allItemContainers: [{ id: 'page-1-1-1' }, { id: 'page-1-1-2' }],
      container: [
        {
          id: 'page-1-1',
          rows: 2,
          columns: 1,
          itemContainers: [{ id: 'page-1-1-1' }, { id: 'page-1-1-2' }],
        },
      ],
    },
  ]

  const [containersNew, setContainersNew] = useState<
    { id: string; items: typeof initialItems }[]
  >([
    /*{ id: 'page-1-1-1', items: [] },
    { id: 'page-1-1-2', items: [] },
    { id: 'page-1-1-3', items: [] },
    { id: 'page-1-1-4', items: [] },
    { id: 'page-1-2-1', items: [] },
    { id: 'page-1-2-2', items: [] },
    { id: 'page-2-1-1', items: [] },
    { id: 'page-2-1-2', items: [] },
    { id: 'page-2-1-3', items: [] },
    { id: 'page-2-1-4', items: [] },
    { id: 'page-2-2-1', items: [] },
    { id: 'page-2-2-2', items: [] },
    { id: 'page-2-2-3', items: [] },
    { id: 'page-2-2-4', items: [] },
    { id: 'page-1-3-1-1', items: [] },
    { id: 'page-1-3-1-2', items: [] },
    { id: 'page-1-3-1-3', items: [] },
    { id: 'page-2-1-1-1-1', items: [] },
    { id: 'page-2-1-1-1-2', items: [] },
    { id: 'page-2-1-1-1-3', items: [] },
    { id: 'page-2-1-2-1', items: [] },*/
  ])

  const addContainersToRow = (
    container: Container,
    rows: number = 1,
    columnNumber: number,
  ) => {
    const id = container.id + '-' + columnNumber

    setContainersNew((oldArray) => [
      ...oldArray,
      {
        id,
        items: [],
      },
    ])
    //setContainersNew(newContainersNew)
    return id
    /*for (var i=0; i < columns; i++) {

    }*/
  }

  const addRow = (
    page: Page,
    containerId: string,
    rows: number,
    columns: number,
  ) => {
    page.container?.forEach((container) => {
      if (container.id === containerId) {
        for (var i = 0; i < columns; i++) {
          const test = addContainersToRow(container, undefined, i + 1)
          container?.itemContainers?.push({ id: test })
        }
      } else {
        container.container?.forEach((container2) => {
          if (container2.id === containerId) {
            for (var i = 0; i < columns; i++) {
              const test = addContainersToRow(container2, undefined, i + 1)
              container2?.itemContainers?.push({ id: test })
            }
          } else {
            container2.container?.forEach((container3) => {
              if (container3.id === containerId) {
                for (var i = 0; i < columns; i++) {
                  const test = addContainersToRow(container3, undefined, i + 1)
                  container3?.itemContainers?.push({ id: test })
                }
              }
            })
          }
        })
      }
    })
  }

  const renderItemContainers = (
    page: Page,
    container: any,
    itemContainers: any,
  ) => {
    // rendera itemcontainers
    var div: any = []

    itemContainers.forEach((itemCon: any) => {
      div.push(
        <DroppableContainer
          key={itemCon.id}
          id={itemCon.id}
          items={containersNew.find((cont) => cont.id === itemCon.id)?.items}
          onDrop={handleDrop}
          addRowAvailable={
            containersNew.find((cont) => cont.id === itemCon.id)?.items.length
              ? false
              : true
          }
          addRow={() => addRow(page, container.id, 1, 2)}
          page={page}
          allItems={allItems}
          handleRemove={removeItem}
          //ref={containerRefs[index]}
        >
          <></>
        </DroppableContainer>,
      )
    })

    return (
      <div
        className={`grid grid-cols-${container.columns} grid-rows-${container.rows} gap-2 overflow-hidden`}
      >
        {div}
      </div>
    )
  }

  const renderItemContainersForPagesToChoose = (
    page: Page,
    container: any,
    itemContainers: any,
  ) => {
    // rendera itemcontainers
    var div: any = []

    itemContainers.forEach((itemCon: any) => {
      div.push(
        <div
          className="flex flex-wrap gap-1 overflow-hidden relative bg-gray-200"
          style={{
            padding: '5px',
            //minHeight: '100px',
          }}
        ></div>,
      )
    })

    return (
      <div
        className={`cursor-pointer grid grid-cols-${container.columns} grid-rows-${container.rows} gap-1 overflow-hidden`}
      >
        {div}
      </div>
    )
  }

  const createPage = (page: Page) => {}

  const renderContainer = (container: any) => {
    // rendera container
  }

  const renderContainersForPage = (pageId: string) => {
    var div: any = []

    const page = pagesNew.find((page: any) => page.id === pageId)

    if (page) {
      page.container?.forEach((container) => {
        if (container.itemContainers) {
          div.push(
            renderItemContainers(page, container, container.itemContainers),
          )
        } else {
          container.container?.forEach((container2) => {
            if (container2.itemContainers) {
              div.push(
                renderItemContainers(
                  page,
                  container2,
                  container2.itemContainers,
                ),
              )
            } else {
              container2.container?.forEach((container3) => {
                if (container3.itemContainers) {
                  div.push(
                    renderItemContainers(
                      page,
                      container3,
                      container3.itemContainers,
                    ),
                  )
                } else {
                }
              })
            }
          })
        }
      })
    }
    return <>{div}</>
  }

  const renderPagesToChoose = (page: Page) => {
    var div: any = []

    //const page = pagesNew.find((page: any) => page.id == pageId)

    if (page) {
      page.container?.forEach((container) => {
        if (container.itemContainers) {
          div.push(
            renderItemContainersForPagesToChoose(
              page,
              container,
              container.itemContainers,
            ),
          )
        } else {
          container.container?.forEach((container2) => {
            if (container2.itemContainers) {
              div.push(
                renderItemContainersForPagesToChoose(
                  page,
                  container2,
                  container2.itemContainers,
                ),
              )
            } else {
              container2.container?.forEach((container3) => {
                if (container3.itemContainers) {
                  div.push(
                    renderItemContainersForPagesToChoose(
                      page,
                      container3,
                      container3.itemContainers,
                    ),
                  )
                } else {
                }
              })
            }
          })
        }
      })
    }
    return <>{div}</>
  }

  //const [availableItems, setAvailableItems] = useState(initialItems)
  const [availableItems, setAvailableItems] = useState(skaItems)
  const [allItems, setAllItems] = useState(skaItems)
  useEffect(() => {
    setAvailableItems(skaItems)
    setAllItems(skaItems)
  }, [skaItems])

  const [containers, setContainers] = useState<
    { id: string; items: typeof initialItems }[]
  >([
    { id: 'container-1', items: [] },
    { id: 'container-2', items: [] },
  ])

  const [pages, setPages] = useState([
    {
      id: 'page-1',
      containers: [
        {
          id: 1,
          containers: [],
          items: [],
        },
      ],
    },
  ])
  /*console.log('pages', pages)
  console.log('containersNew', containersNew)*/

  /*const addNewPage = (type: number) => {
    setPages((currPages) => [
      ...pages,
      {
        id: pages.length + 1,
        type: type,
        containers: [
          {
            id: 1,
            containers: [],
            items: [],
          },
        ],
      },
    ])
  }*/

  //const containerRefs = containers.map(() => useRef<HTMLDivElement>(null))

  const generatePDF = () => {
    const doc = new jsPDF('p', 'mm', 'a4')

    // Add content to the PDF here, for example:
    doc.text('Hello world!', 10, 10)

    // Save the generated PDF
    doc.save('a4-page.pdf')
  }

  const handleDrop = (
    itemId: string,
    targetContainerId: string,
    position?: number,
  ) => {
    /*if (
      !containersNew.find((container) => container.id === targetContainerId)
    ) {
      console.log('hallåå1')
      setContainersNew([...containersNew, { id: targetContainerId, items: [] }])
    }
    console.log('hallåå2')*/
    setContainersNew((prev) =>
      prev.map((container) => {
        //console.log('container', container)
        //console.log('targetContainerId', targetContainerId)
        if (container.id === targetContainerId) {
          const droppedItem =
            allItems.find((item: any) => item.id === itemId) ||
            container.items.find((item) => item.id === itemId)
          if (!droppedItem) return container
          const newItems = container.items.filter((item) => item.id !== itemId)
          if (position !== undefined) {
            newItems.splice(position, 0, droppedItem)
          } else {
            newItems.push(droppedItem)
          }

          return { ...container, items: newItems }
        }

        return {
          ...container,
          items: container.items.filter((item) => item.id !== itemId),
        }
      }),
    )
    const newAvailableItems = []
    for (const item of availableItems) {
      if (item.id == itemId) {
        item.available = false
      }
      newAvailableItems.push(item)
    }
    setAvailableItems(newAvailableItems)
    /*setAvailableItems((prev: any) =>
      prev.filter((item: any) => item.id !== itemId),
    )*/

    /*setContainers((prev) =>
      prev.map((container) => {
        if (container.id === targetContainerId) {
          const droppedItem =
            availableItems.find((item) => item.id === itemId) ||
            container.items.find((item) => item.id === itemId)
          if (!droppedItem) return container

          const newItems = container.items.filter((item) => item.id !== itemId)
          if (position !== undefined) {
            newItems.splice(position, 0, droppedItem)
          } else {
            newItems.push(droppedItem)
          }

          return { ...container, items: newItems }
        }
        return {
          ...container,
          items: container.items.filter((item) => item.id !== itemId),
        }
      }),
    )
    setAvailableItems((prev) => prev.filter((item) => item.id !== itemId))*/
  }

  const handleResetItem = (
    itemId: string,
    targetContainerId: string,
    position?: any,
  ) => {
    const newAvailableItems = []
    for (const item of availableItems) {
      if (item.id === itemId) {
        item.available = true
      }
      newAvailableItems.push(item)
    }
    //console.log('containersNew', containersNew)
    for (var i = 0; i < containersNew.length; i++) {
      // console.log('testing123', containersNew[i])
    }

    const newContainersNew = []
    for (const container of containersNew) {
      if (container.items.find((item) => item.id === itemId)) {
        //container.items.filter((item) => item.id !== itemId)
        container.items = container.items.filter((item) => item.id !== itemId)
      }
      newContainersNew.push(container)
    }

    //setContainersNew(newContainersNew)
    /* const newContainersNew = []
    for (const container of containersNew) {
      console.log(
        'test',
        container.items.find((item) => item.id === itemId),
      )
      console.log('test2', container.items)
      console.log('test3', containersNew)

      for (const item of container.items) {
        if (item.id === itemId) {
          console.log('Tjohooo')
        }
      }

      if (container.items.find((item) => item.id === itemId)) {
        container.items.filter((item) => item.id !== itemId)
      }
      newContainersNew.push(container)
    }
*/
    setContainersNew(newContainersNew)
    setAvailableItems(newAvailableItems)
  }

  const removeItem = (itemId: string) => {
    const newAvailableItems = []
    for (const item of availableItems) {
      if (item.id === itemId) {
        item.available = true
      }
      newAvailableItems.push(item)
    }
    const newContainersNew = []
    for (const container of containersNew) {
      if (container.items.find((item) => item.id === itemId)) {
        //container.items.filter((item) => item.id !== itemId)
        container.items = container.items.filter((item) => item.id !== itemId)
      }
      newContainersNew.push(container)
    }

    setContainersNew(newContainersNew)
    setAvailableItems(newAvailableItems)
  }

  return (
    <div className="grid grid-cols-6 gap-5 bg-gray-300 min-h-screen">
      {loading && <Loading />}
      <div className="w-full h-auto col-span-2">
        <StartingContainer
          key={1}
          id={'1'}
          items={availableItems.filter((item) => item.available)}
          onDrop={handleResetItem}
          containersNew={containersNew}
          //ref={containerRefs[index]}
        />
      </div>
      <div className="col-span-3">
        <button
          className="m-4 rounded-xl text-white bg-eventsMain py-2 px-4"
          onClick={() => {
            if (pdfExportComponent.current) {
              //@ts-ignore
              pdfExportComponent.current.save()
            }
          }}
        >
          Export PDF
        </button>
        <PDFExport
          keepTogether="p"
          paperSize="A4"
          margin="0cm"
          ref={pdfExportComponent}
          fileName={title ?? 'export'}
        >
          <div className="pdfFont">
            {pagesNew.map((page) => {
              return (
                <div
                  className={classNames(
                    // 'w-[476px] h-[674px] grid gap-2 mb-10 relative',
                    // SENASTE'w-[595px] h-[841px] grid gap-2 relative mb-10 p-[50px]',
                    'w-[620px] h-[877px] grid gap-2 relative mb-10 p-[50px] border border-white',
                    // exporting ? '' : 'border border-gray-500',
                  )}
                >
                  <div
                    className="absolute top-[40px] left-[-22px] rounded-full w-[20px] h-[20px] bg-red-500 text-center z-20 text-white leading-[20px] text-2xs"
                    onClick={() => {
                      Swal.fire({
                        text: t('export.warningRemovePage'),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.yes'),
                        cancelButtonText: t('general.cancel'),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          if (page.allItemContainers) {
                            for (const item of page?.allItemContainers) {
                              const container = containersNew.find(
                                (cont) => cont.id == item.id,
                              )
                              if (container?.items.length) {
                                for (const itemInContainer of container.items) {
                                  const newAvailableItems = []
                                  for (const item of availableItems) {
                                    if (item.id == itemInContainer.id) {
                                      item.available = true
                                    }
                                    newAvailableItems.push(item)
                                  }

                                  const newContainersNew = []
                                  for (const container of containersNew) {
                                    if (
                                      container.items.find(
                                        (item) =>
                                          item.id === itemInContainer.id,
                                      )
                                    ) {
                                      container.items.filter(
                                        (item) =>
                                          item.id !== itemInContainer.id,
                                      )
                                    }
                                    newContainersNew.push(container)
                                  }

                                  setContainersNew(newContainersNew)
                                  setAvailableItems(newAvailableItems)
                                  /*setAvailableItems((oldArray) => [
                                  ...oldArray,
                                  itemInContainer,
                                ])*/
                                }
                                //container.items = []
                                //setContainersNew(containersNew)
                              }
                            }
                          }
                          setPagesNew((prev: any) =>
                            prev.filter((item: any) => item.id !== page.id),
                          )
                        }
                      })
                    }}
                  >
                    X
                  </div>
                  {renderContainersForPage(page.id)}
                </div>
              )
              /*
          return (
            <div className="w-[476px] h-[674px] border border-gray-500 grid gap-2">
              {page.container &&
                page.container.map((container) => {
                  return (
                    <div
                      className={`grid grid-cols-${container.columns} grid-rows-${container.rows} border  border-gray-500 gap-2 overflow-hidden`}
                    >
                      {container.itemContainers &&
                        container.itemContainers.map((itemCon: any) => {
                          return (
                            <DroppableContainer
                              key={itemCon.id}
                              id={itemCon.id}
                              items={
                                containersNew.find(
                                  (cont) => cont.id === itemCon.id,
                                )?.items
                              }
                              onDrop={handleDrop}
                              //ref={containerRefs[index]}
                            >
                              <></>
                            </DroppableContainer>
                          )
                        })}
                    </div>
                  )
                })}
            </div>
          )*/
            })}
          </div>
        </PDFExport>

        {/*<div onClick={() => addNewPage(1)}>Fler Pages 1</div>
      <div onClick={() => addNewPage(2)}>Fler Pages 2</div>
      <div onClick={() => addNewPage(3)}>Fler Pages 3</div>
      {pages.map((page) => {
        if (page.type === 1) {
          return (
            <div className="w-[476px] h-[674px] border border-gray-500 grid grid-cols-2 grid-rows-2 gap-2"></div>
          )
        } else if (page.type === 2) {
          return (
            <div className="w-[476px] h-[674px] border border-gray-500 grid grid-cols-1 grid-rows-2 gap-2"></div>
          )
        } else if (page.type === 3) {
          return (
            <div className="w-[476px] h-[674px] border border-gray-500 grid grid-cols-1 grid-rows-3 gap-2">
              <div className="grid border grid-cols-2 border-gray-500 gap-2">
                <div className="grid border border-gray-500 gap-2">a</div>
                <div className="grid border grid-cols-1 grid-rows-2 border-gray-500 gap-2">
                  <div className="grid grid-cols-2 grid-rows-1 border border-gray-500 gap-2">
                    <div className="grid border border-gray-500 gap-2">b</div>
                    <div className="grid border border-gray-500 gap-2">c</div>
                  </div>
                  <div className="grid grid-cols-2 grid-rows-1 border border-gray-500 gap-2">
                    <div className="grid border border-gray-500 gap-2">b</div>
                    <div className="grid border border-gray-500 gap-2">c</div>
                  </div>
                </div>
              </div>
              <div className="grid border border-gray-500 gap-2"></div>
              <div className="grid border border-gray-500 gap-2"></div>
            </div>
          )
        }
      })}
    */}
        {/*<StartingContainer>
        {availableItems.map((item) => (
          <DraggableItem
            key={item.id}
            id={item.id}
            content={item.content}
            type={item.type}
          />
        ))}
        </StartingContainer>*/}

        {/*<div className="w-[476px] h-[674px] border border-gray-500 grid grid-cols-3 grid-rows-4 gap-2">
        containers.map((container, index) => (
          <DroppableContainer
            key={container.id}
            id={container.id}
            items={container.items}
            onDrop={handleDrop}
            //ref={containerRefs[index]}
          >
            {container.items.map((item) => (
              <DraggableItem
                key={item.id}
                id={item.id}
                content={item.content}
                type={item.type}
              />
            ))}
          </DroppableContainer>
            ))
      </div>*/}
      </div>
      <div>
        <div className="grid grid-cols-2">
          {pagesToBuildWith.map((page: any) => {
            return (
              <div
                className="w-[83.3px] h-[117.95px] border border-gray-500 grid gap-1 mb-10"
                onClick={() => {
                  let pageStyle = JSON.stringify(page)
                  let pageToAdd = JSON.parse(
                    pageStyle.replaceAll(
                      'page-1',
                      'page-' + Math.floor(Math.random() * Date.now()),
                    ),
                  )
                  setPagesNew([...pagesNew, pageToAdd])
                  pageToAdd.allItemContainers.forEach((allItemCont: any) => {
                    containersNew.push({ id: allItemCont.id, items: [] })
                  })
                  setContainersNew(containersNew)
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 500,
                  })
                }}
              >
                {renderPagesToChoose(page)}
              </div>
            )
          })}
        </div>
      </div>

      {/*<button onClick={generatePDF}>Generate PDF</button>*/}
    </div>
  )
})

export default Page
