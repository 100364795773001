import React, { useContext, useEffect, useState } from 'react'
import Page from '../../../components/Page'
import { useParams } from 'react-router-dom'
import { NavigationStoreContext } from '../../../store/navigationStore'
import ColorCard from '../../../components/ColorCard'
import Swal from 'sweetalert2'
import { TagStoreContext } from '../../../store/tagStore'
import { Button } from '../../../components/Button'
import ModalRegular from '../../../components/ModalRegular'
import { Slider } from '../../../components/Slider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StepButton } from '../../events3/components/StepButton'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useEventTypeSelections } from '../../events3/CreateEvent3'
import { Tag } from '../../../components/Tag'
import { TagInterface } from '../../../Models/Tag'
import { observer } from 'mobx-react'
import { successSwal } from '../../settings/MyConnections'
import { useTranslation } from 'react-i18next'

const initialTagValues = {
  name: '',
  color: undefined,
  category: undefined,
  only_staff: false,
}

export const EventsAdmin: React.FC<{ onClick?: any }> = observer(
  ({ onClick }) => {
    const [newTag, setNewTag] = useState<TagInterface | null>(null)
    const [editTag, setEditTag] = useState<TagInterface | null>(null)
    const { t } = useTranslation()
    const {
      fetchAllTagsForEvent,
      allTagsForEvent,
      createTagForEvent,
      archiveTagForEvent,
      editTagForEvent,
    } = useContext(TagStoreContext)
    const eventTypeSelections = useEventTypeSelections()
    console.log('editTag', editTag)
    const colors = [
      // blå
      '#728eae', // mörkare nyans
      '#8ea5be', // ursprunglig färg
      '#aabbce', // ljusare nyans
      '#c7d2df',

      // lila
      '#bd7ec9', // mörkare nyans
      '#ca98d4', // ursprunglig färg
      '#d7b2df', // ljusare nyans
      '#e5cbe9',

      // grön
      '#a0bd82', // mörkare nyans
      '#b3ca9b', // ursprunglig färg
      '#c6d7b4', // ljusare nyans
      '#d9e5cd',

      // röd
      '#c86868', // mörkare nyans
      '#d38686', // ursprunglig färg
      '#dea4a4', // ljusare nyans
      '#e9c3c3',

      // gul
      '#ffee81', // mörkare nyans
      '#fff19a', // ursprunglig färg
      '#fff5b3', // ljusare nyans
      '#fff8cd',

      // rosa
      '#9d8f82', // mörkare nyans
      '#b1a59b', // ursprunglig färg
      '#c4bcb4', // ljusare nyans
      '#d8d2cd',

      // orange
      '#ebaf5b', // mörkare nyans
      '#efbf7c', // ursprunglig färg
      '#f3cf9d', // ljusare nyans
      '#f7dfbd',
    ]

    const { schoolId } = useParams()
    useEffect(() => {
      if (schoolId) {
        fetchAllTagsForEvent([schoolId])
      }
    }, [schoolId])

    const { setTitle } = useContext(NavigationStoreContext)
    useEffect(() => {
      setTitle(t('menu.events'))
    }, [t])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [modalHeaderText, setModalHeaderText] = useState('')

    return (
      <Page naked>
        <ColorCard title={t('menu.events')} bgColor="bg-eventsMain p-4">
          <div className="w-full flex gap-y-4 flex-col">
            {eventTypeSelections.map((eventType) => {
              return (
                <>
                  <StepButton onClick={() => {}}>
                    <div className="flex flex-row justify-between w-full items-center relative">
                      <div className="w-full" onClick={() => {}}>
                        <div className=" flex flex-col gap-y-2">
                          <div className="font-bold flex justify-between">
                            <h2 className="text-xl">{eventType.label}</h2>
                            <Button
                              icon={faPlus}
                              iconColor="white"
                              color="#fff"
                              className="rounded-full"
                              variant="primary"
                              onClick={() => {
                                setNewTag({
                                  ...initialTagValues,
                                  category: eventType.value,
                                })
                              }}
                            />
                          </div>
                          <h4>{t('tags.regularTagsText')}</h4>
                          <div className="flex flex-row">
                            {allTagsForEvent
                              ?.filter(
                                (tag) =>
                                  tag.category === eventType.value &&
                                  !tag.only_staff,
                              )
                              .map((tag) => {
                                return (
                                  <Tag
                                    label={tag.name ?? ''}
                                    color={tag.color}
                                    keyTag={tag.id}
                                    variant="borderColor"
                                    onClick={() => {
                                      setEditTag({
                                        name: tag.name,
                                        color: tag.color,
                                        id: tag.id,
                                        category: tag.category,
                                      })
                                    }}
                                  />
                                )
                              })}
                          </div>
                          <h4>{t('tags.teacherTagsText')}</h4>
                          <div className="flex flex-row">
                            {allTagsForEvent
                              ?.filter(
                                (tag) =>
                                  tag.category === eventType.value &&
                                  tag.only_staff,
                              )
                              .map((tag) => {
                                return (
                                  <Tag
                                    label={tag.name ?? ''}
                                    color={tag.color}
                                    keyTag={tag.id}
                                    variant="borderColor"
                                    onClick={() => {
                                      setEditTag({
                                        name: tag.name,
                                        color: tag.color,
                                        id: tag.id,
                                        category: tag.category,
                                      })
                                    }}
                                  />
                                )
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </StepButton>
                </>
              )
            })}
          </div>
        </ColorCard>
        <ModalRegular
          onClose={() => {
            setNewTag(null)
            setModalHeaderText('')
          }}
          title={t('admin.createTag')}
          bgColor="bg-eventsMain p-4"
          isOpen={newTag ? true : false}
        >
          <div className="flex flex-col relative">
            <div className="p-4 mt-4">
              <div className="flex flex-col mb-4">
                <div className="flex flex-row gap-x-1">
                  <p className="text-red-500">*</p>
                  <p className="text-gray-500">
                    <>{t('admin.createTagInfo')}</>
                  </p>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <input
                  type="text"
                  className=" w-auto p-2 border mb-12 border-gray-300 rounded-2xl text-center  focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                  placeholder={t('admin.writeNamehere')}
                  value={newTag?.name ?? ''}
                  onChange={(e) => {
                    if (newTag) {
                      setNewTag({
                        ...newTag,
                        name: e.target.value,
                      })
                    }
                  }}
                  maxLength={30}
                  minLength={3}
                />
              </div>
              <div className="font-medium text-gray-500 -pl-1 mb-4">
                <>{t('admin.chooseColor')}</>
              </div>
              <div className="flex flex-wrap justify-center gap-2 mb-4">
                {colors.map((color, index) => (
                  <div
                    key={index}
                    style={{ backgroundColor: color }}
                    className="w-8 h-8 hover:scale-[105%] cursor-pointer"
                    onClick={() => {
                      if (newTag) {
                        setNewTag({
                          ...newTag,
                          color: color,
                        })
                      }
                    }}
                  ></div>
                ))}{' '}
              </div>
              <div className="flex flex-col">
                <div className="w-full flex justify-center">
                  <Tag
                    label={newTag?.name ?? t('admin.nameOnTag')}
                    color={newTag?.color}
                    keyTag={'1'}
                    variant="borderColor"
                  />
                </div>
                <div className="w-full flex justify-between mt-2">
                  <div className="flex flex-col">
                    <div className="font-bold">
                      {t('tags.onlyTeacherEventTags')}
                    </div>
                    <div className="text-xs">
                      {t('tags.showOnlyTagsTeacher')}
                    </div>
                  </div>
                  <Slider
                    id={0}
                    onSliderClick={() => {
                      if (newTag) {
                        setNewTag({
                          ...newTag,
                          only_staff: newTag.only_staff ? false : true,
                        })
                      }
                    }}
                    onBoxClick={() => {}}
                    value={newTag?.only_staff}
                  />
                </div>
                <div className="flex justify-end mt-5">
                  {newTag && newTag.name && newTag.color ? (
                    <Button
                      size="sm"
                      variant="fullGreen"
                      onClick={() => {
                        if (schoolId) {
                          createTagForEvent(schoolId, newTag).then(() => {
                            setNewTag(null)
                            fetchAllTagsForEvent([schoolId])
                          })
                        }
                      }}
                      label={t('admin.createTag')}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </ModalRegular>
        <ModalRegular
          isOpen={editTag ? true : false}
          onClose={() => {
            setEditTag(null)
          }}
          bgColor="bg-red-300 p-4"
          closeColor="white"
          title={t('admin.editTagg')}
        >
          <div className="p-4">
            <div className="p-10 flex">
              <div className="flex">
                <div className="flex flex-wrap justify-center gap-2 mb-4">
                  {colors.map((color, index) => (
                    <div className="flex gap-4">
                      <div
                        key={index}
                        style={{ backgroundColor: color }}
                        className="w-8 h-8 hover:scale-[105%] cursor-pointer"
                        onClick={() => {
                          //setSelectedColor(color)
                          if (editTag) {
                            setEditTag({ ...editTag, color: color })
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex w-full justify-center items-center gap-4">
              {editTag && (
                <>
                  <Tag
                    label={editTag.name ?? ''}
                    color={editTag.color}
                    keyTag={editTag.id ?? '1'}
                    variant="borderColor"
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="text-red-600 cursor-pointer"
                    onClick={async () => {
                      Swal.fire({
                        text: t('admin.archiveWarningText'),
                        icon: 'warning',
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          // Call the deleteTag function when "OK" is clicked
                          archiveTagForEvent(editTag)
                            .then(() => {
                              setEditTag(null)
                              successSwal()
                            })
                            .catch((e) => {})
                            .finally(() => {
                              if (schoolId) {
                                fetchAllTagsForEvent([schoolId])
                              }
                            })
                        }
                      })
                    }}
                  />
                </>
              )}
            </div>
            <div className="flex w-full justify-between">
              <Button
                size="sm"
                variant={'default'}
                label={t('general.cancel')}
                onClick={() => {
                  setEditTag(null)
                }}
              />

              <Button
                size="sm"
                variant={'primary'}
                label={t('general.save')}
                onClick={() => {
                  if (editTag) {
                    editTagForEvent(editTag)
                      .then(() => {
                        setEditTag(null)
                        successSwal()
                      })
                      .catch((e) => {})
                      .finally(() => {})
                  }
                  /*Swal.fire({
                    text: t('admin.tagChangeText'),
                    icon: 'success',
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                  }).finally(() => {
                    
                    setEditTag(null)
                  })*/
                }}
              />
            </div>
          </div>
        </ModalRegular>
      </Page>
    )
  },
)
