import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  RefObject,
} from 'react'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import { AbsenceModal, ReRegistration } from './AbsenceModal'
import { UserStoreContext } from '../../store/userStore'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/Button'
import { Route, Routes } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom'
import { KidsStoreContext } from '../../store/kidsStore'
import { DepartmentStoreContext } from '../../store/departmentStore'
import { DepartmentChooser } from '../documents/components/DepartmentChooser'
import {
  faArrowRight,
  faEyeSlash,
  faPlus,
  faTrash,
  faPenToSquare,
  faAddressBook,
  faSquareCheck,
  faCheck,
  faCalendarDays,
  faBell,
  faTrashCan,
  faLock,
  faLockOpen,
  faClose,
} from '@fortawesome/free-solid-svg-icons'
import { getImageOrPlaceHolder } from '../../utils/parseUtils'
import { SearchKids } from '../ourKids/OurKids'
import { AbsenceStoreContext } from '../../store/absenceStore'
import moment from 'moment'
import classNames from 'classnames'
import { LoadingBar } from '../../components/LoadingBar'
import ModalRegular from '../../components/ModalRegular'
import { Department } from '../../Models/Department'
import { Kid } from '../../Models/Kid'
import {
  faArrowTurnDownRight,
  faBadge,
  faBadgeCheck,
  faBadgePercent,
  faEllipsisVertical,
  faUserCheck,
} from '@fortawesome/pro-solid-svg-icons'
import Calendar from 'reactjs-availability-calendar'
import { AbsenceCalendar } from '../../components/AbsenceCalendar'
import ColorCard from '../../components/ColorCard'
import { DateCalendarIcon } from '../../components/DateCalendarIcon'
import { ButtonDropdown } from '../../components/Dropdown'
import { useClickOutside } from '../../hooks/useClickoutside'
import { AbsenceHistoricModal } from './AbsenceHistoricModal'
import Card from '../../components/Card'
import {
  faCalendarCheck,
  faCalendarPlus,
} from '@fortawesome/free-regular-svg-icons'
import { faCalendarPen, faFolderPlus } from '@fortawesome/pro-regular-svg-icons'
import { RandomColors } from '../../components/RandomColors'
import Multiselect from '../../components/Multiselect'
import { DateCalendarIconSmall } from '../../components/DateCalendarIconSmall'
import MultiSelectOpen from '../../components/MultiSelectOpen'
import { Loading } from '../../components/Loading'
import Swal from 'sweetalert2'
import Parse from 'parse'
import {
  NotificationContext,
  NotificationVariant,
} from '../../App/notification/notificationManger'
import { getDateForTranslatation } from '../events/Events'

export const Absence: React.FC = observer(() => {
  const { savedUserRoleId, currentUserRole, fetchUserRole } =
    useContext(UserStoreContext)
  const { t } = useTranslation()

  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    if (savedUserRoleId) {
      fetchUserRole(savedUserRoleId)
    }
  }, [savedUserRoleId])
  useEffect(() => {
    setTitle(t('menu.absence'))
  }, [t])
  return (
    <>
      {currentUserRole?.attributes?.role_type > USER_ROLE_TYPE.PARENT && (
        <>
          <Routes>
            <Route path={'/'} element={<AbsencePersonal />}></Route>
            <Route path={'/add'} element={<AbsencePersonalAdd />}></Route>
            <Route path={'/add/kid/:kidId'} element={<Absences />}></Route>
          </Routes>
        </>
      )}

      {currentUserRole?.attributes?.role_type === USER_ROLE_TYPE.PARENT && (
        <Absences />
      )}
    </>
  )
})

const AbsencePersonalAdd: React.FC = observer(() => {
  const { t } = useTranslation()
  /*const [search, setSearch] = useState('')*/
  const { currentUserRole } = useContext(UserStoreContext)
  const { fetchKids, kids, setSearch, search } = useContext(KidsStoreContext)
  const [loading, setLoading] = useState(false)
  const {
    departments,
    fetchDepartments,
    selectedDepartment,
    setSelectedDepartments,
    selectedDepartments,
    fetchDepartmentsFromUserRole,
  } = useContext(DepartmentStoreContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUserRole && currentUserRole?.school_pointer) {
      setLoading(true)
      fetchKids(currentUserRole)
      fetchDepartmentsFromUserRole(currentUserRole).finally(() => {
        setLoading(false)
      })
    }
  }, [currentUserRole?.id])

  return (
    <Page
      loading={loading}
      clearFilter={() => {
        setSelectedDepartments([])
        if (currentUserRole && currentUserRole?.school_pointer) {
          fetchKids(currentUserRole)
        }
      }}
      naked
      filterCount={
        (selectedDepartments?.length ? selectedDepartments.length : 0) +
        (search ? 1 : 0)
      }
      filter={
        <div className="space-y-2">
          <div className="w-full flex justify-center space-x-2  ">
            <MultiSelectOpen
              onChange={(e) => {
                setSelectedDepartments(e)
                if (currentUserRole && currentUserRole?.school_pointer) {
                  fetchKids(currentUserRole)
                }
              }}
              items={departments as Department[]}
              initialSelectedItems={selectedDepartments}
              itemVariableKey={'klassName'}
              placeholder={t('general.department')}
            />

            {/*<Button size="md" label={t('general.save')} onClick={() => {}} />*/}
          </div>
        </div>
      }
      searchFilter={
        <SearchKids
          onSearch={(s: string) => {
            setSearch(s)
          }}
          initialValue={search}
        />
      }
      topBar={
        <>
          {/*<SearchKids
            onSearch={(s: string) => {
              setSearch(s)
            }}
          />
          <DepartmentChooser />*/}
        </>
      }
    >
      {loading && <Loading />}
      {/*loading && <Loading />*/}
      <div className={'flex flex-col justify-center w-full'}>
        <div className={'flex justify-center w-full flex-col md:space-y-10'}>
          <Kids
            kids={kids ?? []}
            departments={departments ?? []}
            selectedDepartments={selectedDepartments ?? []}
            search={search ?? ''}
            onKidKlick={(kid: Kid) => navigate('kid/' + kid.id)}
          />
        </div>
      </div>
    </Page>
  )
})

const Kids = ({
  departments,
  kids,
  onKidKlick,
  selectedDepartments,
  search,
}: {
  departments: Department[]
  kids: Kid[]
  selectedDepartments: Department[]
  onKidKlick: any
  search: string
}) => {
  return (
    <Fragment>
      {departments
        ?.filter((dep) => {
          if (!selectedDepartments.length) {
            return true
          }

          return selectedDepartments.find((selDep) => selDep.id === dep.id)
        })
        .map((department, index) => {
          return (
            <ColorCard
              title={department?.attributes?.klassName}
              bgColor={
                department.get('departmentColor')
                  ? 'bg-[' + department.get('departmentColor') + ']'
                  : RandomColors[index]
              }
            >
              <div
                className={
                  'flex flex-wrap max-w-7xl font-medium justify-center'
                }
              >
                <div className={'flex flex-col '} key={department?.id}>
                  <div
                    className={'flex flex-wrap max-w-7xl py-3 justify-center'}
                  >
                    {kids
                      ?.filter((k) => {
                        if (search.length) {
                          return (
                            k?.attributes?.departmentPointer?.id ===
                              department?.id &&
                            k?.attributes?.kidName
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                          )
                        }
                        return (
                          k?.attributes?.departmentPointer?.id ===
                          department?.id
                        )
                      })
                      .map((kid) => {
                        return (
                          <div
                            key={kid?.id}
                            onClick={() => {
                              onKidKlick(kid)
                            }}
                            className={
                              'flex flex-col space-y-2 items-center justify-center p-3 rounded-2xl hover:shadow-md hover:bg-gray-50 cursor-pointer relative'
                            }
                          >
                            <div className=" relative hover:scale-105">
                              {kid?.attributes?.hidden_in_dep_list && (
                                <FontAwesomeIcon
                                  className="absolute -top-1 -right-1 text-eventsMain"
                                  icon={faEyeSlash}
                                  size={'1x'}
                                />
                              )}
                              <img
                                className={
                                  'w-[80px] h-[80px] rounded-full object-cover shadow  '
                                }
                                src={getImageOrPlaceHolder(
                                  kid?.attributes?.kidImage?._url,
                                )}
                                alt=""
                              />

                              {kid?.attributes?.absenceLog?.attributes
                                ?.confirmed && (
                                <div className="h-[22px] w-[22px] flex-none border-2 rounded-full absolute -top-[2px]  left-[4px] border-white bg-green-600">
                                  <FontAwesomeIcon
                                    icon={faLock}
                                    className="relative bottom-[3px] left-[3.5px] text-white"
                                    size="xs"
                                  />
                                </div>
                              )}
                            </div>
                            <p>
                              {kid?.attributes?.kidSick &&
                              kid?.attributes?.absenceLog?.attributes
                                ?.endDate >= moment().format('YYYY-MM-DD') ? (
                                <div className="text-red-600">
                                  {kid?.attributes?.kidName}
                                </div>
                              ) : (
                                <>{kid?.attributes?.kidName}</>
                              )}
                            </p>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </ColorCard>
          )
        })}
    </Fragment>
  )
}

const AbsencePersonal: React.FC = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    departments,
    selectedDepartment,
    selectedDepartments,
    setSelectedDepartments,
    fetchDepartmentsFromUserRole,
  } = useContext(DepartmentStoreContext)
  const { fetchAbsenceKids, absencesKids } = useContext(AbsenceStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  const [selected, setSelected] = useState<any>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (currentUserRole) {
      setLoading(true)
      fetchAbsenceKids(currentUserRole?.attributes?.school_pointer?.id)
      fetchDepartmentsFromUserRole(currentUserRole).finally(() => {
        setLoading(false)
      })
    }
  }, [currentUserRole?.id])

  return (
    <Page
      loading={loading}
      naked
      clearFilter={() => {
        setSelectedDepartments([])
        fetchAbsenceKids(currentUserRole?.attributes?.school_pointer?.id)
      }}
      filterCount={selectedDepartments?.length ? selectedDepartments.length : 0}
      filter={
        <>
          <div className="flex w-full justify-center">
            <MultiSelectOpen
              onChange={(e) => {
                setSelectedDepartments(e)
                fetchAbsenceKids(
                  currentUserRole?.attributes?.school_pointer?.id,
                )
              }}
              items={departments as Department[]}
              initialSelectedItems={selectedDepartments}
              itemVariableKey={'klassName'}
              placeholder={t('general.department')}
            />
          </div>
          {/*<div className="flex w-full justify-end">
            <Button size="lg" label={t('general.save')} onClick={() => {}} />
            </div>*/}
        </>
      }
      topBar={
        <>
          {/*<DepartmentChooser />*/}
          <Button
            size="md"
            variant={'blue'}
            label={t('absence.chooseKid')}
            onClick={() => {
              navigate('add')
            }}
          />
        </>
      }
    >
      {loading && <Loading />}
      <div className={'flex justify-center w-full'}>
        <div className={'flex w-full max-w-7xl flex-col '}>
          {/*<p
          className={
            'py-3 text-center w-full max-w-7xl mt-3 text-4xl text-gray-500 font-bold md:mb-16'
          }
        >
          {t('absence.absence')}
        </p>*/}

          <div className={'flex flex-col space-y-10 md:space-y-16 px-3'}>
            {departments
              ?.filter((dep) => {
                if (!selectedDepartments?.length) {
                  return true
                }

                return selectedDepartments?.find(
                  (selDep) => selDep.id === dep.id,
                )
              })
              .map((department, index) => {
                return (
                  <ColorCard
                    title={department?.attributes?.klassName}
                    bgColor={
                      department.get('departmentColor')
                        ? 'bg-[' + department.get('departmentColor') + ']'
                        : RandomColors[index]
                    }
                  >
                    <div
                      className={'flex flex-col md:mt-6 md:ml-4'}
                      key={department?.id}
                    >
                      <div
                        className={
                          'flex flex-wrap gap-4 justify-center md:justify-start md:ml-3 mb-4'
                        }
                      >
                        {absencesKids?.kids
                          ?.filter((k: Kid) => {
                            return (
                              k?.attributes?.departmentPointer?.id ===
                              department?.id
                            )
                          })
                          .sort((a: Kid, b: Kid) => {
                            const dateA = new Date(
                              a.attributes.BackFrSicknessDate,
                            ).getTime()
                            const dateB = new Date(
                              b.attributes.BackFrSicknessDate,
                            ).getTime()

                            return dateA - dateB
                          })
                          .map((kid: Kid) => {
                            return (
                              <div
                                key={kid?.id}
                                className={
                                  'flex flex-row space-x-4 items-center font-bold font-sans text-neutral-600  p-1 pl-2 cursor-pointer hover:scale-[102%] relative border-b-2 rounded-2xl  shadow-tyraShadow '
                                }
                                onClick={() => {
                                  setSelected(kid?.attributes?.absenceLog)
                                  navigate('add/kid/' + kid.id)
                                }}
                              >
                                <img
                                  className={
                                    'w-[70px] h-[70px] rounded-full object-cover '
                                  }
                                  src={getImageOrPlaceHolder(
                                    kid.attributes?.kidImage?._url,
                                  )}
                                  alt=""
                                />
                                <div className="flex items-center flex-row space-x-6">
                                  <div>
                                    <p>{kid?.attributes?.kidName}</p>
                                    <p
                                      className={
                                        'text-red-500 text-sm font-medium'
                                      }
                                    >
                                      {moment(
                                        kid?.attributes.absenceLog?.attributes
                                          ?.startDate,
                                      ).format('D MMM yyyy')}
                                    </p>
                                    <p
                                      className={
                                        'text-blue-500 text-sm font-medium'
                                      }
                                    >
                                      {moment(
                                        kid?.attributes.absenceLog?.attributes
                                          ?.endDate,
                                      ).format('D MMM yyyy')}
                                    </p>
                                  </div>
                                  <div className="relative top-2 ">
                                    <DateCalendarIconSmall
                                      day={kid?.get('BackFrSicknessDate')}
                                    />
                                    {kid?.attributes.absenceLog?.attributes
                                      .confirmed && (
                                      <div className="h-[22px] w-[22px] flex-none border-2 rounded-full absolute top-[2px] right-4 border-white bg-green-600">
                                        <FontAwesomeIcon
                                          icon={faLock}
                                          className="relative bottom-[3px] left-[3.5px] text-white"
                                          size="xs"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                      </div>
                      {!absencesKids?.kids?.filter((k: Kid) => {
                        return (
                          k?.attributes?.departmentPointer?.id ===
                          department?.id
                        )
                      }).length && (
                        <div
                          className={
                            'flex flex-col text-center justify-center items-center space-y-4 text-lg sm:text-2xl text-gray-300 font-bold'
                          }
                        >
                          <p className="flex items-center mt-16 mb-24">
                            {t('absence.empty')}
                          </p>
                        </div>
                      )}
                    </div>
                  </ColorCard>
                )
              })}
          </div>
        </div>

        <ModalRegular
          title={''}
          className={'max-w-xl '}
          isOpen={selected ? true : false}
          onClose={() => {
            setSelected(null)
          }}
        >
          <div className={'pt-1'}>
            {selected && (
              <p>
                {selected?.attributes?.kid?.firstName} Beräknas åter:{' '}
                {moment(selected?.attributes?.endDate).format('DD/M')}
              </p>
            )}
          </div>
        </ModalRegular>
      </div>
    </Page>
  )
})

const Absences: React.FC = observer(() => {
  const { t } = useTranslation()
  const {
    fetchAbsences,
    setAbsences,
    absences,
    removeAbsence,
    kid,
    setKid,
    fetchKid,
    absencePutToHistory,
    reportBack,
  } = useContext(AbsenceStoreContext)
  const [showModal, setShowModal] = useState(false)
  const [showHistoricModal, setShowHistoricModal] = useState(false)
  const [showRegistrationModal, setShowRegistrationModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedAbsence, setSelectedAbsence] = useState<any>()
  const { currentUserRole } = useContext(UserStoreContext)
  const { kidId } = useParams()
  const [showCalender, setShowCalender] = useState<boolean>(false)
  const [kidAbsences, setKidAbsences] = useState<[] | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [menuRef, togglerRef] = useClickOutside(() => setIsOpen(false))
  const [currentAbsenceMeny, setCurrentAbsenceMeny] = useState(null)
  const [currentDate, setCurrentDate] = useState(moment())
  const [lastMonthDate, setLastMonthDate] = useState(
    moment().subtract(1, 'months'),
  )
  const [showReturnModal, setShowReturnModal] = useState<string | null>(null)
  const [nextMonthDate, setNextMonthDate] = useState(moment().add(1, 'months'))
  const { notify } = useContext(NotificationContext)
  const [selectedDate, setSelectedDate] = useState(moment())
  const isAdmin = currentUserRole?.attributes?.role_type >= USER_ROLE_TYPE.ADMIN
  const isTeacher =
    currentUserRole?.attributes?.role_type > USER_ROLE_TYPE.PARENT
  const isParent =
    currentUserRole?.attributes?.role_type == USER_ROLE_TYPE.PARENT

  const allowLock = currentUserRole?.school_pointer?.attributes?.school_setting
    ?.attributes?.absence_no_locking
    ? false
    : true

  const allowRegisterReturnDate =
    currentUserRole?.school_pointer?.attributes?.school_setting?.attributes
      ?.absence_has_report_back

  const bookings = [
    {
      from: new Date('2022-11-01'),
      to: new Date('2022-11-04'),
      middayCheckout: false,
    },
    {
      from: '2022-11-08',
      to: '2022-11-12',
      middayCheckout: false,
    },
  ]

  useEffect(() => {
    const absenceRow: any = []
    setLoading(true)
    fetchAbsences(
      currentUserRole?.attributes?.school_pointer?.id,
      kidId ? kidId : currentUserRole?.attributes?.kid?.id,
    ).finally(() => {
      if (absences?.length) {
        absences.map((absence) => {
          absenceRow.push({
            from: new Date(absence.startDate),
            to: new Date(absence.endDate),
            middayCheckout: true,
          })
        })
      }
      setKidAbsences(absenceRow)
      setLoading(false)
    })

    return () => {
      setAbsences([])
      setKid()
    }
  }, [currentUserRole?.id])

  useEffect(() => {
    fetchKid(kidId ? kidId : currentUserRole?.attributes?.kid?.id)
  }, [kidId])
  if (loading || !kid) {
    return <Loading />
  }
  return (
    <Page
      loading={loading}
      naked
      topBar={
        <div className="flex sm:justify-end w-full justify-center ">
          <Button
            size="md"
            variant={kid?.get('absenceLog') ? 'disableRed' : 'red'}
            label={t('absence.newAbsence')}
            //icon={faPlus}
            onClick={() => {
              if (kid?.get('absenceLog')) {
                Swal.fire({
                  //title: 'Are you sure?',
                  text: t('absence.alarmAbsenceCreate'),
                  icon: 'warning',
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.ok'),
                })
              } else {
                setSelectedAbsence(null)
                setShowModal(true)
              }
            }}
          />
          <AbsenceModal
            showModal={showModal}
            initialAbsence={selectedAbsence}
            kidId={kidId}
            onClose={() => {
              setSelectedAbsence(null)
              setShowModal(false)
            }}
          />
          <AbsenceHistoricModal
            showModal={showHistoricModal}
            initialAbsence={selectedAbsence}
            kidId={kidId}
            onClose={() => {
              setSelectedAbsence(null)
              setShowHistoricModal(false)
            }}
            absences={absences}
          />
          <ReRegistration
            showModal={showRegistrationModal}
            initialAbsence={selectedAbsence}
            kidId={kidId}
            onClose={() => {
              setSelectedAbsence(null)
              setShowRegistrationModal(false)
            }}
          />
        </div>
      }
    >
      <div className={'flex justify-center w-full '}>
        <div className={'flex w-full max-w-7xl flex-col items-center'}>
          <div className="shadow-tyraShadow rounded-2xl bg-white flex w-full max-w-7xl flex-col items-center sm:mt-6 lg:mt-0">
            {/*loading && <Loading />*/}

            {kid && (
              <div
                className={
                  'flex flex-row items-center w-full py-1 bg-eventsMain border-gray-100 rounded-t-2xl'
                }
              >
                <img
                  className={
                    'w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] rounded-full m-1 mx-4'
                  }
                  src={getImageOrPlaceHolder(kid?.attributes?.kidImage?._url)}
                  alt=""
                />
                <p className={'text-white sm:text-3xl w-full text-2xl'}>
                  {kid?.attributes?.firstName} {kid?.attributes?.lastName}
                </p>
                <div className="flex justify-end w-full">
                  <FontAwesomeIcon
                    className="flex justify-end mr-6 text-white transition duration-250 cursor-pointer w-fit sm:w-6 sm:h-6 hover:scale-[110%]"
                    icon={faCalendarDays}
                    onClick={() => {
                      setShowCalender(true)
                    }}
                  />
                </div>
              </div>
            )}
            {!kid?.get('absenceLog') && (
              <div
                className={
                  'flex flex-col text-center justify-center  items-center space-y-4 text-4xl text-gray-300 font-bold'
                }
              >
                <div className="mt-20 mb-20 ">
                  {kid?.attributes?.kidName + ' '}
                  {t('absence.activeAbsence')}
                </div>
              </div>
            )}
            {/* CURRENT ABSENCE STARTS */}
            {absences
              ?.filter((ab) => ab.id == kid?.get('absenceLog')?.id)
              .map((absence, index) => {
                const canChangeAdmin = isAdmin
                let canChangeActiveAbsence = false
                if (absence?.attributes?.confirmed) {
                  canChangeActiveAbsence = canChangeAdmin ? true : false
                } else {
                  canChangeActiveAbsence =
                    kid?.get('absenceLog')?.id == absence.id
                }

                return (
                  <>
                    <div
                      className={classNames(
                        ' flex flex-wrap sm:flex-col relative my-6 w-full py-6 px-4 sm:px-12 justify-center',
                      )}
                      key={absence?.id}
                    >
                      <div className="flex flex-row absolute top-0 right-4">
                        <div className="relative flex">
                          {canChangeActiveAbsence && (
                            <button
                              ref={togglerRef}
                              type="button"
                              className="w-full rounded-md group focus:outline-none"
                              id={'options-menu' + absence.id}
                              aria-haspopup="true"
                              aria-expanded="true"
                              onClick={() => {
                                setCurrentAbsenceMeny(absence.id)
                                setIsOpen((prev) => !prev)
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEllipsisVertical}
                                className=" text-gray-400 absolute top-2 right-2"
                              ></FontAwesomeIcon>
                            </button>
                          )}

                          {isOpen && currentAbsenceMeny == absence.id && (
                            <div
                              ref={menuRef as RefObject<HTMLDivElement>}
                              className="absolute right-2 z-50 mx-3 origin-top divide-y w-44 top-1"
                              role="menu"
                              aria-orientation="vertical"
                              aria-labelledby={'options-menu' + absence.id}
                              key={absence.id}
                            >
                              <ButtonDropdown.Menu>
                                {moment(absence.attributes.endDate).format(
                                  'YYYY-MM-DD',
                                ) < moment().format('YYYY-MM-DD') ? (
                                  <ButtonDropdown.Section>
                                    <ButtonDropdown.Button
                                      icon={faArrowTurnDownRight}
                                      label={t('absence.moveToHistory')}
                                      onClick={async (e) => {
                                        e.stopPropagation()
                                        Swal.fire({
                                          //title: 'Are you sure?',
                                          text: t(
                                            'absence.absence_this_will_move_this_absence_report_to_history',
                                          ),
                                          icon: 'warning',
                                          showCancelButton: true,
                                          confirmButtonColor: '#7eb5f4',
                                          cancelButtonColor: '#d33',
                                          confirmButtonText: t('general.yes'),
                                          cancelButtonText: t('general.cancel'),
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            absencePutToHistory(
                                              absence.id,
                                            ).then(() => {
                                              fetchAbsences(
                                                currentUserRole?.attributes
                                                  ?.school_pointer?.id,
                                                kidId,
                                              )
                                              fetchKid(
                                                kidId
                                                  ? kidId
                                                  : currentUserRole?.attributes
                                                      ?.kid?.id,
                                              )
                                            })
                                          }
                                        })
                                      }}
                                    />
                                  </ButtonDropdown.Section>
                                ) : null}
                                {canChangeActiveAbsence && (
                                  <>
                                    <ButtonDropdown.Section>
                                      <ButtonDropdown.Button
                                        icon={faTrashCan}
                                        className="text-red-700"
                                        label={t('absence.delete')}
                                        onClick={async (e) => {
                                          e.stopPropagation()

                                          Swal.fire({
                                            //title: 'Are you sure?',
                                            text: t('absence.noticAreYouSure'),
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#7eb5f4',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: t('general.yes'),
                                            cancelButtonText:
                                              t('general.cancel'),
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              removeAbsence(absence).then(
                                                () => {
                                                  fetchAbsences(
                                                    currentUserRole?.attributes
                                                      ?.school_pointer?.id,
                                                    kidId,
                                                  )
                                                },
                                              )
                                            }
                                          })
                                        }}
                                      />
                                    </ButtonDropdown.Section>
                                  </>
                                )}
                              </ButtonDropdown.Menu>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={'flex flex-col md:flex-row'}>
                        <div
                          className={
                            'flex items-center space-x-3 sm:space-x-16'
                          }
                        >
                          <DateCalendarIcon
                            day={kid?.get('BackFrSicknessDate')}
                          />
                          {/*} <div
                          className={
                          'h-[90px] w-[90px] lg:h-[100px] lg:w-[100px] rounded-full bg-eventsMain shadow-lg flex flex-wrap flex-col items-center justify-center mr-6 mb-4'
                          }
                          >
                          <p
                          className={'text-white text-xs sm:text-sm font-bold'}
                          >
                          {t('absence.backFrSickness')}
                          </p>
                          <p className={'font-medium text-white text-lg'}>
                          {moment(kid?.get('BackFrSicknessDate')).format('DD')}
                          </p>
                          <p
                          className={'text-white text-xs sm:text-sm font-bold'}
                          >
                          {moment(absence?.attributes?.createdAt).format(
                            'MMMM',
                          )}
                          </p>
                          </div>*/}
                        </div>

                        <div className="flex flex-col flex-1 justify-center">
                          <p className={'text-eventsMain font-medium'}>
                            {t('absence.absence')} (
                            {moment(absence?.attributes?.endDate).diff(
                              moment(absence?.attributes?.startDate),
                              'days',
                            ) + 1}{' '}
                            {moment(absence?.attributes?.endDate).diff(
                              moment(absence?.attributes?.startDate),
                              'days',
                            ) +
                              1 >
                            1
                              ? t('absence.absenceDays')
                              : t('absence.absenceDay')}
                            )
                          </p>
                          <div className="flex flex-col ">
                            <p className={'font-bold text-neutral-500 text-lg'}>
                              <div>
                                <span className="">
                                  {moment(
                                    absence?.attributes?.startDate,
                                  ).format('D ')}
                                  {getDateForTranslatation(
                                    moment(
                                      absence?.attributes?.startDate,
                                    ).format('MM'),
                                  )}
                                  {moment(
                                    absence?.attributes?.startDate,
                                  ).format(' YYYY')}
                                </span>

                                <span className="mx-2 text-gray-700  ">
                                  <FontAwesomeIcon icon={faArrowRight} />
                                </span>
                                <span>
                                  {moment(absence?.attributes?.endDate).format(
                                    'D ',
                                  )}
                                  {getDateForTranslatation(
                                    moment(absence?.attributes?.endDate).format(
                                      'MM',
                                    ),
                                  )}
                                  {moment(absence?.attributes?.endDate).format(
                                    ' YYYY',
                                  )}
                                </span>
                              </div>
                            </p>

                            <span className={' text-xs text-gray-500 italic'}>
                              <p>
                                {new Date(absence?.attributes?.updatedAt) >
                                new Date(absence?.attributes?.createdAt) ? (
                                  <FontAwesomeIcon
                                    className="text-yellow-500"
                                    icon={faCalendarPen}
                                  ></FontAwesomeIcon>
                                ) : (
                                  <FontAwesomeIcon
                                    className=" text-blue-500"
                                    icon={faCalendarPlus}
                                  ></FontAwesomeIcon>
                                )}{' '}
                                {absence?.attributes?.user?.attributes
                                  ?.firstName ? (
                                  absence?.attributes?.user?.attributes
                                    ?.firstName +
                                  ' ' +
                                  absence?.attributes?.user?.attributes
                                    ?.lastName
                                ) : (
                                  <> {t('general.unknown')}</>
                                )}
                                {absence?.attributes?.updatedAt ==
                                absence?.attributes?.createdAt ? (
                                  <>
                                    {' '}
                                    {moment(
                                      absence?.attributes?.createdAt,
                                    ).format('YYYY-MM-DD')}
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    {moment(
                                      absence?.attributes?.updatedAt,
                                    ).format('YYYY-MM-DD')}
                                  </>
                                )}
                              </p>
                            </span>
                            {absence?.attributes?.confirmed && (
                              <p>
                                <span
                                  className={' text-xs text-gray-500 italic'}
                                >
                                  <FontAwesomeIcon
                                    className=" text-green-500"
                                    icon={faCalendarCheck}
                                  ></FontAwesomeIcon>{' '}
                                  {absence?.attributes?.confirmedBy?.attributes
                                    ?.firstName ? (
                                    absence?.attributes?.confirmedBy?.attributes
                                      ?.firstName +
                                    ' ' +
                                    absence?.attributes?.confirmedBy?.attributes
                                      ?.lastName
                                  ) : (
                                    <> {t('general.unknown')}</>
                                  )}
                                  <>
                                    {' '}
                                    {moment(
                                      absence?.attributes?.confirmedAt,
                                    ).format('YYYY-MM-DD')}
                                  </>
                                </span>
                              </p>
                            )}
                          </div>
                        </div>
                        <div className={'flex flex-wrap sm:mt-2 mt-4 gap-y-2'}>
                          {isParent && allowRegisterReturnDate && (
                            <div className="flex flex-col items-start sm:items-center  sm:mt-6 mx-2 justify-center w-full sm:w-fit">
                              <Button
                                icon={faUserCheck}
                                className="h-[60px] w-[60px] lg:h-[70px] lg:w-[70px]"
                                variant={'roundBlue'}
                                onClick={() => {
                                  if (
                                    moment(absence.attributes.startDate).format(
                                      'YYYY-MM-DD',
                                    ) > moment().format('YYYY-MM-DD')
                                  ) {
                                    Swal.fire({
                                      title: t('general.sorry'),
                                      text: t('absence.alarmRe-registration'),
                                      icon: 'warning',
                                      showCancelButton: false,
                                      focusConfirm: false,
                                      confirmButtonColor: '#7eb5f4',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: t('general.ok'),
                                    })
                                  } else {
                                    if (absence) {
                                      setShowReturnModal(absence.id)
                                    }
                                  }
                                }}
                              />
                              <h2 className="mt-2 font-medium text-xs sm:text-sm text-center">
                                {t('absence.registerReturnDate')}
                              </h2>
                            </div>
                          )}

                          {isTeacher && !absence.get('confirmed') && (
                            <div className="flex flex-col items-start sm:items-center text-center sm:mt-6 justify-center w-full sm:w-fit">
                              <Button
                                icon={faBell}
                                className={classNames(
                                  'h-[60px] w-[60px] lg:h-[70px] lg:w-[70px]',
                                  absence.get('reminder_sent') ==
                                    moment().format('YYYY-MM-DD') &&
                                    'opacity-40 !cursor-not-allowed',
                                )}
                                variant={'roundBlue'}
                                onClick={async () => {
                                  if (
                                    absence.get('reminder_sent') !==
                                    moment().format('YYYY-MM-DD')
                                  ) {
                                    await Parse.Cloud.run(
                                      'sendAbsenceReminder',
                                      {
                                        kidId: kidId,
                                        absenceId: absence.id,
                                      },
                                    )
                                      .catch((e) => {
                                        throw e
                                      })
                                      .then(() => {
                                        /*notify({
                                          variant: NotificationVariant.OK,
                                          title: '',
                                          message: t('general.reminderSent'),
                                        })*/
                                        Swal.fire({
                                          text: t('general.reminderSent'),
                                          icon: 'success',
                                          showCancelButton: false,
                                          focusConfirm: false,
                                          confirmButtonColor: '#7eb5f4',
                                          cancelButtonColor: '#d33',
                                          confirmButtonText: t('general.ok'),
                                        })
                                        fetchAbsences(
                                          currentUserRole?.attributes
                                            ?.school_pointer?.id,
                                          kidId,
                                        )
                                        fetchKid(
                                          kidId
                                            ? kidId
                                            : currentUserRole?.attributes?.kid
                                                ?.id,
                                        )
                                      })
                                      .finally(() => {})
                                    /*Swal.fire({
                                    //title: 'Are you sure?',
                                    text: t('events.eventAlarmNotImp'),
                                    icon: 'error',
                                    showCancelButton: false,
                                    focusConfirm: false,
                                    confirmButtonColor: '#7eb5f4',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: t('general.ok'),
                                  })*/
                                  } else {
                                    Swal.fire({
                                      //title: 'Are you sure?',
                                      text: t('absence.alarmNotice'),
                                      icon: 'warning',
                                      showCancelButton: false,
                                      focusConfirm: false,
                                      confirmButtonColor: '#7eb5f4',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: t('general.ok'),
                                    })
                                  }
                                }}
                              />
                              <h2 className="mt-2 font-medium text-xs sm:text-sm text-center">
                                {t('absence.sendNotice')}
                              </h2>
                            </div>
                          )}

                          <div className="flex flex-col sm:pl-3 sm:pr-8 items-start sm:items-center text-center sm:mt-6 justify-center w-full sm:w-fit ">
                            <Button
                              icon={faPenToSquare}
                              className="h-[60px] w-[60px] lg:h-[70px] lg:w-[70px]"
                              variant={'roundOrange'}
                              onClick={() => {
                                if (absence.get('confirmed') !== true) {
                                  setSelectedAbsence(absence)
                                  setShowModal(true)
                                } else {
                                  if (isParent) {
                                    Swal.fire({
                                      //title: 'Are you sure?',
                                      text: t(
                                        'absence.confirmed_absence_can_not_be_edited_guardian_etc_guardian',
                                      ),
                                      icon: 'warning',
                                      showCancelButton: false,
                                      confirmButtonColor: '#7eb5f4',
                                      focusConfirm: false,
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: t('general.ok'),
                                    })
                                  } else {
                                    Swal.fire({
                                      //title: 'Are you sure?',
                                      text: t('absence.alarmEditLocked'),
                                      icon: 'warning',
                                      showCancelButton: false,
                                      focusConfirm: false,
                                      confirmButtonColor: '#7eb5f4',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: t('general.ok'),
                                    })
                                  }
                                }
                              }}
                            />
                            <h2 className="mt-2 font-medium text-center text-xs sm:text-sm ">
                              {t('absence.changeNotification')}
                            </h2>
                          </div>

                          <>
                            {allowLock && (
                              <>
                                {absence.get('confirmed') == true ? (
                                  <div className="flex flex-col items-start sm:items-center text-center sm:mt-6 mx-2">
                                    <Button
                                      icon={faLock}
                                      className=" rounded-full h-[60px] w-[60px] lg:h-[70px] lg:w-[70px]"
                                      variant={'roundGreen'}
                                      onClick={() => {
                                        if (isAdmin || isTeacher) {
                                          setSelectedAbsence(absence)
                                          setShowRegistrationModal(true)
                                        } else {
                                          /* VAD ÄR DETTA???
                                        ;<ModalRegular
                                          children={undefined}
                                          isOpen={false}
                                          onClose={undefined}
                                        ></ModalRegular>*/
                                          Swal.fire({
                                            //title: 'Are you sure?',
                                            text: t(
                                              'absence.undo_confirmation_information_guardian',
                                            ),
                                            icon: 'warning',
                                            showCancelButton: false,
                                            focusConfirm: false,
                                            confirmButtonColor: '#7eb5f4',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: t('general.ok'),
                                          })
                                        }
                                      }}
                                    />
                                    <h2 className="mt-2 font-medium text-xs sm:text-sm text-center">
                                      {/*t('absence.reRegistrationConfirm')*/}
                                      {t('absence.dateConfirm')}
                                    </h2>
                                  </div>
                                ) : (
                                  <div className="flex flex-col items-start sm:items-center text-center sm:mt-6 justify-center w-full sm:w-fit">
                                    <Button
                                      icon={faLock}
                                      className=" rounded-full h-[60px] w-[60px] lg:h-[70px] lg:w-[70px]"
                                      variant={'roundWhiteCircleGreen'}
                                      onClick={() => {
                                        //if (canChangeAdmin) {
                                        setSelectedAbsence(absence)
                                        setShowRegistrationModal(true)
                                        //}
                                      }}
                                    />
                                    <h2 className="mt-2 font-medium text-xs sm:text-sm text-center">
                                      {/*t('absence.confirmReRegistration')*/}
                                      {t('absence.confirmDate')}
                                    </h2>
                                  </div>
                                )}
                              </>
                            )}
                          </>

                          {/*<div className="relative flex items-end">
                            <button
                              ref={togglerRef}
                              type="button"
                              className="w-full rounded-md group focus:outline-none"
                              id={'options-menu' + absence.id}
                              aria-haspopup="true"
                              aria-expanded="true"
                              onClick={() => {
                                setCurrentAbsenceMeny(absence.id)
                                setIsOpen((prev) => !prev)
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEllipsisVertical}
                                className=" text-gray-400 absolute bottom-36 right-1"
                              ></FontAwesomeIcon>
                            </button>

                            {isOpen && currentAbsenceMeny == absence.id && (
                              <div
                                ref={menuRef as RefObject<HTMLDivElement>}
                                className="absolute right-0 z-50 mx-3 origin-top divide-y w-44 top-0"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby={'options-menu' + absence.id}
                                key={absence.id}
                              >
                                <ButtonDropdown.Menu>
                                  {moment(absence.attributes.endDate).format(
                                    'YYYY-MM-DD',
                                  ) < moment().format('YYYY-MM-DD') &&
                                  absence.get('confirmed') ? (
                                    <ButtonDropdown.Section>
                                      <ButtonDropdown.Button
                                        icon={faArrowTurnDownRight}
                                        label={t('absence.moveToHistory')}
                                        onClick={async (e) => {
                                          e.stopPropagation()
                                          if (
                                            window.confirm(
                                              '!Är du säker på att du vill flytta denna frånvaro till historiken?',
                                            )
                                          ) {
                                            absencePutToHistory(
                                              absence.id,
                                            ).then(() => {
                                              fetchAbsences(
                                                currentUserRole?.attributes
                                                  ?.school_pointer?.id,
                                                kidId,
                                              )
                                            })
                                          }
                                        }}
                                      />
                                    </ButtonDropdown.Section>
                                  ) : null}
                                  {canChangeActiveAbsence &&
                                    absence.get('confirmed') !== true && (
                                      <ButtonDropdown.Section>
                                        <ButtonDropdown.Button
                                          icon={faPenToSquare}
                                          label={t(
                                            'absence.changeNotification',
                                          )}
                                          onClick={() => {
                                            if (
                                              canChangeActiveAbsence ||
                                              canChangeAdmin
                                            ) {
                                              setSelectedAbsence(absence)
                                              setShowModal(true)
                                            }
                                          }}
                                        />
                                      </ButtonDropdown.Section>
                                    )}
                                  {canChangeActiveAbsence && (
                                    <ButtonDropdown.Section>
                                      <ButtonDropdown.Button
                                        icon={faTrashCan}
                                        label={t('absence.delete')}
                                        onClick={async (e) => {
                                          e.stopPropagation()
                                          if (
                                            window.confirm(
                                              t('absence.noticAreYouSure'),
                                            )
                                          ) {
                                            removeAbsence(absence).then(() => {
                                              fetchAbsences(
                                                currentUserRole?.attributes
                                                  ?.school_pointer?.id,
                                                kidId,
                                              )
                                            })
                                          }
                                        }}
                                      />
                                    </ButtonDropdown.Section>
                                  )}
                                </ButtonDropdown.Menu>
                              </div>
                            )}
                          </div>*/}
                        </div>
                      </div>
                      {absence?.attributes?.info && (
                        <p
                          className={
                            'pb-2 pt-10 pl-2 w-full mt-8 md:mt-2 text-gray-500'
                          }
                        >
                          {absence?.attributes?.info}
                        </p>
                      )}
                    </div>
                    <hr className="w-full" />
                    <ModalRegular
                      //bgColor="bg-red-500 p-6"
                      //title={t('absence.absenceOverview')}
                      //className={'text-black'}
                      isOpen={showReturnModal ? true : false}
                      onClose={() => {
                        setShowReturnModal(null)
                      }}
                    >
                      <div
                        className={
                          'pt-10 flex flex-col items-center justify-center'
                        }
                      >
                        <p
                          className={
                            'text-gray-500 text-2xl mb-6 px-5 text-center'
                          }
                        >
                          {t('absence.registerReturnDate')}
                        </p>
                        <div className="relative h-auto w-auto flex-1 flex justify-between gap-x-2">
                          <p className="text-gray-900 text-center p-5">
                            {t('absence.absenceTodayTo')}
                          </p>
                        </div>
                        <div className={'pb-[20px]'}></div>
                        <Button
                          //label={t('absence.reRegistration')}
                          label={t('absence.yesToday')}
                          loading={loading}
                          className={'mb-4'}
                          //variant={initialAbsence?.attributes?.confirmed ? 'red' : 'green'}
                          onClick={async () => {
                            reportBack(
                              kidId
                                ? kidId
                                : currentUserRole?.attributes?.kid?.id,
                              absence.id,
                              true,
                            )
                              .catch((e) => {
                                Swal.fire({
                                  text: t('events.eventAlarmFail'),
                                  icon: 'error',
                                  showCancelButton: false,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  confirmButtonText: t('general.ok'),
                                  cancelButtonText: t('general.cancel'),
                                })
                                throw e
                              })
                              .then(() => {
                                fetchAbsences(
                                  currentUserRole?.attributes?.school_pointer
                                    ?.id,
                                  kidId
                                    ? kidId
                                    : currentUserRole?.attributes?.kid?.id,
                                )
                                fetchKid(
                                  kidId
                                    ? kidId
                                    : currentUserRole?.attributes?.kid?.id,
                                )
                                setShowReturnModal(null)
                              })
                              .finally(() => {
                                setLoading(false)
                              })
                          }}
                        ></Button>
                        <Button
                          label={t('absence.noYesterday')}
                          loading={loading}
                          className={'mb-4'}
                          onClick={async () => {
                            reportBack(
                              kidId
                                ? kidId
                                : currentUserRole?.attributes?.kid?.id,
                              absence.id,
                              false,
                            )
                              .catch((e) => {
                                Swal.fire({
                                  text: t('events.eventAlarmFail'),
                                  icon: 'error',
                                  showCancelButton: false,
                                  confirmButtonColor: '#7eb5f4',
                                  cancelButtonColor: '#d33',
                                  confirmButtonText: t('general.ok'),
                                  cancelButtonText: t('general.cancel'),
                                })
                                throw e
                              })
                              .then(() => {
                                fetchAbsences(
                                  currentUserRole?.attributes?.school_pointer
                                    ?.id,
                                  kidId
                                    ? kidId
                                    : currentUserRole?.attributes?.kid?.id,
                                )
                                fetchKid(
                                  kidId
                                    ? kidId
                                    : currentUserRole?.attributes?.kid?.id,
                                )
                                setShowReturnModal(null)
                              })
                              .finally(() => {
                                setLoading(false)
                              })
                          }}
                        ></Button>
                        <Button
                          label={t('general.cancel')}
                          loading={loading}
                          className={'mb-6'}
                          variant={'red'}
                          onClick={async () => {
                            setShowReturnModal(null)
                          }}
                        ></Button>
                      </div>
                    </ModalRegular>
                  </>
                )
              })}
          </div>
          {/* CURRENT ABSENCE ENDS */}
          {/* HISTORY STARTS */}
          <ColorCard
            title={t('absence.history')}
            bgColor="bg-[#A8321D] w-full h-20"
            className=""
            actionButton={
              <>
                {isAdmin || isParent ? (
                  <Button
                    className="mt-3 mr-3"
                    size="md"
                    variant={'roundHistory'}
                    //label={t('absence.add')}
                    icon={faPlus}
                    onClick={() => {
                      setSelectedAbsence(null)
                      setShowHistoricModal(true)
                    }}
                  />
                ) : null}
              </>
            }
          >
            {!absences?.filter((ab) => ab.id !== kid?.get('absenceLog')?.id)
              .length && (
              <div
                className={
                  'flex flex-col text-center justify-center  items-center space-y-4 text-4xl text-gray-300 font-bold'
                }
              >
                <div className="mt-20 ">
                  {kid?.attributes?.kidName + ' '}
                  {t('absence.historicalAbsence')}
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-12 sm:m-12">
              {absences
                ?.filter((ab) => ab.id !== kid?.get('absenceLog')?.id)
                .sort(
                  (a: any, b: any) =>
                    new Date(b?.attributes?.startDate!).getTime() -
                    new Date(a.attributes.startDate!).getTime(),
                )
                .map((absence, index) => {
                  const canChangeActiveAbsence =
                    kid?.get('absenceLog')?.id == absence?.id
                  return (
                    <Card>
                      <div className={classNames('w-full')} key={absence?.id}>
                        {isAdmin && (
                          <div className="absolute top-4 right-4 flex items-end">
                            <button
                              ref={togglerRef}
                              type="button"
                              className="w-full rounded-md group focus:outline-none"
                              id={'options-menu' + absence?.id}
                              aria-haspopup="true"
                              aria-expanded="true"
                              onClick={() => {
                                setCurrentAbsenceMeny(absence?.id)
                                setIsOpen((prev) => !prev)
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEllipsisVertical}
                                className=" text-gray-400 bottom-36"
                              ></FontAwesomeIcon>
                            </button>

                            {isOpen && currentAbsenceMeny == absence?.id && (
                              <div
                                ref={menuRef as RefObject<HTMLDivElement>}
                                className="absolute right-0 z-50 mx-3 origin-top divide-y w-44 top-0"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby={'options-menu' + absence?.id}
                                key={absence?.id}
                              >
                                <ButtonDropdown.Menu>
                                  {isAdmin && (
                                    <>
                                      <ButtonDropdown.Section>
                                        <ButtonDropdown.Button
                                          icon={faPenToSquare}
                                          label={t(
                                            'absence.changeNotification',
                                          )}
                                          onClick={() => {
                                            if (
                                              canChangeActiveAbsence ||
                                              isAdmin
                                            ) {
                                              setSelectedAbsence(absence)
                                              setShowHistoricModal(true)
                                            }
                                          }}
                                        />
                                      </ButtonDropdown.Section>
                                      <ButtonDropdown.Section>
                                        <ButtonDropdown.Button
                                          icon={faTrashCan}
                                          label={t('absence.delete')}
                                          onClick={async (e) => {
                                            e.stopPropagation()
                                            Swal.fire({
                                              //title: 'Are you sure?',
                                              text: t(
                                                'absence.noticAreYouSure',
                                              ),
                                              icon: 'warning',
                                              showCancelButton: true,
                                              confirmButtonColor: '#7eb5f4',
                                              cancelButtonColor: '#d33',
                                              confirmButtonText:
                                                t('general.yes'),
                                              cancelButtonText:
                                                t('general.cancel'),
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                removeAbsence(absence).then(
                                                  () => {
                                                    fetchAbsences(
                                                      currentUserRole
                                                        ?.attributes
                                                        ?.school_pointer?.id,
                                                      kidId,
                                                    )
                                                  },
                                                )
                                              }
                                            })
                                          }}
                                        />
                                      </ButtonDropdown.Section>
                                    </>
                                  )}
                                </ButtonDropdown.Menu>
                              </div>
                            )}
                          </div>
                        )}
                        <div className={'w-full'}>
                          <div className="flex flex-col flex-1 justify-center">
                            <p className={'text-eventsMain font-medium'}>
                              {t('absence.absence')} (
                              {moment(absence?.attributes?.endDate).diff(
                                moment(absence?.attributes?.startDate),
                                'days',
                              ) + 1}{' '}
                              {moment(absence?.attributes?.endDate).diff(
                                moment(absence?.attributes?.startDate),
                                'days',
                              ) +
                                1 >
                              1
                                ? t('absence.absenceDays')
                                : t('absence.absenceDay')}
                              )
                            </p>
                            <div className="flex flex-col ">
                              <p
                                className={'font-bold text-neutral-500 text-lg'}
                              >
                                <div>
                                  <span>
                                    {moment(
                                      absence?.attributes?.startDate,
                                    ).format('D ')}
                                    {getDateForTranslatation(
                                      moment(
                                        absence?.attributes?.startDate,
                                      ).format('MM'),
                                    )}
                                    {moment(
                                      absence?.attributes?.startDate,
                                    ).format(' YYYY')}
                                  </span>

                                  <span className="mx-2 text-gray-700  ">
                                    <FontAwesomeIcon icon={faArrowRight} />
                                  </span>
                                  <span>
                                    {moment(
                                      absence?.attributes?.endDate,
                                    ).format('D ')}
                                    {getDateForTranslatation(
                                      moment(
                                        absence?.attributes?.endDate,
                                      ).format('MM'),
                                    )}
                                    {moment(
                                      absence?.attributes?.endDate,
                                    ).format(' YYYY')}
                                  </span>
                                </div>
                              </p>

                              <span className={' text-xs text-gray-500 italic'}>
                                <p>
                                  {new Date(absence?.attributes?.updatedAt) >
                                  new Date(absence?.attributes?.createdAt) ? (
                                    <FontAwesomeIcon
                                      className="text-yellow-500"
                                      icon={faCalendarPen}
                                    ></FontAwesomeIcon>
                                  ) : (
                                    <FontAwesomeIcon
                                      className=" text-blue-500"
                                      icon={faCalendarPlus}
                                    ></FontAwesomeIcon>
                                  )}{' '}
                                  {absence?.attributes?.user?.attributes
                                    ?.firstName ? (
                                    absence?.attributes?.user?.attributes
                                      ?.firstName +
                                    ' ' +
                                    absence?.attributes?.user?.attributes
                                      ?.lastName
                                  ) : (
                                    <> {t('general.unknown')}</>
                                  )}
                                  {absence?.attributes?.updatedAt ==
                                  absence?.attributes?.createdAt ? (
                                    <>
                                      {' '}
                                      {moment(
                                        absence?.attributes?.createdAt,
                                      ).format('YYYY-MM-DD')}
                                    </>
                                  ) : (
                                    <>
                                      {' '}
                                      {moment(
                                        absence?.attributes?.updatedAt,
                                      ).format('YYYY-MM-DD')}
                                    </>
                                  )}
                                </p>
                              </span>
                              {absence?.attributes?.confirmed && (
                                <p>
                                  <span
                                    className={' text-xs text-gray-500 italic'}
                                  >
                                    <FontAwesomeIcon
                                      className=" text-green-500"
                                      icon={faCalendarCheck}
                                    ></FontAwesomeIcon>{' '}
                                    {absence?.attributes?.confirmedBy
                                      ?.attributes?.firstName ? (
                                      absence?.attributes?.confirmedBy
                                        ?.attributes?.firstName +
                                      ' ' +
                                      absence?.attributes?.confirmedBy
                                        ?.attributes?.lastName
                                    ) : (
                                      <> {t('general.unknown')}</>
                                    )}
                                    <>
                                      {' '}
                                      {moment(
                                        absence?.attributes?.confirmedAt,
                                      ).format('YYYY-MM-DD')}
                                    </>
                                  </span>
                                </p>
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              'flex flex-wrap sm:mt-2 mt-4 gap-[10px] '
                            }
                          >
                            {canChangeActiveAbsence && (
                              <div className="flex flex-col items-center  sm:mt-6 mr-2 sm:mr-7">
                                <Button
                                  icon={faBell}
                                  className="h-[60px] w-[60px] lg:h-[80px] lg:w-[80px]"
                                  variant={'roundBlue'}
                                  onClick={() => {
                                    /*if (canChange) {
                                setSelectedAbsence(absence)
                                setShowModal(true)
                              }*/
                                    Swal.fire({
                                      //title: 'Are you sure?',
                                      text: t('events.eventAlarmNotImp'),
                                      icon: 'warning',
                                      showCancelButton: false,
                                      focusConfirm: false,
                                      confirmButtonColor: '#7eb5f4',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: t('general.ok'),
                                    })
                                  }}
                                />
                                <h2 className=" font-medium text-xs sm:text-sm top-[58px] sm:top-24 absolute flex">
                                  {t('absence.sendNotice')}
                                </h2>
                              </div>
                            )}

                            {canChangeActiveAbsence && (
                              <>
                                {absence.get('confirmed') == true ? (
                                  <div className="flex flex-col items-center sm:mt-6 mx-2">
                                    <Button
                                      icon={faCheck}
                                      className=" rounded-full h-[60px] w-[60px] lg:h-[80px] lg:w-[80px]"
                                      variant={'roundGreen'}
                                      onClick={() => {
                                        if (canChangeActiveAbsence) {
                                          setSelectedAbsence(absence)
                                          setShowRegistrationModal(true)
                                        }
                                      }}
                                    />
                                    <h2 className="mt-2 font-medium text-xs sm:text-sm top-[58px] sm:top-24 absolute text-center">
                                      {/*t('absence.reRegistrationConfirm')*/}
                                      {t('absence.dateConfirm')}
                                    </h2>
                                  </div>
                                ) : (
                                  <div className="flex flex-col items-center sm:mt-6 mx-2">
                                    <Button
                                      icon={faLock}
                                      className=" rounded-full h-[60px] w-[60px] lg:h-[80px] lg:w-[80px]"
                                      variant={'roundWhiteCircleGreen'}
                                      onClick={() => {
                                        if (isAdmin) {
                                          setSelectedAbsence(absence)
                                          setShowRegistrationModal(true)
                                        }
                                      }}
                                    />
                                    <h2 className="mt-2 font-medium text-xs sm:text-sm top-[58px] sm:top-24 absolute text-center">
                                      {/*t('absence.confirmReRegistration')*/}
                                      {t('absence.confirmDate')}
                                    </h2>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {absence?.attributes?.info && (
                          <p className={'w-full pb-2 pt-6 pl-2 text-gray-500'}>
                            {absence?.attributes?.info}
                          </p>
                        )}
                      </div>
                    </Card>
                  )
                })}
            </div>
          </ColorCard>
          {/* HISTORY ENDS */}
        </div>
      </div>
      <ModalRegular
        bgColor="bg-red-500 p-4"
        title={t('absence.absenceOverview')}
        className={'max-w-xl p-0'}
        isOpen={showCalender ? true : false}
        onClose={() => {
          setShowCalender(false)
        }}
        closeColor={'white'}
      >
        <AbsenceCalendar
          value={currentDate}
          lastMonthDate={lastMonthDate}
          selected={selectedDate}
          onChange={setCurrentDate}
          changeLastMonthDate={setLastMonthDate}
          absences={absences ? absences : []}
        />
        {/*<div className={'pt-1'}>
          <Calendar
            bookings={bookings ?? []}
            showNumberOfMonths={1}
            showKey={false}
          />
      </div>*/}
      </ModalRegular>
    </Page>
  )
})
