import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { DocumentationStoreContext } from '../../../store/documentationStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import classNames from 'classnames'
import ModalRegular from '../../../components/ModalRegular'
import { Spinner } from '../../../components/Spinner'
import { SKA_CARD_TYPE, SKA_RELATION_TYPE } from '../../../Enums/TyraEnums'
import {
  faArrowLeft,
  faImage,
  faBookOpen,
  faTags,
} from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../components/Button'
import moment from 'moment'
import { getIcon, getTitle } from '../DocumentationViewer'
import { BloggDoc } from '../../../Models/BloggDoc'

export const AddConnectionsModal = observer(
  ({
    showModal,
    currentUserRoleId,
    onClose,
    type,
    id,
    schoolId,
    onAdd,
  }: {
    showModal: boolean
    currentUserRoleId: string
    onClose: any
    type: string
    id: string
    schoolId: string
    onAdd: any
  }) => {
    const [relationType, setRelationType] = useState<number | undefined>()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [selected, setSelected] = useState<any[]>()
    const {
      selectConnections,
      fetchSelectConnections,
      addConnectionCards,
      setSelectConnections,
    } = useContext(DocumentationStoreContext)

    useEffect(() => {
      if (relationType) {
        setLoading(true)
        fetchSelectConnections({
          type: relationType,
          userRoleId: currentUserRoleId,
        }).finally(() => {
          setLoading(false)
        })
      }
    }, [relationType])

    const Item = ({
      title,
      selected,
      date,
      onClick,
    }: {
      title: string
      selected: boolean
      date: string
      onClick: any
    }) => {
      return (
        <div
          className={
            'flex justify-between items-center border p-3 mb-3 hover:bg-blue-50 cursor-pointer'
          }
          onClick={onClick}
        >
          <div className={'flex justify-center flex-col rounded '}>
            <p className={'text-gray-300 text-xs'}>{date}</p>
            <p className={'line-clamp-1'}>{title}</p>
          </div>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={classNames(
              selected ? 'text-green-600' : 'text-gray-500',
            )}
          />
        </div>
      )
    }

    const BlogItem = ({
      blogItem,
      selected,
      onClick,
    }: {
      blogItem: BloggDoc
      selected: boolean
      onClick: any
    }) => {
      return (
        <div
          className={
            'flex justify-between items-center border p-3 mb-3 hover:bg-blue-50 cursor-pointer'
          }
          onClick={onClick}
        >
          <div className={'flex justify-center flex-col rounded w-full'}>
            <div className={'flex justify-between w-full pr-3 mb-3'}>
              <p className={'text-xs'}>
                {blogItem?.attributes?.user_author?.attributes?.firstName}{' '}
                {blogItem?.attributes?.user_author?.attributes?.lastName}
              </p>
              <p className={'text-gray-300 text-xs'}>
                {moment(blogItem?.attributes?.createdAt).format(
                  'D MMM YYYY - HH:mm',
                )}
              </p>
            </div>
            <p className={'line-clamp-1'}>{blogItem?.attributes?.content}</p>
            <div className={'flex px-3 space-x-20 pt-3'}>
              <div className={'flex flex-col justify-center items-center'}>
                <FontAwesomeIcon
                  icon={faImage}
                  className={classNames('text-eventsMain ')}
                />

                <p className={'text-sm'}>
                  {blogItem?.attributes?.images?.length ?? 0}
                </p>
              </div>
              <div className={'flex flex-col justify-center items-center'}>
                <FontAwesomeIcon
                  icon={faBookOpen}
                  className={classNames('text-orange-400 ')}
                />

                <p className={'text-sm'}>
                  {blogItem?.attributes?.curriculums?.length ?? 0}
                </p>
              </div>
              <div className={'flex flex-col justify-center items-center'}>
                <FontAwesomeIcon
                  icon={faTags}
                  className={classNames('text-purple-700 ')}
                />

                <p className={'text-sm'}>
                  {blogItem?.attributes?.images?.doc_tags ?? 0}
                </p>
              </div>
            </div>
          </div>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={classNames(
              selected ? 'text-green-600' : 'text-gray-500',
            )}
          />
        </div>
      )
    }

    const getItems = () => {
      if (
        relationType === SKA_RELATION_TYPE.OPERATION_PLANING ||
        relationType === SKA_RELATION_TYPE.PROJECT_PLANING
      ) {
        return (
          selectConnections &&
          selectConnections?.length > 0 &&
          selectConnections
            ?.filter((c) => {
              if (search.length) {
                return c?.attributes?.name
                  ?.toLowerCase()
                  .includes(search.toLowerCase())
              }
              return c
            })
            ?.map((connection) => {
              return (
                <Item
                  selected={selected?.find((id) => id === connection?.id)}
                  title={connection?.attributes?.name}
                  date={moment(connection?.attributes?.createdAt).format(
                    'D MMM YYYY - HH:mm',
                  )}
                  onClick={() => {
                    if (selected?.find((id) => id === connection?.id)) {
                      setSelected(
                        selected?.filter((id) => id !== connection?.id),
                      )
                    } else {
                      setSelected([...(selected ?? []), connection?.id])
                    }
                  }}
                />
              )
            })
        )
      } else if (
        relationType === SKA_RELATION_TYPE.BLOG ||
        relationType === SKA_RELATION_TYPE.PORTFOLIO
      ) {
        return (
          selectConnections &&
          selectConnections?.length > 0 &&
          selectConnections?.map((connection) => {
            return (
              <BlogItem
                selected={selected?.find((id) => id === connection?.id)}
                blogItem={connection}
                onClick={() => {
                  if (selected?.find((id) => id === connection?.id)) {
                    setSelected(selected?.filter((id) => id !== connection?.id))
                  } else {
                    setSelected([...(selected ?? []), connection?.id])
                  }
                }}
              />
            )
          })
        )
      }
    }
    return (
      <div>
        <ModalRegular
          title={''}
          isOpen={showModal}
          className={'max-w-2xl'}
          onClose={() => {
            onClose()
            setRelationType(undefined)
          }}
        >
          <div className={'mt-10'}>
            {loading && <Spinner />}
            {!selectConnections?.length && (
              <>
                {type !== 'project' && (
                  <div
                    className={
                      'h-[60px] flex items-center border-b cursor-pointer'
                    }
                    onClick={() => {
                      setRelationType(SKA_RELATION_TYPE.PROJECT_PLANING)
                    }}
                  >
                    {getIcon(SKA_RELATION_TYPE.PROJECT_PLANING)}{' '}
                    {getTitle(SKA_RELATION_TYPE.PROJECT_PLANING)}
                  </div>
                )}
                {type !== 'operation' && (
                  <div
                    className={
                      'h-[60px] flex items-center border-b cursor-pointer'
                    }
                    onClick={() => {
                      setRelationType(SKA_RELATION_TYPE.OPERATION_PLANING)
                    }}
                  >
                    {getIcon(SKA_RELATION_TYPE.OPERATION_PLANING)}{' '}
                    {getTitle(SKA_RELATION_TYPE.OPERATION_PLANING)}
                  </div>
                )}
                <div
                  className={
                    'h-[60px] flex items-center border-b cursor-pointer'
                  }
                  onClick={() => {
                    setRelationType(SKA_RELATION_TYPE.REFLECTION)
                  }}
                >
                  {getIcon(SKA_RELATION_TYPE.REFLECTION)}{' '}
                  {getTitle(SKA_RELATION_TYPE.REFLECTION)}
                </div>

                <div
                  className={
                    'h-[60px] flex items-center border-b cursor-pointer'
                  }
                  onClick={() => {
                    setRelationType(SKA_RELATION_TYPE.BLOG)
                  }}
                >
                  {getIcon(SKA_RELATION_TYPE.BLOG)}{' '}
                  {getTitle(SKA_RELATION_TYPE.BLOG)}
                </div>

                {type !== 'operation' && (
                  <div
                    className={
                      'h-[60px] flex items-center border-b cursor-pointer'
                    }
                    onClick={() => {
                      setRelationType(SKA_RELATION_TYPE.PORTFOLIO)
                    }}
                  >
                    {getIcon(SKA_RELATION_TYPE.PORTFOLIO)}{' '}
                    {getTitle(SKA_RELATION_TYPE.PORTFOLIO)}
                  </div>
                )}
              </>
            )}
            {selectConnections && selectConnections?.length > 0 && (
              <div className={'flex w-full justify-between items-center p-3'}>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  onClick={() => {
                    setRelationType(undefined)
                    setSelectConnections([])
                  }}
                />
                <Button
                  label={'Save'}
                  loading={loading}
                  onClick={async () => {
                    if (relationType) {
                      setLoading(true)
                      await addConnectionCards({
                        id: id,
                        toIds: selected ?? [],
                        relationType: relationType,
                        type: type,
                        cardType: getCardTypeFromRelationType(relationType),
                        schoolId: schoolId,
                      })
                      setLoading(false)
                      onAdd()
                    }
                  }}
                />
              </div>
            )}

            {selectConnections &&
              selectConnections?.length > 0 &&
              relationType !== SKA_RELATION_TYPE.BLOG && (
                <div className={'mb-3'}>
                  <input
                    className={classNames(
                      'block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
                    )}
                    type={'text'}
                    value={search}
                    placeholder={'Sök på namn...'}
                    onChange={(e) => {
                      setSearch(e.target.value)
                    }}
                  />
                </div>
              )}
            {getItems()}
          </div>
        </ModalRegular>
      </div>
    )
  },
)

const getCardTypeFromRelationType = (relationType: SKA_RELATION_TYPE) => {
  if (relationType === SKA_RELATION_TYPE.PROJECT_PLANING) {
    return SKA_CARD_TYPE.RELATION_PROJECT_PLANS
  } else if (relationType === SKA_RELATION_TYPE.OPERATION_PLANING) {
    return SKA_CARD_TYPE.RELATION_OPERATION_PLANS
  }

  return SKA_CARD_TYPE.RELATION_OPERATION_PLANS
}
