import React from 'react'
import { BloggDoc } from '../../../Models/BloggDoc'
import moment, { Moment as MomentType } from 'moment'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/sv'

export const CheckInOutBlock = ({
  logs,
  firstDate,
  amountOfDays,
  typeOfView,
}: {
  logs: any
  firstDate: MomentType
  amountOfDays: number
  typeOfView: string
}) => {
  const renderDiv = () => {
    const div: any = []

    const amountOfHours = amountOfDays * 24
    const totalTime = amountOfHours * 60 * 60 * 1000 // Time in miliseconds

    logs.map((log: any) => {
      const startTime = log.checkedIn
        ? moment(log.checkedIn, 'Y-M-D HH:mm')
        : moment(log.checkedOut, 'Y-M-D HH:mm').startOf('day')

      const endTime = log.checkedOut
        ? moment(log.checkedOut, 'Y-M-D HH:mm')
        : moment(log.checkedIn, 'Y-M-D HH:mm').endOf('day')

      const timeDiff: any = moment.duration(endTime.diff(startTime))
      const timeDiffInMiliseconds = timeDiff * 60 * 60 * 1000

      const firstHour = moment(firstDate).startOf('day')

      const fromLeft: any = moment.duration(startTime.diff(firstHour))
      const fromLeftInMilliseconds = fromLeft * 1000 // Time in miliseconds
      const fromLeftPercentage = (fromLeft / totalTime) * 100
      //const checkInOutLength = timeDiffInMiliseconds / totalTime
      const checkInOutLength = timeDiff / totalTime
      const checkInOutWidth = checkInOutLength * 100

      let left = fromLeftPercentage + '%'
      let width = checkInOutWidth + '%'

      let divLength = {
        width: width,
        left: left,
      }
      div.push(
        <div className="checkInOutBlock" style={divLength}>
          <small>
            {moment(log.checkedIn).format('HH:mm')}
            {' - '}
            {moment(log.checkedOut).format('HH:mm')}
          </small>
        </div>,
      )
    })
    return div
  }
  return <>{renderDiv()}</>
}
