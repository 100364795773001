import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { Event3StoreContext } from '../../../store/event3Store'

interface IComment {
  eventTimeId: string
  eventGroupInfoId: string
  refreshDetail: () => void
}
export const CommentInput: React.FC<IComment> = observer((event) => {
  const [commentMessage, setMessage] = useState<string>('')
  const { createEventComment } = useContext(Event3StoreContext)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value)
  }

  const createComment = async () => {
    await createEventComment(
      event.eventTimeId,
      event.eventGroupInfoId,
      commentMessage,
    )

    setMessage('')
    event.refreshDetail()
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      createComment()
    }
  }

  return (
    <div className="flex items-center p-2 rounded-lg border border-gray-300 bg-gray-100  w-full ">
      <FontAwesomeIcon icon={faComment} className="pr-2 " />
      <textarea
        placeholder="Skriv kommentar..."
        className="bg-gray-100 outline-none w-full resize-none "
        value={commentMessage}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
          event.stopPropagation()
          handleChange(event)
        }}
        onKeyPress={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
          event.stopPropagation()
          handleKeyPress(event)
        }}
        onClick={(e) => e.stopPropagation()}
        rows={1}
      />
    </div>
  )
})
