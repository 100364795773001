import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import CustomInput from '../../../components/CustomInput'
import { Button } from '../../../components/Button'
import {
  CreateEventParams,
  EVENT3_POST_TO,
  EVENT3_TYPE,
} from '../../../store/event3Store'
import { Department } from '../../../Models/Department'
import { School } from '../../../Models/School'
import { useTranslation } from 'react-i18next'

interface Props {
  newEvent: CreateEventParams
  schools: School[]
  onChange: Dispatch<SetStateAction<CreateEventParams>>
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
}

interface Props2 {
  newEvent: CreateEventParams
  schools: School[]
  onChange: Dispatch<SetStateAction<CreateEventParams>>
}

export const ChooseSchoolsSelections: React.FC<Props2> = (props: Props2) => {
  const { t } = useTranslation()

  return (
    <>
      <>
        {props.schools.length === props.newEvent.multiSchools?.length ? (
          <h4
            className="text-blue-800 cursor-pointer"
            onClick={() => {
              props.onChange({
                ...props.newEvent,
                multiSchools: [],
              })
            }}
          >
            <>{t('multiselect.deSelectAll')}</>
          </h4>
        ) : (
          <h4
            className="text-blue-800 cursor-pointer"
            onClick={() => {
              props.onChange({
                ...props.newEvent,
                multiSchools: props.schools.map((dep) => dep.id),
              })
            }}
          >
            <>{t('settings.markAll')}</>
          </h4>
        )}
      </>
      <div className="">
        {props.schools.map((school) => {
          return (
            <Button
              size="sm"
              variant={
                props.newEvent.multiSchools?.find(
                  (schoolId) => schoolId === school.id,
                )
                  ? 'fullBlue'
                  : 'default'
              }
              label={school.display_school_name}
              onClick={() => {
                //let chosenSchoolIds = props.newEvent.multiSchools

                if (
                  props.newEvent.multiSchools?.find(
                    (schoolId) => schoolId === school.id,
                  )
                ) {
                  props.onChange({
                    ...props.newEvent,
                    multiSchools: props.newEvent.multiSchools.filter(
                      (schoolId) => schoolId !== school.id,
                    ),
                  })
                } else {
                  // Add to array
                  if (props.newEvent.multiSchools) {
                    props.onChange((prev) => {
                      return {
                        ...prev,
                        multiSchools: prev.multiSchools
                          ? [...prev.multiSchools, school.id]
                          : [school.id],
                      }
                    })
                  }
                }
              }}
            />
          )
        })}
      </div>
    </>
  )
}

export const ChooseSchools: React.FC<Props> = observer((props: Props) => {
  const [openSelections, setOpenSelections] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <div className="flex justify-between">
        <div
          onClick={() => {
            setOpenSelections(!openSelections)
          }}
        >
          <h4>
            {t('general.chooseSchools')}: {props.newEvent.multiSchools?.length}{' '}
            {t('events.choosen')}
          </h4>
        </div>
      </div>
    </>
  )
})
