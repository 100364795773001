import React, { useContext, useState } from 'react'
import Parse from 'parse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFile,
  faFolderPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import { Field, FieldProps, Form, Formik } from 'formik'
import { Input } from '../../../components/formik/Input'
import { Button, ButtonSize } from '../../../components/Button'
import * as Yup from 'yup'
import { DocumentStoreContext } from '../../../store/documentStore'
import { UserStoreContext } from '../../../store/userStore'
import {
  NotificationContext,
  NotificationVariant,
} from '../../../App/notification/notificationManger'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  faFileCirclePlus,
  faFileMedical,
} from '@fortawesome/pro-thin-svg-icons'
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons'
import Swal from 'sweetalert2'

export const CreateFolderButton: React.FC<{
  onFolderAdded: any
  onFileAdded: any
  type: string
  hideFolder?: boolean
  folderId?: string
  kidId?: string
  depId?: string
}> = ({
  onFolderAdded,
  onFileAdded,
  folderId,
  kidId,
  depId,
  hideFolder,
  type,
}) => {
  const { notify } = useContext(NotificationContext)
  const { t } = useTranslation()
  return (
    <div className={' flex justify-center'}>
      <div
        className={
          'max-w-7xl flex flex-wrap justify-center md:justify-end md:items-center gap-3 md:flex-row md:space-x-6 w-full '
        }
      >
        <AddFileModal
          type={type}
          kidId={kidId}
          depId={depId}
          folderId={folderId}
          onFileAdded={() => {
            /*notify({
              variant: NotificationVariant.OK,
              title: t('documents.fileCreatedSuccessTitle'),
              message: t('documents.fileCreatedSuccessMessage'),
            })*/
            Swal.fire({
              text: t('documents.fileCreatedSuccessMessage'),
              icon: 'success',
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonColor: '#7eb5f4',
              cancelButtonColor: '#d33',
              confirmButtonText: t('general.ok'),
            })
            onFileAdded()
          }}
        />
        {!hideFolder && (
          <AddFolderModal
            type={type}
            kidId={kidId}
            depId={depId}
            onFolderAdded={() => {
              /*notify({
                variant: NotificationVariant.OK,
                title: t('documents.folderCreatedSuccessTitle'),
                message: t('documents.folderCreatedSuccessMessage'),
              })*/
              Swal.fire({
                text: t('documents.folderCreatedSuccessMessage'),
                icon: 'success',
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonColor: '#7eb5f4',
                cancelButtonColor: '#d33',
                confirmButtonText: t('general.ok'),
              })
              onFolderAdded()
            }}
            folderId={folderId}
          />
        )}
      </div>
    </div>
  )
}

const AddFileModal: React.FC<{
  onFileAdded: any
  type: string
  folderId?: string
  kidId?: string
  depId?: string
}> = ({ type, onFileAdded, kidId, depId, folderId }) => {
  const { t } = useTranslation()
  const { addFile } = useContext(DocumentStoreContext)
  const [name, setName] = useState('')
  const [checked, setChecked] = useState(false)
  const [withParent, setWithParent] = useState(false)
  const { savedUserRoleId } = useContext(UserStoreContext)
  const [loading, setLoading] = useState(false)
  const [myFiles, setMyFiles] = useState([] as any)
  const onDrop = (files: any) => {
    files.map((file: any) => {
      const reader = new FileReader()
      setName(file.name)
      reader.onload = (e) => {
        setMyFiles(
          files.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              base64Image: e?.target?.result,
            }),
          ),
        )
      }
      reader.readAsDataURL(file)
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  })

  const files = myFiles.map((file: any, i: number) => (
    <div
      key={file?.path}
      onClick={() => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
      }}
    >
      {file.path}
      <FontAwesomeIcon
        icon={faTrash}
        className={'pl-2 text-xs cursor-pointer'}
      />
    </div>
  ))

  const [showModal, setShowModal] = useState(false)
  return (
    <div>
      <Button
        variant="primary"
        size="lg"
        //label={t('documents.addFile')}
        reverseIcon={false}
        icon={faFilePlus}
        onClick={() => {
          setShowModal(true)
        }}
      />
      <ModalRegular
        title={t('documents.saveFile')}
        isOpen={showModal}
        bgColor="bg-eventsMain p-4"
        closeColor="white"
        className={'max-w-2xl'}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <div className={'p-5 pt-7'}>
          <div className="container mt-6">
            <input
              className={classNames(
                'block w-full px-3 py-2 mb-4 placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
              )}
              type={'text'}
              placeholder={t('documents.documentName')}
              disabled={myFiles?.length === 0}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
            <div
              {...getRootProps({
                className:
                  'dropzone flex flex-row p-10 item-center justify-center bg-gray-50 border rounded-lg ',
              })}
            >
              <input {...getInputProps()} />
              <p className={' text-center mr-1'}>
                {t('documents.dragAndDropTitle')}
              </p>{' '}
              <p className=" text-center cursor-pointer hover:text-blue-500 text-blue-300">
                {t('documents.chooseFileDrop')}
              </p>
            </div>
            <div className={'mt-6 mb-6'}>
              <ul>{files}</ul>
            </div>

            <div>
              {myFiles[0]?.type === 'application/pdf' && (
                <div className={'pl-2 pb-4 flex items-center space-x-2'}>
                  <input
                    type={'checkbox'}
                    checked={checked}
                    onChange={(e) => {
                      setChecked(!checked)
                    }}
                  />
                  <p className={'text-sm'}>{t('documents.requestSign')}</p>
                </div>
              )}

              {type === 'students' && (
                <div className={'pl-2 pb-4 flex items-center space-x-2'}>
                  <input
                    type={'checkbox'}
                    checked={withParent}
                    onChange={(e) => {
                      setWithParent(!withParent)
                    }}
                  />
                  <p className={'text-sm'}>{t('documents.shareWithParent')}</p>
                </div>
              )}
            </div>

            <div className={'flex w-full justify-end mt-3'}>
              <Button
                loading={loading}
                label={t('documents.saveFileWithPush')}
                variant="primary"
                disabled={myFiles?.length === 0}
                size="md"
                onClick={async () => {
                  setLoading(true)
                  const file = myFiles[0]
                  const parseFile = new Parse.File('tyrafile', file)
                  const f = await parseFile.save()

                  await addFile({
                    file: f,
                    type: type,
                    name: name,
                    kidId: kidId,
                    depId: depId,
                    withParent: withParent,
                    sign: checked,
                    mimeType: myFiles[0]?.type,
                    userRoleId: savedUserRoleId ?? '',
                    folderId: folderId,
                    sendPush: true,
                  }).finally(() => {
                    setLoading(false)
                    setMyFiles([])
                    setName('')
                  })
                  setShowModal(false)
                  onFileAdded()
                }}
              />
              <Button
                loading={loading}
                label={t('documents.saveFile')}
                variant="primary"
                disabled={myFiles?.length === 0}
                size="md"
                onClick={async () => {
                  setLoading(true)
                  const file = myFiles[0]
                  const parseFile = new Parse.File('tyrafile', file)
                  const f = await parseFile.save()

                  await addFile({
                    file: f,
                    type: type,
                    name: name,
                    kidId: kidId,
                    depId: depId,
                    withParent: withParent,
                    sign: checked,
                    mimeType: myFiles[0]?.type,
                    userRoleId: savedUserRoleId ?? '',
                    folderId: folderId,
                    sendPush: false,
                  }).finally(() => {
                    setLoading(false)
                    setMyFiles([])
                    setName('')
                  })
                  setShowModal(false)
                  onFileAdded()
                }}
              />
            </div>
          </div>
        </div>
      </ModalRegular>
    </div>
  )
}

const AddFolderModal: React.FC<{
  onFolderAdded: any
  type: string
  folderId?: string
  kidId?: string
  depId?: string
}> = ({ onFolderAdded, type, kidId, depId, folderId }) => {
  const { t } = useTranslation()
  const [withParent, setWithParent] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { addFolder } = useContext(DocumentStoreContext)
  const { savedUserRoleId } = useContext(UserStoreContext)
  const [loading, setLoading] = useState(false)

  return (
    <div>
      <Button
        className="text-white"
        variant="primary"
        size="lg"
        // label={t('documents.addFolder')}
        reverseIcon={false}
        icon={faFolderPlus}
        onClick={() => {
          setShowModal(true)
        }}
      />

      <ModalRegular
        title={t('documents.saveFolder')}
        isOpen={showModal}
        bgColor="bg-eventsMain p-4"
        closeColor="white"
        className={'max-w-2xl'}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <div className={'p-5'}>
          <Formik
            enableReinitialize
            initialValues={{ name: '' }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(''),
            })}
            onSubmit={async (values, helpers) => {
              setLoading(true)
              await addFolder({
                name: values.name,
                type: type,
                kidId: kidId,
                depId: depId,
                withParent: withParent,
                userRoleId: savedUserRoleId ?? '',
                folderId: folderId,
              }).finally(() => {
                setLoading(false)
              })
              setShowModal(false)
              onFolderAdded()
            }}
            validateOnChange={false}
          >
            {({ isSubmitting, submitForm, dirty, isValid }) => {
              return (
                <Form className={'space-y-2'}>
                  <Field name="name">
                    {(
                      props: FieldProps<{
                        name: string
                      }>,
                    ) => (
                      <Input
                        label=""
                        placeholder={t('documents.name')}
                        autoFocus
                        {...props}
                      />
                    )}
                  </Field>

                  {type === 'students' && (
                    <div className={'pl-2 pb-4 flex items-center space-x-2'}>
                      <input
                        type={'checkbox'}
                        checked={withParent}
                        onChange={(e) => {
                          setWithParent(!withParent)
                        }}
                      />
                      <p className={'text-sm'}>
                        {t('documents.shareWithParent')}
                      </p>
                    </div>
                  )}

                  <div className={'w-full flex justify-end pt-4'}>
                    <Button
                      size="md"
                      loading={loading}
                      type="submit"
                      variant="primary"
                      label={t('documents.saveFolder')}
                      disabled={!dirty || !isValid || isSubmitting}
                    />
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </ModalRegular>
    </div>
  )
}
