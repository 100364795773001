import classNames from 'classnames'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import { UserStoreContext } from '../../../store/userStore'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'

interface Props {
  label: string
  color?: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
  onlyStaff?: boolean
  thinText?: boolean
}

export const EventTagLabel: React.FC<Props> = ({
  label,
  color,
  size,
  onlyStaff,
  thinText,
}) => {
  const { currentUserRole } = useContext(UserStoreContext)

  if (onlyStaff && currentUserRole!.role_type < USER_ROLE_TYPE.TEACHER_LOWEST) {
    return <></>
  }
  return (
    <div>
      <FontAwesomeIcon
        className="w-3 h-3"
        style={{ color: color }}
        icon={faTag}
        flip="horizontal"
      />{' '}
      <span
        className={classNames(
          'text-xs font-bold',
          thinText ? 'font-light' : '',
        )}
      >
        {label}
      </span>
    </div>
  )
}
