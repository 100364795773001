import { useRef, useEffect } from 'react'
import { useOnScreen } from '../hooks/useOnScreen'
import { Button } from './Button'
import { t } from 'i18next'

interface LoadMoreButtonProps {
  loadMore: () => void
  loading: boolean
  needMore: boolean
}

export const LoadMoreButtonOnClick: React.FC<LoadMoreButtonProps> = (props) => {
  // Ref for the element that we want to detect whether on screen
  const ref: any = useRef<HTMLDivElement>()
  // Call the hook passing in ref and root margin
  // In this case it would only be considered onScreen if more ...
  // ... than 50px of element is visible.
  const onScreen: boolean = useOnScreen<HTMLDivElement>(ref, '0px')

  useEffect(() => {
    if (!props.loading && onScreen && props.needMore) {
      //props.loadMore()
    }
  }, [onScreen])

  return (
    <div ref={ref} className={'w-full flex justify-center mt-4 mb-4'}>
      {props.needMore && (
        <Button
          onClick={() => {
            if (!props.loading && props.needMore) {
              props.loadMore()
            }
          }}
          loading={props.loading}
          label={t('general.loadMore')}
        ></Button>
      )}
    </div>
  )
}
