import React from 'react'
import { BloggDoc } from '../../../Models/BloggDoc'
import moment, { Moment as MomentType } from 'moment'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/sv'
import { useTranslation } from 'react-i18next'

export const PresenceScheduleDiffContainer = ({
  presence,
  schema,
  diff,
  compact = false,
  showHeader = true,
  tariff,
}: {
  presence: any
  schema: any
  diff: any
  compact?: boolean
  showHeader?: boolean
  tariff?: string
}) => {
  const { t } = useTranslation()
  if (compact) {
    return (
      <div>
        {showHeader && (
          <div className="px-1">
            <h4 className="font-bold text-sm">{t('general.total')}</h4>
          </div>
        )}
        <div className="text-xl  shadow border overflow-hidden rounded-xl grid-cols-1 ">
          <div className="w-full text-xs p-2 font-medium flex-col flex justify-center gap-x-4 ">
            <div className="flex flex-row gap-x-1">
              <b>{t('general.presence')}:</b>
              <div className="text-green-500">
                {' '}
                {presence &&
                  presence.hours + 'h' + ' ' + presence.minutes + 'm'}
              </div>
            </div>
            <div className="flex flex-row gap-x-1">
              <b>{t('menu.scheme')}:</b>{' '}
              <div className="text-orange-400">
                {schema && schema.hours + 'h' + ' ' + schema.minutes + 'm'}
              </div>
            </div>
            <div className="flex flex-row gap-x-1">
              <b>Diff:</b>{' '}
              <div
                className={
                  diff && diff.negative ? 'text-red-500' : 'text-eventsMain'
                }
              >
                {diff && diff.negative ? '+' : '-'}{' '}
                {diff && diff.hours + 'h' + ' ' + diff.minutes + 'm'}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        {showHeader && (
          <div className="px-1">
            <h4 className="font-bold text-sm">{t('general.total')}</h4>
          </div>
        )}
        <div className="text-xl  shadow border overflow-hidden rounded-xl grid-cols-1 ">
          <div className="w-full text-xs p-4 px-[30px] font-medium flex-row flex justify-center gap-x-4 ">
            <div className="flex flex-col">
              <b>{t('general.presence')}:</b>{' '}
              <div className="text-green-500">
                {presence &&
                  presence.hours + 'h' + ' ' + presence.minutes + 'm'}
              </div>
            </div>
            <div className="flex flex-col">
              <b>{t('menu.scheme')}:</b>{' '}
              <div className="text-orange-400">
                {schema && schema.hours + 'h' + ' ' + schema.minutes + 'm'}
              </div>
            </div>
            <div className="flex flex-col">
              <b>Diff:</b>{' '}
              <div
                className={
                  diff && diff.negative ? 'text-red-500' : 'text-eventsMain'
                }
              >
                {diff && diff.negative ? '+' : '-'}
                {diff && diff.hours + 'h' + ' ' + diff.minutes + 'm'}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
