import { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../components/Button'
import { CreateEventParams } from '../../../store/event3Store'
import { KidStoreContext } from '../../../store/kidStore'
import { UserStoreContext } from '../../../store/userStore'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { observer } from 'mobx-react'
import { Kid } from '../../../Models/Kid'
import { useTranslation } from 'react-i18next'
import { KidsStoreContext } from '../../../store/kidsStore'
import ModalRegular from '../../../components/ModalRegular'

interface IProps {
  newEvent: CreateEventParams
  choosenKids: Kid[]
  onClose: () => void
  openAddParticipatingChildren: boolean
  depIds?: string[]
  onChange: (e: CreateEventParams) => void
  onChangeChoosenKids: (e: any) => void
  toggleAddParticipatingChildrenPopUp: () => void
}

interface DepartmentGroupForKids {
  header: string
  users: Kid[]
}
const createDepTupletForKids = (
  kids?: Kid[],
  departmentsFilter?: (string | undefined)[],
): DepartmentGroupForKids[] => {
  const target: DepartmentGroupForKids[] = []
  const depDict: Record<string, Kid[]> = {}
  const missing: Kid[] = []

  kids?.forEach((kid) => {
    if (kid.departmentPointer?.attributes.klassName === null) {
      missing.push(kid)
    } else if (kid.departmentPointer?.attributes.klassName) {
      const klassName = kid.departmentPointer?.attributes.klassName
      if (klassName) {
        if (!depDict[klassName]) {
          depDict[klassName] = []
        }
        depDict[klassName].push(kid)
      }
    }
  })

  for (const [key, value] of Object.entries(depDict)) {
    if (departmentsFilter?.includes(key) || departmentsFilter?.length === 0)
      target.push({ header: key, users: value })
  }

  target.sort((a, b) => a.header.localeCompare(b.header))

  if (missing.length > 0) {
    const missingDep = 'Department is missing'
    target.push({ header: missingDep, users: missing })
  }

  return target
}
export const AddParticipatingChildren: React.FC<IProps> = observer(
  ({
    newEvent,
    choosenKids,
    onClose,
    openAddParticipatingChildren,
    depIds,
    onChange,
    onChangeChoosenKids,
    toggleAddParticipatingChildrenPopUp,
  }) => {
    const popupRef = useRef<HTMLDivElement>(null)
    const [choosenKidsId, setChoosenKidsId] = useState<string[]>(
      choosenKids.map((kid) => kid.id),
    )
    const { kids, fetchKids } = useContext(KidsStoreContext)
    const { currentUserRole } = useContext(UserStoreContext)
    const { fetchDepartments, departments } = useContext(DepartmentStoreContext)
    const { t } = useTranslation()

    const schoolId = currentUserRole && currentUserRole.school_pointer.id

    const fetchedkidssss = createDepTupletForKids(
      kids,
      departments?.map((department) => department.klassName),
    )

    const handleClickOutside = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    const handleClick = () => {
      onChange({
        ...newEvent,
        addParticipatingKids: [...choosenKidsId],
      })
      if (kids?.length) {
        onChangeChoosenKids([
          ...kids?.filter((kid) => choosenKidsId.includes(kid.id)),
        ])
      }
      onClose()
    }

    useEffect(() => {
      if (schoolId) {
        const fetchDepartmentss = async () => fetchDepartments(schoolId)
        const fetchKidss = async () => {
          await fetchKids(currentUserRole)
        }
        fetchDepartmentss()
        fetchKidss()
      }
    }, [schoolId])

    return (
      <ModalRegular
        title={`${t('events.selectChild')} ${' ('} ${
          choosenKidsId.length
        } ${') '}`}
        className="max-w-3xl h-[60vh]"
        classNameTitle="p-4 w-full text-black flex justify-center"
        isOpen={openAddParticipatingChildren}
        hideCloseButton={true}
        onClose={() => {
          toggleAddParticipatingChildrenPopUp()
        }}
      >
        <div className="flex flex-col overflow-auto scrollbar-hide h-[90%]">
          {fetchedkidssss?.map((group, index) => (
            <>
              <div className="flex justify-between" key={group.users.length}>
                <h2 className="pl-2 font-bold">
                  {group.header} ({group.users.length})
                </h2>
                {group.users.some(
                  (user) => !choosenKidsId.includes(user.id),
                ) ? (
                  <h2
                    className="pr-4 text-sm font-semibold text-blue-500 cursor-pointer"
                    key={group.header}
                    onClick={() => {
                      const prevGroup = group.header
                      prevGroup !== group.header && setChoosenKidsId([])
                      const kidsIds = group.users.map((user) => user.id)
                      setChoosenKidsId((previousIds) => [
                        ...previousIds,
                        ...kidsIds,
                      ])
                    }}
                  >
                    select all
                  </h2>
                ) : (
                  <h2
                    className="pr-4 text-sm font-semibold text-blue-500 cursor-pointer"
                    onClick={() => {
                      setChoosenKidsId(
                        choosenKidsId.filter(
                          (kidId) =>
                            !group.users.map((user) => user.id).includes(kidId),
                        ),
                      )
                    }}
                  >
                    Deselect all
                  </h2>
                )}
              </div>
              <div key={group.header} className="mb-2 py-2 w-full">
                <ul
                  className={`flex flex-wrap gap-3 pl-3 gap-x-4  ${
                    index === fetchedkidssss.length - 1 && 'mb-12'
                  }`}
                >
                  {group.users.map((user) => {
                    return (
                      <li
                        key={user.id}
                        className={`p-1 w-[48%] flex items-center rounded-lg shadow-md bg-[#FFFFFF] transition-transform duration-300 active:scale-90 ${
                          choosenKidsId.includes(user.id) &&
                          'border-1 outline outline-2 outline-blue-500 '
                        } `}
                        onClick={() => {
                          if (choosenKidsId.includes(user.id)) {
                            setChoosenKidsId((previous) =>
                              previous.filter((id) => id !== user.id),
                            )
                          } else {
                            setChoosenKidsId((previous) => [
                              ...previous,
                              user.id,
                            ])
                          }
                        }}
                      >
                        <div className="ml-1 w-full cursor-pointer flex items-center">
                          <img
                            className={`w-10 h-10 rounded-full object-cover`}
                            alt=""
                            key={user?.id}
                            src={
                              user?.kidImage_thumb?._url ??
                              '/images/placeholder-image.png'
                            }
                          />
                          <div className=" flex flex-col pl-2 justify-center">
                            <p className="font-semibold text-md w-full">
                              {user?.kidName}
                            </p>{' '}
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </>
          ))}
        </div>
        <div className="flex w-full justify-center  bottom-0  bg-gradient-to-t from-white fixed rounded-b-lg">
          <Button
            size="sm"
            className="min-w-[200px] my-1.5"
            fontSize="text-lg"
            variant="fullBlue"
            label={t('general.done')}
            onClick={() => {
              handleClick()
            }}
          />
        </div>
      </ModalRegular>
    )
  },
)
