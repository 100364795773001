import { useCallback, useContext, useEffect, useState } from 'react'
import { Button } from '../../../components/Button'
import { BloggDoc } from '../../../Models/BloggDoc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import {
  faEllipsisVertical,
  faFilePdf,
  faPaperPlane,
  faPen,
  faPrint,
  faHeart as farFaHeart,
} from '@fortawesome/free-solid-svg-icons'
import { faComment, faFolder } from '@fortawesome/pro-solid-svg-icons'
import { faComment as farFaComment } from '@fortawesome/pro-regular-svg-icons'

import {
  faBooks,
  faTag,
  faThoughtBubble,
  faTrophyStar,
} from '@fortawesome/pro-solid-svg-icons'
import { ImageCarousel } from '../../../components/ImageCarousel'
import { t } from 'i18next'
import moment from 'moment'
import classNames from 'classnames'
import BlogCommentsModal, {
  BlogLikeModul,
} from '../../portfolio/components/BlogItem'
import { BlogOverview } from './BlogOverview'
import { boolean } from 'yup'
import { FullscreenModal } from '../../portfolio/components/FullScreenModal'
import { library } from '@fortawesome/fontawesome-svg-core'
import Swal from 'sweetalert2'
import ModalRegular from '../../../components/ModalRegular'
import { PortfolioBlogStoreContext } from '../../../store/portfolioBlogStore'
import BlogOverviewModal from './BlogOverviewModal'
import { Spinner } from '../../../components/Spinner'
import { getImageFromRole } from '../../../utils/parseUtils'
import CommentCard from './CommentCard'
import { UserStoreContext } from '../../../store/userStore'
import { UserRole } from '../../../Models/UserRole'
import AudioPlayer from '../../../components/Audioplayer'
import { BlogReflections } from './BlogReflections'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import { faThoughtBubble as farFaThoughtBubble } from '@fortawesome/pro-regular-svg-icons'
import { ExportStoreContext } from '../../../store/exportStore'
import { BloggReflections } from '../../../Models/BloggReflections'
import TimeStamp from '../../../components/TimeStamp'
import VideoModal from '../../../components/VideoModal'
import { ButtonDropdown } from '../../../components/Dropdown'
import { useClickOutside } from '../../../hooks/useClickoutside'
import ExportModal from '../../portfolio/components/ExportModal'
import { useParams } from 'react-router-dom'
import { FileJobStoreContext } from '../../../store/fileJobStore'
import { FileJob } from '../../../Models/FileJob'
import { Loading } from '../../../components/Loading'
import { Kid } from '../../../Models/Kid'

library.add(faHeart, faHeart)

export const BlogRow = ({
  bloggDoc,
  onImageSelected,
  onSaveReflections,
  reflections,
  kidName,
  type,
  kidFirstName,
  kid,
}: {
  bloggDoc: BloggDoc
  onImageSelected: any
  onSaveReflections?: () => void
  reflections?: BloggReflections[]
  kidName?: string
  type: 'Blog' | 'Portfolio'
  kidFirstName?: string
  kid?: Kid
}) => {
  const showComment =
    bloggDoc?.attributes?.port_commentable ||
    bloggDoc?.attributes?.blog_commentable

  const [showCommentModal, setShowCommentModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const showLikes =
    bloggDoc?.attributes?.port_emotable || bloggDoc?.attributes?.blog_emotable
  const [wave, setWave] = useState(false)
  const triggerWave = () => {
    setWave((prevState) => {
      return !prevState
    })
  }
  const [showOverviewModal, setShowOverviewModal] = useState(false)

  const showReflection =
    bloggDoc?.attributes?.port_reflectional ||
    bloggDoc?.attributes?.blog_reflectional
  const [openReflection, setOpenReflection] = useState(false)

  const translateTags = (tag: string) => {
    switch (tag) {
      case 'Språk':
        return t('tags.language')
      case 'Matematik':
        return t('tags.math')
      case 'Motorik':
        return t('tags.motor_skills')
      case 'Skapande':
        return t('tags.creativity')
      case 'EQ':
        return t('tags.eq')
      default:
        return tag
    }
  }

  const [showMoreTags, setShowMoreTags] = useState<any>([])
  const [showFullScreenModal, setShowFullScreenModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [showInput, setShowInput] = useState(false)
  const [showCurriculumsModal, setShowCurriculumsModal] = useState(false)
  const [selectedVideoSrc, setSelectedVideoSrc] = useState<string>('')
  const hideVideoModalFunction = () => {
    setShowVideoModal(false)
  }
  const handleVideoClick = (media: { type: string; src: string }) => {
    setSelectedVideoSrc(media.src)
    setShowVideoModal(true)
  }
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false)
  const [reflection, setReflection] = useState(
    bloggDoc?.attributes?.blog_reflection ?? '',
  )
  const [textareaValue, setTextareaValue] = useState('')
  useEffect(() => {
    if (showInput) {
      setTextareaValue(reflection)
    }
  }, [showInput])
  const [showLikeModal, setShowLikeModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const measuredRef = useCallback((node: any) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
    }
  }, [])
  const [height, setHeight] = useState(0)
  const [newComment, setNewComment] = useState<string>('')
  const {
    setSelectedComments,
    fetchComments,
    selectedComments,
    addNewComment,
  } = useContext(PortfolioBlogStoreContext)
  const { currentUserRole, readOnly, currentUser } =
    useContext(UserStoreContext)
  const isTeacher =
    currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
      ? true
      : false

  const [showMoreCurriculums, setShowMoreCurriculums] = useState<any>([])
  const { updateReflection, updateHeart } = useContext(
    PortfolioBlogStoreContext,
  )
  const { getCurrContent, getCurrTag } = useContext(ExportStoreContext)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }
  const [buttonType, setButtonType] = useState<'Print' | 'Generate'>()
  const [openExportModal, setOpenExportModal] = useState<string | null>(null)
  const canExport =
    type === 'Blog' && 'Portfolio' ? (isTeacher ? true : false) : true
  const [currentBlogId, setCurrentBlogId] = useState<string | null>(null)
  const [menuRef, togglerRef] = useClickOutside(() => {
    setDropdownVisible(false)
  })
  const handlePrintClick = () => {
    setButtonType('Print')
    setOpenExportModal(bloggDoc.id)
  }
  const handleGeneratePdfClick = () => {
    setButtonType('Generate')
    setOpenExportModal(bloggDoc.id)
  }
  const handleButtonClick = (fileJobId: string, e: React.MouseEvent) => {
    //downloadMediaFiles(fileJobId)
    e.stopPropagation()
  }

  let { schoolId, kidId } = useParams()
  const { downloadAndZip } = useContext(FileJobStoreContext)

  return (
    <>
      <div
        onClick={() => {
          setShowOverviewModal(true)
        }}
        className="flex flex-wrap relative  w-full cursor-pointer shadow-md border border-y-gray-200 rounded-2xl justify-between p-3 hover:bg-blue-50"
      >
        {loading && <Loading />}
        <BlogCommentsModal
          bloggDoc={bloggDoc}
          showModal={showOverviewModal}
          hideModal={() => {
            setShowOverviewModal(false)
          }}
        />
        {/* Author name  */}
        <div className="flex flex-row items-start justify-between gap-y-4 w-full mb-2">
          <div className={'text-xs sm:text-sm w-full flex justify-center'}>
            <p className={'font-bold whitespace-nowrap'}>
              {bloggDoc?.attributes?.user_author?.attributes?.firstName ? (
                bloggDoc?.attributes?.user_author?.attributes?.firstName +
                ' ' +
                bloggDoc?.attributes?.user_author?.attributes?.lastName
              ) : (
                <> {t('general.unknown')}</>
              )}
            </p>
          </div>
          {/* dropdown at the right of the component */}
          <div>
            {canExport && (
              <button
                type="button"
                className="w-full rounded-md group focus:outline-none"
                aria-haspopup="true"
                aria-expanded={dropdownVisible}
                onClick={(e) => {
                  e.stopPropagation()
                  toggleDropdown()
                  setCurrentBlogId(bloggDoc.id)
                }}
                ref={togglerRef}
              >
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="text-gray-400 px-4 hover:scale-110"
                ></FontAwesomeIcon>
              </button>
            )}

            {dropdownVisible && (
              <div
                className="absolute right-7 mx-3  w-[280px] top-3"
                style={{
                  display:
                    dropdownVisible && currentBlogId === bloggDoc.id
                      ? 'block'
                      : 'none',
                }}
                ref={menuRef}
              >
                <ButtonDropdown.Menu key={bloggDoc.id}>
                  <ButtonDropdown.Section>
                    <>
                      <ButtonDropdown.Button
                        key={bloggDoc.id}
                        icon={faPrint}
                        label={t('blog.print')}
                        className="text-purple-300"
                        onClick={(e) => {
                          //setSelectedBlogDocId(bloggDoc.id)
                          setOpenExportModal(bloggDoc.id)
                          handlePrintClick()
                          e.stopPropagation()
                        }}
                      />
                    </>
                    <>
                      <ButtonDropdown.Button
                        key={bloggDoc.id}
                        icon={faFilePdf}
                        label={t('blog.generatePDF')}
                        className="text-green-300"
                        onClick={(e) => {
                          setOpenExportModal(bloggDoc.id)
                          handleGeneratePdfClick()
                          e.stopPropagation()
                        }}
                      />
                    </>

                    <>
                      <ButtonDropdown.Button
                        key={bloggDoc.id}
                        icon={faFolder}
                        label={t('blog.exportMedia')}
                        className="text-blue-300"
                        onClick={(e) => {
                          setLoading(true)
                          downloadAndZip(
                            [bloggDoc],
                            type === 'Blog' ? 'blogg' : 'portfolio',
                            kidFirstName,
                          ).finally(() => {
                            setLoading(false)
                          })
                          e.stopPropagation()
                        }}
                      />
                    </>
                  </ButtonDropdown.Section>
                </ButtonDropdown.Menu>
              </div>
            )}
          </div>
        </div>

        {/* image / video */}
        <div className="flex gap-x-4 flex-nowrap w-full">
          <div className="object-cover border w-1/4 h-24 rounded-2xl ">
            <ImageCarousel
              arrowSmall
              images={bloggDoc?.attributes?.images
                ?.filter((item: any) => !item?.attributes?.audio_file)
                ?.map((item: any) => {
                  if (item?.attributes?.image?._url) {
                    return {
                      type: item?.attributes?.is_video ? 'video' : 'image',
                      thumbnailSrc: item?.attributes?.image?._url,
                      src: item?.attributes?.is_video
                        ? item?.attributes?.video_file
                        : item?.attributes?.image?._url,
                    }
                  }
                  return null
                })
                ?.filter(Boolean)}
              imageOnClick={(media: { type: string; src: string }) => {
                onImageSelected(media.src)
              }}
              videoOnClick={handleVideoClick}
            />
            <VideoModal
              src={selectedVideoSrc}
              showModal={showVideoModal}
              hideModal={hideVideoModalFunction}
            />

            <FullscreenModal
              src={selectedImage}
              showModal={showFullScreenModal}
              hideModal={() => {
                setShowFullScreenModal(false)
              }}
            />
          </div>

          {/* tags at the right along the blog card */}
          <div className="flex flex-col  justify-between w-3/4 p-2">
            <p className="font-normal text-xs sm:text-sm h-auto flex flex-wrap max-w-[500px] line-clamp-1 sm:line-clamp-2  ">
              {bloggDoc?.attributes?.content ?? ''}
            </p>

            {bloggDoc?.attributes?.doc_tags?.length > 0 && (
              <div
                className={
                  ' flex flex-wrap gap-y-1 sm:gap-y-0 font-medium sm:mt-0 gap-x-2  mt-2 flex-row text-black cursor-default'
                }
              >
                {bloggDoc?.attributes?.doc_tags?.map(
                  (tag: any, index: number) => {
                    if (
                      index > 4 &&
                      !showMoreTags.find((tag: any) => tag == bloggDoc.id)
                    ) {
                      return null
                    }
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundColor: tag?.attributes?.bg_color,
                        }}
                        className={
                          'rounded-full py-1 w-auto px-2 text-xs text-center '
                        }
                      >
                        {translateTags(tag?.attributes.name)}
                      </div>
                    )
                  },
                )}
              </div>
            )}
            {bloggDoc?.attributes?.doc_tags &&
              bloggDoc?.attributes?.doc_tags.length > 5 && (
                <div className="flex flex-row">
                  <FontAwesomeIcon
                    icon={faTag}
                    className="text-purple-300 hover:scale-110 cursor-pointer mr-1"
                  />
                  <div
                    onClick={() => {
                      if (showMoreTags.find((tag: any) => tag == bloggDoc.id)) {
                        setShowMoreTags(
                          showMoreTags.filter(
                            (tag: any) => tag !== bloggDoc.id,
                          ),
                        )
                      } else {
                        setShowMoreTags([...showMoreTags, bloggDoc.id])
                      }
                    }}
                    className="text-blue-400 text-xs "
                  >
                    {showMoreTags.find((tag: any) => tag == bloggDoc.id) ? (
                      <>{t('general.hide')}</>
                    ) : (
                      t('general.showAll') +
                      '(+' +
                      (Number(bloggDoc?.attributes?.doc_tags.length) - 5) +
                      ')'
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="flex w-full justify-between mt-4 items-center">
          {/* Audio player  */}
          <div className="">
            <div
              onClick={(e) => {
                e.stopPropagation()
              }}
              className="flex justify-start "
            >
              {' '}
              {bloggDoc?.attributes?.images
                ?.filter((img: any) => img.attributes.audio_file)
                ?.map((audio: any) => {
                  return (
                    <AudioPlayer
                      size="play"
                      src={audio.attributes.audio_file._url}
                    />
                  )
                })}
            </div>
          </div>
          <div className="flex  justify-between">
            {bloggDoc && openExportModal === bloggDoc.id && (
              <ExportModal
                typeOfDoc={type}
                buttonType={buttonType}
                type={'OnlySliders'}
                isOpen={true}
                bloggDoc={bloggDoc}
                onClose={() => {
                  setOpenExportModal(null)
                }}
                onImageSelected={undefined}
                schoolId={schoolId ?? currentUserRole?.school_pointer.id}
                kidName={kidName}
                kidId={kidId ?? kid?.id}
              />
            )}

            <div className="font-bold flex w-fit flex-row gap-x-2 text-xs items-end sm:mr-12 mt-2">
              {showComment && (
                <div className="flex flex-row gap-x-2 items-center">
                  <FontAwesomeIcon
                    className={classNames(
                      'text-xl text-green-500 cursor-pointer hover hover:text-green-700 hover:scale-110',
                      !showComment &&
                        ' text-green-300 cursor-auto hover:text-green-500 hover:scale-110',
                    )}
                    icon={faComment}
                    onClick={() => {
                      if (showComment) {
                        setShowModal(true)
                      }
                    }}
                  />

                  <p
                    className={classNames(
                      ' font-bold cursor-pointer ',
                      !showComment && 'font-bold cursor-default',
                    )}
                    onClick={() => {
                      if (showComment) {
                        setShowModal(true)
                      }
                    }}
                  >
                    ({bloggDoc?.attributes.comments_count ?? 0})
                  </p>
                </div>
              )}
              {/* heart for likeing a blog */}
              <div
                className={
                  'flex flex-row space-x-2 items-center justify-start w-full'
                }
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                {showLikes && (
                  <>
                    <div
                      className={
                        wave
                          ? 'animate-shakeHeart flex flex-row'
                          : 'flex flex-row'
                      }
                    >
                      {bloggDoc.attributes.emote_heart?.find(
                        (id: string) => id === currentUser?.id,
                      ) ? (
                        <FontAwesomeIcon
                          className="text-red-600 text-xl hover:text-red-600 hover:scale-110 cursor-pointer"
                          icon={farFaHeart}
                          onClick={() => {
                            if (isTeacher) {
                              setShowLikeModal(true)
                            } else {
                              updateHeart(
                                bloggDoc,
                                bloggDoc.attributes.emote_heart?.find(
                                  (userId: string) =>
                                    userId === currentUser?.id,
                                ),
                              ).finally(() => {
                                if (onSaveReflections) onSaveReflections()
                              })
                            }
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="text-gray-600 text-xl hover:text-red-600 hover:scale-110 cursor-pointer"
                          icon={faHeart}
                          onClick={() => {
                            if (isTeacher) {
                              setShowLikeModal(true)
                            } else {
                              updateHeart(
                                bloggDoc,
                                bloggDoc.attributes.emote_heart?.find(
                                  (userId: string) =>
                                    userId === currentUser?.id,
                                ),
                              ).finally(() => {
                                if (onSaveReflections) onSaveReflections()
                              })
                            }
                          }}
                        />
                      )}
                    </div>
                    {isTeacher && (
                      <div>
                        ({bloggDoc?.attributes.emote_heart?.length ?? 0}){' '}
                      </div>
                    )}
                  </>
                )}
                <BlogLikeModul
                  showLikeModal={showLikeModal}
                  hideLikeModal={() => {
                    setShowLikeModal(false)
                  }}
                  bloggDoc={bloggDoc}
                />

                {bloggDoc?.attributes?.curriculums &&
                  bloggDoc?.attributes?.curriculums.length > 0 && (
                    <div className="flex flex-row space-x-1 items-center">
                      <FontAwesomeIcon
                        className="text-yellow-500 w-4 h-4 cursor-pointer hover:scale-110 hover:text-yellow-600"
                        icon={faBooks}
                        onClick={() => {
                          {
                            if (showMoreCurriculums) {
                              setShowCurriculumsModal(true)
                            }
                          }
                        }}
                      />{' '}
                      <p className="">
                        {' '}
                        ({bloggDoc?.attributes?.curriculums?.length})
                      </p>
                    </div>
                  )}
                {showReflection && isTeacher && (
                  <>
                    <FontAwesomeIcon
                      className={`text-${
                        bloggDoc.attributes.reflections_count > 0
                          ? 'eventsMain'
                          : 'gray-300'
                      } text-xl ${
                        bloggDoc.attributes.blog_reflection?.find(
                          (id: string) => id === currentUser?.id,
                        )
                          ? 'hover:text-blue-300'
                          : 'hover:text-red-300'
                      } hover:scale-110 cursor-pointer`}
                      icon={
                        bloggDoc.attributes.reflections_count > 0
                          ? faThoughtBubble
                          : farFaThoughtBubble
                      }
                      onClick={() => {
                        setOpenReflection(true)
                      }}
                    />
                    <p>({bloggDoc.attributes.reflections_count ?? 0})</p>
                  </>
                )}

                <ModalRegular
                  isOpen={showCurriculumsModal}
                  onClose={() => {
                    setShowCurriculumsModal(false)
                  }}
                  closeColor="white"
                  bgColor="bg-eventsMain p-4"
                  title={t('blog.governingDocument')}
                >
                  <div className="flex flex-col p-4">
                    <div className="h-auto w-full">
                      {bloggDoc?.attributes?.curriculums &&
                        bloggDoc?.attributes?.curriculums.map((cur: any) => {
                          const content = getCurrContent(cur)
                          const tag = getCurrTag(cur)
                          return (
                            <div className="h-auto w-full mb-4 text-xs text-start  font-medium border shadow p-4 rounded-2xl">
                              <div className="flex p-1 px-2 my-1 text-white bg-eventsMain shadow  rounded-full w-fit">
                                <p>
                                  <>{tag.title}</>
                                </p>
                              </div>
                              "{content}"
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </ModalRegular>
                <BlogReflections
                  openReflection={openReflection}
                  setOpenReflection={() => {
                    setOpenReflection(false)
                  }}
                  reflection={''}
                  reflections={reflections}
                  updateReflection={function (
                    bloggDoc: any,
                    value: string,
                  ): Promise<void> {
                    throw new Error('Function not implemented.')
                  }}
                  bloggDoc={bloggDoc}
                  onSaveReflections={() => {
                    if (onSaveReflections) onSaveReflections()
                  }}
                />
              </div>
            </div>
          </div>

          {/* Time stamp */}
          <div>
            <p className={'text-xs text-gray-500 font-bold'}>
              <TimeStamp
                date={moment(
                  bloggDoc.attributes.edited_date ??
                    bloggDoc.attributes.createdAt,
                )}
                displayMode={'relative'}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
