import React, { useState } from 'react'
import classNames from 'classnames'
import { nanoid } from 'nanoid'
import { FieldProps, getIn, useFormikContext } from 'formik'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

interface Props extends FieldProps {
  label: string
  placeholder?: string
  inputType?: string
  onChange?: (e: any) => void
  value?: string
  disabled?: boolean
  hint?: string
  helpText?: string
  trailingIcon?: IconDefinition
  prependIcon?: IconDefinition
  autoFocus?: boolean
  isTextArea?: boolean
  customInputClass?: string
  className?: string
}

export const Input: React.FC<Props> = ({
  inputType,
  label: labelText,
  helpText,
  hint,
  trailingIcon,
  prependIcon,
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  onChange,
  value,
  autoFocus,
  isTextArea,
  customInputClass,
  className,
  ...props
}) => {
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const hasError = error && touched
  const disabled = props.disabled ?? false
  const ctx = useFormikContext()

  const [id] = useState(nanoid())

  return (
    <div className="space-y-1">
      <div className="flex justify-between">
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {labelText}
        </label>
        {!!hint && (
          <span className="text-sm leading-5 text-gray-500">{hint}</span>
        )}
      </div>

      <div className="relative">
        {prependIcon && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-red-600 pointer-events-none">
            <FontAwesomeIcon
              className="w-4 h-4 text-gray-400"
              icon={prependIcon}
              fixedWidth
            />
          </div>
        )}
        {isTextArea ? (
          <textarea
            id={id}
            className={classNames(
              'block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-xl shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
              hasError &&
                'border-red-300 text-red-900 pr-10 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red',
              disabled && 'bg-gray-100 text-gray-600',
              prependIcon && 'pl-9',
            )}
            autoFocus={autoFocus ?? false}
            placeholder={props.placeholder || ''}
            aria-invalid={hasError}
            aria-describedby={hasError || helpText ? `${id}` : undefined}
            {...field}
            value={value ? value : field.value}
            onChange={onChange ? onChange : field.onChange}
            disabled={disabled || ctx?.isSubmitting}
            rows={4}
          ></textarea>
        ) : (
          <input
            id={id}
            className={classNames(
              customInputClass
                ? customInputClass
                : 'block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
              hasError &&
                'border-red-300 text-red-900 pr-10 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red',
              disabled && 'bg-gray-100 text-gray-600',
              prependIcon && 'pl-9',
            )}
            autoFocus={autoFocus ?? false}
            type={inputType || 'text'}
            placeholder={props.placeholder || ''}
            aria-invalid={hasError}
            aria-describedby={hasError || helpText ? `${id}` : undefined}
            {...field}
            value={value ? value : field.value}
            onChange={onChange ? onChange : field.onChange}
            disabled={disabled || ctx?.isSubmitting}
          />
        )}
        {hasError && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <FontAwesomeIcon
              className="w-4 h-4 text-red-600"
              icon={faExclamationCircle}
              fixedWidth
            />
          </div>
        )}
        {!hasError && trailingIcon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-red-600 pointer-events-none">
            <FontAwesomeIcon
              className="w-4 h-4 text-gray-400"
              icon={trailingIcon}
              fixedWidth
            />
          </div>
        )}
      </div>
      {hasError && (
        <p className="mt-2 pl-3 text-sm text-red-600" id={`${id}`}>
          {error}
        </p>
      )}
      {!hasError && helpText && (
        <p className="mt-2 pl-3 text-sm text-gray-500" id={`${id}`}>
          {helpText}
        </p>
      )}
    </div>
  )
}
