import React, { useContext, useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisH,
  faPen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import { ButtonDropdown } from '../../../components/Dropdown'
import { PortfolioBlogStoreContext } from '../../../store/portfolioBlogStore'
import { t } from 'i18next'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import { faBoxArchive } from '@fortawesome/pro-solid-svg-icons'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { UserStoreContext } from '../../../store/userStore'
import { BloggComments } from '../../../Models/BloggComments'
import ModalRegular from '../../../components/ModalRegular'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components/Button'
import { ARCHIVE_STATE, USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import { getImageFromUser } from '../../../utils/parseUtils'

interface CommentDisplayProps {
  comment: BloggComments
  getImageFromRole: (user: any) => string
  onSaveReflections?: () => void
  ref?: React.RefObject<HTMLDivElement>
}

const CommentDisplay = React.forwardRef<HTMLDivElement, CommentDisplayProps>(
  ({ comment, getImageFromRole, onSaveReflections }, ref) => {
    const { deleteComment, editComment, fetchComments } = useContext(
      PortfolioBlogStoreContext,
    )
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()
    const [currentCommentId, setCurrentCommentId] = useState<string | null>(
      null,
    )

    const { currentUser, currentUserRole, readOnly } =
      useContext(UserStoreContext)

    const [menuRef, togglerRef] = useClickOutside(() => setIsOpen(false))

    const [dropdownVisible, setDropdownVisible] = useState(false)

    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible)
    }
    const [editing, setEditing] = useState(false)
    const [editedMessage, setEditedMessage] = useState('')
    const startEditing = () => {
      setEditing(true)
      setEditedMessage(comment.get('message'))
    }

    const isTeacher =
      currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
        ? true
        : false

    const isAdmin =
      currentUserRole && currentUserRole?.role_type >= USER_ROLE_TYPE.ADMIN
        ? true
        : false

    return (
      <div ref={ref} className={'flex w-full flex-col p-3 relative'}>
        <div className={'flex space-x-2 items-center '}>
          <img
            className={'w-[30px] h-[30px] object-cover rounded-full'}
            src={getImageFromUser(comment?.attributes?.user_author)}
            alt=""
          />
          <p className={'font-medium text-sm'}>
            {comment?.attributes?.user_author.attributes.archive_state ===
            ARCHIVE_STATE.HISTORIC_ANONYMOUS ? (
              <>{t('general.unknown')}</>
            ) : (
              <>
                {comment?.attributes?.user_author?.attributes?.firstName ? (
                  <>
                    {comment?.attributes?.user_author?.attributes?.firstName}{' '}
                    {comment?.attributes?.user_author?.attributes?.lastName}
                  </>
                ) : (
                  <>{t('general.unknown')}</>
                )}
              </>
            )}
          </p>
        </div>
        <span className={'pl-[38px] text-sm font-normal flex flex-wrap'}>
          {comment?.attributes?.message}
        </span>
        <p className={'text-xs text-gray-300 mt-1 pl-9'}>
          {moment(comment?.createdAt).format('YYYY-MM-DD HH:MM')}
        </p>
        <div className="absolute top-2 right-2">
          {(comment?.attributes?.user_author &&
            currentUser &&
            currentUser.id === comment?.attributes?.user_author.id) ||
          isAdmin ? (
            <button
              ref={togglerRef}
              type="button"
              className="w-full rounded-md group focus:outline-none "
              aria-haspopup="true"
              aria-expanded={dropdownVisible}
              onClick={() => {
                toggleDropdown()
                setCurrentCommentId(comment.id)
              }}
            >
              <FontAwesomeIcon
                icon={faEllipsisH}
                className="text-gray-400 px-4 hover:scale-110 "
              ></FontAwesomeIcon>
            </button>
          ) : (
            <></>
          )}
          {dropdownVisible && (
            <div
              className="absolute right-5 z-50 mx-3 origin-top divide-y w-44 top-4"
              style={{
                display:
                  dropdownVisible && currentCommentId == comment.id
                    ? 'block'
                    : 'none',
              }}
              ref={menuRef}
            >
              <ButtonDropdown.Menu>
                <ButtonDropdown.Section>
                  {comment?.attributes?.user_author &&
                    currentUser &&
                    currentUser.id === comment?.attributes?.user_author.id && (
                      <ButtonDropdown.Button
                        icon={faBoxArchive}
                        label={t('absence.change')}
                        className="text-blue-400"
                        onClick={async () => {
                          startEditing()
                          /*const result = await Swal.fire({
                      title: 'Är du säker?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Ja, ta bort det!',
                      cancelButtonText: 'Nej, behåll det',
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                    })
                    if (result.isConfirmed) {
                      editComment(comment, editedMessage)
                      fetchComments(comment)
                    }*/
                        }}
                      />
                    )}
                  <ButtonDropdown.Button
                    icon={faTrashCan}
                    label={t('absence.delete')}
                    className="text-red-600"
                    onClick={async () => {
                      const result = await Swal.fire({
                        title: t('blog.bloggRemoveComment'),
                        text: t('blog.bloggRemoveCommentText'),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: t('general.ok'),
                        cancelButtonText: t('general.cancel'),
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#7eb5f4',
                      })

                      if (result.isConfirmed) {
                        if (currentUser) {
                          deleteComment(comment, currentUser).finally(() => {
                            if (onSaveReflections) {
                              onSaveReflections()
                              fetchComments(comment.attributes.blogg_doc)
                            }
                          })
                        }
                      }
                    }}
                  />
                </ButtonDropdown.Section>
              </ButtonDropdown.Menu>
            </div>
          )}
        </div>
        {editing && (
          <ModalRegular
            isOpen={editing}
            onClose={() => {
              setEditing(false)
              setEditedMessage('')
            }}
          >
            <div className="p-4 text-center text-white bg-eventsMain font-bold">
              {t('blog.edit')}
            </div>
            <div className="p-5">
              <textarea
                placeholder={t('notes.commentHere')}
                className="p-3 pr-9 resize-none text-sm border w-full rounded-2xl max-h-[70px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                value={editedMessage}
                onChange={(e) => {
                  setEditedMessage(e.target.value)
                }}
                rows={4}
              />
              <div className="flex justify-between">
                <Button
                  variant="danger"
                  label={t('general.cancel')}
                  onClick={() => {
                    setEditing(false)
                    setEditedMessage('')
                  }}
                />
                <Button
                  variant="fullBlue"
                  label={t('general.save')}
                  onClick={() => {
                    editComment(comment, editedMessage).finally(() => {
                      setEditing(false)
                      setEditedMessage('')
                    })
                  }}
                />
              </div>
            </div>
          </ModalRegular>
        )}
      </div>
    )
  },
)

export default CommentDisplay
