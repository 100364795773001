import { t } from 'i18next'
import { useContext, useEffect, useRef, useState } from 'react'
import ColorCard from '../../components/ColorCard'
import { NavigationStoreContext } from '../../store/navigationStore'
import Page from '../../components/Page'
import { Button } from '../../components/Button'
import Swal from 'sweetalert2'
import { NoticeItem } from './componentes/NoticeItem'
import { UserStoreContext } from '../../store/userStore'
import { NoticeAndPush } from '../settings/NoticeAndPush'
import { Loading } from '../../components/Loading'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons'

interface OriginItem {
  attributes: {
    title: string
    date: Date
  }
}
type Notice = {
  type: string
  marked: boolean
  seen: boolean
  date: string
  header: string
}

type CategorizedNotices = {
  Today: Notice[]
  Week: Notice[]
  Month: Notice[]
  AllTime: Notice[]
}

export const NoticeCenter: React.FC<{}> = ({}) => {
  const {
    fetchNotice,
    noticeCenterNewPost,
    schedulePressence,
    messagesAndPosts,
    authorized,
    allNotices,
    setMessagesAndPosts,
    setNoticeCenterNewPost,
    setSchedulePressence,
    setAuthorized,
  } = useContext(UserStoreContext)
  // console.log('behörigheter', authorized)
  //console.log('nya inlägg', noticeCenterNewPost)
  // console.log('schema/närvaro', schedulePressence)
  //console.log('meddelande', messagesAndPosts)
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('Aviseringscenter'))
  }, [t])
  const [loading, setLoading] = useState(false)

  const [notices, setNotices] = useState(noticeCenterNewPost)

  const toggleMarkNotice = (
    noticeId: string,
    arrayType:
      | 'messagesAndPosts'
      | 'noticeCenterNewPost'
      | 'authorized'
      | 'schedulePressence',
  ) => {
    const updateNoticeInArray = (array: any[]) =>
      array.map((notice) => {
        if (notice.id === noticeId) {
          // Assuming each notice has a unique `id` property
          return { ...notice, marked: !notice.marked }
        }
        return notice
      })

    switch (arrayType) {
      case 'messagesAndPosts':
        setMessagesAndPosts((prev: any[]) => updateNoticeInArray(prev))
        break
      case 'noticeCenterNewPost':
        setNoticeCenterNewPost((prev: any[]) => updateNoticeInArray(prev))
        break
      case 'authorized':
        setAuthorized((prev: any[]) => updateNoticeInArray(prev))
        break
      case 'schedulePressence':
        setSchedulePressence((prev: any) => updateNoticeInArray(prev))
        break
      default:
        // Handle unknown array type if necessary
        break
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchNotice().finally(() => {
      setLoading(false)
    })
  }, [fetchNotice])
  /*useEffect(() => {
    const combinedNotices = [
      ...(noticeCenterNewPost || []),
      ...(schedulePressence || []),
      ...(messagesAndPosts || []),
      ...(authorized || []),
    ]
    setNotices(combinedNotices)
  }, [noticeCenterNewPost, schedulePressence, messagesAndPosts, authorized])*/

  const toggleSeen = (index: number) => {
    const updatedNotices = notices?.map((notice, idx) => {
      if (index === idx) {
        return { ...notice, seen: !notice.seen }
      }
      return notice
    })
    setNotices(updatedNotices)
  }
  const today = new Date()
  const oneWeekAgo = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7,
  )
  const oneMonthAgo = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate(),
  )

  const startOfToday = new Date(today.setHours(0, 0, 0, 0))
  const startOfOneWeekAgo = new Date(oneWeekAgo.setHours(0, 0, 0, 0))
  const startOfOneMonthAgo = new Date(oneMonthAgo.setHours(0, 0, 0, 0))
  const categorizeNotices = (): CategorizedNotices => {
    const categorizedNotices: CategorizedNotices = {
      Today: [],
      Week: [],
      Month: [],
      AllTime: [],
    }

    notices?.forEach((notice) => {
      const noticeDate = new Date(notice.date)

      if (noticeDate >= startOfToday) {
        categorizedNotices.Today.push(notice)
      } else if (noticeDate >= startOfOneWeekAgo && noticeDate < startOfToday) {
        categorizedNotices.Week.push(notice)
      } else if (
        noticeDate >= startOfOneMonthAgo &&
        noticeDate < startOfOneWeekAgo
      ) {
        categorizedNotices.Month.push(notice)
      } else {
        categorizedNotices.AllTime.push(notice)
      }
    })

    return categorizedNotices
  }

  const [activeTab, setActiveTab] = useState('Allt')
  const [highlightStyle, setHighlightStyle] = useState({})
  const tabsRef = useRef<(HTMLDivElement | null)[]>([])
  const [showFirstGroup, setShowFirstGroup] = useState(true)
  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 450)

  const tabs = [
    'Allt',
    'Meddelande/kommentar',
    'Nya Inlägg',
    'Schema/närvaro',
    'Behörigheter',
  ]

  useEffect(() => {
    const visibleTabs = tabs.filter((_, index) =>
      isNarrowScreen ? (showFirstGroup ? index < 3 : index >= 3) : true,
    )

    const activeTabIndex = visibleTabs.indexOf(activeTab)

    const currentTab = tabsRef.current[activeTabIndex]
    if (currentTab) {
      setHighlightStyle({
        width: `${currentTab.offsetWidth}px`,
        transform: `translateX(${currentTab.offsetLeft}px)`,
      })
    }
  }, [isNarrowScreen, activeTab])

  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 450)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  /*const areAllMarked =
    messagesAndPosts?.every((notice) => notice.marked) ?? false*/

  const toggleTabGroup = () => {
    if (showFirstGroup) {
      setShowFirstGroup(false)
      setActiveTab('Schema/närvaro')
    } else {
      setShowFirstGroup(true)
      setActiveTab('Allt')
    }
  }
  const [fakeState, setFakeState] = useState(false)
  return (
    <Page
      naked
      topBar={
        <>
          <div className="w-full rounded p-1 relative">
            <div className="flex items-center justify-start w-full overflow-x-auto">
              {tabs
                .filter((tab, index) =>
                  isNarrowScreen
                    ? showFirstGroup
                      ? index < 3
                      : index >= 3
                    : true,
                )
                .map((tab, index) => (
                  <div
                    key={tab}
                    ref={(el) => (tabsRef.current[index] = el)}
                    onClick={() => setActiveTab(tab)}
                    className={`flex-1 text-center py-3 cursor-pointer text-xs ${
                      activeTab === tab ? 'text-blue-500' : 'text-gray-600'
                    }`}
                  >
                    {tab}
                  </div>
                ))}
              {isNarrowScreen && (
                <button
                  onClick={() => {
                    setShowFirstGroup(!showFirstGroup)
                    setActiveTab(showFirstGroup ? 'Schema/närvaro' : 'Allt')
                  }}
                  className={`text-xs py-3 px-4 ml-auto ${
                    showFirstGroup
                      ? 'absolute top-2 -right-3'
                      : 'absolute top-2 -left-3'
                  } `}
                >
                  {showFirstGroup ? (
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="hover:text-eventsMain"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="hover:text-eventsMain"
                    />
                  )}{' '}
                </button>
              )}
            </div>
            <div
              className="absolute bottom-0 h-1 rounded-full bg-blue-500 transition-transform duration-300 ease-in-out"
              style={highlightStyle}
            />
          </div>
        </>
      }
    >
      {loading && <Loading />}

      <ColorCard
        actionButton={
          <div className="flex gap-x-2">
            <Button
              onClick={() => {
                Swal.fire({
                  text: 'Are you sure you want to clear all notices?',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.yes'),
                  cancelButtonText: t('general.cancel'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    setNotices(
                      notices?.map((notice) => ({ ...notice, seen: true })),
                    )
                  }
                })
              }}
              size="sm"
              label="Läs alla"
              className="text-eventsMain"
              variant={'blue'}
            />
          </div>
        }
        bgColor="bg-blue-400 p-4 justify-center flex"
        title={t('Aviseringscenter')}
      >
        <div className="flex flex-wrap flex-col p-10 gap-y-6">
          {activeTab === 'Allt' && (
            <div className="flex flex-wrap flex-col">
              <div className="flex justify-between flex-col items-start mb-2">
                <p className="text-gray-500 mb-2">Allt</p>
              </div>
              <div>
                {allNotices ? (
                  allNotices.length > 0 ? (
                    allNotices.map((notice, index) => (
                      <NoticeItem
                        content={notice?.content}
                        header={notice?.header}
                        createdBy={notice.createdBy}
                        updatedAt={moment(notice.updatedAt).format(
                          'YYYY-MM-DD',
                        )}
                        createdAt={moment(notice.createdAt).format(
                          'YYYY-MM-DD',
                        )}
                        //relation={notice?.attributes?.notice_creator?.relation}
                        readAt={moment(notice.readAt).format('YYYY-MM-DD')}
                        editedAt={notice.editedAt}
                        key={notice.id}
                        index={index}
                        /*toggleMarkNotice={() => {
                          toggleMarkNotice(notice, 'messagesAndPosts')
                          setFakeState(!fakeState)
                        }}
                        toggleSeen={() => {
                          toggleSeen(index)
                          setFakeState(!fakeState)
                        }}*/
                      />
                    ))
                  ) : (
                    <p className="text-gray-500 font-medium">
                      Inga nya inlägg 🤔
                    </p>
                  )
                ) : (
                  <p className="text-gray-500 font-medium">Laddar inlägg...</p>
                )}
              </div>
            </div>
          )}
          {activeTab === 'Meddelande/kommentar' && (
            <div className="flex flex-wrap flex-col">
              {/* Existing code for rendering categorized notices */}
              <div className="flex justify-between flex-col items-start mb-2">
                <p className="text-gray-500 mb-2">Meddelande/kommentarer</p>
              </div>
              {/*<div className="flex flex-row gap-x-2 items-center">
                <p className="text-sm">Markera alla</p>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    className="hidden-checkbox"
                    /*checked={
                      messagesAndPosts?.every((notice) => notice.marked) ??
                      false
                    }
                    onChange={(e) => {
                      setFakeState(!fakeState)
                    }}
                  />
                  <span className="checkbox-style"></span>
                </label>
                  </div>*/}
              <div>
                {messagesAndPosts ? (
                  messagesAndPosts.length > 0 ? (
                    messagesAndPosts.map((notice, index) => (
                      <NoticeItem
                        content={notice?.content}
                        header={notice?.header}
                        createdBy={notice.createdBy}
                        updatedAt={moment(notice.updatedAt).format(
                          'YYYY-MM-DD',
                        )}
                        createdAt={moment(notice.createdAt).format(
                          'YYYY-MM-DD',
                        )}
                        //relation={notice?.attributes?.notice_creator?.relation}
                        readAt={moment(notice.readAt).format('YYYY-MM-DD')}
                        editedAt={notice.editedAt}
                        key={notice.id}
                        index={index}
                        /*toggleMarkNotice={() => {
                          toggleMarkNotice(notice, 'messagesAndPosts')
                          setFakeState(!fakeState)
                        }}
                        toggleSeen={() => {
                          toggleSeen(index)
                          setFakeState(!fakeState)
                        }}*/
                      />
                    ))
                  ) : (
                    <p className="text-gray-500 font-medium">
                      Inga nya inlägg 🤔
                    </p>
                  )
                ) : (
                  <p className="text-gray-500 font-medium">Laddar inlägg...</p>
                )}
              </div>
            </div>
          )}
          {activeTab === 'Nya Inlägg' && (
            <div className="flex flex-wrap flex-col">
              {/* Existing code for rendering categorized notices */}
              <div className="flex justify-between flex-col items-start mb-2">
                <p className="text-gray-500 mb-2">Nya Inlägg</p>
              </div>
              {/*<div className="flex flex-row gap-x-2 items-center">
                <p className="text-sm">Markera alla</p>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    className="hidden-checkbox"
                    /*checked={
                      messagesAndPosts?.every((notice) => notice.marked) ??
                      false
                    }
                    onChange={(e) => {
                      setFakeState(!fakeState)
                    }}
                  />
                  <span className="checkbox-style"></span>
                </label>
                  </div>*/}
              <div>
                {noticeCenterNewPost ? (
                  noticeCenterNewPost.length > 0 ? (
                    noticeCenterNewPost.map((notice, index) => (
                      <NoticeItem
                        content={notice?.content}
                        header={notice?.header}
                        createdBy={notice.createdBy}
                        updatedAt={moment(notice.updatedAt).format(
                          'YYYY-MM-DD',
                        )}
                        createdAt={moment(notice.createdAt).format(
                          'YYYY-MM-DD',
                        )}
                        //relation={notice?.attributes?.notice_creator?.relation}
                        readAt={moment(notice.readAt).format('YYYY-MM-DD')}
                        editedAt={notice.editedAt}
                        key={notice.id}
                        index={index}
                        /*toggleMarkNotice={() => {
                        toggleMarkNotice(notice, 'messagesAndPosts')
                        setFakeState(!fakeState)
                      }}
                      toggleSeen={() => {
                        toggleSeen(index)
                        setFakeState(!fakeState)
                      }}*/
                      />
                    ))
                  ) : (
                    <p className="text-gray-500 font-medium">
                      Inga nya inlägg 🤔
                    </p>
                  )
                ) : (
                  <p className="text-gray-500 font-medium">Laddar inlägg...</p>
                )}
              </div>
            </div>
          )}
          {activeTab === 'Schema/närvaro' && (
            <div className="flex flex-wrap flex-col">
              <div className="flex justify-between flex-col items-start mb-2">
                <p className="text-gray-500 mb-2">schema/närvaro</p>
              </div>
              {/*<div className="flex flex-row gap-x-2 items-center">
                <p className="text-sm">Markera alla</p>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    className="hidden-checkbox"
                    /*checked={
                      messagesAndPosts?.every((notice) => notice.marked) ??
                      false
                    }
                    onChange={(e) => {
                      setFakeState(!fakeState)
                    }}
                  />
                  <span className="checkbox-style"></span>
                </label>
                  </div>*/}
              <div>
                {schedulePressence ? (
                  schedulePressence.length > 0 ? (
                    schedulePressence.map((notice, index) => (
                      <NoticeItem
                        content={notice?.content}
                        header={notice?.header}
                        createdBy={notice.createdBy}
                        updatedAt={moment(notice.updatedAt).format(
                          'YYYY-MM-DD',
                        )}
                        createdAt={moment(notice.createdAt).format(
                          'YYYY-MM-DD',
                        )}
                        //relation={notice?.attributes?.notice_creator?.relation}
                        readAt={moment(notice.readAt).format('YYYY-MM-DD')}
                        editedAt={notice.editedAt}
                        key={notice.id}
                        index={index}
                        /*toggleMarkNotice={() => {
                        toggleMarkNotice(notice, 'messagesAndPosts')
                        setFakeState(!fakeState)
                      }}
                      toggleSeen={() => {
                        toggleSeen(index)
                        setFakeState(!fakeState)
                      }}*/
                      />
                    ))
                  ) : (
                    <p className="text-gray-500 font-medium">
                      Inga nya inlägg 🤔
                    </p>
                  )
                ) : (
                  <p className="text-gray-500 font-medium">Laddar inlägg...</p>
                )}
              </div>
            </div>
          )}
          {activeTab === 'Behörigheter' && (
            <div className="flex flex-wrap flex-col">
              {/* Existing code for rendering categorized notices */}
              <div className="flex justify-between flex-col items-start mb-2">
                <p className="text-gray-500 mb-2">Behörigheter</p>
              </div>
              {/*<div className="flex flex-row gap-x-2 items-center">
                <p className="text-sm">Markera alla</p>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    className="hidden-checkbox"
                    /*checked={
                      messagesAndPosts?.every((notice) => notice.marked) ??
                      false
                    }
                    onChange={(e) => {
                      setFakeState(!fakeState)
                    }}
                  />
                  <span className="checkbox-style"></span>
                </label>
                  </div>*/}
              <div>
                {authorized ? (
                  authorized.length > 0 ? (
                    authorized.map((notice, index) => (
                      <NoticeItem
                        content={notice?.content}
                        header={notice?.header}
                        createdBy={notice.createdBy}
                        updatedAt={moment(notice.updatedAt).format(
                          'YYYY-MM-DD',
                        )}
                        createdAt={moment(notice.createdAt).format(
                          'YYYY-MM-DD',
                        )}
                        //relation={notice?.attributes?.notice_creator?.relation}
                        readAt={moment(notice.readAt).format('YYYY-MM-DD')}
                        editedAt={notice.editedAt}
                        key={notice.id}
                        index={index}
                        /*toggleMarkNotice={() => {
                        toggleMarkNotice(notice, 'messagesAndPosts')
                        setFakeState(!fakeState)
                      }}
                      toggleSeen={() => {
                        toggleSeen(index)
                        setFakeState(!fakeState)
                      }}*/
                      />
                    ))
                  ) : (
                    <p className="text-gray-500 font-medium">
                      Inga nya inlägg 🤔
                    </p>
                  )
                ) : (
                  <p className="text-gray-500 font-medium">Laddar inlägg...</p>
                )}
              </div>
            </div>
          )}
        </div>
      </ColorCard>
    </Page>
  )
}

export default NoticeCenter
