import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button } from '../../../components/Button'
import ModalRegular from '../../../components/ModalRegular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import { t } from 'i18next'
import ContactCard from './components/ContactCard'
import { SchoolStoreContext } from '../../../store/schoolStore'
import { useParams } from 'react-router-dom'
import { string } from 'yup'
import { Department } from '../../../Models/Department'
import Swal from 'sweetalert2'
import { create } from 'domain'
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import Parse from 'parse'
import { ImageUploader } from '../../../components/ImageUploader'
import CustomInput from '../../../components/CustomInput'

interface ContactModalProps {
  onClose: () => void
  isOpen: boolean
}

interface NewContact {
  klassName: string
  lastName: string
  contactPic: any | null
  workTitle: string
  teleNumber: string
  otherMobNum: string
  email: string
  adress: string
  areaCode: string
  city: string
}

let initialValues = {
  klassName: '',
  lastName: '',
  contactPic: null,
  workTitle: '',
  teleNumber: '',
  otherMobNum: '',
  email: '',
  adress: '',
  areaCode: '',
  city: '',
}

const ContactModal: React.FC<ContactModalProps> = ({ onClose, isOpen }) => {
  const titleNameInputRef = useRef<HTMLInputElement>(null)
  const nameInputRef = useRef<HTMLInputElement>(null)
  const surNameInputRef = useRef<HTMLInputElement>(null)
  const numberInputRef = useRef<HTMLInputElement>(null)
  const otherNumberInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const adressInputRef = useRef<HTMLInputElement>(null)
  const areaCodeInputRef = useRef<HTMLInputElement>(null)
  const cityInputRef = useRef<HTMLInputElement>(null)

  const [imageUrl, setImageUrl] = useState<string>('')
  const [newContact, setNewContact] = useState<NewContact>(initialValues)
  const uploadImageToParse = async (file: any) => {
    var extension = file.name.split('.').pop()
    var parseImageFile = new Parse.File('contactPic.' + extension, file)

    await parseImageFile.save()
    return parseImageFile
  }

  const [showAddContact, setShowAddContact] = useState(false)
  const {
    getContactPersons,
    fetchSingleSchool,
    school,
    contactPersons,
    createNewContact,
  } = useContext(SchoolStoreContext)
  const handleSave = async (values: any) => {
    try {
      if (values.contactPic) {
        values.contactPic = await uploadImageToParse(values.contactPic)
      }
      if (schoolId) {
        await createNewContact(schoolId, values)
        setNewContact(initialValues)
        setImageUrl('')
        Swal.fire({
          icon: 'success',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        }).finally(() => {
          if (schoolId) {
            getContactPersons(schoolId)
          }
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#7eb5f4',
        cancelButtonColor: '#d33',
        confirmButtonText: t('general.ok'),
      })
    }
  }
  const [fakeState, setFakeState] = useState('')

  const { schoolId } = useParams()
  useEffect(() => {
    if (schoolId) {
      fetchSingleSchool(schoolId)
      getContactPersons(schoolId)
    }
  }, [schoolId])
  const focusInput = (
    e: React.MouseEvent,
    inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e.stopPropagation()
    inputRef.current?.focus()
  }

  const handleNewImage = (file: File) => {
    setNewContact({
      ...newContact,
      contactPic: file,
    })
  }
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <ModalRegular
      onClose={onClose}
      isOpen={isOpen}
      bgColor="bg-yellow-300 p-4"
      title={t('ourKids.contacts')}
      closeColor="white"
    >
      <div className="flex flex-wrap flex-col w-full items-center pb-4">
        {contactPersons?.map((contact) => {
          return <ContactCard contact={contact} />
        })}

        <div className="flex justify-end w-full pr-4">
          <Button
            icon={faPlus}
            variant="fullBlue"
            onClick={() => {
              setShowAddContact(true)
            }}
          />
        </div>
        <ModalRegular
          onClose={() => {
            setShowAddContact(false)
          }}
          isOpen={showAddContact}
          bgColor="bg-yellow-300 p-4"
          title={t('admin.addContact')}
          closeColor="white"
          className="max-w-lg"
        >
          <div className="flex flex-col p-4 bg-gray-50 gap-y-4">
            <div className="flex flex-row w-full justify-center">
              <ImageUploader size="lg" onImageChange={handleNewImage} />
            </div>
            <CustomInput
              label={t('admin.title')}
              value={newContact.workTitle}
              onChange={(value) => {
                setNewContact({ ...newContact, workTitle: value })
                setFakeState(value)
              }}
              required={true}
              placeholder={t('admin.title')}
              ref={titleNameInputRef}
            />
            <CustomInput
              label={t('admin.firstName')}
              value={newContact.klassName}
              onChange={(value) => {
                setNewContact({
                  ...newContact,
                  klassName: capitalizeFirstLetter(value),
                })
                setFakeState(value)
              }}
              required={newContact.klassName === ''}
              placeholder={t('admin.firstName')}
              ref={nameInputRef}
            />
            <CustomInput
              label={t('admin.surName')}
              value={newContact.lastName}
              onChange={(value) => {
                setNewContact({
                  ...newContact,
                  lastName: capitalizeFirstLetter(value),
                })
                setFakeState(value)
              }}
              required={newContact.lastName === ''}
              placeholder={t('admin.surName')}
              ref={surNameInputRef}
            />
            <CustomInput
              label={t('ourKids.phone')}
              value={newContact.teleNumber}
              onChange={(value) => {
                setNewContact({ ...newContact, teleNumber: value })
                setFakeState(value)
              }}
              format="default"
              //required={true}
              onlyNumbers={true}
              placeholder={t('ourKids.phone')}
              ref={numberInputRef}
            />
            <CustomInput
              label={t('admin.otherNumber')}
              value={newContact.otherMobNum}
              onChange={(value) => {
                setNewContact({
                  ...newContact,
                  otherMobNum: value,
                })
                setFakeState(value)
              }}
              format="default"
              //required={true}
              onlyNumbers={true}
              placeholder={t('admin.otherNumber')}
              ref={otherNumberInputRef}
            />
            <CustomInput
              label={t('login.email')}
              value={newContact.email}
              onChange={(value) => {
                setNewContact({ ...newContact, email: value })
                setFakeState(value)
              }}
              format="default"
              //required={true}
              placeholder={t('login.email')}
              ref={emailInputRef}
            />
            <CustomInput
              label={t('contactList.adress')}
              value={newContact.adress}
              onChange={(value) => {
                setNewContact({
                  ...newContact,
                  adress: capitalizeFirstLetter(value),
                })
                setFakeState(value)
              }}
              format="default"
              //required={true}
              placeholder={t('contactList.adress')}
              ref={adressInputRef}
            />
            <CustomInput
              label={t('admin.areaCode')}
              value={newContact.areaCode}
              onChange={(value) => {
                setNewContact({ ...newContact, areaCode: value })
                setFakeState(value)
              }}
              onlyNumbers={true}
              format="default"
              //required={true}
              placeholder={t('admin.areaCode')}
              ref={areaCodeInputRef}
            />
            <CustomInput
              label={t('admin.city')}
              value={newContact.city}
              onChange={(value) => {
                setNewContact({
                  ...newContact,
                  city: capitalizeFirstLetter(value),
                })
                setFakeState(value)
              }}
              format="default"
              //required={true}
              placeholder={t('admin.city')}
              ref={cityInputRef}
            />
            <div className="flex w-full justify-between mt-1">
              <Button
                size="sm"
                variant="fullRed"
                label={t('general.cancel')}
                onClick={() => {
                  setShowAddContact(false)
                }}
              />
              <Button
                size="sm"
                variant="fullBlue"
                label={t('general.save')}
                disabled={
                  !newContact.workTitle ||
                  !newContact.klassName ||
                  !newContact.lastName
                }
                onClick={() => {
                  if (school) {
                    handleSave(newContact).finally(() => {
                      setShowAddContact(false)
                      Swal.fire({
                        icon: 'success',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      }).finally(() => {
                        if (schoolId) {
                          getContactPersons(schoolId)
                        }
                      })
                    })
                  }
                }}
              />
            </div>
          </div>
        </ModalRegular>
      </div>
    </ModalRegular>
  )
}

export default ContactModal
