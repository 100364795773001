import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { UserRole } from '../Models/UserRole'

class ContactStore {
  contact?: UserRole
  constructor() {
    makeAutoObservable(this)
  }

  setContact = (contact: UserRole) => {
    this.contact = contact
  }

  fetchContact = async (id: string) => {
    const role = await new Parse.Query(UserRole)
      .include('user.user_extra')
      .include('alternative_contact')
      .get(id)
    this.setContact(role)
  }
}

export const contactStore = (() => {
  return new ContactStore()
})()
export const ContactStoreContext: React.Context<ContactStore> =
  React.createContext(contactStore)
