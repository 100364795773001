import Parse from 'parse'
import { SKA_VISIBILITY } from '../Enums/TyraEnums'
import { Kid } from './Kid'
import { School } from './School'
import { User } from './User'

// Superclass to SkaOperationsPlanning, SkaProjectPlanning and SkaReflections

export abstract class SkaDocument extends Parse.Object {
  get name(): string {
    return this.get('name')
  }
  set name(value: string) {
    this.set('name', value)
  }
  get author(): User {
    return this.get('author')
  }
  set author(value: User) {
    this.set('author', value)
  }
  get edited_date(): Date {
    return this.get('edited_date')
  }
  set edited_date(value: Date) {
    this.set('edited_date', value)
  }

  get visibility(): SKA_VISIBILITY {
    return this.get('visibility')
  }
  set visibility(value: SKA_VISIBILITY) {
    this.set('visibility', value)
  }

  get cards(): string[] {
    return this.get('cards')
  }
  set cards(value: string[]) {
    this.set('cards', value)
  }

  get draft_shared_users(): string[] {
    return this.get('draft_shared_users')
  }
  set draft_shared_users(value: string[]) {
    this.set('draft_shared_users', value)
  }
  get tagged_kids(): Kid[] {
    return this.get('tagged_kids')
  }
  set tagged_kids(value: Kid[]) {
    this.set('tagged_kids', value)
  }

  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
}
