import Parse from 'parse'
import { KID_ACTION, NOTIFICATION_MODULE } from '../Enums/TyraEnums'
import { AlternativeContact } from './AlternativeContact'
import { Department } from './Department'
import { School } from './School'
import { User } from './User'
import { UserRole } from './UserRole'

export class Kid extends Parse.Object {
  public static readonly Fields = {
    objectId: 'objectId',
    setNotice: 'setNotice',
    departmentPointer: 'departmentPointer',
    school: 'school',
    school_pointer: 'school_pointer',
    relatives: 'relatives',
    className: 'kids',
    department: 'department',
  }
  constructor() {
    super('kids')
  }

  get id(): string {
    return this.get('id')
  }

  get noticeUser(): User {
    return User.createWithoutData(this.get('notice_user_id')) as User
  }
  get noticeUserId(): string {
    return this.get('notice_user_id')
  }

  get removed_from_ss12k(): string {
    return this.get('removed_from_ss12k')
  }

  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
  get setNotice(): boolean {
    return this.get('setNotice')
  }
  set setNotice(input: boolean) {
    this.set('setNotice', input)
  }
  get action(): KID_ACTION {
    return this.get('action')
  }
  set action(input: KID_ACTION) {
    this.set('action', input)
  }
  get firstName(): string {
    return this.get('firstName')
  }
  set firstName(input: string) {
    this.set('firstName', input)
  }
  get lastName(): string {
    return this.get('lastName')
  }
  set lastName(input: string) {
    this.set('lastName', input)
  }
  get kidImage(): Parse.File | undefined {
    return this.get('kidImage')
  }
  get kidImage_thumb(): Parse.File | undefined {
    return this.get('kidImage_thumb')
  }
  get kid_pers_id_num(): string | undefined {
    return this.get('kid_pers_id_num')
  }
  set kid_pers_id_num(input: string | undefined) {
    this.set('kid_pers_id_num', input)
  }
  get checkInOutDate(): Date {
    return this.get('checkInOutDate')
  }
  set checkInOutDate(input: Date) {
    this.set('checkInOutDate', input)
  }
  get relatives(): UserRole[] {
    return this.get('relatives')
  }
  set relatives(input: UserRole[]) {
    this.set('relatives', input)
  }
  get who_will_pick_up(): UserRole | undefined {
    return this.get('who_will_pick_up')
  }
  set who_will_pick_up(input: UserRole | undefined) {
    this.set('who_will_pick_up', input)
  }
  get timeToPickUp(): string {
    return this.get('timeToPickUp')
  }
  set timeToPickUp(input: string) {
    this.set('timeToPickUp', input)
  }
  get pickupInfo(): string | undefined {
    return this.get('pickupInfo')
  }
  set pickupInfo(input: string | undefined) {
    this.set('pickupInfo', input)
  }
  get departmentPointer(): Department {
    return this.get('departmentPointer')
  }
  set departmentPointer(input: Department) {
    this.set('departmentPointer', input)
  }
  get whoCheckInOut(): User {
    return this.get('whoCheckInOut')
  }
  set whoCheckInOut(input: User) {
    this.set('whoCheckInOut', input)
  }
  get pickupWho(): User | undefined {
    return this.get('pickupWho')
  }
  set pickupWho(input: User | undefined) {
    this.set('pickupWho', input)
  }
  get alternative_pickupWho(): AlternativeContact | undefined {
    return this.get('alternative_pickupWho')
  }
  set alternative_pickupWho(input: AlternativeContact | undefined) {
    this.set('alternative_pickupWho', input)
  }
  set hide_in_dep_list(input: boolean) {
    this.set('hide_in_dep_list', input)
  }
  get hide_in_dep_list(): boolean {
    return this.get('hide_in_dep_list')
  }
  set kidName(input: string) {
    this.set('kidName', input)
  }
  get kidName(): string {
    return this.get('kidName')
  }
  set department(input: string) {
    this.set('department', input)
  }
  set tariff(input: string | undefined) {
    this.set('tariff', input)
  }
  get tariff(): string | undefined {
    return this.get('tariff')
  }
  set canBeTagged(input: boolean) {
    this.set('canBeTagged', input)
  }
  set kidCheckIn(input: boolean) {
    this.set('kidCheckIn', input)
  }
  set kidSick(input: boolean) {
    this.set('kidSick', input)
  }
  set birth_date(input: Date | undefined) {
    this.set('birth_date', input)
  }
  get birth_date(): Date | undefined {
    return this.get('birth_date')
  }
  set start_date(input: string) {
    this.set('start_date', input)
  }
  get start_Date(): string {
    return this.get('start_date')
  }

  get notificationModule(): NOTIFICATION_MODULE {
    switch (this.action) {
      case KID_ACTION.CHECK_IN:
        return NOTIFICATION_MODULE.CHECK_IN
      case KID_ACTION.CHECK_OUT:
        return NOTIFICATION_MODULE.CHECK_OUT
      case KID_ACTION.CHECK_UPD:
        return NOTIFICATION_MODULE.CHECK_UPD
      case KID_ACTION.SICK:
        return NOTIFICATION_MODULE.SICK
      case KID_ACTION.NOTSICK:
        return NOTIFICATION_MODULE.NOTSICK
      case KID_ACTION.SICKUPD:
        return NOTIFICATION_MODULE.SICKUPD
      default:
        return NOTIFICATION_MODULE.UNDEFINED
    }
  }

  get extraInfoForNotification(): any[] | undefined {
    if (this.action === KID_ACTION.CHECK_UPD) {
      return [
        this.pickupWho ?? this.alternative_pickupWho ?? null,
        this.timeToPickUp,
        this.pickupInfo ?? null,
        this.who_will_pick_up ?? null,
      ]
    }
    return undefined
  }
}
Parse.Object.registerSubclass('kids', Kid)
