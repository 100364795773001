import React, { useContext, useEffect, useRef, useState } from 'react'
import ModalRegular from '../../../../components/ModalRegular'
import { Button } from '../../../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import { t } from 'i18next'
import { KidsStoreContext } from '../../../../store/kidsStore'
import { values } from 'mobx'
import { useParams } from 'react-router-dom'
import { Kid } from '../../../../Models/Kid'
import Swal from 'sweetalert2'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { Department } from '../../../../Models/Department'
import CustomInput from '../../../../components/CustomInput'
import Parse from 'parse'
import { ImageUploader } from '../../../../components/ImageUploader'
import moment from 'moment'

interface AddKidModalProps {
  onClose: () => void
  isOpen: boolean
}
interface NewKid {
  kidName: string
  firstName: string
  lastName: string
  birth_date: Date | null
  kid_pers_id_num: string
  start_date: string
  kidImage: any | null
  department: Department | null
}
let initialValues = {
  kidName: '',
  firstName: '',
  lastName: '',
  department: null,
  birth_date: null,
  kid_pers_id_num: '',
  start_date: '',
  kidImage: '',
}

const AddKidModal: React.FC<AddKidModalProps> = ({ onClose, isOpen }) => {
  const { createNewKid, school } = useContext(KidsStoreContext)
  const [newKid, setNewKid] = useState<NewKid>(initialValues)

  const { departments, fetchDepartments } = useContext(DepartmentStoreContext)
  const uploadImageToParse = async (file: any) => {
    var extension = file.name.split('.').pop()
    var parseImageFile = new Parse.File('kidImage' + extension, file)
    var parseImageFile = new Parse.File('kidImage_thumb' + extension, file)

    await parseImageFile.save()
    return parseImageFile
  }
  const { schoolId } = useParams()
  const handleSave = async (values: any) => {
    if (values.kidImage && values.kidImage_thumb) {
      values.kidImage = await uploadImageToParse(values.kidImage)
      values.kidImage_thumb = await uploadImageToParse(values.kidImage_thumb)
    }
    if (schoolId) {
      createNewKid(values, schoolId).then(async (kid) => {
        if (kid && values.kidImage) {
          const image = await uploadImageToParse(values.kidImage)
          kid.set('kidImage', image ?? undefined)
          kid.set('kidImage_thumb', image ?? undefined)
          await kid.save()
          setNewKid(initialValues)
        }
      })
    }
  }
  const [fakeState, setFakeState] = useState('')

  const [selectedDepartment, setSelectedDepartment] =
    useState<Department | null>(null)
  useEffect(() => {
    if (schoolId) {
      fetchDepartments(schoolId)
    }
  }, [schoolId])
  const handleNewImage = (file: File) => {
    setNewKid({
      ...newKid,
      kidImage: file,
    })
  }
  const isDepartmentValid = (department: Department | null) => {
    return department !== null
  }
  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)
  const displayNameRef = useRef<HTMLInputElement>(null)
  const psnRef = useRef<HTMLInputElement>(null)
  const birthDateRef = useRef<HTMLInputElement>(null)
  const startDateRef = useRef<HTMLInputElement>(null)
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  useEffect(() => {
    if (newKid.firstName && newKid.lastName) {
      const displayName = `${capitalizeFirstLetter(
        newKid.firstName,
      )} ${capitalizeFirstLetter(newKid.lastName.charAt(0))}`
      setNewKid((prevState) => ({ ...prevState, kidName: displayName }))
    }
  }, [newKid.firstName, newKid.lastName])

  return (
    <ModalRegular
      onClose={onClose}
      isOpen={isOpen}
      bgColor="bg-blue-300 p-4"
      title={t('admin.addKid')}
      closeColor="white"
      className="bg-gray-50"
    >
      <div className="w-full h-full flex flex-col p-4 sm:px-20 sm:pt-10 ">
        <div className="flex gap-y-2 flex-col">
          <div className="flex flex-row w-full relative">
            <div className="flex w-full flex-row">
              <div className="flex w-full flex-col gap-y-2">
                <div className="flex flex-row w-full justify-center">
                  <ImageUploader size="lg" onImageChange={handleNewImage} />
                </div>

                <CustomInput
                  ref={firstNameRef}
                  label={t('admin.kidFirstName')}
                  value={newKid.firstName}
                  onChange={(value) =>
                    setNewKid({
                      ...newKid,
                      firstName: capitalizeFirstLetter(value),
                    })
                  }
                  required={true}
                />

                <CustomInput
                  ref={lastNameRef}
                  label={t('admin.kidLastName')}
                  value={newKid.lastName}
                  onChange={(value) =>
                    setNewKid({
                      ...newKid,
                      lastName: capitalizeFirstLetter(value),
                    })
                  }
                  required={true}
                />

                <CustomInput
                  ref={displayNameRef}
                  label={t('admin.kidDisplayName')}
                  value={newKid.kidName}
                  onChange={(value) => setNewKid({ ...newKid, kidName: value })}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 gap-4 flex flex-col">
          <h1 className="text-lg font-bold text-gray-500 flex gap-x-2">
            {!isDepartmentValid(selectedDepartment) && (
              <span className="text-red-500">*</span>
            )}
            <>{t('admin.kidChooseDep')}</>
          </h1>
          <div className="flex flex-row gap-x-2">
            <div className="flex w-full justify-center flex-wrap">
              {departments?.map((department) => (
                <div
                  key={department.id}
                  className={`m-2 p-2 border max-w-[200px]  rounded-full flex flex-row relative justify-center min-w-[190px] text-center shadow-md cursor-pointer  no-select ${
                    newKid.department?.id === department.id
                      ? 'bg-blue-300 hover:bg-blue-300 text-white border-none'
                      : 'text-gray-400 bg-white'
                  }`}
                  onClick={() => {
                    setNewKid({ ...newKid, department: department })
                    setSelectedDepartment(department)
                  }}
                >
                  {department.attributes.klassName}
                  {newKid.department?.id === department.id && (
                    <div className="ml-2 absolute top-[9px] right-4">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-green-500"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex gap-y-2 flex-col">
          <CustomInput
            label={t('admin.kidPSN')}
            value={newKid?.kid_pers_id_num?.toString()}
            onChange={(value) => {
              setNewKid({
                ...newKid,
                kid_pers_id_num: value,
              })
              setFakeState(value)
            }}
            format="YYYYMMDD-XXXX"
            ref={psnRef}
            onlyNumbers={false}
          />

          <CustomInput
            label={t('admin.kidBirthDay')}
            value={
              newKid && newKid.birth_date
                ? moment(newKid.birth_date).format('YYYY-MM-DD')
                : ''
            }
            onChange={(dateValue) => {
              setNewKid({
                ...newKid,
                birth_date: moment(dateValue, 'YYYY-MM-DD').toDate(),
              })

              setFakeState(dateValue)
            }}
            format="DATE"
            ref={birthDateRef}
          />

          <CustomInput
            label={t('admin.kidStartDate')}
            value={
              newKid && newKid.start_date
                ? moment(newKid.start_date).format('YYYY-MM-DD')
                : ''
            }
            onChange={(dateValue) => {
              setNewKid({
                ...newKid,
                start_date: moment(dateValue).format('YYYY-MM-DD'),
              })

              setFakeState(dateValue)
            }}
            format="DATE"
            ref={startDateRef}
          />
          <div className="flex justify-center py-8">
            {newKid.firstName &&
            newKid.lastName &&
            newKid.kidName &&
            newKid.department ? (
              <Button
                size="custom"
                variant="primary"
                label={t('admin.kidAdd')}
                onClick={() => {
                  if (schoolId) {
                    handleSave(newKid)
                      .then(() => {
                        setNewKid(initialValues)
                        onClose()
                        Swal.fire({
                          icon: 'success',
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      })
                      .catch((e) => {
                        console.error('Error när jg ska spara', e)
                        Swal.fire({
                          icon: 'error',
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#d33',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      })
                  }
                }}
              />
            ) : (
              <Button
                size="custom"
                label={t('admin.kidAdd')}
                variant={'disableGray'}
                onClick={() => {
                  Swal.fire({
                    text: t('admin.addKidCheck'),
                    icon: 'warning',
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                  })
                }}
              />
            )}
          </div>
        </div>
      </div>
    </ModalRegular>
  )
}

export default AddKidModal
