import Parse from 'parse'
import { School } from './School'
import { User } from './User'
import { UserRole } from './UserRole'

export interface TagInterface {
  id?: string
  name?: string
  color?: string
  type?: string
  category?: string
  only_staff?: boolean
}

export class Tag extends Parse.Object {
  public static readonly Fields = {
    className: 'tags',

    NAME: 'name',
    COLOR: 'color',

    TYPE: 'type',
    CATEGORY: 'category',
    ONLY_STAFF: 'only_staff',

    ARCHIVE_STATE: 'archive_state',
    ARCHIVED_AT: 'archived_at',
    ARCHIVED_BY: 'archived_by',

    SCHOOL: 'school_pointer',
    AUTHOR: 'author',
  }
  constructor() {
    super(Tag.Fields.className)
  }

  get school(): School {
    return this.get(Tag.Fields.SCHOOL)
  }
  set school(value: School) {
    this.set(Tag.Fields.SCHOOL, value)
  }
  get author(): UserRole | undefined {
    return this.get(Tag.Fields.AUTHOR)
  }
  set author(value: UserRole | undefined) {
    this.set(Tag.Fields.AUTHOR, value)
  }

  get name(): string | undefined {
    return this.get(Tag.Fields.NAME)
  }
  set name(value: string | undefined) {
    this.set(Tag.Fields.NAME, value)
  }
  get color(): string | undefined {
    return this.get(Tag.Fields.COLOR)
  }
  set color(value: string | undefined) {
    this.set(Tag.Fields.COLOR, value)
  }

  get type(): string | undefined {
    return this.get(Tag.Fields.TYPE)
  }
  set type(value: string | undefined) {
    this.set(Tag.Fields.TYPE, value)
  }
  get category(): string | undefined {
    return this.get(Tag.Fields.CATEGORY)
  }
  set category(value: string | undefined) {
    this.set(Tag.Fields.CATEGORY, value)
  }
  get only_staff(): boolean | undefined {
    return this.get(Tag.Fields.ONLY_STAFF)
  }
  set only_staff(value: boolean | undefined) {
    this.set(Tag.Fields.ONLY_STAFF, value)
  }

  get archive_state(): number | undefined {
    return this.get(Tag.Fields.ARCHIVE_STATE)
  }
  set archive_state(value: number | undefined) {
    this.set(Tag.Fields.ARCHIVE_STATE, value)
  }
  get archived_at(): Date | undefined {
    return this.get(Tag.Fields.ARCHIVED_AT)
  }
  set archived_at(value: Date | undefined) {
    this.set(Tag.Fields.ARCHIVED_AT, value)
  }
  get archived_by(): UserRole | undefined {
    return this.get(Tag.Fields.ARCHIVED_BY)
  }
  set archived_by(value: UserRole | undefined) {
    this.set(Tag.Fields.ARCHIVED_BY, value)
  }

  /*asJson(): TagInterface {
    return {
      id: this.id,
      name: this.name,
      color: this.color,
      type: this.type,
      category: this.category,
      only_staff: this.only_staff,
    }
  }*/
}
Parse.Object.registerSubclass(Tag.Fields.className, Tag)
