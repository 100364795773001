import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faSquareArrowUpRight,
} from '@fortawesome/free-solid-svg-icons'
import React from 'react'

export const ExternalLinkItem = ({
  link,
  role,
}: {
  link: any
  role: number
}) => {
  return role >= link.visibility ? (
    <div
      onClick={() => {
        window.open(link.link, '_blank')
      }}
      className={classNames(
        'flex flex-row  items-center text-gray-800  cursor-pointer p w-full shadow-[0px_2px_12px_2px_rgba(0,0,0,0.10)]',
        'relative',
        'border rounded-2xl shadow',
        'p-3  space-x-0  hover:shadow-md md:justify-center px-3',
      )}
    >
      <div className={classNames('flex  items-center relative')}>
        <div className={'relative text-invoiceMain'}>
          <FontAwesomeIcon icon={faSquareArrowUpRight} size="5x" />
        </div>
      </div>
      <div
        className={
          'flex flex-col justify-center flex-1  h-full border-gray-200  relative w-[150px] overflow-hidden break-all'
        }
      >
        <div className={'flex'}>
          <div className={'flex flex-col'}>
            <p className={classNames(' px-1 flex-1 pl-3 ')}>{link?.name}</p>
          </div>
        </div>

        <div>
          <FontAwesomeIcon
            style={{ top: '50%', transform: 'translateY(-50%)' }}
            className={'absolute right-2 text-gray-400 md:hidden'}
            icon={faChevronRight}
          />
        </div>
      </div>
    </div>
  ) : null
}
