import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faPenToSquare,
  faChildReaching,
  faChevronRight,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import classNames from 'classnames'
import { SKA_VISIBILITY, USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import { Kid } from '../../../Models/Kid'
import { getImageOrPlaceHolder } from '../../../utils/parseUtils'
import { KidsModal } from './KidsModal'
import { DocumentationStoreContext } from '../../../store/documentationStore'
import { Spinner } from '../../../components/Spinner'
import { Button } from '../../../components/Button'
import { UsersModal } from './UsersModal'
import { toJS } from 'mobx'
import { UserStoreContext } from '../../../store/userStore'
import { User } from '../../../Models/User'

export const EditTitleModal = ({
  type,
  documentation,
}: {
  type: string
  documentation: any
}) => {
  const [showModal, setShowModal] = useState(false)
  const [addTaggedLoading, setAddTaggedLoading] = useState(false)
  const [addSharedUsersLoading, setAddSharedUsersLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showKidsModal, setShowKidsModal] = useState(false)
  const [showUsersModal, setShowUsersModal] = useState(false)
  const [name, setName] = useState(documentation?.attributes?.name)
  const { currentUserRole } = useContext(UserStoreContext)
  const [shareType, setShareType] = useState(
    documentation?.attributes?.visibility,
  )

  const {
    addTaggedKidsDocumentation,
    addSharedUsersDocumentation,
    fetchDocumentation,
    editDocumentation,
    teachers,
    fetchUsers,
  } = useContext(DocumentationStoreContext)

  useEffect(() => {
    if (currentUserRole && currentUserRole?.school_pointer) {
      fetchUsers(currentUserRole?.school_pointer?.id, USER_ROLE_TYPE.TEACHER)
    }
  }, [currentUserRole?.id])

  return (
    <>
      <p
        className={'text-xl cursor-pointer hover:scale-110'}
        onClick={() => {
          setShowModal(true)
        }}
      >
        <span className={'mr-2'}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </span>
        {documentation?.attributes?.name}
      </p>
      <ModalRegular
        title={''}
        isOpen={showModal}
        className={'max-w-2xl'}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <div className={'mt-10 flex flex-col mb-3'}>
          <p className={'mb-1 font-medium'}>Dokumentnamn</p>

          <input
            className={classNames(
              'block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
            )}
            type={'text'}
            value={name}
            placeholder={''}
            onChange={(e) => {
              setName(e.target.value)
            }}
          />

          <p className={'mt-6 mb-3 font-medium'}>Delningsinställningar</p>

          <div className={'flex flex-col space-y-2 border-b border-t py-2'}>
            <div
              className={classNames(
                'flex justify-between items-center cursor-pointer',
                shareType !== SKA_VISIBILITY.PRIVATE_DRAFT && 'opacity-60',
                'hover:opacity-100',
              )}
              onClick={() => {
                setShareType(SKA_VISIBILITY.PRIVATE_DRAFT)
              }}
            >
              <div className="flex flex-col">
                <p>Privat utkast</p>
                <p>Synlig endast för dig</p>
              </div>

              {shareType === SKA_VISIBILITY.PRIVATE_DRAFT && (
                <FontAwesomeIcon
                  className={'text-eventsMain text-2xl'}
                  icon={faCircleCheck}
                />
              )}
            </div>
            <div
              className={classNames(
                'flex justify-between items-center cursor-pointer',
                shareType !== SKA_VISIBILITY.SHARED_DRAFT && 'opacity-60',
                'hover:opacity-100',
              )}
              onClick={() => {
                setShareType(SKA_VISIBILITY.SHARED_DRAFT)
              }}
            >
              <div className="flex flex-col">
                <p>Delat utkast</p>
                <p>Synlig endast för dig och valda pedagoger</p>
                {shareType === SKA_VISIBILITY.SHARED_DRAFT && (
                  <>
                    <div className={'flex flex-wrap space-x-2 py-2 '}>
                      {addSharedUsersLoading ? (
                        <Spinner />
                      ) : (
                        documentation?.attributes?.draft_shared_users?.map(
                          (id: string) => {
                            const user = teachers?.find((t) => t.id === id)
                            return (
                              <div className={'flex space-x-2 items-center'}>
                                <img
                                  className={'w-[15px] h-[15px] rounded-full'}
                                  src={getImageOrPlaceHolder(
                                    user?.attributes?.myPic?._url,
                                  )}
                                  alt=""
                                />
                                <p className={'text-xs'}>
                                  {user?.attributes?.firstName}{' '}
                                  {user?.attributes?.lastName?.[0]}
                                </p>
                              </div>
                            )
                          },
                        )
                      )}
                    </div>

                    <div
                      className={
                        'flex space-x-2 items-center text-eventsMain  cursor-pointer'
                      }
                      onClick={() => {
                        setShowUsersModal(true)
                      }}
                    >
                      <FontAwesomeIcon icon={faUserPlus} />
                      <p>Lägg till pedagoger</p>

                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </>
                )}
              </div>

              {shareType === SKA_VISIBILITY.SHARED_DRAFT && (
                <FontAwesomeIcon
                  className={'text-eventsMain text-2xl'}
                  icon={faCircleCheck}
                />
              )}
            </div>
            <div
              className={classNames(
                'flex justify-between items-center cursor-pointer',
                shareType !== SKA_VISIBILITY.PUBLISHED_TEACHERS && 'opacity-60',
                'hover:opacity-100',
              )}
              onClick={() => {
                setShareType(SKA_VISIBILITY.PUBLISHED_TEACHERS)
              }}
            >
              <div className="flex flex-col">
                <p>Publicera till pedagoger</p>
                <p>Visas för samtliga pedagoger</p>
              </div>

              {shareType === SKA_VISIBILITY.PUBLISHED_TEACHERS && (
                <FontAwesomeIcon
                  className={'text-eventsMain text-2xl'}
                  icon={faCircleCheck}
                />
              )}
            </div>
            <div
              className={classNames(
                'flex justify-between items-center cursor-pointer',
                shareType !== SKA_VISIBILITY.PUBLISHED_TEACHERS_AND_PARENTS &&
                  'opacity-60',
                'hover:opacity-100',
              )}
              onClick={() => {
                setShareType(SKA_VISIBILITY.PUBLISHED_TEACHERS_AND_PARENTS)
              }}
            >
              <div className="flex flex-col">
                <p>Publicera till pedagoger och vårdnadshavare</p>
                <p className={'text-sm'}>
                  Visas för pedagoger och vårdnadshavare
                </p>
              </div>

              {shareType === SKA_VISIBILITY.PUBLISHED_TEACHERS_AND_PARENTS && (
                <FontAwesomeIcon
                  className={'text-eventsMain text-2xl'}
                  icon={faCircleCheck}
                />
              )}
            </div>
          </div>
          <p className={'mt-6 mb-3 font-medium'}>Taggade barn</p>
          <p>Tagga barn</p>
          <p className={'text-sm'}>
            Dessa kommer man bland annat kunna filtrera efter
          </p>

          <div className={'flex flex-wrap space-x-2 py-2 '}>
            {addTaggedLoading ? (
              <Spinner />
            ) : (
              documentation?.attributes?.tagged_kids?.map((kid: Kid) => {
                return (
                  <div className={'flex space-x-2 items-center'}>
                    <img
                      className={'w-[15px] h-[15px] rounded-full'}
                      src={getImageOrPlaceHolder(
                        kid?.attributes?.kidImage?._url,
                      )}
                      alt=""
                    />
                    <p className={'text-xs'}>{kid?.attributes?.kidName}</p>
                  </div>
                )
              })
            )}
          </div>

          <div
            className={
              'flex space-x-2 items-center text-classlistMain  cursor-pointer'
            }
            onClick={() => {
              setShowKidsModal(true)
            }}
          >
            <FontAwesomeIcon icon={faChildReaching} />
            <p>Lägg till barn</p>

            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          <KidsModal
            initialKids={documentation?.attributes?.tagged_kids ?? []}
            showModal={showKidsModal}
            onClose={() => {
              setShowKidsModal(false)
            }}
            onSave={async (kids: Kid[]) => {
              setAddTaggedLoading(true)
              setShowKidsModal(false)
              await addTaggedKidsDocumentation({
                kids,
                type: type,
                id: documentation?.id,
              })
              await fetchDocumentation({
                type: type,
                id: documentation?.id,
              })
              setAddTaggedLoading(false)
            }}
          />
          <UsersModal
            showModal={showUsersModal}
            onClose={() => {
              setShowUsersModal(false)
            }}
            onSave={async (users: User[]) => {
              setAddSharedUsersLoading(true)
              setShowUsersModal(false)
              await addSharedUsersDocumentation({
                users,
                type: type,
                id: documentation?.id,
              })
              await fetchDocumentation({
                type: type,
                id: documentation?.id,
              })
              setAddSharedUsersLoading(false)
            }}
            initialUsers={[]}
            role={USER_ROLE_TYPE.TEACHER}
          />
          <div className={'flex w-full justify-end mt-3'}>
            <Button
              loading={loading}
              label={'Save'}
              onClick={async () => {
                setLoading(true)
                await editDocumentation({
                  id: documentation.id,
                  name: name ?? '',
                  type: type ?? '',
                  visibility: shareType,
                })
                await fetchDocumentation({
                  type: type,
                  id: documentation?.id,
                })
                setLoading(false)
                setShowModal(false)
              }}
            />
          </div>
        </div>
      </ModalRegular>
    </>
  )
}
