import { User } from '../Models/User'

export enum ROLES {
  ADMINS = '_a',
  TSENSITIVE = '_ts',
  TEACHERS = '_t',
  PARENTS = '_p',
  KIDS = '_k',
}

export enum ARCHIVE_STATE {
  ACTIVE = 0,
  HISTORIC = 200,
  HISTORIC_ANONYMOUS = 220,
  DELETED = 400,
}

export enum USER_ROLE_TYPE {
  NONE = 0,
  KINDRED = 330,
  PARENT = 350,
  TEACHER_LOWEST = 400,
  MULTIUSER = 410,
  TEACHER = 450,
  ADMIN = 500,
  SUPER_ADMIN = 550,
  TADMIN = 1337,
}

export enum SKA_TEXT_TAGS {
  ANALYSIS = 1,
  REFLECTION = 2,
}

export enum USER_ROLE_STATUS {
  NONE = 0,
  NEW = 10,
  PENDING = 30,
  PAUSED = 35,
  ACTIVE = 40,
}

export enum TERRITORY {
  SWEDEN = 752,
  NORWAY = 578,
  SPAIN = 724,
}

export enum PHONE_TERRITORY {
  SWEDEN = '+46',
  NORWAY = '+47',
  SPAIN = '+34',
}

export enum NOTIFICATION_MODULE {
  UNDEFINED = 0,
  BOX = 1,
  EVENTS = 2,
  BLOGG = 3,
  PORT = 4,
  CHECK_IN = 5,
  CHECK_OUT = 6,
  SICK = 7,
  NOTSICK = 8,
  NOTE = 9,
  NOTECOM = 10,
  SCHEDULE = 11,
  CHAT = 12,
  CHECK_UPD = 13,
  SICKUPD = 14,
  SCHEDULE_PERS = 15,
  SCHEDULE_COPY = 16,
  DOCUMENTS = 17,
  DOCUMENTS_SIGN = 18,
  STAFF_ATTENDANCE_CHECKINOUT = 31,
  STAFF_ATTENDANCE_EDITED = 32,
  INVITE = 98,
  INVITE_NEED_ACTION = 99,
}

export enum NOTIFICATION_ORIGIN_STATUS {
  CREATED = 1,
  UPDATED = 2,
  DELETED = 3,
}

// TODO: Go over error numbers and also go over client at the same time
export enum TYRA_ERROR {
  UNDEFINED_ERROR = 1000,
  FIELD_MISSING = 1001,
  DUPLICATE_ROW = 1002,
  UNAVAILABLE_SERVICE = 1003,
  CLOCK_OUT_OF_SYNC = 1004,
  ALREADY_INVITED = 1101,
  USERNAME_NOT_FOUND = 1102,
  AUTH_ERROR = 1103,
  NO_USERROLE_FOUND = 1104,
  PHONENUMBER_TAKEN = 1105,
  EMAIL_ON_MOBILEUSER_TAKEN = 1106,
  DELETE_FILE_ERROR = 1107,
  ROLE_ERROR = 1108,
  DELETE_FILE_HAS_OTHER_RELATION = 1109,
}

export enum UPDATE_FLAG {
  NONE = 0,
  EDITED = 10,
  PUBLISHED = 20,
}

export enum USER_FLAGS {
  UPDATE_DEPS = 'update_deps',
  UPDATE_DEP_PIC = 'update_dep_pic',
  UPDATE_DEP_NAME = 'update_dep_name',
  UPDATE_PORT_TAGS = 'update_port_tags',
  UPDATE_EVENTS = 'update_events',
  UPDATE_MODULES = 'update_modules',
}

export enum USER_GROUP {
  ALL = 0,
  PARENTS = 10,
  TEACHERS = 20,
}

export enum KID_ACTION {
  UNDEFINED = 0,

  CHECK_IN = 1,
  CHECK_OUT = 2,
  CHECK_UPD = 3,

  SICK = 4,
  NOTSICK = 5,
  SICKUPD = 6,
}

export enum DEST_TYPE {
  NONE = 0,
  STAFF = 1,
  PARENT = 2,
  BOTH = 3,
}

export enum USER_SETTING {
  UNSET = 0,
  SHOW_ALL = 10,
  HIDE_CONTACT_INFO = 20,
  HIDE_ALL = 30,
}

export enum EVENT_TYPE {
  PT_ASSOCIATION = 'Föräldraförening',
  NEWS = 'Nyheter',
  PT_MEETING = 'Föräldramöte',
  STUDY_DAYS = 'Studiedagar',
  OTHER = 'Övrigt',
  STAFF = 'Personal',
}

export enum BOX_STATUS {
  EMPTY = 0,
  FEW = 1,
  FULL = 2,
}

export enum PUSH_FROM {
  DOCUMENTS = 'documents',
  INVITE = 'invite',
}

export enum DOCUMENT_VISIBILITY_TYPE {
  STAFF = 1,
  SCHOOL = 2, // Was: PARENT
  MINE = 3,
  CHILD = 4,
}

export enum PUSH_OPERATION {
  EQUALTO = 1,
  NOTEQUALTO = 2,
  CONTAINEDIN = 3,
  EXISTS = 4,
}

export enum PUSH_TABLE {
  INSTALLATION = 0,
  USER_ROLE_SETTING = 1,
}

export enum ACL_ROLE_TYPE {
  KID = 200,
  PARENT = 300,
  TEACHER = 400,
  TSENSITIVTE = 450,
  ADMIN = 500,
}

export enum SKA_VISIBILITY {
  PRIVATE_DRAFT = 1,
  SHARED_DRAFT = 2,
  PUBLISHED_TEACHERS = 11,
  PUBLISHED_TEACHERS_AND_PARENTS = 12,
  PUBLISHED_TEACHERS_AND_KIDS = 13,
}

export enum SKA_CARD_TYPE {
  TEXT = 10,
  IMAGES = 20,
  PDFS = 30,
  CURRICULUMS = 40,
  TAGS = 50,
  RELATION_OPERATION_PLANS = 110,
  RELATION_PROJECT_PLANS = 120,
  RELATION_REFLECTIONS = 130,
  RELATION_BLOG_DOCS = 140,
  ALL_RELATION_CARDS = 170,
}

export enum SKA_RELATION_TYPE {
  OPERATION_PLANING = 10,
  PROJECT_PLANING = 11,
  REFLECTION = 12,
  BLOG = 20,
  PORTFOLIO = 21,
}

export enum HTTP_RESPONSE {
  OK = 200,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
}

export enum IT_INVOICE_VISIBILITY {
  ADMINS = 10,
  ADMINS_AND_PARENTS = 20,
}

export enum USER_TYPE {
  UNDEFINED = 0,
  MULTI = 1,
  EMAIL = 20,
  PHONE = 30,
  BANKID = 40,
}

export enum ENTITY_CLASS {
  EVENTS = 'events',
}

export enum EMOTE {
  EMOTE_NONE = 0,
  EMOTE_HEART = 1,
}

/* ******************* SUPPORT ************** */

export enum SUPPORT_LOG_OPERATION {
  UNKNOWN = 0,
  LOGINNAME_CHANGE = 1,
  USERROLE_TYPE_CHANGE = 2,
  SCHOOL_DELETION = 20, // Was: SLO_SCHOOL_DELETION
  SOFT_USER_DELETION = 21,
  HARD_USER_DELETION = 22,
}

/* For new login */

export const enum LOGIN_METHOD {
  UNKNOWN = 0,
  EMAIL = 20,
  PHONE = 30,
  GRANDID = 40,
}

export interface TyraSession {
  sessionTokenStr: string
  user?: User | undefined
}

export interface GrandIDSession {
  sessionId: string
  autoStartToken: string | undefined
  redirectUrl: string | undefined
  // Either autoStartToken or redirectUrl must exist.
}

export interface LoginSessionResponse {
  loginSessionId: string | null
  action: LoginAction
  otherLoginMethod?: LOGIN_METHOD
  loginChoice?: LoginChoice[]
  idp_url?: string
  parseSessionId?: string
  retry?: boolean
  noUser?: boolean
  grandIdSession?: GrandIDSession
  qrCode?: string // Base 64 encoded svg.
  hintCode?: string // Grand id qr code hint code, primarly 'outstandingTransaction' and 'userSign'
  cancelledGrandIdSession?: boolean
}

export interface LoginChoice {
  id: string
  name: string
}

export enum LoginAction {
  Password = 'password',
  Verify = 'verify',
  Choice = 'choice',
  Idp = 'idp',
  OtherLoginMethod = 'otherloginmethod',
  Become = 'become',
  Outdated = 'outdated',
  BankID = 'bankid',
  PollBankId = 'pollbankid',
  Retry = 'retry',
  MultiWeb = 'multiWeb',
}

export enum ClientType {
  Android = 'android',
  Web = 'web',
  iOS = 'ios',
}
