import React from 'react'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

export const getDateForTranslatation = (months: string) => {
  switch (months) {
    case '01':
      return t('monthsShort.january')
    case '02':
      return t('monthsShort.february')
    case '03':
      return t('monthsShort.march')
    case '04':
      return t('monthsShort.april')
    case '05':
      return t('monthsShort.may')
    case '06':
      return t('monthsShort.june')
    case '07':
      return t('monthsShort.july')
    case '08':
      return t('monthsShort.august')
    case '09':
      return t('monthsShort.september')
    case '10':
      return t('monthsShort.october')
    case '11':
      return t('monthsShort.november')
    case '12':
      return t('monthsShort.december')
  }
}
export const getDayForTranslation = (day: string) => {
  switch (day) {
    case 'Mon':
      return t('days.monday')
    case 'Tue':
      return t('days.tuesday')
    case 'Wed':
      return t('days.wednesday')
    case 'Thu':
      return t('days.thursday')
    case 'Fri':
      return t('days.friday')
    case 'Sat':
      return t('days.saturday')
    case 'Sun':
      return t('days.sunday')
    default:
      return day
  }
}

interface TimeStampProps {
  date: Moment
  className?: string
  showYear?: boolean
  containerClassName?: string
  displayMode?: 'relative' | 'standard'
  isEdited?: boolean
}

const TimeStamp: React.FC<TimeStampProps> = ({
  date,
  className,
  showYear = false,
  containerClassName,
  displayMode = 'standard',
  isEdited,
}) => {
  const { t } = useTranslation()

  const getRelativeTimeDisplay = () => {
    const now = moment()
    const diffInMinutes = now.diff(date, 'minutes')

    if (diffInMinutes < 60) {
      return diffInMinutes + ' ' + t('general.minutesAgo')
    } else if (diffInMinutes < 120) {
      // Less than 2 hours
      return t('general.justOverAnHourAgo')
    } else if (diffInMinutes < 24 * 60) {
      // Less than 24 hours
      return Math.floor(diffInMinutes / 60) + ' ' + t('general.hoursAgo')
    } else if (diffInMinutes < 7 * 24 * 60) {
      // Less than 7 days
      return `${getDayForTranslation(date.format('ddd'))} ${date.format(
        'HH:mm',
      )}`
    } else {
      return `${getDateForTranslatation(date.format('MM'))} ${date.format(
        'DD',
      )}`
    }
  }

  const getStandardTimeDisplay = () => {
    if (date.isSame(moment(), 'day')) {
      const hours = moment().diff(date, 'hours')
      const minutes = moment().diff(date, 'minutes')

      if (hours >= 1) {
        return `${hours} ${
          hours === 1 ? t('general.hourAgo') : t('general.hoursAgo')
        }`
      } else {
        return `${minutes} ${
          minutes === 1 ? t('general.minuteAgo') : t('general.minutesAgo')
        }`
      }
    } else {
      return `${date.format('DD')} ${getDateForTranslatation(
        date.format('MM'),
      )?.toLowerCase()}${
        showYear ? `-${date.format('YYYY')}` : ''
      } ${date.format('HH:mm')}`
    }
  }

  return (
    <span className={`${containerClassName || ''} ${className || ''}`}>
      {isEdited ? `${t('blog.blogUpdateTime')}` : ''}
      {displayMode === 'relative'
        ? getRelativeTimeDisplay()
        : getStandardTimeDisplay()}
    </span>
  )
}

export default TimeStamp
