import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getFullMonthForTranslatation } from '../views/events/Events'
interface Props {
  day: string
  mode?: 'sickness' | 'month' // Make mode optional with "?"
}

export const DateCalendarIconSmall: React.FC<Props> = observer(
  ({ day, mode = 'sickness' }: Props) => {
    const { t } = useTranslation()
    const month = getFullMonthForTranslatation(moment(day).format('MM'))
    const monthAbbreviation = moment(day).format('MMM')

    return (
      <div
        className={
          'h-16 w-16 mb-4 mr-6 rounded-xl flex justify-center items-center text-sm bg-gray-50 text-gray-800 text-center shadow-md'
        }
      >
        {day && (
          <div className="grid grid-cols-1 w-full h-full">
            {mode === 'sickness' ? (
              <p className="pt-1 w-full text-xs bg-red-500 font-bold text-white items-center justify-center flex rounded-t-xl shadow">
                {t('absence.backFrSickness')}
              </p>
            ) : (
              <p className=" w-full text-xs mb-1 bg-red-500 font-bold text-white items-center justify-center flex rounded-t-xl shadow">
                {monthAbbreviation.toUpperCase()}
              </p>
            )}
            <p className="font-bold text-base items-center justify-center">
              {moment(day).format('DD')}
            </p>
            {mode === 'sickness' ? (
              <p className="font-medium pb-1 items-center text-xs justify-center flex">
                {month}
              </p>
            ) : null}
          </div>
        )}
      </div>
    )
  },
)
