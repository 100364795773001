import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { LoadingBar } from '../../../../components/LoadingBar'
import { faArrowRight, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { t } from 'i18next'

interface Props {
  children?: React.ReactNode
  description?: string
  title: string
  loading?: boolean
  className?: string
  icon: IconDefinition
  iconClassName?: string
  titleContainerClassName?: string
  onClick?: () => void
  statusCheck?: boolean
  onLoad?: (icon: IconDefinition, title: string) => void
  iconBgColor?: string
}

export default function ModulesCard(props: Props) {
  const { icon, title, onLoad, iconBgColor = 'transparent' } = props

  useEffect(() => {
    if (onLoad) {
      onLoad(icon, title)
    }
  }, [icon, title])
  return (
    <div
      className={classNames(
        'w-1/5 min-h-[60px] min-w-[300px] overflow-hidden rounded-2xl shadow-tyraShadow m-4 cursor-pointer',
      )}
      onClick={props.onClick}
    >
      {props?.loading && <LoadingBar />}
      <div className="flex space-x-4 rounded-b-2xl items-center font-bold font-sans bg-white text-neutral-600 relative">
        <div
          className={classNames(
            'flex flex-wrap py-3 font-medium justify-start w-full h-full',
            props.className,
          )}
        >
          <>
            <div className="flex relative flex-row items-center h-16 w-full">
              <div
                style={{ backgroundColor: iconBgColor }}
                className="flex items-center"
              >
                {props.icon && (
                  <FontAwesomeIcon
                    icon={props.icon}
                    className={classNames(
                      'absolute left-3 h-7 w-7 border p-1.5 rounded-lg',
                      props.iconClassName,
                    )}
                  />
                )}
              </div>
              <div
                className={classNames(
                  'flex flex-wrap flex-col justify-center items-center w-full h-full',
                  props.titleContainerClassName,
                )}
              >
                <div>{props.title}</div>
                <div>
                  {props.statusCheck ? (
                    <span className="text-green-500">
                      <>{t('general.active')}</>
                    </span>
                  ) : (
                    <span className="text-red-500">
                      {' '}
                      <>{t('general.inactive')}</>
                    </span>
                  )}
                </div>
              </div>
              <div className="absolute right-3">
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
            <div className="flex-grow flex items-center justify-center">
              {props.children}
            </div>
          </>
        </div>
      </div>
    </div>
  )
}
