import React, { useState, useRef, useContext, useEffect } from 'react'

import ModalRegular from '../../../../components/ModalRegular'
import { Button } from '../../../../components/Button'
import { useTranslation } from 'react-i18next'
import { SchoolStoreContext } from '../../../../store/schoolStore'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Department } from '../../../../Models/Department'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import Parse from 'parse'
import { ImageUploader } from '../../../../components/ImageUploader'
import CustomInput from '../../../../components/CustomInput'

interface Props {
  department: Department
  onClose: () => void
  isOpen: boolean
}
interface NewDepartment {
  klassName: string
  contactPic: any | undefined
  contactPic_thumb: any | undefined
  workTitle: string
  teleNumber: string
  otherMobNum: string
  email: string
  adress: string
  areaCode: string
  city: string
}
let initialValues = {
  contactPic_thumb: undefined,
  klassName: '',
  contactPic: undefined,
  workTitle: '',
  teleNumber: '',
  otherMobNum: '',
  email: '',
  adress: '',
  areaCode: '',
  city: '',
}

const CreateDepartment: React.FC<Props> = ({ onClose, isOpen }) => {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showAddDepartment, setShowAddDepartment] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [newDepartment, setNewDepartment] =
    useState<NewDepartment>(initialValues)
  const { createNewDepartment } = useContext(SchoolStoreContext)
  const departmentNameInputRef = useRef<HTMLInputElement>(null)
  const numberToDepartmentInputRef = useRef<HTMLInputElement>(null)
  const otherNumberInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const adressInputRef = useRef<HTMLInputElement>(null)
  const areaCodeInputRef = useRef<HTMLInputElement>(null)
  const cityInputRef = useRef<HTMLInputElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fakeState, setFakeState] = useState('')

  const { schoolId } = useParams()
  useEffect(() => {
    if (schoolId) {
      fetchDepartments(schoolId, true)
    }
  }, [schoolId])
  const { fetchDepartments } = useContext(DepartmentStoreContext)
  const uploadImageToParse = async (file: any) => {
    var extension = file.name.split('.').pop()
    var parseImageFile = new Parse.File('contactPic.' + extension, file)

    await parseImageFile.save()
    return parseImageFile
  }
  const handleSave = async (values: any) => {
    if (values.contactPic) {
      values.contactPic = await uploadImageToParse(values.contactPic)
    }
    if (schoolId) {
      createNewDepartment(schoolId, values)
    }
  }

  return (
    <ModalRegular
      onClose={onClose}
      isOpen={isOpen}
      bgColor="bg-eventsMain p-4"
      title={t('admin.addDepartment')}
      closeColor="white"
      className="max-w-lg"
    >
      <div className="flex flex-col p-4 bg-gray-50">
        <div className="flex justify-center w-full ">
          <ImageUploader
            onImageChange={(file) => {
              setNewDepartment({
                ...newDepartment,
                contactPic: file,
              })
              setImageUrl(URL.createObjectURL(file))
            }}
            initialImageUrl={imageUrl}
            size="lg"
          />
        </div>

        <div className="flex flex-col p-4 gap-y-4">
          <CustomInput
            label={t('admin.departmentName')}
            value={newDepartment.klassName}
            onChange={(value) => {
              setNewDepartment({ ...newDepartment, klassName: value })
              setFakeState(value)
            }}
            required={true}
            //placeholder={t('admin.title')}
            ref={departmentNameInputRef}
          />
          <CustomInput
            label={t('admin.departmentNumber')}
            value={newDepartment.teleNumber}
            onChange={(value) => {
              setNewDepartment({ ...newDepartment, teleNumber: value })
              setFakeState(value)
            }}
            onlyNumbers={true}
            //required={true}
            //placeholder={t('admin.title')}
            ref={numberToDepartmentInputRef}
          />
          <CustomInput
            label={t('admin.otherNumber')}
            value={newDepartment.otherMobNum}
            onChange={(value) => {
              setNewDepartment({ ...newDepartment, otherMobNum: value })
              setFakeState(value)
            }}
            onlyNumbers={true}
            //required={true}
            //placeholder={t('admin.title')}
            ref={otherNumberInputRef}
          />
          <CustomInput
            label={t('login.email')}
            value={newDepartment.email}
            onChange={(value) => {
              setNewDepartment({ ...newDepartment, email: value })
              setFakeState(value)
            }}
            //required={true}
            //placeholder={t('admin.title')}
            ref={emailInputRef}
          />
          <CustomInput
            label={t('contactList.adress')}
            value={newDepartment.adress}
            onChange={(value) => {
              setNewDepartment({ ...newDepartment, adress: value })
              setFakeState(value)
            }}
            //required={true}
            //placeholder={t('admin.title')}
            ref={adressInputRef}
          />
          <CustomInput
            label={t('admin.areaCode')}
            value={newDepartment.areaCode}
            onChange={(value) => {
              setNewDepartment({ ...newDepartment, areaCode: value })
              setFakeState(value)
            }}
            onlyNumbers={true}
            //required={true}
            //placeholder={t('admin.title')}
            ref={areaCodeInputRef}
          />
          <CustomInput
            label={t('admin.city')}
            value={newDepartment.city}
            onChange={(value) => {
              setNewDepartment({ ...newDepartment, city: value })
              setFakeState(value)
            }}
            //required={true}
            //placeholder={t('admin.title')}
            ref={cityInputRef}
          />

          <div className="flex w-full justify-between mt-1">
            <Button
              size="sm"
              variant="fullRed"
              label={t('general.cancel')}
              onClick={() => {
                setShowAddDepartment(false)
                onClose()
              }}
            />{' '}
            <Button
              size="sm"
              variant="fullBlue"
              label={t('general.save')}
              disabled={!newDepartment.klassName}
              onClick={() => {
                handleSave(newDepartment).then(() => {
                  Swal.fire({
                    icon: 'success',
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                  }).finally(() => {
                    if (schoolId) {
                      fetchDepartments(schoolId)
                    }
                    setShowAddDepartment(false)
                    onClose()
                  })
                })
              }}
            />
          </div>
        </div>
      </div>
    </ModalRegular>
  )
}

export default CreateDepartment
