/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { DocumentStoreContext } from '../../store/documentStore'
import { UserStoreContext } from '../../store/userStore'
import { DocumentItem } from './components/DocumentItem'
import { getDocsData } from './getDocsData'
import { downloadFile } from '../../utils/downloadFile'
import { CreateFolderButton } from './components/CreateFolderButton'
import { Button } from '../../components/Button'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { ButtonDropdown } from '../../components/Dropdown'
import { ReadAllModal } from './components/ReadAllModal'
import { useTranslation } from 'react-i18next'
import { SearchDocument, showAddPermissions } from './DocumentsFromType'
import moment from 'moment'
import Singleselect from '../../components/Singleselect'
import ColorCard from '../../components/ColorCard'
import { Loading } from '../../components/Loading'

export const Documents: React.FC = observer(() => {
  const { t } = useTranslation()
  const {
    getDocsFromFolderId,
    docs,
    docsLoading,
    setDocumentViewed,
    search,
    setSearch,
    sort,
    setSort,
  } = useContext(DocumentStoreContext)
  const { savedUserRoleId, currentUserRole, fetchUserRole, readOnly } =
    useContext(UserStoreContext)
  const { setTitle } = useContext(NavigationStoreContext)
  let { folderId } = useParams()
  /*const [sort, setSort] = useState(SortOptions.UPDATED)*/
  const [value, setValue] = useState(PersonalOrParent.PERSONAL)
  const [searchParams] = useSearchParams()
  /*const [search, setSearch] = useState('')*/
  const [showAdd, setShowAdd] = useState({ addFile: false, addFolder: false })
  const [hideAdd, setHideAdd] = useState(false)
  const type = searchParams.get('type')
  const title = searchParams.get('title')
  // todo Maybe come up with something smarter!?
  const step = searchParams.get('step')
  const id = searchParams.get('id')
  const kidId = searchParams.get('kidId')
  const navigate = useNavigate()
  const data = getDocsData(type ?? '')
  useEffect(() => {
    setTitle(title ?? '')
  }, [title])

  useEffect(() => {
    if (id) {
      fetch()
      if (id.length > 15) {
        if (
          currentUserRole?.school_pointer.attributes.school_setting.attributes.document_default_kid_folders?.find(
            (defaultFolder: any) => defaultFolder.id === id,
          )?.archive_state
        ) {
          setHideAdd(true)
        }
      }
    }
  }, [id, currentUserRole])

  const fetch = () => {
    getDocsFromFolderId(
      savedUserRoleId ?? '',
      id ?? '',
      step !== '2' ? type ?? '' : '',
      kidId ?? undefined,
    )
  }

  useEffect(() => {
    if (savedUserRoleId) {
      fetchUserRole(savedUserRoleId).finally(() => {})
    }
  }, [savedUserRoleId])
  useEffect(() => {
    if (currentUserRole) {
      //fetchUserRole(savedUserRoleId).finally(() => {
      const data = showAddPermissions(currentUserRole, type ?? '')
      if (data) {
        setShowAdd(data)
      }
      //})
    }
  }, [currentUserRole, savedUserRoleId])

  let documents = docs
  if (type === 'students' && !search?.length) {
    if (value === PersonalOrParent.PARENT) {
      documents = docs?.filter((d: any) => !d.onlyStaff)
    } else {
      documents = docs?.filter((d: any) => d.onlyStaff)
    }
  }

  documents = sortDocuments({
    documents: documents,
    option: sort ?? SortOptions.UPDATED,
    search: search,
  })

  const anyUnread = (allDocs: any) => {
    let unreadExists = false
    if (allDocs) {
      allDocs.map((doc: any) => {
        if (doc.hasUnReadCount > 0 || doc.unread) {
          unreadExists = true
        }
      })
    }
    return unreadExists
  }
  const showMarkAsRead = anyUnread(docs)

  const renderItem = (item: any, index: number) => {
    const ext = item?.url?.split('.').pop()
    if (docsLoading) {
      //return <></>
    }
    return (
      <DocumentItem
        id={item?.id}
        hideSettings={item.id.length > 15 ? true : false}
        isFile={item?.isFile}
        requireSign={item?.requireSign}
        signed={item?.signed}
        key={index}
        owner={item?.owner}
        title={item?.title}
        favorite={item?.favorite}
        sharedWith={type === 'my' && item?.userSharedWith?.length > 0}
        //icon={data?.icon}
        unReadCount={item?.hasUnReadCount ?? 0}
        className={data?.color ?? ''}
        count={item?.docCount ?? 0}
        countSign={item?.unSignedDocsCount ?? 0}
        createdAt={moment(item.createdAt).format('DD/MM/YYYY')}
        hasUnRead={
          item?.isFile ? item?.unread : (item?.hasUnReadCount ?? 0) > 0
        }
        type={type ?? ''}
        mimeType={ext}
        onUpdate={() => {
          fetch()
        }}
        onClick={() => {
          if (item?.isFile) {
            setDocumentViewed({
              documentId: item.id,
              userRoleId: savedUserRoleId ?? '',
            }).then(() => {
              fetch()
            })

            downloadFile(item?.url)
          }
          if (item?.isFolder) {
            if (type === 'students') {
              navigate(
                '/documents-id/' +
                  folderId +
                  '?type=' +
                  type +
                  '&id=' +
                  item.id +
                  '&step=2' +
                  '&title=' +
                  item?.title +
                  '&kidId=' +
                  id,
              )
            } else {
              navigate(
                '/documents-id/' +
                  folderId +
                  '?type=' +
                  type +
                  '&id=' +
                  item.id +
                  '&step=2' +
                  '&title=' +
                  item?.title,
              )
            }
          }
        }}
      />
    )
  }

  return (
    <Page
      naked
      loading={docsLoading}
      filterCount={search ? 1 : 0}
      searchFilter={
        <SearchDocument
          onSearch={(s: string) => {
            setSearch(s)
          }}
          initialValue={search ?? ''}
          placeholder={t('documents.searchDocuments')}
        />
      }
      topBar={
        <>
          <Sort
            onSort={(s: string) => {
              setSort(s)
            }}
            sort={' ' + sortName(sort ?? SortOptions.UPDATED, t) ?? ''}
            initialValue={sort ?? SortOptions.UPDATED}
          />
          {type === 'students' && (
            <PersonalOrParentChooser
              value={value}
              onValueChange={(v: string) => {
                setValue(v)
              }}
            />
          )}
          <div>
            {showAdd?.addFile && !readOnly?.documents && (
              <>
                {!hideAdd && (
                  <CreateFolderButton
                    kidId={step !== '2' ? id ?? '' : kidId ?? ''}
                    depId={step !== '2' ? id ?? '' : ''}
                    hideFolder={(step ?? 0) === '2' || !showAdd?.addFolder}
                    type={type ?? ''}
                    folderId={id ?? ''}
                    onFolderAdded={() => {
                      fetch()
                    }}
                    onFileAdded={() => {
                      fetch()
                    }}
                  />
                )}
              </>
            )}
          </div>
        </>
      }
    >
      {docsLoading && <Loading />}
      {type !== 'kid' && step !== '2' && (
        <ColorCard title={t('documents.folders')} bgColor={data?.bgColor ?? ''}>
          <div className="mb-2"></div>
          <div className="w-max-[20px] flex flex-fit justify-end mx-4 mb-4">
            {showMarkAsRead && (
              <ReadAllModal
                type={type ?? ''}
                folderId={id ?? ''}
                kidId={step !== '2' ? id ?? '' : ''}
                depId={step !== '2' ? id ?? '' : ''}
                onDone={() => {
                  fetch()
                }}
              />
            )}
          </div>
          {!docsLoading && !documents?.folders?.length && (
            <div
              className={
                'w-full flex flex-col justify-center text-2xl items-center sm:mt-12 space-y-12 text-gray-200'
              }
            >
              <p className="text-gray-300">{t('ourKids.noContactInfo')}</p>
            </div>
          )}

          {documents?.folders?.length > 0 && (
            <div
              className={
                'grid gap-3 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 max-w-7xl px-3 font-medium text-gray-800  '
              }
            >
              {documents?.folders?.map((item: any, index: number) => {
                return renderItem(item, index)
              })}
            </div>
          )}
          <div className={'pb-12'} />
        </ColorCard>
      )}
      <ColorCard title={t('documents.files')} bgColor="bg-gray-400">
        <div className="mb-2"></div>
        {!docsLoading && !documents?.documents.length && (
          <div
            className={
              ' w-full flex flex-col justify-center items-center text-gray-200 m-auto'
            }
          >
            <p className="text-gray-300 sm:mt-12 text-2xl">
              {t('ourKids.noContactInfo')}
            </p>
          </div>
        )}

        {documents?.documents?.length > 0 && (
          <div
            className={
              'grid gap-3 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 max-w-7xl px-3 font-medium text-gray-800  '
            }
          >
            {documents?.documents?.map((item: any, index: number) => {
              return renderItem(item, index)
            })}
          </div>
        )}

        <div className={'pb-12'} />
      </ColorCard>
    </Page>
  )
})

export const SortOptions = {
  CREATED: 'created',
  UPDATED: 'updated',
  ALPHABETIC: 'alphabetic',
  UNREAD: 'unread',
}

export const PersonalOrParent = {
  PERSONAL: 'personal',
  PARENT: 'parent',
}

export const sortName = (option: string, t: any) => {
  if (option === SortOptions.ALPHABETIC) {
    return t('documents.sortAlphabetic')
  } else if (option === SortOptions.CREATED) {
    return t('documents.sortCreated')
  } else if (option === SortOptions.UPDATED) {
    return t('documents.sortUpdated')
  }
}

const valueName = (option: string, t: any) => {
  if (option === PersonalOrParent.PARENT) {
    return t('documents.parent')
  } else if (option === PersonalOrParent.PERSONAL) {
    return t('documents.staff')
  }
}

const sort = ({
  documents,
  option,
  search,
}: {
  documents: any[]
  option: string
  search?: string
}) => {
  if (documents) {
    if (search && search.length) {
      return documents.filter((doc: any) => {
        return doc?.title?.toLowerCase().includes(search.toLowerCase())
      })
    } else {
      if (option === SortOptions.ALPHABETIC) {
        return documents?.slice()?.sort((a, b) => {
          return a.title.localeCompare(b.title)
        })
      } else if (option === SortOptions.CREATED) {
        return documents?.slice()?.sort((a: any, b: any) => {
          // @ts-ignore
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
      } else if (option === SortOptions.UPDATED) {
        return documents?.slice()?.sort((a: any, b: any) => {
          // @ts-ignore
          return new Date(b.updatedAt) - new Date(a.updatedAt)
        })
      }
    }
  }
}

export const sortDocuments = ({
  documents,
  option,
  search,
}: {
  documents: any[]
  option: string
  search?: string
}) => {
  if (documents) {
    let folders = documents?.filter((doc) => !doc.isFile)
    let docs = documents?.filter((doc) => doc.isFile)

    // @ts-ignore
    folders = sort({ documents: folders, option, search })
    // @ts-ignore
    docs = sort({ documents: docs, option, search })

    return { documents: docs, folders: folders }
  }
}

export const Sort = ({
  onSort,
  initialValue,
}: {
  onSort: any
  sort: string
  initialValue?: string
}) => {
  const { t } = useTranslation()

  const sortItems = [
    { label: t('documents.sortCreated'), value: SortOptions.CREATED },
    { label: t('documents.sortUpdated'), value: SortOptions.UPDATED },
    {
      label: t('documents.sortAlphabetic'),
      value: SortOptions.ALPHABETIC,
    },
  ]
  return (
    <div>
      {/*<Singleselect />*/}

      <Singleselect
        items={sortItems}
        onChange={(e) => {
          onSort(e.value)
        }}
        itemVariableKey="label"
        placeholder={t('documents.sortBy')}
        initialSelectedItem={sortItems.find(
          (item) => item.value === initialValue,
        )}
        removeSelected
      />
      {/*
      <Button
        variant="transparent"
        icon={faSort}
        label={t('documents.sortBy') + sort}
        reverseIcon
        dropdownIcon={false}
        dropdown={
          <ButtonDropdown.Menu>
            <ButtonDropdown.Button
              label={t('documents.sortCreated')}
              onClick={() => {
                onSort(SortOptions.CREATED)
              }}
            />
            <ButtonDropdown.Button
              label={t('documents.sortUpdated')}
              onClick={() => {
                onSort(SortOptions.UPDATED)
              }}
            />
            <ButtonDropdown.Button
              label={t('documents.sortAlphabetic')}
              onClick={() => {
                onSort(SortOptions.ALPHABETIC)
              }}
            />
          </ButtonDropdown.Menu>
        }
      />*/}
    </div>
  )
}

const PersonalOrParentChooser = ({
  onValueChange,
  value,
}: {
  onValueChange: any
  value: string
}) => {
  const { t } = useTranslation()

  return (
    <div className={' flex justify-end'}>
      <Button
        size="md"
        variant="transparent"
        icon={faSort}
        label={valueName(value, t)}
        reverseIcon
        dropdownIcon={false}
        dropdown={
          <ButtonDropdown.Menu>
            <ButtonDropdown.Button
              label={t('documents.staff')}
              onClick={() => {
                onValueChange(PersonalOrParent.PERSONAL)
              }}
            />
            <ButtonDropdown.Button
              label={t('documents.parent')}
              onClick={() => {
                onValueChange(PersonalOrParent.PARENT)
              }}
            />
          </ButtonDropdown.Menu>
        }
      />
    </div>
  )
}
