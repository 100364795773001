import React from 'react'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import { CreateEventParams } from '../../store/event3Store'
import { TypeOfEventSelections } from './components/TypeOfEvent'
import { PostEventToSelections } from './components/PostEventTo'
import { Department } from '../../Models/Department'
import { School } from '../../Models/School'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/Button'
import classNames from 'classnames'
import { warningSwal } from '../settings/MyConnections'
import { useNavigate, useParams } from 'react-router-dom'

interface Props {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  departments: Department[]
  schools?: School[]
  setEventStep: (e: number) => void
}

export const CreateEvent3Start: React.FC<Props> = observer(
  ({ newEvent, onChange, departments, schools, setEventStep }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { schoolId } = useParams()
    // Check that the user choose at least one department
    // eslint-disable-next-line no-self-compare
    const departmentChosed = newEvent.depIds?.length ?? 0 > 0

    return (
      <Page>
        <div className="flex flex-col justify-between h-full">
          <div
            className={classNames(
              'w-full p-4 text-white bg-eventsMain flex justify-between items-center h-[10%]',
            )}
          >
            <div className="w-1/5">
              <Button
                variant="custom"
                textColor="7eb5f4"
                size="sm"
                label={t('general.close')}
                onClick={() => {
                  navigate('/events/' + schoolId)
                }}
              />
            </div>
            <div className="flex items-center justify-center text-2xl w-3/5 font-bold">
              {t('events.eventCreate')}
            </div>
            <div className="w-1/5"></div>
          </div>
          <div className="flex flex-col gap-y-2 p-3 h-[75%] overflow-y-auto scrollbar-hide">
            <div>
              <h2 className="text-xl font-bold text-left mb-1">
                {t('events.typeOfEvent')}
              </h2>
              <TypeOfEventSelections newEvent={newEvent} onChange={onChange} />
            </div>
            <div>
              <PostEventToSelections
                newEvent={newEvent}
                onChange={onChange}
                departments={departments}
                schools={schools}
              />
            </div>
          </div>

          <div className="flex w-full justify-center h-[15%] items-center">
            <Button
              className="w-[40%]"
              size="lg"
              fontSize="font-bold"
              variant="fullBlue"
              label={t('login.nextTitle')}
              onClick={() => {
                departmentChosed
                  ? setEventStep(2)
                  : warningSwal('departmentMissing')
              }}
            />
          </div>
        </div>
      </Page>
    )
  },
)
