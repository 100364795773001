import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { Kid } from '../Models/Kid'

class AbsenceStore {
  kid?: Kid
  absences?: any[]
  absencesKids: any
  constructor() {
    makeAutoObservable(this)
  }

  setAbsences = (absences: any[]) => {
    this.absences = absences
  }
  setAbsencesKids = (absencesKids: any) => {
    this.absencesKids = absencesKids
  }

  setKid = (kid?: Kid) => {
    this.kid = kid
  }

  fetchAbsences = async (schoolId: string, kidId?: string) => {
    const absences = await Parse.Cloud.run('getAbsences', {
      schoolId,
      kidId,
      excludeAnonymous: true,
    })
    if (absences) {
      this.setAbsences(absences.logs)
    }
  }

  fetchKid = async (kidId: string) => {
    const kidQuery = new Parse.Query(Kid).equalTo('objectId', kidId)
    const kid = await kidQuery.first()
    if (kid) {
      this.setKid(kid)
    }
  }

  fetchAbsenceKids = async (schoolId: string) => {
    const absencesKids = await Parse.Cloud.run('getAbsenceKids', {
      schoolId,
      webCall: true,
    })
    this.setAbsencesKids(absencesKids)
  }

  reportBack = async (
    kidId: string,
    absenceId: string,
    includeToday: boolean,
  ) => {
    await Parse.Cloud.run('absenceReportBack', {
      kidId,
      absenceId,
      includeToday,
    })
  }

  addAbsence = async ({
    info,
    startDate,
    endDate,
    schoolId,
    kidId,
  }: {
    info?: string
    startDate: Date
    endDate: Date
    schoolId: string
    kidId: string
  }) => {
    const sD = startDate.toISOString().slice(0, 10)
    const eD = endDate.toISOString().slice(0, 10)
    await Parse.Cloud.run('addAbsence', {
      info,
      startDate: sD,
      endDate: eD,
      schoolId,
      kidId,
    })
  }

  addHistoricAbsence = async ({
    info,
    startDate,
    endDate,
    schoolId,
    kidId,
  }: {
    info?: string
    startDate: Date
    endDate: Date
    schoolId: string
    kidId: string
  }) => {
    const sD = startDate.toISOString().slice(0, 10)
    const eD = endDate.toISOString().slice(0, 10)
    await Parse.Cloud.run('addHistoricAbsence', {
      info,
      startDate: sD,
      endDate: eD,
      schoolId,
      kidId,
    })
  }

  editAbsence = async ({
    info,
    startDate,
    endDate,
    absenceId,
    confirmed,
  }: {
    info?: string
    startDate: Date
    endDate: Date
    absenceId: string
    confirmed?: boolean
  }) => {
    const sD = new Date(startDate).toISOString().slice(0, 10)
    const eD = new Date(endDate).toISOString().slice(0, 10)
    await Parse.Cloud.run('editAbsence', {
      info,
      startDate: sD,
      endDate: eD,
      absenceId,
      confirmed,
    })
  }

  confirmAbsence = async ({
    absenceId,
    confirmed,
  }: {
    absenceId: string
    confirmed?: boolean
  }) => {
    await Parse.Cloud.run('confirmAbsence', {
      absenceId,
      confirmed,
    })
  }

  absencePutToHistory = async (absenceId: string) => {
    await Parse.Cloud.run('absencePutToHistory', {
      absenceId: absenceId,
    })
  }

  removeAbsence = async (absence: any) => {
    await Parse.Cloud.run('deleteAbsence', {
      absenceId: absence.id,
    })
  }
}

export const absenceStore = (() => {
  return new AbsenceStore()
})()
export const AbsenceStoreContext: React.Context<AbsenceStore> =
  React.createContext(absenceStore)
