// AudioModal.tsx
import React, { useContext, useEffect, useState } from 'react'

import { t } from 'i18next'
import {
  faEnvelope,
  faFilePdf,
  faPhone,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import DownloadCard from './DownloadCard'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FILE_JOB_STATUS } from '../../../Models/FileJob'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { FileJobStoreContext } from '../../../store/fileJobStore'
import { KidsStoreContext } from '../../../store/kidsStore'
import { UserStoreContext } from '../../../store/userStore'
import { Loading } from '../../../components/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '../../../components/Button'
import Swal from 'sweetalert2'
import { type } from 'os'
type CopyType = 'email' | 'phone'
type CopiedItemType = CopyType | null
type AboutTyraModalProps = {
  showModal: boolean
  hideModal: () => void
}

const AboutTyraModal: React.FC<AboutTyraModalProps> = ({
  showModal,
  hideModal,
}) => {
  const copyToClipboard = (text: any, type: CopyType) => {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)

    setCopiedItem(type)
    const timeoutId = setTimeout(() => {
      setCopiedItem(null)
    }, 800)
    return () => clearTimeout(timeoutId)
  }

  const [copiedItem, setCopiedItem] = useState<CopiedItemType>(null)
  const handleIconClick = (contentToCopy: any, type: CopyType) => {
    copyToClipboard(contentToCopy, type)
  }

  return (
    <>
      <ModalRegular
        bgColor="bg-eventsMain p-4"
        isOpen={showModal}
        onClose={hideModal}
        closeColor="white"
        title={t('settings.aboutTYRA')}
      >
        <div className="flex flex-col ">
          <div className="flex flex-row w-full justify-center">
            <img
              className={'w-full max-w-[230px] object-contain mt-12'}
              src={process.env.PUBLIC_URL + '/images/TYRA_logo_text.png'}
            />

            <img
              className={
                'w-full animate-wave mt-2 max-w-[110px] object-contain'
              }
              src={process.env.PUBLIC_URL + '/images/hand.png'}
              alt="tyra logo"
            />
          </div>
          <h1 className="text-eventsMain font-bold flex flex-row w-full justify-center ">
            <>{t('settings.completeApp')}</>
          </h1>
          <div className="flex flex-row w-full my-8 justify-center items-center">
            <h2 className="flex justify-center items-center text-start max-w-[400px] relative">
              <p className="flex flex-col">
                <>{t('settings.aboutText')}</>
              </p>
            </h2>
          </div>

          <div className="flex flex-row  gap-x-20 mt-4 mb-10 justify-center">
            <div className="relative flex flex-col items-center">
              <FontAwesomeIcon
                className="text-eventsMain cursor-pointer hover:scale-105"
                size="3x"
                icon={faEnvelope}
                onClick={() => handleIconClick('support@tyra.io', 'email')}
              />
              <h3 className="cursor-pointer text-eventsMain font-bold flex items-center">
                support@tyra.io
              </h3>
              {copiedItem === 'email' && (
                <div className="border p-2 rounded-2xl shadow bg-gray-200 top-20 absolute ">
                  <>{t('general.copied')}</>
                </div>
              )}
            </div>
            <div className="relative flex flex-col items-center">
              <FontAwesomeIcon
                className="text-eventsMain cursor-pointer hover:scale-105"
                size="3x"
                icon={faPhone}
                onClick={() => handleIconClick('+46 40-869 10', 'phone')}
              />
              <h3 className="cursor-pointer text-eventsMain font-bold flex items-center">
                +46 40-869 10
              </h3>
              {copiedItem === 'phone' && (
                <div className="border p-2 rounded-2xl shadow bg-gray-200 top-20 absolute ">
                  <>{t('general.copied')}</>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-center py-7">
            <a
              href="https://m.facebook.com/tyraappen?wtsid=rdr_0i0dRiSEBRYi7Tj8v"
              target="_blank"
            >
              <Button
                variant="faceBook"
                label={t('settings.likeOnFacebook')}
                icon={faThumbsUp}
                reverseIcon={true}
              />
            </a>
          </div>

          <a href="https://www.linkedin.com/company/bayou-ab/" target="_blank">
            <div className="flex flex-row justify-center font-bold items-center mb-5">
              <p className="font-bold">Linked</p>
              <div className="py-[0.5px] px-[3px] rounded-sm bg-[#0077b5] text-white">
                in
              </div>
            </div>
          </a>
        </div>
      </ModalRegular>
    </>
  )
}

export default AboutTyraModal
