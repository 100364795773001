import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { Spinner } from '../../components/Spinner'
import Page from '../../components/Page'
import { UserStoreContext } from '../../store/userStore'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useTranslation } from 'react-i18next'
import { StatisticStoreContext } from '../../store/statisticStore'
import DatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import es from 'date-fns/locale/es'
import moment from 'moment'
import { Button } from '../../components/Button'
import { KidStoreContext } from '../../store/kidStore'
import Singleselect from '../../components/Singleselect'
import { KidsStoreContext } from '../../store/kidsStore'
import { getImageOrPlaceHolder } from '../../utils/parseUtils'
import { Kid } from '../../Models/Kid'
import { t } from 'i18next'
import ModalRegular from '../../components/ModalRegular'
import Parse from 'parse'
import {
  NotificationContext,
  NotificationVariant,
} from '../../App/notification/notificationManger'
import classNames from 'classnames'
import { Loading } from '../../components/Loading'
import Swal from 'sweetalert2'
import { faCheck, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import ColorCard from '../../components/ColorCard'
import { SORT_VALUES } from './Statistics'
import { v4 as uuidv4 } from 'uuid'
import { PresenceScheduleDiffContainer } from './components/PresenceScheduleDiffContainer'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
registerLocale('sv', sv)
registerLocale('en', en)
registerLocale('es', es)

export const ShowWeeks: React.FC<{
  kidStatistics: any
  firstDate: any
  firstDateOfWeek: any
  lastDate: any
  showWeekNumber?: boolean
  showKidInfo?: boolean
  schoolId?: string
  fetch: () => void
  fromDate: Date
  toDate: Date
  exporting?: boolean
  showSsn?: boolean
  sortBy?: SORT_VALUES
}> = observer(
  ({
    kidStatistics,
    firstDate,
    firstDateOfWeek,
    lastDate,
    showWeekNumber = true,
    showKidInfo = false,
    schoolId,
    fetch,
    fromDate,
    toDate,
    exporting,
    showSsn,
    sortBy,
  }) => {
    const [editLogMulti, setEditLogMulti] = useState<any>(null)
    const [editLog, setEditLog] = useState<any>(null)
    const [newLog, setNewLog] = useState<any>(null)
    const [accept, setAccept] = useState(false)
    const { createNewLog, setFreeOnSchedule, setAbsenceOnSchedule } =
      useContext(StatisticStoreContext)
    const { currentUser } = useContext(UserStoreContext)

    const [weeks, setWeeks] = useState<any>([])
    const { notify } = useContext(NotificationContext)
    const { t, i18n } = useTranslation()

    const [freeForDay, setFreeForDay] = useState(false)
    const [absenceForDay, setAbsenceForDay] = useState(false)

    const generateWeeks = () => {
      let weeks: any[] = []
      for (var d = firstDate; d < lastDate; firstDate.add(1, 'days')) {
        if (!weeks.find((wn) => wn.week == moment(firstDate).get('week'))) {
          const days: Array<any> = []
          for (var x = 0; x < 7; x++) {
            days.push(firstDateOfWeek.format('YYYY-MM-DD'))
            firstDateOfWeek.add(1, 'days')
          }
          weeks.push({
            week: moment(firstDate).get('week'),
            days,
            fromDate: moment(firstDate).startOf('week').add(1, 'days'),
            toDate: moment(firstDate).endOf('week').subtract(1, 'days'),
          })
        }
        //moment(firstDate).add(1, 'days');
      }
      return weeks
    }

    useEffect(() => {
      const weeks = generateWeeks()
      setWeeks(weeks)
    }, [firstDate])

    /*useEffect(() => {
      const kid = fetchKid(createLog?.kidId ?? '')
    }, [createLog])*/

    const createLog = async () => {
      const log = await createNewLog(
        kidStatistics?.kidId,
        newLog.checkedIn,
        newLog.checkedOut,
      )

      if (log) {
        setNewLog(null)
        fetch()
        /*notify({
        variant: NotificationVariant.OK,
        title: t('statistics.success'),
        message: t('statistics.InOutCheckSave'),
      })*/
        Swal.fire({
          text: t('statistics.InOutCheckSave'),
          icon: 'success',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        })
      } else {
        /*notify({
        variant: NotificationVariant.ERROR,
        title: t('events.eventAlarmFail'),
        message: t('statistics.contactSupport'),
      })*/
        Swal.fire({
          text: t('statistics.contactSupport'),
          icon: 'error',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        })
      }
    }

    const saveLog = async () => {
      try {
        for (const log of editLog) {
          if (log.remove) {
            await removeLog(log)
          } else if (log.new) {
            await createNewLog(
              kidStatistics?.kidId,
              log.checkedIn,
              log.checkedOut,
            )
          } else {
            const logCheckedIn = await new Parse.Query('log').get(
              log.checkedInId,
            )
            const logCheckedOut = await new Parse.Query('log').get(
              log.checkedOutId,
            )

            logCheckedIn.set('check_in_out_date', log.checkedIn)
            logCheckedIn.set('edited', true)
            logCheckedIn.set('user_performer_id', currentUser?.id)
            const saveCheckedIn = await logCheckedIn.save()
            logCheckedOut.set('check_in_out_date', log.checkedOut)
            logCheckedOut.set('edited', true)
            logCheckedOut.set('user_performer_id', currentUser?.id)
            const saveCheckedOut = await logCheckedOut.save()
          }
        }
        Swal.fire({
          text: t('statistics.changesSaved'),
          icon: 'success',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        })
        setEditLog(null)
        fetch()
      } catch (error) {
        Swal.fire({
          text: t('statistics.contactSupport'),
          icon: 'error',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        })
      }
      /*if (saveCheckedIn && saveCheckedOut) {
       
        Swal.fire({
          text: t('statistics.changesSaved'),
          icon: 'success',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        })
        setEditLog(null)
        fetch()
      } 
        Swal.fire({
          text: t('statistics.contactSupport'),
          icon: 'error',
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonColor: '#7eb5f4',
          cancelButtonColor: '#d33',
          confirmButtonText: t('general.ok'),
        })
      }*/
    }

    const removeLog = async (log: any) => {
      const logCheckedIn = await new Parse.Query('log').get(log.checkedInId)
      const logCheckedOut = await new Parse.Query('log').get(log.checkedOutId)

      logCheckedIn.set('check_in_out_date', log.checkedIn)
      const saveCheckedIn = await logCheckedIn.destroy().then(
        () => {
          return true
        },
        (error) => {
          /* notify({
          variant: NotificationVariant.ERROR,
          title: t('events.eventAlarmFail'),
          message: t('statistics.contactSupport'),
        })*/
          Swal.fire({
            text: t('statistics.contactSupport'),
            icon: 'error',
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            confirmButtonText: t('general.ok'),
          })
          throw error
        },
      )
      logCheckedOut.set('check_in_out_date', log.checkedOut)
      const saveCheckedOut = await logCheckedOut.destroy().then(
        () => {
          return true
        },
        (error) => {
          /* notify({
          variant: NotificationVariant.ERROR,
          title: t('events.eventAlarmFail'),
          message: t('statistics.contactSupport'),
        })*/
          Swal.fire({
            text: t('statistics.contactSupport'),
            icon: 'error',
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            confirmButtonText: t('general.ok'),
          })
          throw error
        },
      )

      /*notify({
      variant: NotificationVariant.OK,
      title: t('statistics.success'),
      message: t('statistics.changesSaved'),
    })*/
      /*Swal.fire({
        text: t('statistics.changesSaved'),
        icon: 'success',
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#7eb5f4',
        cancelButtonColor: '#d33',
        confirmButtonText: t('general.ok'),
      })
      setEditLog(null)
      fetch()*/
    }

    return (
      <>
        {weeks.map((week: any, index: any) => {
          let totalTimeAttendingHours = 0
          let totalTimeAttendingMinutes = 0
          let totalTimeAttendingHoursMulti = 0
          let totalTimeAttendingMinutesMulti = 0
          return (
            <tr
              className={index % 2 == 0 ? 'bg-white' : 'bg-gray-200'}
              key={index}
            >
              {showWeekNumber && (
                <td className="p-2 whitespace-nowrap">
                  <>
                    {t('general.week')} {week.week}
                    <br />
                    <span className="text-2xs">
                      {moment(week.fromDate).format('DD/MM') +
                        ' - ' +
                        moment(week.toDate).format('DD/MM')}
                    </span>
                  </>
                </td>
              )}
              {showKidInfo && (
                <>
                  <td className="p-2 whitespace-nowrap">
                    <a
                      href={
                        schoolId
                          ? '/statistics/' +
                            schoolId +
                            '/' +
                            kidStatistics.kidId
                          : '#'
                      }
                    >
                      <div className="flex items-center cursor-pointer hover:scale-[102%]">
                        <div className="flex-shrink-0 mr-2 sm:mr-3">
                          <img
                            className="rounded-full"
                            src={
                              kidStatistics.kidImage
                                ? kidStatistics.kidImage
                                : process.env.PUBLIC_URL +
                                  '/images/placeholder-image.png'
                            }
                            width="60"
                            height="60"
                            alt={''}
                          />
                        </div>
                        <div className="font-bold text-gray-800 mr-12">
                          {exporting ? (
                            <>{kidStatistics.kidName}</>
                          ) : (
                            <>
                              {sortBy === SORT_VALUES.FIRSTNAMEASC ||
                              sortBy === SORT_VALUES.FIRSTNAMEDESC
                                ? kidStatistics.kidFirstName
                                : null}
                              {sortBy === SORT_VALUES.LASTNAMEASC ||
                              sortBy === SORT_VALUES.LASTNAMEDESC
                                ? kidStatistics.kidLastName
                                : null}
                              <br />{' '}
                              <span className="font-normal">
                                {sortBy === SORT_VALUES.FIRSTNAMEASC ||
                                sortBy === SORT_VALUES.FIRSTNAMEDESC
                                  ? kidStatistics.kidLastName
                                  : null}
                                {sortBy === SORT_VALUES.LASTNAMEASC ||
                                sortBy === SORT_VALUES.LASTNAMEDESC
                                  ? kidStatistics.kidFirstName
                                  : null}
                              </span>
                            </>
                          )}
                          <br />{' '}
                          {!exporting ? (
                            <span className="text-xs text-gray-700 font-normal">
                              {kidStatistics.kidSsn}
                            </span>
                          ) : showSsn ? (
                            <span className="text-xs text-gray-700 font-normal">
                              {kidStatistics.kidSsn}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </a>
                  </td>
                  {/*showSsn && (
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left">{kidStatistics.kidSsn}</div>
                    </td>
                  )*/}
                </>
              )}

              {kidStatistics &&
                week.days.map((day: any) => {
                  let checkedInArray = []
                  let checkedIn = null
                  let checkedOut = null
                  let logMulti = kidStatistics?.logs?.filter(
                    (log: any) =>
                      moment(log.checkedIn).format('YYYY-MM-DD') === day &&
                      moment(log.checkedOut).format('YYYY-MM-DD') === day,
                  )

                  for (const row of logMulti ?? []) {
                    if (row.checkedIn && row.checkedOut) {
                      var diffMs =
                        (moment(
                          moment(row.checkedOut).format('YYYY-MM-DD HH:mm'),
                        ) as any) -
                        (moment(
                          moment(row.checkedIn).format('YYYY-MM-DD HH:mm'),
                        ) as any)
                      // TODO Should have better data type above.
                      var diffHrs = Math.floor((diffMs % 86400000) / 3600000)
                      var diffMins = Math.round(
                        ((diffMs % 86400000) % 3600000) / 60000,
                      )
                      totalTimeAttendingHoursMulti =
                        totalTimeAttendingHoursMulti + diffHrs
                      totalTimeAttendingMinutesMulti =
                        totalTimeAttendingMinutesMulti + diffMins
                      if (totalTimeAttendingMinutesMulti >= 60) {
                        totalTimeAttendingHoursMulti =
                          totalTimeAttendingHoursMulti + 1
                        totalTimeAttendingMinutesMulti =
                          totalTimeAttendingMinutesMulti - 60
                      }
                    }
                  }
                  let log = kidStatistics.logs?.find(
                    (log: any) =>
                      moment(log.checkedIn).format('YYYY-MM-DD') === day &&
                      moment(log.checkedOut).format('YYYY-MM-DD') === day,
                  )

                  checkedIn = kidStatistics.logs?.find(
                    (log: any) =>
                      moment(log.checkedIn).format('YYYY-MM-DD') === day,
                  )?.checkedIn
                  checkedOut = kidStatistics.logs?.find(
                    (log: any) =>
                      moment(log.checkedOut).format('YYYY-MM-DD') === day,
                  )?.checkedOut

                  if (checkedIn && checkedOut) {
                    var diffMs = (checkedOut as any) - (checkedIn as any)
                    // TODO Should have better data type above.
                    var diffHrs = Math.floor((diffMs % 86400000) / 3600000)
                    var diffMins = Math.round(
                      ((diffMs % 86400000) % 3600000) / 60000,
                    )
                    totalTimeAttendingHours = totalTimeAttendingHours + diffHrs
                    totalTimeAttendingMinutes =
                      totalTimeAttendingMinutes + diffMins

                    if (totalTimeAttendingMinutes >= 60) {
                      totalTimeAttendingHours = totalTimeAttendingHours + 1
                      totalTimeAttendingMinutes = totalTimeAttendingMinutes - 60
                    }
                  }

                  let scheduleStart = kidStatistics.kidSchedule?.find(
                    (sched: any) => sched.date == day,
                  )?.from
                  let scheduleEnd = kidStatistics.kidSchedule?.find(
                    (sched: any) => sched.date == day,
                  )?.to

                  if (moment(day).day() == 0 || moment(day).day() == 6) {
                    return null
                  }

                  if (
                    moment(day).format('YYYY-MM-DD') <
                    moment(fromDate).format('YYYY-MM-DD')
                  ) {
                    return <td></td>
                  }

                  if (
                    moment(day).format('YYYY-MM-DD') >
                    moment(toDate).format('YYYY-MM-DD')
                  ) {
                    return <td></td>
                  }
                  if (
                    kidStatistics.closedDates?.find(
                      (cD: string) => cD === moment(day).format('YYYY-MM-DD'),
                    )
                  ) {
                    return (
                      <td className="text-center cursor-pointer hover:bg-blue-200 text-blue-500">
                        <>
                          <span className="w-full text-center">
                            <>{t('statistics.closed')}</>
                          </span>
                        </>
                      </td>
                    )
                  }
                  if (
                    kidStatistics.kidSchedule?.find(
                      (sched: any) => sched.date == day,
                    )?.from == 'null'
                  ) {
                    return (
                      <td
                        className={classNames(
                          'text-center cursor-pointer hover:bg-blue-200',
                          checkedIn && checkedOut
                            ? 'text-green-600 font-bold'
                            : '',
                        )}
                        onClick={() => {
                          if (log) {
                            setEditLog(log)
                          } else {
                            setNewLog({
                              kidId: kidStatistics?.kidId,
                              date: new Date(day),
                              checkedIn: new Date(
                                moment(day).format('YYYY-MM-DD 08:00'),
                              ),
                              checkedOut: new Date(
                                moment(day).format('YYYY-MM-DD 16:00'),
                              ),
                              freeAvailable: true,
                            })
                          }
                        }}
                      >
                        {checkedIn && checkedOut && (
                          <>
                            {moment(checkedIn).format('HH:mm') +
                              ' - ' +
                              moment(checkedOut).format('HH:mm')}{' '}
                          </>
                        )}{' '}
                        {!exporting && (
                          <>
                            {checkedIn && checkedOut && <br />}
                            <span className="w-full text-center">-</span>
                          </>
                        )}
                      </td>
                    )
                  } else if (
                    scheduleStart &&
                    scheduleEnd &&
                    /*checkedIn &&
                    checkedOut*/
                    logMulti?.length
                  ) {
                    return (
                      <td
                        className="text-center relative text-green-600 hover:bg-blue-200"
                        onClick={() => {
                          setEditLog(logMulti)
                          /* else {
                          setNewLog({
                            kidId: kidStatistics?.kidId,
                            date: new Date(day),
                            checkedIn: new Date(
                              moment(day).format('YYYY-MM-DD 08:00'),
                            ),
                            checkedOut: new Date(
                              moment(day).format('YYYY-MM-DD 16:00'),
                            ),
                          })
                        }*/
                        }}
                      >
                        {/*{checkedIn && checkedOut && (*/}
                        <>
                          {/*moment(checkedIn).format('HH:mm') +
                              ' - ' +
                        moment(checkedOut).format('HH:mm')*/}
                          {logMulti?.map((multi: any) => {
                            return (
                              <>
                                {moment(multi.checkedIn).format('HH:mm') +
                                  ' - ' +
                                  moment(multi.checkedOut).format('HH:mm')}
                                <br />
                              </>
                            )
                          })}
                          {/*!exporting && (
                            <div
                              className="absolute top-2 right-0 rounded-full w-5 h-5 bg-eventsMain text-white cursor-pointer"
                              onClick={() => {
                                setNewLog({
                                  kidId: kidStatistics?.kidId,
                                  date: new Date(day),
                                  checkedIn: new Date(
                                    moment(day).format('YYYY-MM-DD 08:00'),
                                  ),
                                  checkedOut: new Date(
                                    moment(day).format('YYYY-MM-DD 16:00'),
                                  ),
                                })
                              }}
                            >
                              +
                            </div>
                            )*/}
                        </>
                        {/*)}*/}

                        {/*
                      
                          TODO: DONT DO THIS ?? SHOW FREE IF CHECKED IN AND OUT*/}

                        {!exporting && (
                          <table className="!text-orange-400 w-full font-normal">
                            <tbody>
                              <tr>
                                <td className="text-green-600 w-full text-center block"></td>
                              </tr>
                              <tr className="">
                                {scheduleStart == 'ledig' ? (
                                  <td className="text-blue-500 w-full text-center">
                                    <>{t('statistics.offHour')}</>
                                  </td>
                                ) : (
                                  <td className="!text-orange-400 relative w-full text-center">
                                    {scheduleStart} - {scheduleEnd}
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </td>
                    )
                  } else if (
                    kidStatistics.kidSchedule?.find(
                      (sched: any) => sched.date == day,
                    )?.from == 'Stängt'
                  ) {
                    return (
                      <td
                        className={classNames(
                          'text-center cursor-pointer hover:bg-blue-200',
                          checkedIn && checkedOut
                            ? 'text-green-600 font-bold'
                            : 'text-blue-500',
                        )}
                        onClick={() => {
                          if (log) {
                            setEditLog(log)
                          } else {
                            setNewLog({
                              kidId: kidStatistics?.kidId,
                              date: new Date(day),
                              checkedIn: new Date(
                                moment(day).format('YYYY-MM-DD 08:00'),
                              ),
                              checkedOut: new Date(
                                moment(day).format('YYYY-MM-DD 16:00'),
                              ),
                              absenceAvailable: true,
                              week: week.week,
                              freeAvailable: false,
                            })
                            setAbsenceForDay(false)
                          }
                        }}
                      >
                        {checkedIn && checkedOut && (
                          <>
                            {moment(checkedIn).format('HH:mm') +
                              ' - ' +
                              moment(checkedOut).format('HH:mm')}
                          </>
                        )}{' '}
                        <>
                          <span className="w-full text-center">
                            <>{t('statistics.closed')}</>
                          </span>
                        </>
                      </td>
                    )
                  } else if (
                    kidStatistics.kidSchedule?.find(
                      (sched: any) => sched.date == day,
                    )?.from == 'ledig'
                  ) {
                    return (
                      <td
                        className={classNames(
                          'text-center cursor-pointer hover:bg-blue-200',
                          checkedIn && checkedOut
                            ? 'text-green-600 font-bold'
                            : 'text-blue-500',
                        )}
                        onClick={() => {
                          if (log) {
                            setEditLog(log)
                          } else {
                            setNewLog({
                              kidId: kidStatistics?.kidId,
                              date: new Date(day),
                              checkedIn: new Date(
                                moment(day).format('YYYY-MM-DD 08:00'),
                              ),
                              checkedOut: new Date(
                                moment(day).format('YYYY-MM-DD 16:00'),
                              ),
                              absenceAvailable: true,
                              week: week.week,
                              freeAvailable: false,
                            })
                            setAbsenceForDay(false)
                          }
                        }}
                      >
                        {checkedIn && checkedOut && (
                          <>
                            {moment(checkedIn).format('HH:mm') +
                              ' - ' +
                              moment(checkedOut).format('HH:mm')}
                          </>
                        )}{' '}
                        <>
                          <span className="w-full text-center">
                            <>{t('statistics.offHour')}</>
                          </span>
                        </>
                      </td>
                    )
                  } else if (day < moment().format('YYYY-MM-DD')) {
                    if (/*checkedIn && checkedOut*/ logMulti?.length) {
                      return (
                        <td
                          className="p-2 text-center whitespace-nowrap text-green-600 font-bold cursor-pointer hover:bg-blue-200"
                          onClick={() => {
                            setEditLog(logMulti)
                          }}
                        >
                          {logMulti?.map((multi: any) => {
                            return (
                              <>
                                {moment(multi.checkedIn).format('HH:mm') +
                                  ' - ' +
                                  moment(multi.checkedOut).format('HH:mm')}
                                <br />
                              </>
                            )
                          })}
                        </td>
                      )
                    } else {
                      return (
                        <td
                          className="text-red-500 text-center font-medium cursor-pointer hover:bg-blue-200"
                          onClick={() => {
                            let newCheckedIn =
                              scheduleStart && scheduleStart !== 'ledig'
                                ? moment(day).format(
                                    'YYYY-MM-DD ' + scheduleStart,
                                  )
                                : moment(day).format('YYYY-MM-DD 08:00')
                            let newCheckedOut =
                              scheduleEnd && scheduleEnd !== 'ledig'
                                ? moment(day).format(
                                    'YYYY-MM-DD ' + scheduleEnd,
                                  )
                                : moment(day).format('YYYY-MM-DD 16:00')

                            setNewLog({
                              kidId: kidStatistics?.kidId,
                              date: new Date(day),
                              checkedIn: new Date(newCheckedIn),
                              checkedOut: new Date(newCheckedOut),
                              freeAvailable: true,
                              week: week.week,
                              scheduleStart: scheduleStart,
                              scheduleEnd: scheduleEnd,
                            })
                          }}
                        >
                          <>{t('absence.absence')}</>
                          {!exporting && (
                            <table className="hideWhenExport w-full font-normal">
                              <tbody>
                                <tr>
                                  <td className="text-green-600 w-full text-center block"></td>
                                </tr>
                                <tr className="">
                                  {scheduleStart == 'ledig' ? (
                                    <td className="text-blue-500 w-full text-center">
                                      <>{t('statistics.offHour')}</>
                                    </td>
                                  ) : (
                                    <td className="text-orange-400 w-full text-center">
                                      {scheduleStart} - {scheduleEnd}
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </td>
                      )
                    }
                  } else {
                    let scheduleStart = kidStatistics.kidSchedule?.find(
                      (sched: any) => sched.date == day,
                    )?.from
                    let scheduleEnd = kidStatistics.kidSchedule?.find(
                      (sched: any) => sched.date == day,
                    )?.to
                    if (scheduleStart && scheduleEnd) {
                      var diffMs =
                        (moment(
                          moment(day).format('YYYY-MM-DD ' + scheduleEnd),
                        ) as any) -
                        (moment(
                          moment(day).format('YYYY-MM-DD ' + scheduleStart),
                        ) as any)

                      // TODO Should have better data type above.
                      var diffHrs = Math.floor((diffMs % 86400000) / 3600000)
                      var diffMins = Math.round(
                        ((diffMs % 86400000) % 3600000) / 60000,
                      )
                      totalTimeAttendingHoursMulti =
                        totalTimeAttendingHoursMulti + diffHrs
                      totalTimeAttendingMinutesMulti =
                        totalTimeAttendingMinutesMulti + diffMins
                      if (totalTimeAttendingMinutesMulti >= 60) {
                        totalTimeAttendingHoursMulti =
                          totalTimeAttendingHoursMulti + 1
                        totalTimeAttendingMinutesMulti =
                          totalTimeAttendingMinutesMulti - 60
                      }

                      return (
                        <td className="text-orange-400 text-center">
                          {scheduleStart} - {scheduleEnd}
                        </td>
                      )
                    } else {
                      return <td></td>
                    }
                  }
                })}

              <td className="text-center font-semibold text-black">
                {/*totalTimeAttendingHours}h {totalTimeAttendingMinutes}m - EN
              <br />*/}
                {totalTimeAttendingHoursMulti}h {totalTimeAttendingMinutesMulti}
                m{/* BÅDA */}
              </td>
            </tr>
          )
        })}
        {editLog && (
          <ModalRegular
            className="overflow-visible max-w-lg"
            isOpen={editLog ? true : false}
            onClose={() => {
              setEditLog(null)
            }}
          >
            <div className="w-full flex justify-center mb-4 p-4 text-center">
              <b>
                {kidStatistics?.kidName} -{' '}
                {editLog && moment(editLog[0].checkedIn).format('YYYY-MM-DD')}
              </b>
            </div>
            <div className="p-10 flex flex-col gap-y-10">
              <div className="flex flex-col flex-wrap w-full justify-center ">
                <div className="flex flex-col w-full justify-center relative gap-y-2">
                  {editLog
                    .filter((log: any) => !log.remove)
                    .map((log: any) => {
                      return (
                        <div className="flex justify-center flex-row items-start gap-x-2 ">
                          <div className="flex justify-center flex-col items-start ">
                            <div className="text-green-400 font-semibold ">
                              {t('statistics.checkedInTime')}
                            </div>
                            <div className="flex items-center rounded-xl">
                              {' '}
                              <div>
                                <DatePicker
                                  className="border border-gray-500 shadow p-2 py-[10px] rounded-xl text-center max-w-[140px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                  selected={
                                    log?.checkedIn
                                      ? log?.checkedIn == 'ledig'
                                        ? null
                                        : log?.checkedIn
                                      : null
                                  }
                                  onChange={(date: Date) => {
                                    const updatedEditLog = editLog.map(
                                      (row: any) => {
                                        if (
                                          row.checkedInId === log.checkedInId
                                        ) {
                                          // This is the row we want to update
                                          return { ...row, checkedIn: date }
                                        } else {
                                          // This is not the row we want to update, so we return it unchanged
                                          return row
                                        }
                                      },
                                    )
                                    setEditLog(updatedEditLog)
                                  }}
                                  minTime={
                                    new Date(
                                      moment(log?.checkedIn).format(
                                        'YYYY-MM-DD 00:01',
                                      ),
                                    )
                                  }
                                  maxTime={log?.checkedOut ?? null}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="HH:mm"
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  locale={i18n.language}
                                  onKeyDown={(e) => {
                                    e.preventDefault()
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-center items-center">
                            <div className="flex justify-center flex-col items-start">
                              <div className="text-red-300 font-semibold ">
                                {t('statistics.checkedOutTime')}
                              </div>
                              <div className=" flex items-center rounded-xl">
                                <DatePicker
                                  className="border border-gray-500 shadow p-2 py-[10px] rounded-xl text-center max-w-[140px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                  selected={log?.checkedOut ?? null}
                                  onChange={(date: Date) => {
                                    const updatedEditLog = editLog.map(
                                      (row: any) => {
                                        if (
                                          row.checkedOutId === log.checkedOutId
                                        ) {
                                          // This is the row we want to update
                                          return { ...row, checkedOut: date }
                                        } else {
                                          // This is not the row we want to update, so we return it unchanged
                                          return row
                                        }
                                      },
                                    )
                                    setEditLog(updatedEditLog)
                                  }}
                                  minTime={log?.checkedIn ?? null}
                                  maxTime={
                                    new Date(
                                      moment(log?.checkedOut).format(
                                        'YYYY-MM-DD 23:59',
                                      ),
                                    )
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="HH:mm"
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  locale={i18n.language}
                                  onKeyDown={(e) => {
                                    e.preventDefault()
                                  }}
                                />
                              </div>
                            </div>
                            <div className="ml-3 mt-[20px] absolute right-5 t-[20px]">
                              <Button
                                variant="fullRed"
                                size="md"
                                //label="Ta bort"
                                icon={faTrashCan}
                                onClick={() => {
                                  //removeLog(log)
                                  const updatedEditLog = editLog.map(
                                    (row: any) => {
                                      if (
                                        row.checkedOutId === log.checkedOutId
                                      ) {
                                        // This is the row we want to update
                                        return { ...row, remove: true }
                                      } else {
                                        // This is not the row we want to update, so we return it unchanged
                                        return row
                                      }
                                    },
                                  )
                                  setEditLog(updatedEditLog)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  {/*<div className="flex justify-center items-center gap-4 mt-2">
                          <div className="flex items-center gap-4 py-2 px-4 rounded-xl">
                            <Button
                          variant="default"
                         label="Sätt som ledig"
                        onClick={() => {
                           saveLog("free")
                         }}
                      />
                            </div>
                            </div>*/}
                  <div className="w-full flex justify-center mt-5">
                    <Button
                      variant="fullBlue"
                      size="md"
                      //label="Ta bort"
                      icon={faPlus}
                      onClick={() => {
                        //removeLog(log)
                        setEditLog([
                          ...editLog,
                          {
                            new: true,
                            checkedIn: new Date(
                              moment(editLog[0].checkedIn).format(
                                'YYYY-MM-DD 08:00',
                              ),
                            ),
                            checkedInId: uuidv4(),
                            checkedOut: new Date(
                              moment(editLog[0].checkedIn).format(
                                'YYYY-MM-DD 16:00',
                              ),
                            ),
                            checkedOutId: uuidv4(),
                          },
                        ])
                        /*const updatedEditLog = editLog.map((row: any) => {
                          if (row.checkedOutId === log.checkedOutId) {
                            // This is the row we want to update
                            return { ...row, remove: true }
                          } else {
                            // This is not the row we want to update, so we return it unchanged
                            return row
                          }
                        })
                        setEditLog(updatedEditLog)*/
                      }}
                    />
                  </div>

                  <div className="w-full flex justify-center mt-8">
                    <Button
                      variant="fullGreen"
                      size="custom"
                      label="Spara"
                      onClick={() => {
                        saveLog()
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalRegular>
        )}

        {newLog && (
          <ModalRegular
            className="overflow-visible p-0 max-w-lg"
            isOpen={newLog ? true : false}
            onClose={() => {
              setNewLog(null)
              setFreeForDay(false)
              setAbsenceForDay(false)
            }}
          >
            <div className="w-full flex justify-center mb-4 p-4 text-center">
              <b>
                {kidStatistics?.kidName} -{' '}
                {moment(newLog?.date).format('YYYY-MM-DD')}
              </b>
            </div>
            <div className="p-10 flex flex-col gap-y-10">
              <div className="flex flex-col flex-wrap w-full justify-center">
                <div className="flex flex-row w-full justify-center relative gap-x-2">
                  <div className={'flex justify-center flex-col items-start '}>
                    <div className="flex justify-center flex-col items-start ">
                      <div className="text-green-400 font-semibold ">
                        {t('statistics.checkedInTime')}
                      </div>
                      <div className="flex items-center rounded-xl">
                        <DatePicker
                          className="border border-gray-500 shadow p-2 py-[10px] rounded-xl text-center max-w-[140px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                          selected={
                            newLog && newLog?.checkedIn
                              ? newLog?.checkedIn
                              : new Date(
                                  moment(newLog?.date).format(
                                    'YYYY-MM-DD 08:00',
                                  ),
                                )
                          }
                          onChange={(date: Date) =>
                            setNewLog({ ...newLog, checkedIn: date })
                          }
                          minTime={
                            new Date(
                              moment(newLog?.checkedIn).format(
                                'YYYY-MM-DD 00:01',
                              ),
                            )
                          }
                          disabled={freeForDay}
                          maxTime={newLog?.checkedOut ?? null}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption="HH:mm"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          locale={i18n.language}
                          onKeyDown={(e) => {
                            e.preventDefault()
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <div className="flex justify-center flex-col items-start">
                      <div className="text-red-300 font-semibold ">
                        {t('statistics.checkedOutTime')}
                      </div>
                      <div className=" flex items-center rounded-xl">
                        <DatePicker
                          className="border border-gray-500 shadow p-2 py-[10px] rounded-xl text-center max-w-[140px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                          selected={
                            newLog && newLog?.checkedOut
                              ? newLog?.checkedOut
                              : new Date(
                                  moment(newLog?.date).format(
                                    'YYYY-MM-DD 16:00',
                                  ),
                                )
                          }
                          disabled={freeForDay}
                          onChange={(date: Date) =>
                            setNewLog({ ...newLog, checkedOut: date })
                          }
                          minTime={newLog?.checkedIn ?? null}
                          maxTime={
                            new Date(
                              moment(newLog?.checkedOut).format(
                                'YYYY-MM-DD 23:59',
                              ),
                            )
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption="HH:mm"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          locale={i18n.language}
                          onKeyDown={(e) => {
                            e.preventDefault()
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-end">
                    {newLog.absenceAvailable ? (
                      !absenceForDay ? (
                        <Button
                          variant={'squaredShadow'}
                          label={t('absence.absence')}
                          size="md"
                          onClick={() => {
                            setAbsenceForDay(true)
                          }}
                        />
                      ) : (
                        <Button
                          className="text-white"
                          variant={'squaredShadowAccept'}
                          label={t('absence.absence')}
                          icon={faCheck}
                          reverseIcon
                          size="md"
                          onClick={() => {
                            setAbsenceForDay(false)
                          }}
                        />
                      )
                    ) : null}
                    {newLog.freeAvailable ? (
                      !freeForDay ? (
                        <Button
                          variant={'squaredShadow'}
                          label={t('statistics.offHour')}
                          size="md"
                          onClick={() => {
                            setFreeForDay(true)
                          }}
                        />
                      ) : (
                        <Button
                          className="text-white"
                          variant={'squaredShadowAccept'}
                          label={t('statistics.offHour')}
                          icon={faCheck}
                          reverseIcon
                          size="md"
                          onClick={() => {
                            setFreeForDay(false)
                          }}
                        />
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              {newLog.scheduleStart && newLog.scheduleEnd && (
                <div className=" text-gray-500 font-semibold pl-5">
                  <>{t('statistics.accordingSchedule')}: </>
                  {newLog && newLog?.scheduleStart
                    ? newLog.scheduleStart
                    : '08:30'}{' '}
                  -{' '}
                  {newLog && newLog?.scheduleEnd ? newLog.scheduleEnd : '16:30'}
                </div>
              )}
              <div className="w-full flex justify-end">
                <Button
                  size="custom"
                  variant="fullBlue"
                  label={t('general.save')}
                  onClick={() => {
                    if (freeForDay) {
                      setFreeOnSchedule(
                        kidStatistics?.kidId,
                        newLog?.checkedIn,
                        newLog?.week,
                      ).finally(() => {
                        fetch()
                        setNewLog(null)
                        setFreeForDay(false)
                        setAbsenceForDay(false)
                      })
                    } else if (absenceForDay) {
                      setAbsenceOnSchedule(
                        kidStatistics?.kidId,
                        newLog?.checkedIn,
                        newLog?.week,
                      ).finally(() => {
                        fetch()
                        setNewLog(null)
                        setFreeForDay(false)
                        setAbsenceForDay(false)
                      })
                    } else {
                      createLog().finally(() => {
                        setNewLog(null)
                        setFreeForDay(false)
                        setAbsenceForDay(false)
                      })
                    }
                  }}
                />
              </div>
            </div>
          </ModalRegular>
        )}
      </>
    )
  },
)

export const StatisticsDetail: React.FC = observer(() => {
  let { schoolId, kidId } = useParams()
  const { setTitle } = useContext(NavigationStoreContext)
  const { t, i18n } = useTranslation()
  const { fetchKid, kid } = useContext(KidStoreContext)
  const { saveKidTariff } = useContext(KidsStoreContext)
  const [historicSchemaCsvnExport, setHistoricSchemaCsvnExport] =
    useState<boolean>(false)
  const {
    getKidStatistics,
    statistics,
    statisticsFromDate,
    setStatisticsFromDate,
    statisticsToDate,
    setStatisticsToDate,
    statisticsWeeksOnly,
    setStatisticsWeeksOnly,
  } = useContext(StatisticStoreContext)
  const isMonday = (date: Date) => {
    const day = date.getDay()
    if (!statisticsWeeksOnly) return day !== 1337
    return (
      day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6 && day !== 0
    )
  }
  const isSunday = (date: Date) => {
    const day = date.getDay()
    if (!statisticsWeeksOnly) return day !== 1337
    return (
      day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6 && day !== 1
    )
  }
  /*const [fromDate, setFromDate] = useState<Date | null>(
    new Date(
      moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
    ),
  )
  const [toDate, setToDate] = useState<Date | null>(
    new Date(moment().endOf('week').format('YYYY-MM-DD')),
  )*/
  const { savedUserRoleId, fetchUserRole, currentUserRole } =
    useContext(UserStoreContext)
  const [loading, setLoading] = useState(true)
  let [firstDate, setFirstDate] = useState(moment().startOf('week'))
  let [lastDate, setLastDate] = useState(moment().endOf('week'))
  const [amountOfDays, setAmountOfDays] = useState(7)
  const [typeOfView, setTypeOfView] = useState<'day' | 'oneWeek' | 'month'>(
    'oneWeek',
  )

  const tariffValues = [
    {
      value: null,
      label: t('statistics.tariffNothingSelected'),
    },
    {
      value: '-15',
      label: t('statistics.tariffMaxHours').replace('XXtimmarXX', '15'),
    },
    {
      value: '15',
      label: t('statistics.tariffHoursOver').replace('XXtimmarXX', '15'),
    },
    {
      value: '-20',
      label: t('statistics.tariffMaxHours').replace('XXtimmarXX', '20'),
    },
    {
      value: '20',
      label: t('statistics.tariffHoursOver').replace('XXtimmarXX', '20'),
    },
    {
      value: '-30',
      label: t('statistics.tariffMaxHours').replace('XXtimmarXX', '30'),
    },
    {
      value: '30',
      label: t('statistics.tariffHoursOver').replace('XXtimmarXX', '30'),
    },
    {
      value: '-40',
      label: t('statistics.tariffMaxHours').replace('XXtimmarXX', '40'),
    },
    {
      value: '40',
      label: t('statistics.tariffHoursOver').replace('XXtimmarXX', '40'),
    },
  ]

  useEffect(() => {
    if (savedUserRoleId && schoolId) {
      /*getStatistics(
        savedUserRoleId,
        fromDate,
        toDate,
        selectedDepartments,
        selectedKids,
        schoolId,
      )*/

      setLoading(true)
      fetchUserRole(savedUserRoleId).finally(() => {})
      setTitle(t('menu.attendance'))
    }
  }, [])

  const [exporting, setExporting] = useState(false)
  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay))
  }

  const generatePdf = async (type: 'p' | 'l') => {
    setExporting(true)
    await timeout(1000)
    const doc = new jsPDF(type)

    /*autoTable(doc, {
      //startY: 30,

      //html: showSum ? '#my-table-header' : '#my-table-header' + i,
      html: '#my-table-header',
      //theme: 'striped',
      useCss: true,
      includeHiddenHtml: false,
      //styles: { cellPadding: 10 },
    })*/

    autoTable(doc, {
      //startY: 30,
      //html: showSum ? '#my-table' : '#my-table' + i,
      html: '#my-table',
      //theme: 'striped',
      useCss: true,
      includeHiddenHtml: false,
      didDrawPage: function (data) {
        // @ts-ignore
        const pageCount = doc.internal.getNumberOfPages()

        doc.setFontSize(8)

        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight()
      },
    })

    let docName = ''

    docName =
      'tyra-export-weekly_' +
      moment(statisticsFromDate).format('YYYY-MM-DD') +
      '-' +
      moment(statisticsToDate).format('YYYY-MM-DD') +
      '.pdf'

    doc.save(docName)

    setExporting(false)
  }

  const generateCsvSum = async () => {
    let rows: any = []
    let headers: any[] = []
    headers.push(t('statistics.kidName'))
    headers.push(t('statistics.totalScheduleTime'))
    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      headers.push(t('statistics.totalPresence'))
      headers.push(t('statistics.totalDiff'))
    }
    headers.push(t('statistics.schedulePerWeek'))

    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      headers.push(t('statistics.presencePerWeek')) // Per vecka
      headers.push(t('statistics.diffPerWeek'))
    }

    headers.push(t('statistics.schedulePerDay'))

    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      headers.push(t('statistics.presencePerDay')) // Per dag
      headers.push(t('statistics.diffPerDay'))
      //}
    }

    headers.push(t('statistics.offHour'))
    headers.push(t('statistics.tariff'))

    rows.push(headers)

    let rowElements = [statistics.kidName]
    rowElements.push(
      statistics.kidScheduleTotal?.hours +
        'h ' +
        statistics.kidScheduleTotal?.minutes +
        'm',
    )
    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      rowElements.push(
        statistics.kidTimeAttendingTotal?.hours +
          'h ' +
          statistics.kidTimeAttendingTotal?.minutes +
          'm',
      )
      rowElements.push(
        statistics.kidTimeDifferenceTotal?.hours +
          'h ' +
          statistics.kidTimeDifferenceTotal?.minutes +
          'm',
      )
    }

    rowElements.push(
      statistics.kidScheduleHoursPerWeek?.hours +
        'h ' +
        statistics.kidScheduleHoursPerWeek?.minutes +
        'm',
    )

    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      rowElements.push(
        statistics.kidTimeAttending?.hours +
          'h ' +
          statistics.kidTimeAttending?.minutes +
          'm',
      )
      rowElements.push(
        statistics.kidTimeDifference?.hours +
          'h ' +
          statistics.kidTimeDifference?.minutes +
          'm',
      )
    }

    rowElements.push(
      statistics.kidSchedulePerDay?.hours +
        'h ' +
        statistics.kidSchedulePerDay?.minutes +
        'm',
    )
    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      rowElements.push(
        statistics.kidTimeAttendingPerDay?.hours +
          'h ' +
          statistics.kidTimeAttendingPerDay?.minutes +
          'm',
      )
      rowElements.push(
        statistics.kidTimeDifferencePerDay?.hours +
          'h ' +
          statistics.kidTimeDifferencePerDay?.minutes +
          'm',
      )
    }

    rowElements.push(statistics.kidScheduleTotal?.totalFree)
    let tariff = statistics.tariff || ''
    rowElements.push(tariff)
    rows.push(rowElements)
    //})

    let csvContent = 'data:text/csv;charset=utf-8,'

    rows.forEach(function (rowArray: any) {
      let row = Array.isArray(rowArray) ? rowArray.join(',') : rowArray
      csvContent += row + '\r\n'
    })

    var encodedUri = encodeURI(csvContent)
    var hiddenElement = document.createElement('a')
    hiddenElement.href = encodedUri
    hiddenElement.target = '_blank'
    hiddenElement.download =
      'summery-export_' +
      statistics.kidName +
      '_' +
      moment(statisticsFromDate).format('YYYY-MM-DD') +
      '-' +
      moment(statisticsToDate).format('YYYY-MM-DD') +
      '.csv'
    hiddenElement.click()
  }

  const generateDays = (week: any) => {
    let weeks: any[] = []
    let firstDateOfWeek = moment(week.fromDate).startOf('week')
    let firstDateToAdd = moment(week.fromDate).startOf('week')
    /*for (var d = firstDateToAdd; d < lastDate; firstDateToAdd.add(1, 'days')) {
      if (!weeks.find((wn) => wn.week == moment(firstDateToAdd).get('week'))) {*/
    const days: Array<any> = []
    for (var x = 0; x < 7; x++) {
      days.push(firstDateOfWeek.format('YYYY-MM-DD'))
      firstDateOfWeek.add(1, 'days')
    }
    //weeks.push({ week: moment(firstDate).get('week'), days })
    //}
    //moment(firstDate).add(1, 'days');
    //}
    return { week, days }
  }
  const generateCsv = async () => {
    let rows: any = []
    let firstDateOfWeek = moment(statisticsToDate).startOf('week')
    let weeks: any[] = []
    const firstDate1 = moment(statisticsFromDate).startOf('week')
    const lastDate1 = moment(statisticsToDate).endOf('week')
    for (var d = firstDate1; d < lastDate1; firstDate1.add(1, 'days')) {
      if (!weeks.find((wn) => wn.week == moment(firstDate1).get('week'))) {
        const days: Array<any> = []
        for (var x = 0; x < 7; x++) {
          days.push(firstDateOfWeek.format('YYYY-MM-DD'))
          firstDateOfWeek.add(1, 'days')
        }
        weeks.push({
          week: moment(firstDate1).get('week'),
          days,
          fromDate: moment(firstDate1).startOf('week'),
          toDate: moment(firstDate1).endOf('week'),
        })
      }
    }

    /*if (index > 0) {
        rows.push([''])
      }
      rows.push([dep.klassName])
      rows.push([''])*/
    weeks.forEach((week, index) => {
      if (index > 0) {
        rows.push([''])
      }
      /*rows.push([
        t('general.week') +
          ' ' +
          week.week +
          ' - ' +
          moment(week.fromDate).format('YYYY'),
      ])*/
      /*if (showSsn) {
        rows.push([
          t('statistics.kidName') +
            ';' +
            t('statistics.socialsecurityNr') +
            ';' +
            t('days.monday') +
            ';' +
            t('days.tuesday') +
            ';' +
            t('days.wednesday') +
            ';' +
            t('days.thursday') +
            ';' +
            t('days.friday') +
            ';' +
            t('general.total') +
            ';',
        ])
      } else {*/
      rows.push([
        t('general.week') +
          ';' +
          t('days.monday') +
          ';' +
          t('days.tuesday') +
          ';' +
          t('days.wednesday') +
          ';' +
          t('days.thursday') +
          ';' +
          t('days.friday') +
          ';' +
          t('general.total') +
          ';',
      ])
      //}

      /*statistics?.kidsList
        .filter((k: any) => {
          const kid = kids?.find((kid) => kid.id === k.kidId)
          return kid?.attributes?.departmentPointer?.id === dep.id
        })
        ?.filter((k: any) => {
          if (search) {
            return k?.kidName?.toLowerCase().includes(search.toLowerCase())
          } else {
            return k
          }
        })
        .sort((a: any, b: any) => {
          return a?.kidName.localeCompare(b?.kidName)
        })
        .forEach((kid: any) => {*/
      let totalTimeAttendingHours = 0
      let totalTimeAttendingMinutes = 0
      const kidWeek = generateDays(week)
      let row = ''
      row = row + week.week + ' - ' + moment(week.fromDate).format('YYYY')
      /*if (showSsn) {
        let kidSsn = ''
        if (kid.kidSsn) {
          kidSsn = '' + kid.kidSsn
        } else {
          kidSsn = '' //+ kid.kidSsn
        }

        row = row + ';' + kidSsn
      }*/
      kid &&
        kidWeek.days?.forEach((day: any) => {
          let log = statistics.logs?.find(
            (log: any) =>
              moment(log.checkedIn).format('YYYY-MM-DD') === day &&
              moment(log.checkedOut).format('YYYY-MM-DD') === day,
          )
          let checkedIn = statistics.logs?.find(
            (log: any) => moment(log.checkedIn).format('YYYY-MM-DD') === day,
          )?.checkedIn
          let checkedOut = statistics.logs?.find(
            (log: any) => moment(log.checkedOut).format('YYYY-MM-DD') === day,
          )?.checkedOut

          if (checkedIn && checkedOut) {
            var diffMs = (checkedOut as any) - (checkedIn as any)
            // TODO Should have better data type above.
            var diffHrs = Math.floor((diffMs % 86400000) / 3600000)
            var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000)
            totalTimeAttendingHours = totalTimeAttendingHours + diffHrs
            totalTimeAttendingMinutes = totalTimeAttendingMinutes + diffMins
          }

          let scheduleStart = statistics.kidSchedule?.find(
            (sched: any) => sched.date == day,
          )?.from
          let scheduleEnd = statistics.kidSchedule?.find(
            (sched: any) => sched.date == day,
          )?.to
          if (moment(day).day() === 6) {
            row =
              row +
              ';' +
              totalTimeAttendingHours +
              'h ' +
              totalTimeAttendingMinutes +
              'm'
          }
          if (moment(day).day() == 0 || moment(day).day() == 6) {
            //row = row + ';' + ''
            return
          }

          if (
            moment(day).format('YYYY-MM-DD') <
            moment(statisticsFromDate).format('YYYY-MM-DD')
          ) {
            row = row + ';' + ''
            return
          }

          if (
            moment(day).format('YYYY-MM-DD') >
            moment(statisticsToDate).format('YYYY-MM-DD')
          ) {
            row = row + ';' + ''
            return
          }

          if (checkedIn && checkedOut) {
            row =
              row +
              ';' +
              moment(checkedIn).format('HH:mm') +
              ' - ' +
              moment(checkedOut).format('HH:mm')
            if (
              scheduleStart &&
              scheduleEnd &&
              scheduleStart !== 'ledig' &&
              historicSchemaCsvnExport
            ) {
              row = row + ' ( ' + scheduleStart + ' - ' + scheduleEnd + ' )'
            }
            return
          }

          if (
            statistics.closedDates?.find(
              (cD: string) => cD === moment(day).format('YYYY-MM-DD'),
            )
          ) {
            row = row + ';' + t('statistics.closed')
            return
          }

          if (scheduleStart == 'ledig') {
            row = row + ';' + t('statistics.offHour')
            return
          }

          if (day >= moment().format('YYYY-MM-DD')) {
            if (scheduleStart && scheduleEnd) {
              row = row + ';' + scheduleStart + ' - ' + scheduleEnd
              return
            }
          }

          if (day < moment().format('YYYY-MM-DD')) {
            if (checkedIn && checkedOut) {
              row =
                row +
                ';' +
                moment(checkedIn).format('HH:mm') +
                ' - ' +
                moment(checkedOut).format('HH:mm')
              if (
                scheduleStart &&
                scheduleEnd &&
                scheduleStart !== 'ledig' &&
                historicSchemaCsvnExport
              ) {
                row = row + ' ( ' + scheduleStart + ' - ' + scheduleEnd + ' )'
              }
              return
            } else {
              row = row + ';' + t('absence.absence')
              if (
                scheduleStart &&
                scheduleEnd &&
                scheduleStart !== 'ledig' &&
                historicSchemaCsvnExport
              ) {
                row = row + ' ( ' + scheduleStart + ' - ' + scheduleEnd + ' )'
              }
              return
            }
          }

          row = row + ';' + ''
        })

      rows.push([row])
      //})
    })

    let csvContent = 'data:text/csv;charset=utf-8,'

    rows.forEach(function (rowArray: any) {
      let row = rowArray.join(';')
      csvContent += row + '\r\n'
    })
    var encodedUri = encodeURI(csvContent)
    var hiddenElement = document.createElement('a')
    hiddenElement.href = encodedUri
    hiddenElement.target = '_blank'
    hiddenElement.download =
      'weekly-export_' +
      statistics.kidName +
      '_' +
      moment(statisticsFromDate).format('YYYY-MM-DD') +
      '-' +
      moment(statisticsToDate).format('YYYY-MM-DD') +
      '.csv'
    hiddenElement.click()
    //window.open(encodedUri)
  }

  useEffect(() => {
    if (kidId) {
      setLoading(true)
      fetchKid(kidId).then(() => {})
    }
  }, [kidId])

  useEffect(() => {
    if (savedUserRoleId && kid && schoolId) {
      getKidStatistics(
        savedUserRoleId,
        //statisticsFromDate,
        //statisticsToDate,
        [],
        [kid],
        schoolId,
      ).finally(() => {
        setLoading(false)
      })
    }
  }, [savedUserRoleId, kid, schoolId])

  const onChangeFromDate = (date: Date) => {
    setStatisticsFromDate(date)
    getData(date, null)
  }

  const onChangeToDate = (date: Date) => {
    setStatisticsToDate(date)
    getData(null, date)
  }

  const getData = (
    fromDateSelected: Date | null = null,
    toDateSelected: Date | null = null,
  ) => {
    if (savedUserRoleId && kid && schoolId) {
      setLoading(true)
      if (fromDateSelected) {
        getKidStatistics(
          savedUserRoleId,
          //fromDateSelected,
          //toDate,
          [],
          [kid],
          schoolId,
        ).finally(() => {
          setLoading(false)
        })
      } else if (toDateSelected) {
        getKidStatistics(
          savedUserRoleId,
          //fromDate,
          //toDateSelected,
          [],
          [kid],
          schoolId,
        ).finally(() => {
          setLoading(false)
        })
      } else {
        getKidStatistics(
          savedUserRoleId,
          //fromDate,
          //toDate,
          [],
          [kid],
          schoolId,
        ).finally(() => {
          setLoading(false)
        })
      }
    }
  }
  const [focusRing1, setFocusRing1] = useState(false)
  const [focusRing2, setFocusRing2] = useState(false)
  {
    /* if (loading) {
    return <Loading />
  }*/
  }
  /*
  Total tid valda datum istället för genomsnitt per dag
  */
  return (
    <Page naked>
      {loading && <Loading />}
      <div className=" mb-24">
        {/*<Button
          loading={loading}
          type="submit"
          variant="primary"
          label={'Hämta data'}
          onClick={() => getData()}
          //disabled={isSubmitting}
  />*/}

        <ColorCard
          titlePicture={
            <div className="flex flex-row items-center gap-x-2">
              <img
                className={'w-[60px] h-[60px] rounded-full'}
                src={getImageOrPlaceHolder(kid?.attributes?.kidImage?._url)}
                alt=""
              />
              <p className={'font-semibold text-white text-lg'}>
                {kid?.attributes?.firstName} {kid?.attributes?.lastName}{' '}
                <br></br>
                {kid?.kid_pers_id_num}
              </p>
            </div>
          }
          bgColor="bg-eventsMain"
          actionButton={
            <div className="flex justify-center bg-white rounded-lg  w-fitt p-1 mt-2 sm:mr-10">
              <div className="flex justify-center items-center flex-col sm:flex-row gap-x-4">
                <div className="relative">
                  <DatePicker
                    className="border border-gray-200 hover:border-eventsMain text-xs sm:text-sm cursor-pointer  max-w-[100px] p-[1px] sm:p-2 rounded-lg text-center focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                    dateFormat="yyyy-MM-dd"
                    locale={i18n.language}
                    selected={statisticsFromDate as unknown as Date}
                    onChange={(date: Date) => {
                      onChangeFromDate(date)
                      setFocusRing1(false)
                    }}
                    onFocus={() => setFocusRing1(true)}
                    onBlur={() => {
                      setFocusRing1(false)
                    }}
                    filterDate={isMonday}
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                  />
                </div>
                <div className="text-gray-600 font-normal text-xs sm:text-sm">
                  {t('general.to')}
                </div>
                <div className="relative">
                  <DatePicker
                    className="border border-gray-200 hover:border-eventsMain text-xs sm:text-sm cursor-pointer  max-w-[100px] p-[1px] sm:p-2 rounded-lg text-center focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                    dateFormat="yyyy-MM-dd"
                    locale={i18n.language}
                    selected={statisticsToDate as unknown as Date}
                    onChange={(date: Date) => {
                      onChangeToDate(date)
                      setFocusRing2(false)
                    }}
                    onFocus={() => setFocusRing2(true)}
                    onBlur={() => {
                      setFocusRing2(false)
                    }}
                    filterDate={isSunday}
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                  />
                </div>
              </div>
            </div>
          }
        >
          <div className="flex flex-col w-full justify-center h-full p-6">
            <div>
              <div className="flex justify-end gap-x-4 mt-[-15px] mb-[20px] relative">
                <Button
                  size="xs"
                  variant="fullGray"
                  label={t('statistics.exportLandscape')}
                  onClick={() => generatePdf('l')}
                />
                <Button
                  size="xs"
                  variant="fullGray"
                  label={t('statistics.exportPortrait')}
                  onClick={() => generatePdf('p')}
                />

                {/* Day by day CSV*/}
                <Button
                  size="xs"
                  variant="fullGray"
                  label={t('contactList.exportCSV')}
                  onClick={() => {
                    generateCsv()
                  }}
                />
                <div className="absolute bottom-[-25px] right-0 text-xs font-normal justify-end">
                  <label className="flex flex-row gap-x-2 justify-end">
                    <>{t('statistics.includeHistoricSchemaCsvExport')}</>
                    <input
                      id="showSsn"
                      type="checkbox"
                      checked={historicSchemaCsvnExport}
                      //className="hidden-checkbox"
                      onChange={() =>
                        setHistoricSchemaCsvnExport(!historicSchemaCsvnExport)
                      }
                    />
                  </label>{' '}
                </div>
              </div>
              <div className="flex flex-row flex-wrap w-full cursor-default justify-center gap-x-6 gap-y-4 mb-5">
                <div className="min-w-[300px] max-w-[300px]">
                  <div className="px-1">
                    <h4 className="font-bold text-sm">{t('general.total')}</h4>
                    <div className="mt-[14px]"></div>
                  </div>
                  <PresenceScheduleDiffContainer
                    presence={statistics?.kidTimeAttendingTotal}
                    schema={statistics?.kidScheduleTotal}
                    diff={statistics?.kidTimeDifferenceTotal}
                    compact={false}
                    showHeader={false}
                  />
                </div>
                {!statisticsWeeksOnly ? (
                  <div className="min-w-[300px] max-w-[300px]">
                    <div className="flex flex-col gap-x-4 w-full px-1 justify-between items-left text-left">
                      <h4 className="font-bold text-sm">
                        {t('statistics.averagePerWeek')}
                      </h4>
                      <span className="text-2xs">
                        <b>
                          {' '}
                          <>{t('statistics.obs')}</>
                        </b>{' '}
                        <>{t('statistics.basedOnAveragePerWeek')}</>
                      </span>
                    </div>
                    <PresenceScheduleDiffContainer
                      presence={statistics?.kidTimeAttending}
                      schema={statistics?.kidScheduleHoursPerWeek}
                      diff={statistics?.kidTimeDifference}
                      compact={false}
                      showHeader={false}
                    />
                  </div>
                ) : (
                  <div className="min-w-[300px] max-w-[300px]">
                    <div className="flex flex-row gap-x-4 w-full px-1 justify-between items-end text-end">
                      <h4 className="font-bold text-sm">
                        {t('statistics.averagePerDay')}
                      </h4>
                    </div>
                    <div className="text-xl shadow border overflow-hidden  rounded-xl grid-cols-1 ">
                      <div className="w-full text-xs p-4 font-medium flex-row flex justify-between ">
                        <div className="flex flex-col">
                          <b>{t('general.presence')}:</b>
                          <div className="text-green-500">
                            {statistics?.kidTimeAttendingPerDay &&
                              statistics?.kidTimeAttendingPerDay.hours +
                                'h' +
                                ' ' +
                                statistics?.kidTimeAttendingPerDay.minutes +
                                'm'}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <b>{t('menu.scheme')}:</b>
                          <div className="text-orange-400">
                            {statistics?.kidSchedulePerDay &&
                              statistics?.kidSchedulePerDay.hours +
                                'h' +
                                ' ' +
                                statistics?.kidSchedulePerDay.minutes +
                                'm'}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <b>Diff:</b>{' '}
                          <div
                            className={
                              statistics?.kidTimeDifferencePerDay &&
                              statistics?.kidTimeDifferencePerDay.negative
                                ? 'text-red-500'
                                : 'text-green-600'
                            }
                          >
                            {statistics?.kidTimeDifferencePerDay &&
                              statistics?.kidTimeDifferencePerDay.hours +
                                'h' +
                                ' ' +
                                statistics?.kidTimeDifferencePerDay.minutes +
                                'm'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  <div className="px-1">
                    <h4 className="font-bold text-sm">
                      {t('statistics.tariff')}
                    </h4>
                  </div>
                  <div className="text-xl  shadow border rounded-xl grid-cols-1 mt-[14px]">
                    <div className="w-full text-center justify-center py-[7px] px-6 text-xl ">
                      <Singleselect
                        dropdownClassName="w-[295px] -right-[25px] rounded-full flex flex-col w-full border cursor-pointer"
                        removeSelected
                        hasBorder
                        onChange={(e: any) => {
                          /*let departments = selectedDepartments
                          departments.push(e)
                           setSelectedDepartments(departments)*/
                          //setFieldValue('type', e ? e.label : null)
                          if (kid) {
                            saveKidTariff(kid, Number(e.value))
                          }

                          if (kidId) {
                            fetchKid(kidId)
                          }
                        }}
                        items={tariffValues}
                        initialSelectedItem={
                          kid && kid.tariff
                            ? tariffValues.find(
                                (v) => Number(v.value) == Number(kid.tariff),
                              )
                            : {
                                value: null,
                                label: t('statistics.tariffNothingSelected'),
                              }
                        }
                        itemVariableKey={'label'}
                        placeholder={t('statistics.tariff')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-x-1 justify-end mb-3 text-xs font-medium">
              <h4 className="">{t('statistics.daysOff')}:</h4>
              <div className="flex text-center justify-center">
                {statistics?.kidScheduleHoursPerWeek && (
                  <div className="font-bold">
                    {statistics?.kidScheduleHoursPerWeek.totalFree}
                    {statistics?.kidScheduleHoursPerWeek.totalFreeNotBeen >
                      0 && (
                      <>
                        {' '}
                        <span className="text-orange-400  group relative w-max cursor-help">
                          (
                          {statistics?.kidScheduleHoursPerWeek.totalFreeNotBeen}
                          )
                          <span className="pointer-events-none absolute -top-10 left-0 w-max max-w-[200px] opacity-0 transition-opacity group-hover:opacity-100 border p-1 rounded-xl text-xs bg-gray-100 border-gray-200 text-gray-900">
                            {t('statistics.freeNotBeen')}
                          </span>
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <table className="table-auto w-full" id="my-table">
              <thead className="text-xs">
                <tr>
                  <th className="p-2 whitespace-nowrap w-[13%] text-left">
                    <span className="text-base font-bold">
                      {/*t('general.week')*/}

                      {exporting ? (
                        <>
                          {moment(statisticsFromDate).format('YYYY')}{' '}
                          {moment(statisticsFromDate).format('YYYY') !==
                          moment(statisticsToDate).format('YYYY') ? (
                            <> - {moment(statisticsToDate).format('YYYY')}</>
                          ) : null}
                        </>
                      ) : (
                        t('general.date')
                      )}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('days.monday')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('days.tuesday')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('days.wednesday')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('days.thursday')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('days.friday')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                  <th className="p-2 whitespace-nowrap w-[13%] text-center">
                    <span className="text-base font-bold">
                      {t('general.total')}
                    </span>
                    <br />
                    <span className="text-2xs">
                      <br />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                <ShowWeeks
                  kidStatistics={statistics}
                  firstDate={moment(statisticsFromDate).startOf('week')}
                  firstDateOfWeek={moment(statisticsFromDate).startOf('week')}
                  lastDate={moment(statisticsToDate).endOf('week')}
                  showWeekNumber={true}
                  fetch={() => {
                    if (savedUserRoleId && kid && schoolId) {
                      getKidStatistics(
                        savedUserRoleId,
                        //fromDate,
                        //toDate,
                        [],
                        [kid],
                        schoolId,
                      )
                    }
                  }}
                  fromDate={statisticsFromDate!}
                  toDate={statisticsToDate!}
                  exporting={exporting}
                />
                {/*showWeeks({
                          statistics,
                          moment(fromDate).startOf('week'),
                          moment(fromDate).startOf('week'),
                          moment(toDate).endOf('week'),
                        })*/}
                {/*statistics?.kidsList?.map((kid: any) => {
                          return (
                            <tr>
                              <td className="p-2 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                                    <img
                                      className="rounded-full"
                                      src={kid.kidImage}
                                      width="40"
                                      height="40"
                                      alt={''}
                                    />
                                  </div>
                                  <div className="font-medium text-gray-800">
                                    {kid.kidName}
                                  </div>
                                </div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div className="text-left">{kid.kidSsn}</div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div className="text-left font-medium text-gray-800">
                                  {kid.kidScheduleHoursPerWeek.hours +
                                    'h ' +
                                    kid.kidScheduleHoursPerWeek.minutes +
                                    'm'}
                                </div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div className="text-left font-medium text-gray-800">
                                  {kid.kidTimeAttending.hours +
                                    'h ' +
                                    kid.kidTimeAttending.minutes +
                                    'm'}
                                </div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div
                                  className={
                                    kid.kidTimeDifference.negative
                                      ? 'text-left font-medium text-gray-800'
                                      : 'text-left font-medium text-red-500'
                                  }
                                >
                                  {kid.kidTimeDifference.hours +
                                    'h ' +
                                    kid.kidTimeDifference.minutes +
                                    'm'}
                                </div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div className="text-left font-medium text-gray-800">
                                  {kid.kidScheduleHoursPerWeek.totalFree +
                                    ' dagar'}
                                </div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div className="text-lg text-center">
                                  {kid.tariff}
                                </div>
                              </td>
                            </tr>
                          )
                        })*/}
              </tbody>
            </table>
          </div>
        </ColorCard>
      </div>
    </Page>
  )
})
