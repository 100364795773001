import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from '../../../components/Button'
import ModalRegular from '../../../components/ModalRegular'
import { ActiveUsers, DocumentStoreContext } from '../../../store/documentStore'
import { UserStoreContext } from '../../../store/userStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { UserRole } from '../../../Models/UserRole'
import { User } from '../../../Models/User'
import {
  NotificationContext,
  NotificationVariant,
} from '../../../App/notification/notificationManger'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { Loading } from '../../../components/Loading'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'

const findRoles = (items: any, search: string) => {
  const text = search.split(' ')
  return items.filter((item: any) => {
    return text.every((el) => {
      return (
        item?.user?.attributes?.firstName.toLowerCase().includes(el) ||
        item?.user?.attributes?.lastName.toLowerCase().includes(el)
      )
    })
  })
}

const findKids = (items: any, search: string) => {
  const text = search.split(' ')
  return items.filter((item: any) => {
    return text.every((el) => {
      return (
        item?.kid.attributes?.firstName.toLowerCase().includes(el) ||
        item?.kid.attributes?.lastName.toLowerCase().includes(el)
      )
    })
  })
}

export const ShareDocumentModal: React.FC<{
  documentId: string
  onSaved: any
}> = observer(({ documentId, onSaved }) => {
  const { t } = useTranslation()
  const {
    getSchoolActiveUsers,
    schoolsActiveUsers,
    shareDocument,
    activeDocument,
    setActiveDocument,
    getDoc,
  } = useContext(DocumentStoreContext)
  const { notify } = useContext(NotificationContext)
  const [loading, setLoading] = useState(false)
  const { savedUserRoleId, currentUser } = useContext(UserStoreContext)
  const [showModal, setShowModal] = useState(false)
  const [personal, setPersonal] = useState(true)
  const [parent, setParent] = useState(true)
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [initialSelectedUsers, setInitialSelectedUsers] = useState<any>([])
  const [searchActiveUsers, setSearchActiveUsers] =
    useState<ActiveUsers | null>()
  const [clicked, setClicked] = useState(false)

  const search = (searchText: string) => {
    const roles = findRoles(schoolsActiveUsers?.userRoles, searchText)
    const kids = findKids(schoolsActiveUsers?.kids, searchText)
    if (searchText?.length > 0) {
      setSearchActiveUsers({ userRoles: roles, kids: kids })
    } else {
      setSearchActiveUsers(null)
    }
  }
  useEffect(() => {
    if (savedUserRoleId) {
      getSchoolActiveUsers(savedUserRoleId)
    }
  }, [savedUserRoleId])

  useEffect(() => {
    getDoc(documentId)

    return () => {
      setActiveDocument(null)
      setInitialSelectedUsers([])
      setSelectedUsers([])
      setClicked(false)
    }
  }, [documentId])

  useEffect(() => {
    if (activeDocument) {
      setInitialSelectedUsers(
        activeDocument?.attributes?.user_shared_with ?? [],
      )
    }
  }, [activeDocument])

  useEffect(() => {
    if (clicked) {
      setLoading(true)
      const selected = [...initialSelectedUsers, ...selectedUsers]
      const userIds = selected.map((u: any) => u.id)
      shareDocument(userIds, documentId).finally(() => {
        setLoading(false)
      })
    }
  }, [selectedUsers, clicked])

  const items = searchActiveUsers ? searchActiveUsers : schoolsActiveUsers

  return (
    <div className={'w-full'}>
      <Button
        size="md"
        className={'w-full'}
        label={t('documents.share')}
        onClick={() => {
          setShowModal(true)
        }}
      />

      <ModalRegular
        title={t('documents.share')}
        isOpen={showModal}
        className={'max-w-7xl '}
        overlayClassName={'opacity-0'}
        onClose={() => {
          setShowModal(false)
        }}
        bgColor="bg-documentsMain p-4"
        closeColor="white"
      >
        {loading && <Loading />}
        {activeDocument && (
          <div className="p-5">
            <div className={'mt-2 mb-4 flex flex-col gap-y-7'}>
              <div className="flex flex-wrap w-full justify-center items-center">
                <SearchInput
                  onChange={(s: string) => {
                    search(s)
                  }}
                />
              </div>
              <div className={'flex space-x-6'}>
                <div className={'pl-2 pb-4 flex items-center space-x-2'}>
                  <input
                    type={'checkbox'}
                    checked={personal}
                    onChange={(e) => {
                      setPersonal(!personal)
                    }}
                  />
                  <p className={'text-sm'}>{t('documents.allStaff')}</p>
                </div>
                <div className={'pl-2 pb-4 flex items-center space-x-2'}>
                  <input
                    type={'checkbox'}
                    checked={parent}
                    onChange={(e) => {
                      setParent(!parent)
                    }}
                  />
                  <p className={'text-sm'}>{t('documents.parent')}</p>
                </div>
              </div>
            </div>

            <div
              className={
                ' grid grid-cols-1 gap-2 h-[calc(100vh-250px)] overflow-auto '
              }
            >
              {initialSelectedUsers.length > 0 && (
                <p className="font-semibold text">
                  {t('documents.sharedWith')}
                </p>
              )}
              <div className="grid grid-cols-2 flex-wrap gap-2">
                {items?.userRoles
                  .filter((role) => {
                    /*if (parent && personal) {
                      return true
                    } else if (parent && !personal) {
                      return role?.attributes?.role_type === 350
                    } else if (!parent && personal) {
                      return role?.attributes?.role_type > 350
                    }*/
                    if (role.id === savedUserRoleId) {
                      return null
                    }
                    if (!personal) {
                      return null
                    }
                    return role?.attributes?.role_type > 350
                  })
                  .map((role, index) => {
                    let show = false
                    for (const user of [
                      ...initialSelectedUsers,
                      ...selectedUsers,
                    ]) {
                      if (user.id === role?.user?.id) {
                        show = true
                      }
                    }
                    if (!show) {
                      return null
                    }

                    return (
                      <UserRoleItem
                        key={index}
                        userRole={role}
                        selected={true}
                        onClick={(selected: boolean) => {
                          setClicked(true)
                          setInitialSelectedUsers(
                            initialSelectedUsers?.filter(
                              (u: any) => u?.id !== role?.user?.id,
                            ),
                          )
                          setSelectedUsers(
                            selectedUsers.filter(
                              (u: any) => u?.id !== role?.user?.id,
                            ),
                          )
                        }}
                      />
                    )
                  })}

                {items?.kids?.map((kid, index) => {
                  if (!parent) {
                    return null
                  }
                  let show = false
                  for (const user of [
                    ...initialSelectedUsers,
                    ...selectedUsers,
                  ]) {
                    for (const uR of kid.userRoles) {
                      if (
                        user.id === uR?.user?.id &&
                        currentUser?.id !== uR?.user?.id
                      ) {
                        show = true
                      }
                    }
                  }

                  /*if (
                    [...initialSelectedUsers, ...selectedUsers].find(
                      (user: any) =>
                        user.id ===
                        kid.userRoles.find((ur: UserRole) => ur?.user?.id)?.user
                          ?.id,
                    )
                  ) {*/
                  if (show) {
                    return (
                      <KidItem
                        key={index}
                        kid={kid}
                        selectedUsers={[
                          ...initialSelectedUsers,
                          ...selectedUsers,
                        ]}
                        kidClick={(user: User) => {
                          setClicked(true)
                          if (
                            [...initialSelectedUsers, ...selectedUsers].find(
                              (u: any) => u?.id === user?.id,
                            )
                          ) {
                            setInitialSelectedUsers(
                              initialSelectedUsers.filter(
                                (u: any) => u?.id !== user?.id,
                              ),
                            )
                            setSelectedUsers(
                              selectedUsers.filter(
                                (u: any) => u?.id !== user?.id,
                              ),
                            )
                          } else {
                            setSelectedUsers([...selectedUsers, user])
                          }
                        }}
                        onClick={() => {
                          setClicked(true)
                          const ids = kid?.userRoles?.map(
                            (u: any) => u?.user?.id,
                          )
                          if (
                            selectedUsers.filter((u: any) =>
                              ids.includes(u?.id),
                            )?.length > 0
                          ) {
                            setSelectedUsers(
                              selectedUsers.filter(
                                (u: any) => !ids.includes(u.id),
                              ),
                            )
                          } else {
                            const users = kid.userRoles?.map((u) => u.user)
                            setSelectedUsers([...selectedUsers, ...users])
                          }
                        }}
                      />
                    )
                  }
                })}
              </div>
              <p className="font-semibold text">
                {t('documents.notSharedWith')}
              </p>
              <div className="grid grid-cols-2 flex-wrap gap-2">
                {items?.userRoles
                  .filter((role) => {
                    /*if (parent && personal) {
                      return true
                    } else if (parent && !personal) {
                      return role?.attributes?.role_type === 350
                    } else if (!parent && personal) {
                      return role?.attributes?.role_type > 350
                    }*/
                    if (role.id === savedUserRoleId) {
                      return null
                    }
                    if (!personal) {
                      return null
                    }
                    return role?.attributes?.role_type > 350
                  })
                  .map((role, index) => {
                    let show = true
                    for (const user of [
                      ...initialSelectedUsers,
                      ...selectedUsers,
                    ]) {
                      if (user.id === role?.user?.id) {
                        show = false
                      }
                    }
                    if (!show) {
                      return null
                    }
                    return (
                      <div>
                        <UserRoleItem
                          key={index}
                          userRole={role}
                          selected={
                            !!selectedUsers?.find(
                              (u: any) => u?.id === role?.attributes?.user?.id,
                            )
                          }
                          onClick={(selected: boolean) => {
                            setClicked(true)
                            if (selected) {
                              setSelectedUsers(
                                selectedUsers.filter(
                                  (u: any) =>
                                    u?.id !== role?.attributes?.user.id,
                                ),
                              )
                            } else {
                              setSelectedUsers([
                                ...selectedUsers,
                                role?.attributes?.user,
                              ])
                            }
                          }}
                        />
                      </div>
                    )
                  })}

                {items?.kids?.map((kid, index) => {
                  if (!parent) {
                    return null
                  }
                  let show = true
                  for (const user of [
                    ...initialSelectedUsers,
                    ...selectedUsers,
                  ]) {
                    for (const uR of kid.userRoles) {
                      if (
                        user.id === uR?.user?.id &&
                        currentUser?.id !== uR?.user?.id
                      ) {
                        show = false
                      }
                    }
                  }
                  /*if (
                    [...initialSelectedUsers, ...selectedUsers].find(
                      (user: any) =>
                        user.id ===
                        kid.userRoles.find((ur: UserRole) => ur?.user?.id)?.user
                          ?.id,
                    )
                  ) {
                    return null
                  }*/
                  if (!show) {
                    return null
                  }
                  return (
                    <div>
                      <KidItem
                        key={index}
                        kid={kid}
                        selectedUsers={[
                          ...initialSelectedUsers,
                          ...selectedUsers,
                        ]}
                        kidClick={(user: User) => {
                          setClicked(true)
                          if (
                            selectedUsers.find((u: any) => u?.id === user?.id)
                          ) {
                            setSelectedUsers(
                              selectedUsers.filter(
                                (u: any) => u?.id !== user?.id,
                              ),
                            )
                          } else {
                            setSelectedUsers([...selectedUsers, user])
                          }
                        }}
                        onClick={() => {
                          setClicked(true)
                          const ids = kid?.userRoles?.map(
                            (u: any) => u?.user?.id,
                          )
                          if (
                            selectedUsers.filter((u: any) =>
                              ids.includes(u?.id),
                            )?.length > 0
                          ) {
                            setSelectedUsers(
                              selectedUsers.filter(
                                (u: any) => !ids.includes(u.id),
                              ),
                            )
                          } else {
                            const users = kid.userRoles?.map((u) => u.user)
                            setSelectedUsers([...selectedUsers, ...users])
                          }
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </div>

            {/*<div className={'flex w-full justify-end mt-4'}>
              <Button
                size="md"
                loading={loading}
                label={t('documents.save')}
                onClick={async () => {
                  setLoading(true)
                  const selected = [...initialSelectedUsers, ...selectedUsers]
                  const userIds = selected.map((u: any) => u.id)
                  await shareDocument(userIds, documentId).finally(() => {
                    setLoading(false)
                  })

                  
                  Swal.fire({
                    text: t('documents.sharedSuccessMessage'),
                    icon: 'success',
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                  })
                  setShowModal(false)
                  onSaved()
                }}
              />
            </div>*/}
          </div>
        )}
      </ModalRegular>
    </div>
  )
})

const SearchInput = ({ onChange }: { onChange: any }) => {
  const [text, setText] = useState('')
  const { t } = useTranslation()

  return (
    <input
      className={classNames(
        'block w-full px-6 py-4 placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
      )}
      type={'text'}
      value={text}
      placeholder={t('documents.search') + '...'}
      onChange={(e) => {
        setText(e.target.value)
        onChange(e.target.value.toLowerCase())
      }}
    />
  )
}

const KidItem = ({
  kid,
  onClick,
  kidClick,
  selectedUsers,
}: {
  kid: any
  onClick: any
  kidClick: any
  selectedUsers?: User[]
}) => {
  const allRolesSelected = () => {
    for (const role of kid?.userRoles) {
      if (!selectedUsers?.find((u) => u.id === role?.user?.id)) {
        return false
      }
    }
    return true
  }
  return (
    <div
      className={classNames(
        'h-[60px] flex border hover:bg-blue-50 cursor-pointer rounded-xl',
        allRolesSelected() && 'bg-green-50',
      )}
      onClick={() => {
        onClick()
      }}
    >
      <div className={'flex  items-center p-3 relative w-full'}>
        {allRolesSelected() && (
          <FontAwesomeIcon
            className={'text-eventsMain absolute top-2 left-2 '}
            icon={faCheckCircle}
          />
        )}

        {kid?.kid?.attributes?.kidImage?._url ? (
          <img
            className={'w-[30px] h-[30px] rounded-full'}
            src={kid?.kid?.attributes?.kidImage?._url}
            alt=""
          />
        ) : (
          <div
            className={
              'w-[30px] h-[30px] bg-gray-200 rounded-full flex justify-center items-center'
            }
          >
            <FontAwesomeIcon className={'text-gray-500 '} icon={faUser} />
          </div>
        )}
        <div className={'text-sm ml-3 flex-1'}>
          <p className={'font-medium whitespace-nowrap'}>
            {kid?.kid?.attributes?.firstName + ' '}
            {kid?.kid?.attributes?.lastName.charAt(0)}
          </p>
        </div>

        <div className={'w-[50%] flex overflow-auto space-x-4'}>
          {kid?.userRoles?.map((role: UserRole, index: number) => {
            const isSelected = () => {
              return selectedUsers?.find((u) => u.id === role?.user?.id)
            }
            return (
              <div
                key={index}
                className={
                  'flex flex-col justify-center items-center relative '
                }
                onClick={(e) => {
                  e.stopPropagation()
                  kidClick(role?.user)
                }}
              >
                <p className={'text-2xs'}>
                  {role?.user?.attributes?.firstName}
                </p>
                {isSelected() && (
                  <FontAwesomeIcon
                    size={'xs'}
                    className={'text-eventsMain absolute top-3 left-0 '}
                    icon={faCheckCircle}
                  />
                )}
                {role?.user?.attributes?.myPic_thumb?._url ? (
                  <div className={'w-[25px] h-[25px]'}>
                    <img
                      className={'w-full h-full rounded-full'}
                      src={role?.user?.attributes?.myPic_thumb?._url}
                    />
                  </div>
                ) : (
                  <div
                    className={
                      'w-[25px] h-[25px]  bg-gray-200 rounded-full flex justify-center items-center'
                    }
                  >
                    <FontAwesomeIcon
                      className={'text-gray-500 '}
                      icon={faUser}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const UserRoleItem = ({
  userRole,
  onClick,
  selected,
}: {
  userRole: UserRole
  onClick: any
  selected?: boolean
}) => {
  return (
    <div
      className={classNames(
        'h-[60px] w-full flex border hover:bg-blue-50 cursor-pointer rounded-xl',
        selected && 'bg-green-50',
      )}
      onClick={() => {
        onClick(selected)
      }}
    >
      <div className={'flex  items-center p-3 relative w-full'}>
        {selected && (
          <FontAwesomeIcon
            className={'text-eventsMain absolute top-2 left-2 '}
            icon={faCheckCircle}
          />
        )}

        {userRole?.user?.attributes?.myPic_thumb?._url ? (
          <img
            className={'w-[30px] h-[30px] rounded-full'}
            src={userRole?.user?.attributes?.myPic_thumb?._url}
            alt=""
          />
        ) : (
          <div
            className={
              'w-[30px] h-[30px] bg-gray-200 rounded-full flex justify-center items-center'
            }
          >
            <FontAwesomeIcon className={'text-gray-500 '} icon={faUser} />
          </div>
        )}
        <div className={'text-sm ml-3 flex-1'}>
          <p className={'font-medium whitespace-nowrap'}>
            {userRole?.user?.attributes?.firstName + ' '}
            {userRole?.user?.attributes?.lastName}
          </p>
          <p className={'text-gray-500 text-xs'}>
            {userRole.attributes?.relation}
          </p>
        </div>
      </div>
    </div>
  )
}

const SelectedUserItem = ({
  user,
  onClick,
  selected,
}: {
  user: User
  onClick: any
  selected?: boolean
}) => {
  return (
    <div
      className={classNames(
        'h-[60px] flex border hover:bg-blue-50 cursor-pointer',
        selected && 'bg-green-50',
      )}
      onClick={() => {
        onClick(selected)
      }}
    >
      <div className={'flex  items-center p-3 relative w-full'}>
        {selected && (
          <FontAwesomeIcon
            className={'text-eventsMain absolute top-2 left-2 '}
            icon={faCheckCircle}
          />
        )}

        {user?.attributes?.myPic_thumb?._url ? (
          <img
            className={'w-[30px] h-[30px] rounded-full'}
            src={user?.attributes?.myPic_thumb?._url}
            alt=""
          />
        ) : (
          <div
            className={
              'w-[30px] h-[30px] bg-gray-200 rounded-full flex justify-center items-center'
            }
          >
            <FontAwesomeIcon className={'text-gray-500 '} icon={faUser} />
          </div>
        )}
        <div className={'text-sm ml-3 flex-1'}>
          <p className={'font-medium whitespace-nowrap'}>
            {user?.attributes?.firstName + ' '}
            {user?.attributes?.lastName}
          </p>
        </div>
      </div>
    </div>
  )
}
