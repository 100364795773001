import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from '../../components/Button'
import ModalRegular from '../../components/ModalRegular'
import classNames from 'classnames'
import DatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
import { AbsenceStoreContext } from '../../store/absenceStore'
import { UserStoreContext } from '../../store/userStore'
import moment from 'moment'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import {
  NotificationContext,
  NotificationVariant,
} from '../../App/notification/notificationManger'
import Swal from 'sweetalert2'
registerLocale('sv', sv)
registerLocale('en', en)
registerLocale('es', es)

export const AbsenceModal: React.FC<{
  initialAbsence?: any
  onClose: any
  kidId?: string
  showModal: boolean
}> = observer(({ initialAbsence, onClose, kidId, showModal }) => {
  const { t, i18n } = useTranslation()
  const { addAbsence, editAbsence, fetchAbsences, addHistoricAbsence } =
    useContext(AbsenceStoreContext)
  const { currentUserRole, readOnly } = useContext(UserStoreContext)
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [info, setInfo] = useState<string>()
  const { notify } = useContext(NotificationContext)
  const [focusRing1, setFocusRing1] = useState(false)
  const [focusRing2, setFocusRing2] = useState(false)

  const isAdmin = currentUserRole?.attributes?.role_type >= USER_ROLE_TYPE.ADMIN
  useEffect(() => {
    setInfo(initialAbsence?.attributes?.info)
    if (initialAbsence?.attributes?.startDate) {
      setStartDate(new Date(initialAbsence?.attributes?.startDate))
    }
    if (initialAbsence?.attributes?.endDate) {
      setEndDate(new Date(initialAbsence?.attributes?.endDate))
    }
  }, [initialAbsence])

  return (
    <div>
      <ModalRegular
        title={''}
        className={'max-w-xl pb-6 bg-gray-50 overflow-visible'}
        isOpen={showModal}
        onClose={onClose}
      >
        <div className={'m-10 '}>
          <div className="w-full text-center text-lg font-bold mb-4">
            {initialAbsence ? (
              <>{t('absence.editAbsence')}</>
            ) : (
              <>{t('absence.newRegistration')}</>
            )}
          </div>
          <div className={'flex space-x-6 mb-6 items-center'}>
            <div className="relative h-full w-full flex justify-center gap-x-2 items-center">
              <div className="relative">
                <div
                  className={`absolute top-[-8px] left-[28px] rounded-xl bg-white z-[100] border-t border-gray-200 px-3 text-sm font-bold ${
                    focusRing1 ? 'focusRing1' : ''
                  }`}
                >
                  {t('general.fromDate')}
                </div>
                <DatePicker
                  //placeholderText={t('general.fromDate')}
                  className="border border-gray-200 p-2 px-9 rounded-lg text-center w-full focus:ring-eventsMain focus:border-eventsMain focus:outline-none"
                  dateFormat="yyyy-MM-dd"
                  locale={i18n.language}
                  selected={startDate as unknown as Date}
                  //minDate={!isAdmin ? moment().toDate() : undefined}
                  onChange={(date: Date) => {
                    setFocusRing1(false)
                    if (endDate && endDate < date) {
                      setStartDate(date)
                      setEndDate(date)
                    } else {
                      setStartDate(date)
                    }
                  }}
                  onFocus={() => setFocusRing1(true)}
                  onBlur={() => {
                    setFocusRing1(false)
                  }}
                />
              </div>
              <div className="relative">
                <div
                  className={`absolute top-[-8px] left-[25px] bg-white z-[100] px-3 text-sm border-t border-gray-200 font-bold rounded-xl ${
                    focusRing2 ? 'focusRing2' : ''
                  }`}
                >
                  {t('general.toDate')}
                </div>
                <DatePicker
                  //placeholderText={t('general.toDate')}
                  className="border border-gray-200 p-2 px-9 rounded-lg text-center w-full focus:ring-eventsMain focus:border-eventsMain focus:outline-none"
                  dateFormat="yyyy-MM-dd"
                  locale={i18n.language}
                  selected={endDate as unknown as Date}
                  minDate={startDate as unknown as Date}
                  onChange={(date: Date) => {
                    setFocusRing2(false)
                    setEndDate(date)
                  }}
                  onFocus={() => setFocusRing2(true)}
                  onBlur={() => {
                    setFocusRing2(false)
                  }}
                />
              </div>
            </div>
          </div>
          {!readOnly?.absence ? (
            <textarea
              className={classNames(
                'block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-xl shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
              )}
              placeholder={t('absence.inputPlaceholder')}
              rows={8}
              value={info}
              disabled={readOnly?.absence}
              onChange={(e) => {
                setInfo(e.target.value)
              }}
            />
          ) : (
            <textarea
              className={classNames(
                'block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-xl shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
              )}
              placeholder={t('general.noPermission')}
              rows={8}
              //value={info}
              disabled={readOnly?.absence}
              onChange={(e) => {
                //setInfo(e.target.value)
              }}
            />
          )}
          <div className={'flex justify-end mt-6'}>
            <Button
              size="md"
              loading={loading}
              label={initialAbsence ? t('general.update') : t('general.save')}
              onClick={() => {
                if (startDate && endDate) {
                  setLoading(true)

                  // Need to do this because parse.js on server is rewrites to utc 0 time.
                  // And if we dont the dates will be wrong on the server
                  const start = new Date(
                    `${startDate.getUTCFullYear()}-${(
                      '0' +
                      (startDate.getMonth() + 1)
                    ).slice(-2)}-${('0' + startDate.getDate()).slice(
                      -2,
                    )}T00:00:00.000Z`,
                  )

                  const end = new Date(
                    `${endDate.getUTCFullYear()}-${(
                      '0' +
                      (endDate.getMonth() + 1)
                    ).slice(-2)}-${('0' + endDate.getDate()).slice(
                      -2,
                    )}T00:00:00.000Z`,
                  )

                  if (
                    moment(startDate).format('YYYY-MM-DD') <
                      moment().format('YYYY-MM-DD') &&
                    moment(endDate).format('YYYY-MM-DD') <
                      moment().format('YYYY-MM-DD')
                  ) {
                    /*notify({
                      variant: NotificationVariant.ERROR,
                      title: '',
                      message: t('absence.alarmCreateAbsenceTime'),
                    })*/
                    Swal.fire({
                      text: t('absence.alarmCreateAbsenceTime'),
                      icon: 'error',
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                    })
                    setLoading(false)
                    return false
                  }

                  if (initialAbsence) {
                    editAbsence({
                      info: info,
                      startDate: start,
                      endDate: end,
                      absenceId: initialAbsence?.id,
                    })
                      .then(() => {
                        fetchAbsences(
                          currentUserRole?.attributes?.school_pointer?.id,
                          kidId,
                        )
                        onClose()
                      })
                      .catch((error) => {
                        if (error.message) {
                          /*notify({
                            variant: NotificationVariant.ERROR,
                            title: '',
                            message:
                              error.message.message == 'Span contains logs'
                                ? t(
                                    'absence.absence_error_checked_in_is_in_range',
                                  )
                                : error.message.message,
                          })*/
                          Swal.fire({
                            text:
                              error.message.message == 'Span contains logs'
                                ? t(
                                    'absence.absence_error_checked_in_is_in_range',
                                  )
                                : error.message.message,
                            icon: 'error',
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                          })
                        }
                      })
                      .finally(() => {
                        setLoading(false)
                      })
                  } else {
                    addAbsence({
                      info: info,
                      startDate: start,
                      endDate: end,
                      schoolId: currentUserRole?.attributes?.school_pointer?.id,
                      kidId: kidId
                        ? kidId
                        : currentUserRole?.attributes?.kid?.id,
                    })
                      .then(() => {
                        // Show success alert
                        Swal.fire({
                          text: t('absence.absenceCreate'),
                          icon: 'success',
                          showCancelButton: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                          cancelButtonText: t('general.cancel'),
                        }).then(() => {
                          fetchAbsences(
                            currentUserRole?.attributes?.school_pointer?.id,
                            kidId,
                          )
                          onClose()
                        })
                      })
                      .catch((error) => {
                        if (error.message) {
                          /* notify({
                            variant: NotificationVariant.ERROR,
                            title: '',
                            message:
                              error.message.message == 'Span contains logs'
                                ? t(
                                    'absence.absence_error_checked_in_is_in_range',
                                  )
                                : error.message.message,
                          })*/
                          Swal.fire({
                            text:
                              error.message.message == 'Span contains logs'
                                ? t(
                                    'absence.absence_error_checked_in_is_in_range',
                                  )
                                : error.message.message,
                            icon: 'error',
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                          })
                        }
                      })
                      .finally(() => {
                        setLoading(false)
                      })
                  }
                }
              }}
            />
          </div>
        </div>
      </ModalRegular>
    </div>
  )
})

export const ReRegistration: React.FC<{
  initialAbsence?: any
  onClose: any
  kidId?: string
  showModal: boolean
}> = observer(({ initialAbsence, onClose, kidId, showModal }) => {
  const tomorrow = new Date()
  const { t } = useTranslation()
  tomorrow.setDate(tomorrow.getDate() + 1)
  const [endDate, setEndDate] = useState<Date>(tomorrow)
  const { currentUserRole } = useContext(UserStoreContext)
  const { editAbsence, fetchAbsences, confirmAbsence } =
    useContext(AbsenceStoreContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!endDate) {
      const d = initialAbsence?.attributes?.endDate.setDate(
        initialAbsence?.attributes?.endDate.getDate() + 1,
      )
      setEndDate(d)
    }
  }, [initialAbsence])

  const isAdmin = currentUserRole?.attributes?.role_type >= USER_ROLE_TYPE.ADMIN
  const isTeacher =
    currentUserRole?.attributes?.role_type > USER_ROLE_TYPE.PARENT
  return (
    <div>
      <ModalRegular
        title={''}
        className={'max-w-xl pb-6 !overflow-visible'}
        isOpen={showModal}
        onClose={onClose}
      >
        <div className={'pt-10 flex flex-col items-center justify-center'}>
          <p className={'text-gray-500 text-2xl mb-6 px-5 text-center'}>
            {initialAbsence?.attributes?.confirmed ? (
              <>{t('absence.unlockPopUpHeader')}</>
            ) : (
              <>{t('absence.lockPopUpHeader')}</>
            )}
          </p>
          <div className="relative h-auto w-auto flex-1 flex justify-between gap-x-2">
            <p className="text-gray-900 text-center p-5">
              {initialAbsence?.attributes?.confirmed ? (
                <>{t('absence.goodToKnowPushUnlockStaff')}</>
              ) : (
                <>
                  <div>{t('absence.goodToKnow')}</div>
                  {isTeacher
                    ? t('absence.goodToKnowPushLockStaff')
                    : t('absence.goodToKnowPushLockParent')}
                </>
              )}
            </p>
            {/*<div className="absolute top-[-5px] left-7 bg-white z-[100] px-3 text-sm font-bold">
              {t('general.fromDate')}
            </div>
            <DatePicker
              className="border border-gray-200 p-10 rounded-lg text-center w-full "
              dateFormat="MMMM d, yyyy"
              selected={endDate as unknown as Date}
              minDate={!isAdmin ? moment().toDate() : undefined}
              onChange={(date: Date) => setEndDate(date)}
      />*/}
          </div>
          <div className={'pb-[30px]'}></div>
          <Button
            //label={t('absence.reRegistration')}
            label={
              initialAbsence?.attributes?.confirmed
                ? t('absence.cancelReport')
                : t('absence.confirmDate')
            }
            loading={loading}
            variant={initialAbsence?.attributes?.confirmed ? 'red' : 'green'}
            onClick={async () => {
              confirmAbsence({
                absenceId: initialAbsence?.id,
                confirmed: initialAbsence?.attributes.confirmed ? false : true,
              })
                .then(() => {
                  fetchAbsences(
                    currentUserRole?.attributes?.school_pointer?.id,
                    kidId,
                  )
                  onClose()
                })
                .finally(() => {
                  setLoading(false)
                })
            }}
          ></Button>
        </div>
      </ModalRegular>{' '}
    </div>
  )
})
