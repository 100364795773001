import { motion } from 'framer-motion'
import classNames from 'classnames'

export const LoadingBar: React.FC<{
  label?: string
  className?: string
  speed?: number
}> = ({ label, className, speed = 5 }) => {
  return (
    <div className={classNames('flex flex-col', className)}>
      <motion.div
        initial={{ opacity: 0, width: '0%' }}
        animate={{ opacity: 1, width: `100%` }}
        transition={{ width: { duration: speed, repeat: Infinity } }}
        className="h-2 bg-blue-200 rounded-full"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 4, duration: 2, repeat: Infinity }}
        className="mt-1 text-xs text-gray-500 capitalize"
      >
        {label}
      </motion.div>
    </div>
  )
}
