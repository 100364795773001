import Parse from 'parse'
import { User } from './User'

export class ActionStates extends Parse.Object {
  constructor() {
    super('action_states')
  }

  get user_owner(): User {
    return this.get('user_owner')
  }
  set user_owner(value: User) {
    this.set('user_owner', value)
  }
}
Parse.Object.registerSubclass('action_states', ActionStates)
