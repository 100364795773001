import {
  faLeft,
  faLeftFromLine,
  faRight,
  faRightFromLine,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import moment, { Moment } from 'moment'
import { useState } from 'react'
import { getDateForTranslatation } from '../views/events/Events'
//import Moment from 'react-moment'
import { AbsenceCalendarCell } from './AbsenceCalendarCell'

interface Props {
  value?: Moment
  lastMonthDate?: Moment
  selected?: Moment
  onChange: (value: Moment) => void
  changeLastMonthDate: (value: Moment) => void
  absences: any
}

export const AbsenceCalendar: React.FC<Props> = ({
  value = moment(),
  lastMonthDate = moment().subtract(1, 'months'),
  selected,
  onChange,
  changeLastMonthDate,
  absences,
}) => {
  const firstDate = moment(value).startOf('month')
  const lastDate = moment(value).endOf('month')
  const daysOfMonth = moment(value).daysInMonth()
  const [countAbsenceDaysThisMonth, setCountAbsenceDaysThisMonth] =
    useState<number>(0)
  //const [firstDate, setFirstDate] = useState(moment().startOf('month'))
  //const [lastDate, setLastDate] = useState(moment().endOf('month'))
  //const [daysOfMonth, setDaysOfMonth] = useState(moment().daysInMonth())

  const prefixDays =
    moment(firstDate).day() == 0 ? 6 : moment(firstDate).day() - 1
  const suffixDays =
    moment(lastDate).day() == 0 ? 0 : 7 - moment(lastDate).day()
  //const [prefixDays, setPrefixDays] = useState(firstDate.day())
  //const [suffixDays, setSuffixDays] = useState(6 - lastDate.day())
  const daysOfWeek = [
    t('days.monday'),
    t('days.tuesday'),
    t('days.wednesday'),
    t('days.thursday'),
    t('days.friday'),
    t('days.saturday'),
    t('days.sunday'),
  ]

  const absenceInCell = (cellDate: string) => {
    return absences?.find(
      (ab: any) =>
        cellDate >= ab.attributes.startDate &&
        cellDate <= ab.attributes.endDate,
    )
  }
  const showDays = () => {
    const div = []

    for (var i = 0; i < daysOfMonth; i++) {
      //let red = false
      const cellDate = moment(
        moment(value).format('YYYY') +
          '-' +
          moment(value).format('MM') +
          '-' +
          (i + 1).toString(),
      ).format('YYYY-MM-DD')

      const absence = absenceInCell(cellDate)

      div.push(
        <AbsenceCalendarCell absence={absence} key={i}>
          <>{i + 1}</>
        </AbsenceCalendarCell>,
      )
    }

    return div
  }
  const amountOfAbsenceDaysCurrentMonth = () => {
    let count = 0

    for (var i = 0; i < daysOfMonth; i++) {
      //let red = false
      const cellDate = moment(
        moment(value).format('YYYY') +
          '-' +
          moment(value).format('MM') +
          '-' +
          (i + 1).toString(),
      ).format('YYYY-MM-DD')

      const absence = absenceInCell(cellDate)
      if (absence) {
        count = count + 1
      }
    }
    return count
  }

  const showPrefixDays = () => {
    const div = []
    for (var i = 0; i < lastMonthDate.daysInMonth(); i++) {
      if (i > lastMonthDate.daysInMonth() - (prefixDays + 1)) {
        const cellDate = moment(
          moment(lastMonthDate).format('YYYY') +
            '-' +
            moment(lastMonthDate).format('MM') +
            '-' +
            (i + 1).toString(),
        ).format('YYYY-MM-DD')

        const absence = absenceInCell(cellDate)
        div.push(
          <AbsenceCalendarCell
            className=" bg-slate-100"
            opacity
            absence={absence}
            key={i}
          >
            {i + 1}
          </AbsenceCalendarCell>,
        )
      }
    }
    return div
  }
  const showSuffixDays = () => {
    const div = []
    for (var i = 0; i < suffixDays; i++) {
      div.push(
        <AbsenceCalendarCell className="bg-slate-100 " opacity key={i}>
          {i + 1}
        </AbsenceCalendarCell>,
      )
    }
    return div
  }

  const nextMonth = () => {
    onChange(moment(value).add(1, 'months'))
    changeLastMonthDate(moment(lastMonthDate).add(1, 'months'))
  }

  const prevMonth = () => {
    onChange(moment(value).subtract(1, 'months'))
    changeLastMonthDate(moment(lastMonthDate).subtract(1, 'months'))
  }

  const nextYear = () => {
    onChange(moment(value).add(1, 'years'))
    changeLastMonthDate(moment(lastMonthDate).add(1, 'years'))
  }

  const prevYear = () => {
    onChange(moment(value).subtract(1, 'years'))
    changeLastMonthDate(moment(lastMonthDate).subtract(1, 'years'))
  }

  return (
    <>
      <div className="flex flex-row justify-start text-xl text-gray-800 mt-12 font-medium ml-5">
        <>{t('absence.absenceThisMonth')}</>
        <div className="text-absenceMain font-bold   ml-2">
          {amountOfAbsenceDaysCurrentMonth()} <>{t('absence.absenceDays')}</>
        </div>
      </div>

      <div className="flex flex-wrap mb-2">
        <div className="w-[400px] h-[500px] flex-1 mt-12 sm:m-6 font-medium ">
          <div className="grid grid-cols-7 ">
            <AbsenceCalendarCell
              onClick={prevYear}
              className=" text-blue-500  cursor-pointer font-bold shadow-sm bg-blue-50 hover:scale-105 hover:shadow-md transition ease-in  hover:bg-blue-100 rounded-tl-2xl "
            >
              <FontAwesomeIcon
                className="hover:scale-125"
                icon={faLeftFromLine}
              />
            </AbsenceCalendarCell>
            <AbsenceCalendarCell
              onClick={prevMonth}
              className=" text-blue-500  cursor-pointer font-bold shadow-sm bg-blue-50 hover:scale-105 hover:shadow-md transition ease-in  hover:bg-blue-100 rounded-tr-2xl "
            >
              <FontAwesomeIcon className="hover:scale-125" icon={faLeft} />
            </AbsenceCalendarCell>
            <AbsenceCalendarCell className="col-span-3 font-bold text-2xl border-none sm:text-3xl capitalize text-gray-800">
              {getDateForTranslatation(firstDate.format('MM'))}{' '}
              {firstDate.format('YYYY')}
            </AbsenceCalendarCell>
            <AbsenceCalendarCell
              onClick={nextMonth}
              className=" text-blue-500  cursor-pointer font-bold shadow-sm bg-blue-50 hover:scale-105 hover:shadow-md transition ease-in  hover:bg-blue-100 rounded-tl-2xl  "
            >
              <FontAwesomeIcon className="hover:scale-125" icon={faRight} />
            </AbsenceCalendarCell>

            <AbsenceCalendarCell
              onClick={nextYear}
              className=" text-blue-500  cursor-pointer font-bold shadow-sm bg-blue-50 hover:scale-105 hover:shadow-md transition ease-in hover:bg-blue-100 rounded-tr-2xl "
            >
              <FontAwesomeIcon
                className="hover:scale-125"
                icon={faRightFromLine}
              />
            </AbsenceCalendarCell>

            {daysOfWeek.map((day) => (
              <AbsenceCalendarCell className=" text-gray-800 font-bold border-r  bg-blue-50">
                {day}
              </AbsenceCalendarCell>
            ))}
            {showPrefixDays()}
            {showDays()}
            {showSuffixDays()}
          </div>
        </div>
      </div>
    </>
  )
}
