import { faMapPin, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components/Button'
import { LocationInterface } from '../../../Models/Location'
import { faLocationPlus } from '@fortawesome/pro-solid-svg-icons'
import Swal from 'sweetalert2'
import { EventTimeSmallInterface } from '../../../store/event3Store'
import moment from 'moment'
import classNames from 'classnames'
import {
  getDayForTranslatation,
  getDaysFullForTranslatation,
} from '../../events/components/EventsInfo'

interface FreeTimesCardProps {
  times: EventTimeSmallInterface[]
  onClick: (time: EventTimeSmallInterface | null) => void
  selectedTime?: EventTimeSmallInterface | null
}

export const FreeTimesCard: React.FC<FreeTimesCardProps> = ({
  times,
  onClick,
  selectedTime,
}) => {
  const { t } = useTranslation()
  let datesWrittenArray: string[] = []

  return (
    <div className="flex flex-wrap gap-4 mt-4">
      {times
        .filter((time) => !time.booked_by_kid_id)
        .slice()
        .sort((a, b) => {
          // Ensure both dates are valid before comparison
          const dateA = new Date(a.start_date!)
          const dateB = new Date(b.start_date!)

          // First, compare by start_date
          if (dateA.getTime() !== dateB.getTime()) {
            return dateA.getTime() - dateB.getTime() // Sort by date first
          }

          // If the dates are the same, compare by start_time
          const timeA = a.start_time
            ? a.start_time.split(':').map(Number)
            : [0, 0]
          const timeB = b.start_time
            ? b.start_time.split(':').map(Number)
            : [0, 0]

          // Compare hours first, then minutes
          if (timeA[0] !== timeB[0]) {
            return timeA[0] - timeB[0] // Compare hours
          }

          return timeA[1] - timeB[1] // Compare minutes
        })
        .map((time, index) => {
          const selected = time === selectedTime
          const selectedColor = 'bg-purple-400'
          let newDate = false
          if (
            !datesWrittenArray.find(
              (date) => date === moment(time.start_date).format('YYYY-MM-DD'),
            )
          ) {
            newDate = true
            datesWrittenArray.push(moment(time.start_date).format('YYYY-MM-DD'))
          }

          return (
            <>
              {newDate && (
                <div className="w-full font-bold text-sm">
                  {getDaysFullForTranslatation(moment(time.start_date).day())}{' '}
                  {moment(time.start_date).format('DD/MM')}
                </div>
              )}

              <div
                className={classNames(
                  'shadow-md rounded-full flex flex-row p-2 text-xs cursor-pointer transition duration-500 font-semibold',
                  selected ? `${selectedColor} text-white` : '',
                )}
                onClick={() => {
                  if (time === selectedTime) {
                    onClick(null)
                  } else {
                    onClick(time)
                  }
                }}
              >
                {time.start_time} - {time.end_time}
              </div>
            </>
          )
        })}
    </div>
  )
}
