import { IconDefinition, icon } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useContext, useState } from 'react'
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight, faPlay } from '@fortawesome/pro-solid-svg-icons'
import { LoadingBar } from '../../../components/LoadingBar'
import { UserStoreContext } from '../../../store/userStore'

interface Props {
  onClick?: (link: string) => void
  tags?: string[]
  role?: string
  content?: string
  isFixedSize?: boolean
  link?: string
}

export default function DocAndLathundCard(props: Props) {
  const handleClick = () => {
    if (props.onClick && props.link) {
      props.onClick(props.link)
    }
  }

  return (
    <div
      className={classNames(
        'border shadow rounded-2xl flex hover:shadow-lg cursor-pointer relative hover:scale-[100%] min-h-[150px] max-h-[150px] min-w-[300px] transition ',
        {
          'max-w-[300px] min-w-[300px] sm:max-w-[400px] sm:min-w-[400px]':
            !props.isFixedSize,
        },
      )}
      onClick={handleClick}
    >
      <div className="flex flex-col p-4 pl-10 flex-wrap justify-center relative">
        <div className="flex flex-row w-full overflow-auto gap-x-2 mb-3">
          {props.tags?.slice(0, 3).map((tag, index) => (
            <div
              key={index}
              className="w-fit bg-white
          rounded-full py-1 px-3 border-blue-400 border text-sm font-semibold items-center  flex max-h-[40px] flex-row text-eventsMain"
            >
              {tag}
            </div>
          ))}

          {props.tags && props.tags.length > 3 && (
            <div
              title={props.tags.slice(3).join(', ')}
              className="tag-more-style"
            >
              +{props.tags.length - 3}
            </div>
          )}
        </div>
        <div>
          <p>{props.role}</p>
          <p className="text-sm font-normal line-clamp-2  text-gray-500 max-w-[200px] sm:max-w-[300px]">
            {props.content}
          </p>
        </div>
      </div>
      <div className="absolute top-16 right-3">
        {props.isFixedSize ? (
          <div className="flex flex-col justify-center mr-5">
            <FontAwesomeIcon icon={faChevronRight} className="text-gray-400" />
          </div>
        ) : (
          <div className="flex flex-col justify-center ml-20">
            <FontAwesomeIcon icon={faChevronRight} className="text-gray-400" />
          </div>
        )}
      </div>
    </div>
  )
}
