// AudioModal.tsx
import React from 'react'
import ModalRegular from './ModalRegular'
import AudioPlayer from './Audioplayer'
import { t } from 'i18next'

type AudioModalProps = {
  src: any
  showModal: boolean
  hideModal: () => void
}

const AudioModal: React.FC<AudioModalProps> = ({
  src,
  showModal,
  hideModal,
}) => {
  return (
    <ModalRegular
      title={t('blog.playAudioFile')}
      isOpen={showModal}
      onClose={hideModal}
      bgColor={'p-4 bg-eventsMain'}
      closeColor="white"
    >
      <div className={'w-full h-full p-10'}>
        <audio
          src={src}
          controls
          className={'w-full rounded-2xl shadow'}
        ></audio>{' '}
      </div>
    </ModalRegular>
  )
}

export default AudioModal
