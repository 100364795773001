import Parse from 'parse'
import { SKA_VISIBILITY } from '../Enums/TyraEnums'
import { School } from './School'

export class SkaOperationsPlanning extends Parse.Object {
  public static readonly Fields = {
    school: 'school_pointer',
    visibility: 'visibility',
  }
  constructor() {
    super('ska_operations_planning')
  }

  get name(): string {
    return this.get('name')
  }

  get school_pointer(): School {
    return this.get('school_pointer')
  }
  get visibility(): SKA_VISIBILITY {
    return this.get('visibility')
  }
}
Parse.Object.registerSubclass('ska_operations_planning', SkaOperationsPlanning)
