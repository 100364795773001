import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Page from '../../../components/Page'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

export const HelpScection: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Page>
      <div className={`flex flex-col h-full`}>
        <div
          id="event-header"
          className={classNames(
            'sticky top-0 w-full p-2 text-white flex justify-between items-center bg-[#7eb5f4] ',
          )}
        >
          <div className="flex justify-center items-center w-full text-xl font-bold h-12">
            Help Section
          </div>
          <div className="flex items-center pr-3">
            <FontAwesomeIcon
              icon={faXmark}
              size="2x"
              className="text-gray-100 cursor-pointer"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
        <div>
          <p className=" text-xl font-bold m-1">{t('events.explanation')}</p>
          <div id="Add" className="text-lg m-1 whitespace-pre-wrap mb-1">
            <p className="text-xl font-bold text-blue-400">{t('events.add')}</p>
            <p className="font-semibold">{t('events.addExplanation')}</p>
          </div>
          <div
            id="Set as present"
            className="text-lg m-1 whitespace-pre-wrap mb-1"
          >
            <p className="text-xl font-bold text-green-500">
              {t('events.setAsPresent')}
            </p>
            <p className="font-semibold">
              {t('events.setAsPresentExplanation')}
            </p>
          </div>
          <div
            id="Leaving activity"
            className="text-lg m-1 whitespace-pre-wrap mb-1"
          >
            <p className="text-xl font-bold text-orange-400">
              {t('events.leavingActivity')}
            </p>
            <p className="font-semibold">
              {t('events.leavingActivityExplanation')}
            </p>
          </div>
          <div id="Count" className="text-lg m-1 whitespace-pre-wrap mb-1">
            <p className="text-xl font-bold text-purple-500">
              {t('events.count')}
            </p>
            <p className="font-semibold">{t('events.countExplanation')}</p>
          </div>
          <div
            id="Remove participants"
            className="text-lg m-1 whitespace-pre-wrap mb-1"
          >
            <p className="text-xl font-bold text-red-500">
              {t('events.removeParticipants')}
            </p>
            <p className="font-semibold">
              {t('events.removeParticipantsExplanation')}
            </p>
          </div>
          <div
            id="A practical example"
            className="text-lg m-1 whitespace-pre-wrap mb-1"
          >
            <p className="text-xl font-bold">{t('events.practicalExample')}</p>
            <ul className="list-disc list-inside font-semibold">
              <li>{t('events.practicalExample1stLI')}</li>
              <li>{t('events.practicalExample2ndLI')}</li>
              <li>{t('events.practicalExample3rdLI')}</li>
              <li>{t('events.practicalExample4thLI')}</li>
              <li>{t('events.practicalExample5thLI')}</li>
              <li>{t('events.practicalExample6thLI')}</li>
              <li>{t('events.practicalExample7thLI')}</li>
              <li>{t('events.practicalExample8thLI')}</li>
              <li>{t('events.practicalExample9thLI')}</li>
            </ul>
          </div>
        </div>
      </div>
    </Page>
  )
}
