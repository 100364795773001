import Parse from 'parse'
import { SKA_VISIBILITY } from '../Enums/TyraEnums'
import { School } from './School'
import { SkaFile } from './SkaFile'

export class SkaCard extends Parse.Object {
  public static readonly Fields = {
    // school: "school_pointer", // Removed since it is a replication of school_pointer below.
    school_pointer: 'school_pointer',
    relation_type: 'relation_type',
    kid_owner_id: 'kid_owner_id',
    T_visibility: 'T_visibility',
  }
  constructor() {
    super('ska_cards')
  }

  get card_type(): number[] {
    return this.get('card_type')
  }
  set card_type(value: number[]) {
    this.set('card_type', value)
  }

  get pdfs(): SkaFile[] {
    return this.get('pdfs')
  }
  set pdfs(value: SkaFile[]) {
    this.set('pdfs', value)
  }
  get images(): SkaFile[] {
    return this.get('images')
  }
  set images(value: SkaFile[]) {
    this.set('images', value)
  }
  get text(): string {
    return this.get('text')
  }
  set text(value: string) {
    this.set('text', value)
  }

  get relation_from(): Parse.Object[] {
    return this.get('relation_from')
  }
  set relation_from(value: Parse.Object[]) {
    this.set('relation_from', value)
  }
  get relation_to(): Parse.Object[] {
    return this.get('relation_to')
  }
  set relation_to(value: Parse.Object[]) {
    this.set('relation_to', value)
  }
  get relation_type(): number {
    return this.get('relation_type')
  }
  set relation_type(value: number) {
    this.set('relation_type', value)
  }
  get relation_container(): number {
    return this.get('relation_container')
  }
  set relation_container(value: number) {
    this.set('relation_container', value)
  }

  get T_visibility(): SKA_VISIBILITY {
    return this.get('T_visibility')
  }
  set T_visibility(value: SKA_VISIBILITY) {
    this.set('T_visibility', value)
  }
  get T_tagged_kids_id(): string[] {
    return this.get('T_tagged_kids_id')
  }
  set T_tagged_kids_id(value: string[]) {
    this.set('T_tagged_kids_id', value)
  }
  get text_tags(): number[] {
    return this.get('text_tags')
  }
  set text_tags(value: number[]) {
    this.set('text_tags', value)
  }

  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
}
Parse.Object.registerSubclass('ska_cards', SkaCard)
