import React, { useContext, useEffect, useState } from 'react'
import Page from '../../components/Page'
import ColorCard from '../../components/ColorCard'
import { UserStoreContext } from '../../store/userStore'
import { useNavigate, useParams } from 'react-router-dom'
import { NavigationStoreContext } from '../../store/navigationStore'
import { Slider } from '../../components/Slider'
import { boolean } from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBookOpen,
  faBox,
  faBoxArchive,
  faCheck,
  faCheckCircle,
  faCircleUser,
  faClock,
  faCommentMedical,
  faFolder,
  faMegaphone,
  faNewspaper,
  faRightLeft,
  faStar,
} from '@fortawesome/pro-solid-svg-icons'
import { DashboardItem } from '../dashboard/components/DashboardItem'
import {
  getImageFromRole,
  getImageOrPlaceHolder,
  getSubTitleFromRole,
  getTitleFromRole,
} from '../../utils/parseUtils'
import UserChooser from './components/UserChooser'
import { DepartmentStoreContext } from '../../store/departmentStore'
import { Department } from '../../Models/Department'
import { UserRole } from '../../Models/UserRole'
import { USER_ROLE_STATUS, USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import { EventStoreContext } from '../../store/eventStore'
import { DocumentStoreContext } from '../../store/documentStore'
import { PortfolioBlogStoreContext } from '../../store/portfolioBlogStore'
import { KidsStoreContext } from '../../store/kidsStore'
import { t } from 'i18next'
import { Spinner } from '../../components/Spinner'
import { Loading } from '../../components/Loading'

export interface NoticeAndPushInterface {
  type: string //'mobile' | 'email' | 'ssn'
  username: string
  name: string
  primary_department: Department | null
  selectedDepartments: Department[]
  admin: boolean
  school_account: boolean
  staff_scheduele: boolean
  staff_sensitive_information: boolean
  staff_statistics: boolean
  super_admin: boolean
  read_only: boolean
}

const initialValues = {
  type: 'mobile',
  username: '',
  name: '',
  primary_department: null,
  selectedDepartments: [],
  admin: false,
  school_account: false,
  staff_scheduele: false,
  staff_sensitive_information: false,
  staff_statistics: false,
  super_admin: false,
  read_only: false,
}

export const NoticeAndPush: React.FC<{
  onClick?: any
  userRole?: UserRole
}> = ({ userRole }) => {
  let { schoolId } = useParams()

  const { setTitle } = useContext(NavigationStoreContext)
  const { absenceCreateBool } = useContext(UserStoreContext)
  const [currentRoleType, setCurrentRoleType] = useState(null)
  const [newDepartment, setNewDepartment] =
    useState<NoticeAndPushInterface>(initialValues)

  const { fetchUserRole, currentUserRole, readOnly } =
    useContext(UserStoreContext)
  const {
    departmentsForSpecificUserRole,
    fetchDepartmentsForSpecificUserRole,
  } = useContext(DepartmentStoreContext)
  const {
    userRoles,
    fetchUserRoles,
    checkInfoKidBool,
    newEventBool,
    newBlogPostBool,
    newBlogCommentBool,
    newPortCommentBool,
    newPortPostBool,
    scheduleEditKidBool,
    staffScheduleEditBool,
    createNoteKidBool,
    documentCreatedBool,
    staffCheckBool,
    adminEditCheckBool,
    markAllTeacherBool,
    kidCheckInBool,
    kidCheckOutBool,
    boxEmpty,
    boxAlmostEmpty,
    markAllParentBool,
    selectNoticeDeparments,
  } = useContext(UserStoreContext)
  const [selectedRole, setSelectedRole] = useState<UserRole | null>(
    currentUserRole ?? null,
  )
  const [fakeState, setFakeState] = useState(false)
  const [loading, setLoading] = useState(false)

  const others = userRoles
    ?.filter((role) => !role?.attributes?.kid)
    .sort((a, b) => {
      return getTitleFromRole(a).localeCompare(getTitleFromRole(b))
    })
  const kids = userRoles
    ?.filter((role) => role?.attributes?.kid)
    .sort((a, b) => {
      return a?.attributes?.kid?.attributes.kidName.localeCompare(
        b?.attributes?.kid?.attributes.kidName,
      )
    })
  useEffect(() => {
    if (schoolId) {
      setLoading(true)
      fetchUserRoles().finally(() => {
        setLoading(false)
      })
    }
    setTitle(t('settings.notificationsAndPush'))
  }, [schoolId])

  useEffect(() => {
    if (selectedRole && schoolId) {
      setLoading(true)
      fetchDepartmentsForSpecificUserRole(schoolId, selectedRole).finally(
        () => {
          setLoading(false)
        },
      )
    }
  }, [selectedRole])
  const isTeacher = selectedRole?.attributes?.role_type > USER_ROLE_TYPE.PARENT
  const isParent = selectedRole?.attributes?.role_type == USER_ROLE_TYPE.PARENT

  return (
    <Page>
      {loading && <Loading />}

      <div className="flex flex-col justify-between gap-y-4 h-full w-full p-4 sm:p-20">
        <div className="flex-wrap flex  gap-4 justify-center w-full">
          {[...(others ?? []), ...(kids ?? [])]?.map((role, index) => {
            return (
              <>
                <div
                  key={index}
                  className={`flex w-[120px] relative h-[140px] items-center text-center  justify-center p-4 border-[1px] shadow ${
                    selectedRole?.id === role.id
                      ? 'bg-gray-100'
                      : 'hover:shadow-md hover:bg-gray-50 '
                  } rounded-2xl flex-col items-center border-b cursor-pointer`}
                  onClick={() => {
                    if (role.status !== USER_ROLE_STATUS.PAUSED) {
                      setLoading(true)
                      setSelectedRole(role)
                      setLoading(false)
                    }
                  }}
                >
                  <div className="flex flex-col items-center gap-y-2">
                    {role?.attributes?.role_type === USER_ROLE_TYPE.PARENT ? (
                      <img
                        src={getImageFromRole(role)}
                        className="h-[40px] w-[40px] rounded-full object-cover"
                        alt=""
                      />
                    ) : (
                      <div className="h-[40px] w-[40px] bg-gray-200 rounded-full object-cover flex items-center justify-center">
                        <p className={'text-gray-500'}>
                          {getTitleFromRole(role).slice(0, 2)?.toUpperCase()}
                        </p>
                      </div>
                    )}

                    <div className={'flex flex-col'}>
                      <p className={'text-black text-sm'}>
                        {getTitleFromRole(role)}
                      </p>
                      <p className={'text-gray-700 font-medium text-sm'}>
                        {getSubTitleFromRole(role)}
                      </p>
                    </div>
                  </div>
                  <div className=" absolute top-1 right-1">
                    {selectedRole?.id === role?.id && (
                      <FontAwesomeIcon
                        className="text-xl text-eventsMain"
                        icon={faCheckCircle}
                      />
                    )}
                  </div>
                </div>
              </>
            )
          })}
        </div>
        {isTeacher && (
          <div className="flex flex-col gap-y-4">
            <div>
              <h1 className="flex flex-col gap-y-2 font-semibold">
                <>{t('settings.notisFromDepartments')}:</>
              </h1>

              <div className="flex w-full justify-center p-4 flex-wrap gap-2 ">
                {departmentsForSpecificUserRole?.map((department) => (
                  <div
                    key={department.id}
                    className={`p-2 rounded-full flex flex-row relative justify-center min-w-[190px] max-w-[200px] overflow-hidden shadow-md cursor-pointer no-select ${
                      selectedRole?.user_role_setting.attributes?.active_departments?.find(
                        (depId: string) => depId === department.id,
                      )
                        ? 'bg-blue-300 hover:bg-blue-300 border text-white'
                        : 'bg-white hover:bg-gray-100 border border-gray-200 text-gray-900'
                    }`}
                    onClick={() => {
                      // console.log('välja')
                      if (selectedRole)
                        selectNoticeDeparments(
                          selectedRole,
                          department.id,
                        ).finally(() => {
                          setFakeState(!fakeState)
                        })
                    }}
                  >
                    <div className=" absolute top-[9px] left-4">
                      {/*selectedRole?.staff_primary_dep?.id ===
                        department.id && (
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-yellow-600"
                        />
                        )*/}
                    </div>
                    <div className="max-w-[120px] flex justify-start overflow-hidden">
                      {department.attributes.klassName}
                    </div>
                    {selectedRole?.user_role_setting.attributes.active_departments.find(
                      (depId: string) => depId === department.id,
                    ) && (
                      <div className="ml-2 absolute top-[9px] right-4 ">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-lime-300"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col justify-between gap-y-2 mb-4">
              <div className="flex flex-col">
                <div className="flex flex-row justify-between items-start text-start w-full ">
                  <h1 className="font-semibold sm:text-lg w-full">
                    <div className="flex flex-row items-center text-end gap-x-4">
                      <>{t('settings.notisCreatePush')}:</>
                      {'   '}
                    </div>
                  </h1>
                  <div className="flex flex-col sm:flex-row gap-x-2 items-end sm:items-start justify-end w-[260px] mr-6">
                    <p className="text-sm font-semibold flex items-start text-start mt-[6px]">
                      <>{t('settings.markAll')}</>
                    </p>
                    <Slider
                      id={0}
                      value={
                        selectedRole?.user_role_setting.attributes
                          .absence_push &&
                        selectedRole?.user_role_setting.attributes
                          .check_info_push &&
                        selectedRole?.user_role_setting.attributes
                          .events_push &&
                        selectedRole?.user_role_setting.attributes.blog_push &&
                        selectedRole?.user_role_setting.attributes
                          .blog_push_comment &&
                        selectedRole?.user_role_setting.attributes
                          .portfolio_push &&
                        selectedRole?.user_role_setting.attributes
                          .portfolio_comments_push &&
                        selectedRole?.user_role_setting.attributes
                          .kids_schedule_push &&
                        selectedRole?.user_role_setting.attributes
                          .staff_schedule_push &&
                        selectedRole?.user_role_setting.attributes.notes_push &&
                        selectedRole?.user_role_setting.attributes
                          .document_push &&
                        selectedRole?.user_role_setting.attributes
                          .staff_check_push &&
                        selectedRole?.user_role_setting.attributes
                          .staff_check_edit_push
                          ? true
                          : false
                      }
                      onSliderClick={() => {
                        if (selectedRole) {
                          markAllTeacherBool(
                            selectedRole,
                            selectedRole?.user_role_setting.attributes
                              .absence_push &&
                              selectedRole?.user_role_setting.attributes
                                .check_info_push &&
                              selectedRole?.user_role_setting.attributes
                                .events_push &&
                              selectedRole?.user_role_setting.attributes
                                .blog_push &&
                              selectedRole?.user_role_setting.attributes
                                .blog_push_comment &&
                              selectedRole?.user_role_setting.attributes
                                .portfolio_push &&
                              selectedRole?.user_role_setting.attributes
                                .portfolio_comments_push &&
                              selectedRole?.user_role_setting.attributes
                                .kids_schedule_push &&
                              selectedRole?.user_role_setting.attributes
                                .staff_schedule_push &&
                              selectedRole?.user_role_setting.attributes
                                .notes_push &&
                              selectedRole?.user_role_setting.attributes
                                .document_push &&
                              selectedRole?.user_role_setting.attributes
                                .staff_check_push &&
                              selectedRole?.user_role_setting.attributes
                                .staff_check_edit_push
                              ? false
                              : true,
                          ).finally(() => {
                            setFakeState(!fakeState)
                          })
                        }
                      }}
                      onBoxClick={() => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-red-400 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faCommentMedical} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.absenceCreate')}</>
                </h1>
              </div>
              <div className="">
                <Slider
                  id={0}
                  value={
                    selectedRole?.user_role_setting?.attributes.absence_push
                      ? true
                      : false
                  }
                  onSliderClick={() => {
                    if (selectedRole) {
                      absenceCreateBool(
                        selectedRole,
                        selectedRole?.user_role_setting?.attributes
                          ?.absence_push
                          ? false
                          : true,
                      ).finally(() => {
                        setFakeState(!fakeState)
                      })
                    }
                  }}
                  onBoxClick={() => {}}
                />
              </div>
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-red-300 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faArrowRightFromBracket} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.infoKidEdit')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.check_info_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    checkInfoKidBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.check_info_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-blue-300 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faNewspaper} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.newEventCreate')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.events_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    newEventBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.events_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-gray-800 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faMegaphone} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  {' '}
                  <>{t('settings.newBlogCreated')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.blog_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    newBlogPostBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.blog_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow  bg-gray-800 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faMegaphone} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.whenBlogCommented')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.blog_push_comment
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    newBlogCommentBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes
                        .blog_push_comment
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-lime-300 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faCircleUser} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.whenPortCommented')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes
                    .portfolio_comments_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    newPortCommentBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes
                        .portfolio_comments_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-yellow-300 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faClock} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.scheduleForKidEdit')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.kids_schedule_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    scheduleEditKidBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes
                        .kids_schedule_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-yellow-300 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faClock} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  {' '}
                  <>{t('settings.myScheduleEdit')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.staff_schedule_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    staffScheduleEditBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes
                        .staff_schedule_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-red-200 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faBookOpen} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.createNoteKid')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.notes_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    createNoteKidBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.notes_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-blue-300 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faFolder} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.documentCreated')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.document_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    documentCreatedBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.document_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-gray-400 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faRightLeft} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.staffCheckInOut')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.staff_check_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    staffCheckBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.staff_check_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-gray-400 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faRightLeft} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.adminEditCheckInOut')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes
                    .staff_check_edit_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    adminEditCheckBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes
                        .staff_check_edit_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
          </div>
        )}{' '}
        {isParent && (
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col justify-between gap-y-2 mb-4">
              <div className="flex flex-col">
                <div className="flex flex-row justify-between items-start text-start ">
                  <h1 className="font-semibold sm:text-lg w-full">
                    <div className="flex flex-row items-center text-end gap-x-4">
                      <>{t('settings.notisCreatePush')}:</>
                      {'   '}
                    </div>
                  </h1>
                  <div className="flex flex-col sm:flex-row gap-x-2 items-end sm:items-start justify-end w-[260px] mr-6">
                    <p className="text-sm font-semibold flex items-start text-start mt-[6px]">
                      <>{t('settings.markAll')}</>
                    </p>
                    <Slider
                      id={0}
                      value={
                        selectedRole?.user_role_setting.attributes
                          .check_in_push &&
                        selectedRole?.user_role_setting.attributes
                          .check_out_push &&
                        selectedRole?.user_role_setting.attributes
                          .check_info_push &&
                        selectedRole?.user_role_setting.attributes
                          .events_push &&
                        selectedRole?.user_role_setting.attributes.blog_push &&
                        selectedRole?.user_role_setting.attributes
                          .blog_push_comment &&
                        selectedRole?.user_role_setting.attributes
                          .portfolio_push &&
                        selectedRole?.user_role_setting.attributes
                          .portfolio_comments_push &&
                        selectedRole?.user_role_setting.attributes
                          .kids_schedule_push &&
                        selectedRole?.user_role_setting.attributes
                          .document_push &&
                        selectedRole?.user_role_setting.attributes
                          .staff_check_edit_push &&
                        selectedRole?.user_role_setting.attributes
                          .box_none_push &&
                        selectedRole?.user_role_setting.attributes.box_some_push
                          ? true
                          : false
                      }
                      onSliderClick={() => {
                        if (selectedRole) {
                          markAllParentBool(
                            selectedRole,
                            selectedRole?.user_role_setting.attributes
                              .check_in_push &&
                              selectedRole?.user_role_setting.attributes
                                .check_out_push &&
                              selectedRole?.user_role_setting.attributes
                                .check_info_push &&
                              selectedRole?.user_role_setting.attributes
                                .events_push &&
                              selectedRole?.user_role_setting.attributes
                                .blog_push &&
                              selectedRole?.user_role_setting.attributes
                                .blog_push_comment &&
                              selectedRole?.user_role_setting.attributes
                                .portfolio_push &&
                              selectedRole?.user_role_setting.attributes
                                .portfolio_comments_push &&
                              selectedRole?.user_role_setting.attributes
                                .kids_schedule_push &&
                              selectedRole?.user_role_setting.attributes
                                .document_push &&
                              selectedRole?.user_role_setting.attributes
                                .staff_check_edit_push &&
                              selectedRole?.user_role_setting.attributes
                                .box_none_push &&
                              selectedRole?.user_role_setting.attributes
                                .box_some_push
                              ? false
                              : true,
                          ).finally(() => {
                            setFakeState(!fakeState)
                          })
                        }
                      }}
                      onBoxClick={function (): void {
                        throw new Error('Function not implemented.')
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-emerald-300 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faArrowRightToBracket} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.myKidCheckIn')}</>
                </h1>
              </div>
              <div className="">
                <Slider
                  id={0}
                  value={
                    selectedRole?.user_role_setting.attributes.check_in_push
                      ? true
                      : false
                  }
                  onSliderClick={() => {
                    if (selectedRole) {
                      kidCheckInBool(
                        selectedRole,
                        selectedRole.user_role_setting.attributes.check_in_push
                          ? false
                          : true,
                      ).finally(() => {
                        setFakeState(!fakeState)
                      })
                    }
                  }}
                  onBoxClick={() => {}}
                />
              </div>
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-red-300 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faArrowRightFromBracket} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  {' '}
                  <>{t('settings.myKidCheckOut')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.check_out_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    kidCheckOutBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.check_out_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-blue-300 text-white">
                  {' '}
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    size="2x"
                  />{' '}
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.infoKidEdit')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.check_info_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    checkInfoKidBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.check_info_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-yellow-200 text-white">
                  {' '}
                  <FontAwesomeIcon icon={faClock} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.scheduleForKidEdit')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.kids_schedule_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    scheduleEditKidBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes
                        .kids_schedule_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-boxMain text-white">
                  {' '}
                  <FontAwesomeIcon icon={faBoxArchive} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.boxAlmostEmpty')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.box_some_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    boxAlmostEmpty(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.box_some_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-boxMain text-white">
                  {' '}
                  <FontAwesomeIcon icon={faBoxArchive} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.boxEmpty')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.box_none_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    boxEmpty(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.box_none_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-eventsMain text-white">
                  {' '}
                  <FontAwesomeIcon icon={faNewspaper} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.newEventCreate')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.events_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    newEventBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.events_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-black text-white">
                  {' '}
                  <FontAwesomeIcon icon={faMegaphone} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.newBlogCreated')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.blog_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    newBlogPostBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.blog_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-black text-white">
                  {' '}
                  <FontAwesomeIcon icon={faMegaphone} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.whenBlogCommented')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.blog_push_comment
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    newBlogCommentBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes
                        .blog_push_comment
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow   bg-portfolioMain text-white">
                  {' '}
                  <FontAwesomeIcon icon={faCircleUser} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.newPortCreated')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.portfolio_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    newPortPostBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.portfolio_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow  bg-portfolioMain text-white">
                  {' '}
                  <FontAwesomeIcon icon={faCircleUser} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  {' '}
                  <>{t('settings.whenPortCommented')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes
                    .portfolio_comments_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    newPortCommentBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes
                        .portfolio_comments_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-notesMain text-white">
                  {' '}
                  <FontAwesomeIcon icon={faBookOpen} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  <>{t('settings.adminEditCheckInOut')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes
                    .staff_check_edit_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    adminEditCheckBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes
                        .staff_check_edit_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-x-4">
                <div className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] flex justify-center items-center rounded-2xl shadow bg-documentsMain text-white">
                  {' '}
                  <FontAwesomeIcon icon={faFolder} size="2x" />
                </div>
                <h1 className="text-sm font-semibold">
                  {' '}
                  <>{t('settings.documentCreated')}</>
                </h1>
              </div>
              <Slider
                id={0}
                value={
                  selectedRole?.user_role_setting.attributes.document_push
                    ? true
                    : false
                }
                onSliderClick={() => {
                  if (selectedRole) {
                    documentCreatedBool(
                      selectedRole,
                      selectedRole.user_role_setting.attributes.document_push
                        ? false
                        : true,
                    ).finally(() => {
                      setFakeState(!fakeState)
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            <hr />
          </div>
        )}
      </div>
    </Page>
  )
}
