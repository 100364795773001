import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import { USER_ROLE_TYPE } from '../../../../Enums/TyraEnums'
import ColorCard from '../../../../components/ColorCard'
import Page from '../../../../components/Page'
import { RandomColors } from '../../../../components/RandomColors'
import { getImageOrPlaceHolder } from '../../../../utils/parseUtils'
import { useContext, useEffect, useState } from 'react'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { KidsStoreContext } from '../../../../store/kidsStore'
import { UserStoreContext } from '../../../../store/userStore'
import { Button } from '../../../../components/Button'
import ModalRegular from '../../../../components/ModalRegular'
import Swal from 'sweetalert2'
import { Department } from '../../../../Models/Department'
import { faCheck, faEye } from '@fortawesome/pro-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { NavigationStoreContext } from '../../../../store/navigationStore'
import { Loading } from '../../../../components/Loading'

export const HideChildParent = () => {
  const { departments, selectedDepartments, fetchDepartments } = useContext(
    DepartmentStoreContext,
  )
  const { fetchKids, kids, search, fetchUserRoles, hideKidParent } =
    useContext(KidsStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  const [selectedKids, setSelectedKids] = useState<string[]>([])
  const toggleKidSelection = (kidId: string) => {
    if (selectedKids.includes(kidId)) {
      setSelectedKids(selectedKids.filter((id) => id !== kidId))
    } else {
      setSelectedKids([...selectedKids, kidId])
    }
  }
  const [loading, setLoading] = useState(false)
  const [selectedHiddenKids, setSelectedHiddenKids] = useState<string[]>([])
  const allSelectedKidsAcrossDepartments = kids?.filter(
    (kid) =>
      selectedKids.includes(kid?.id) &&
      departments?.some(
        (department) =>
          kid?.attributes?.departmentPointer?.id === department?.id,
      ),
  )
  const toggleHiddenKidSelection = (kidId: string) => {
    if (selectedHiddenKids.includes(kidId)) {
      setSelectedHiddenKids(selectedHiddenKids.filter((id) => id !== kidId))
    } else {
      setSelectedHiddenKids([...selectedHiddenKids, kidId])
    }
  }
  const [showModal, setShowModal] = useState(false)

  const [selectedDepartment, setSelectedDepartment] =
    useState<Department | null>(null)
  const { schoolId } = useParams()
  useEffect(() => {
    if (currentUserRole && currentUserRole?.school_pointer) {
      setLoading(true)
      fetchKids(currentUserRole)
      fetchDepartments(currentUserRole?.attributes?.school_pointer?.id, true)
      fetchUserRoles(currentUserRole).finally(() => {
        setLoading(false)
      })
    }
  }, [currentUserRole?.id])
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('absence.chooseKid'))
  }, [t])

  return (
    <Page
      topBar={
        <>
          <div className="flex flex-row gap-x-2">
            {' '}
            {allSelectedKidsAcrossDepartments &&
              allSelectedKidsAcrossDepartments.length > 0 && (
                <Button
                  variant="borderRed"
                  size="sm"
                  label={t('general.hide')}
                  onClick={() => {
                    Swal.fire({
                      text: t('admin.kidHideParentText'),
                      icon: 'warning',
                      showCancelButton: true,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        for (let kid of allSelectedKidsAcrossDepartments ||
                          []) {
                          const kidObject = kids?.find((k) => k.id === kid.id)
                          if (kidObject) {
                            await hideKidParent(true, kidObject)
                          }
                        }
                        if (currentUserRole) {
                          fetchKids(currentUserRole)
                        }
                        setSelectedKids([])
                      }
                    })
                  }}
                />
              )}
            {selectedHiddenKids.length > 0 && (
              <Button
                variant="borderBlue"
                size="sm"
                label={t('admin.show')}
                icon={faEye}
                reverseIcon
                onClick={() => {
                  Swal.fire({
                    //title: 'Are you sure?',
                    text: t('admin.showHideParentText'),
                    icon: 'warning',
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                    cancelButtonText: t('general.cancel'),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      for (let kidId of selectedHiddenKids) {
                        const kidObject = kids?.find((k) => k.id === kidId)
                        if (kidObject) {
                          await hideKidParent(false, kidObject)
                        }
                      }
                      if (currentUserRole) {
                        fetchKids(currentUserRole)
                      }
                      setSelectedHiddenKids([])
                    }
                  })
                }}
              />
            )}
          </div>
        </>
      }
      naked
    >
      {loading && <Loading />}

      <div className={'flex justify-center w-full flex-col md:space-y-10 '}>
        {kids?.some((kid) => kid?.attributes?.hidden_in_dep_list) && (
          <ColorCard bgColor="bg-red-300 p-3" title={t('admin.hiddenKid')}>
            <div className="flex flex-wrap max-w-7xl py-3 font-medium justify-center">
              {kids
                ?.filter((kid) => kid?.attributes?.hidden_in_dep_list)
                .map((hiddenKid) => {
                  const isSelected = selectedHiddenKids.includes(
                    hiddenKid?.id || '',
                  )

                  return (
                    <div
                      key={hiddenKid?.id}
                      onClick={() => {
                        toggleHiddenKidSelection(hiddenKid?.id || '')
                      }}
                      className={`flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:bg-gray-50 hover:shadow-md rounded-2xl`}
                    >
                      <div className="relative">
                        {isSelected && (
                          <div className="absolute border-[4px] border-red-600 rounded-full w-[80px] h-[80px] top-0 left-0" />
                        )}
                        {hiddenKid.removed_from_ss12k && (
                          <div className="z-10 w-full h-full absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] rounded-full font-bold text-red-400 flex items-center justify-center text-xs text-center">
                            <>{t('general.endDate')}</>
                            <br />
                            {hiddenKid.attributes.placement.attributes.endDate}
                          </div>
                        )}
                        <img
                          className={`w-[80px] h-[80px] rounded-full object-cover shadow`}
                          src={getImageOrPlaceHolder(
                            hiddenKid?.attributes?.kidImage?._url,
                          )}
                          alt={hiddenKid?.attributes?.kidName}
                        />
                      </div>
                      <p>{hiddenKid?.attributes.kidName}</p>
                    </div>
                  )
                })}
            </div>
          </ColorCard>
        )}
        {departments
          ?.filter((dep) => {
            if (!selectedDepartments?.length) {
              return true
            }
            return selectedDepartments?.find((selDep) => selDep.id === dep.id)
          })
          .map((department, index) => {
            const selectedKidsInDepartment = selectedKids.filter((kidId) =>
              kids?.find(
                (kid) =>
                  kid?.id === kidId &&
                  kid?.attributes?.departmentPointer?.id === department?.id,
              ),
            )
            return (
              <>
                <ColorCard
                  title={department?.attributes?.klassName + ''}
                  bgColor={
                    department.get('departmentColor')
                      ? 'bg-[' + department.get('departmentColor') + ']'
                      : RandomColors[index]
                  }
                >
                  {department.get('departmentColor') ? (
                    <div className="flex flex-wrap justify-center py-44 text-lg sm:text-2xl text-gray-300 w-full ">
                      <> {t('ourKids.noContactInfo')}</>
                    </div>
                  ) : (
                    <div
                      className={
                        'flex flex-wrap max-w-7xl py-3 font-medium justify-center'
                      }
                    >
                      {kids
                        ?.filter((k) => {
                          if (k?.attributes?.hidden_in_dep_list) {
                            return false
                          }

                          if (search?.length) {
                            return (
                              k?.attributes?.departmentPointer?.id ===
                                department?.id &&
                              k?.attributes?.kidName
                                ?.toLowerCase()
                                .includes(search.toLowerCase())
                            )
                          }
                          return (
                            k?.attributes?.departmentPointer?.id ===
                            department?.id
                          )
                        })
                        .sort((a, b) => {
                          return a?.attributes?.kidName.localeCompare(
                            b?.attributes?.kidName,
                          )
                        })
                        .map((kid) => {
                          const isSelected = selectedKids.includes(
                            kid?.id || '',
                          )

                          return (
                            <div
                              key={kid?.id}
                              onClick={() => {
                                toggleKidSelection(kid?.id || '')
                              }}
                              className={`flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:bg-gray-50 hover:shadow-md rounded-2xl`}
                            >
                              <div className="relative ">
                                {isSelected && (
                                  <div className="absolute border-[4px] border-eventsMain rounded-full w-[80px] h-[80px] top-0 left-0" />
                                )}
                                {kid.removed_from_ss12k && (
                                  <div className="z-10 w-full h-full absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] rounded-full font-bold text-red-400 flex items-center justify-center text-xs text-center">
                                    <>{t('general.endDate')}</>
                                    <br />
                                    {
                                      kid.attributes.placement.attributes
                                        .endDate
                                    }
                                  </div>
                                )}
                                <img
                                  className={`w-[80px] h-[80px] rounded-full object-cover shadow`}
                                  src={getImageOrPlaceHolder(
                                    kid?.attributes?.kidImage?._url,
                                  )}
                                  alt=""
                                />
                              </div>
                              <p>{kid?.attributes.kidName}</p>
                            </div>
                          )
                        })}
                    </div>
                  )}
                </ColorCard>
              </>
            )
          })}
      </div>
    </Page>
  )
}

export default HideChildParent
