import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import ModalRegular from '../../../components/ModalRegular'
import { Overview } from './Overview'
import { Button } from '../../../components/Button'
import { useTranslation } from 'react-i18next'
import { DocumentStoreContext } from '../../../store/documentStore'
import { Loading } from '../../../components/Loading'

export const OverviewModal: React.FC<{
  documentId: string
  type: string
}> = observer(({ documentId, type }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  return (
    <div className={'w-full'}>
      <Button
        size="md"
        className={'w-full'}
        label={t('documents.overview')}
        onClick={() => {
          setShowModal(true)
        }}
      />
      <ModalRegular
        title={''}
        isOpen={showModal}
        className={'max-w-7xl'}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <div className={'pt-10 min-h-[400px]'}>
          <Overview documentId={documentId} type={type} />
        </div>
      </ModalRegular>
    </div>
  )
})
