import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ContactStoreContext } from '../../store/contactStore'
import { getImageOrPlaceHolder } from '../../utils/parseUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faPhone,
  faCommentSms,
  faEnvelope,
  faMapLocationDot,
} from '@fortawesome/free-solid-svg-icons'

export const Contact: React.FC<{}> = observer(({}) => {
  const { t } = useTranslation()
  let { userRoleId } = useParams()
  const { setTitle } = useContext(NavigationStoreContext)
  const { fetchContact, contact } = useContext(ContactStoreContext)
  useEffect(() => {
    setTitle(t('menu.contact'))
  }, [t])

  useEffect(() => {
    if (userRoleId) {
      fetchContact(userRoleId)
    }
  }, [userRoleId])

  return (
    <Page>
      <div className={'flex flex-col justify-center items-center pt-6 px-3'}>
        <div
          className={'flex flex-col space-y-3 justify-center items-center mb-6'}
        >
          <img
            className={'w-[80px] h-[80px] rounded-full border'}
            src={getImageOrPlaceHolder(
              contact?.attributes?.user?.attributes?.myPic_thumb?._url,
            )}
            alt=""
          />
          <div className={'flex flex-col justify-center items-center'}>
            <p className={'text-2xl'}>{contact?.attributes?.user?.firstName}</p>
            <p className={'text-sm text-gray-500'}>
              {contact?.attributes?.relation}
            </p>
          </div>
        </div>
        <div className={'w-full max-w-lg flex items-center'}>
          <div>
            <p className={'text-eventsMain'}>mobil</p>
            <p>
              {
                contact?.attributes?.user?.attributes?.user_extra?.attributes
                  ?.primary_phone
              }
            </p>
          </div>

          <div
            className={
              'flex-1 flex justify-end space-x-3 text-eventsMain cursor-pointer'
            }
          >
            <FontAwesomeIcon
              icon={faCommentSms}
              size={'2x'}
              onClick={() => {
                window.open(
                  'sms:' +
                    contact?.attributes?.user?.attributes?.user_extra
                      ?.attributes?.primary_phone,
                )
              }}
            />
            <FontAwesomeIcon
              icon={faPhone}
              size={'2x'}
              onClick={() => {
                window.open(
                  'tel:' +
                    contact?.attributes?.user?.attributes?.user_extra
                      ?.attributes?.primary_phone,
                )
              }}
            />
          </div>
        </div>
        <div className={'w-full max-w-lg flex items-center mt-6'}>
          <div>
            <p className={'text-eventsMain'}>email</p>
            <p>
              {
                contact?.attributes?.user?.attributes?.user_extra?.attributes
                  ?.email
              }
            </p>
          </div>

          <div
            className={
              'flex-1 flex justify-end space-x-3 text-eventsMain cursor-pointer'
            }
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              size={'2x'}
              onClick={() => {
                window.open(
                  'mailto:' +
                    contact?.attributes?.user?.attributes?.user_extra
                      ?.attributes?.email,
                )
              }}
            />
          </div>
        </div>
        <div className={'w-full max-w-lg flex items-center mt-6'}>
          <div>
            <p className={'text-eventsMain'}>adress</p>
            <p>
              {
                contact?.attributes?.user?.attributes?.user_extra?.attributes
                  ?.street_address
              }
            </p>
            <p>
              {
                contact?.attributes?.user?.attributes?.user_extra?.attributes
                  ?.zip_code
              }{' '}
              {
                contact?.attributes?.user?.attributes?.user_extra?.attributes
                  ?.city
              }
            </p>
          </div>
          <div
            className={
              'flex-1 flex justify-end space-x-3 text-eventsMain cursor-pointer'
            }
          >
            <FontAwesomeIcon
              icon={faMapLocationDot}
              size={'2x'}
              onClick={() => {
                const geo =
                  contact?.attributes?.user?.attributes?.user_extra?.attributes
                    ?.geo_point
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${geo?.latitude}%2C${geo?.longitude}`,
                  '_blank',
                )
              }}
            />
          </div>
        </div>
      </div>
    </Page>
  )
})
