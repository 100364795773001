import { observer } from 'mobx-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { EventListItemData, ILocation } from '../Events3'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGroup, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components/Button'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { UserStoreContext } from '../../../store/userStore'
import { StepButton } from './StepButton'
import { DateToEvent, DateToEventSelections } from './DateToEvent'
import {
  CreateEventParams,
  EVENT3_POST_TO,
  EVENT3_TYPE,
  Event3StoreContext,
  Times,
} from '../../../store/event3Store'
import {
  CREATE_EVENT_STEP,
  initialValues,
  usePostToSelections,
} from '../CreateEvent3'
import { TagsToEvent, TagsToEventSelections } from './TagsToEvent'
import { TagStoreContext } from '../../../store/tagStore'
import { LocationToEvent } from './LocationToEvent'
import { SeparateParentsToBooking } from './SeparateParentsToBooking'
import { AddFileModalNew } from './AddFileModalNew'
import { Kid } from '../../../Models/Kid'
import { AddParticipatingChildren } from './AddParticipatingChildren'
import CustomRadioButton from '../../../components/CustomRadioButton'
import Swal from 'sweetalert2'
import { PublishEventModal } from './PublishEventModal'
import ModalRegular from '../../../components/ModalRegular'
import classNames from 'classnames'
import { PostEventToSelections } from './PostEventTo'
import { TypeOfEventSelections } from './TypeOfEvent'
import { ChooseSchoolsSelections } from './ChooseSchools'
import { CreateLocation } from './CreateLocation'
import { useParams } from 'react-router-dom'

export interface EditEventParams {
  mode: 'edit'
  eventTimeId: string
  eventGroupInfoId: string
  updateTimeOnly?: boolean
  topic?: string
  content?: string
  fileIds?: (string | undefined)[] | undefined
  tagIds?: (string | undefined)[] | undefined
  locationId?: ILocation
  maxParticipants?: number
  depIds?: string[]
  newTimeSpan?: Times
  onlyStaff?: boolean
  publishAs?: string
  eventResponsible?: string
  isPinned?: boolean
  sendPush?: boolean
}
const EditEventPopUp: React.FC<{
  fetchedEventDetail: EventListItemData
  toggleEditMode: () => void
}> = observer(({ fetchedEventDetail, toggleEditMode }) => {
  const files: any[] | undefined =
    fetchedEventDetail.files && fetchedEventDetail.files.map((file) => file)

  const fileIds: any[] | undefined = fetchedEventDetail.files
    ? fetchedEventDetail.files
        .map((file) => file.id)
        .filter((id): id is string => id !== undefined)
    : undefined

  const tags: any[] | undefined = fetchedEventDetail.tags
    ? fetchedEventDetail.tags
        .map((tag) => tag.id)
        .filter((id): id is string => id !== undefined)
    : undefined

  const popupRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { fetchDepartmentsForMultiSchools, departmentsForMultiSchool } =
    useContext(DepartmentStoreContext)
  const {
    currentUserRole,
    getSchoolsForUserRoleAdmins,
    schoolsForUserRoleAdmins,
  } = useContext(UserStoreContext)

  const { tagsForEvent, fetchTagsForEvent } = useContext(TagStoreContext)
  const [toBeEditEvent, setToBeEditEvent] =
    useState<CreateEventParams>(initialValues)
  const [choosenKids, setChoosenKids] = useState<Kid[]>([])
  const [openAddParticipatingChildren, setOpenAddParticipatingChildren] =
    useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentStep, setCurrentStep] = useState<CREATE_EVENT_STEP>()
  const [openModal, setOpenModal] = useState(false)
  const { fetchLocations, locations } = useContext(Event3StoreContext)
  const { schoolId } = useParams()
  const postToSelections = usePostToSelections()

  const handleClickOutside = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation()
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      toggleEditMode()
    }
  }

  const toggleAddParticipatingChildrenPopUp = () => {
    setOpenAddParticipatingChildren(!openAddParticipatingChildren)
  }

  const handleDateChange = () => {
    setCurrentStep(CREATE_EVENT_STEP.DATE)
    setOpenModal(true)
  }
  const handleTagsChange = () => {
    setCurrentStep(CREATE_EVENT_STEP.TAGS)
    setOpenModal(true)
  }
  const handlePlatsChange = () => {
    setCurrentStep(CREATE_EVENT_STEP.LOCATION)
    if (!toBeEditEvent.location) {
      setOpenModal(true)
    }
  }

  const handleSeparateParentsChange = () => {
    setCurrentStep(CREATE_EVENT_STEP.SEPARATE_PARENTS)
    setOpenModal(true)
  }

  useEffect(() => {
    fetchDepartmentsForMultiSchools([currentUserRole?.school_pointer.id ?? ''])
    fetchTagsForEvent(
      [currentUserRole?.school_pointer.id ?? ''],
      fetchedEventDetail.type,
      fetchedEventDetail.onlyStaff ?? false,
    )
  }, [currentUserRole])

  useEffect(() => {
    setToBeEditEvent({
      ...toBeEditEvent,
      mod: 'edit',
      eventType:
        EVENT3_TYPE[
          fetchedEventDetail.type.toUpperCase() as keyof typeof EVENT3_TYPE
        ],
      topic: fetchedEventDetail.topic,
      content: fetchedEventDetail.content,
      files: files,
      fileIds: fileIds,
      tagIds: tags,
      location: fetchedEventDetail.location,
      depIds: fetchedEventDetail.departmentIds,
      maxParticipants: fetchedEventDetail.maxParticipants,
      times: fetchedEventDetail.times.map((item) => ({
        date: fetchedEventDetail.eventDate,
        start: item.start_time || undefined,
        end: item.end_time || undefined,
        fakeId: item.id ?? '',
      })),
      onlyStaff: fetchedEventDetail.onlyStaff,
      /* publishAs: // how to find the info about the publish as?, */
      /* eventResponsible: // where in the ui we pass responsible */
      /* isPinned: fetchedEventDetail.isPinned, */
    })
  }, [fetchedEventDetail])

  useEffect(() => {
    if (schoolId) {
      fetchLocations([schoolId])
    }
  }, [schoolId])

  return (
    <div
      className="fixed inset-0 flex items-center  justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50"
      onClick={handleClickOutside}
    >
      <div
        className="bg-[#F6F6F6] h-[85%] my-auto w-4/5 h-5/5 p-1 rounded-lg shadow-lg sm:w-2/3"
        ref={popupRef}
      >
        <div className="flex flex-col w-full h-full">
          <div className="flex justify-end p-1">
            <FontAwesomeIcon
              icon={faXmark}
              size="1x"
              className="text-gray-600 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation()
                toggleEditMode()
              }}
            />
          </div>
          <div id="departments " className="flex flex-col p-2">
            <p className="font-semibold">
              !! Select classroom {'( '} {toBeEditEvent.depIds?.length} {')'}
            </p>
            <div className="flex items-center h-[60px] overflow-x-auto text-nowrap gap-x-2">
              {departmentsForMultiSchool?.map((dep) => {
                return (
                  <Button
                    key={dep.id}
                    size="sm"
                    variant={
                      toBeEditEvent.depIds?.find((depId) => depId === dep.id)
                        ? 'lightPurple'
                        : 'fullWhiteGrayText'
                    }
                    label={dep.klassName}
                    onClick={() => {
                      if (
                        toBeEditEvent.depIds?.find((depId) => depId === dep.id)
                      ) {
                        // Remove from array
                        setToBeEditEvent({
                          ...toBeEditEvent,
                          depIds: [
                            ...toBeEditEvent.depIds?.filter(
                              (depId) => depId !== dep.id,
                            ),
                          ],
                        })
                      } else {
                        // Add to array
                        if (toBeEditEvent.depIds)
                          setToBeEditEvent({
                            ...toBeEditEvent,
                            depIds: [...toBeEditEvent.depIds, dep.id],
                          })
                      }
                    }}
                  />
                )
              })}
            </div>
          </div>
          <div className="flex p-2">
            <div className="basis-7/12 pr-2 mr-2">
              <>
                <div className="p-6 w-full bg-white rounded-lg shadow-md flex flex-col border-2">
                  <div className="flex flex-col">
                    <div className="flex items-center space-x-2">
                      <div className="flex-1">
                        <input
                          type="text"
                          id="header"
                          className={`appearance-none border-b ${
                            !toBeEditEvent.topic ||
                            toBeEditEvent.topic.trim() === ''
                              ? 'border-black-700 placeholder-black'
                              : 'border-gray-300'
                          } w-full py-2 px-3 text-black-100 leading-tight focus:outline-none focus:shadow-outline font-extrabold`}
                          placeholder={t('notes.header')}
                          value={toBeEditEvent?.topic}
                          onChange={(e) => {
                            const value = e.target.value
                            // Kapitalisera det första tecknet i strängen och lägg till resten av strängen som den är.
                            const capitalizedValue =
                              value.charAt(0).toUpperCase() + value.slice(1)
                            setToBeEditEvent({
                              ...toBeEditEvent,
                              topic: capitalizedValue,
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <textarea
                      id="description"
                      className="rounded py-2 px-3 placeholder-gray-500 leading-tight focus:outline-none focus:shadow-outline text-md h-[300px]"
                      placeholder={t('events.contentPlaceholder')}
                      onChange={(e) => {
                        e.stopPropagation()
                        setToBeEditEvent({
                          ...toBeEditEvent,
                          content: e.target.value,
                        })
                      }}
                      value={toBeEditEvent.content}
                    ></textarea>
                  </div>
                </div>
              </>
              <ModalRegular
                isOpen={openModal}
                hideCloseButton={true}
                onClose={() => {
                  setOpenModal(false)
                }}
                className={classNames(
                  'p-12 transition',
                  currentStep === CREATE_EVENT_STEP.DATE ||
                    currentStep === CREATE_EVENT_STEP.SEPARATE_PARENTS
                    ? 'max-w-4xl'
                    : 'max-w-lg',
                )}
              >
                {currentStep === CREATE_EVENT_STEP.POST_TO && (
                  <PostEventToSelections
                    onChange={setToBeEditEvent}
                    newEvent={toBeEditEvent}
                    departments={departmentsForMultiSchool ?? []}
                    schools={schoolsForUserRoleAdmins}
                  />
                )}
                {currentStep === CREATE_EVENT_STEP.TYPE && (
                  <TypeOfEventSelections
                    onChange={setToBeEditEvent}
                    newEvent={toBeEditEvent}
                    closeModal={() => {
                      setOpenModal(false)
                    }}
                  />
                )}
                {currentStep === CREATE_EVENT_STEP.DATE && (
                  <DateToEventSelections
                    onChange={setToBeEditEvent}
                    newEvent={toBeEditEvent}
                  />
                )}
                {currentStep === CREATE_EVENT_STEP.TAGS && (
                  <TagsToEventSelections
                    newEvent={toBeEditEvent}
                    tags={tagsForEvent ?? []}
                    schools={schoolsForUserRoleAdmins}
                    onChange={setToBeEditEvent}
                    onDone={() => {}}
                    onSave={() => {}}
                  />
                )}
                {currentStep === CREATE_EVENT_STEP.CHOOSE_SCHOOLS && (
                  <ChooseSchoolsSelections
                    newEvent={toBeEditEvent}
                    schools={schoolsForUserRoleAdmins ?? []}
                    onChange={setToBeEditEvent}
                  />
                )}
                {currentStep === CREATE_EVENT_STEP.LOCATION && (
                  <CreateLocation
                    value={toBeEditEvent.locationType}
                    newEvent={toBeEditEvent}
                    onChange={setToBeEditEvent}
                    onDone={() => {}}
                    onSave={() => {}}
                    locations={locations ?? []}
                    onClose={() => {
                      setOpenModal(false)
                      //setNewEvent
                    }}
                  />
                )}

                {currentStep !== CREATE_EVENT_STEP.LOCATION && (
                  <div className="flex flex-row justify-center items-center w-full  pt-4">
                    <Button
                      size="lg"
                      className="w-[50%]"
                      variant={'fullBlue'}
                      label={t('events.add')}
                      onClick={(e) => {
                        e.stopPropagation()
                        setOpenModal(false)
                      }}
                    />
                  </div>
                )}
              </ModalRegular>
            </div>
            <div className="basis-5/12 flex flex-col gap-y-2 overflow-y-auto h-[380px]">
              <StepButton>
                <div className="flex flex-row w-full items-center">
                  <DateToEvent
                    newEvent={toBeEditEvent}
                    onChange={setToBeEditEvent}
                    onDone={() => {}}
                    onSave={() => {}}
                    onClick={handleDateChange}
                  />
                </div>
              </StepButton>
              <StepButton>
                <div className="flex flex-row justify-between w-full items-center">
                  <TagsToEvent
                    newEvent={toBeEditEvent}
                    tags={tagsForEvent ?? []}
                    schools={schoolsForUserRoleAdmins}
                    onChange={setToBeEditEvent}
                    onDone={() => {}}
                    onSave={() => {}}
                    onClick={handleTagsChange}
                  />
                </div>
              </StepButton>
              {fetchedEventDetail.type === EVENT3_TYPE.ACTIVITIES ||
              fetchedEventDetail.type === EVENT3_TYPE.BOOKING ||
              fetchedEventDetail.type === EVENT3_TYPE.MEETING ? (
                <StepButton>
                  <div className="flex flex-row justify-between w-full items-center ">
                    <LocationToEvent
                      newEvent={toBeEditEvent}
                      onChange={setToBeEditEvent}
                      onDone={() => {}}
                      onSave={() => {}}
                      onClick={handlePlatsChange}
                    />
                  </div>
                </StepButton>
              ) : null}
              {fetchedEventDetail.type === EVENT3_TYPE.BOOKING && (
                <StepButton>
                  <div className="flex flex-row justify-between w-full items-center ">
                    <SeparateParentsToBooking
                      newEvent={toBeEditEvent}
                      onChange={setToBeEditEvent}
                      onDone={() => {}}
                      onSave={() => {}}
                      onClick={handleSeparateParentsChange}
                    />
                  </div>
                </StepButton>
              )}
              <StepButton>
                <AddFileModalNew
                  newEvent={toBeEditEvent}
                  onChange={setToBeEditEvent}
                />
              </StepButton>
              {fetchedEventDetail.type === EVENT3_TYPE.ACTIVITIES &&
                fetchedEventDetail.onlyStaff !== true && (
                  <StepButton>
                    <div className="flex flex-col  justify-between items-center w-full">
                      <div className="font-bold flex flex-col items-center justify-between w-full">
                        <div className="font-bold flex flex-wrap items-center justify-between gap-x-1 w-full overflow-x-auto">
                          {''}
                          {!toBeEditEvent.addParticipatingKids?.length ? (
                            <div className="flex">
                              <FontAwesomeIcon
                                icon={faUserGroup}
                                className="text-orange-300 text-xl p-1"
                              />
                              <p>!!Add participating children: </p>
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <FontAwesomeIcon
                                icon={faUserGroup}
                                className="text-orange-300 text-xl p-1"
                              />
                              <p>
                                Selected children{'('}
                                {toBeEditEvent.addParticipatingKids?.length}
                                {')'}
                              </p>
                            </div>
                          )}
                          <Button
                            label={t('events.add')}
                            variant="darkBorderAndText"
                            size="xs"
                            onClick={(e) => {
                              toggleAddParticipatingChildrenPopUp()
                            }}
                          />
                        </div>
                        <div className="flex flex-row items-center w-[300px] whitespace-nowrap gap-1 mt-2 overflow-x-auto p-2">
                          {choosenKids.map((kid) => (
                            <div className="flex flex-col items-center justify-around space-x-1">
                              <img
                                className={`w-12 h-12 rounded-full object-cover bg-slate-400`}
                                alt=""
                                key={kid?.id}
                              >
                                {}
                              </img>
                              <p className="font-semibold">{kid.kidName}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {openAddParticipatingChildren && (
                      <AddParticipatingChildren
                        newEvent={toBeEditEvent}
                        onClose={() => setOpenAddParticipatingChildren(false)}
                        openAddParticipatingChildren={
                          openAddParticipatingChildren
                        }
                        depIds={toBeEditEvent.depIds}
                        onChange={setToBeEditEvent}
                        choosenKids={choosenKids}
                        onChangeChoosenKids={setChoosenKids}
                        toggleAddParticipatingChildrenPopUp={
                          toggleAddParticipatingChildrenPopUp
                        }
                      />
                    )}
                  </StepButton>
                )}
            </div>
          </div>
          <div className="p-2">
            <CustomRadioButton
              options={postToSelections}
              disable={fetchedEventDetail.type === EVENT3_TYPE.BOOKING}
              onChange={(value) => {
                /* if (value === EVENT3_POST_TO.ALL) {
                  setToBeEditEvent({
                    ...toBeEditEvent,
                    postTo: value,
                    onlyStaff: undefined,
                    destKids: undefined,
                    destRoles: undefined,
                  })
                } else */ if (value === EVENT3_POST_TO.ONLY_STAFF) {
                  setToBeEditEvent({
                    ...toBeEditEvent,
                    postTo:
                      toBeEditEvent.postTo === value
                        ? EVENT3_POST_TO.ALL
                        : value,
                    onlyStaff: toBeEditEvent.onlyStaff ? false : true,
                    destKids: undefined,
                    destRoles: undefined,
                  })
                } else if (value === EVENT3_POST_TO.SPECIFIC_USERS) {
                  setToBeEditEvent({
                    ...toBeEditEvent,
                    postTo: value,
                    onlyStaff: undefined,
                    destKids: [],
                    destRoles: [],
                  })
                }
              }}
              value={toBeEditEvent.onlyStaff === true ? 'only_staff' : 'all'}
            />
          </div>

          <div className="flex w-full justify-center h-[15%] items-center">
            <Button
              className="w-[40%]"
              size="lg"
              variant="custom"
              label={t('login.nextTitle')}
              onClick={() => {
                if (
                  !toBeEditEvent.eventType ||
                  !toBeEditEvent.depIds ||
                  toBeEditEvent.depIds?.length < 1 ||
                  !toBeEditEvent.topic
                ) {
                  Swal.fire({
                    icon: 'warning',
                    text: t('events.addHeaderText'),
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                  })
                } else {
                  if (toBeEditEvent.isRecurrence) {
                    if (
                      toBeEditEvent.recurrence?.startsOn &&
                      toBeEditEvent.recurrence.endsOn &&
                      toBeEditEvent.recurrence.days &&
                      toBeEditEvent.recurrence.interval
                    ) {
                      setShowModal(true)
                    } else {
                      Swal.fire({
                        icon: 'warning',
                        text: t('events.addHeaderText'),
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      })
                    }
                  } else {
                    if (toBeEditEvent.eventType === EVENT3_TYPE.BOOKING) {
                      if (
                        toBeEditEvent.times?.length &&
                        toBeEditEvent.times.find(
                          (time) => time.start && time.end,
                        )
                      ) {
                        setShowModal(true)
                      } else {
                        Swal.fire({
                          icon: 'warning',
                          text: t('events.noSetTime'),
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      }
                    } else {
                      setShowModal(true)
                    }
                  }
                }
              }}
            />
          </div>
          <PublishEventModal
            newEvent={toBeEditEvent}
            departments={departmentsForMultiSchool ?? []}
            showModal={showModal}
            setShowModal={setShowModal}
            onChange={setToBeEditEvent}
            onDone={() => {}}
            onSave={() => {}}
            dataAction={{
              eventTimeId: fetchedEventDetail.eventTimeId,
              eventGroupInfoId: fetchedEventDetail.eventGroupInfoId,
            }}
          />
        </div>
      </div>
    </div>
  )
})

export default EditEventPopUp
