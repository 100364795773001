import ModalRegular from '../../../components/ModalRegular'
import React, { useContext, useEffect, useState } from 'react'
import { KidsStoreContext } from '../../../store/kidsStore'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { UserStoreContext } from '../../../store/userStore'
import { DepartmentChooser } from '../../documents/components/DepartmentChooser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { getImageOrPlaceHolder } from '../../../utils/parseUtils'
import { SearchKids } from '../../ourKids/OurKids'
import { Kid } from '../../../Models/Kid'
import { Button } from '../../../components/Button'
import { observer, Observer } from 'mobx-react'
import { t } from 'i18next'

export const KidsModal: React.FC<{
  showModal: boolean
  onClose: any
  onSave: any
  initialKids: Kid[]
}> = observer(({ showModal, onClose, onSave, initialKids }) => {
  const { currentUserRole } = useContext(UserStoreContext)
  const [search, setSearch] = useState('')
  const [selectedKids, setSelectedKids] = useState<Kid[]>([])
  const { fetchKids, kids } = useContext(KidsStoreContext)
  const { departments, selectedDepartment, fetchDepartments } = useContext(
    DepartmentStoreContext,
  )

  useEffect(() => {
    setSelectedKids(initialKids)
  }, [initialKids])

  useEffect(() => {
    if (currentUserRole && currentUserRole?.school_pointer) {
      fetchKids(currentUserRole)
      fetchDepartments(currentUserRole?.attributes?.school_pointer?.id)
    }
  }, [currentUserRole?.id])

  return (
    <ModalRegular title={''} onClose={onClose} isOpen={showModal}>
      <div className={'w-full flex flex-1 flex-wrap my-10 relative'}>
        <div
          className={'flex sm:flex-1 flex-wrap justify-center gap-x-3 gap-y-3'}
        >
          <SearchKids
            onSearch={(s: string) => {
              setSearch(s)
            }}
          />
          <DepartmentChooser />
          <Button
            size="md"
            label={t('general.save')}
            onClick={() => {
              onSave(selectedKids)
            }}
          />
        </div>
      </div>

      <div className={'flex justify-center w-full flex-col'}>
        {departments
          ?.filter((dep) => {
            if (!selectedDepartment) {
              return true
            }

            return selectedDepartment?.id === dep.id
          })
          .map((department) => {
            return (
              <div
                className={'flex flex-col items-center '}
                key={department?.id}
              >
                <p
                  className={
                    'py-3 bg-gray-200 text-center w-full max-w-2xl rounded-xl'
                  }
                >
                  {department?.attributes?.klassName}
                </p>
                <div className={'flex justify-center flex-wrap max-w-4xl py-3'}>
                  {kids
                    ?.filter((k) => {
                      if (search.length) {
                        return (
                          k?.attributes?.departmentPointer?.id ===
                            department?.id &&
                          k?.attributes?.kidName
                            ?.toLowerCase()
                            .includes(search.toLowerCase())
                        )
                      }
                      return (
                        k?.attributes?.departmentPointer?.id === department?.id
                      )
                    })
                    .map((kid) => {
                      return (
                        <div
                          key={kid?.id}
                          onClick={() => {
                            if (
                              !selectedKids.find((k: Kid) => k?.id === kid?.id)
                            ) {
                              setSelectedKids([...selectedKids, kid])
                            } else {
                              setSelectedKids(
                                selectedKids.filter((k) => k.id !== kid?.id),
                              )
                            }
                          }}
                          className={
                            'flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:scale-110 relative'
                          }
                        >
                          {selectedKids.find((k: Kid) => k?.id === kid?.id) && (
                            <FontAwesomeIcon
                              className="absolute top-5 right-3 text-eventsMain"
                              icon={faCircleCheck}
                            />
                          )}
                          <img
                            className={
                              'w-[60px] h-[60px] rounded-full object-cover border'
                            }
                            src={getImageOrPlaceHolder(
                              kid?.attributes?.kidImage?._url,
                            )}
                            alt=""
                          />

                          <p>{kid?.attributes.kidName}</p>
                        </div>
                      )
                    })}
                </div>
              </div>
            )
          })}
      </div>
    </ModalRegular>
  )
})
