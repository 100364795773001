import React, { useState } from 'react'
import { BloggDoc } from '../../../Models/BloggDoc'
import moment, { Moment as MomentType } from 'moment'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/sv'
import { SORT_VALUES } from '../Statistics'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownAZ, faFilter } from '@fortawesome/free-solid-svg-icons'
import { ButtonDropdown } from '../../../components/Dropdown'

export const SortBy = ({
  sortByOptions,
  sortBy,
  onChange,
}: {
  sortByOptions: any
  sortBy: string
  onChange: (value: any) => void
}) => {
  const [filterOpen, setFilterOpen] = useState(false)
  const [menuRefFilter, togglerRefFilter] = useClickOutside(() => {
    setFilterOpen(false)
  })
  return (
    <>
      <div className="flex flex-row  gap-x-2 pr-3">
        <div className="flex items-center">
          {(() => {
            const currentOption = sortByOptions.find(
              (option: any) => option.value === sortBy,
            )
            if (currentOption) {
              return (
                <>
                  <FontAwesomeIcon icon={currentOption.icon} />
                  <span className="ml-2 text-2xs">{currentOption.label}</span>
                </>
              )
            } else {
              return <FontAwesomeIcon icon={faArrowDownAZ} />
            }
          })()}
        </div>

        {/*<div className="font-semibold">
                        {sortByOptions.find((option) => option.value === sortBy)
                          ?.label || t('statistics.firstNameAsc')}
                        </div>*/}
        <div className="relative flex items-end">
          <button
            ref={togglerRefFilter}
            type="button"
            className="w-full rounded-md group focus:outline-none"
            id={'options-menu'}
            aria-haspopup="true"
            aria-expanded={filterOpen}
            onClick={() => setFilterOpen((prev) => !prev)}
          >
            <span className="flex items-center justify-between w-full">
              <span className="flex items-center justify-between min-w-0">
                <div className="text-xs group-hover:text-blue-50">
                  <FontAwesomeIcon icon={faFilter} className="text-gray-600" />
                </div>
              </span>
              <svg
                className="flex-shrink-0 w-5 h-5 text-transparent group-hover:text-gray-600"
                x-description="Heroicon name: selector"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
          </button>

          {filterOpen && (
            <div
              className="absolute left-0 z-50 mx-3 origin-top divide-y w-52 top-10"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby={'options-menu'}
              ref={menuRefFilter}
            >
              <ButtonDropdown.Menu>
                <ButtonDropdown.Section>
                  {sortByOptions
                    .filter((sort: any) => sort.value === sortBy)
                    .map((option: any) => {
                      return (
                        <div>
                          <ButtonDropdown.Button
                            key={option.value}
                            label={option.label}
                            icon={option.icon}
                            onClick={() => {
                              onChange(option.value)
                              setFilterOpen(false)
                            }}
                          />
                        </div>
                      )
                    })}
                  {sortByOptions
                    .filter((sort: any) => sort.value !== sortBy)
                    .map((option: any) => {
                      return (
                        <div>
                          <ButtonDropdown.Button
                            key={option.value}
                            label={option.label}
                            icon={option.icon}
                            onClick={(e) => {
                              e.preventDefault()
                              onChange(option.value)
                              setFilterOpen(false)
                            }}
                          />
                        </div>
                      )
                    })}
                </ButtonDropdown.Section>
              </ButtonDropdown.Menu>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
