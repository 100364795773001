import classNames from 'classnames'
import React, { forwardRef, useCallback } from 'react'
import { useDrop } from 'react-dnd'
import DraggableItem from './DraggableItem'
import { useTranslation } from 'react-i18next'
//import { t } from 'i18next'

interface StartingContainerProps {
  id: string
  items: {
    id: string
    content: string
    type: string
    bgColor?: string
    subHeader?: string
    tag?: any
  }[]
  onDrop: (id: string, targetId: string, containersNew: any) => void
  containersNew: any
}

const StartingContainer = forwardRef<HTMLDivElement, StartingContainerProps>(
  ({ id, items, onDrop, containersNew }, ref) => {
    var items22: any = containersNew[0]?.items
    const { t } = useTranslation()
    const [{ isOver }, drop] = useDrop(() => ({
      accept: ['text', 'image', 'curriculums', 'tag', 'pdf'],
      drop: (item: any) => {
        onDrop(item.id, id, items22)
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }))

    //monitor.getItem().onDrop(id, index)

    const getCorrectClasss = (type: string) => {}
    return (
      <div
        //ref={drop}
        className="fixed top-[60px] grid grid-cols-1 gap-4 max-w-[30%] overflow-y-scroll h-[calc(100vh-60px)] mb-[60px]"
        style={{
          padding: '16px',
          minHeight: '100px',
          //background: isOver ? 'red' : 'white',
          background: 'white',
        }}
      >
        {!items.length && (
          <div
            className={
              'flex flex-col text-center justify-center items-center space-y-4 text-lg sm:text-2xl text-gray-300 font-bold my-44'
            }
          >
            <p>{t('ourKids.noContactInfo')}</p>
          </div>
        )}
        {items.map((item) => (
          <div
            className={classNames(
              '',
              item.type === 'line' || item.type === 'header'
                ? ''
                : item.type === 'image'
                ? '' //'max-h-[150px]'
                : '', //'border border-gray p-2',
            )}
          >
            <DraggableItem
              key={item.id}
              id={item.id}
              content={item.content}
              type={item.type}
              bgColor={item.bgColor}
              subHeader={item.subHeader}
              tag={item.tag}
            />
          </div>
        ))}
      </div>
    )
  },
)
export default StartingContainer
