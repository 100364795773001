import React from 'react'
import { makeAutoObservable } from 'mobx'
import Parse from 'parse'
import { DocTag } from '../Models/DocTag'
import { School } from '../Models/School'
import { userStore } from './userStore'
import { Tag, TagInterface } from '../Models/Tag'
import { EVENT3_TYPE } from './event3Store'
import { ARCHIVE_STATE } from '../Enums/TyraEnums'

class TagStore {
  school?: School
  tagItems?: DocTag[]
  defaultTags?: DocTag[]
  customTags?: DocTag[]
  tagsForEvent?: Tag[]
  informationTags?: Tag[]
  importantInformationTags?: Tag[]
  activityTags?: Tag[]
  groupMeetingTags?: Tag[]
  meetingTags?: Tag[]
  allTagsForEvent?: Tag[]
  constructor() {
    makeAutoObservable(this)
  }

  setAllTagsForEvent = (tags: Tag[]) => {
    this.allTagsForEvent = tags
  }

  setInformationTags = (tags: Tag[]) => {
    this.informationTags = tags
  }
  setImportantInformationTags = (tags: Tag[]) => {
    this.importantInformationTags = tags
  }
  setActivityTags = (tags: Tag[]) => {
    this.activityTags = tags
  }
  setGroupMeetingTags = (tags: Tag[]) => {
    this.groupMeetingTags = tags
  }
  setMeetingTags = (tags: Tag[]) => {
    this.meetingTags = tags
  }

  setTagsForEvent = (tags: Tag[]) => {
    this.tagsForEvent = tags
  }
  setDefaultTags = (defaultTags: DocTag[]) => {
    this.defaultTags = defaultTags
  }
  setCustomTags = (customTags: DocTag[]) => {
    this.customTags = customTags
  }
  createCustomTags = async (schoolId: string, values: any) => {
    const newTags = new DocTag()
    const currentUserRole = userStore.currentUserRole

    newTags.set('is_base', values.is_base)
    newTags.set('name', values.name)
    newTags.set('bg_color', values.bg_color)
    newTags.set('createdAt', values.createdAt)
    newTags.set('updatedAt', values.updatedAt)
    newTags.set('school_pointer', currentUserRole?.school_pointer)
    newTags.set('school', currentUserRole?.school)
    newTags.set('order', values.order)
    newTags.set('is_base', false)
    try {
      await newTags.save()
      console.log('Tag created successfully')
    } catch (error) {
      console.log('Error creating tag: ', error)
    }
  }
  editCustomTag = async (tag: DocTag) => {
    await tag.save()
  }
  deleteTag = async (tagId: string) => {
    const tagToDelete = DocTag.createWithoutData(tagId)
    try {
      await tagToDelete.destroy()
      console.log('Tag togs borrt')
    } catch (error) {
      console.log('Error delete: ', error)
    }
  }

  fetchDefultTags = async (schoolId: string) => {
    const docQuery = new Parse.Query(DocTag).equalTo('is_base', true)
    const tags = await docQuery.find()
    this.setDefaultTags(tags)
  }
  fetchCustomTags = async (schoolId: string) => {
    const docQuery = new Parse.Query(DocTag)
      .equalTo('school_pointer', School.createWithoutData(schoolId))
      .equalTo('is_base', false)
      .doesNotExist('archive_state')
    const tags = await docQuery.find()
    this.setCustomTags(tags)
  }

  fetchTagsForEvent = async (
    schoolIds: string[],
    category: string,
    onlyStaff: boolean,
  ) => {
    const tagQuery = new Parse.Query(Tag)
    tagQuery
      .containedIn(
        Tag.Fields.SCHOOL,
        schoolIds.map((schoolId) => School.createWithoutData(schoolId)),
      )
      .equalTo(Tag.Fields.CATEGORY, category)
      .equalTo(Tag.Fields.TYPE, 'events')
      .equalTo(Tag.Fields.ONLY_STAFF, onlyStaff)
      .doesNotExist(Tag.Fields.ARCHIVE_STATE)
    const tags = await tagQuery.find()
    this.setTagsForEvent(tags)
  }

  fetchAllTagsForEvent = async (schoolIds: string[]) => {
    const tagQuery = new Parse.Query(Tag)
    tagQuery
      .containedIn(
        Tag.Fields.SCHOOL,
        schoolIds.map((schoolId) => School.createWithoutData(schoolId)),
      )
      .equalTo(Tag.Fields.TYPE, 'events')
      .doesNotExist(Tag.Fields.ARCHIVE_STATE)
    const tags = await tagQuery.find()
    this.setAllTagsForEvent(tags)
    this.setInformationTags(
      tags.filter((tag) => tag.category === EVENT3_TYPE.INFO),
    )
    this.setImportantInformationTags(
      tags.filter((tag) => tag.category === EVENT3_TYPE.IMPORTANT),
    )
    this.setActivityTags(
      tags.filter((tag) => tag.category === EVENT3_TYPE.ACTIVITIES),
    )
    this.setGroupMeetingTags(
      tags.filter((tag) => tag.category === EVENT3_TYPE.BOOKING),
    )
    this.setMeetingTags(
      tags.filter((tag) => tag.category === EVENT3_TYPE.MEETING),
    )
  }
  createTagForEvent = async (schoolId: string, values: any) => {
    const newTags = new Tag()
    const currentUserRole = userStore.currentUserRole

    newTags.set('name', values.name)
    newTags.set('color', values.color)
    newTags.set('createdAt', new Date())
    newTags.set('updatedAt', new Date())
    newTags.set('school_pointer', currentUserRole?.school_pointer)
    newTags.set('school', currentUserRole?.school)
    newTags.set('is_base', false)
    newTags.set('type', 'events')
    newTags.set('category', values.category)
    newTags.set('only_staff', values.only_staff)
    try {
      await newTags.save()
      console.log('Tag created successfully')
    } catch (error) {
      console.log('Error creating tag: ', error)
    }
  }

  editTagForEvent = async (tag: TagInterface) => {
    const tagToEdit = this.allTagsForEvent?.find((t) => t.id === tag.id)

    if (tagToEdit) {
      tagToEdit.color = tag.color
      await tagToEdit.save()
    }
  }

  archiveTagForEvent = async (tag: TagInterface) => {
    const tagToEdit = this.allTagsForEvent?.find((t) => t.id === tag.id)
    const currentUserRole = userStore.currentUserRole
    if (tagToEdit) {
      tagToEdit.archive_state = ARCHIVE_STATE.HISTORIC
      tagToEdit.archived_at = new Date()
      tagToEdit.archived_by = currentUserRole
      await tagToEdit.save()
    }
  }
}

export const tagStore = (() => {
  return new TagStore()
})()
export const TagStoreContext: React.Context<TagStore> =
  React.createContext(tagStore)
