import React, { useContext } from 'react'
import { DocumentationStoreContext } from '../../../store/documentationStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faTrash } from '@fortawesome/free-solid-svg-icons'
import { AddFileModal } from './AddFileModal'
import classNames from 'classnames'

export const Images = ({
  card,
  editMode,
  setSelectedImage,
  setShowFullScreenModal,
  onAdd,
  onRemove,
  onRemoveCard,
}: {
  card: any
  editMode: boolean
  setShowFullScreenModal: any
  setSelectedImage: any
  onAdd: any
  onRemove: any
  onRemoveCard: any
}) => {
  const { removeDocumentationFile } = useContext(DocumentationStoreContext)
  return (
    <div
      className={'flex flex-wrap justify-center min-h-[200px] border-b pb-3 pb'}
    >
      <div className={'flex justify-between w-full'}>
        <p className={'w-full text-eventsMain'}>
          <span className={'mr-2'}>
            <FontAwesomeIcon icon={faImage} />
          </span>
          Bilder ({card?.attributes?.images?.length ?? 0})
        </p>
        {editMode && (
          <FontAwesomeIcon
            icon={faTrash}
            className={'text-gray-400 hover:text-red-500 cursor-pointer'}
            onClick={async () => {
              if (window.confirm('Är du säker du vill ta bort?')) {
                onRemoveCard()
              }
            }}
          />
        )}
      </div>
      {editMode && (
        <AddFileModal
          cardId={card?.id}
          imageType={'image'}
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
          }}
          onFileAdded={onAdd}
          buttonClassName={'text-eventsMain'}
        />
      )}
      {card?.attributes?.images?.map((image: any) => {
        return (
          <div className={'relative'} key={image?.id}>
            {editMode && (
              <div
                onClick={async () => {
                  if (window.confirm('Är du säker du vill ta bort?')) {
                    await removeDocumentationFile({
                      cardId: card?.id,
                      fileId: image?.id,
                      imageType: 'image',
                    })
                    onRemove()
                  }
                }}
                className={classNames(
                  'absolute right-5 top-5 h-[30px] w-[30px] rounded-2xl bg-white flex justify-center items-center',
                  'hover:bg-red-200 cursor-pointer text-gray-500 hover:text-red-500',
                )}
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            )}
            <img
              className={
                'aspect-square mr-3 mt-3 h-[196px] object-cover rounded-2xl cursor-pointer'
              }
              onClick={() => {
                setSelectedImage(image?.attributes?.image?._url)
                setShowFullScreenModal(true)
              }}
              src={image?.attributes?.image?._url}
              alt=""
            />
          </div>
        )
      })}
    </div>
  )
}
