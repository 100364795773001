import React from 'react'

interface VideoPlayerProps {
  src: string
  onClose: () => void
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <video className="w-[100%] h-[100%]">
        <source src={props.src} type="video/mp4" />
        Din webbläsare stöder inte videotaggen.
      </video>
    </div>
  )
}

export default VideoPlayer
