import classNames from 'classnames'
import React, { forwardRef, useCallback, useContext, useState } from 'react'
import { useDrop } from 'react-dnd'
import { DragAndDropStoreContext } from '../../../store/dragAndDropStore'
import DraggableItem from './DraggableItem'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'

interface DroppableContainerProps {
  id: string
  items?: {
    id: string
    content: string
    type: string
    size?: string
    bgColor?: string
    tag?: any
  }[]
  onDrop: (id: string, targetId: string) => void
  children: React.ReactNode
  addRowAvailable: boolean
  addRow: () => void
  page: any
  allItems: any
  handleRemove: (itemId: string) => void
}

const DroppableContainer = forwardRef<HTMLDivElement, DroppableContainerProps>(
  (
    {
      id,
      items,
      onDrop,
      children,
      addRowAvailable,
      addRow,
      page,
      allItems,
      handleRemove,
    },
    ref,
  ) => {
    const ref2: any = React.createRef()
    const { dragging } = useContext(DragAndDropStoreContext)
    const [contentOutside, setContentOutside] = useState<boolean>(false)
    const { t } = useTranslation()
    /*React.useLayoutEffect(() => {
      if (
        ref2.current.clientWidth < ref2.current.scrollWidth ||
        ref2.current.clientHeight < ref2.current.scrollHeight
      ) {
        setContentOutside(true)
      } else {
        setContentOutside(false)
      }
    }, [ref2])*/
    const [{ isOver }, drop] = useDrop(() => ({
      accept: ['text', 'image', 'curriculums', 'tag', 'pdf'],
      drop: (item: any) =>
        /*item.type === 'pdf'
          ? page.allItemContainers.length === 1
            ? onDrop(item.id, id)
            : alert('fel')
          : onDrop(item.id, id),*/
        allItems?.find((it: any) => it.id == item.id)?.type === 'pdf'
          ? page.allItemContainers.length === 1
            ? Swal.fire({
                title: t('general.obs'),
                text: t('export.infoPDFPage'),
                icon: 'info',
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonColor: '#7eb5f4',
                cancelButtonColor: '#d33',
                confirmButtonText: t('general.ok'),
              }).then(() => {
                onDrop(item.id, id)
              })
            : Swal.fire({
                text: t('export.warningPDFPage'),
                icon: 'warning',
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonColor: '#7eb5f4',
                cancelButtonColor: '#d33',
                confirmButtonText: t('general.ok'),
              })
          : onDrop(item.id, id),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }))

    //monitor.getItem().onDrop(id, index)

    return (
      <div
        //ref={ref2}
        ref={drop}
        className="flex flex-wrap gap-2 overflow-hidden relative"
        style={{
          //padding: '16px',
          minHeight: '100px',
          background: isOver ? 'orange' : dragging ? 'white' : 'white',
        }}
      >
        {contentOutside && (
          <div className="absolute top-0 left-0 w-full h-full bg-red-400 opacity-40"></div>
        )}
        {/*<div ref={drop} className="w-full h-full flex flex-wrap relative gap-2">*/}
        <div
          ref={drop}
          className="w-auto h-auto max-w-full max-h-full relative gap-2"
        >
          {items?.map((item) => (
            <DraggableItem
              key={item.id}
              id={item.id}
              content={item.content}
              type={item.type}
              size={item.size}
              bgColor={item.bgColor}
              tag={item.tag}
              onClick={() => handleRemove(item.id)}
            />
          ))}
          {/*addRowAvailable && <div onClick={() => addRow()}>Klicka på mig</div>*/}
        </div>
      </div>
    )
  },
)
export default DroppableContainer
