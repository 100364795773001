import { observer } from 'mobx-react'

import { EventListItemData } from '../Events3'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faXmark } from '@fortawesome/free-solid-svg-icons'
import { IEventDetails } from '../EventDetailPage'
import { useState } from 'react'
import { OverviewUsersList, User } from './OverviewUsersList'
import { useTranslation } from 'react-i18next'

interface Iprops {
  eventDetail?: EventListItemData
  isOverviewVisible: boolean
  setOverviewVisible: (isOverviewVisible: boolean) => void
  eventOverview?: IEventDetails
  refreshDetail: () => void
}

const getUniqueDepartments = (users?: User[]): (string | undefined)[] => {
  const allDepartments = users?.flatMap((user) => user.departmentNames)
  return Array.from(new Set(allDepartments))
}

export const EventOverview: React.FC<Iprops> = observer(
  ({
    eventDetail,
    setOverviewVisible,
    isOverviewVisible,
    eventOverview,
    refreshDetail,
  }) => {
    const infoType = eventDetail?.type === 'info'
    const importantInfoType = eventDetail?.type === 'important'
    const activityType = eventDetail?.type === 'activities'
    const meetingType = eventDetail?.type === 'meeting'
    const bookingType = eventDetail?.type === 'booking'

    const { t } = useTranslation()

    const [filter, setFilter] = useState<boolean>(false)
    const [filterDepartments, setFilterDepartments] = useState<
      (string | undefined)[]
    >([])

    const departments = getUniqueDepartments([
      ...(eventOverview?.readUsers || []),
      ...(eventOverview?.unreadUsers || []),
    ])

    const handleDepartmentChange = (department: string | undefined) => {
      setFilterDepartments((prevState) =>
        prevState.includes(department)
          ? prevState.filter((d) => d !== department)
          : [...prevState, department],
      )
    }

    const handleSelectAllChange = () => {
      if (filterDepartments.length === departments.length) {
        setFilterDepartments([])
      } else {
        setFilterDepartments(departments)
      }
    }

    const isAllSelected = filterDepartments.length === departments.length

    return (
      <>
        <div className="fixed inset-0 flex items-center  justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
          <div
            /* className={`flex flex-col justify-between h-full w-full rounded-md mr-1 overflow-y-auto bg-[#F6F6F6]`} */
            className="bg-[#F6F6F6] h-[60%] my-auto rounded-lg shadow-lg sm:w-3/5"
          >
            <div
              id="event-header"
              className={classNames(
                `w-full flex justify-between items-center h-[60px] rounded-t-lg ${
                  infoType
                    ? 'bg-[#7eb5f4]'
                    : importantInfoType
                    ? 'bg-[#f26d6d]'
                    : activityType
                    ? 'bg-[#6BBD73]'
                    : meetingType
                    ? 'bg-[#ffa948]'
                    : bookingType
                    ? 'bg-[#E1A6F0]'
                    : 'bg-white'
                }`,
              )}
            >
              <div className="flex justify-between items-center w-full px-3">
                <div className="flex items-center pr-3">
                  <FontAwesomeIcon
                    icon={faFilter}
                    size="lg"
                    className={`text-white cursor-pointer`}
                    onClick={(e) => {
                      e.stopPropagation()
                      setFilter(!filter)
                    }}
                  />
                </div>
                <div className="text-lg font-bold text-white">
                  {t('documents.overview')}
                </div>
                <div className="flex items-center pl-3">
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="lg"
                    className="text-white cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation()
                      setOverviewVisible(!isOverviewVisible)
                    }}
                  />
                </div>
              </div>
            </div>

            <div id="filter" className="flex  h-[88%] w-full">
              {filter && (
                <div className="p-4 h-full w-full">
                  <div className="flex justify-between">
                    <h1 className="text-sm font-semibold mb-4">
                      {t('admin.departments')}{' '}
                    </h1>
                    <span className="flex justify-end font-semibold text-gray-500 text-sm">
                      {filterDepartments.length}
                      {' / '}
                      {departments.length}
                    </span>
                  </div>
                  <div className="space-y-2">
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleSelectAllChange}
                        onClick={(e) => e.stopPropagation()}
                        className="form-checkbox h-4 w-4 text-indigo-600"
                      />
                      <span className="font-semibold text-sm">
                        !!Select All
                      </span>
                    </label>
                    {departments.map((department) => (
                      <label
                        key={department}
                        className="flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          checked={filterDepartments.includes(department)}
                          onChange={() => handleDepartmentChange(department)}
                          onClick={(e) => e.stopPropagation()}
                          className="form-checkbox h-4 w-4 text-indigo-600 text-sm"
                        />
                        <span className="font-semibold text-sm">
                          {department === null ? 'Inga barn' : department}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              )}
              <div
                className={`${
                  filter ? 'hidden' : 'block w-full overflow-y-hidden'
                }`}
              >
                <OverviewUsersList
                  filterDepartments={filterDepartments}
                  eventOverview={eventOverview}
                  eventDetail={eventDetail}
                  refreshDetail={refreshDetail}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  },
)
