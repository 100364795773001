import { useParams } from 'react-router-dom'

export function VideoPlaybackPage() {
  const { videoUrlBase64 } = useParams<{ videoUrlBase64?: string }>()

  if (!videoUrlBase64) {
    return <div>Error: Video URL not provided!</div>
  }

  const videoUrl = atob(videoUrlBase64) // decode from base64

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <video controls width="80%" height="80%">
        <source src={videoUrl} type="video/mp4" />
        ERROR
      </video>
    </div>
  )
}
