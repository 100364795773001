import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import CustomRadioButton from '../../../components/CustomRadioButton'
import { Button } from '../../../components/Button'
import {
  CreateEventParams,
  EVENT3_POST_TO,
  EVENT3_TYPE,
} from '../../../store/event3Store'
import { usePostToSelections } from '../CreateEvent3'
import { Department } from '../../../Models/Department'
import { School } from '../../../Models/School'
import { useTranslation } from 'react-i18next'

interface Props {
  newEvent: CreateEventParams
  departments: Department[]
  schools?: School[]
  onChange: (e: CreateEventParams) => void
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
}

interface Props2 {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  departments: Department[]
  schools?: School[]
}

export const PostEventToSelections: React.FC<Props2> = (props: Props2) => {
  const postToSelections = usePostToSelections()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-row items-center justify-start w-full ">
        <h2 className="text-xl font-bold text-left">
          {t('events.postToEvent')}
        </h2>
        <div>
          {props.departments.length === props.newEvent.depIds?.length ? (
            <h4
              className="text-blue-500 select-none cursor-pointer font-semibold rounded-full border border-white w-32 text-sm pl-4"
              onClick={() => {
                props.onChange({
                  ...props.newEvent,
                  depIds: [],
                })
              }}
            >
              <>{t('multiselect.deSelectAll')}</>
            </h4>
          ) : (
            <h4
              className="text-blue-500 select-none cursor-pointer font-semibold rounded-full border border-white w-32 text-sm pl-4"
              onClick={() => {
                props.onChange({
                  ...props.newEvent,
                  depIds: props.departments.map((dep) => dep.id),
                })
              }}
            >
              <>{t('settings.markAll')}</>
            </h4>
          )}
        </div>
      </div>
      <div className="mb-4">
        {props.newEvent.multiSchools ? (
          <>
            {props.newEvent.multiSchools.map((multiSchoolId) => {
              return (
                <div className="flex flex-col gap-y-1">
                  <div className="flex-1 w-full mt-2">
                    <b>
                      {
                        props.schools?.find(
                          (school) => school.id === multiSchoolId,
                        )?.display_school_name
                      }
                    </b>
                  </div>
                  <div className="flex flex-1 w-full gap-2 mb-4">
                    {props.departments
                      .filter((dep) => dep.school_pointer.id === multiSchoolId)
                      .map((dep) => {
                        return (
                          <Button
                            size="sm"
                            variant={
                              props.newEvent.depIds?.find(
                                (depId) => depId === dep.id,
                              )
                                ? 'fullBlue'
                                : 'default'
                            }
                            label={dep.klassName}
                            onClick={() => {
                              let chosenDepIds = props.newEvent.depIds

                              if (
                                chosenDepIds?.find((depId) => depId === dep.id)
                              ) {
                                // Remove from array
                                chosenDepIds = chosenDepIds.filter(
                                  (depId) => depId !== dep.id,
                                )
                              } else {
                                // Add to array
                                chosenDepIds?.push(dep.id)
                              }
                              props.onChange({
                                ...props.newEvent,
                                depIds: chosenDepIds,
                              })
                            }}
                          />
                        )
                      })}
                  </div>
                </div>
              )
            })}
          </>
        ) : (
          <div className="flex whitespace-nowrap  gap-2 mb-3 py-1 h-[50px] overflow-auto scrollbar-hide">
            {props.departments.map((dep) => {
              return (
                <Button
                  key={dep.id}
                  size="sm"
                  variant={
                    props.newEvent.depIds?.find((depId) => depId === dep.id)
                      ? 'lightPurple'
                      : 'fullWhiteGrayText'
                  }
                  label={dep.klassName}
                  onClick={() => {
                    let chosenDepIds = props.newEvent.depIds

                    if (chosenDepIds?.find((depId) => depId === dep.id)) {
                      // Remove from array
                      chosenDepIds = chosenDepIds.filter(
                        (depId) => depId !== dep.id,
                      )
                    } else {
                      // Add to array
                      chosenDepIds?.push(dep.id)
                    }
                    props.onChange({
                      ...props.newEvent,
                      depIds: chosenDepIds,
                    })
                  }}
                />
              )
            })}
          </div>
        )}
        <CustomRadioButton
          options={postToSelections}
          disable={props.newEvent.eventType === EVENT3_TYPE.BOOKING}
          onChange={(value) => {
            if (value === EVENT3_POST_TO.ALL) {
              props.onChange({
                ...props.newEvent,
                postTo: value,
                onlyStaff: undefined,
                destKids: undefined,
                destRoles: undefined,
              })
            } else if (value === EVENT3_POST_TO.ONLY_STAFF) {
              props.onChange({
                ...props.newEvent,
                postTo:
                  props.newEvent.postTo === value ? EVENT3_POST_TO.ALL : value,
                onlyStaff: props.newEvent.onlyStaff ? false : true,
                destKids: undefined,
                destRoles: undefined,
              })
            } else if (value === EVENT3_POST_TO.SPECIFIC_USERS) {
              props.onChange({
                ...props.newEvent,
                postTo: value,
                onlyStaff: undefined,
                destKids: [],
                destRoles: [],
              })
            }
          }}
          value={props.newEvent.postTo!!}
        />
      </div>
    </div>
  )
}

export const PostEventTo: React.FC<Props> = observer((props: Props) => {
  const [openSelections, setOpenSelections] = useState(false)

  return (
    <>
      <div onClick={() => setOpenSelections(!openSelections)}>
        <div className="flex flex-col gap-y-2">
          <div className="font-bold">
            <>{t('events.postToEvent')}:</>
          </div>

          {props.newEvent.multiSchools ? (
            <>
              {props.newEvent.multiSchools.map((multiSchoolId) => (
                <div key={multiSchoolId} className="flex flex-col gap-y-1">
                  <div className="flex-1 w-full mt-2">
                    <b>
                      {
                        props.schools?.find(
                          (school) => school.id === multiSchoolId,
                        )?.display_school_name
                      }
                    </b>
                  </div>
                  <div className="flex flex-1 w-full gap-2">
                    {props.departments
                      .filter((dep) => dep.school_pointer.id === multiSchoolId)
                      .map(
                        (dep) =>
                          props.newEvent.depIds?.includes(dep.id) && (
                            <div
                              key={dep.id}
                              className="text-sm bg-blue-100 rounded-full px-2"
                            >
                              {dep.klassName}
                            </div>
                          ),
                      )}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="flex flex-row flex-wrap w-full gap-2">
              {props.departments.map(
                (dep) =>
                  props.newEvent.depIds?.includes(dep.id) && (
                    <>
                      <Button
                        size="xs"
                        variant={
                          props.newEvent.depIds?.find(
                            (depId) => depId === dep.id,
                          )
                            ? 'lightPurple'
                            : 'fullWhiteGrayText'
                        }
                        label={dep.klassName}
                        onClick={() => {}}
                      />
                    </>
                  ),
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
})
