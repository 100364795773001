import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { LoadingBar } from '../../../../components/LoadingBar'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { StatusBox } from '../invitedUsers/components/StatusBox'
import { Button } from '../../../../components/Button'
import Swal from 'sweetalert2'
import { t } from 'i18next'
import { UserRole } from '../../../../Models/UserRole'
import { AdminStoreContext } from '../../../../store/adminStore'
import { useParams } from 'react-router-dom'
import {
  getImageFromUser,
  getUserNameFromRole,
} from '../../../../utils/parseUtils'
import KidCard from './KidCard'

export const VHCard: React.FC<{
  children?: React.ReactNode
  description?: string
  title?: string
  loading?: boolean
  className?: string
  icon?: IconDefinition
  iconClassName?: string
  titleContainerClassName?: string
  onClick?: () => void
  activeState?: boolean
  userRole: UserRole
  allUserRolesWithKid?: UserRole[]
  allUserRolesForUser?: UserRole[]
  type?: 'paused' | 'active' | 'waiting' | 'invited'
}> = ({
  children,
  description,
  title,
  loading,
  className,
  icon,
  iconClassName,
  titleContainerClassName,
  onClick,
  activeState,
  userRole,
  allUserRolesWithKid,
  type,
  allUserRolesForUser,
}) => {
  const { activateUserRole, fetchPausedUserRoles } =
    useContext(AdminStoreContext)

  const { schoolId } = useParams()

  return (
    <div
      className={classNames(
        ' min-h-[60px] relative  overflow-hidden rounded-2xl shadow-tyraShadow m-4 p-4 cursor-pointer',
      )}
      onClick={onClick}
    >
      {loading && <LoadingBar />}
      <div
        className={classNames(
          'flex space-x-4 rounded-b-2xl items-center font-bold font-sans bg-white text-neutral-600 relative',
          { 'pb-10': type !== 'paused' },
        )}
      >
        <div
          className={classNames(
            'flex flex-wrap py-3 font-medium justify-start w-full h-full',
            className,
            { 'bg-transparent text-gray-500': activeState },
          )}
        >
          <>
            <div className="flex flex-row w-full  ml-2">
              <label className="relative flex items-center justify-center border rounded-full max-w-[60px] bg-gray-300  max-h-[60px]  min-w-[60px]  min-h-[60px] overflow-hidden">
                <img
                  className={'w-[60px] h-[60px] object-cover rounded-full'}
                  src={getImageFromUser(userRole.user)}
                  alt=""
                />
              </label>
              <div className="flex flex-col w-full justify-center pl-2">
                <h1 className="font-bold">
                  {type === 'invited'
                    ? userRole.invite_note
                    : userRole.user?.firstName + ' ' + userRole.user?.lastName}
                </h1>
                <h2 className="font-thin text-gray-600">
                  {/* TODO: usernamet visas inte på alla, varför? */}
                  {type === 'active' && <>{getUserNameFromRole(userRole)}</>}
                  {type === 'invited' && <>{getUserNameFromRole(userRole)}</>}
                  {type === 'waiting' && <>{getUserNameFromRole(userRole)}</>}
                </h2>
              </div>
            </div>
            <div
              className={classNames(
                'flex flex-wrap justify-center w-full',
                titleContainerClassName,
              )}
            >
              {title}
            </div>

            <div
              className={classNames(
                'flex flex-wrap justify-center w-full',
                titleContainerClassName,
              )}
            >
              {title}
            </div>

            <div className="w-4/5 flex flex-col flex-wrap">
              {allUserRolesForUser?.map((uR) => {
                if (!uR.kid) {
                  return null
                }
                return (
                  <KidCard
                    relation={uR.tyraRelation}
                    title={uR.kid?.firstName + ' ' + uR.kid?.lastName}
                    userRole={uR}
                    smallPic={true}
                  />
                )
              })}
            </div>
            {type === 'paused' && (
              <div className="w-full flex flex-wrap justify-center pt-4">
                <div className="flex w-[200px] xs:w-fit font-medium">
                  <StatusBox
                    isActive={
                      allUserRolesForUser?.filter(
                        (uR) => uR.is_upload_document_parent,
                      ) &&
                      allUserRolesForUser?.filter(
                        (uR) => uR.is_upload_document_parent,
                      )?.length > 0
                        ? true
                        : false
                    }
                    label={t('admin.documentUpload')}
                    className={
                      allUserRolesForUser?.filter(
                        (uR) => uR.is_upload_document_parent,
                      ) &&
                      allUserRolesForUser?.filter(
                        (uR) => uR.is_upload_document_parent,
                      )?.length > 0
                        ? 'bg-purple-300 p-1 text-xs w-full shadow border-none'
                        : 'bg-transparent p-1 text-xs w-full shadow'
                    }
                  />
                </div>
              </div>
            )}
            {type === 'paused' && (
              <div className="flex flex-col gap-y-4 w-full lg:flex-row justify-center gap-x-4 mt-4">
                <Button
                  size="custom"
                  variant="fullBlue"
                  label={t('admin.activate')}
                  onClick={() =>
                    Swal.fire({
                      //title: 'Are you sure?',
                      text: t('admin.warningActivate'),
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.yes'),
                      cancelButtonText: t('general.cancel'),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        activateUserRole(allUserRolesForUser ?? []).finally(
                          () => {
                            if (schoolId) {
                              fetchPausedUserRoles()
                            }
                          },
                        )
                      }
                    })
                  }
                />
                <Button
                  size="custom"
                  variant="fullRed"
                  label={t('documents.remove')}
                  onClick={() =>
                    Swal.fire({
                      //title: 'Are you sure?',
                      text: t('absence.noticAreYouSure'),
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.yes'),
                      cancelButtonText: t('general.cancel'),
                    })
                  }
                />
              </div>
            )}
          </>
        </div>
      </div>
      {type === 'active' && (
        <div className="absolute top-1/2 right-3 transform -translate-y-1/2">
          <FontAwesomeIcon className="text-gray-300" icon={faChevronRight} />
        </div>
      )}
      {type === 'invited' && (
        <div className="absolute top-1/2 right-3 transform -translate-y-1/2">
          <FontAwesomeIcon className="text-gray-300" icon={faChevronRight} />
        </div>
      )}
      {type !== 'paused' && (
        <div className="absolute  bottom-5 right-2 w-full flex flex-wrap justify-center pt-4">
          <div className="flex w-[200px] xs:w-fit font-medium">
            <StatusBox
              isActive={
                allUserRolesForUser?.filter(
                  (uR) => uR.is_upload_document_parent,
                ) &&
                allUserRolesForUser?.filter(
                  (uR) => uR.is_upload_document_parent,
                )?.length > 0
                  ? true
                  : false
              }
              label={t('admin.documentUpload')}
              className={
                allUserRolesForUser?.filter(
                  (uR) => uR.is_upload_document_parent,
                ) &&
                allUserRolesForUser?.filter(
                  (uR) => uR.is_upload_document_parent,
                )?.length > 0
                  ? 'bg-purple-300 p-1 text-xs w-full shadow border-none'
                  : 'bg-transparent p-1 text-xs w-full shadow'
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}
