import { User } from 'parse'
import { School } from './School'
import { ARCHIVE_STATE } from '../Enums/TyraEnums'
import Parse from 'parse'

export const enum FILE_JOB_TYPE {
  EXPORT = 10,
}

export interface RequestBlogPortfolioObject {
  objectIds: string[] | undefined
  fromDate: Date | undefined
  toDate: Date | undefined
  filter: ExportFilter | undefined
  type: ExportType
  optimizeImages: boolean
  includeReflections: boolean
  includeCurriculums: boolean
  includeTags: boolean
  createPrePage: boolean
  prePageTitle: string | undefined
  pdfName: string | undefined
  languageCode: string | undefined
  schoolId: string | undefined
  enableProclaim: boolean | undefined
}

export interface ExportFilter {
  departmentIds: string[] | undefined
  tagIds: string[] | undefined
  kidIds: string[] | undefined
  textSearch: string | undefined
}

export const enum FILE_JOB_STATUS {
  QUEUED = 10,
  IN_PROGRESS = 20,
  SUCCESS = 30,
  OPENED = 40,
  ERROR = 90,
}
export interface PdfObjectsArray {
  fromDate: Date | undefined
  toDate: Date | undefined
  type: ExportType
  optimizeImages: boolean
  includeReflections: boolean
  includeCurriculums: boolean
  includeTags: boolean
  createPrePage: boolean
  prePageTitle: string[] | undefined
  languageCode: string | undefined
  schoolName: string | undefined
  kidId: string | undefined
  objects: PdfBloggPortfolioObject[]
}
interface PdfObjectTag {
  title: string
  bgColor: string | undefined
}

interface PdfBloggPortfolioObject {
  title: string | undefined
  content: string | undefined
  tags: PdfObjectTag[]
  curriculums: string[]
  reflections: string[]
  imageUrls: string[] | undefined
  authorName: string | undefined
  authorImg: string
  createdAt: Date
}
export enum ExportType {
  PORTFOLIO = 10,
  BLOGG = 20,
}

export class FileJob extends Parse.Object {
  public static readonly Fields = {
    school_pointer: 'school_pointer',
    status: 'status',
    snatch_increment: 'snatch_increment',
    archive_state: 'archive_state',
  }

  constructor() {
    super('file_job')
  }

  get name(): string {
    return this.get('name')
  }
  set name(value: string) {
    this.set('name', value)
  }

  get file(): Parse.File | undefined {
    return this.get('file')
  }
  set file(value: Parse.File | undefined) {
    this.set('file', value)
  }

  get type(): FILE_JOB_TYPE {
    return this.get('type')
  }
  set type(value: FILE_JOB_TYPE) {
    this.set('type', value)
  }

  get status(): FILE_JOB_STATUS {
    return this.get('status')
  }
  set status(value: FILE_JOB_STATUS) {
    this.set('status', value)
  }

  get snatch_increment(): number {
    return this.get('snatch_increment')
  }
  set snatch_increment(value: number) {
    this.set('snatch_increment', value)
  }

  get mime_type(): string {
    return this.get('mime_type')
  }
  set mime_type(value: string) {
    this.set('mime_type', value)
  }

  get export_data(): PdfObjectsArray {
    return this.get('export_data')
  }
  set export_data(value: PdfObjectsArray) {
    this.set('export_data', value)
  }
  get export_media(): PdfBloggPortfolioObject {
    return this.get('export_media')
  }
  set export_media(value: PdfBloggPortfolioObject) {
    this.set('export_media', value)
  }

  get user(): User | undefined {
    return this.get('user')
  }
  set user(value: User | undefined) {
    this.set('user', value)
  }

  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }

  get ttl(): Date {
    return this.get('ttl')
  }
  set ttl(value: Date) {
    this.set('ttl', value)
  }

  /* get error(): string | undefined {
    return this.get('error')
  }
  set error(value: string) {
    this.set('error', value)
  }*/

  get archive_state(): ARCHIVE_STATE | undefined {
    return this.get('archive_state')
  }
  set archive_state(value: ARCHIVE_STATE | undefined) {
    value ? this.set('archive_state', value) : this.unset('archive_state')
  }
}
Parse.Object.registerSubclass('file_job', FileJob)
