import classNames from 'classnames'
import React, { useState } from 'react'
import { LoadingBar } from './LoadingBar'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faSliders } from '@fortawesome/pro-solid-svg-icons'
import { t } from 'i18next'
import { Button } from './Button'
import ModalRegular from './ModalRegular'

interface Props {
  children: React.ReactNode
  description?: string
  title?: string
  loading?: boolean
  bgColor?: string
  actionButton?: React.ReactNode
  icon?: IconDefinition
  iconColor?: string
  className?: string
  titlePicture?: React.ReactNode
  iconComponent?: React.ReactElement
  searchFilter?: React.ReactNode
  filter?: React.ReactNode | string
  filterCount?: number
  clearFilter?: () => void
  notBold?: boolean
}

export default function ColorCard(props: Props) {
  const [showSearchFilter, setShowSearchFilter] = useState(false)
  const [showFilter, setShowFilter] = useState<boolean>(false)

  const toggleSearchFilter = () => {
    setShowSearchFilter(!showSearchFilter)
  }
  return (
    <div className={'w-full flex flex-col mt-6 rounded-2xl shadow-tyraShadow'}>
      {props?.loading && <LoadingBar />}
      <div className={classNames('flex p-3 rounded-t-2xl', props.bgColor)}>
        <div className=" flex justify-start items-center ml-6 font-medium text-xl sm:text-2xl text-white w-full max-w-7xl text-center">
          {props.titlePicture && props.titlePicture}
          {props.iconComponent
            ? React.cloneElement(props.iconComponent, {
                className: classNames('mr-2', props.iconColor),
              })
            : props.icon && (
                <FontAwesomeIcon
                  icon={props.icon}
                  className={classNames('mr-2', props.iconColor)}
                />
              )}
          {props.title}
        </div>

        <div className="flex flex-row space-x-4 w-full justify-end">
          <div> {props.actionButton && props.actionButton}</div>

          {props.filter && (
            <>
              <Button
                size="md"
                variant="filter"
                label={t('general.filter')}
                icon={faSliders}
                className="text-eventsMain  cursor-pointer hover:scale-[102%%] relative"
                onClick={() => {
                  setShowFilter(true)
                }}
                countPrompt={props.filterCount}
              />

              <ModalRegular
                bgColor="bg-gray-400 p-4"
                title={t('general.filter')}
                className="overflow-visible p-0"
                isOpen={showFilter}
                onClose={() => {
                  setShowFilter(false)
                }}
                closeColor={'white'}
              >
                <div className="w-full px-10 py-10 ">{props.filter}</div>
                {props.clearFilter && (
                  <div className="w-full flex justify-between ">
                    <Button
                      className="m-4 "
                      label={t('general.cleanFilter')}
                      variant="roundWhiteCircleGreen"
                      onClick={props.clearFilter}
                    />
                    {/* <Button
                              className="m-4"
                              variant="roundGreen"
                              label="!Klar"
                            />*/}
                    <Button
                      className="m-4"
                      size="custom"
                      variant="roundGreen"
                      label={t('general.done')}
                      onClick={() => {
                        setShowFilter(false)
                      }}
                    />
                  </div>
                )}
              </ModalRegular>
            </>
          )}
          {props.searchFilter && (
            <div className="max-w-[180px] flex flex-row relative">
              <div className="flex justify-between items-center">
                <div className="min-w-[200px] flex flex-row justify-center sm:justify-start items-center">
                  <FontAwesomeIcon
                    className="text-white mr-4"
                    icon={faMagnifyingGlass}
                  />
                  {props.searchFilter && !showSearchFilter && (
                    <div
                      className={classNames(
                        'transition ease-in-out duration-1000',
                        !showSearchFilter ? 'w-fit mr-4' : 'w-0 ',
                      )}
                    >
                      {props.searchFilter}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className={classNames(
          'flex space-x-4 rounded-b-2xl  items-center font-sans bg-white text-neutral-600  sm:p-3 relative',
          props.notBold ? '' : 'font-bold',
        )}
      >
        <div className="w-full pt-2 sm:pt-0">{props.children}</div>
      </div>
    </div>
  )
}
