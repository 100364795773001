import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Button } from '../../../components/Button'
import { Slider } from '../../../components/Slider'
import { ButtonDropdown } from '../../../components/Dropdown'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import CustomInput from '../../../components/CustomInput'
import { UserStoreContext } from '../../../store/userStore'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import Swal from 'sweetalert2'
import { t } from 'i18next'
import { UserRole } from '../../../Models/UserRole'
import { Department } from '../../../Models/Department'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { useParams } from 'react-router-dom'
import { getImageFromRole } from '../../../utils/parseUtils'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { errorSwal, successSwal } from '../MyConnections'

export const MyConnectionsCard: React.FC<{
  userRole: UserRole
  showRemove?: boolean
  showApprove?: boolean
  showHideInSchedule?: boolean
  showDepartment?: boolean
  saveDirectly?: boolean
  cancelAction?: () => void
  approveAction?: () => void
}> = ({
  approveAction,
  cancelAction,
  userRole,
  showRemove,
  showApprove,
  showDepartment,
  showHideInSchedule,
  saveDirectly,
}) => {
  const { currentUserRole } = useContext(UserStoreContext)
  const { schoolId } = useParams()
  const otherRef = useRef<HTMLInputElement>(null)
  const [fakeState, setFakeState] = useState(false)
  const [departments, setDepartments] = useState<Department[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [menuRef, togglerRef] = useClickOutside(() => setIsOpen(false))
  const [openOtherModal, setOpenOtherModal] = useState(false)
  const { t } = useTranslation()
  const { fetchDepartmentsForSpecificUserRole } = useContext(
    DepartmentStoreContext,
  )
  useEffect(() => {
    const loadDepartments = async () => {
      if (schoolId && userRole) {
        try {
          const departments = await fetchDepartmentsForSpecificUserRole(
            schoolId,
            userRole,
          )
          setDepartments(departments)
        } catch (error) {
          console.error('Kunde inte hämta avdelningarna: ', error)
          setDepartments([])
        }
      }
    }

    loadDepartments()
  }, [schoolId, currentUserRole, fetchDepartmentsForSpecificUserRole])

  const primaryDepartment = () => {
    if (userRole.staff_primary_dep) {
      const primaryDep = departments.find(
        (dep) => dep.id === userRole.staff_primary_dep?.id,
      )

      if (primaryDep) {
        return primaryDep.klassName
      }
    }
    return t('settings.choosePrimaryDepartment')
  }

  return (
    <div className="border p-4 rounded-2xl shadow flex w-full max-w-[400px] justify-between flex-col gap-y-4">
      <div className="flex flex-row items-center justify-between gap-x-10">
        <label className="relative flex items-center justify-center border rounded-full max-w-[60px] bg-gray-300  max-h-[60px]  min-w-[60px]  min-h-[60px] overflow-hidden">
          <img
            src={getImageFromRole(userRole)}
            className={'w-[60px] h-[60px] object-cover rounded-full'}
            alt=""
          />
        </label>

        {showDepartment ? (
          <div className="relative flex w-full flex-col">
            <div className="text-semibold text-sm">
              {t('settings.teacherOn')}{' '}
              {userRole.school_pointer.display_school_name ??
                userRole.school_pointer.schoolName}
            </div>
            <button
              ref={togglerRef}
              type="button"
              className="w-full rounded-full group focus:outline-none hover:bg-gray-50"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <div className="flex px-4 py-2 border rounded-full w-full font-normal text-sm items-center justify-between gap-x-4">
                <p className="font-semibold"> {primaryDepartment()}</p>
                <FontAwesomeIcon
                  className={`text-gray-400 hover:text-gray-500 ${
                    isOpen ? 'animate-rotate-to-180' : 'animate-rotate-to-0'
                  }`}
                  icon={faChevronDown}
                />{' '}
              </div>
            </button>

            {isOpen && (
              <div
                ref={menuRef as RefObject<HTMLDivElement>}
                className="absolute right-6 z-50 mx-3 origin-top divide-y w-52 top-[68px]"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <ButtonDropdown.Menu>
                  <ButtonDropdown.Section>
                    {departments.map((department) => (
                      <ButtonDropdown.Button
                        key={department.id}
                        label={department.klassName || ''}
                        onClick={async () => {
                          userRole.staff_primary_dep = department

                          if (saveDirectly) {
                            await userRole
                              .save()
                              .then(() => {
                                successSwal()
                              })
                              .catch((e) => {
                                errorSwal()
                              })
                              .finally(() => {})
                          }
                          setIsOpen(false)
                        }}
                      />
                    ))}

                    <ButtonDropdown.Button
                      label={t('settings.noDepartment')}
                      onClick={async () => {
                        userRole.unset('staff_primary_dep')

                        if (saveDirectly) {
                          await userRole
                            .save()
                            .then(() => {
                              successSwal()
                            })
                            .catch((e) => {
                              errorSwal()
                            })
                            .finally(() => {})
                        }
                        setIsOpen(false)
                      }}
                    />
                  </ButtonDropdown.Section>
                </ButtonDropdown.Menu>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      {/*<div>{fullName}</div>*/}
      {!showDepartment && (
        <div className="relative flex w-full flex-col">
          <div className="text-semibold">
            {t('settings.teacherOn')}{' '}
            {userRole.school_pointer.display_school_name ??
              userRole.school_pointer.schoolName}
          </div>
        </div>
      )}
      {showHideInSchedule && (
        <div className="flex flex-row justify-between items-center">
          <p className="font-medium">
            <>{t('settings.hideScheduale')}</>
          </p>
          <div className="cursor-pointer">
            <Slider
              id={0}
              value={userRole.staff_schedule_invisible}
              onSliderClick={async () => {
                userRole.staff_schedule_invisible =
                  userRole.staff_schedule_invisible ? false : true
                setFakeState(!fakeState)
                if (saveDirectly) {
                  await userRole
                    .save()
                    .then(() => {
                      successSwal()
                    })
                    .catch((e) => {
                      errorSwal()
                    })
                    .finally(() => {})
                }
              }}
              onBoxClick={() => {}}
            />
            {/* Du kan lägga till en ikon eller något visuellt här som ändras baserat på sliderState */}
          </div>
        </div>
      )}
      <div>
        <>
          <div
            className={classNames(
              'flex',
              showApprove && showRemove ? 'justify-between' : 'justify-center',
            )}
          >
            {showRemove && (
              <Button
                size="sm"
                variant={'borderRed'}
                label={t('documents.remove') + ' ' + '😳'}
                onClick={async () => {
                  const result = await Swal.fire({
                    title: t('documents.remove') + '?',
                    text: t('admin.removeActiveOneKid')
                      .replace(
                        'XYC1337',
                        userRole?.user?.firstName +
                          ' ' +
                          userRole?.user?.lastName,
                      )
                      .replace(
                        'CYX7331',
                        userRole.school_pointer.display_school_name ??
                          userRole.school_pointer.schoolName,
                      ),
                    icon: 'info',
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    cancelButtonText: t('general.cancel'),
                    confirmButtonText: t('documents.remove'),
                  })
                  if (result.isConfirmed) {
                    if (cancelAction) {
                      cancelAction()
                    }
                  }
                }}
              />
            )}
            {showApprove && (
              <Button
                size="sm"
                variant={'fullBlue'}
                label={t('settings.accept')}
                onClick={() => {
                  if (approveAction) {
                    approveAction()
                  }
                }}
              />
            )}
          </div>
        </>
      </div>
      <ModalRegular
        isOpen={openOtherModal}
        onClose={() => {
          setOpenOtherModal(false)
        }}
        bgColor="bg-eventsMain p-4"
        closeColor="white"
        title={t('settings.chooseRelation')}
      >
        <div className="flex flex-col p-4">
          <div>
            <p>
              <>{t('settings.otherInfo')}</>
            </p>
            <div>
              <CustomInput
                ref={otherRef}
                onChange={function (value: string): void {
                  throw new Error('Function not implemented.')
                }}
              />
            </div>
          </div>
          <div className="flex flex-row w-full justify-center mt-10">
            {' '}
            <Button
              onClick={() => {
                setOpenOtherModal(false)
              }}
              variant="fullBlue"
              size="custom"
              label={t('general.done')}
            />
          </div>
        </div>
      </ModalRegular>
    </div>
  )
}
