// AudioModal.tsx
import React, { useContext, useEffect, useState } from 'react'

import { t } from 'i18next'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import DownloadCard from './DownloadCard'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FILE_JOB_STATUS } from '../../../Models/FileJob'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { FileJobStoreContext } from '../../../store/fileJobStore'
import { KidsStoreContext } from '../../../store/kidsStore'
import { UserStoreContext } from '../../../store/userStore'
import { Loading } from '../../../components/Loading'

type DownloadModalProps = {
  showModal: boolean
  hideModal: () => void
}

const DownloadModal: React.FC<DownloadModalProps> = ({
  showModal,
  hideModal,
}) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { fileJobs, fetchFileJobs } = useContext(FileJobStoreContext)
  const { fetchUserRole, currentUserRole, readOnly } =
    useContext(UserStoreContext)
  const { fetchUserRoles } = useContext(KidsStoreContext)
  const statusOrder: { [key in FILE_JOB_STATUS]: number } = {
    [FILE_JOB_STATUS.QUEUED]: 1,
    [FILE_JOB_STATUS.IN_PROGRESS]: 2,
    [FILE_JOB_STATUS.SUCCESS]: 3,
    [FILE_JOB_STATUS.OPENED]: 4,
    [FILE_JOB_STATUS.ERROR]: 5,
  }
  const [isEmpty, setIsEmpty] = useState(false)
  useEffect(() => {
    setLoading(true)
    fetchFileJobs().finally(() => {
      setLoading(false)
    })
  }, [])
  return (
    <>
      {loading && <Loading />}

      <ModalRegular
        isOpen={showModal}
        onClose={hideModal}
        title={t('blog.myDownloads')}
        bgColor="bg-eventsMain p-4"
        closeColor="white"
        className="max-w-xl"
      >
        <div className="flex flex-col ">
          <div className="flex flex-wrap justify-center">
            <div className="flex flex-col mt-5 mb-10">
              {fileJobs && fileJobs.length > 0 ? (
                fileJobs
                  .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
                  .map((fileJob: any, index: number) => (
                    <DownloadCard
                      fileJob={fileJob}
                      key={fileJob.id}
                      iconleft={faFilePdf}
                      iconClassName="text-red-500"
                      title={fileJob.name}
                      description={`Status: ${fileJob.status}`}
                      fileJobStatus={fileJob.status}
                      pdfUrl={fileJob?.file?._url}
                      updatedAt={fileJob.updatedAt}
                      ttl={fileJob.ttl}
                    />
                  ))
              ) : (
                <div className="p-10 text-center">{t('documents.empty')}</div>
              )}
            </div>
          </div>
        </div>
      </ModalRegular>
    </>
  )
}

export default DownloadModal
