import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { useDrag } from 'react-dnd'
import { DragAndDropStoreContext } from '../../../store/dragAndDropStore'
import DOMPurify from 'dompurify'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem } from '@fortawesome/free-solid-svg-icons'
import { faLink, faTrash } from '@fortawesome/pro-solid-svg-icons'

const joypixels = require('emoji-toolkit')

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  process.env.REACT_APP_PUBLIC_URL + '/pdf.worker.min.js',
  import.meta.url,
).toString()

interface DraggableItemProps {
  id: string
  content: string
  type: string
  size?: string
  bgColor?: string
  subHeader?: string
  tag?: any
  onClick?: () => void
}

export const ItemTypes = {
  DRAGGABLE_ITEM: 'draggableItem',
}

const DraggableItem: React.FC<DraggableItemProps> = ({
  id,
  content,
  type,
  size,
  bgColor,
  subHeader,
  tag,
  onClick,
}) => {
  const { setDragging } = useContext(DragAndDropStoreContext)
  const [numPages, setNumPages] = useState(null)
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  /*
  const [imageB64, setImageB64] = useState<any>('')

  const image: any = document.getElementById(id)

  const getBase64StringFromDataURL = (dataURL: any) =>
    dataURL.replace('data:', '').replace(/^.+,/, '')

  const toDataUrl = (url: any, callback: any) => {
    var xhr = new XMLHttpRequest()
    xhr.onload = function () {
      var reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }
  if (type == 'image') {
    toDataUrl(content, function (myBase64: any) {
      //console.log('hehe', myBase64) // myBase64 is the base64 string
      setImageB64(myBase64)
    })
  }

  if (image) {
    // Get the remote image as a Blob with the fetch API
    fetch(image.src)
      .then((res) => res.blob())
      .then((blob) => {
        // Read the Blob as DataURL using the FileReader API
        const reader = new FileReader()
        reader.onloadend = () => {
          // Logs data:image/jpeg;base64,wL2dvYWwgbW9yZ...

          // Convert to Base64 string
          const base64 = getBase64StringFromDataURL(reader.result)

          // Logs wL2dvYWwgbW9yZ...
        }
        reader.readAsDataURL(blob)
      })
  }*/

  useEffect(() => {
    if (isDragging) {
      setDragging(true)
    } else {
      setDragging(false)
    }
  }, [isDragging])
  if (type === 'header') {
    return (
      <>
        <div className="w-full h-auto text-xl font-bold">{content}</div>
        {subHeader && (
          <div className="w-full h-auto text-sm">
            <FontAwesomeIcon
              className=" text-blue-500"
              icon={faLink}
            ></FontAwesomeIcon>{' '}
            {subHeader}
          </div>
        )}
      </>
    )
  }
  if (type === 'line') {
    return <div className="w-full h-[1px] bg-gray-400"></div>
  }
  if (type === 'pdf') {
    const onDocumentLoadSuccess = (numPages2: any) => {
      setNumPages(numPages2?.numPages)
    }
    return (
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.2 : 1 }}
        className={classNames(
          'group relative flex-auto max-h-full max-w-full box-border',
          size ? `text-${size}` : '',
        )}
      >
        {onClick && (
          <div
            className="absolute top-[5px] left-[5px] bg-red-500 invisible group-hover:visible cursor-pointer py-[5px] px-[10px] opacity-80 rounded-full z-10"
            onClick={() => {
              if (onClick) {
                onClick()
              }
            }}
          >
            <FontAwesomeIcon
              className=" text-white"
              icon={faTrash}
            ></FontAwesomeIcon>
          </div>
        )}
        <>
          <Document
            file={content}
            //options={{ workerSrc: '/pdf.worker.js' }}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
            className={'max-w-[100%]'}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <>
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={520}
                />
              </>
            ))}
          </Document>
        </>
      </div>
    )
  }
  if (type === 'image') {
    return (
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.2 : 1 }}
        className={classNames(
          'group relative flex-auto max-h-full max-w-full mt-1 mb-2',
          size ? `text-${size}` : '',
        )}
      >
        {onClick && (
          <div
            className="absolute top-[5px] left-[5px] bg-red-500 invisible group-hover:visible cursor-pointer py-[5px] px-[10px] opacity-80 rounded-full"
            onClick={() => {
              if (onClick) {
                onClick()
              }
            }}
          >
            <FontAwesomeIcon
              className=" text-white"
              icon={faTrash}
            ></FontAwesomeIcon>
          </div>
        )}
        <>
          <img
            id={id}
            src={content}
            alt="Draggable"
            className="max-h-full max-w-full object-contain"
          />
        </>
      </div>
    )
  }
  if (type === 'curriculums') {
    return (
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.2 : 1 }}
        className={classNames(
          'flex-auto mt-1 mb-2 group relative',
          size ? `text-${size}` : '',
        )}
      >
        {onClick && (
          <div
            className="absolute top-[5px] left-[5px] bg-red-500 invisible group-hover:visible cursor-pointer py-[5px] px-[10px] opacity-80 rounded-full"
            onClick={() => {
              if (onClick) {
                onClick()
              }
            }}
          >
            <FontAwesomeIcon
              className=" text-white"
              icon={faTrash}
            ></FontAwesomeIcon>
          </div>
        )}
        {tag?.title && (
          <div
            className="py-[5px] px-[10px] rounded-2xl inline-block text-white mb-2"
            style={{ backgroundColor: tag?.tagColor }}
          >
            {tag?.title}
          </div>
        )}
        <div className="italic">"{content}"</div>
      </div>
    )
  }
  if (type === 'tag') {
    return (
      <div
        style={{ opacity: isDragging ? 0.2 : 1 }}
        className={classNames('group relative', size ? `text-${size}` : '')}
      >
        {onClick && (
          <div
            className="absolute top-[5px] left-[5px] bg-red-500 invisible group-hover:visible cursor-pointer py-[5px] px-[10px] opacity-80 rounded-full"
            onClick={() => {
              if (onClick) {
                onClick()
              }
            }}
          >
            <FontAwesomeIcon
              className=" text-white"
              icon={faTrash}
            ></FontAwesomeIcon>
          </div>
        )}
        <div
          ref={drag}
          className={`py-2 px-4 bg-[${bgColor}] text-black inline-block text-2xs rounded-2xl mt-1 mb-2`}
          style={{ backgroundColor: bgColor }}
        >
          {content}
        </div>
      </div>
    )
  }
  if (type === 'text') {
    return (
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.2 : 1 }}
        className={classNames(
          'group relative flex-auto mt-1 mb-2',
          size ? `text-${size}` : '',
        )}
      >
        {onClick && (
          <div
            className="absolute top-[5px] left-[5px] bg-red-500 invisible group-hover:visible cursor-pointer py-[5px] px-[10px] opacity-80 rounded-full"
            onClick={() => {
              if (onClick) {
                onClick()
              }
            }}
          >
            <FontAwesomeIcon
              className=" text-white"
              icon={faTrash}
            ></FontAwesomeIcon>
          </div>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(joypixels.toImage(content)),
          }}
        ></div>
      </div>
    )
  }
  return <div></div>
}

export default DraggableItem
