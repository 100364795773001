import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { School } from '../Models/School'
import { Event } from '../Models/Event'
import { Department } from '../Models/Department'
import { async } from 'q'
import { ActionStates } from '../Models/ActionStates'
import { User } from '../Models/User'
import { userStore } from './userStore'
import { departmentStore } from './departmentStore'
import moment from 'moment'

interface Category {
  id: string
  label: string
}

export type EventOverview = {
  signed: User[]
  read: User[]
  unRead: User[]
  actionStates: ActionStates[]
}

class EventStore {
  events?: Event[]
  event?: Event
  eventOverview?: EventOverview
  eventsCount?: number
  nearestDate?: Date
  constructor() {
    makeAutoObservable(this)
  }

  setNearestDate = (nearestDate: Date) => {
    this.nearestDate = nearestDate
  }
  setEvents = (events: Event[]) => {
    this.events = events
  }

  setEventsCount = (eventsCount: number) => {
    this.eventsCount = eventsCount
  }

  setEvent = (event: Event) => {
    this.event = event
  }

  setEventOverview = (overview: any) => {
    this.eventOverview = overview
  }

  getEventOverview = async ({ eventId }: { eventId: string }) => {
    const overview = await Parse.Cloud.run('getEventOverview', {
      eventId,
    })
    this.setEventOverview(overview)
  }

  fetchSingleEvent = async (eventId: string) => {
    const eventsQuery = new Parse.Query(Event)
      .include('author')
      .equalTo('objectId', eventId)
      .descending('date')

    const event = await eventsQuery.first()
    if (event) {
      this.setEvent(event)
      return event
    }
  }

  deleteSingleEvent = async (event: Event) => {
    await event.destroy().then(
      () => {
        return true
      },
      (error) => {
        throw error
      },
    )
  }

  setEventAsRead = async (event: Event) => {
    const action_state = new Parse.Object('action_states')
    action_state.set('viewed', new Date())
    action_state.set('school_pointer', event.school_pointer)
    action_state.set('school', event.school)
    action_state.set('entity_id', event.id)
    action_state.set('entity_class', 'events')
    action_state.set('user_owner', Parse.User.current())
    action_state
      .save()
      .then(function (response) {})
      .catch(function (error) {
        console.log('Error: ' + error.code + ' ' + error.message)
      })
  }

  fetchEventsActionStates = async (events: Event[]) => {
    const eventIds: string[] = []

    for (var i = 0; i < events.length; i++) {
      eventIds.push(events[i].id)
    }

    const actionStatesQuery = new Parse.Query(ActionStates)
      .equalTo('entity_class', 'events')
      .containedIn('entity_id', eventIds)
      .equalTo('user_owner', Parse.User.current())
    const actionStates = await actionStatesQuery.find()

    return actionStates
  }

  fetchEvents = async (
    schoolId: string,
    departmentPointers: Department[] = [],
    categories: Category[] = [],
    skip?: number,
  ) => {
    /*const schoolQuery = new Parse.Query(School)
    const school = await schoolQuery.get(schoolId)
    const eventsQuery = new Parse.Query(Event)
      .include('author')
      //.greaterThanOrEqualTo('author.archive_state', 200)
      .doesNotExist('author.archive_state')
      .equalTo('school_pointer', school)
      .ascending('date')

    const waitToSetCurrentUserRole = await userStore.fetchUserRole(
      localStorage.getItem('user_role_id') ?? '',
    )
    const currentUserRole = userStore.currentUserRole

    if (currentUserRole && currentUserRole.kid) {
      eventsQuery.notEqualTo('type', 'Personal')
      if (currentUserRole.kid.departmentPointer) {
        const kidDepartment = currentUserRole.kid
          .departmentPointer as Department
        if (kidDepartment) {
          eventsQuery.containedIn('class_pointer', [kidDepartment])
        }
      }
    }

    if (departmentPointers.length) {
      eventsQuery.containedIn('class_pointer', departmentPointers)
    }

    if (categories.length) {
      let cats: string[] = []
      for (var i = 0; i < categories.length; i++) {
        cats.push(categories[i].id)
      }
      eventsQuery.containedIn('type', cats)
    }
    const events: any = await eventsQuery.find()*/

    const departmentIds = departmentPointers.map((dep) => dep.id)
    const categoryIds = categories.map((cat) => cat.id)

    /*const waitToSetCurrentUserRole = await userStore.fetchUserRole(
      localStorage.getItem('user_role_id') ?? '',
    )
    const currentUserRole = userStore.currentUserRole*/

    const userRoleId = localStorage.getItem('user_role_id')

    const { events, count } = await Parse.Cloud.run('getEvents', {
      departmentIds,
      categoryIds,
      schoolId,
      userRoleId,
      skip,
    })

    const dateToCheckFor = moment().format('YYYY-MM-DD')

    let nearestDate: any

    let evCount = 0
    events.forEach((event: Event) => {
      let diff = moment(event.date).diff(moment(dateToCheckFor), 'days')
      if (diff > 0) {
        if (nearestDate) {
          if (moment(event.date).diff(moment(nearestDate), 'days') < 0) {
            nearestDate = event.date
          }
        } else {
          nearestDate = event.date
        }
      }

      evCount++
      if (evCount == events.length && !nearestDate) {
        nearestDate = event.date
      }
    })

    this.setNearestDate(nearestDate)
    const eventsWithRead = await this.fetchEventsActionStates(events)
    this.setEventsCount(count)
    for (var i = 0; i < events.length; i++) {
      if (eventsWithRead.find((ev) => ev.get('entity_id') == events[i].id)) {
        events[i].read = true
      } else {
        events[i].read = false
      }
    }

    if (skip ?? 0 > 0) {
      this.setEvents([...events, ...(this.events ?? [])])
    } else {
      this.setEvents(events)
    }
  }
}

export const eventStore = (() => {
  return new EventStore()
})()
export const EventStoreContext: React.Context<EventStore> =
  React.createContext(eventStore)
