import React from 'react'
import { useTranslation } from 'react-i18next'
import Flags from 'country-flag-icons/react/3x2'
import classNames from 'classnames'

export enum CHANGE_TYPES {
  FLAGS = 'flags',
  LIST = 'list',
}
export const ChangeTranslation: React.FC<{ changeType: CHANGE_TYPES }> = ({
  changeType,
}) => {
  const { i18n } = useTranslation()

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng)
  }

  if (changeType == CHANGE_TYPES.FLAGS) {
    return (
      <div className={'flex space-x-2 items-center'}>
        <div className="w-[30px] rounded-full h-[30px] hover:scale-110 overflow-hidden text-center justify-center align-middle">
          <Flags.SE
            title="Svenska"
            onClick={() => changeLanguage('sv')}
            className={classNames(
              i18n.language == 'sv' ? 'languageFlags' : 'languageFlagsOpacity',
              'w-[45px] h-[45px] mt-[-7.5px] ml-[-7.5px] cursor-pointer',
              'hover:scale-110 justify-center align-middle',
            )}
          />
        </div>
        <div className="w-[30px] rounded-full h-[30px] hover:scale-110 overflow-hidden text-center justify-center align-middle">
          <Flags.GB
            title="English"
            onClick={() => changeLanguage('en')}
            className={classNames(
              i18n.language == 'en' ? 'languageFlags' : 'languageFlagsOpacity',
              'w-[45px] h-[45px] mt-[-7.5px] ml-[-7.5px] cursor-pointer',
              'hover:scale-110 justify-center align-middle',
            )}
          />
        </div>
        <div className="w-[30px] rounded-full h-[30px] hover:scale-110 overflow-hidden text-center justify-center align-middle">
          <Flags.ES
            title="Espanyol"
            onClick={() => changeLanguage('es')}
            className={classNames(
              i18n.language == 'es' ? 'languageFlags' : 'languageFlagsOpacity',
              'w-[45px] h-[45px] mt-[-7.5px] ml-[-7.5px] cursor-pointer',
              'hover:scale-110 justify-center align-middle',
            )}
          />
        </div>
      </div>
    )
  }

  if (changeType == CHANGE_TYPES.LIST) {
    return (
      <div style={{ width: 80 }}>
        <div
          onClick={() => changeLanguage('sv')}
          className={i18n.language == 'sv' ? 'flagsList' : 'flagsListOpacity'}
        >
          <div style={{ float: 'left', width: '50%' }}>
            <Flags.SE title="Svenska" className={'languageFlagsList'} />
          </div>
          <div style={{ float: 'left', width: '50%' }}>SV</div>
        </div>
        <div
          onClick={() => changeLanguage('en')}
          className={i18n.language == 'en' ? 'flagsList' : 'flagsListOpacity'}
        >
          <div style={{ float: 'left', width: '50%' }}>
            <Flags.GB title="English" className={'languageFlagsList'} />
          </div>
          <div style={{ float: 'left', width: '50%' }}>EN</div>
        </div>
        <div
          onClick={() => changeLanguage('es')}
          className={i18n.language == 'es' ? 'flagsList' : 'flagsListOpacity'}
        >
          <div style={{ float: 'left', width: '50%' }}>
            <Flags.ES title="Espanyol" className={'languageFlagsList'} />
          </div>
          <div style={{ float: 'left', width: '50%' }}>ES</div>
        </div>
      </div>
    )
  }

  return <div></div>
}
