import {
  faPersonWalkingArrowLoopLeft,
  faPersonWalkingArrowRight,
  faTrash,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button } from '../../../components/Button'
import { observer } from 'mobx-react-lite'
import { User } from './OverviewUsersList'
import moment from 'moment'
import { ContactStoreContext } from '../../../store/contactStore'
import OverviewUserContactDetailsPopUp from './OverviewUserContactDetailsPopUp'
import { Event3StoreContext } from '../../../store/event3Store'

interface PopupProps {
  eventTimeId?: string
  user: User | undefined
  onClose: () => void
  refreshDetail: () => void
}

const OverviewUserPopUp: React.FC<PopupProps> = observer(
  ({ eventTimeId, user, onClose, refreshDetail }) => {
    const popupRef = useRef<HTMLDivElement>(null)
    const { fetchContact, contact } = useContext(ContactStoreContext)
    const { sendReminder, isPresentOnEventTime, declineParticipatingInEvent } =
      useContext(Event3StoreContext)
    const [
      isUserContactDetailsPopUpVisible,
      setUserContactDetailsPopUpVisible,
    ] = useState<boolean>(false)

    const handleClosePopup = () => {
      setUserContactDetailsPopUpVisible(false)
    }

    const handleClickOutside = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      event.stopPropagation()
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    const readAt = moment(user?.readAt).format('YYYY-MM-DD')

    useEffect(() => {
      const fetchContactDetails = async () => {
        if (user?.userRoleId) await fetchContact(user?.userRoleId)
      }
      fetchContactDetails()
    }, [user])

    return (
      <>
        <div
          className="fixed inset-0 flex items-center  justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50"
          onClick={handleClickOutside}
        >
          <div
            className="bg-white h-[60%] my-auto w-3/5 h-5/5 p-6 rounded-lg shadow-lg relative sm:h-3/ sm:w-1/2"
            ref={popupRef}
          >
            <div className="flex justify-end">
              <FontAwesomeIcon
                icon={faXmark}
                size="2x"
                className="text-gray-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  onClose()
                }}
              />
            </div>

            <div className="flex flex-col p-4 h-full overflow-y-auto">
              <div className="flex items-center border-b-2 py-2">
                <img
                  className={`w-12 h-12 rounded-full object-cover ${
                    !user?.thumbnail && 'bg-gray-300'
                  }`}
                  src={user?.thumbnail}
                  alt=""
                />
                <div className="flex w-[90%] items-center justify-between">
                  <div className="flex flex-col font-bold p-2 ">
                    <p className=" text-gray-900 ">{user?.name}</p>
                    {!!user?.kidId === false ? (
                      <p
                        className=" text-blue-600 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation()
                          setUserContactDetailsPopUpVisible(
                            !isUserContactDetailsPopUpVisible,
                          )
                        }}
                      >
                        See contact details
                      </p>
                    ) : (
                      <p className="text-gray-500">
                        {user?.departmentNames && user?.departmentNames[0]}
                      </p>
                    )}
                  </div>
                  {!!user?.kidId === false && (
                    <div>
                      {!user?.hasRead ? (
                        <p className="font-bold text-red-500">Unread</p>
                      ) : (
                        <div className="flex flex-col font-bold text-gray-500 items-center">
                          <p>Read</p>
                          <p>{readAt}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {!!user?.kidId === true && (
                <div className="flex justify-center items-center h-[80%]">
                  <div className=" flex flex-col m-4 gap-4">
                    <Button
                      size="lg"
                      icon={
                        user?.isPresent === true
                          ? faPersonWalkingArrowLoopLeft
                          : faPersonWalkingArrowRight
                      }
                      fontSize="text-xl"
                      variant={
                        user?.isPresent === false ||
                        user?.isPresent === undefined
                          ? 'fullGreen'
                          : 'fullRed'
                      }
                      label={
                        user?.isPresent === false ||
                        user?.isPresent === undefined
                          ? 'Present'
                          : 'Leaving'
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        try {
                          const presentStatus = !!!user?.isPresent
                          isPresentOnEventTime(
                            eventTimeId,
                            [user?.id ?? ''],
                            presentStatus,
                          )
                          refreshDetail()
                          onClose()
                        } catch (error) {
                          console.error(error)
                        }
                      }}
                    />
                    {user?.isPresent === false ||
                    user?.isPresent === undefined ? (
                      <p className="text-gray-400 font-semibold">
                        !!Confirm present at the event
                      </p>
                    ) : (
                      <p className="text-gray-400 font-semibold">
                        !!Confirm leaving the event
                      </p>
                    )}
                    <Button
                      size="lg"
                      icon={faTrash}
                      fontSize="text-xl"
                      variant="red"
                      label="Remove"
                      onClick={(e) => {
                        e.stopPropagation()
                        try {
                          declineParticipatingInEvent(
                            eventTimeId,
                            [user?.id ?? ''],
                            [user?.kidId ?? ''],
                          )
                          refreshDetail()
                          onClose()
                        } catch (error) {
                          console.error(error)
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              <div className=" h-[80%] overflow-y-auto">
                {user?.kids?.map((child, index) => (
                  <div className="flex items-center border-b-2 py-2">
                    <img
                      className={`w-12 h-12 rounded-full object-cover ${
                        !child?.thumbnail && 'bg-gray-300'
                      }`}
                      src={child?.thumbnail}
                      alt=""
                    />
                    <div className="flex flex-col items-start p-2">
                      <p key={child.kidId} className="font-bold text-gray-900">
                        {child.name}
                      </p>
                      <p key={index} className="font-semibold text-gray-500">
                        {child.departmentName}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {!!user?.kidId === false && (
              <div>
                {!user?.hasRead && (
                  <div className="flex justify-center">
                    <Button
                      className="w-[50%]"
                      size="lg"
                      fontSize="text-xl"
                      variant="borderMain"
                      label="Send remider"
                      onClick={(e) => {
                        e.stopPropagation()
                        sendReminder(eventTimeId, [user?.userId ?? ''])
                      }}
                    />
                  </div>
                )}{' '}
              </div>
            )}
          </div>
        </div>
        {isUserContactDetailsPopUpVisible && (
          <OverviewUserContactDetailsPopUp
            user={user}
            contact={contact}
            onClose={handleClosePopup}
          />
        )}
      </>
    )
  },
)

export default OverviewUserPopUp
