import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import {
  faArrowAltCircleDown,
  faClock,
  faExclamationTriangle,
  faEye,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import {
  faArrowDownToLine,
  faCheckCircle,
  faChevronRight,
  faLayerGroup,
} from '@fortawesome/pro-solid-svg-icons'
import { LoadingBar } from '../../../components/LoadingBar'
import { FILE_JOB_STATUS, FileJob } from '../../../Models/FileJob'
import { t } from 'i18next'
import moment from 'moment'
import { getDateForTranslatation } from '../../events/Events'
function statusToIcon(status: FILE_JOB_STATUS | undefined) {
  switch (status) {
    case FILE_JOB_STATUS.QUEUED:
      return faLayerGroup
    case FILE_JOB_STATUS.IN_PROGRESS:
      return faSpinner
    case FILE_JOB_STATUS.SUCCESS:
      return faArrowDownToLine
    case FILE_JOB_STATUS.OPENED:
      return faArrowDownToLine
    case FILE_JOB_STATUS.ERROR:
      return faExclamationTriangle
    default:
      return null
  }
}
function statusToColor(status: FILE_JOB_STATUS | undefined): string {
  switch (status) {
    case FILE_JOB_STATUS.QUEUED:
      return '#3f9ae5'
    case FILE_JOB_STATUS.IN_PROGRESS:
      return '#3f9ae5'
    case FILE_JOB_STATUS.SUCCESS:
      return '#3f9ae5'
    case FILE_JOB_STATUS.OPENED:
      return '#3f9ae5'
    case FILE_JOB_STATUS.ERROR:
      return 'red'
    default:
      return 'grey'
  }
}
const determineText = (status: FILE_JOB_STATUS | undefined, ttl: any) => {
  switch (status) {
    case FILE_JOB_STATUS.QUEUED:
      return t('settings.pendingPDF')
    case FILE_JOB_STATUS.IN_PROGRESS:
      return t('settings.pendingPDF')
    case FILE_JOB_STATUS.SUCCESS:
      return t('settings.autoDelete') + ' ' + calculateDays(ttl)
    case FILE_JOB_STATUS.OPENED:
      return t('settings.autoDelete') + ' ' + calculateDays(ttl)
    default:
      return ''
  }
}

function calculateDays(ttl: any) {
  var a = moment()
  var b = moment(ttl)
  return (
    moment(ttl).format('DD') +
    ' ' +
    getDateForTranslatation(moment(ttl).format('MM'))
  )
}

interface Props {
  children?: React.ReactNode
  description?: string
  title?: string
  loading?: boolean
  className?: string
  iconleft?: IconDefinition
  fileJobStatus?: FILE_JOB_STATUS
  iconClassName?: string
  titleContainerClassName?: string
  onClick?: () => void
  titleClassName?: string
  arrowSide?: boolean
  relation?: string
  text?: string
  fileData?: any
  pdfUrl?: string
  updatedAt?: string
  ttl?: Date
  fileJob?: FileJob
}

export default function DownloadCard(props: Props) {
  //const statusIcon = statusToIcon(props.fileJobStatus)
  const statusIcon = statusToIcon(props.fileJob?.status)
  const statusColor = statusToColor(props.fileJob?.status)

  //const statusColor = statusToColor(props.fileJobStatus)
  const [fakeState, setFakeState] = useState(false)
  const handlePdfPreview = () => {
    if (
      (props.fileJob?.status === FILE_JOB_STATUS.SUCCESS ||
        props.fileJob?.status === FILE_JOB_STATUS.OPENED) &&
      props.pdfUrl
    ) {
      window.open(props.pdfUrl, '_blank')
    }
  }

  useEffect(() => {
    let fetchInterval: any

    const fetchFileJob = () => {
      if (
        props.fileJob &&
        props.fileJob.status === FILE_JOB_STATUS.IN_PROGRESS
      ) {
        props.fileJob.fetch().finally(() => {
          setFakeState(!fakeState)
        })
      }
    }

    if (props.fileJob && props.fileJob.status === FILE_JOB_STATUS.IN_PROGRESS) {
      fetchInterval = setInterval(fetchFileJob, 5000) // Fetch every 5 seconds
    }

    return () => {
      if (fetchInterval) {
        clearInterval(fetchInterval)
      }
    }
  }, [props.fileJob])

  return (
    <div
      className={classNames(
        'w-1/3 min-h-[60px] min-w-[500px] overflow-hidden rounded-2xl  shadow-tyraShadow m-4 cursor-pointer',
      )}
      onClick={handlePdfPreview}
    >
      {props?.loading && <LoadingBar />}
      <div className="flex space-x-4 rounded-b-2xl items-center font-bold font-sans bg-white text-neutral-600 relative">
        <div
          className={classNames(
            'flex flex-wrap py-3 font-medium justify-start w-full h-full border hover:bg-blue-50',
            props.className,
          )}
        >
          <div className="flex relative flex-row items-center h-16 w-full">
            {props.iconleft && (
              <FontAwesomeIcon
                size="2x"
                icon={props.iconleft}
                className={classNames(
                  'absolute left-3 h-8 w-8',
                  props.iconClassName,
                )}
              />
            )}
            <div
              className={classNames(
                'flex flex-wrap flex-col justify-center items-center w-full text-sm',
                props.titleContainerClassName,
              )}
            >
              <p className={classNames(props.titleClassName)}>
                <p className="font-bold">{props.title}</p>
              </p>
              <p>{determineText(props.fileJob?.status, props.ttl)}</p>
              <p className="font-bold">{props.relation}</p>
            </div>

            {props.arrowSide && (
              <FontAwesomeIcon className="pr-2" icon={faChevronRight} />
            )}
          </div>

          <div className="flex-grow flex items-center justify-center">
            {props.children}

            {statusIcon && (
              <FontAwesomeIcon
                size="2x"
                icon={statusIcon}
                className="absolute top-7 right-3 h-6 w-6"
                style={{ color: statusColor }}
                spin={props.fileJob?.status === FILE_JOB_STATUS.IN_PROGRESS}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
