import React, { useContext, useEffect, useState } from 'react'
import Page from '../../components/Page'
import ColorCard from '../../components/ColorCard'
import AdminCard from '../admin/adminDashboard/components/AdminCard'
import ModalRegular from '../../components/ModalRegular'
import { NavigationStoreContext } from '../../store/navigationStore'
import { t } from 'i18next'

export const DemoVideo: React.FC<{ onClick?: any }> = ({ onClick }) => {
  const [openVideoModal, setOpenVideoModal] = useState(false)
  const [currentVideoUrl, setCurrentVideoUrl] = useState('')
  const { setTitle } = useContext(NavigationStoreContext)

  useEffect(() => {
    setTitle(t('settings.demoVideo'))
  })
  const videoSources = [
    {
      src: 'https://www.youtube.com/embed/m4oDZprHzrs',
      title: t('demo.titleDocumentation'),
      describe: t('demo.descDocumentation'),
      section: t('menu.documentation'),
    },
    {
      src: 'https://www.youtube.com/embed/RGQPfZMDhkQ',
      title: t('demo.titleUpload'),
      describe: t('demo.descUpload'),
      section: t('general.teacher'),
    },
    {
      src: 'https://www.youtube.com/embed/Y68NDDiji5o',
      title: t('demo.titleBlog'),
      describe: t('demo.descBlog'),
      section: t('menu.blog'),
    },
    {
      src: 'https://www.youtube.com/embed/RDBpgVrsZx8',
      title: t('demo.titleDocModul'),
      describe: t('demo.descDocModul'),
      section: t('menu.documentation'),
    },
    {
      src: 'https://www.youtube.com/embed/VYUEMKLuiQ4',
      title: t('demo.titleAbsence'),
      describe: t('demo.descAbsence'),
      section: t('absence.absence'),
    },
    {
      src: 'https://www.youtube.com/embed/kBl_U-I_hjQ',
      title: t('demo.titleAttendance'),
      describe: t('demo.descAttendance'),
      section: t('menu.attendance'),
    },
  ]
  const tagColor: { [key: string]: string } = {
    documentation: 'bg-teal-200',
    admin: 'bg-adminMain',
    blog: 'bg-blue-200',
    absence: 'bg-red-200',
    attendance: 'bg-yellow-200',
    parent: 'bg-orange-200',
    teacher: 'bg-green-300',
  }
  const getKeyForTranslatedSection = (translatedSection: string): string => {
    const sectionTranslationMap = {
      admin: 'admin',
      blog: 'blog',
      absence: 'absence',
      attendance: 'attendance',
      parent: 'parent',
      documentation: 'documentation',
      teacher: 'teacher',
      [t('menu.admin')]: 'admin',
      [t('menu.blog')]: 'blog',
      [t('absence.absence')]: 'absence',
      [t('menu.attendance')]: 'attendance',
      [t('menu.parent')]: 'parent',
      [t('menu.documentation')]: 'documentation',
      [t('general.teacher')]: 'teacher',

      // Add other translations as needed
    }

    return sectionTranslationMap[translatedSection] || 'default'
  }

  const handleAdminCardClick = (video: { src: string; title: string }) => {
    setCurrentVideoUrl(video.src)
    setOpenVideoModal(true)
  }

  const renderAdminCards = () => {
    return videoSources.map((video, index) => (
      <AdminCard
        key={index}
        description={video.describe}
        title={video.title}
        titleContainerClassName="flex flex-col p-2 pt-4 mt-4"
        className="hover:shadow-md shadow-none hover:drop-shadow-none drop-shadow-sm min-h-[120px] border-2"
        titleClassName="font-bold rounded-full w-fit py-1 px-2"
        onClick={() => handleAdminCardClick(video)}
        section={video.section}
        sectionClassName={`border w-fit mt-2 px-6 py-1 rounded-full text-white font-bold ${
          tagColor[getKeyForTranslatedSection(video.section)] ||
          'bg-defaultColor'
        }`}
        iconPlayRight
      />
    ))
  }

  return (
    <Page naked topBar={''}>
      <ColorCard title={t('settings.demoVideo')} bgColor="bg-eventsMain">
        <div className="flex flex-row gap-4 mb-10 flex-wrap w-full justify-center ">
          {renderAdminCards()}
        </div>
        {openVideoModal && (
          <ModalRegular
            isOpen={openVideoModal}
            onClose={() => setOpenVideoModal(false)}
            closeColor="white"
            className="max-w-[70%]"
          >
            <div className="flex justify-center bg-[#050505]">
              <iframe
                width="90%"
                height="550"
                src={currentVideoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                allowTransparency
              ></iframe>
            </div>
          </ModalRegular>
        )}
      </ColorCard>
    </Page>
  )
}
