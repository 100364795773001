import React, { useContext, useEffect, useRef, useState } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'
import ModalRegular from '../../../../../components/ModalRegular'
import { Button } from '../../../../../components/Button'
import PhoneInput from 'react-phone-input-2'
import { Slider } from '../../../../../components/Slider'
import { SE } from 'country-flag-icons/react/3x2'
import InviteExistingModal from './InviteExistingModal'
import { t } from 'i18next'
import { Kid } from '../../../../../Models/Kid'
import { UserStoreContext } from '../../../../../store/userStore'
import { LOGIN_METHOD, TERRITORY } from '../../../../../Enums/TyraEnums'
import { KidsStoreContext } from '../../../../../store/kidsStore'
import Swal from 'sweetalert2'
import { AdminStoreContext } from '../../../../../store/adminStore'
import { useParams } from 'react-router-dom'
import CustomInput from '../../../../../components/CustomInput'

interface Props {
  image?: string
  icon?: IconDefinition
  iconClassName?: string
  onClose: () => void
  isOpen: boolean
  kid?: Kid
}

export interface InviteNewGuardian {
  type: string
  ssn: string
  email: string
  name: string
  phone: string
  uploadParent: boolean
}

const initialValues = {
  type: 'mobil',
  ssn: '',
  email: '',
  name: '',
  phone: '',
  uploadParent: false,
}

export const KidCardModal: React.FC<Props> = ({
  image,
  icon,
  iconClassName,
  isOpen,
  onClose,
  kid,
}) => {
  const [newGuardian, setNewGuardian] =
    useState<InviteNewGuardian>(initialValues)
  const [inviteMode, setInviteMode] = useState('mobil')
  const [activeButton, setActiveButton] = useState<string | null>(null)
  const [inviteExisting, setInviteExisting] = useState(false)
  const [parentDocUpload, setParentDocUpload] = useState(false)
  const { currentUser, currentUserRole } = useContext(UserStoreContext)
  const { inviteGuardian } = useContext(AdminStoreContext)
  const { schoolId } = useParams()
  const nameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const psnRef = useRef<HTMLInputElement>(null)
  const buttonClass = (button: string) =>
    `w-full text-center rounded-2xl cursor-pointer hover:bg-blue-300 hover:text-white transition duration-400 ${
      activeButton === button ? 'bg-blue-300 text-white' : ''
    }`
  const [isTyped, setIsTyped] = useState(false)
  const [showDot, setShowDot] = useState(true)
  const [emailAllowed, setEmailAllowed] = useState(false)
  const [mobileAllowed, setMobileAllowed] = useState(false)
  const [grandIdAllowed, setGrandIdAllowed] = useState(false)
  useEffect(() => {
    if (currentUserRole?.school_pointer.isIDPSchool) {
      const idpConfiguration =
        currentUserRole.school_pointer.attributes.idp?.attributes.configuration
      if (
        idpConfiguration.approved_invite_methods.find(
          (methods: number) => methods === LOGIN_METHOD.GRANDID,
        )
      ) {
        setGrandIdAllowed(true)
        setNewGuardian({
          ...newGuardian,
          type: 'bankid',
        })
      }
      if (
        idpConfiguration.approved_invite_methods.find(
          (methods: number) => methods === LOGIN_METHOD.EMAIL,
        )
      ) {
        setEmailAllowed(true)
        setNewGuardian({
          ...newGuardian,
          type: 'email',
        })
      }
      if (
        idpConfiguration.approved_invite_methods.find(
          (methods: number) => methods === LOGIN_METHOD.PHONE,
        )
      ) {
        setMobileAllowed(true)
        setNewGuardian({
          ...newGuardian,
          type: 'mobile',
        })
      }
    } else {
      setMobileAllowed(true)
      setEmailAllowed(true)
      setGrandIdAllowed(true)
    }
  }, [currentUserRole])
  return (
    <ModalRegular
      isOpen={isOpen}
      onClose={onClose}
      bgColor="bg-blue-300 p-4"
      title={t('admin.inviteUser')}
      closeColor="white"
      className="bg-gray-50"
    >
      <div className="p-4 gap-y-4 flex flex-col">
        <div className="flex flex-row items-center">
          <div className="border shadow items-center bg-gray-200 max-h-[60px] min-h-[60px] max-w-[60px] min-w-[60px] rounded-full overflow-hidden relative">
            {image ? (
              <img
                src={image}
                className={classNames(
                  'absolute inset-0 object-cover rounded-full',
                  iconClassName,
                )}
              />
            ) : (
              <FontAwesomeIcon
                size="2x"
                icon={icon || faUser}
                className={classNames(
                  'absolute -bottom-1 left-[5px] h-12 w-12 text-gray-400 ',
                  iconClassName,
                )}
              />
            )}
          </div>
          <div className="flex flex-col ml-4 items-start">
            <div className="font-bold text-lg">
              {kid?.attributes.firstName} {kid?.attributes.lastName}
            </div>
            <div className="text-gray-400">
              {kid?.attributes.kid_pers_id_num}
            </div>
            <div className="text-gray-400">{kid?.attributes.department}</div>
          </div>
        </div>
        <div>
          <>{t('admin.chooseInviteStyle')}</>
        </div>
        <div className="flex justify-center w-full">
          <Button
            size="custom"
            variant="borderGreen"
            label={t('admin.inviteExistingUsers')}
            onClick={() => {
              setInviteExisting(true)
            }}
          />
          {kid && (
            <InviteExistingModal
              isOpen={inviteExisting}
              onClose={() => {
                setInviteExisting(false)
              }}
              kid={kid}
            />
          )}
        </div>
        <div className="flex w-full justify-center">
          <>{t('admin.orInviteNewUser')}</>
        </div>
        <div>
          <div className="font-bold text-2xl text-gray-400 flex justify-start">
            <>{t('admin.inviteOn')}</>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-x-2">
          {mobileAllowed && (
            <div
              className={classNames(
                'w-full text-center rounded-2xl cursor-pointer hover:bg-blue-300 hover:text-white transition duration-400',
                newGuardian.type === 'mobil' ? 'bg-blue-300 text-white' : '',
              )}
              onClick={() => {
                setNewGuardian({ ...newGuardian, type: 'mobil' })
              }}
            >
              <>{t('login.mobile')}</>
            </div>
          )}
          {emailAllowed && (
            <div
              className={classNames(
                'w-full text-center rounded-2xl cursor-pointer hover:bg-blue-300 hover:text-white transition duration-400',
                newGuardian.type === 'email' ? 'bg-blue-300 text-white' : '',
              )}
              onClick={() => {
                setNewGuardian({ ...newGuardian, type: 'email' })
              }}
            >
              <>{t('login.email')}</>
            </div>
          )}
          {currentUserRole?.school_pointer.territory === TERRITORY.SWEDEN && (
            <>
              {grandIdAllowed && (
                <div
                  className={classNames(
                    'w-full text-center rounded-2xl cursor-pointer hover:bg-blue-300 hover:text-white transition duration-400',
                    newGuardian.type === 'bankid'
                      ? 'bg-blue-300 text-white'
                      : '',
                  )}
                  onClick={() => {
                    setNewGuardian({ ...newGuardian, type: 'bankid' })
                  }}
                >
                  <>{t('general.socialNr')}</>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex flex-col w-full h-full gap-y-4 relative">
          {newGuardian.type === 'mobil' && (
            <PhoneInput
              countryCodeEditable
              value={newGuardian.phone}
              /*onChange={(value) => {
                setNewGuardian({ ...newGuardian, phone: value })
              }}*/
              onChange={(phone, xx, e) => {
                var s = phone
                if (s.charAt(2) === '0') {
                  e.preventDefault()
                  var position = 3 // its 1 based
                  s =
                    s.substring(0, position - 1) +
                    s.substring(position, s.length)
                }
                setNewGuardian({ ...newGuardian, phone: '+' + s })
              }}
              autoFormat={false}
              country={'se'}
            />
          )}
          {newGuardian.type === 'email' && (
            <CustomInput
              label={t('login.email')}
              value={newGuardian.email}
              onChange={(newValue) => {
                setNewGuardian({ ...newGuardian, email: newValue })
              }}
              ref={emailRef}
              required
            />
          )}
          {newGuardian.type === 'bankid' && (
            <CustomInput
              label={t('general.socialNr')}
              value={newGuardian.ssn}
              onChange={(newValue) => {
                setNewGuardian({ ...newGuardian, ssn: newValue })
              }}
              format="YYYYMMDD-XXXX"
              ref={psnRef}
              required
            />
          )}

          <div className="flex flex-col gap-y-4">
            <div className="font-bold text-2xl text-gray-400 flex justify-start">
              <>{t('admin.otherInfo')}</>
            </div>
            {newGuardian.type !== 'mobil' && (
              <PhoneInput
                country={'se'}
                autoFormat={false}
                countryCodeEditable
                onChange={(phone, xx, e) => {
                  var s = phone
                  if (s.charAt(2) === '0') {
                    e.preventDefault()
                    var position = 3 // its 1 based
                    s =
                      s.substring(0, position - 1) +
                      s.substring(position, s.length)
                  }
                  setNewGuardian({ ...newGuardian, phone: '+' + s })
                }}
              />
            )}
            {newGuardian.type !== 'email' && (
              <>
                <CustomInput
                  label={t('login.email')}
                  value={newGuardian.email}
                  onChange={(newValue) => {
                    setNewGuardian({ ...newGuardian, email: newValue })
                  }}
                  ref={emailRef}
                />
              </>
            )}
            {newGuardian.type !== 'bankid' && (
              <>
                <CustomInput
                  label={t('general.socialNr')}
                  value={newGuardian.ssn}
                  onChange={(newValue) => {
                    setNewGuardian({ ...newGuardian, ssn: newValue })
                  }}
                  format="YYYYMMDD-XXXX"
                  ref={psnRef}
                />
              </>
            )}
            <CustomInput
              required
              label={t('documents.name')}
              value={newGuardian.name}
              onChange={(newValue) => {
                setNewGuardian({ ...newGuardian, name: newValue })
              }}
              ref={nameRef}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div>
            <>{t('admin.addDocSchool')}</>
          </div>
          <div>
            <Slider
              value={newGuardian.uploadParent}
              id={0}
              onSliderClick={() => {
                setNewGuardian({
                  ...newGuardian,
                  uploadParent: !newGuardian.uploadParent,
                })
              }}
              onBoxClick={() => {}}
            />
          </div>
        </div>
        <div className="flex flex-row justify-center">
          {!newGuardian.name ||
          (newGuardian.type === 'mobil' && !newGuardian.phone) ||
          (newGuardian.type === 'email' && !newGuardian.email) ||
          (newGuardian.type === 'bankid' && !newGuardian.ssn) ? (
            <Button
              label={t('admin.invite')}
              variant={'disableGray'}
              size="custom"
            />
          ) : (
            <Button
              variant="fullBlue"
              label={t('admin.invite')}
              size="custom"
              onClick={() => {
                Swal.fire({
                  //title: 'Are you sure?',
                  text: t('admin.inviteCheck'),
                  icon: 'info',
                  showCancelButton: true,
                  focusConfirm: false,
                  showConfirmButton: true,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  cancelButtonText: t('general.cancel'),
                  confirmButtonText: t('general.yes'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    if (schoolId && kid) {
                      inviteGuardian(schoolId, kid, newGuardian)
                        .then((response) => {
                          if (response === 'OK') {
                            Swal.fire({
                              text: t('admin.inviteSent'),
                              icon: 'success',
                              showCancelButton: false,
                              showConfirmButton: true,
                              focusConfirm: false,
                              confirmButtonColor: '#7eb5f4',
                              cancelButtonColor: '#d33',
                              cancelButtonText: t('general.cancel'),
                              confirmButtonText: t('general.ok'),
                            }).finally(() => {
                              /*fetchNewUserRoles(schoolId).finally(() => {
                            setFakeState(!fakeState)
                          })*/
                              setNewGuardian(initialValues)
                              onClose()
                            })
                          }
                          if (response === 'ALREADY_INVITED') {
                            // HANTERA FELMEDDELANDE
                            Swal.fire({
                              text: t('admin.userAlreadyInvited'),
                              icon: 'error',
                              showCancelButton: false,
                              showConfirmButton: true,
                              focusConfirm: false,
                              confirmButtonColor: '#7eb5f4',
                              cancelButtonColor: '#d33',
                              cancelButtonText: t('general.cancel'),
                              confirmButtonText: t('general.ok'),
                            })
                          }
                          if (response === 'ERROR') {
                            // HANTERA FELMEDDELANDE
                            Swal.fire({
                              title: t('events.eventAlarmFail'),
                              text: t('statistics.contactSupport'),
                              icon: 'error',
                              showCancelButton: false,
                              showConfirmButton: true,
                              focusConfirm: false,
                              confirmButtonColor: '#7eb5f4',
                              cancelButtonColor: '#d33',
                              cancelButtonText: t('general.cancel'),
                              confirmButtonText: t('general.ok'),
                            })
                          }
                        })
                        .catch((e) => {
                          console.error(e)
                        })
                        .finally(() => {})
                    }
                  }
                })
              }}
            />
          )}
        </div>
      </div>
    </ModalRegular>
  )
}

export default KidCardModal
