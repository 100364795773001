import React, { useContext, useEffect, useRef, useState } from 'react'
import ModalRegular from '../../../components/ModalRegular'
import { Button } from '../../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPen } from '@fortawesome/free-solid-svg-icons'
import DepartmentCard from './components/DepartmentCard'
import { t } from 'i18next'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { Department } from '../../../Models/Department'
import { SchoolStoreContext } from '../../../store/schoolStore'
import { useParams } from 'react-router-dom'
import CreateDepartment from './components/CreateDepartment'

interface DepartmentsModalProps {
  onClose: () => void
  isOpen: boolean
}

const DepartmentsModal: React.FC<DepartmentsModalProps> = ({
  onClose,
  isOpen,
}) => {
  const [showAddDepartment, setShowAddDepartment] = useState(false)

  const { fetchDepartments, departments } = useContext(DepartmentStoreContext)

  const { schoolId } = useParams()
  useEffect(() => {
    if (schoolId) {
      fetchDepartments(schoolId, true)
    }
  }, [schoolId])

  const focusInput = (
    e: React.MouseEvent,
    inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e.stopPropagation()
    inputRef.current?.focus()
  }
  return (
    <ModalRegular
      onClose={onClose}
      isOpen={isOpen}
      bgColor="bg-blue-300 p-4"
      title={t('admin.departments')}
      closeColor="white"
    >
      <div className="flex flex-wrap flex-col w-full items-center gap-y-4 mt-4 pb-4 ">
        {departments?.map((department) => {
          return <DepartmentCard department={department} />
        })}

        <div className="flex justify-end w-full pr-4">
          <Button
            icon={faPlus}
            variant="fullBlue"
            onClick={() => {
              setShowAddDepartment(true)
            }}
          />
          <CreateDepartment
            isOpen={showAddDepartment}
            onClose={() => {
              setShowAddDepartment(false)
            }}
            department={new Department()}
          />
        </div>
      </div>
    </ModalRegular>
  )
}

export default DepartmentsModal
