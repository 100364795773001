import React, { useEffect, useRef, useState } from 'react'
import { Button } from './Button'
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { t } from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'
import VideoPlayer from './Videoplayer'

export type MediaItem = {
  type: 'image' | 'video'
  thumbnailSrc?: string
  src: string
}

export const ImageCarousel = ({
  images = [],
  imageOnClick,
  videoOnClick,
  className,
  arrowSmall,
  displayMode = 'carousel',
}: {
  images: MediaItem[]
  imageOnClick: (media: MediaItem) => void
  videoOnClick?: any
  className?: string
  arrowSmall?: boolean
  displayMode?: 'carousel' | 'grid'
}) => {
  const carousel = useRef(null) as any
  const imageRef = React.useRef() as any
  const [showVideoPlayer, setShowVideoPlayer] = useState(false)
  const [currentVideoSrc, setCurrentVideoSrc] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)
  const maxScrollWidth = useRef(0)

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft =
        (imageRef?.current?.clientWidth ?? 0) * currentIndex
    }
  }, [currentIndex])

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0
  }, [])

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1)
    }
  }

  const moveNext = () => {
    console.log('moving next')
    if (carousel.current !== null) {
      setCurrentIndex((prevState) => prevState + 1)
    }
  }

  const renderMediaItem = (media: MediaItem, index: number) => {
    if (media.type === 'image') {
      return (
        <img
          ref={imageRef}
          key={index}
          className="object-cover w-full h-[220px]"
          src={media.src}
          alt=""
          onClick={(e) => {
            e.stopPropagation()
            imageOnClick(media)
          }}
        />
      )
    }

    if (media.type === 'video') {
      return (
        <div
          key={index}
          className="object-cover w-full h-[220px] relative"
          onClick={(e) => {
            e.stopPropagation()
            videoOnClick(media)
          }}
        >
          <img src={media.thumbnailSrc} className="w-full h-full" />
          <FontAwesomeIcon
            icon={faPlay}
            className="absolute hover:scale-110 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-3xl"
          />
        </div>
      )
    }
    return null
  }
  const chunkArray = (arr: any, chunk_size: any) => {
    const results = []
    while (arr.length) {
      results.push(arr.splice(0, chunk_size))
    }
    return results
  }

  const renderGridView = () => {
    const imageChunks = chunkArray([...images], 4)
    const totalSlides = imageChunks.length

    const moveNextPage = () => {
      if (currentIndex < imageChunks.length - 1) {
        setCurrentIndex((prevState) => prevState + 1)
      }
    }

    const movePrevPage = () => {
      if (currentIndex > 0) {
        setCurrentIndex((prevState) => prevState - 1)
      }
    }

    return (
      <div className={`carousel-wrapper ${className} bg-gray-50`}>
        {imageChunks.length === 0 ? (
          // Render placeholder if no images
          <div className="relative flex flex-col items-center w-full h-full">
            <div className="transform scale-50 w-full h-full flex justify-center items-center">
              <img
                ref={imageRef}
                key={1}
                className={
                  'object-contain z-10 w-full h-full shrink-0 snap-start'
                }
                src={'/images/Tyra_tomt.png'}
                alt=""
              />
              <div className="absolute w-full h-full flex items-center justify-center">
                <div className="w-fit text-center cursor-default font-medium text-gray-600 text-4xl z-20 bg-white p-4 rounded-full">
                  <>{t('blog.noPic')} </>
                </div>
              </div>
            </div>
          </div>
        ) : (
          imageChunks.map((chunk, chunkIndex) => (
            <div
              key={chunkIndex}
              className={`slide ${
                currentIndex === chunkIndex ? 'active' : 'hidden'
              }`}
            >
              {chunk.length === 1 ? (
                <div className="w-full h-[445px] object-cover relative">
                  {chunk[0].type === 'video' ? (
                    <>
                      <img
                        src={chunk[0].thumbnailSrc}
                        alt=""
                        className="object-cover w-full h-full"
                        onClick={(e) => {
                          e.stopPropagation()
                          videoOnClick(chunk[0])
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faPlay}
                        className="absolute hover:scale-110 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-3xl"
                      />
                    </>
                  ) : (
                    <img
                      src={chunk[0].src}
                      alt=""
                      className="object-cover w-full h-full"
                      onClick={(e) => {
                        e.stopPropagation()
                        imageOnClick(chunk[0])
                      }}
                    />
                  )}

                  {/*chunk.map((mediaItem: MediaItem, itemIndex: number) =>
                    renderMediaItem(mediaItem, chunkIndex * 4 + itemIndex),
                )*/}
                </div>
              ) : chunk.length === 2 ? (
                <div className="grid grid-cols-1 h-[445px] w-full gap-y-[0px]">
                  {chunk.map((mediaItem: MediaItem, itemIndex: number) =>
                    renderMediaItem(mediaItem, chunkIndex * 4 + itemIndex),
                  )}
                </div>
              ) : chunk.length === 3 ? (
                <div className="grid grid-rows-2 gap-y-[3px]">
                  <div className="grid grid-cols-2 gap-x-[3px]">
                    {chunk
                      .slice(0, 2)
                      .map((mediaItem: MediaItem, itemIndex: number) =>
                        renderMediaItem(mediaItem, chunkIndex * 4 + itemIndex),
                      )}
                  </div>
                  <div className="col-span-2 gap-[2px]">
                    {renderMediaItem(chunk[2], chunkIndex * 4 + 2)}
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-[3px]">
                  {chunk.map((mediaItem: MediaItem, itemIndex: number) =>
                    renderMediaItem(mediaItem, chunkIndex * 4 + itemIndex),
                  )}
                </div>
              )}
            </div>
          ))
        )}

        {currentIndex !== 0 && (
          <div
            className={classNames(
              'absolute left-3',
              arrowSmall && 'left-1 bottom-12',
            )}
            style={{
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <Button
              size={arrowSmall ? 'xs2' : 'lg'}
              icon={faChevronCircleLeft}
              onClick={(e) => {
                e.stopPropagation()
                movePrevPage()
              }}
            />
          </div>
        )}
        {currentIndex < totalSlides - 1 && (
          <div
            className={classNames(
              'absolute right-3',
              arrowSmall && 'right-1 bottom-12',
            )}
            style={{
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <Button
              size={arrowSmall ? 'xs2' : 'lg'}
              icon={faChevronCircleRight}
              onClick={(e) => {
                e.stopPropagation()
                moveNextPage()
              }}
            />
          </div>
        )}
      </div>
    )
  }

  const renderCarouselView = () => {
    return (
      <div className={'relative h-full'}>
        <div
          ref={carousel}
          className={classNames(
            'flex snap-mandatory overflow-y-scroll scroll-smooth scrollbar-hidden h-full',
            className ? className : '',
          )}
        >
          {images && images.length === 0 ? (
            <div className="relative flex flex-col items-center w-full h-full">
              <div className="transform scale-50 w-full h-full flex justify-center items-center">
                <img
                  ref={imageRef}
                  key={1}
                  className={
                    'object-contain z-10 w-full h-full shrink-0 snap-start'
                  }
                  src={'/images/Tyra_tomt.png'}
                  alt=""
                />
                <div className="absolute w-full h-full flex items-center justify-center">
                  <div className="w-fit text-center cursor-default font-medium text-gray-600 text-4xl z-20 bg-white p-4 rounded-full">
                    <>{t('blog.noPic')} </>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {images?.map((media: MediaItem, index: number) => {
                if (media.type === 'image') {
                  return (
                    <img
                      ref={imageRef}
                      key={index}
                      className={
                        'object-contain z-0 cursor-pointer w-full h-full shrink-0 snap-start'
                      }
                      src={media.src}
                      alt=""
                      onClick={(e) => {
                        e.stopPropagation()
                        imageOnClick(media)
                      }}
                    />
                  )
                }
                if (media.type === 'video') {
                  return (
                    <div
                      key={index}
                      className="relative object-contain z-0 cursor-pointer w-full h-full shrink-0 snap-start"
                      onClick={(e) => {
                        e.stopPropagation()
                        videoOnClick(media) // Här kommer vi ändra videoOnClick för att öppna videon i ett nytt fönster
                      }}
                    >
                      <img src={media.thumbnailSrc} className="w-full h-full" />
                      <FontAwesomeIcon
                        icon={faPlay}
                        className="absolute hover:scale-110 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-3xl"
                      />
                    </div>
                  )
                }
                return null
              })}
            </>
          )}
          {showVideoPlayer && (
            <VideoPlayer
              src={currentVideoSrc}
              onClose={() => setShowVideoPlayer(false)}
            />
          )}
        </div>

        {currentIndex !== 0 && (
          <div
            className={classNames(
              'absolute left-3',
              arrowSmall && 'left-1 bottom-12',
            )}
            style={{
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <Button
              size={arrowSmall ? 'xs2' : 'lg'}
              icon={faChevronCircleLeft}
              onClick={(e) => {
                e.stopPropagation()
                movePrev()
              }}
            />
          </div>
        )}
        {currentIndex < images?.length - 1 && (
          <div
            className={classNames(
              'absolute right-1',
              arrowSmall && 'right-1 bottom-12',
            )}
            style={{
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <Button
              size={arrowSmall ? 'xs2' : 'lg'}
              icon={faChevronCircleRight}
              onClick={(e) => {
                e.stopPropagation()
                moveNext()
              }}
            />
          </div>
        )}
      </div>
    )
  }

  return displayMode === 'grid' ? renderGridView() : renderCarouselView()
}
