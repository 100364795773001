import React, { RefObject, useContext, useState } from 'react'
import { faCog, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { useClickOutside } from '../../hooks/useClickoutside'
import { ButtonDropdown } from '../../components/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { UserStoreContext } from '../../store/userStore'
import { useNavigate, useParams } from 'react-router-dom'
import {
  faBell,
  faBook,
  faDownload,
  faFileLines,
  faInfoCircle,
  faUsersCog,
  faVideo,
} from '@fortawesome/pro-solid-svg-icons'
import DownloadModal from '../../views/settings/components/DownloadModal'
import EditProfilModal from '../../views/settings/components/EditProfilModal'
import AboutTyraModal from '../../views/settings/components/AboutTyraModal'

export const UserProfile: React.FC<{ onClick?: any }> = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [menuRef, togglerRef] = useClickOutside(() => setIsOpen(false))
  const { t } = useTranslation()
  const { logoutUser } = useContext(UserStoreContext)
  const navigate = useNavigate()
  const [openDownload, setOpenDownload] = useState(false)
  const [openAboutModal, setOpenAboutModal] = useState(false)
  const [openEditProfile, setOpenEditProfile] = useState(false)
  const { schoolId } = useParams()
  const { currentUserRole } = useContext(UserStoreContext)
  return (
    <div className="relative flex items-end">
      <button
        ref={togglerRef}
        type="button"
        className="w-full rounded-md group focus:outline-none"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className="flex items-center justify-between w-full">
          <span className="flex items-center justify-between min-w-0">
            <div className="text-xl group-hover:text-blue-50">
              <FontAwesomeIcon icon={faCog} />
            </div>
          </span>
          <svg
            className="flex-shrink-0 w-5 h-5 text-transparent group-hover:text-blue-50"
            x-description="Heroicon name: selector"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </span>
      </button>

      {isOpen && (
        <div
          ref={menuRef as RefObject<HTMLDivElement>}
          className="absolute right-0 z-50 mx-3 origin-top divide-y w-80 top-10"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <ButtonDropdown.Menu>
            <ButtonDropdown.Section>
              <ButtonDropdown.Button
                icon={faUsersCog}
                label={t('settings.myConnections')}
                onClick={() => {
                  navigate(
                    `/settings/${currentUserRole?.attributes?.school_pointer?.id}/MyConnections`,
                  )
                  if (onClick) {
                    onClick()
                  }
                  setIsOpen(false)
                }}
              />
              <>
                {process.env.REACT_APP_PUBLIC_URL?.includes('localhost') && (
                  <>
                    <ButtonDropdown.Button
                      icon={faUser}
                      label={t('settings.myProfile')}
                      onClick={() => {
                        setOpenEditProfile(true)
                        setIsOpen(false)
                      }}
                    />
                  </>
                )}
                {process.env.REACT_APP_PUBLIC_URL?.includes('localhost') ||
                process.env.REACT_APP_PUBLIC_URL?.includes('alfa') ? (
                  <>
                    <ButtonDropdown.Button
                      icon={faInfoCircle}
                      label={t('settings.aboutTYRA')}
                      onClick={() => {
                        setOpenAboutModal(true)
                        setIsOpen(false)
                      }}
                    />
                    <ButtonDropdown.Button
                      icon={faBook}
                      label={t('settings.lazyDogAndDoc')}
                      onClick={() => {
                        navigate(
                          `/settings/${currentUserRole?.attributes?.school_pointer?.id}/DocAndLathund`,
                        )
                        if (onClick) {
                          onClick()
                        }
                        setIsOpen(false)
                      }}
                    />
                    <ButtonDropdown.Button
                      icon={faBell}
                      label={t('settings.notificationsAndPush')}
                      onClick={() => {
                        navigate(
                          `/settings/${currentUserRole?.attributes?.school_pointer?.id}/NoticeAndPush`,
                        )
                        if (onClick) {
                          onClick()
                        }
                        setIsOpen(false)
                      }}
                    />
                    <ButtonDropdown.Button
                      icon={faFileLines}
                      label={t('settings.termsOfUseAndPrivacy')}
                      onClick={() => {
                        navigate(
                          `/settings/${currentUserRole?.attributes?.school_pointer?.id}/TermsAndPrivacy`,
                        )
                        if (onClick) {
                          onClick()
                        }
                        setIsOpen(false)
                      }}
                    />
                    <ButtonDropdown.Button
                      icon={faVideo}
                      label={t('settings.demoVideo')}
                      onClick={() => {
                        navigate(
                          `/settings/${currentUserRole?.attributes?.school_pointer?.id}/DemoVideo`,
                        )
                        if (onClick) {
                          onClick()
                        }
                        setIsOpen(false)
                      }}
                    />
                  </>
                ) : null}
              </>
              <ButtonDropdown.Button
                icon={faDownload}
                label={t('blog.myDownloads')}
                onClick={() => {
                  setOpenDownload(true)
                  setIsOpen(false)
                }}
              />
              <ButtonDropdown.Button
                icon={faSignOutAlt}
                label={t('login.logout')}
                onClick={async () => {
                  logoutUser().finally(() => {
                    navigate('/')
                  })
                }}
              />
            </ButtonDropdown.Section>
          </ButtonDropdown.Menu>
        </div>
      )}
      {openDownload && (
        <DownloadModal
          showModal={openDownload}
          hideModal={() => {
            setOpenDownload(false)
          }}
        />
      )}
      {openEditProfile && (
        <EditProfilModal
          showModal={openEditProfile}
          hideModal={() => {
            setOpenEditProfile(false)
          }}
        />
      )}
      {openAboutModal && (
        <AboutTyraModal
          showModal={openAboutModal}
          hideModal={() => {
            setOpenAboutModal(false)
          }}
        />
      )}
    </div>
  )
}
