import { BloggDoc } from '../../../Models/BloggDoc'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'
import { observer } from 'mobx-react'
import { PortfolioBlogStoreContext } from '../../../store/portfolioBlogStore'
import ModalRegular from '../../../components/ModalRegular'
import { Spinner } from '../../../components/Spinner'
import { ImageCarousel, MediaItem } from '../../../components/ImageCarousel'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getImageFromRole, getImageFromUser } from '../../../utils/parseUtils'
import { t } from 'i18next'
import {
  faEllipsisVertical,
  faFolder,
  faPaperPlane,
  faTrashCan,
  faHeart as farFaHeart,
} from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../components/Button'
import {
  faBooks,
  faCalendarDay,
  faComment,
  faFile,
  faFilePdf,
  faPen,
  faPrint,
  faShare,
  faTag,
  faThoughtBubble,
} from '@fortawesome/pro-solid-svg-icons'
import { faThoughtBubble as farFaThoughtBubble } from '@fortawesome/pro-regular-svg-icons'
import Swal from 'sweetalert2'
import CommentCard from '../../blog/components/CommentCard'
import { UserStoreContext } from '../../../store/userStore'
import { ButtonDropdown } from '../../../components/Dropdown'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { User } from '../../../Models/User'
import AudioPlayer from '../../../components/Audioplayer'
import { BlogReflections } from '../../blog/components/BlogReflections'
import { KidsStoreContext } from '../../../store/kidsStore'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { TagStoreContext } from '../../../store/tagStore'
import { DocTag } from '../../../Models/DocTag'
import EditModal from './EditModal'
import { useParams } from 'react-router-dom'
import { AdminStoreContext } from '../../../store/adminStore'
import VideoModal from '../../../components/VideoModal'
import { BloggReflections } from '../../../Models/BloggReflections'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import { ExportStoreContext } from '../../../store/exportStore'
import TimeStamp from '../../../components/TimeStamp'
import ExportModal from './ExportModal'
import { Department } from '../../../Models/Department'
import { BloggComments } from '../../../Models/BloggComments'
import { FileJobStoreContext } from '../../../store/fileJobStore'
import { Loading } from '../../../components/Loading'
import { Kid } from '../../../Models/Kid'

export const getFullMonthForTranslatation = (months: string) => {
  switch (months) {
    case '01':
      return t('months.january')
    case '02':
      return t('months.february')
    case '03':
      return t('months.march')
    case '04':
      return t('months.april')
    case '05':
      return t('months.may')
    case '06':
      return t('months.june')
    case '07':
      return t('months.july')
    case '08':
      return t('months.august')
    case '09':
      return t('months.september')
    case '10':
      return t('months.october')
    case '11':
      return t('months.november')
    case '12':
      return t('months.december')
  }
}

export const BlogItem = ({
  bloggDoc,
  onImageSelected,
  reflections,
  onSaveReflections,
  type,
  kidName,
  kidFirstName,
  kid,
}: {
  bloggDoc: BloggDoc
  onImageSelected: any
  reflections?: BloggReflections[]
  onSaveReflections?: () => void
  type: 'Blog' | 'Portfolio'
  kidName?: string
  kidFirstName?: string
  kid?: Kid
}) => {
  const { t } = useTranslation()
  const { updateReflection, updateHeart } = useContext(
    PortfolioBlogStoreContext,
  )

  const [showFullText, setShowFullText] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showLikeModal, setShowLikeModal] = useState(false)
  const [showMoreCurriculums, setShowMoreCurriculums] = useState<any>([])
  const showLikes =
    bloggDoc?.attributes?.port_emotable || bloggDoc?.attributes?.blog_emotable
  const showComment =
    bloggDoc?.attributes?.port_commentable ||
    bloggDoc?.attributes?.blog_commentable

  const showReflection =
    bloggDoc?.attributes?.port_reflectional ||
    bloggDoc?.attributes?.blog_reflectional
  const translateTags = (tag: string) => {
    switch (tag) {
      case 'Språk':
        return t('tags.language')
      case 'Matematik':
        return t('tags.math')
      case 'Motorik':
        return t('tags.motor_skills')
      case 'Skapande':
        return t('tags.creativity')
      case 'EQ':
        return t('tags.eq')
      default:
        return tag
    }
  }
  const translateRelation = (relation: string) => {
    switch (relation) {
      case 'Personal':
        return t('documents.staff')
      case 'Mamma':
        return t('general.mother')
      case 'Pappa':
        return t('general.father')
      case 'Vårdnadshavare':
        return t('documents.parent')
      default:
        return relation
    }
  }
  const [wave, setWave] = useState(false)
  const triggerWave = () => {
    setWave((prevState) => {
      return !prevState
    })
  }
  const { defaultTags, customTags } = useContext(TagStoreContext)
  const { fetchKids, kids, search, fetchUserRoles, kidNoBlog } =
    useContext(KidsStoreContext)
  const { currentUserRole, currentUser } = useContext(UserStoreContext)

  const isTeacher =
    currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
      ? true
      : false

  const isAdmin =
    currentUserRole && currentUserRole?.role_type >= USER_ROLE_TYPE.ADMIN
      ? true
      : false

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }
  const [showCurriculumsModal, setShowCurriculumsModal] = useState(false)
  const [openReflection, setOpenReflection] = useState(false)
  const [showInput, setShowInput] = useState(false)

  const [reflection, setReflection] = useState(
    bloggDoc?.attributes?.blog_reflection ?? '',
  )
  const handleOnClick = async () => {
    if (textareaValue !== '') {
      try {
        await updateReflection(bloggDoc, textareaValue)
        setReflection(textareaValue)
      } catch (error) {
        console.error('Failed to update reflection:', error)
      }
    }
  }
  let { schoolId, kidId } = useParams()

  /*useEffect(() => {
    if (currentUserRole && currentUserRole?.school_pointer) {
      fetchKids(currentUserRole)
      fetchDepartments(currentUserRole?.attributes?.school_pointer?.id, true)
      fetchUserRoles(currentUserRole)
    }
  }, [currentUserRole?.id])*/
  const { departments, selectedDepartments } = useContext(
    DepartmentStoreContext,
  )

  useEffect(() => {
    const closeDatePicker = () => {
      setDatePickerOpen(false)
    }

    document.addEventListener('click', closeDatePicker)

    return () => {
      document.removeEventListener('click', closeDatePicker)
    }
  }, [])
  const [openEditModal, setOpenEditModal] = useState(false)
  const [currentBlogId, setCurrentBlogId] = useState<string | null>(null)
  const [textareaValue, setTextareaValue] = useState('')
  useEffect(() => {
    if (showInput) {
      setTextareaValue(reflection)
    }
  }, [showInput])
  const [menuRef, togglerRef] = useClickOutside(() => {
    setDropdownVisible(false)
  })
  const [showMoreTags, setShowMoreTags] = useState<any>([])
  let displayText = ''
  const publishAs = bloggDoc?.attributes?.publish_as
  let showPublishImage = true
  let department: Department | null | undefined = null
  switch (publishAs) {
    case undefined:
      displayText =
        bloggDoc?.attributes?.user_author?.attributes?.firstName +
        ' ' +
        bloggDoc?.attributes?.user_author?.attributes?.lastName
      break
    case 'school':
      showPublishImage = false
      displayText =
        bloggDoc?.attributes?.school_pointer?.attributes?.display_school_name
      break
    case 'anonymous':
      showPublishImage = false
      displayText = ''
      break
    default:
      if (publishAs?.startsWith('dep:')) {
        const depId = publishAs.replace('dep:', '')
        department = departments?.find((dep) => dep.id === depId)
        displayText = department?.klassName ?? ''
      } else {
        displayText = ''
      }
      break
  }
  // console.log('pulisera', publishAs)
  const toggleHiddenKidSelection = (kidId: string) => {
    if (selectedHiddenKids.includes(kidId)) {
      setSelectedHiddenKids(selectedHiddenKids.filter((id) => id !== kidId))
    } else {
      setSelectedHiddenKids([...selectedHiddenKids, kidId])
    }
  }
  const [selectedHiddenKids, setSelectedHiddenKids] = useState<string[]>([])

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const datePickerRef = useRef(null)

  const now = moment()
  const publishDate = moment(bloggDoc?.attributes?.publish_date)
  const updatedAt = bloggDoc?.attributes?.updatedAt

  const isUpdated = updatedAt && moment(updatedAt).diff(publishDate) !== 0
  const dateToDisplay = isUpdated ? moment(updatedAt) : publishDate

  const diffInMinutes = now.diff(dateToDisplay, 'minutes')
  let displayDate = ''

  if (diffInMinutes < 60) {
    displayDate = diffInMinutes + ' ' + t('general.minutesAgo')
  } else if (diffInMinutes < 120) {
    // Less than 2 hours
    displayDate = t('general.justOverAnHourAgo')
  } else if (diffInMinutes < 24 * 60) {
    // Less than 24 hours
    displayDate = Math.floor(diffInMinutes / 60) + ' ' + t('general.hoursAgo')
  } else if (diffInMinutes < 7 * 24 * 60) {
    // Less than 7 days
    displayDate = dateToDisplay.format('ddd HH:mm')
  } else {
    displayDate = dateToDisplay.format('MMM DD')
  }

  const [isExpanded, setIsExpanded] = useState(false)
  const [isExpandedTags, setIsExpandedTags] = useState(false)
  const [selectedVideoSrc, setSelectedVideoSrc] = useState<string>('')
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false)
  const { downloadAndZip } = useContext(FileJobStoreContext)

  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [fakeState, setFakeState] = useState('')
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDatePickerOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])
  const [selectedTags, setSelectedTags] = useState<DocTag[]>([])
  const toggleTag = (tag: DocTag) => {
    const isTagSelected = selectedTags.some((t) => t.id === tag.id)

    if (isTagSelected) {
      const newTags = selectedTags.filter((t) => t.id !== tag.id)
      setSelectedTags(newTags)
    } else {
      setSelectedTags([...selectedTags, tag])
    }
  }

  const handleVideoClick = (media: { type: string; src: string }) => {
    setSelectedVideoSrc(media.src)
    setShowVideoModal(true)
  }
  const hideVideoModalFunction = () => {
    setShowVideoModal(false)
  }

  const { getCurrContent, getCurrTag } = useContext(ExportStoreContext)
  const [openExportModal, setOpenExportModal] = useState<string | null>(null)
  const [selectedBlogDocId, setSelectedBlogDocId] = useState<string | null>(
    null,
  )
  const [buttonType, setButtonType] = useState<'Print' | 'Generate'>()

  const [loading, setLoading] = useState(false)

  const handlePrintClick = () => {
    setButtonType('Print')
    setOpenExportModal(bloggDoc.id)
  }
  const handleGeneratePdfClick = () => {
    setButtonType('Generate')
    setOpenExportModal(bloggDoc.id)
  }
  const canExport =
    type === 'Blog' && 'Portfolio' ? (isTeacher ? true : false) : true
  return (
    <div
      className={
        'shadow relative rounded-2xl overflow-hidden cursor-pointer hover:bg-blue-50 '
      }
    >
      {loading && <Loading />}
      <div
        className={
          'flex flex-wrap flex-row bg-gray-50 text-start gap-x-2 p-4 relative'
        }
      >
        {bloggDoc.attributes.event_date ? (
          <>
            <div className="flex flex-row gap-2">
              <div className="flex items-center">
                <FontAwesomeIcon
                  className="text-red-300 h-6 w-6"
                  icon={faCalendarDay}
                />
              </div>
            </div>

            <div className="">
              <p className="text-gray-500 text-xs">{t('blog.eventDate')}</p>
              <p className={'text-xs text-gray-700 '}>
                {moment(bloggDoc?.attributes?.event_date).format('Do')}{' '}
                {getFullMonthForTranslatation(
                  moment(bloggDoc?.attributes?.event_date).format('MM'),
                )}{' '}
                {moment(bloggDoc?.attributes?.event_date).format('YYYY')}
              </p>
            </div>
          </>
        ) : (
          <div className="flex w-full h-8"></div>
        )}

        <div className="absolute top-3 -right-1 z-10000">
          {canExport && (
            <button
              type="button"
              className="w-full rounded-md group focus:outline-none "
              aria-haspopup="true"
              aria-expanded={dropdownVisible}
              onClick={(e) => {
                e.stopPropagation()
                toggleDropdown()
                setCurrentBlogId(bloggDoc.id)
              }}
              ref={togglerRef}
            >
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="text-gray-400 absolute top-3 right-1 px-4 hover:scale-110 "
              ></FontAwesomeIcon>
            </button>
          )}

          {dropdownVisible && (
            <div
              className="absolute right-5 z-50 mx-3 origin-top divide-y w-[280px] top-2"
              style={{
                display:
                  dropdownVisible && currentBlogId === bloggDoc.id
                    ? 'block'
                    : 'none',
              }}
              ref={menuRef}
            >
              <ButtonDropdown.Menu key={bloggDoc.id}>
                <ButtonDropdown.Section>
                  <>
                    <ButtonDropdown.Button
                      key={bloggDoc.id}
                      icon={faPrint}
                      label={t('blog.print')}
                      className="text-purple-300"
                      onClick={(e) => {
                        //setSelectedBlogDocId(bloggDoc.id)
                        setOpenExportModal(bloggDoc.id)
                        handlePrintClick()
                      }}
                    />
                  </>
                  <>
                    <ButtonDropdown.Button
                      key={bloggDoc.id}
                      icon={faFilePdf}
                      label={t('blog.generatePDF')}
                      className="text-green-300"
                      onClick={(e) => {
                        setOpenExportModal(bloggDoc.id)
                        handleGeneratePdfClick()
                        e.stopPropagation()
                      }}
                    />
                  </>
                  <>
                    <ButtonDropdown.Button
                      key={bloggDoc.id}
                      icon={faFolder}
                      label={t('blog.exportMedia')}
                      className="text-blue-300"
                      onClick={(e) => {
                        setLoading(true)
                        downloadAndZip(
                          [bloggDoc],
                          type === 'Blog' ? 'blogg' : 'portfolio',
                          kidFirstName,
                        ).finally(() => {
                          setLoading(false)
                        })
                        e.stopPropagation()
                      }}
                    />
                  </>
                  <>
                    {/*
                    <ButtonDropdown.Button
                      key={bloggDoc.id}
                      icon={faPen}
                      label={t('blog.edit')}
                      className="text-orange-300"
                      onClick={(e) => {
                        setOpenEditModal(true)
                        e.stopPropagation()
                      }}
                    />
                    {openEditModal && (
                      <EditModal
                        isOpen={openEditModal}
                        onClose={() => {
                          setOpenEditModal(false)
                        }}
                      />
                      )}*/}
                  </>
                  {/*
                  <>
                    <ButtonDropdown.Button
                      key={bloggDoc.id}
                      icon={faFile}
                      label={t('documents.copy')}
                      className="text-green-500"
                      onClick={(e) => {
                        setOpenEditModal(true)
                        e.stopPropagation()
                      }}
                    />
                    </>*/}

                  {/* <>
                    <ButtonDropdown.Button
                      key={bloggDoc.id}
                      icon={faTrashCan}
                      label={t('absence.delete')}
                      className="text-red-700"
                      onClick={() => {
                        Swal.fire({
                          text: t('blog.bloggRemoveText'),
                          icon: 'warning',
                          showCancelButton: true,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      }}
                    />
                    </>*/}
                </ButtonDropdown.Section>
              </ButtonDropdown.Menu>
            </div>
          )}
        </div>
        {/*  Todo add PDF generation on cloud first
        <Button
          size={'sm'}
          dropdownIcon={faEllipsisV}
          variant={'transparent'}
          dropdown={
            <div>
              <ButtonDropdown.Menu>
                <ButtonDropdown.Section>
                  <ButtonDropdown.Button
                    label="Dela pdf"
                    icon={faImages}
                    onClick={() => {}
                  />
                </ButtonDropdown.Section>
              </ButtonDropdown.Menu>
            </div>
          }
        />*/}
      </div>
      {bloggDoc && openExportModal === bloggDoc.id && (
        <ExportModal
          typeOfDoc={type}
          buttonType={buttonType}
          type={'OnlySliders'}
          isOpen={true}
          bloggDoc={bloggDoc}
          onClose={() => {
            setOpenExportModal(null)
          }}
          onImageSelected={undefined}
          schoolId={schoolId ?? currentUserRole?.school_pointer.id}
          kidName={kidName}
          kidId={kidId ?? kid?.id}
        />
      )}
      <VideoModal
        src={selectedVideoSrc}
        showModal={showVideoModal}
        hideModal={hideVideoModalFunction}
      />
      <div
        onClick={() => {
          setShowModal(true)
        }}
      >
        {type === 'Blog' && (
          <div className="text-normal font-normal text-sm h-4 w-full px-2 ">
            {bloggDoc?.attributes?.blogg_department?.length ===
            departments?.length ? (
              <div className="text-xs font-bold">
                {t('blog.allDepartments')}
              </div>
            ) : (
              <div className="flex flex-row gap-x-2">
                {bloggDoc?.attributes?.blogg_department?.map(
                  (bloggDoc: BloggDoc) => (
                    <div className="text-xs font-bold">
                      {bloggDoc?.attributes?.klassName}
                    </div>
                  ),
                )}
              </div>
            )}
          </div>
        )}

        <div className={'relative'}>
          <ImageCarousel
            className={'md:h-[450px]'}
            images={bloggDoc?.attributes?.images
              ?.filter((item: any) => !item?.attributes?.audio_file)
              ?.map((item: any) => {
                if (item?.attributes?.image?._url) {
                  return {
                    type: item?.attributes?.is_video ? 'video' : 'image',
                    thumbnailSrc: item?.attributes?.image?._url,
                    src: item?.attributes?.is_video
                      ? item?.attributes?.video_file
                      : item?.attributes?.image?._url,
                  }
                }
                return null
              })
              .filter(Boolean)}
            displayMode="grid"
            imageOnClick={(media: { type: string; src: string }) => {
              onImageSelected(media.src)
            }}
            videoOnClick={handleVideoClick}
          />
        </div>

        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
          className={'flex justify-between space-x-2 pt-2 pl-3'}
        >
          <div
            className={
              'flex flex-row space-x-2 items-center justify-start w-full'
            }
          >
            {showComment && (
              <div className="flex flex-row space-x-2 items-center">
                <FontAwesomeIcon
                  className={classNames(
                    'text-xl text-green-500 cursor-pointer hover hover:text-green-700 hover:scale-110',
                    !showComment &&
                      ' text-green-500 cursor-auto hover:text-green-700 hover:scale-110',
                  )}
                  icon={faComment}
                  onClick={() => {
                    if (showComment) {
                      setShowModal(true)
                    }
                  }}
                />

                <p
                  className={classNames(
                    ' font-bold cursor-pointer ',
                    !showComment && 'font-bold cursor-default',
                  )}
                  onClick={() => {
                    if (showComment) {
                      setShowModal(true)
                    }
                  }}
                >
                  ({bloggDoc?.attributes.comments_count ?? 0})
                </p>
              </div>
            )}
            {showLikes && (
              <>
                <div
                  className={
                    wave ? 'animate-shakeHeart flex flex-row' : 'flex flex-row'
                  }
                >
                  {bloggDoc.attributes.emote_heart?.find(
                    (id: string) => id === currentUser?.id,
                  ) ? (
                    <FontAwesomeIcon
                      className="text-red-600 text-xl hover:text-red-600 hover:scale-110 cursor-pointer"
                      icon={farFaHeart}
                      onClick={() => {
                        if (isTeacher) {
                          setShowLikeModal(true)
                        } else {
                          updateHeart(
                            bloggDoc,
                            bloggDoc.attributes.emote_heart?.find(
                              (userId: string) => userId === currentUser?.id,
                            ),
                          ).finally(() => {
                            if (onSaveReflections) onSaveReflections()
                          })
                        }
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="text-gray-600 text-xl hover:text-red-600 hover:scale-110 cursor-pointer"
                      icon={faHeart}
                      onClick={() => {
                        if (isTeacher) {
                          setShowLikeModal(true)
                        } else {
                          updateHeart(
                            bloggDoc,
                            bloggDoc.attributes.emote_heart?.find(
                              (userId: string) => userId === currentUser?.id,
                            ),
                          ).finally(() => {
                            if (onSaveReflections) onSaveReflections()
                          })
                        }
                      }}
                    />
                  )}
                </div>
                {isTeacher && (
                  <div>({bloggDoc?.attributes.emote_heart?.length ?? 0}) </div>
                )}
              </>
            )}
            <BlogLikeModul
              showLikeModal={showLikeModal}
              hideLikeModal={() => {
                setShowLikeModal(false)
              }}
              bloggDoc={bloggDoc}
            />

            {bloggDoc?.attributes?.curriculums &&
              bloggDoc?.attributes?.curriculums.length > 0 && (
                <div className="flex flex-row space-x-1 items-center">
                  <FontAwesomeIcon
                    className="text-yellow-500 w-4 h-4 cursor-pointer hover:scale-110 hover:text-yellow-600"
                    icon={faBooks}
                    onClick={() => {
                      {
                        if (showMoreCurriculums) {
                          setShowCurriculumsModal(true)
                        }
                      }
                    }}
                  />{' '}
                  <p className="">
                    {' '}
                    ({bloggDoc?.attributes?.curriculums?.length})
                  </p>
                </div>
              )}
            {showReflection && isTeacher && (
              <>
                <FontAwesomeIcon
                  className={`text-${
                    bloggDoc.attributes.reflections_count > 0
                      ? 'eventsMain'
                      : 'gray-300'
                  } text-xl ${
                    bloggDoc.attributes.blog_reflection?.find(
                      (id: string) => id === currentUser?.id,
                    )
                      ? 'hover:text-blue-300'
                      : 'hover:text-red-300'
                  } hover:scale-110 cursor-pointer`}
                  icon={
                    bloggDoc.attributes.reflections_count > 0
                      ? faThoughtBubble
                      : farFaThoughtBubble
                  }
                  onClick={() => {
                    setOpenReflection(true)
                  }}
                />
                <p>({bloggDoc.attributes.reflections_count ?? 0})</p>
              </>
            )}

            <div className="flex flex-wrap justify-end items-end"> </div>

            <ModalRegular
              isOpen={showCurriculumsModal}
              onClose={() => {
                setShowCurriculumsModal(false)
              }}
              closeColor="white"
              bgColor="bg-eventsMain p-4"
              title={t('blog.governingDocument')}
            >
              <div className="flex flex-col p-4">
                <div className="h-auto w-full">
                  {bloggDoc?.attributes?.curriculums &&
                    bloggDoc?.attributes?.curriculums.map((cur: any) => {
                      const content = getCurrContent(cur)
                      const tag = getCurrTag(cur)
                      return (
                        <div className="h-auto w-full mb-4 text-xs text-start  font-medium border shadow p-4 rounded-2xl">
                          <div className="flex p-1 px-2 my-1 text-white bg-eventsMain shadow  rounded-full w-fit">
                            <p>
                              <>{tag.title}</>
                            </p>
                          </div>
                          "{content}"
                        </div>
                      )
                    })}
                  {/*{bloggDoc?.attributes?.curriculums &&
                    bloggDoc?.attributes?.curriculums.length > 1 && (
                      <div className="flex flex-row justify-end">
                        <div
                          className="mb-4 text-gray-600 max-w-[100px] flex justify-start underline text-xs cursor-pointer hover:text-blue-600 "
                          onClick={() => {
                            if (
                              showMoreCurriculums.find(
                                (curr: any) => curr == bloggDoc.id,
                              )
                            ) {
                              setShowMoreCurriculums(
                                showMoreCurriculums.filter(
                                  (curr: any) => curr !== bloggDoc.id,
                                ),
                              )
                            } else {
                              setShowMoreCurriculums([
                                ...showMoreCurriculums,
                                bloggDoc.id,
                              ])
                            }
                          }}
                        >
                          {showMoreCurriculums.find(
                            (curr: any) => curr == bloggDoc.id,
                          )
                            ? t('general.hide')
                            : t('general.showAll') +
                              '(+' +
                              (Number(
                                bloggDoc?.attributes?.curriculums.length,
                              ) -
                                2) +
                              ')'}
                        </div>
                      </div>
                              )}*/}
                </div>
              </div>
            </ModalRegular>
            <BlogReflections
              openReflection={openReflection}
              setOpenReflection={() => {
                setOpenReflection(false)
              }}
              reflection={''}
              reflections={reflections}
              updateReflection={function (
                bloggDoc: any,
                value: string,
              ): Promise<void> {
                throw new Error('Function not implemented.')
              }}
              bloggDoc={bloggDoc}
              onSaveReflections={() => {
                if (onSaveReflections) onSaveReflections()
              }}
            />
          </div>
        </div>
        <div className={'px-3 mb-10 pt-3 hover:bg-blue-50'}>
          <div className="flex flex-1">
            <div
              onClick={(e) => {
                e.stopPropagation()
              }}
              className="flex justify-start "
            >
              {' '}
              {bloggDoc.attributes.images
                ?.filter((img: any) => img.attributes.audio_file)
                ?.map((audio: any) => {
                  return (
                    <AudioPlayer
                      size="play"
                      src={audio.attributes.audio_file._url}
                    />
                  )
                })}
            </div>
          </div>

          <div className="flex flex-wrap line-clamp-3 mb-2">
            <p className=" line-clamp-2 items-center mt-2">
              {bloggDoc.attributes.title}
            </p>

            <p className="text-normal font-medium">
              {bloggDoc?.attributes?.content ?? ''}
            </p>
          </div>
          <div className="flex ">
            {bloggDoc?.attributes?.doc_tags?.length > 0 && (
              <div
                className={
                  ' flex flex-wrap gap-y-2 mb-2 font-medium sm:mt-0 gap-x-2  mt-2 flex-row text-black cursor-default'
                }
              >
                {bloggDoc?.attributes?.doc_tags?.map(
                  (tag: any, index: number) => {
                    if (
                      index > 4 &&
                      !showMoreTags.find((tag: any) => tag == bloggDoc.id)
                    ) {
                      return null
                    }
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundColor: tag?.attributes?.bg_color,
                        }}
                        className={
                          'rounded-full py-1 w-auto px-2 text-xs text-center '
                        }
                      >
                        {translateTags(tag?.attributes.name)}
                      </div>
                    )
                  },
                )}
              </div>
            )}
          </div>

          {/*<div className="h-auto w-full">
          {bloggDoc?.attributes?.curriculums &&
            bloggDoc?.attributes?.curriculums.map((cur: any, index: number) => {
              if (
                index > 1 &&
                !showMoreCurriculums.find((curr: any) => curr == bloggDoc.id)
              ) {
                return null
              }
              return (
                <div className="h-auto w-full mb-4 text-xs italic font-normal">
                  "{cur.get('content')}"
                </div>
              )
            })}
          {bloggDoc?.attributes?.curriculums &&
            bloggDoc?.attributes?.curriculums.length > 1 && (
              <div className="flex flex-row justify-end">
                <div
                  className="mb-4 text-gray-600 max-w-[100px] flex justify-start underline text-xs cursor-pointer hover:text-blue-600 "
                  onClick={() => {
                    if (
                      showMoreCurriculums.find(
                        (curr: any) => curr == bloggDoc.id,
                      )
                    ) {
                      setShowMoreCurriculums(
                        showMoreCurriculums.filter(
                          (curr: any) => curr !== bloggDoc.id,
                        ),
                      )
                    } else {
                      setShowMoreCurriculums([
                        ...showMoreCurriculums,
                        bloggDoc.id,
                      ])
                    }
                  }}
                >
                  {showMoreCurriculums.find((curr: any) => curr == bloggDoc.id)
                    ? t('general.hide')
                    : t('general.showAll') +
                      '(+' +
                      (Number(bloggDoc?.attributes?.curriculums.length) - 2) +
                      ')'}
                </div>
              </div>
            )}
                  </div>
        {bloggDoc?.attributes?.doc_tags?.length > 0 && (
          <div className={' pb-6 h-auto flex flex-wrap gap-x-2 gap-y-2'}>
            {bloggDoc?.attributes?.doc_tags?.map((tag: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: tag?.attributes?.bg_color,
                  }}
                  className={'rounded-full py-2 w-auto px-4 text-sm'}
                >
                  {translateTags(tag?.attributes.name)}
                </div>
              )
            })}
          </div>
        )}*/}

          <div className="absolute bottom-2 right-2">
            <div className="flex flex-row items-center">
              {showPublishImage && (
                <img
                  className={'w-[30px] h-[30px] rounded-full'}
                  src={
                    publishAs?.startsWith('dep:')
                      ? department?.contactPic?._url
                      : getImageFromUser(bloggDoc?.attributes?.user_author)
                  }
                  alt=""
                />
              )}
              <div className={'text-xs ml-3'}>
                <div className="flex flex-col items-end">
                  <p className={'font-medium whitespace-nowrap '}>
                    {displayText}
                  </p>
                  {/*<p className="font-normal whitespace-nowrap flex items-end">
                    {displayDate}
                   </p>*/}
                  <div>
                    <TimeStamp
                      date={moment(
                        bloggDoc.attributes.edited_date ??
                          bloggDoc.attributes.createdAt,
                      )}
                      displayMode={'relative'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BlogCommentsModal
        bloggDoc={bloggDoc}
        showModal={showModal}
        hideModal={() => {
          setShowModal(false)
        }}
        onSaveReflections={() => {
          if (onSaveReflections) onSaveReflections()
        }}
      />
    </div>
  )
}

const BlogCommentsModal = observer(
  ({
    showModal,
    hideModal,
    bloggDoc,
    onImageSelected,
    onSaveReflections,
    comment,
  }: {
    showModal: boolean
    hideModal: any
    bloggDoc: BloggDoc
    onImageSelected?: any
    onSaveReflections?: () => void
    comment?: BloggComments
  }) => {
    const ref = React.useRef() as any
    const [height, setHeight] = useState(0)
    const measuredRef = useCallback((node: any) => {
      if (node !== null) {
        setHeight(node.getBoundingClientRect().height)
      }
    }, [])
    const [newComment, setNewComment] = useState('')
    const [loading, setLoading] = useState(false)
    const { getCurrContent, getCurrTag } = useContext(ExportStoreContext)
    const { fetchComments, selectedComments, addNewComment, updateHeart } =
      useContext(PortfolioBlogStoreContext)
    const { currentUser, currentUserRole, readOnly } =
      useContext(UserStoreContext)

    const isTeacher =
      currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
        ? true
        : false

    const isAdmin =
      currentUserRole && currentUserRole?.role_type >= USER_ROLE_TYPE.ADMIN
        ? true
        : false

    const showComment =
      bloggDoc?.attributes?.port_commentable ||
      bloggDoc?.attributes?.blog_commentable
    useEffect(() => {
      if (showModal) {
        setLoading(true)
        fetchComments(bloggDoc).finally(() => {
          setLoading(false)
        })
      }
    }, [showModal])
    const handleVideoClick = (media: { type: string; src: string }) => {
      setSelectedVideoSrc(media.src)
      setShowVideoModal(true)
    }
    const hideVideoModalFunction = () => {
      setShowVideoModal(false)
    }
    const [selectedVideoSrc, setSelectedVideoSrc] = useState<string>('')
    const [showVideoModal, setShowVideoModal] = useState<boolean>(false)
    const showLikes =
      bloggDoc?.attributes?.port_emotable || bloggDoc?.attributes?.blog_emotable
    const [wave, setWave] = useState(false)
    const triggerWave = () => {
      setWave((prevState) => {
        return !prevState
      })
    }

    const [showMoreTags, setShowMoreTags] = useState<any>([])
    const translateTags = (tag: string) => {
      switch (tag) {
        case 'Språk':
          return t('tags.language')
        case 'Matematik':
          return t('tags.math')
        case 'Motorik':
          return t('tags.motor_skills')
        case 'Skapande':
          return t('tags.creativity')
        case 'EQ':
          return t('tags.eq')
        default:
          return tag
      }
    }
    const [showMoreCurriculums, setShowMoreCurriculums] = useState<any>([])
    const [showLikeModal, setShowLikeModal] = useState(false)
    const newCommentRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      if (newCommentRef.current) {
        newCommentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      }
    }, [selectedComments])

    return (
      <ModalRegular
        title={''}
        isOpen={showModal}
        onClose={() => {
          hideModal(true)
        }}
        className={'max-w-[900px]'}
        bgColor={'p-7 bg-eventsMain'}
        closeColor="white"
      >
        <div className="flex flex-col p-4 ">
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
            className={'flex flex-col justify-center space-y-3 p-4  w-full '}
          >
            {loading && (
              <div className={'flex justify-center'}>
                <Spinner />
              </div>
            )}
            {!loading && (
              <div
                className={
                  'flex relative flex-col md:flex-row shadow min-h-[305px] border max-h-[700px] rounded-2xl overflow-auto'
                }
              >
                <div className={'w-full h-full border-r'} ref={measuredRef}>
                  <ImageCarousel
                    displayMode="carousel"
                    className={'md:h-[450px] shadow object-cover'}
                    images={bloggDoc?.attributes?.images
                      ?.filter((item: any) => !item?.attributes?.audio_file)
                      ?.map((item: any) => {
                        if (item?.attributes?.image?._url) {
                          return {
                            type: item?.attributes?.is_video
                              ? 'video'
                              : 'image',
                            thumbnailSrc: item?.attributes?.image?._url,
                            src: item?.attributes?.is_video
                              ? item?.attributes?.video_file
                              : item?.attributes?.image?._url,
                          }
                        }
                        return null
                      })
                      .filter(Boolean)}
                    imageOnClick={(media: { type: string; src: string }) => {
                      onImageSelected(media.src)
                    }}
                    videoOnClick={handleVideoClick}
                  />
                  <VideoModal
                    src={selectedVideoSrc}
                    showModal={showVideoModal}
                    hideModal={hideVideoModalFunction}
                  />
                </div>
                {showComment && (
                  <div
                    className={'h-full w-full overflow-y-auto flex flex-col'}
                  >
                    <div
                      className={
                        'flex bg-gray-100 items-center p-2 relative border-b'
                      }
                    >
                      <img
                        className={'w-[30px] h-[30px] rounded-full'}
                        src={getImageFromUser(
                          bloggDoc?.attributes?.user_author,
                        )}
                        alt=""
                      />
                      <div className={'text-sm ml-3 flex-1'}>
                        <p className={'font-medium whitespace-nowrap'}>
                          {bloggDoc?.attributes?.user_author?.attributes
                            ?.firstName ? (
                            bloggDoc?.attributes?.user_author?.attributes
                              ?.firstName +
                            ' ' +
                            bloggDoc?.attributes?.user_author?.attributes
                              ?.lastName
                          ) : (
                            <> {t('general.unknown')}</>
                          )}
                        </p>
                        <p>
                          {bloggDoc?.attributes?.author?.attributes?.relation}
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        'flex flex-col overflow-y-auto overflow-visable relative flex-grow'
                      }
                      style={{ height: height - 165 }}
                    >
                      <div className="mb-8">
                        {selectedComments?.map((comment, index) => {
                          return (
                            <CommentCard
                              comment={comment}
                              key={index}
                              ref={
                                index === selectedComments.length - 1
                                  ? newCommentRef
                                  : null
                              }
                              getImageFromRole={getImageFromRole} // todo: denna gör ingenting
                              onSaveReflections={() => {
                                if (onSaveReflections) onSaveReflections()
                              }}
                            />
                            //här ska jag använda ref
                          )
                        })}
                      </div>
                    </div>
                    {!readOnly?.notes && (
                      <div className="flex m-4 relative">
                        <div className="flex flex-col w-full relative bg-transparent">
                          <textarea
                            placeholder={t('notes.commentHere')}
                            className="p-3 pr-9 resize-none text-sm border rounded-2xl max-h-[70px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                            value={newComment}
                            onChange={(e) => {
                              setNewComment(e.target.value)
                            }}
                            rows={4}
                          />
                          <div className="absolute bottom-2 right-2">
                            <Button
                              variant="roundBlue"
                              size="sm"
                              icon={faPaperPlane}
                              onClick={async () => {
                                if (newComment !== '') {
                                  try {
                                    await addNewComment(
                                      bloggDoc,
                                      newComment,
                                    ).finally(() => {
                                      if (onSaveReflections) {
                                        onSaveReflections()
                                      }
                                    })
                                    setNewComment('')
                                    fetchComments(bloggDoc)
                                  } catch (error) {}
                                } else {
                                  Swal.fire({
                                    text: t('notes.noComment'),
                                    icon: 'warning',
                                    showCancelButton: false,
                                    confirmButtonColor: '#7eb5f4',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: t('general.ok'),
                                    cancelButtonText: t('general.cancel'),
                                  })
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-y-4 m-2">
            <div className="flex flex-col justify-between h-full  sm:min-w-[180px]">
              <div
                onClick={(e) => {
                  e.stopPropagation()
                }}
                className="flex justify-start "
              >
                {' '}
                {bloggDoc.attributes.images
                  ?.filter((img: any) => img.attributes.audio_file)
                  ?.map((audio: any) => {
                    return (
                      <AudioPlayer
                        size="play"
                        src={audio.attributes.audio_file._url}
                      />
                    )
                  })}
              </div>
              <div className="font-normal flex w-fit flex-row gap-x-2 text-xs items-start sm:mr-12 mt-2  ">
                <div className={'flex flex-row space-x-1 items-center'}>
                  {showLikes && (
                    <div className="flex flex-row items-center space-x-1">
                      <>
                        <div
                          className={
                            wave
                              ? 'animate-shakeHeart flex flex-row'
                              : 'flex flex-row'
                          }
                        >
                          {bloggDoc.attributes.emote_heart?.find(
                            (id: string) => id === currentUser?.id,
                          ) ? (
                            <FontAwesomeIcon
                              className="text-red-600 text-xl hover:text-red-600 hover:scale-110 cursor-pointer"
                              icon={farFaHeart}
                              onClick={(e) => {
                                e.stopPropagation()
                                if (isTeacher) {
                                  setShowLikeModal(true)
                                } else {
                                  updateHeart(
                                    bloggDoc,
                                    bloggDoc.attributes.emote_heart?.find(
                                      (userId: string) =>
                                        userId === currentUser?.id,
                                    ),
                                  ).finally(() => {
                                    if (onSaveReflections) onSaveReflections()
                                  })
                                }
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="text-gray-600 text-xl hover:text-red-600 hover:scale-110 cursor-pointer"
                              icon={faHeart}
                              onClick={(e) => {
                                e.stopPropagation()
                                if (isTeacher) {
                                  setShowLikeModal(true)
                                } else {
                                  updateHeart(
                                    bloggDoc,
                                    bloggDoc.attributes.emote_heart?.find(
                                      (userId: string) =>
                                        userId === currentUser?.id,
                                    ),
                                  ).finally(() => {
                                    if (onSaveReflections) onSaveReflections()
                                  })
                                }
                              }}
                            />
                          )}
                        </div>
                        {isTeacher && (
                          <div className="font-bold ">
                            ({bloggDoc?.attributes.emote_heart?.length ?? 0}){' '}
                          </div>
                        )}
                      </>
                    </div>
                  )}
                  <BlogLikeModul
                    showLikeModal={showLikeModal}
                    hideLikeModal={() => {
                      setShowLikeModal(false)
                    }}
                    bloggDoc={bloggDoc}
                  />
                </div>
                {bloggDoc?.attributes?.curriculums &&
                  bloggDoc?.attributes?.curriculums.length > 0 && (
                    <div className="text-eventsMain text-sm flex flex-row space-x-1 items-center"></div>
                  )}
              </div>
            </div>
            <div className="font-bold text-2xl">
              {' '}
              {bloggDoc.attributes.title}
            </div>
            <div className="flex flex-col h-full w-auto justify-between">
              <p className="font-normal text-xs sm:text-sm h-auto flex flex-wrap max-w-[500px]  ">
                {bloggDoc?.attributes?.content ?? ''}
              </p>
            </div>
            <div>
              {bloggDoc?.attributes?.doc_tags?.length > 0 && (
                <div
                  className={
                    ' flex flex-wrap gap-y-1 sm:gap-y-0 font-medium sm:mt-0 gap-x-2  mt-2 flex-row text-black cursor-default'
                  }
                >
                  {bloggDoc?.attributes?.doc_tags?.map(
                    (tag: any, index: number) => {
                      if (
                        index > 4 &&
                        !showMoreTags.find((tag: any) => tag == bloggDoc.id)
                      ) {
                        return null
                      }
                      return (
                        <div
                          key={index}
                          style={{
                            backgroundColor: tag?.attributes?.bg_color,
                          }}
                          className={
                            'rounded-full py-1 w-auto px-2 text-xs text-center '
                          }
                        >
                          {translateTags(tag?.attributes.name)}
                        </div>
                      )
                    },
                  )}
                </div>
              )}
              {bloggDoc?.attributes?.doc_tags &&
                bloggDoc?.attributes?.doc_tags.length > 5 && (
                  <div className="flex flex-row">
                    <FontAwesomeIcon
                      icon={faTag}
                      className="text-purple-300 hover:scale-110 cursor-pointer mr-1"
                    />
                    <div
                      onClick={() => {
                        if (
                          showMoreTags.find((tag: any) => tag == bloggDoc.id)
                        ) {
                          setShowMoreTags(
                            showMoreTags.filter(
                              (tag: any) => tag !== bloggDoc.id,
                            ),
                          )
                        } else {
                          setShowMoreTags([...showMoreTags, bloggDoc.id])
                        }
                      }}
                      className="text-blue-400 text-xs "
                    >
                      {showMoreTags.find((tag: any) => tag == bloggDoc.id) ? (
                        <>{t('general.hide')}</>
                      ) : (
                        t('general.showAll') +
                        '(+' +
                        (Number(bloggDoc?.attributes?.doc_tags.length) - 5) +
                        ')'
                      )}
                    </div>
                  </div>
                )}
            </div>
            <>
              {bloggDoc?.attributes?.curriculums &&
                bloggDoc?.attributes?.curriculums.map((cur: any) => {
                  const content = getCurrContent(cur)
                  const tag = getCurrTag(cur)
                  return (
                    <div className="h-auto w-full mb-4 text-xs text-start  font-medium border shadow p-4 rounded-2xl">
                      <div
                        className={`flex p-1 px-2 my-1 text-white bg-blue-300 shadow  rounded-full w-fit`}
                      >
                        <p className="">{tag.title}</p>
                      </div>
                      "{content}"
                    </div>
                  )
                })}
            </>
            <p className={'text-xs text-gray-500 w-fit  font-bold'}>
              {moment(bloggDoc?.attributes?.event_date).format('MMM Do YYYY')}
            </p>
          </div>
        </div>
      </ModalRegular>
    )
  },
)
export default BlogCommentsModal
export const BlogLikeModul = observer(
  ({
    showLikeModal,
    hideLikeModal,
    bloggDoc,
    user,
  }: {
    showLikeModal: boolean
    hideLikeModal: any
    bloggDoc: BloggDoc
    user?: User
  }) => {
    const { updateHeart } = useContext(PortfolioBlogStoreContext)
    const [wave, setWave] = useState(false)
    const triggerWave = () => {
      setWave((prevState) => {
        return !prevState
      })
    }
    const [newLikeId, setNewLikeId] = useState<string | null>(null)
    const [saving, setSaving] = useState(false)
    const [showLikers, setShowLikers] = useState(true)
    const { activeUserRoles } = useContext(AdminStoreContext)
    const { currentUser } = useContext(UserStoreContext)
    const [isAnimating, setIsAnimating] = useState(false)
    const handleHeartClick = () => {
      if (!saving) {
        setSaving(true)
        const liked = bloggDoc.attributes.emote_heart?.includes(currentUser?.id)

        updateHeart(bloggDoc, liked).finally(() => {
          setSaving(false)

          if (!liked) {
            setIsAnimating(true)
            setIsShaking(true)
            setShowLikers(false)
            setNewLikeId(currentUser?.id ?? null)

            setTimeout(() => {
              setIsAnimating(false)
              setIsShaking(false)
              setShowLikers(true)
              setNewLikeId(null)
            }, 2000) // duration of animation
          }
        })
      }
    }

    const triggerShake = () => {
      setIsShaking(true)
      setTimeout(() => setIsShaking(false), 500) // the shake animation lasts 0.5s
    }

    const [isShaking, setIsShaking] = useState(false)
    const isLiked = bloggDoc.attributes.emote_heart?.includes(currentUser?.id)

    return (
      <ModalRegular
        title={
          (bloggDoc?.attributes.emote_heart?.length ?? 0) +
          ' ' +
          t('general.likes')
        }
        isOpen={showLikeModal}
        onClose={() => {
          setNewLikeId(null) // Reset any states as necessary
          hideLikeModal(false)
        }}
        bloggDoc={bloggDoc}
        className={'max-w-[500px]'}
        bgColor={'p-4 bg-eventsMain'}
        closeColor="white"
      >
        <div className="flex p-10 gap-x-5">
          <div className="flex flex-col justify-between">
            <div className="w-full flex mb-4">
              <p className="font-bold text-2xl flex w-full">
                <>{t('blog.peopleLike')}</>
              </p>
            </div>
            {bloggDoc.attributes.emote_heart?.map((userId: any) => {
              const rowUser = activeUserRoles?.find(
                (ur) => userId === ur?.user?.id,
              )?.user
              const isNewLike = userId === newLikeId

              return (
                <div
                  className={`flex flex-row mt-2 ${
                    isNewLike ? 'animate-fadeIn' : ''
                  }`}
                >
                  <div className="flex flex-row w-full gap-x-2 justify-start items-center font-medium">
                    {' '}
                    <img
                      className={'w-[30px] h-[30px] rounded-full'}
                      src={getImageFromUser(rowUser)}
                      alt=""
                    />
                    {rowUser?.attributes.firstName}{' '}
                    {rowUser?.attributes.lastName}
                  </div>
                  <div className="flex justify-end  items-center flex-row">
                    {' '}
                    <FontAwesomeIcon
                      className="text-red-300"
                      icon={farFaHeart}
                    />{' '}
                  </div>
                </div>
              )
            })}
          </div>

          <div
            className="flex justify-end relative h-auto w-auto"
            onClick={handleHeartClick}
          >
            <FontAwesomeIcon
              icon={isLiked ? farFaHeart : faHeart}
              className={`text-xl hover:text-red-600 hover:scale-110 cursor-pointer h-10 w-10 z-10 transition-colors duration-300 ${
                isLiked ? 'text-red-600' : 'text-gray-600'
              } ${isShaking ? 'animate-shake' : ''}`}
            ></FontAwesomeIcon>

            {isAnimating &&
              Array.from({ length: 1 }).map((_, index) => (
                <FontAwesomeIcon
                  key={index}
                  icon={farFaHeart}
                  className="text-red-500 text-xs -top-3 right-[14px] absolute animate-popHeart"
                  style={{
                    transform: 'translate(50%, -3px) scale(1)',
                    animationDelay: `${index * 0.2}s`,
                  }}
                />
              ))}
          </div>
        </div>
      </ModalRegular>
    )
  },
)
