import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../../components/Button'
import { faChevronDown, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { t } from 'i18next'
import { Department } from '../../../../Models/Department'
import {
  faChevronUp,
  faTrashCan,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { useParams } from 'react-router-dom'
import { SchoolStoreContext } from '../../../../store/schoolStore'
import Swal from 'sweetalert2'
import Parse from 'parse'
import CustomInput from '../../../../components/CustomInput'
import { ImageUploader } from '../../../../components/ImageUploader'

interface Props {
  department: Department
}

const DepartmentCard: React.FC<Props> = (props: Props) => {
  const [expanded, setExpanded] = useState(false)
  const departmentNameInputRef = useRef<HTMLInputElement>(null)
  const numberToDepartmentInputRef = useRef<HTMLInputElement>(null)
  const otherNumberInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const adressInputRef = useRef<HTMLInputElement>(null)
  const areaCodeInputRef = useRef<HTMLInputElement>(null)
  const cityInputRef = useRef<HTMLInputElement>(null)

  const { fetchDepartments, departments } = useContext(DepartmentStoreContext)
  const { editDepartment, deleteDepartment } = useContext(SchoolStoreContext)
  const { schoolId } = useParams()
  const [touched, setTouched] = useState(false)
  const [fakeState, setFakeState] = useState('')
  const handleBoxClick = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).nodeName !== 'INPUT') {
      setTouched(true)
      setAnimating(true)
      setExpanded((prevState) => !prevState)
      setTimeout(() => {
        setAnimating(false)
      }, 800)
    }
  }
  const uploadImageToParse = useCallback(async (file: File) => {
    var extension = file.name.split('.').pop()
    var parseImageFile = new Parse.File(`tyraimage.${extension}`, file)
    await parseImageFile.save()
    return parseImageFile
  }, [])

  const focusInput = (
    e: React.MouseEvent,
    inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e.stopPropagation()
    inputRef.current?.focus()
  }
  const handleFileChange = async (file: File) => {
    if (file) {
      const parseFile = await uploadImageToParse(file)

      props.department.set('contactPic', parseFile)
      props.department.set('contactPic_thumb', parseFile)
    }
  }
  const handleImageClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const [fieldsVisible, setFieldsVisible] = useState(false)
  const [animating, setAnimating] = useState(false)
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  return (
    <div className="flex flex-wrap flex-col w-full items-center">
      <div
        className={`py-5 w-[80%] border shadow-md p-3 bg-gray-50 rounded-2xl transition-all ease-in-out delay-10 hover:scale-[102%] ${
          touched
            ? expanded
              ? 'animate-expandingDepartment hover:scale-[100%]'
              : 'animate-collapsingDepartment'
            : ''
        }`}
      >
        {!expanded ? (
          <div className="flex flex-row items-center w-full justify-between gap-x-2">
            <div
              className=" relative flex items-center justify-center border rounded-full max-w-[40px]  max-h-[40px]  min-w-[40px]  min-h-[40px] overflow-hidden"
              style={
                props.department.contactPic
                  ? {
                      backgroundImage: `url(${props.department.contactPic?._url})`,
                      backgroundSize: 'cover',
                    }
                  : { backgroundImage: undefined, backgroundSize: 'cover' }
              }
            >
              {!props.department.contactPic && (
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-gray-300 h-8 w-8 absolute -bottom-[1px]"
                />
              )}
            </div>
            <div>
              <h2 className="text-lg font-bold mb-2 text-gray-600 mt-1 flex-wrap flex max-w-[230px] overflow-hidden">
                {props.department.klassName}
              </h2>
            </div>
            <div className={'flex-1 flex items-end w-full justify-end'}>
              <FontAwesomeIcon
                className="cursor-pointer"
                onClick={(e) => {
                  handleBoxClick(e)
                  props.department.revert()
                }}
                icon={faChevronDown}
                size={'sm'}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-center w-full">
            <div className="flex relative justify-center items-center w-full">
              <div className="absolute top-6 left-1">
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="text-red-500 cursor-pointer hover:scale-105"
                  onClick={(e) =>
                    Swal.fire({
                      text: t('admin.deleteDepartmentText'),
                      icon: 'warning',
                      showCancelButton: true,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire({
                          text: t('admin.deleteDepartmentWarning'),
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.yesDelete'),
                          cancelButtonText: t('general.noCancel'),
                        }).then(async (secondResult) => {
                          if (secondResult.isConfirmed) {
                            await deleteDepartment(props.department)

                            handleBoxClick(e)

                            if (schoolId) {
                              fetchDepartments(schoolId)
                            }
                          }
                        })
                      }
                    })
                  }
                />
              </div>
              <ImageUploader
                initialImageUrl={props.department.contactPic?._url}
                onImageChange={handleFileChange}
              />
            </div>

            <div className={'flex-1 flex justify-end'}>
              <FontAwesomeIcon
                className="cursor-pointer"
                onClick={(e) => {
                  handleBoxClick(e)
                  props.department.revert()
                }}
                icon={faChevronUp}
                size={'sm'}
              />
            </div>
          </div>
        )}
        {expanded && (
          <div
            className="flex flex-col transition-opacity duration-200 ease-in gap-y-4"
            style={{
              opacity: animating ? 0 : 1,
            }}
          >
            <CustomInput
              label={t('admin.departmentName')}
              value={props.department.klassName}
              onChange={(value) => {
                props.department.set('klassName', value)
                setFakeState(value)
              }}
              required={true}
              //placeholder={t('admin.title')}
              ref={departmentNameInputRef}
            />
            <CustomInput
              label={t('admin.departmentNumber')}
              value={props.department.teleNumber}
              onChange={(value) => {
                props.department.set('teleNumber', value)
                setFakeState(value)
              }}
              onlyNumbers={true}
              //required={true}
              //placeholder={t('admin.title')}
              ref={numberToDepartmentInputRef}
            />

            <CustomInput
              label={t('admin.otherNumber')}
              value={props.department.otherMobNum}
              onChange={(value) => {
                props.department.set('otherMobNum', value)
                setFakeState(value)
              }}
              onlyNumbers={true}
              required={false}
              //placeholder={t('admin.title')}
              ref={otherNumberInputRef}
            />
            <CustomInput
              label={t('login.email')}
              value={props.department.email}
              onChange={(value) => {
                props.department.set('email', value)
                setFakeState(value)
              }}
              //required={true}
              //placeholder={t('admin.title')}
              ref={emailInputRef}
            />
            <CustomInput
              label={t('contactList.adress')}
              value={props.department.adress}
              onChange={(value) => {
                props.department.set('adress', value)
                setFakeState(value)
              }}
              //required={true}
              //placeholder={t('admin.title')}
              ref={adressInputRef}
            />
            <CustomInput
              label={t('admin.areaCode')}
              value={props.department.areaCode}
              onChange={(value) => {
                props.department.set('areaCode', value)
                setFakeState(value)
              }}
              //required={true}
              //placeholder={t('admin.title')}
              ref={areaCodeInputRef}
            />

            <CustomInput
              label={t('admin.city')}
              value={props.department.city}
              onChange={(value) => {
                props.department.set('city', value)
                setFakeState(value)
              }}
              //required={true}
              //placeholder={t('admin.title')}
              ref={cityInputRef}
            />
            <div className="flex w-full justify-between mt-1">
              <Button
                size="sm"
                variant="fullRed"
                label={t('general.cancel')}
                onClick={(e) => {
                  if (departments) {
                    handleBoxClick(e)
                    props.department.revert()
                  }
                }}
              />
              <Button
                size="sm"
                variant="fullBlue"
                label={t('general.save')}
                disabled={!props.department.klassName}
                onClick={(e) => {
                  editDepartment(props.department)
                    .then(() => {
                      handleBoxClick(e)
                      Swal.fire({
                        //title: 'Are you sure?',
                        text: t('admin.informationChange'),
                        icon: 'success',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      })
                    })
                    .finally(() => {
                      if (schoolId) {
                        fetchDepartments(schoolId)
                      }
                    })
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DepartmentCard
