import { useContext, useEffect, useState } from 'react'
import ColorCard from '../../../../components/ColorCard'
import Page from '../../../../components/Page'
import KidCard from './components/KidCard'
import KidCardModal from './components/KidCardModal'
import { t } from 'i18next'
import { NavigationStoreContext } from '../../../../store/navigationStore'
import { KidsStoreContext } from '../../../../store/kidsStore'
import { UserStoreContext } from '../../../../store/userStore'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { getImageOrPlaceHolder } from '../../../../utils/parseUtils'
import { Kid } from '../../../../Models/Kid'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../../components/Loading'
import { RandomColors } from '../../../../components/RandomColors'
import React from 'react'
interface InviteParents {
  kid?: Kid
}
// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-unused-vars
export const InviteParents: React.FC<{ onClick?: any }> = ({ onClick }) => {
  const [showInviteModal, setShowInviteModal] = useState(false)
  const { fetchKids, kids } = useContext(KidsStoreContext)
  const { currentUserRole, fetchUserRolesToInviteToKid, fetchNewUserRoles } =
    useContext(UserStoreContext)
  const { departments, fetchDepartments } = useContext(DepartmentStoreContext)
  const { setTitle } = useContext(NavigationStoreContext)

  const { schoolId } = useParams()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (currentUserRole && schoolId) {
      setLoading(true)
      fetchKids(currentUserRole)
      fetchDepartments(schoolId, true)
      //fetchUserRoles(currentUserRole)
      fetchUserRolesToInviteToKid(schoolId)
      fetchNewUserRoles(schoolId).finally(() => {
        setLoading(false)
      })
    }
  }, [schoolId])
  useEffect(() => {
    setTitle(t('admin.inviteParent'))
  }, [t])
  const [selectedKid, setSelectedKid] = useState<Kid | undefined>()

  return (
    <Page naked>
      {loading && <Loading />}
      {departments?.map((department, index) => (
        <ColorCard
          title={department.klassName}
          bgColor={
            department.get('departmentColor')
              ? 'bg-[' + department.get('departmentColor') + ']'
              : RandomColors[index]
          }
        >
          <div className="flex flex-wrap justify-center">
            {kids
              ?.filter(
                (kid) =>
                  kid?.attributes?.departmentPointer?.id === department?.id,
              )
              .map((kid) => (
                <KidCard
                  PSN={kid.kid_pers_id_num}
                  title={`${kid.firstName} ${kid.lastName}`}
                  onClick={() => {
                    setSelectedKid(kid)
                    setShowInviteModal(true)
                  }}
                  image={getImageOrPlaceHolder(kid?.attributes?.kidImage?._url)}
                />
              ))}
          </div>
        </ColorCard>
      ))}
      <KidCardModal
        isOpen={showInviteModal}
        onClose={() => {
          setShowInviteModal(false)
        }}
        kid={selectedKid}
        image={getImageOrPlaceHolder(selectedKid?.attributes?.kidImage?._url)}
      />
    </Page>
  )
}
