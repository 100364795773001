import React, { RefObject, useContext, useState } from 'react'
import { faBell } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'

export const NotificationButton: React.FC<{ onClick?: any }> = ({
  onClick,
}) => {
  const { schoolId } = useParams()
  const navigate = useNavigate()
  return (
    <div className="relative flex items-end">
      <button
        type="button"
        className="w-full rounded-md group focus:outline-none"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={() => {
          navigate(`/noticeCenter/notice`)
          if (onClick) {
            onClick()
          }
        }}
      >
        <div className="text-xl group-hover:text-blue-50">
          <FontAwesomeIcon icon={faBell} />
        </div>
      </button>
    </div>
  )
}
