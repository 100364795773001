import Parse from 'parse'
import { BOX_STATUS } from '../Enums/TyraEnums'
import { Kid } from './Kid'
import { School } from './School'

export class Box extends Parse.Object {
  public static readonly Fields = {
    status: 'status',
    notice_user_id: 'notice_user_id',
    sendPush: 'sendPush',
  }

  constructor() {
    super('box')
  } // Needed for triggers, although does not seems to be called.

  get items(): string {
    return this.get('items')
  }
  set items(value: string) {
    this.set('items', value)
  }
  get kid_owner(): Kid {
    return this.get('kid_owner')
  }
  set kid_owner(value: Kid) {
    this.set('kid_owner', value)
  }
  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  get status(): BOX_STATUS {
    return this.get('status')
  }
  get edited_date(): Date {
    return this.get('edited_date')
  }
  set edited_date(value: Date) {
    this.set('edited_date', value)
  }

  // * Signal variables *
  get notice_user_id(): string {
    return this.get('notice_user_id')
  }
  get sendPush(): boolean {
    return this.get('sendPush')
  }
}

Parse.Object.registerSubclass('box', Box)
