import { CSSProperties, FC, useContext, useEffect, useState } from 'react'
import Page from '../../../components/Page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRightToBracket,
  faClock,
  faTrashCan,
  faUserClock,
  faUserLock,
} from '@fortawesome/pro-solid-svg-icons'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { Slider } from '../../../components/Slider'
import ModalRegular from '../../../components/ModalRegular'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import { t } from 'i18next'
import i18n from '../../../i18nextConfig'
import DatePicker, { registerLocale } from 'react-datepicker'
import { Field, FieldProps } from 'formik'
import { Button } from '../../../components/Button'
import Swal from 'sweetalert2'
import { SchoolStoreContext } from '../../../store/schoolStore'
import { useParams } from 'react-router-dom'
import { NavigationStoreContext } from '../../../store/navigationStore'
import { start } from 'repl'

interface newOpentime {
  date: Date | null
  startingHour: Date | null
  endingHour: Date | null
}

let initialValues = {
  date: new Date(),
  startingHour: new Date().setHours(8, 0, 0, 0),
  endingHour: null,
}

export const AttendanceAndSchedule: FC = () => {
  const fixedContainerStyle: CSSProperties = {
    position: 'relative',
  }

  const fixedTitleStyle: CSSProperties = {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
  }
  const [showModal, setShowModal] = useState(false)
  const [startTime, setStartTime] = useState<Date | null>(null)
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [loading, setLoading] = useState(false)
  const {
    school,
    setAdminAllowCheckInOutParent,
    fetchSingleSchool,
    setAdminAllowMassCheckInOut,
    setAdminAllowPushInOutCheck,
    setAdminAllowCustomTimeEdit,
    setNewStartingHour,
  } = useContext(SchoolStoreContext)
  const [disableParentCheckInOut, setDisableParentCheckInOut] = useState(false)
  const [disableAllowMassCheckInOut, setDisableAllowMassCheckInOut] =
    useState(false)
  const [disableAllowPushInOutCheck, setDisableAllowPushInOutCheck] =
    useState(false)
  const [disableAllowCustomTimeEdit, setDisableAllowCustomTimeEdit] =
    useState(false)
  const { schoolId } = useParams()
  useEffect(() => {
    if (schoolId) {
      setLoading(true)
      fetchSingleSchool(schoolId).finally(() => {
        setLoading(false)
      })
    }
  }, [schoolId])
  useEffect(() => {
    if (school) {
      if (school.school_setting.attributes.preschool_open_times) {
        setEndTime(
          new Date(
            moment().format(
              'YYYY-MM-DD ' +
                school.school_setting.attributes.preschool_open_times.end,
            ),
          ),
        )
        setStartTime(
          new Date(
            moment().format(
              'YYYY-MM-DD ' +
                school.school_setting.attributes.preschool_open_times.start,
            ),
          ),
        )
      }
    }
  }, [school])
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('admin.attendanceSchedule'))
  }, [t])
  const [isDateChanged, setIsDateChanged] = useState(false)

  return (
    <div className="relative">
      <Page>
        <div className="p-10 flex gap-y-6 flex-col flex-wrap w-full justify-center ">
          <div className="flex w-full justify-center text-2xl font-bold text-gray-600">
            <>{t('admin.changeOpenHour')}</>
          </div>
          <div className="flex-col flex gap-x-4 justify-center">
            <div className="flex flex-row justify-center">
              <div className="flex items-center">
                <div className="text-center">
                  <label>
                    <>{t('admin.weOpen')}</>
                  </label>
                  <DatePicker
                    className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                    selected={startTime}
                    onChange={(date: Date) => {
                      setIsDateChanged(true)
                      setStartTime(date)
                    }}
                    minTime={new Date(moment().format('00:01'))}
                    maxTime={new Date(moment().format('23:59'))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    timeCaption={t('general.timeOfDay')}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    locale={i18n.language}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-center">
                  <label>
                    <>{t('admin.weClose')}</>
                  </label>
                  <DatePicker
                    className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                    selected={endTime}
                    onChange={(date: Date) => {
                      setIsDateChanged(true)
                      setEndTime(date)
                    }}
                    minTime={startTime ? new Date(startTime) : new Date()}
                    maxTime={new Date(moment().endOf('day').toDate())}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    timeCaption={t('general.timeOfDay')}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    locale={i18n.language}
                  />
                </div>
              </div>

              <div className="flex justify-center items-center pt-4">
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="text-red-300 cursor-pointer"
                  onClick={() => {
                    setStartTime(null)
                    setEndTime(null)
                    setIsDateChanged(true)
                  }}
                />{' '}
              </div>
            </div>
            {!startTime && !endTime && (
              <div className="text-center text-sm text-red-500 mt-5">
                <>( {t('admin.noTimeSelected')} )</>
              </div>
            )}
            {isDateChanged && (
              <div className="flex w-full justify-center mt-5">
                <Button
                  variant="fullBlue"
                  size="sm"
                  label={t('general.save')}
                  onClick={() => {
                    if (school) {
                      setNewStartingHour(
                        school,
                        endTime ? moment(endTime).format('HH:mm') : null,
                        startTime ? moment(startTime).format('HH:mm') : null,
                      ).finally(() => {
                        setShowModal(false)
                        Swal.fire({
                          icon: 'success',
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      })
                    }
                  }}
                />
              </div>
            )}
          </div>
          {/*<div className="flex flex-wrap flex-row justify-center w-full gap-4">
            <div
              className="relative flex shadow p-4 bg-gray-100 rounded-2xl min-w-[300px] items-center justify-center cursor-pointer hover:bg-gray-50"
              onClick={() => {
                Swal.fire({
                  //title: 'Are you sure?',
                  text: t('events.eventAlarmNotImp'),
                  icon: 'info',
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.ok'),
                })
              }}
            >
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faUserLock}
                  className="text-purple-400 absolute left-4 top-[19px]"
                />
                <div>
                  {' '}
                  <>{t('admin.lockAltKid')}</>
                </div>
              </div>
            </div>
            <div
              className="relative flex shadow p-4 bg-gray-100 rounded-2xl min-w-[300px] items-center justify-center cursor-pointer hover:bg-gray-50"
              onClick={() => {
                Swal.fire({
                  //title: 'Are you sure?',
                  text: t('events.eventAlarmNotImp'),
                  icon: 'info',
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.ok'),
                })
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faUserClock}
                  className="text-gray-600 absolute left-4 top-[19px]"
                />
                <div>
                  <>{t('admin.teacherAttendance')}</>
                </div>
              </div>
            </div>
            <div
              className="relative flex shadow p-4 bg-gray-100 rounded-2xl min-w-[300px] items-center justify-center cursor-pointer hover:bg-gray-50"
              onClick={() => setShowModal(true)}
            >
              <div>
                <FontAwesomeIcon
                  icon={faClock}
                  className="text-yellow-500 absolute left-4 top-[19px]"
                />
                <div>
                  <>{t('admin.ourOpenTime')}</>
                </div>
              </div>
            </div>
            </div>*/}
        </div>
      </Page>
      <div style={fixedContainerStyle}>
        <div
          className="text-gray-600 font-bold text-2xl "
          style={fixedTitleStyle}
        >
          <>{t('admin.otherSettings')}</>
        </div>
        <Page>
          <div className="flex flex-col justify-center w-full p-4">
            <div className="grid grid-cols-12 items-center p-2 border-b">
              {' '}
              <FontAwesomeIcon
                icon={faBell}
                className="text-green-400 sm:pl-6"
              />
              <div className="col-span-8 md:col-span-9">
                {' '}
                <h1 className="font-medium">
                  <>{t('admin.pushInOutCheck')}</>
                </h1>
                <h2>
                  <>{t('admin.pushInOutCheckText')}</>
                </h2>
              </div>
              <div className="col-span-3 md:col-span-2 flex justify-end">
                {' '}
                {!loading && (
                  <Slider
                    value={
                      school?.school_setting.attributes.kid_check_push_optional
                    }
                    id={0}
                    onSliderClick={() => {
                      if (school && !disableAllowPushInOutCheck) {
                        setDisableAllowPushInOutCheck(true)
                        setAdminAllowPushInOutCheck(
                          school,
                          school?.school_setting.attributes
                            .kid_check_push_optional
                            ? false
                            : true,
                        ).finally(() => {
                          setDisableAllowPushInOutCheck(false)
                        })
                      }
                    }}
                    onBoxClick={() => {
                      if (school && !disableAllowPushInOutCheck) {
                        setDisableAllowPushInOutCheck(true)
                        setAdminAllowPushInOutCheck(
                          school,
                          school?.school_setting.attributes
                            .kid_check_push_optional
                            ? false
                            : true,
                        ).finally(() => {
                          setDisableAllowPushInOutCheck(false)
                        })
                      }
                    }}
                  />
                )}
              </div>
            </div>
            <div className="grid grid-cols-12 items-center p-2 border-b">
              <FontAwesomeIcon
                icon={faArrowRightToBracket}
                className="text-red-300 sm:pl-6"
              />
              <div className="col-span-8 md:col-span-9">
                <h1 className="font-medium">
                  <>{t('admin.allowParentInOutCheck')}</>
                </h1>
                <h2>
                  <>{t('admin.allowParentInOutCheckText')}</>
                </h2>
              </div>
              <div className="col-span-3 md:col-span-2 flex justify-end">
                {!loading && (
                  <Slider
                    value={school?.parentCheckInOutLocked ? false : true}
                    id={2}
                    onSliderClick={() => {
                      if (school && !disableParentCheckInOut) {
                        setDisableParentCheckInOut(true)
                        setAdminAllowCheckInOutParent(
                          school,
                          school?.parentCheckInOutLocked ? false : true,
                        ).finally(() => {
                          setDisableParentCheckInOut(false)
                        })
                      }
                    }}
                    onBoxClick={() => {
                      if (school && !disableParentCheckInOut) {
                        setDisableParentCheckInOut(true)
                        setAdminAllowCheckInOutParent(
                          school,
                          school?.parentCheckInOutLocked ? false : true,
                        ).finally(() => {
                          setDisableParentCheckInOut(false)
                        })
                      }
                    }}
                  />
                )}
              </div>
            </div>

            <div className="grid grid-cols-12 items-center p-2 border-b">
              <FontAwesomeIcon
                icon={faArrowRightToBracket}
                className="text-eventsMain sm:pl-6"
              />
              <div className="col-span-8 md:col-span-9">
                <h1 className="font-medium">
                  <>{t('admin.allowMassInOutCheck')}</>
                </h1>
                <h2>
                  <>{t('admin.allowMassInOutCheckText')}</>
                </h2>
              </div>
              <div className="col-span-3 md:col-span-2 flex justify-end">
                {!loading && (
                  <Slider
                    value={
                      school?.school_setting.attributes.allow_mass_kid_check
                    }
                    id={3}
                    onSliderClick={() => {
                      if (school && !disableAllowMassCheckInOut) {
                        setDisableAllowMassCheckInOut(true)
                        setAdminAllowMassCheckInOut(
                          school,
                          school?.school_setting.attributes.allow_mass_kid_check
                            ? false
                            : true,
                        ).finally(() => {
                          setDisableAllowMassCheckInOut(false)
                        })
                      }
                    }}
                    onBoxClick={() => {
                      if (school && !disableAllowMassCheckInOut) {
                        setDisableAllowMassCheckInOut(true)
                        setAdminAllowMassCheckInOut(
                          school,
                          school?.school_setting.attributes.allow_mass_kid_check
                            ? false
                            : true,
                        ).finally(() => {
                          setDisableAllowMassCheckInOut(false)
                        })
                      }
                    }}
                  />
                )}
              </div>
            </div>

            <div className="grid grid-cols-12 items-center p-2 border-b">
              <FontAwesomeIcon
                icon={faArrowRightToBracket}
                className="text-orange-400 sm:pl-6"
              />
              <div className="col-span-8 md:col-span-9">
                <h1 className="font-medium">
                  <>{t('admin.allowEditInOutCheck')}</>
                </h1>
                <h2>
                  <>{t('admin.allowEditInOutCheckText')}</>
                </h2>
              </div>
              <div className="col-span-3 md:col-span-2 flex justify-end">
                {!loading && (
                  <Slider
                    value={school?.attributes.custom_check_in_out_time}
                    id={4}
                    onSliderClick={() => {
                      if (school && !disableAllowCustomTimeEdit) {
                        setDisableAllowCustomTimeEdit(true)
                        setAdminAllowCustomTimeEdit(
                          school,
                          school?.attributes.custom_check_in_out_time
                            ? false
                            : true,
                        ).finally(() => {
                          setDisableAllowCustomTimeEdit(false)
                        })
                      }
                    }}
                    onBoxClick={() => {
                      if (school && !disableAllowCustomTimeEdit) {
                        setDisableAllowCustomTimeEdit(true)
                        setAdminAllowCustomTimeEdit(
                          school,
                          school?.attributes.custom_check_in_out_time
                            ? false
                            : true,
                        ).finally(() => {
                          setDisableAllowCustomTimeEdit(false)
                        })
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Page>
        <ModalRegular
          onClose={() => {
            setShowModal(false)
          }}
          isOpen={showModal}
          bgColor="bg-yellow-300 p-4"
          title={t('admin.ourOpenTime')}
          closeColor="white"
        >
          <div className="flex-col flex pb-[60px] pt-[70px] gap-x-4 justify-center">
            <div className="flex flex-row justify-center">
              <div className="flex items-center">
                <div className="text-center">
                  <label>
                    <>{t('admin.weOpen')}</>
                  </label>
                  <DatePicker
                    className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                    selected={startTime}
                    onChange={(date: Date) => setStartTime(date)}
                    minTime={new Date(moment().format('00:01'))}
                    maxTime={new Date(moment().format('23:59'))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    timeCaption={t('general.timeOfDay')}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    locale={i18n.language}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-center">
                  <label>
                    <>{t('admin.weClose')}</>
                  </label>
                  <DatePicker
                    className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                    selected={endTime}
                    onChange={(date: Date) => setEndTime(date)}
                    minTime={new Date(moment().format('23:59'))}
                    maxTime={new Date(moment().format('00:01'))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    timeCaption={t('general.timeOfDay')}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    locale={i18n.language}
                  />
                </div>
              </div>

              <div className="flex justify-center items-center pt-4">
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="text-red-300 cursor-pointer"
                  onClick={() => {
                    setStartTime(null)
                    setEndTime(null)
                  }}
                />{' '}
              </div>
            </div>
            <div className="flex justify-center items-center mt-[70px] border bg-green-50 gap-x-2 text-2xl">
              <div>{startTime ? moment(startTime).format('HH:mm') : ''}</div>-
              <div>{endTime ? moment(endTime).format('HH:mm') : ''}</div>
            </div>
          </div>

          <div className="flex justify-center pb-10 ">
            <Button
              variant="fullBlue"
              size="custom"
              label={t('general.save')}
              onClick={() => {
                if (school) {
                  setNewStartingHour(
                    school,
                    moment(endTime).format('HH:mm'),
                    moment(startTime).format('HH:mm'),
                  ).finally(() => {
                    setShowModal(false)
                    Swal.fire({
                      icon: 'success',
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                    })
                  })
                }
              }}
            />
          </div>
        </ModalRegular>
      </div>
    </div>
  )
}

export default AttendanceAndSchedule
