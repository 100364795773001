import Parse from 'parse'
import { EVENT_TYPE } from '../Enums/TyraEnums'
import { Department } from './Department'
import { School } from './School'
import { User } from './User'

export class Event extends Parse.Object {
  public static readonly Fields = {
    school: 'school',
    className: 'events',
    class_pointer: 'class_pointer',
  }
  read: any
  constructor() {
    super('events')
  }
  public get id(): string {
    return this.get('id')
  }
  public get pdf(): Parse.File | undefined {
    return this.get('pdf')
  }
  public get picture(): Parse.File | undefined {
    return this.get('picture')
  }
  public get school_pointer(): School {
    return this.get('school_pointer')
  }
  public set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  public get school(): string {
    return this.get('school')
  }
  public set school(value: string) {
    this.set('school', value)
  }

  public get type(): EVENT_TYPE {
    return this.get('type')
  }
  public set type(value: EVENT_TYPE) {
    this.set('type', value)
  }

  public get date(): Date {
    return this.get('date')
  }
  public set date(value: Date) {
    this.set('date', value)
  }

  public get topic(): string {
    return this.get('topic')
  }
  public set topic(value: string) {
    this.set('topic', value)
  }

  public get content(): string {
    return this.get('content')
  }
  public set content(value: string) {
    this.set('content', value)
  }

  public get class_pointer(): Department[] {
    return this.get('class_pointer')
  }
  public get author(): User {
    return this.get('author')
  }
}
Parse.Object.registerSubclass('events', Event)
