import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { observer } from 'mobx-react'
import ModalRegular from '../../components/ModalRegular'
import { Kid } from '../../Models/Kid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faEyeSlash,
  faPen,
  faBoxArchive,
  faBookOpen,
  faClock,
  faUserCircle,
  faPhone,
  faCommentSms,
  faEnvelope,
  faMapLocationDot,
  faChevronDown,
  faMapPin,
  faMarker,
  faLocationDot,
  faAddressBook,
  faBook,
  faCake,
} from '@fortawesome/free-solid-svg-icons'
import { DashboardItem } from '../dashboard/components/DashboardItem'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UserStoreContext } from '../../store/userStore'
import classNames from 'classnames'
import { getImageOrPlaceHolder } from '../../utils/parseUtils'
import { Button } from '../../components/Button'
import { KidsStoreContext } from '../../store/kidsStore'
import {
  USER_ROLE_STATUS,
  USER_ROLE_TYPE,
  USER_SETTING,
  USER_TYPE,
} from '../../Enums/TyraEnums'
import { UserRole } from '../../Models/UserRole'
import { faRotate } from '@fortawesome/pro-thin-svg-icons'
import { transform } from 'typescript'
import { ContactStoreContext } from '../../store/contactStore'
import {
  faBirthdayCake,
  faFloppyDisk,
  faIdCard,
  faSensorAlert,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'
import { t } from 'i18next'
import DOMPurify from 'dompurify'
import Box from '../Box/Box'
import Swal from 'sweetalert2'

export const getDateForTranslatation = (months: string) => {
  switch (months) {
    case '01':
      return t('monthsShort.january')
    case '02':
      return t('monthsShort.february')
    case '03':
      return t('monthsShort.march')
    case '04':
      return t('monthsShort.april')
    case '05':
      return t('monthsShort.may')
    case '06':
      return t('monthsShort.june')
    case '07':
      return t('monthsShort.july')
    case '08':
      return t('monthsShort.august')
    case '09':
      return t('monthsShort.september')
    case '10':
      return t('monthsShort.october')
    case '11':
      return t('monthsShort.november')
    case '12':
      return t('monthsShort.december')
  }
}
const translateRelation = (relation: string) => {
  switch (relation) {
    case 'Personal':
      return t('documents.staff')
    case 'Mamma':
      return t('general.mother')
    case 'Pappa':
      return t('general.father')
    case 'Vårdnadshavare':
      return t('documents.parent')
    default:
      return relation
  }
}

export const KidModal: React.FC<{
  showModal: boolean
  onClose: any
  kid?: Kid
}> = observer(({ showModal, onClose, kid }) => {
  const navigate = useNavigate()
  const [tab, setTab] = useState('information')
  const { t } = useTranslation()
  const { currentUserRole, readOnly } = useContext(UserStoreContext)
  const { saveKidInfo, userRoles } = useContext(KidsStoreContext)
  const [showEditModal, setShowEditModal] = useState(false)
  const [editAllergies, setEditAllergies] = useState<Boolean>(false)
  const [editOtherInfo, setEditOtherInfo] = useState<Boolean>(false)
  const [allergies, setAllergies] = useState<string>()
  const [other, setOther] = useState<string>()
  const [saveLoading, setSaveLoading] = useState(false)
  const [detailedContactID, setDetailedContactID] = useState<null | string>(
    null,
  )
  const { fetchContact, contact } = useContext(ContactStoreContext)
  const [loading, setLoading] = useState<Boolean>(false)

  useEffect(() => {
    setAllergies(kid?.attributes?.kid_extra?.attributes?.allergies)
  }, [kid?.attributes?.kid_extra?.attributes?.allergies])

  useEffect(() => {
    setOther(kid?.attributes?.kid_extra?.attributes?.other_info)
  }, [kid?.attributes?.kid_extra?.attributes?.other_info])

  const canSeAll =
    currentUserRole?.attributes?.role_type > USER_ROLE_TYPE.PARENT ||
    kid?.id === currentUserRole?.attributes?.kid?.id
  const canSeeSensetive =
    currentUserRole?.attributes?.staff_sensetive_info_bool ||
    kid?.id === currentUserRole?.attributes?.kid?.id
  const [showBox, setShowBox] = useState(false)
  const isBirthdayToday =
    kid?.attributes?.birth_date &&
    moment().format('MM-DD') ===
      moment(kid?.attributes?.birth_date).format('MM-DD')

  return (
    <div>
      <ModalRegular
        className={'max-w-xl pb-6'}
        isOpen={showModal}
        onClose={onClose}
      >
        <div
          className={'mt-10 flex flex-col justify-center items-center relative'}
        >
          <div className="flex flex-row my-4">
            {' '}
            <div className={'relative flex '}>
              {kid?.attributes?.hidden_in_dep_list && canSeAll && (
                <FontAwesomeIcon
                  className="absolute -top-2 -right-2 text-eventsMain"
                  icon={faEyeSlash}
                  size={'xs'}
                />
              )}
              {isBirthdayToday && (
                <FontAwesomeIcon
                  icon={faBirthdayCake}
                  className="absolute -top-1 -left-2 text-purple-400"
                />
              )}
              <img
                className={'w-[80px] h-[80px] rounded-full border'}
                src={getImageOrPlaceHolder(kid?.attributes?.kidImage?._url)}
                alt=""
              />
            </div>
            <div className="flex flex-col items-start mt-1 ml-2 ">
              <p className={'text-xl font-bold text-gray-600'}>
                {kid?.firstName + ' ' + kid?.lastName}
              </p>
              <div className={'flex space-x-1 text-sm text-gray-400'}>
                {canSeAll ? (
                  <>
                    <FontAwesomeIcon
                      className="text-red-400 mr-[2px] ml-[2px]"
                      icon={faCake}
                    />
                    <p>
                      {kid?.birth_date
                        ? `${moment(kid.birth_date).format(
                            'DD',
                          )} ${getDateForTranslatation(
                            moment(kid.birth_date).format('MM'),
                          )} ${moment(kid.birth_date).format('YYYY')}`
                        : t('ourKids.birthDateMissing')}
                    </p>
                  </>
                ) : (
                  <p></p>
                )}
              </div>
              {canSeAll && (
                <div
                  className={
                    'flex space-x-1 text-sm text-gray-400 items-center'
                  }
                >
                  <FontAwesomeIcon className="text-gray-400" icon={faIdCard} />
                  {canSeeSensetive ? (
                    <p>
                      {kid?.kid_pers_id_num || t('ourKids.personalIdMissing')}
                    </p>
                  ) : (
                    <p>{t('general.missingPermission')}</p>
                  )}
                </div>
              )}
            </div>
          </div>
          {canSeAll && (
            <div
              className={
                'flex flex-wrap w-full justify-center pt-4 space-x-2 sm:space-x-6 text-gray-800 font-medium'
              }
            >
              <DashboardItem
                title={t('menu.portfolio')}
                className={'bg-portfolioMain'}
                icon={faUserCircle}
                onClick={() => {
                  navigate(`/portfolio/${kid?.id}`)
                }}
              />
              <DashboardItem
                title={t('menu.box')}
                className={'bg-boxMain'}
                icon={faBoxArchive}
                onClick={() => setShowBox(true)}
              />
              {kid && (
                <Box
                  isOpen={showBox}
                  onClose={() => setShowBox(false)}
                  kidId={kid.id}
                />
              )}
              <DashboardItem
                title={t('menu.notes')}
                className={classNames(
                  'bg-notesMain',
                  !canSeeSensetive && 'opacity-40 cursor-not-allowed',
                )}
                icon={faBookOpen}
                onClick={() => {
                  if (canSeeSensetive) {
                    navigate(`/notes/${kid?.id}`)
                  }
                }}
              />
              <DashboardItem
                title={t('menu.scheme')}
                className={'bg-scheduleMain opacity-40 cursor-not-allowed'}
                icon={faClock}
                onClick={() => {
                  Swal.fire({
                    text: t('events.eventAlarmNotImp'),
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                    cancelButtonText: t('general.cancel'),
                  })
                }}
              />
            </div>
          )}
          {!canSeAll &&
            currentUserRole?.attributes.role_type == USER_ROLE_TYPE.PARENT && (
              <div className={'flex  w-full max-w-lg bg-white rounded p-1'}>
                <div
                  className="flex-1 text-center py-3 text-eventsMain

                    border-eventsMain border-b-4  "
                >
                  {t('ourKids.contacts')}
                </div>
              </div>
            )}
          {canSeAll && (
            <div className={'flex  w-full max-w-lg bg-white rounded p-1'}>
              <div
                className="absolute left-4 top-[300px] z-20 rounded-full bg-eventsMain h-1 mt-1 transition-all duration-300 ease-in-out"
                style={{
                  width: 'calc(50% - 34px)',
                  left:
                    tab === 'information'
                      ? 'calc(1.5% + 30px)'
                      : 'calc(50% + 4px)',
                }}
              ></div>
              <div
                onClick={() => {
                  setTab('information')
                }}
                className={classNames(
                  'flex-1 text-center py-3 cursor-pointer hover:scale-[105%] text-eventsMain',
                  tab === 'information' && 'border-eventsMain text-eventsMain',
                )}
              >
                {t('ourKids.information')}
              </div>

              <div
                onClick={() => {
                  setTab('contacts')
                }}
                className={classNames(
                  'flex-1 text-center py-3 cursor-pointer hover:scale-[105%] text-eventsMain',
                  tab === 'contacts' && 'border-eventsMain  text-eventsMain',
                )}
              >
                {t('ourKids.contacts')}
              </div>
            </div>
          )}

          {tab === 'information' && (
            <div className={'w-full max-w-lg'}>
              {canSeAll && canSeeSensetive ? (
                <div
                  className={classNames(
                    'flex items-center w-full p-3 rounded-2xl py-3 mt-6',
                    !kid?.attributes?.kid_extra?.attributes?.allergies &&
                      'opacity-60',
                  )}
                >
                  <div className={'self-start pt-2 pr-3'}>
                    <div
                      className={
                        'h-[10px] w-[10px] rounded-full bg-absenceMain'
                      }
                    />
                  </div>
                  <div className="w-full pr-4">
                    <p>{t('ourKids.allergyAndSickness')}</p>
                    {editAllergies ? (
                      <>
                        <textarea
                          className={classNames(
                            'block w-full px-2 py-1 mt-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
                          )}
                          onChange={(e) => {
                            setAllergies(e.target.value)
                          }}
                          rows={3}
                        >
                          {allergies ?? ''}
                        </textarea>
                        {/*<input
                                        className={classNames(
                                          'block w-full px-2 py-1 mt-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
                                        )}
                                        type={'text'}
                                        value={allergies ?? ''}
                                        placeholder={''}
                                        onChange={(e) => {
                                          setAllergies(e.target.value)
                                        }}
                                      />*/}
                      </>
                    ) : (
                      <>
                        {kid?.attributes?.kid_extra?.attributes?.allergies ? (
                          <div
                            className="whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                kid?.attributes?.kid_extra?.attributes
                                  ?.allergies,
                              ),
                            }}
                          />
                        ) : (
                          <p className={'text-sm text-gray-400'}>
                            {t('ourKids.noAllergiesOrSick')}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {editAllergies ? (
                    <div className="flex-1 flex justify-end pr-3 space-x-3">
                      <Button
                        variant="green"
                        icon={faFloppyDisk}
                        size="md"
                        onClick={async () => {
                          setSaveLoading(true)
                          if (kid) {
                            await saveKidInfo({ kid, allergies, other })
                          }
                          setSaveLoading(false)
                          setEditAllergies(false)
                        }}
                      />
                      <Button
                        icon={faXmark}
                        size="md"
                        onClick={() => {
                          setEditAllergies(false)
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className={
                        'flex-1 flex justify-end pr-3 text-gray-400 cursor-pointer'
                      }
                    >
                      {!readOnly?.childInfo && (
                        <Button
                          icon={faPen}
                          size="md"
                          onClick={() => {
                            setEditAllergies(true)
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {currentUserRole?.attributes.role_type >
                    USER_ROLE_TYPE.PARENT && (
                    <div className="flex flex-row  items-center">
                      <div className=" py-[58px] flex flex-col font-medium w-full items-center text-gray-300 justify-center">
                        <FontAwesomeIcon icon={faSensorAlert} size="lg" />
                        {t('general.noPermission')}
                      </div>
                    </div>
                  )}
                </>
              )}
              {canSeAll && canSeeSensetive && (
                <div className="mt-4 w-full bg-gray-300 h-[1px]"></div>
              )}
              {canSeAll && canSeeSensetive && (
                <div
                  className={classNames(
                    'flex items-center w-full p-3 rounded-2xl py-3 mt-3 ',
                    !kid?.attributes?.kid_extra?.attributes?.other_info &&
                      'opacity-60',
                  )}
                >
                  <div className={'self-start pt-2 pr-3'}>
                    <div
                      className={
                        'h-[10px] w-[10px] rounded-full bg-contactsMain'
                      }
                    />
                  </div>
                  <div className="w-full pr-4">
                    <p>{t('ourKids.other')}</p>
                    {editOtherInfo ? (
                      <>
                        <textarea
                          className={classNames(
                            'block w-full px-2 py-1 mt-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
                          )}
                          onChange={(e) => {
                            setOther(e.target.value)
                          }}
                          rows={3}
                        >
                          {other ?? ''}
                        </textarea>
                        {/*<input
                          className={classNames(
                            'block w-full px-2 py-1 mt-2 placeholder-gray-400 border border-gray-300 rounded-lg shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
                          )}
                          type={'text'}
                          value={other ?? ''}
                          placeholder={''}
                          onChange={(e) => {
                            setOther(e.target.value)
                          }}
                        />*/}
                      </>
                    ) : (
                      <>
                        {kid?.attributes?.kid_extra?.attributes?.other_info ? (
                          <div
                            className="whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                kid?.attributes?.kid_extra?.attributes
                                  ?.other_info,
                              ),
                            }}
                          />
                        ) : (
                          <p className={'text-sm text-gray-400'}>
                            {t('general.noOtherInfo')}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                  {editOtherInfo ? (
                    <div className="flex-1 flex justify-end space-x-3 pr-3">
                      <Button
                        variant="green"
                        icon={faFloppyDisk}
                        size="md"
                        onClick={async () => {
                          setSaveLoading(true)
                          if (kid) {
                            await saveKidInfo({ kid, allergies, other })
                          }
                          setSaveLoading(false)
                          setEditOtherInfo(false)
                        }}
                      />
                      <Button
                        icon={faXmark}
                        size="md"
                        onClick={() => {
                          setEditOtherInfo(false)
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className={
                        'flex-1 flex justify-end pr-3 text-gray-400 cursor-pointer'
                      }
                    >
                      {!readOnly?.childInfo && (
                        <Button
                          icon={faPen}
                          size="md"
                          onClick={() => {
                            setEditOtherInfo(true)
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {(tab === 'contacts' || !canSeAll) && (
            <div className={'flex flex-col w-full px-6'}>
              {userRoles &&
              userRoles?.filter(
                (role) =>
                  role?.attributes?.kid?.id === kid?.id ||
                  role?.kid?.id === kid?.id,
              )?.length < 1 ? (
                <div className=" py-[58px] flex flex-col font-medium w-full items-center text-lg text-gray-300 justify-center">
                  {t('ourKids.noContactInfo')}
                </div>
              ) : null}
              {userRoles
                ?.filter(
                  (role) =>
                    role?.attributes?.kid?.id === kid?.id ||
                    role?.kid?.id === kid?.id,
                )
                .map((u: UserRole) => {
                  if (
                    // TODO: Filtrera bort detta redan i queryn?? Fixat, men har kvar för säkerhetsskull
                    u?.attributes?.alternative_contact?.attributes
                      ?.archive_state
                  ) {
                    return null
                  }
                  return (
                    <div className="py-5 border shadow-md mt-3 p-3 rounded-2xl ">
                      <div
                        onClick={() => {
                          if (
                            u?.attributes?.status > USER_ROLE_STATUS.NEW ||
                            u.attributes.alternative_contact
                          ) {
                            /* navigate('/contact/' + u?.id)*/
                            if (
                              u.user?.user_setting !==
                                USER_SETTING.HIDE_CONTACT_INFO ||
                              canSeAll
                            ) {
                              if (
                                detailedContactID &&
                                detailedContactID == u.id
                              ) {
                                setDetailedContactID(null)
                              } else {
                                setLoading(true)
                                setDetailedContactID(u?.id)
                                fetchContact(u.id).finally(() =>
                                  setLoading(false),
                                )
                              }
                            }
                          }
                        }}
                        key={u?.id}
                        className={
                          'flex items-center w-full space-x-3 cursor-pointer'
                        }
                      >
                        <img
                          className={'w-[60px] h-[60px] rounded-full border '}
                          src={getImageOrPlaceHolder(
                            u?.attributes?.alternative_contact
                              ? u?.attributes?.alternative_contact.attributes
                                  ?.picture_thumb?._url
                              : u?.attributes?.user?.attributes?.myPic_thumb
                                  ?._url,
                          )}
                          alt=""
                        />
                        <div>
                          {/* TA BORT NEW OM VH */}
                          {u?.attributes.status == USER_ROLE_STATUS.NEW ? (
                            <>
                              {u?.attributes.person ? (
                                <>
                                  <p className="text-lg sm:text-xl font-medium text-gray-600">
                                    {u?.attributes?.person?.attributes
                                      ?.givenName +
                                      ' ' +
                                      u?.attributes?.person?.attributes
                                        ?.familyName}
                                  </p>
                                  <p>
                                    {u?.attributes?.user?.attributes
                                      ?.user_type == USER_TYPE.PHONE &&
                                      u?.attributes?.person?.attributes
                                        ?.phoneNumbers[0]?.value}
                                    {u?.attributes?.user?.attributes
                                      ?.user_type == USER_TYPE.EMAIL &&
                                      u?.attributes?.person?.attributes
                                        ?.emails[0]?.value}
                                    {u?.attributes?.user?.attributes
                                      ?.user_type == USER_TYPE.BANKID &&
                                      t('general.guardian')}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="text-l sm:text-xl font-medium text-gray-600">
                                    {u?.attributes?.invite_note}
                                  </p>
                                  <p>
                                    {u?.attributes?.user?.attributes
                                      ?.user_type == USER_TYPE.PHONE &&
                                      u?.attributes?.user?.attributes
                                        ?.user_extra?.attributes?.primary_phone}
                                    {u?.attributes?.user?.attributes
                                      ?.user_type == USER_TYPE.EMAIL &&
                                      u?.attributes?.user?.attributes
                                        ?.user_extra?.attributes?.email}
                                    {u?.attributes?.user?.attributes
                                      ?.user_type == USER_TYPE.BANKID &&
                                      t('general.guardian')}
                                  </p>
                                </>
                              )}
                            </>
                          ) : (
                            <p className="text-l sm:text-xl font-medium text-gray-600">
                              {u?.attributes?.user?.firstName
                                ? u?.attributes?.user?.firstName +
                                  ' ' +
                                  u?.user?.get('lastName')
                                : u?.attributes?.alternative_contact?.attributes
                                    ?.first_name +
                                  ' ' +
                                  u?.attributes?.alternative_contact?.attributes
                                    ?.last_name}
                            </p>
                          )}
                          {u?.attributes?.alternative_contact?.id ? (
                            <p className={'text-sm text-orange-400'}>
                              {u?.attributes?.relation}
                            </p>
                          ) : (
                            <p className={'text-sm text-gray-500'}>
                              {translateRelation(u?.attributes?.relation)}
                            </p>
                          )}
                        </div>
                        {(u?.attributes?.status > USER_ROLE_STATUS.NEW &&
                          u.user?.user_setting !==
                            USER_SETTING.HIDE_CONTACT_INFO) ||
                        (u.attributes.alternative_contact &&
                          u.user?.user_setting !==
                            USER_SETTING.HIDE_CONTACT_INFO) ||
                        canSeAll ? (
                          <>
                            {detailedContactID && detailedContactID == u.id ? (
                              <div className={'flex-1 flex justify-end'}>
                                <FontAwesomeIcon
                                  className="animate-rotate180 rotate-180"
                                  icon={faChevronDown}
                                  size={'sm'}
                                />
                              </div>
                            ) : (
                              <div className={'flex-1 flex justify-end'}>
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  size={'sm'}
                                />
                              </div>
                            )}
                          </>
                        ) : null}
                      </div>
                      {u?.attributes?.status == USER_ROLE_STATUS.NEW && (
                        <div className="flex mt-2 flex-row">
                          <p className="border border-eventsMain border-1 text-eventsMain py-[5px] px-[10px] text-sm rounded-full block">
                            {t('ourKids.invite')}
                          </p>
                        </div>
                      )}

                      {!loading &&
                      detailedContactID &&
                      detailedContactID == u.id ? (
                        <div
                          className={
                            'flex flex-col justify-center items-center pt-6 px-3 '
                          }
                        >
                          <div className={'w-full max-w-lg flex items-center'}>
                            <div>
                              <p className={'text-eventsMain'}>
                                {t('ourKids.phone')}
                              </p>
                              <p>
                                {contact?.alternative_contact
                                  ? contact?.alternative_contact?.attributes
                                      ?.phone_number
                                  : contact?.attributes?.user?.attributes
                                      ?.user_extra?.attributes?.primary_phone}
                              </p>
                            </div>

                            <div
                              className={
                                'flex-1 flex justify-end space-x-3 text-eventsMain cursor-pointer'
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCommentSms}
                                size={'2x'}
                                onClick={() => {
                                  const phone = contact?.alternative_contact
                                    ? contact?.alternative_contact?.attributes
                                        ?.phone_number
                                    : contact?.attributes?.user?.attributes
                                        ?.user_extra?.attributes?.primary_phone
                                  window.open('sms:' + phone)
                                }}
                              />
                              <FontAwesomeIcon
                                icon={faPhone}
                                size={'2x'}
                                onClick={() => {
                                  const phone = contact?.alternative_contact
                                    ? contact?.alternative_contact?.attributes
                                        ?.phone_number
                                    : contact?.attributes?.user?.attributes
                                        ?.user_extra?.attributes?.primary_phone
                                  window.open('tel:' + phone)
                                }}
                              />
                            </div>
                          </div>
                          {contact?.attributes?.user?.attributes?.user_extra
                            ?.attributes?.secondary_phone && (
                            <div
                              className={
                                'w-full max-w-lg flex items-center mt-6'
                              }
                            >
                              <div>
                                <p className={'text-eventsMain'}>
                                  {t('ourKids.phone')}
                                </p>
                                <p>
                                  {
                                    contact?.attributes?.user?.attributes
                                      ?.user_extra?.attributes?.secondary_phone
                                  }
                                </p>
                              </div>

                              <div
                                className={
                                  'flex-1 flex justify-end space-x-3 text-eventsMain cursor-pointer'
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faCommentSms}
                                  size={'2x'}
                                  onClick={() => {
                                    const phone =
                                      contact?.attributes?.user?.attributes
                                        ?.user_extra?.attributes
                                        ?.secondary_phone
                                    window.open('sms:' + phone)
                                  }}
                                />
                                <FontAwesomeIcon
                                  icon={faPhone}
                                  size={'2x'}
                                  onClick={() => {
                                    const phone =
                                      contact?.attributes?.user?.attributes
                                        ?.user_extra?.attributes
                                        ?.secondary_phone
                                    window.open('tel:' + phone)
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          <div
                            className={'w-full max-w-lg flex items-center mt-6'}
                          >
                            <div>
                              <p className={'text-eventsMain'}>
                                {t('login.email')}
                              </p>
                              <p>
                                {contact?.alternative_contact
                                  ? contact?.alternative_contact?.attributes
                                      ?.email_address
                                  : contact?.attributes?.user?.attributes
                                      ?.user_extra?.attributes?.email}
                              </p>
                            </div>

                            <div
                              className={
                                'flex-1 flex justify-end space-x-3 text-eventsMain cursor-pointer'
                              }
                            >
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                size={'2x'}
                                onClick={() => {
                                  const mail = contact?.alternative_contact
                                    ? contact?.alternative_contact?.attributes
                                        ?.email_address
                                    : contact?.attributes?.user?.attributes
                                        ?.user_extra?.attributes?.email
                                  window.open('mailto:' + mail)
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className={'w-full max-w-lg flex items-center mt-6'}
                          >
                            <div>
                              <p className={'text-eventsMain'}>
                                {t('contactList.adress')}
                              </p>
                              <p>
                                {contact?.alternative_contact
                                  ? contact?.alternative_contact?.attributes
                                      ?.address
                                  : contact?.attributes?.user?.attributes
                                      ?.user_extra?.attributes?.street_address}
                              </p>
                              <p>
                                {contact?.alternative_contact
                                  ? contact?.alternative_contact?.attributes
                                      ?.zip_code
                                  : contact?.attributes?.user?.attributes
                                      ?.user_extra?.attributes?.zip_code}{' '}
                                {contact?.alternative_contact
                                  ? contact?.alternative_contact?.attributes
                                      ?.city
                                  : contact?.attributes?.user?.attributes
                                      ?.user_extra?.attributes?.city}
                              </p>
                            </div>
                            <div
                              className={
                                'flex-1 flex justify-end space-x-3 text-eventsMain cursor-pointer'
                              }
                            >
                              <FontAwesomeIcon
                                icon={faLocationDot}
                                size={'2x'}
                                onClick={() => {
                                  const geo = contact?.alternative_contact
                                    ? contact?.alternative_contact?.attributes
                                        ?.geo
                                    : contact?.attributes?.user?.attributes
                                        ?.user_extra?.attributes?.geo_point
                                  window.open(
                                    `https://www.google.com/maps/search/?api=1&query=${geo?.latitude}%2C${geo?.longitude}`,
                                    '_blank',
                                  )
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      </ModalRegular>

      <ModalRegular
        title={''}
        className={'max-w-xl'}
        isOpen={showEditModal}
        onClose={() => {
          setShowEditModal(false)
        }}
      >
        <div className={'pt-14 flex flex-col space-y-6'}>
          <input
            className={classNames(
              'block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
            )}
            type={'text'}
            value={allergies ?? ''}
            placeholder={''}
            onChange={(e) => {
              setAllergies(e.target.value)
            }}
          />

          <input
            className={classNames(
              'block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
            )}
            type={'text'}
            value={other ?? ''}
            placeholder={''}
            onChange={(e) => {
              setOther(e.target.value)
            }}
          />
          <div className={'flex justify-end'}>
            <Button
              loading={saveLoading}
              label={'Spara'}
              onClick={async () => {
                setSaveLoading(true)
                if (kid) {
                  await saveKidInfo({ kid, allergies, other })
                }
                setSaveLoading(false)
                setShowEditModal(false)
              }}
            />
          </div>
        </div>
      </ModalRegular>
    </div>
  )
})
