import {
  faMapPin,
  faTrashCan,
  faLocation,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components/Button'
import { LocationInterface } from '../../../Models/Location'
import { faLocationPlus } from '@fortawesome/pro-solid-svg-icons'
import Swal from 'sweetalert2'
import classNames from 'classnames'

interface LocationBoxProps {
  location: LocationInterface
  onSaveLocationModal: () => void
  onRemove: () => void
  hideRemove?: boolean
  hideOnSave?: boolean
  showIcon?: boolean
  noPadding?: boolean
}

export const LocationBox: React.FC<LocationBoxProps> = ({
  location,
  onSaveLocationModal,
  onRemove,
  hideRemove,
  hideOnSave,
  showIcon,
  noPadding,
}) => {
  const { t } = useTranslation()

  // Använd LocationInterface för att tydligt definiera typen av 'location'-parametern
  const openInGoogleMaps = (location: LocationInterface) => {
    const address = `${location.address}, ${location.zipcode} ${location.city}`
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address,
    )}`
    window.open(googleMapsUrl, '_blank')
  }

  return (
    <div
      onClick={() => openInGoogleMaps(location)}
      className={classNames(
        'flex justify-between rounded-xl cursor-pointer flex-row items-center w-full',
        noPadding ? '' : 'p-4',
      )}
    >
      <div className="">
        {location.name && (
          <div className="flex flex-row gap-x-2 w-full items-center cursor-pointer font-bold">
            {location.name}
          </div>
        )}

        {location.address && (
          <div className="cursor-pointer">{location.address}</div>
        )}
        <div className="cursor-pointer">
          {location.zipcode && <>{location.zipcode + ' '}</>}
          {location.city && <>{location.city}</>}
        </div>
        {location.lat && <div className="cursor-pointer">{location.lat}</div>}
        {location.long && <div className="cursor-pointer">{location.long}</div>}
      </div>
      <div className="flex flex-row gap-x-2">
        {showIcon && (
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            size="2x"
            className="text-green-400"
          />
        )}
        {!hideOnSave && (
          <Button
            variant="fullBlue"
            size="xs"
            icon={faLocationPlus}
            onClick={(e) => {
              e.stopPropagation()
              onSaveLocationModal()
            }}
            title={t('general.saveLocation')}
          />
        )}
        {!hideRemove && (
          <Button
            variant="fullRed"
            size="xs"
            icon={faTrashCan}
            onClick={(e) => {
              e.stopPropagation()
              Swal.fire({
                text: t('general.archiveLocation') + '🥺',
                icon: 'warning',
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonColor: '#7eb5f4',
                cancelButtonColor: '#d33',
                confirmButtonText: t('general.ok'),
                cancelButtonText: t('general.cancel'),
              }).then(async (result) => {
                if (result.isConfirmed) {
                  onRemove()
                }
              })
            }}
          />
        )}
      </div>
    </div>
  )
}
