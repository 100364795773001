import { faEllipsisVertical, faFolder } from '@fortawesome/pro-solid-svg-icons'
import { Button } from '../../../components/Button'
import Page from '../../../components/Page'
import FolderCard from './components/FolderCard'
import { faFolderUser } from '@fortawesome/pro-regular-svg-icons'
import FolderCardParent from './components/FolderCardParent'
import ModalRegular from '../../../components/ModalRegular'
import { useContext, useEffect, useState } from 'react'
import { t } from 'i18next'
import Swal from 'sweetalert2'
import { Slider } from '../../../components/Slider'
import { Link, SchoolStoreContext } from '../../../store/schoolStore'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../components/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavigationStoreContext } from '../../../store/navigationStore'
import { DefaultKidFolders } from '../../../Models/SchoolSetting'
import { v4 as uuidv4 } from 'uuid'

export const DocumentsEdit: React.FC = () => {
  const [showLinkModal, setShowLinkModal] = useState(false)
  const [showFolderModal, setShowFolderModal] = useState(false)
  const {
    school,
    setAdminCreateFolder,
    fetchSingleSchool,
    addExternalLink,
    deleteExternalLink,
    updateExternalLink,
    createDefaultKidFolder,
  } = useContext(SchoolStoreContext)
  const [loading, setLoading] = useState(false)
  const { schoolId } = useParams()
  useEffect(() => {
    if (schoolId) {
      setLoading(true)
      fetchSingleSchool(schoolId).finally(() => {
        setLoading(false)
      })
    }
  }, [schoolId])
  const [disableAdminCreateFolder, setDisableAdminCreateFolder] =
    useState(false)
  const [link, setLink] = useState('')
  const [linkName, setLinkName] = useState('')
  const [isShared, setIsShared] = useState(false)
  const [currentLink, setCurrentLink] = useState<Link | null>(null)
  const [currentLinkIndex, setCurrentLinkIndex] = useState<number | null>(null)
  const [fakeState, setFakeState] = useState(false)
  const [newFolder, setNewFolder] = useState<DefaultKidFolders>({
    name: '',
    only_staff: true,
    archive_state: undefined,
    id: uuidv4(),
  })

  useEffect(() => {
    if (currentLink) {
      setLink(currentLink.link)
      setLinkName(currentLink.name)
      setIsShared(currentLink.visibility === 0)
    } else {
      setLink('')
      setLinkName('')
      setIsShared(false)
    }
  }, [currentLink])
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('menu.documents'))
  }, [t])
  return (
    <>
      <Page naked>
        {loading && <Loading />}
        <div className="p-0 flex flex-col gap-y-2">
          <div className="flex flex-wrap border rounded-2xl py-4 px-4 bg-white shadow-tyraShadow">
            <div className="font-medium text-2xl text-gray-600">
              <>{t('admin.permissions')}</>
            </div>{' '}
            <div className="flex w-full flex-row justify-between items-center">
              <div className="flex">
                <>{t('admin.allowOtherCreateFolder')}</>
              </div>
              <div className="flex">
                {!loading && (
                  <Slider
                    onSliderClick={() => {
                      if (school && !disableAdminCreateFolder) {
                        setDisableAdminCreateFolder(true)
                        setAdminCreateFolder(
                          school,
                          school?.school_setting.attributes
                            .doc_staff_create_folder
                            ? false
                            : true,
                        ).finally(() => {
                          setDisableAdminCreateFolder(false)
                        })
                      }
                    }}
                    onBoxClick={() => {
                      if (school && !disableAdminCreateFolder) {
                        setDisableAdminCreateFolder(true)
                        setAdminCreateFolder(
                          school,
                          school?.school_setting.attributes
                            .doc_staff_create_folder
                            ? false
                            : true,
                        ).finally(() => {
                          setDisableAdminCreateFolder(false)
                        })
                      }
                    }}
                    id={0}
                    value={
                      school?.school_setting.attributes.doc_staff_create_folder
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-wrap border rounded-2xl gap-y-4 py-4 p-4 bg-white shadow-tyraShadow">
            <div className="flex flex-col w-full ">
              <div className="font-medium text-gray-600 text-2xl">
                <>{t('admin.externalFileLib')}</>
              </div>
            </div>
            <div className="flex w-full justify-between items-center">
              <div className="text-md w-full text-gray-500 font-light">
                <>{t('admin.addLinkExternalFileLib')}</>
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              {school?.school_setting?.attributes?.document_external_links?.map(
                (link: Link, index: number) => {
                  return (
                    <div className="border p-4 w-full rounded-2xl justify-between flex flex-col sm:flex-row">
                      <div className="flex flex-col w-full">
                        <p>{link.name}</p>
                        <a
                          className="hover:text-eventsMain"
                          href={link.link}
                          target="_blank"
                        >
                          {link.link}
                        </a>
                      </div>
                      <div className="flex flex-col gap-y-4 sm:mt-0 mt-4 sm:flex-row gap-x-4 items-center w-full justify-end">
                        <Button
                          size="customSize"
                          label={t('absence.change')}
                          variant="fullYellow"
                          onClick={() => {
                            setCurrentLink(link)
                            setCurrentLinkIndex(index)
                            setShowLinkModal(true)
                          }}
                        />
                        <Button
                          size="customSize"
                          label={t('documents.remove')}
                          variant="fullRed"
                          onClick={() => {
                            if (school) {
                              Swal.fire({
                                text: t('admin.deleteLinkWarning'),
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: t('general.yes'),
                                cancelButtonText: t('general.cancel'),
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteExternalLink(school, index).finally(
                                    () => {
                                      if (schoolId) {
                                        setLoading(true)
                                        fetchSingleSchool(schoolId).finally(
                                          () => {
                                            setLoading(false)
                                          },
                                        )
                                      }
                                    },
                                  )
                                  Swal.fire({
                                    text: t('admin.deleteLinkSuccess'),
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: t('general.ok'),
                                    cancelButtonText: t('general.cancel'),
                                  })
                                }
                              })
                            }
                          }}
                        />
                      </div>
                    </div>
                  )
                },
              )}
            </div>

            <div className="flex justify-end w-full">
              <Button
                variant="fullGray"
                size="sm"
                label={t('admin.addLink')}
                onClick={() => {
                  setCurrentLink(null)
                  setCurrentLinkIndex(null)
                  setLink('')
                  setLinkName('')
                  setIsShared(false)
                  setShowLinkModal(true)
                }}
              />
            </div>
          </div>
          {!school?.school_setting.attributes?.document_hidden_types?.includes(
            4,
          ) && (
            <div className="flex flex-col">
              <div className="flex flex-wrap border rounded-2xl bg-white shadow-tyraShadow pt-4">
                <div className="flex flex-col pl-4">
                  <div className="font-medium text-2xl text-gray-600">
                    <>{t('admin.subfolder')}</>
                  </div>
                  <div className="text-md text-gray-500 font-light">
                    <>{t('admin.subfolderText')}</>
                  </div>
                </div>
                <div className="flex justify-center w-full items-center flex-row flex-wrap">
                  {school?.school_setting.attributes?.document_default_kid_folders
                    ?.filter((fold: DefaultKidFolders) => !fold.archive_state)
                    ?.map((folder: DefaultKidFolders) => {
                      if (schoolId) {
                        return (
                          <FolderCard
                            label={folder.name}
                            folder={folder}
                            school={school}
                            onSave={() =>
                              fetchSingleSchool(schoolId).finally(() =>
                                setFakeState(!fakeState),
                              )
                            }
                          />
                        )
                      }
                    })}
                </div>
                <div className="flex justify-center sm:justify-end w-full px-4 pb-4">
                  {' '}
                  <Button
                    variant="fullOrange"
                    size="sm"
                    label={t('documents.saveFolder')}
                    onClick={() => setShowFolderModal(true)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Page>

      <ModalRegular
        onClose={() => {
          setShowLinkModal(false)
        }}
        isOpen={showLinkModal}
        bgColor="bg-eventsMain p-4"
        title={currentLink ? t('absence.change') : t('admin.addLink')}
        closeColor="white"
        className="max-w-sm"
      >
        <div className="p-8 flex flex-col gap-y-8">
          <div className="flex flex-col w-full gap-y-2">
            <div className="flex">
              <input
                value={link}
                onChange={(e) => setLink(e.target.value)}
                className="border shadow p-2 rounded-lg w-full focus:outline-none focus:ring-eventsMain focus:border-eventsMain "
                placeholder={t('admin.link')}
              />
            </div>
            <div>
              <input
                value={linkName}
                onChange={(e) => setLinkName(e.target.value)}
                className="border shadow p-2 rounded-lg w-full focus:outline-none focus:ring-eventsMain focus:border-eventsMain "
                placeholder={t('admin.nameLink')}
              />
            </div>
          </div>

          <div className="flex">
            <div className="flex flex-row justify-start w-full items-center gap-x-2">
              <input
                type="checkbox"
                checked={isShared}
                onChange={(e) => setIsShared(e.target.checked)}
              />
              <div>
                <> {t('documents.shareWithParent')}</>
              </div>
            </div>
          </div>
          <div>
            <h1>
              <> {t('admin.createLinkText')}</>
            </h1>
          </div>
          <div className="w-full flex justify-center">
            <Button
              label={currentLink ? t('general.save') : t('box.addCategory')}
              variant="fullBlue"
              disabled={!link || !linkName}
              size="custom"
              onClick={async () => {
                if (school) {
                  let success
                  if (currentLink && currentLinkIndex !== null) {
                    success = await updateExternalLink(
                      school,
                      link,
                      linkName,
                      isShared,
                      currentLinkIndex,
                    )

                    if (!success) {
                      setShowLinkModal(false)
                    }
                    Swal.fire({
                      text: success
                        ? t('events.eventAlarmFail')
                        : t('admin.updateLinkSuccess'),
                      icon: success ? 'error' : 'success',
                      showCancelButton: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    })
                  } else {
                    success = await addExternalLink(
                      school,
                      link,
                      linkName,
                      isShared,
                    )
                    if (success) {
                      setShowLinkModal(false)
                    }
                    Swal.fire({
                      text: success
                        ? t('admin.addLinkSuccess')
                        : t('events.eventAlarmFail'),
                      icon: success ? 'success' : 'error',
                      showCancelButton: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    })
                  }
                } else {
                  Swal.fire({
                    text: t('events.eventAlarmFail'),
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                    cancelButtonText: t('general.cancel'),
                  })
                }
              }}
            />
          </div>
        </div>
      </ModalRegular>
      <ModalRegular
        onClose={() => {
          setShowFolderModal(false)
        }}
        isOpen={showFolderModal}
        bgColor="bg-eventsMain p-4"
        title={t('documents.saveFolder')}
        closeColor="white"
        className="max-w-sm"
      >
        <div className="p-8 flex flex-col gap-y-8">
          <div className="flex flex-col w-full gap-y-2">
            <div>
              <input
                className="border shadow p-2 rounded-lg w-full focus:outline-none focus:ring-eventsMain focus:border-eventsMain "
                placeholder={t('documents.name')}
                value={newFolder.name}
                onChange={(e) =>
                  setNewFolder({ ...newFolder, name: e.target.value })
                }
              />
            </div>
          </div>

          <div className="flex">
            <div className="flex flex-row justify-start w-full items-center gap-x-2">
              <input
                type="checkbox"
                checked={newFolder.only_staff ? false : true}
                onChange={() =>
                  setNewFolder({
                    ...newFolder,
                    only_staff: !newFolder.only_staff,
                  })
                }
              />
              <div>
                {' '}
                <> {t('documents.shareWithParent')}</>
              </div>
            </div>
          </div>
          <div>
            <h1>
              <> {t('admin.createFolderText')}</>
            </h1>
          </div>
          <div className="w-full flex justify-center">
            <Button
              label={t('box.addCategory')}
              variant="fullBlue"
              size="custom"
              onClick={() => {
                if (newFolder.name && schoolId && school) {
                  createDefaultKidFolder(newFolder, school).finally(() => {
                    fetchSingleSchool(schoolId)
                    setShowFolderModal(false)
                  })
                } else {
                  // Notify that name is needed
                }
              }}
            />
          </div>
        </div>
      </ModalRegular>
    </>
  )
}
