import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { Button } from '../../../components/Button'
import { Event as EventModel } from '../../../Models/Event'
import { EventsDateCircle } from './EventsDateCircle'
import { useTranslation } from 'react-i18next'
import {
  faFolderOpen,
  faImage,
  faPaperclip,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDayForTranslatation } from './EventsInfo'
import DOMPurify from 'dompurify'

interface Props {
  event: EventModel
  onClick: () => void
}

export const EventsRow = observer((props: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-wrap border-t justify-center items-center p-4">
        <div className="flex-item">
          <EventsDateCircle
            event={props.event}
            dateStamp={true}
            day={getDayForTranslatation(moment(props.event.date).day())}
          />
        </div>
        <div className="flex-1 overflow-hidden pl-5 relative min-w-[150px]">
          {!props.event.read && (
            <div
              className={
                'h-4 w-4 bg-eventsMain rounded-full z-10 float-left mr-2'
              }
            />
          )}
          <b className="float-left text-gray-800">{props.event.get('topic')}</b>
          <br />
          <div className="w-full line-clamp-2">
            <div
              className="whitespace-pre-wrap"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.event.get('content')),
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 sm:ml-12 mr-4 mt-4 sm:mt-0">
          <div>
            <div className="flex justify-center gap-x-2">
              {props.event.get('pdf') || props.event.get('picture') ? (
                <FontAwesomeIcon
                  className="text-gray-500 mb-2 "
                  icon={faPaperclip}
                />
              ) : null}
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              variant="default"
              size="sm"
              label={t('general.open')}
              onClick={() => props.onClick()}
            />
          </div>
          <div>
            <b className="flex mt-2 text-[70%] text-gray-500 align-baseline justify-center">
              {t('events.createdBy')}{' '}
              {props.event.get('author') ? (
                props.event.get('author').get('firstName') ? (
                  props.event.get('author').get('firstName') +
                  ' ' +
                  props.event.get('author').get('lastName')
                ) : (
                  <> {t('general.unknown')}</>
                )
              ) : (
                ''
              )}
            </b>
          </div>
        </div>
      </div>
    </>
  )
  /*return (
    <>
      <div
        className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 items-center m-4"
        onClick={() => props.onClick()}
      >
        <div className="grid grid-cols-2 gap-2 justify-center items-center m-4">
          <EventsDateCircle event={props.event} />
          <div className="">
            <img
              className={'w-[50px] h-[50px] object-cover rounded-full'}
              src={props.event.get('author').get('myPic')?._url}
              alt=""
            />
            {props.event.get('author')
              ? props.event.get('author').get('firstName') +
                ' ' +
                props.event.get('author').get('lastName')
              : ''}
          </div>
        </div>
        <div className="grid grid-cols-1">
          <div className="p-5">
            <b>{props.event.get('topic')}</b>
            <br />
            <p className="truncate text-ellipsis">
              {props.event.get('content')}
            </p>
          </div>
        </div>
      </div>
      <hr />
    </>
  )*/
})
