import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useState } from 'react'
import { LoadingBar } from '../../../../components/LoadingBar'
import { t } from 'i18next'
import {
  getImageFromRole,
  getImageOrPlaceHolder,
} from '../../../../utils/parseUtils'
import { UserRole } from '../../../../Models/UserRole'

interface Props {
  children?: React.ReactNode
  description?: string
  title: string
  loading?: boolean
  className?: string
  icon?: IconDefinition
  iconClassName?: string
  titleContainerClassName?: string
  onClick?: () => void
  relation?: string
  userRole: UserRole
  smallPic?: boolean
}
export const translateRelation = (relation: string) => {
  switch (relation) {
    case 'Personal':
      return t('documents.staff')
    case 'Mamma':
      return t('general.mother')
    case 'Pappa':
      return t('general.father')
    case 'Vårdnadshavare':
      return t('documents.parent')
    default:
      return relation
  }
}
export default function KidCard(props: Props) {
  return (
    <div
      className={classNames(
        'w-full min-h-[60px] min-w-[300px] overflow-hidden rounded-2xl m-4 cursor-pointer',
      )}
      onClick={props.onClick}
    >
      {props?.loading && <LoadingBar />}
      <div className="flex space-x-4 rounded-b-2xl items-center font-bold font-sans bg-white text-neutral-600 relative">
        <div
          className={classNames(
            'flex flex-wrap py-3 font-medium justify-start w-full h-full',
            props.className,
          )}
        >
          <>
            <div className="flex relative flex-row items-center gap-x-2">
              <div className="flex flex-row pl-2">
                <label
                  className={classNames(
                    'relative flex items-center justify-center border rounded-full bg-gray-300 overflow-hidden',
                    {
                      'max-w-[60px] max-h-[60px] min-w-[60px] min-h-[60px]':
                        !props.smallPic,
                      'max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]':
                        props.smallPic,
                    },
                  )}
                >
                  <img
                    className={classNames('object-cover rounded-full', {
                      'w-[60px] h-[60px]': !props.smallPic,
                      'w-[40px] h-[40px]': props.smallPic,
                    })}
                    src={getImageOrPlaceHolder(
                      props.userRole.kid?.attributes?.kidImage?._url,
                    )}
                    alt=""
                  />
                </label>
              </div>
              <div
                className={classNames(
                  'flex flex-wrap w-full flex-col items-center',
                  props.titleContainerClassName,
                )}
              >
                <div>
                  <div className="flex sm:text-sm text-xs text-gray-400">
                    {/*<>{t('admin.parentTo')}</>*/}
                    <>
                      {translateRelation(
                        props.relation
                          ? props.relation + ' ' + t('general.to')
                          : t('admin.parentTo'),
                      )}
                    </>
                  </div>
                  <div className="flex items-start font-bold">
                    {props.title}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grow flex items-center justify-center">
              {props.children}
            </div>
          </>
        </div>
      </div>
    </div>
  )
}
