import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Button } from '../../../components/Button'
import { Slider } from '../../../components/Slider'
import { ButtonDropdown } from '../../../components/Dropdown'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import CustomInput from '../../../components/CustomInput'
import { UserStoreContext } from '../../../store/userStore'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import Swal from 'sweetalert2'
import { t } from 'i18next'
import { UserRole } from '../../../Models/UserRole'

export const InviteAuthCard: React.FC<{
  onClick?: any
  active: boolean
  hideSchedule?: boolean
  userRole?: UserRole
  showMenuHeader?: boolean
  showButtons?: boolean
  onSliderClickButton?: boolean
}> = ({
  onSliderClickButton,
  onClick,
  active,
  hideSchedule,
  userRole,
  showMenuHeader,
  showButtons,
}) => {
  const { currentUserRole } = useContext(UserStoreContext)
  const isTeacher =
    currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
      ? true
      : false
  const otherRef = useRef<HTMLInputElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [menuRef, togglerRef] = useClickOutside(() => setIsOpen(false))
  const [openOtherModal, setOpenOtherModal] = useState(false)
  const defaultLabel =
    !active && isTeacher
      ? t('settings.chooseRelation')
      : t('settings.chooseDepartment')
  const [selectedLabel, setSelectedLabel] = useState(defaultLabel)
  const [onSliderClick, setOnSliderClick] = useState(false)
  return (
    <div className="border p-4 rounded-2xl shadow flex w-full max-w-[400px] flex-col gap-y-10">
      <div className="flex flex-row items-center justify-between gap-x-10">
        <label className="relative flex items-center justify-center border rounded-full max-w-[60px] bg-gray-300  max-h-[60px]  min-w-[60px]  min-h-[60px] overflow-hidden">
          <img
            className={'w-[60px] h-[60px] object-cover rounded-full'}
            alt=""
          />
        </label>

        <div className="relative flex w-full flex-col">
          {showMenuHeader && (
            <div className="text-semibold text-sm">
              <>{t('settings.teacherOn')}</> (!!skolan)
            </div>
          )}
          <button
            ref={togglerRef}
            type="button"
            className="w-full rounded-md group focus:outline-none hover:bg-gray-50"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <div className="flex px-4 py-2 border rounded-full shadow w-full font-normal items-center justify-between gap-x-4">
              <p className="font-semibold"> {selectedLabel}</p>
              <FontAwesomeIcon
                className={`text-gray-400 hover:text-gray-500 ${
                  isOpen ? 'animate-rotate-to-180' : 'animate-rotate-to-0'
                }`}
                icon={faChevronDown}
              />{' '}
            </div>
          </button>

          {isOpen && (
            <div
              ref={menuRef as RefObject<HTMLDivElement>}
              className="absolute right-6 z-50 mx-3 origin-top divide-y w-52 top-12"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <ButtonDropdown.Menu>
                <ButtonDropdown.Section>
                  <>
                    {!active && isTeacher ? (
                      <>
                        <ButtonDropdown.Button
                          label={t('documents.parent')}
                          onClick={() => {
                            setSelectedLabel(t('documents.parent'))
                            setIsOpen(false)
                          }}
                        />
                        <ButtonDropdown.Button
                          label={t('general.mother')}
                          onClick={() => {
                            setSelectedLabel(t('general.mother'))
                            setIsOpen(false)
                          }}
                        />
                        <ButtonDropdown.Button
                          label={t('general.father')}
                          onClick={() => {
                            setSelectedLabel(t('general.father'))
                            setIsOpen(false)
                          }}
                        />
                        <ButtonDropdown.Button
                          label={t('settings.other')}
                          onClick={() => {
                            setOpenOtherModal(true)
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <ButtonDropdown.Button
                          label="Fjärilen"
                          onClick={() => {
                            setSelectedLabel('Fjärilen')
                            setIsOpen(false)
                          }}
                        />
                        <ButtonDropdown.Button
                          label="Bumblebee2"
                          onClick={() => {
                            setSelectedLabel('Bumblebee2')
                            setIsOpen(false)
                          }}
                        />
                        <ButtonDropdown.Button
                          label="El bosque 🌳"
                          onClick={() => {
                            setSelectedLabel('El bosque 🌳')
                            setIsOpen(false)
                          }}
                        />
                        <ButtonDropdown.Button
                          label="Ingen avdelning"
                          onClick={() => {
                            setSelectedLabel('-')
                          }}
                        />
                      </>
                    )}
                  </>
                </ButtonDropdown.Section>
              </ButtonDropdown.Menu>
            </div>
          )}
        </div>
      </div>
      {isTeacher && (
        <div className="flex flex-row justify-between items-center">
          <p className="font-medium">
            <>{t('settings.hideScheduale')}</>
          </p>
          <Slider
            id={0}
            onSliderClick={() => {
              setOnSliderClick(onSliderClick ? true : false)
            }}
            onBoxClick={function (id: number): void {
              throw new Error('Function not implemented.')
            }}
          />
        </div>
      )}
      <div>
        {!active ? (
          <>
            {showButtons && (
              <div className="flex justify-between">
                <Button
                  size="sm"
                  variant={'borderRed'}
                  label={t('documents.remove') + ' ' + '😳'}
                  onClick={() => {
                    Swal.fire({
                      title: t('documents.remove') + '?',
                      text: t('admin.removeActiveOneKid')
                        .replace(
                          'XYC1337',
                          userRole?.user?.firstName +
                            ' ' +
                            userRole?.user?.lastName,
                        )
                        .replace('CYX7331', userRole?.kid?.attributes.kidName),
                      icon: 'info',
                      showCancelButton: true,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      cancelButtonText: t('general.cancel'),
                      confirmButtonText: t('documents.remove'),
                    })
                  }}
                />
                <Button
                  size="sm"
                  variant={'fullBlue'}
                  label={t('settings.accept')}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="flex justify-center">
              <Button
                size="sm"
                variant={'borderRed'}
                label={t('documents.remove') + ' ' + '😳'}
                onClick={() => {
                  Swal.fire({
                    title: t('documents.remove') + '?',
                    text: t('admin.removeActiveOneKid')
                      .replace(
                        'XYC1337',
                        userRole?.user?.firstName +
                          ' ' +
                          userRole?.user?.lastName,
                      )
                      .replace('CYX7331', userRole?.kid?.attributes.kidName),
                    icon: 'info',
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    cancelButtonText: t('general.cancel'),
                    confirmButtonText: t('documents.remove'),
                  })
                }}
              />
            </div>
          </>
        )}
      </div>
      <ModalRegular
        isOpen={openOtherModal}
        onClose={() => {
          setOpenOtherModal(false)
        }}
        bgColor="bg-eventsMain p-4"
        closeColor="white"
        title={t('settings.chooseRelation')}
      >
        <div className="flex flex-col p-4">
          <div>
            <p>
              <>{t('settings.otherInfo')}</>
            </p>
            <div>
              <CustomInput
                ref={otherRef}
                onChange={function (value: string): void {
                  throw new Error('Function not implemented.')
                }}
              />
            </div>
          </div>
          <div className="flex flex-row w-full justify-center mt-10">
            {' '}
            <Button
              onClick={() => {
                setOpenOtherModal(false)
              }}
              variant="fullBlue"
              size="custom"
              label={t('general.done')}
            />
          </div>
        </div>
      </ModalRegular>
    </div>
  )
}
