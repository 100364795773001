import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { BloggDoc } from '../Models/BloggDoc'
import { Button } from './Button'
import { t } from 'i18next'

interface Props {
  children?: React.ReactNode
  isOpen: boolean
  onClose: () => void
  title?: string
  className?: string
  overlayClassName?: string
  bgColor?: string
  closeColor?: string
  bloggDoc?: BloggDoc
  showCancelButton?: boolean
  showOkButton?: boolean
  onConfirm?: (isConfirm: boolean) => void
  header?: string
  spinner?: boolean
  text?: string
  showCloseButton?: boolean
}

export default function SwalCustom({
  bgColor,
  children,
  isOpen,
  onClose,
  title,
  className,
  overlayClassName,
  closeColor,
  bloggDoc,
  showCancelButton,
  showOkButton,
  onConfirm,
  header,
  spinner,
  text,
  showCloseButton,
}: Props) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        style={{ zIndex: 11000 }}
        as="div"
        className="fixed inset-0 overflow-y-auto "
        onClose={() => onClose()}
      >
        <Dialog.Overlay
          className={classNames(
            'fixed inset-0 bg-black opacity-50',
            overlayClassName,
          )}
        />

        <div className="min-h-screen px-3 text-center">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                'relative rounded-3xl inline-block w-full p-10',
                'max-w-2xl my-8 overflow-hidden text-left align-middle',
                ' transition-all transform bg-white shadow-xl',
                className,
              )}
            >
              <div
                className={classNames(
                  'flex rounded-t-2xl   ',

                  bgColor,
                )}
              >
                <Dialog.Title
                  as="p"
                  className="text-xl font-medium leading-6 text-white"
                >
                  {title}
                </Dialog.Title>
                {showCloseButton && (
                  <button
                    type="button"
                    className="-m-4 p-2 text-gray-700 hover:text-gray-500 absolute right-6 top-6 outline-0"
                    onClick={(e) => {
                      e.stopPropagation()
                      onClose()
                    }}
                  >
                    <span className="sr-only">Close panel</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      icon={faClose}
                      color={closeColor}
                    />
                  </button>
                )}
              </div>
              <div className="flex flex-row w-full justify-center items-center font-bold text-2xl">
                {' '}
                {header}
              </div>

              {children}
              {spinner && (
                <div className=" w-full h-full overflow-hidden flex justify-center items-center ">
                  <img
                    src="/images/Circle.png"
                    className="h-20 w-20 animate-spinner"
                  />
                </div>
              )}
              <div
                className={classNames('flex w-full mt-4', {
                  'justify-between': showCancelButton && showOkButton,
                  'justify-center': !showCancelButton || !showOkButton,
                })}
              >
                {showCancelButton && (
                  <Button
                    size="custom"
                    variant={'fullRed'}
                    label={t('general.close')}
                    onClick={() => {
                      onClose()
                    }}
                  />
                )}

                {showOkButton && (
                  <Button
                    size="custom"
                    variant={'fullBlue'}
                    label={t('general.ok')}
                    onClick={() => {
                      if (onConfirm) {
                        onConfirm(true)
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
