import React, { useContext, useEffect, useState } from 'react'
import Page from '../../../components/Page'
import ColorCard from '../../../components/ColorCard'
import { useNavigate, useParams } from 'react-router-dom'
import { t, use } from 'i18next'
import { NavigationStoreContext } from '../../../store/navigationStore'
import PhoneInput from 'react-phone-input-2'
import { Button } from '../../../components/Button'
import CreateSchoolAccountModal from './componentes/CreateSchoolAccountModal'

export const CreateSchoolAcc: React.FC<{ onClick?: any }> = ({ onClick }) => {
  const [showKid, setShowKid] = useState(false)

  const navigate = useNavigate()
  const [CreateModal, setCreateModal] = useState(false)
  const { schoolId } = useParams()
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('admin.createSchoolAcc'))
  }, [t])

  return (
    <Page naked>
      <ColorCard bgColor="bg-green-600" title={t('admin.createSchoolAcc')}>
        <div className="flex flex-wrap m-4">
          <div className="flex flex-col w-full justify-center gap-y-12">
            <div className="flex flex-col gap-y-2">
              <h2 className="text-sm text-gray-800 ml-2">
                <> {t('admin.numberSchoolAcc')}</>
              </h2>
              <PhoneInput
                value={''}
                country={'se'}
                inputClass="custom-form-control"
                containerClass="custom-form-control"
              />
            </div>
            <Button
              variant="borderBlue"
              label={t('admin.controlNumber')}
              size="custom"
              onClick={() => {
                setCreateModal(true)
              }}
            />
            <CreateSchoolAccountModal
              isOpen={CreateModal}
              onClose={() => {
                setCreateModal(false)
              }}
            />
            <div className="mt-12 mb-6 ml-2 text-gray-800">
              {/*Hitta texten i appen*/}
              Detta är ett gemensamt konto för verksamheten. Ni kan koppla på
              flera användare på detta konto och snabbt växla mellan dessa.
              Varje användare behöver skapa en pinkod på sitt egna konto. Detta
              är praktiskt om ni t.ex har en delad surfplatta.
            </div>
          </div>
        </div>
      </ColorCard>
    </Page>
  )
}
