import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, useEffect, useState } from 'react'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'

interface Props {
  items: any[]
  onChange: (e: any) => void
  itemVariableKey: string
  placeholder: string
  initialSelectedItem?: any | null
  removeSelected?: boolean
  hasBorder?: boolean // New prop to control the border
  dropdownClassName?: string
  maxWidth?: string
}

const Singleselect = (props: Props) => {
  const [selectedItem, setSelectedItem] = useState(
    props.initialSelectedItem ?? null,
  ) as any

  useEffect(() => {
    setSelectedItem(props.initialSelectedItem ?? null)
  }, [props.initialSelectedItem])

  const isSelected = (id: string) => {
    return selectedItem
  }

  // Conditional classNames based on hasBorder prop
  const containerClass = classNames(
    `relative pl-4 pt-[3px] bg-white  pb-2 pr-4 rounded-full inline-block space-x-2 min-w-[${
      props.maxWidth ? props.maxWidth : '250px'
    }] w-auto max-w-[10%] h-auto min-h-[50px] max-h-[10px]`,
    { 'border border-gray-200 shadow-md': !props.hasBorder },
  )
  // Use the new dropdownClassName for Listbox.Options
  const dropdownClass = classNames(
    'absolute top-[120%] right-[2px] z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
    props.dropdownClassName,
  )

  return (
    <Listbox
      value={selectedItem ? selectedItem : ''}
      onChange={(items) => {
        setSelectedItem(items ? items : null)
        props.onChange(items ? items : null)
      }}
    >
      <div className={containerClass}>
        {!selectedItem && (
          <div className="w-full text-center text-sm font-bold mt-[10px]">
            {props.placeholder}
          </div>
        )}{' '}
        {selectedItem && (
          <div
            key={selectedItem.id}
            className={classNames(
              props.removeSelected
                ? 'p-2 m-1 w-fit flex space-x-1 justify-center font-semibold'
                : 'border border-gray-200 p-2 m-1 rounded-2xl w-fit flex space-x-1 justify-center',
            )}
          >
            {!props.removeSelected && (
              <p
                className="w-[15px] h-[15px] rounded-full text-red-500 border border-red-500 float-left text-center text-xs cursor-pointer"
                style={{ lineHeight: '13px' }}
                onClick={() => {
                  setSelectedItem(null)
                  props.onChange(null)
                }}
              >
                x
              </p>
            )}
            <p className="text-xs">{selectedItem[props.itemVariableKey]}</p>
          </div>
        )}
        <Listbox.Button
          className={classNames(
            'absolute top-[60%] mt-[-22px] hover:scale-[105%] right-2 px-2 py-1 rounded-full ',
            { ' border-gray-200 text-gray-400': props.hasBorder },
          )}
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100 "
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={dropdownClass}>
            {props.items?.map((item) => {
              if (selectedItem && selectedItem === item) {
                return null
              }
              return (
                <Listbox.Option
                  key={item.id}
                  value={item}
                  className="relative hover:bg-blue-50 select-none py-2 pl-5 cursor-pointer"
                >
                  {item[props.itemVariableKey]}
                </Listbox.Option>
              )
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default Singleselect
