import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { IKidRelation, User } from './OverviewUsersList'
import { UserRole } from '../../../Models/UserRole'
import ContactDetailsCard from './ContactDetailsCard'

interface PopupProps {
  user: User | IKidRelation | undefined
  contact: UserRole | undefined
  onClose: () => void
}

const OverviewUserContactDetailsPopUp: React.FC<PopupProps> = observer(
  ({ contact, user, onClose }) => {
    const popupRef = useRef<HTMLDivElement>(null)

    const handleClickOutside = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      event.stopPropagation()
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    const phonePrimary = contact?.alternative_contact
      ? contact?.alternative_contact?.attributes?.phone_number
      : contact?.attributes?.user?.attributes?.user_extra?.attributes
          ?.primary_phone
    const phoneSecondary =
      contact?.attributes?.user?.attributes?.user_extra?.attributes
        ?.secondary_phone
    const email =
      contact?.attributes?.user?.attributes?.user_extra?.attributes?.email

    const address = contact?.alternative_contact
      ? contact?.alternative_contact?.attributes?.address
      : contact?.attributes?.user?.attributes?.user_extra?.attributes
          ?.street_address
    const zipcode = contact?.alternative_contact
      ? contact?.alternative_contact?.attributes?.zip_code
      : contact?.attributes?.user?.attributes?.user_extra?.attributes?.zip_code
    const city = contact?.alternative_contact
      ? contact?.alternative_contact?.attributes?.city
      : contact?.attributes?.user?.attributes?.user_extra?.attributes?.city

    const addressDetails = { address, zipcode, city }

    return (
      <div
        className="fixed inset-0 flex items-center  justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50 "
        onClick={handleClickOutside}
      >
        <div
          className="bg-white h-[75%] my-auto w-3/5 h-5/5 p-6 rounded-lg shadow-lg relative sm:h-3/ sm:w-1/2 "
          ref={popupRef}
        >
          <div className="flex justify-end">
            <FontAwesomeIcon
              icon={faXmark}
              size="2x"
              className="text-gray-600 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation()
                onClose()
              }}
            />
          </div>
          <div className="flex flex-col items-center">
            <img
              className={`w-24 h-24 rounded-full object-cover ${
                !user?.thumbnail && 'bg-gray-300'
              }`}
              src={user?.thumbnail}
              alt=""
            />
            <p className=" text-gray-900 font-bold mt-2">{user?.name}</p>
          </div>

          <div className="flex flex-col p-4 h-full overflow-y-auto">
            <ContactDetailsCard
              title="Phone"
              content={phonePrimary}
              icon={faPhone}
            />

            {phoneSecondary && (
              <ContactDetailsCard
                title="Phone"
                content={phoneSecondary}
                icon={faPhone}
              />
            )}

            <ContactDetailsCard
              title="Email"
              content={email}
              icon={faEnvelope}
            />
            <ContactDetailsCard
              title="Address"
              content={addressDetails}
              icon={faLocationDot}
            />
          </div>
        </div>
      </div>
    )
  },
)

export default OverviewUserContactDetailsPopUp
