import React from 'react'
import { AppRoutes } from './Routes'
import { Provider } from 'mobx-react'
import { userStore } from '../store/userStore'
import { eventStore } from '../store/eventStore'
import { portfolioBlogStore } from '../store/portfolioBlogStore'
import { navigationStore } from '../store/navigationStore'
import { documentStore } from '../store/documentStore'
import { statisticStore } from '../store/statisticStore'
import { kidStore } from '../store/kidStore'
import { kidsStore } from '../store/kidsStore'
import { contactStore } from '../store/contactStore'
import { absenceStore } from '../store/absenceStore'
import { documentationStore } from '../store/documentationStore'
import { Notifications } from './notification/Notification'
import { adminStore } from '../store/adminStore'
import { boxStore } from '../store/boxStore'
import { categoryStore } from '../store/categoryStore'
import { contactListStore } from '../store/contactListStore'
import { dragAndDropStore } from '../store/dragAndDropStore'
import { event3Store } from '../store/event3Store'
import { exportStore } from '../store/exportStore'
import { fileJobStore } from '../store/fileJobStore'
import { schoolStore } from '../store/schoolStore'
import { tagStore } from '../store/tagStore'

function App() {
  return (
    <Provider
      store={{
        ...userStore,
        ...eventStore,
        ...portfolioBlogStore,
        ...navigationStore,
        ...documentStore,
        ...statisticStore,
        ...kidStore,
        ...kidsStore,
        ...contactStore,
        ...absenceStore,
        ...documentationStore,
        ...adminStore,
        ...boxStore,
        ...categoryStore,
        ...contactListStore,
        ...dragAndDropStore,
        ...event3Store,
        ...exportStore,
        ...fileJobStore,
        ...schoolStore,
        ...tagStore,
      }}
    >
      <Notifications />
      <AppRoutes />
    </Provider>
  )
}

export default App
