import {
  faChild,
  faChildReaching,
  faEllipsis,
  faInfo,
  faPlus,
  faTrash,
  faX,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { User } from './components/OverviewUsersList'
import { ListOfActiveUsers } from './components/ListOfActiveUsers'

export interface IProps {
  presentKids: {
    users: User[]
    header: string
  }[]
  eventTimeId?: string
  refreshDetail: () => void
  departments?: string[]
  toggleMenu: (e: any) => void
  isOpen?: boolean
}
export const CircleMenu: React.FC<IProps> = ({
  presentKids,
  eventTimeId,
  refreshDetail,
  departments,
  toggleMenu,
  isOpen,
}) => {
  const [chooseMany, setChooseMany] = useState(false)
  const [actionOnActiveUsers, setActionOnActiveUsers] = useState<
    string | null
  >()
  const navigate = useNavigate()

  const toggleChooseMany = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setActionOnActiveUsers(event.currentTarget.getAttribute('data-action'))
    setChooseMany(!chooseMany)
  }

  return (
    <div className="flex flex-col w-full p-4 ">
      <div
        className={`flex flex-col-reverse items-start justify-center w-full h-full`}
      >
        {!isOpen && (
          <div className="gap-4 flex flex-col-reverse items-center">
            <button
              onClick={toggleMenu}
              className={`w-14 h-14  rounded-full border border-blue-400 bg-white shadow-lg flex items-center justify-center mx-2 transition ease-in-out duration-700 hover:scale-110`}
            >
              <FontAwesomeIcon
                icon={faEllipsis}
                size="lg"
                className="text-blue-300"
              />
            </button>
          </div>
        )}
        {isOpen && (
          <div className="flex flex-col-reverse items-start justify-start  w-full gap-2 h-full">
            <button onClick={toggleMenu} className="flex items-center gap-2">
              <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                <FontAwesomeIcon icon={faXmark} size="lg" />
              </div>
            </button>
            <button
              className="flex items-center gap-2 "
              onClick={(e) => {
                e.stopPropagation()
                navigate('/helpSection')
              }}
            >
              <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                <FontAwesomeIcon
                  icon={faInfo}
                  size="lg"
                  className="text-[#FFDC01]"
                />
              </div>
              <span className="text-[#FFDC01] font-semibold text-lg">
                Help section
              </span>
            </button>
            <button
              className="flex items-center gap-2 "
              onClick={toggleChooseMany}
              data-action="add-participant"
            >
              <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center ">
                <FontAwesomeIcon
                  icon={faPlus}
                  size="lg"
                  className="text-[#1398F7]"
                />
              </div>
              <span className="text-[#1398F7] font-semibold text-lg">Add</span>
            </button>
            {presentKids?.map((dep) => dep.users).flat().length > 0 && (
              <>
                <button
                  className="flex items-center gap-2 "
                  onClick={toggleChooseMany}
                  data-action="remove-participant"
                >
                  <div className="w-12 h-12 rounded-full  bg-white flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faTrash}
                      size="lg"
                      className="text-[#FF7171]"
                    />
                  </div>
                  <span className="text-[#FF7171] font-semibold text-lg">
                    Remove Participants
                  </span>
                </button>

                <button
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={toggleChooseMany}
                  data-action="leaving-activity"
                >
                  <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faChild}
                      size="lg"
                      className="text-[#FF8E8D]"
                    />
                  </div>
                  <span className="text-[#FF8E8D] font-semibold text-lg">
                    Leaving activity
                  </span>
                </button>
                <button
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={toggleChooseMany}
                >
                  <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faChildReaching}
                      size="lg"
                      className="text-[#6BBD73]"
                    />
                  </div>
                  <span className="text-[#6BBD73] font-semibold text-lg">
                    Set as present
                  </span>
                </button>
              </>
            )}
          </div>
        )}
        {chooseMany && (
          <ListOfActiveUsers
            presentKids={presentKids}
            onClose={() => setChooseMany(false)}
            refreshDetail={() => refreshDetail()}
            eventTimeId={eventTimeId}
            dataAction={actionOnActiveUsers}
            departmentIds={departments}
            toggleMenu={toggleMenu}
          />
        )}
      </div>
    </div>
  )
}
