import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons' // Ensure you import faCheck
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'

interface RadioOptions {
  value: string
  label: string
  text?: string
  icon?: IconDefinition
  color?: string
}

interface Props {
  title?: string
  text?: string
  options: RadioOptions[]
  value: string
  customDep?: boolean
  disable?: boolean
  onChange: (value: any) => void
}

export default function CustomRadioButton(props: Props) {
  const handleDivClick = (value: string) => {
    props.onChange(value)
  }

  const defaultColor = '#7eb5f4'
  console.log(props?.options)

  return (
    <>
      {!props.disable && (
        <div className={'w-full flex flex-col cursor-pointer'}>
          {props.title}
          <div className="cursor-pointer">
            {props.options.map((option) => {
              const isChecked = props.customDep
                ? props.value.includes(option.value)
                : props.value === option.value
              const indicatorColor = option.color || defaultColor
              return (
                <div
                  key={option.value}
                  onClick={() => !props.disable && handleDivClick(option.value)}
                  className={classNames(
                    'flex align-middle justify-between p-2 hover:bg-blue-50 cursor-pointer',
                    props.disable && 'cursor-not-allowed opacity-70',
                  )}
                >
                  <div className="flex flex-row gap-x-2 items-center">
                    {option.icon && (
                      <div className="flex w-10 justify-center">
                        <FontAwesomeIcon
                          size="lg"
                          icon={option.icon}
                          style={{ color: option.color || defaultColor }}
                        />
                      </div>
                    )}
                    <label htmlFor={option.value} className={`flex flex-col`}>
                      {option.value === 'only_staff' ? (
                        <h2
                          className={`${
                            option.value === 'only_staff' && 'text-xl font-bold'
                          }`}
                        >
                          {option.label}
                        </h2>
                      ) : (
                        <div className={`font-semibold`}>{option.label}</div>
                      )}
                      <span className="text-sm font-medium">{option.text}</span>
                    </label>
                  </div>
                  <div className="flex items-center">
                    {/* Hidden actual radio button */}
                    <input
                      type="radio"
                      value={option.value}
                      id={option.value}
                      className="opacity-0 absolute"
                      checked={isChecked}
                      onChange={(e) =>
                        !props.disable && props.onChange(e.target.value)
                      }
                    />
                    {/* Custom indicator with FontAwesomeIcon */}
                    <div
                      className={`w-6 h-6 rounded-full border flex justify-center items-center ${
                        isChecked ? 'border-transparent' : 'border-gray-400'
                      }`}
                      style={{
                        backgroundColor: isChecked
                          ? indicatorColor
                          : 'transparent',
                      }}
                    >
                      {isChecked && (
                        <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
