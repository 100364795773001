import Parse from 'parse'
import { DOCUMENT_VISIBILITY_TYPE } from '../Enums/TyraEnums'
import { Department } from './Department'
import { DocumentFolder } from './DocumentFolder'
import { Kid } from './Kid'
import { School } from './School'
import { User } from './User'

export class Document extends Parse.Object {
  public static readonly Fields = {
    className: 'documents',

    school: 'school',
    school_pointer: 'school_pointer',
    owner: 'owner',
    visibility_type: 'visibility_type',
    in_kid_folder: 'in_kid_folder',
    in_dep_folder: 'in_dep_folder',
    user_shared_with: 'user_shared_with',
    only_staff: 'only_staff',

    notice_user_id: 'notice_user_id',
    notice_kid_dep_id: 'notice_kid_dep_id',
    dest_type: 'dest_type',
    sendPush: 'sendPush',
  }
  constructor() {
    super('documents')
  }

  get school_pointer(): School {
    return this.get('school_pointer')
  }
  set school_pointer(value: School) {
    this.set('school_pointer', value)
  }
  get school(): string {
    return this.get('school')
  }
  set school(value: string) {
    this.set('school', value)
  }
  get file(): Parse.File {
    return this.get('file')
  }
  set file(f: Parse.File) {
    this.set('file', f)
  }
  get uuid(): string {
    return this.get('uuid')
  }
  set uuid(value: string) {
    this.set('uuid', value)
  }

  get require_sign(): boolean {
    return this.get('require_sign')
  }
  set require_sign(value: boolean) {
    this.set('require_sign', value)
  }
  get name(): string {
    return this.get('name')
  }
  set name(value: string) {
    this.set('name', value)
  }
  get mime_type(): string {
    return this.get('mime_type')
  }
  set mime_type(value: string) {
    this.set('mime_type', value)
  }
  get visibility_type(): DOCUMENT_VISIBILITY_TYPE {
    return this.get('visibility_type')
  }
  set visibility_type(input: DOCUMENT_VISIBILITY_TYPE) {
    this.set('visibility_type', input)
  }
  get user_shared_with(): User[] | undefined {
    return this.get('user_shared_with')
  }
  set user_shared_with(value: User[] | undefined) {
    value ? this.set('user_shared_with', value) : this.unset('user_shared_with')
  }
  get owner(): User | undefined {
    return this.get('owner')
  }
  set owner(value: User | undefined) {
    this.set('owner', value)
  }

  get in_folder(): DocumentFolder | undefined {
    return this.get('in_folder')
  }
  set in_folder(value: DocumentFolder | undefined) {
    value ? this.set('in_folder', value) : this.unset('in_folder')
  }
  get in_kid_folder(): Kid | undefined {
    return this.get('in_kid_folder')
  }
  set in_kid_folder(value: Kid | undefined) {
    value ? this.set('in_kid_folder', value) : this.unset('in_kid_folder')
  }
  get in_dep_folder(): Department | undefined {
    return this.get('in_dep_folder')
  }
  set in_dep_folder(value: Department | undefined) {
    value ? this.set('in_dep_folder', value) : this.unset('in_dep_folder')
  }
  get in_default_kid_folder(): string | undefined {
    return this.get('in_default_kid_folder')
  }
  set in_default_kid_folder(value: string | undefined) {
    value
      ? this.set('in_default_kid_folder', value)
      : this.unset('in_default_kid_folder')
  }

  get only_staff(): boolean {
    return this.get('only_staff')
  }
  set only_staff(value: boolean) {
    this.set('only_staff', value)
  }
}

Parse.Object.registerSubclass('documents', Document)
