import React, { useContext, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { observer } from 'mobx-react'
import Page from '../../components/Page'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { t } from 'i18next'
import { NavigationStoreContext } from '../../store/navigationStore'

export enum PDFExportRoutes {
  UTBILDNING = 'utbildning',
  UNDERVISNING = 'undervisning',
  BARNDOKUMENTATION = 'barndokumentation',
}

export const SkaExport: React.FC = observer(({}) => {
  const navigate = useNavigate()
  /*  const { skaId } = useParams()
  const { getSkaReflectionItems, skaItems } = useContext(ExportStoreContext)
  useEffect(() => {
    if (skaId) {
      getSkaReflectionItems(skaId)
    }
  }, [skaId])*/
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('export.exportDocumentation'))
  }, [t])

  return (
    <Page>
      <div className="p-12 flex justify-center ">
        <div className="w-fit flex flex-col gap-y-4">
          <Button
            label={t('documentation.operation')}
            onClick={() => {
              navigate('/pdf-export/' + PDFExportRoutes.UTBILDNING)
            }}
            variant="fullYellowExport"
          />
          <Button
            label={t('documentation.project')}
            onClick={() => {
              navigate('/pdf-export/' + PDFExportRoutes.UNDERVISNING)
            }}
            variant="fullGreenExport"
          />

          <Button
            label={t('export.kidDocument')}
            onClick={() => {
              navigate('/pdf-export/' + PDFExportRoutes.BARNDOKUMENTATION)
            }}
            variant="fullBlueExport"
          />
        </div>
      </div>
    </Page>
  )
})
