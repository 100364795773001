import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faCog,
  faFile,
  faFolder,
} from '@fortawesome/free-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import { Button } from '../../../components/Button'
import {
  NotificationContext,
  NotificationVariant,
} from '../../../App/notification/notificationManger'
import { DocumentationStoreContext } from '../../../store/documentationStore'
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons'
import Swal from 'sweetalert2'
import { t } from 'i18next'

export const DocumentationItem = ({
  id,
  isFile,
  onClick,
  icon,
  title,
  className,
  hideSettings,
  type,
  onRemove,
}: {
  id?: string
  onClick: any
  title: string
  className: string
  icon?: any
  isFile?: boolean
  hideSettings?: boolean
  type?: string
  onRemove?: any
}) => {
  const [showModal, setShowModal] = useState(false)
  const { notify } = useContext(NotificationContext)
  const { removeDocumentation, copyDocumentation } = useContext(
    DocumentationStoreContext,
  )
  return (
    <div
      className={classNames(
        'flex flex-row  items-center  cursor-pointer p w-full',
        'relative',
        'border rounded-2xl shadow',
        'p-3  space-x-0  hover:scale-105 md:justify-center px-3',
      )}
    >
      <div
        style={{ zIndex: 1 }}
        className={
          'absolute md:top-2 right-10 md:right-2 text-gray-500 bg-white w-[20px] z-50 flex flex-col items-center justify-center '
        }
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {!hideSettings && (
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            size={'1x'}
            onClick={() => {
              setShowModal(true)
            }}
          />
        )}
      </div>

      <div className={'relative'} onClick={onClick}>
        <FontAwesomeIcon
          className={classNames(className)}
          icon={isFile ? faFile : faFolder}
          size="5x"
        />
        <div
          className={
            'absolute top-0 left-0 w-full h-full flex justify-center items-center'
          }
        >
          {(isFile || icon) && (
            <FontAwesomeIcon
              className={'text-white pt-2'}
              icon={isFile ? faBars : icon}
              size="2x"
            />
          )}
        </div>
      </div>
      <div
        onClick={onClick}
        className={classNames(
          'flex flex-col  justify-center flex-1  h-full border-gray-200 relative w-[150px] overflow-hidden break-all ',
        )}
      >
        <div className={'flex h-full items-center'}>
          <div className={'flex flex-col p-2'}>
            <p
              className={classNames('flex-1 md:text-center', isFile && 'pt-2')}
            >
              {title}
            </p>
          </div>
        </div>
      </div>

      <ModalRegular
        title={''}
        className={'max-w-xl'}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <div className={'flex flex-col space-y-3 pt-10'}>
          <Button
            size="md"
            variant={'danger'}
            label={'Remove'}
            onClick={async () => {
              if (window.confirm(t('documents.areYouSureRemoveFile'))) {
                await removeDocumentation({
                  id: id ?? ' ',
                  type: type ?? '',
                })
                /*notify({
                  variant: NotificationVariant.OK,
                  title: 'Removed',
                  message: 'Removed',
                })*/
                Swal.fire({
                  text: t('general.removed'),
                  icon: 'success',
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.ok'),
                })

                if (onRemove) {
                  onRemove()
                }
                setShowModal(false)
              }
            }}
          />

          <Button
            size="md"
            label={'Copy'}
            onClick={async () => {
              await copyDocumentation({
                id: id ?? ' ',
                type: type ?? '',
              })
              /*notify({
                variant: NotificationVariant.OK,
                title: 'Copy',
                message: 'Copy',
              })*/
              Swal.fire({
                text: t('general.copied'),
                icon: 'success',
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonColor: '#7eb5f4',
                cancelButtonColor: '#d33',
                confirmButtonText: t('general.ok'),
              })

              if (onRemove) {
                onRemove()
              }
              setShowModal(false)
            }}
          />
        </div>
      </ModalRegular>
    </div>
  )
}
