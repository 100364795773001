import moment from 'moment'

export const getSemesters = (start: Date, end: Date) => {
  const semesters = []
  const startDate = moment(start)
  const endDate = moment(end)

  const todaysMonth = Number(endDate.format('M'))
  const today = Number(endDate.format('Y'))
  let year = Number(startDate.format('Y'))

  for (let i = year; i <= today; i++) {
    if (i === today && todaysMonth < 7) {
      semesters.push({ year: i, semesters: [1] })
    } else {
      semesters.push({ year: i, semesters: [1, 2] })
    }
  }

  return semesters
}
