import React, { useContext } from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { School } from '../Models/School'
import { Department } from '../Models/Department'
import { UserRole } from '../Models/UserRole'
import { userStore, UserStoreContext } from './userStore'
import { getRoleFromId } from '../utils/parseUtils'

class DepartmentStore {
  departments?: Department[]
  departmentsForSpecificUserRole?: Department[]
  selectedDepartment?: Department
  selectedDepartments?: Department[]
  departmentsForMultiSchool?: Department[]
  constructor() {
    makeAutoObservable(this)
  }

  setDepartmentsForMultiSchool = (departmentsForMultiSchool: Department[]) => {
    this.departmentsForMultiSchool = departmentsForMultiSchool
  }

  setDepartments = (departments: Department[]) => {
    this.departments = departments
  }
  setDepartmentsForSpecificUserRole = (departments: Department[]) => {
    this.departmentsForSpecificUserRole = departments
  }
  setSelectedDepartment = (department: Department) => {
    this.selectedDepartment = department
  }

  setSelectedDepartments = (department: Department[]) => {
    this.selectedDepartments = department
  }

  fetchDepartmentsForMultiSchools = async (schoolIds: string[]) => {
    const schoolQuery = new Parse.Query(School)
    const departmentsQuery = new Parse.Query(Department)
      .containedIn(
        'school_pointer',
        schoolIds.map((schoolId) => School.createWithoutData(schoolId)),
      )
      .equalTo('isDep', true)
      .include('school_pointer')
      .limit(10000)
    const departmentsForMultiSchool = await departmentsQuery.find()
    this.setDepartmentsForMultiSchool(departmentsForMultiSchool)
  }

  fetchDepartments = async (schoolId: string, kidAllDeps?: boolean) => {
    const waitToSetCurrentUserRole = await userStore.fetchUserRole(
      localStorage.getItem('user_role_id') ?? '',
    )
    const currentUserRole = userStore.currentUserRole
    if (currentUserRole?.attributes.kid && !kidAllDeps) {
      const departments = await new Parse.Query(Department).get(
        currentUserRole?.attributes.kid.attributes.departmentPointer.id,
      )

      this.setDepartments([departments])
    } else {
      const schoolQuery = new Parse.Query(School)
      const school = await schoolQuery.get(schoolId)
      const departmentsQuery = new Parse.Query(Department)
        .equalTo('school_pointer', school)
        .equalTo('isDep', true)
        .include('school_pointer')
        .limit(10000)
      const departments = await departmentsQuery.find()
      this.setDepartments(departments)
    }
  }
  fetchDepartmentsForSpecificUserRole = async (
    schoolId: string,
    userRole: UserRole,
  ) => {
    let departments = []

    if (userRole?.attributes.kid) {
      const department = await new Parse.Query(Department).get(
        userRole.attributes.kid.attributes.departmentPointer.id,
      )
      departments = [department] // Lägg till den hämtade avdelningen i en array
    } else {
      const school = await new Parse.Query(School).get(schoolId)
      const query = new Parse.Query(Department)
        .equalTo('school_pointer', userRole.school_pointer)
        .equalTo('isDep', true)
        .include('school_pointer')
        .limit(10000)
      departments = await query.find() // Direkt hämta listan med avdelningar
    }

    // Returnera den hämtade listan med avdelningar
    return departments
  }

  fetchDepartmentsFromUserRole = async (userRole: UserRole) => {
    const waitToSetCurrentUserRole = await userStore.fetchUserRole(
      localStorage.getItem('user_role_id') ?? '',
    )
    const currentUserRole = userStore.currentUserRole
    if (currentUserRole?.attributes.kid) {
      const departments = await new Parse.Query(Department).get(
        currentUserRole?.attributes.kid.attributes.departmentPointer.id,
      )
      this.setDepartments([departments])
    } else {
      const departmentsQuery = new Parse.Query(Department)
        .equalTo('school_pointer', userRole?.attributes?.school_pointer)
        .equalTo('isDep', true)
        .include('school_pointer')
        .limit(10000)
      const departments = await departmentsQuery.find()
      this.setDepartments(departments)
    }
  }
}

export const departmentStore = (() => {
  return new DepartmentStore()
})()
export const DepartmentStoreContext: React.Context<DepartmentStore> =
  React.createContext(departmentStore)
