import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import ColorCard from '../../../../components/ColorCard'
import Page from '../../../../components/Page'
import { RandomColors } from '../../../../components/RandomColors'
import { getImageOrPlaceHolder } from '../../../../utils/parseUtils'
import { useContext, useEffect, useState } from 'react'
import { DepartmentStoreContext } from '../../../../store/departmentStore'
import { KidsStoreContext } from '../../../../store/kidsStore'
import { UserStoreContext } from '../../../../store/userStore'
import { Button } from '../../../../components/Button'
import ModalRegular from '../../../../components/ModalRegular'
import Swal from 'sweetalert2'
import { Department } from '../../../../Models/Department'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { NavigationStoreContext } from '../../../../store/navigationStore'
import { useParams } from 'react-router-dom'
import Parse from 'parse'
import { Loading } from '../../../../components/Loading'

export const ChangeChildDep = () => {
  const { departments, selectedDepartments, fetchDepartments } = useContext(
    DepartmentStoreContext,
  )
  const {
    fetchKids,
    kids,
    search,
    fetchUserRoles,
    updateKidDepartment,
    changeDepartment,
  } = useContext(KidsStoreContext)
  const { currentUserRole } = useContext(UserStoreContext)
  const [loading, setLoading] = useState(false)
  const [selectedKids, setSelectedKids] = useState<string[]>([])
  const toggleKidSelection = (kidId: string) => {
    if (selectedKids.includes(kidId)) {
      setSelectedKids(selectedKids.filter((id) => id !== kidId))
    } else {
      setSelectedKids([...selectedKids, kidId])
    }
  }
  const [showModal, setShowModal] = useState(false)

  const [selectedDepartment, setSelectedDepartment] =
    useState<Department | null>(null)
  useEffect(() => {
    if (currentUserRole && currentUserRole?.school_pointer) {
      setLoading(true)
      fetchKids(currentUserRole)
      fetchDepartments(currentUserRole?.attributes?.school_pointer?.id, true)
      fetchUserRoles(currentUserRole).finally(() => {
        setLoading(false)
      })
    }
  }, [currentUserRole?.id])
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('admin.changeKidDep'))
  }, [t])
  const { schoolId } = useParams()
  const showNoDepKid = kids?.find((kid) => !kid.departmentPointer)
  return (
    <Page
      topBar={
        selectedKids.length > 0 && (
          <div className="">
            <Button
              size="sm"
              variant="borderRed"
              label={t('admin.kidChangeDep')}
              onClick={() => {
                setShowModal(true)
              }}
            />
          </div>
        )
      }
      naked
    >
      {loading && <Loading />}
      <div className={'flex justify-center w-full flex-col md:space-y-10 '}>
        {showNoDepKid && (
          <ColorCard
            bgColor="bg-gray-300"
            title={t('documents.missingDepartment')}
          >
            <div
              className={
                'flex flex-wrap max-w-7xl py-3 font-medium justify-center'
              }
            >
              {' '}
              {kids
                ?.filter((kid) => {
                  return !kid.departmentPointer
                })
                .sort((a, b) => {
                  return a?.attributes?.kidName.localeCompare(
                    b?.attributes?.kidName,
                  )
                })
                .map((kid) => {
                  const isSelected = selectedKids.includes(kid?.id || '')

                  return (
                    <div
                      key={kid?.id}
                      onClick={() => {
                        toggleKidSelection(kid?.id || '')
                      }}
                      className={`flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:bg-gray-50 hover:shadow-md rounded-2xl`}
                    >
                      <div className="relative ">
                        {isSelected && (
                          <div className="absolute border-[4px] border-eventsMain rounded-full w-[80px] h-[80px] top-0 left-0" />
                        )}
                        {kid.removed_from_ss12k && (
                          <div className="z-10 w-full h-full absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] rounded-full font-bold text-red-400 flex items-center justify-center text-xs text-center">
                            <>{t('general.endDate')}</>
                            <br />
                            {kid.attributes.placement.attributes.endDate}
                          </div>
                        )}
                        <img
                          className={`w-[80px] h-[80px] rounded-full object-cover shadow`}
                          src={getImageOrPlaceHolder(
                            kid?.attributes?.kidImage?._url,
                          )}
                          alt=""
                        />
                      </div>
                      <p>{kid?.attributes.kidName}</p>
                    </div>
                  )
                })}{' '}
            </div>
          </ColorCard>
        )}
        {departments
          ?.filter((dep) => {
            if (!selectedDepartments?.length) {
              return true
            }

            return selectedDepartments?.find((selDep) => selDep.id === dep.id)
          })
          .map((department, index) => {
            const selectedKidsInDepartment = selectedKids.filter((kidId) =>
              kids?.find(
                (kid) =>
                  kid?.id === kidId &&
                  kid?.attributes?.departmentPointer?.id === department?.id,
              ),
            )

            return (
              <>
                <ColorCard
                  title={department?.attributes?.klassName + ''}
                  bgColor={
                    department.get('departmentColor')
                      ? 'bg-[' + department.get('departmentColor') + ']'
                      : RandomColors[index]
                  }
                >
                  {department.get('departmentColor') ? (
                    <div className="flex flex-wrap justify-center py-44 text-lg sm:text-2xl text-gray-300 w-full ">
                      <> {t('ourKids.noContactInfo')}</>
                    </div>
                  ) : (
                    <div
                      className={
                        'flex flex-wrap max-w-7xl py-3 font-medium justify-center'
                      }
                    >
                      {kids
                        ?.filter((k) => {
                          if (search?.length) {
                            return (
                              k?.attributes?.departmentPointer?.id ===
                                department?.id &&
                              k?.attributes?.kidName
                                ?.toLowerCase()
                                .includes(search.toLowerCase())
                            )
                          }
                          return (
                            k?.attributes?.departmentPointer?.id ===
                            department?.id
                          )
                        })
                        .sort((a, b) => {
                          return a?.attributes?.kidName.localeCompare(
                            b?.attributes?.kidName,
                          )
                        })
                        .map((kid) => {
                          const isSelected = selectedKids.includes(
                            kid?.id || '',
                          )

                          return (
                            <div
                              key={kid?.id}
                              onClick={() => {
                                toggleKidSelection(kid?.id || '')
                              }}
                              className={`flex flex-col space-y-2 items-center justify-center p-3 cursor-pointer hover:bg-gray-50 hover:shadow-md rounded-2xl`}
                            >
                              <div className="relative ">
                                {isSelected && (
                                  <div className="absolute border-[4px] border-eventsMain rounded-full w-[80px] h-[80px] top-0 left-0" />
                                )}
                                {kid.removed_from_ss12k && (
                                  <div className="z-10 w-full h-full absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] rounded-full font-bold text-red-400 flex items-center justify-center text-xs text-center">
                                    <>{t('general.endDate')}</>
                                    <br />
                                    {
                                      kid.attributes.placement.attributes
                                        .endDate
                                    }
                                  </div>
                                )}
                                <img
                                  className={`w-[80px] h-[80px] rounded-full object-cover shadow`}
                                  src={getImageOrPlaceHolder(
                                    kid?.attributes?.kidImage?._url,
                                  )}
                                  alt=""
                                />
                              </div>
                              <p>{kid?.attributes.kidName}</p>
                            </div>
                          )
                        })}
                    </div>
                  )}
                </ColorCard>
              </>
            )
          })}
      </div>
      <ModalRegular
        onClose={() => {
          setShowModal(false)
        }}
        isOpen={showModal}
        bgColor="bg-eventsMain p-4"
        title={t('admin.departments')}
        closeColor="white"
        className="bg-gray-50"
      >
        <div className="h-full w-full flex flex-col p-10">
          <div>
            <>{t('admin.chooseDepChangeKid')}</>:
          </div>
          <div className="flex w-full justify-center p-4 flex-wrap">
            {departments?.map((department) => (
              <div
                key={department.id}
                className={`m-2 p-2 bg-gray-300 rounded-full text-white flex flex-row relative justify-center min-w-[190px] text-center shadow-md cursor-pointer no-select ${
                  selectedDepartment?.id === department.id
                    ? 'bg-blue-300 hover:bg-blue-300'
                    : 'hover:bg-gray-200'
                }`}
                onClick={() => {
                  if (selectedDepartment?.id === department.id) {
                    setSelectedDepartment(null)
                  } else {
                    setSelectedDepartment(department)
                  }
                }}
              >
                <div className="flex max-w-[120px] overflow-hidden">
                  {department.attributes.klassName}
                </div>
                {selectedDepartment?.id === department.id && (
                  <div className="ml-2 absolute top-[9px] right-4 ">
                    <FontAwesomeIcon icon={faCheck} className="text-lime-200" />
                  </div>
                )}
              </div>
            ))}
          </div>
          {selectedDepartment && (
            <div className="mt-auto flex justify-end p-4">
              <Button
                size="sm"
                variant={'borderBlue'}
                label={t('admin.kidChangeDep')}
                onClick={async () => {
                  const result = await Swal.fire({
                    //title: 'Are you sure?',
                    text: t('admin.moveKidDepartment')
                      .replace('c0unt123', '' + selectedKids.length)
                      .replace('dep4rtment', '' + selectedDepartment.klassName),
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                    cancelButtonText: t('general.cancel'),
                  })

                  if (result.isConfirmed && schoolId) {
                    await changeDepartment(
                      selectedKids,
                      schoolId,
                      selectedDepartment,
                    )
                    Swal.fire({
                      //title: 'Are you sure?',
                      icon: 'success',
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                    }).then((result) => {
                      if (result.isConfirmed && currentUserRole) {
                        setShowModal(false)
                        fetchKids(currentUserRole).finally(() => {
                          setSelectedKids([])
                        })
                      }
                    })
                  }
                }}
              />
            </div>
          )}
        </div>
      </ModalRegular>
    </Page>
  )
}

export default ChangeChildDep
