import React from 'react'
import Parse from 'parse'
import { makeAutoObservable } from 'mobx'
import { UserRole } from '../Models/UserRole'

interface Category {
  id: string
  label: string
}

class CategoryStore {
  selectedCategories?: Category[]
  constructor() {
    makeAutoObservable(this)
  }

  setSelectedCategories = (selectedCategories: Category[]) => {
    this.selectedCategories = selectedCategories
  }
}
export const categoryStore = (() => {
  return new CategoryStore()
})()
export const CategoryStoreContext: React.Context<CategoryStore> =
  React.createContext(categoryStore)
